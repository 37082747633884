/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//img 

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import NewProjects from './new_projects_component';
import SearchProjects from './search_component';
import { configService } from '../../../services/config_service';

const styles = (theme) => ({
    docRoot: {
        flex: 1,
        height: '30px'
    },
    docRootSmall: {
        flex: 1,
        height: '30px',
        paddingLeft: "0px",
        paddingRight: '0px'
    },
});


class Dashboard extends React.PureComponent {
    constructor(props) {
        super(props);

        let selectedTab = configService.get("general.activeDashboard") ?? 'new';
        this.state = {
            selectedView: selectedTab
        }
    }

    render() {
        const { classes } = this.props;
        let rootStyle = classes.docRootSmall;
        const wideScreen = this.state.innerWidth > this.props.theme.breakpoints.values.sm;
        if (wideScreen) {
            rootStyle = classes.docRoot;
        }
        return (
            <Box flex={1} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch" alignSelf="stretch" className={this.props.classes.root}>
                <Container maxWidth="lg" className={rootStyle}>
                    {(this.state.selectedView === "new") &&
                        <NewProjects onOpenPdf={this.props.onOpenPdf}
                        onSearch={this.switchView('search')}/>
                    }
                    {(this.state.selectedView === "search") &&
                        <SearchProjects onNewProjects={this.switchView('new')}/>
                    }
                </Container>
            </Box>
        );
    }

    switchView = (newView) => () => {
        configService.set("general.activeDashboard", newView);
        this.setState({selectedView: newView});
    }
}

Dashboard.propTypes = {
    onOpenPdf: PropTypes.func,                          // (url: string) => {} called when the pdf needs to be opened, platform specific, 
};

export default withTheme(withTranslation()(withStyles(styles)(withRouter(Dashboard))));