/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2022] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import AngleAcute from 'mdi-material-ui/AngleAcute';
import CheckAll from 'mdi-material-ui/CheckAll';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
    checkbox: {
        color: 'black',
        width: '24px',
        height: '24px',
        fontSize: '15px',
        backgroundColor: 'white',
        borderRadius: '15px',
        padding: '0px',
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '2px',
        marginLeft: '2px',
    }

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class LidarAngleRolloutDisplay extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    render() {
        const { t } = this.props;
        return (
            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}> 
                <AngleAcute fontSize="inherit" />
                <div className={this.props.classes.text}>{Math.round(this.props.angleValue)}°</div>
                {(this.props.showAccept) &&
                    <IconButton 
                        onClick={this.handleAccept} 
                        className={this.props.classes.checkbox}>
                        <CheckAll fontSize="inherit"/>
                    </IconButton>
                }
            </div>
        );
    }

    handleAccept = async () => {
        if (this.props.onAccept) {
            if (this.props.angleValue) {
                this.props.onAccept(this.props.angleValue);
            }
        }
    }
}

LidarAngleRolloutDisplay.propTypes = {
    showAccept: PropTypes.bool,                      // when true, show a checkbox that allows the user to 'accept' the value as a measurement for an observation
    onAccept: PropTypes.func,
    angleValue: PropTypes.number,
};

export default withStyles(styles)(LidarAngleRolloutDisplay);