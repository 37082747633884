/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { CodesBaseService } from "../../services/codes_base_service";

/** platform independent part. 
 * CodesBaseService provides a mechanism for loading the data.
 */
class CodeSelectorService extends CodesBaseService{

    constructor() {
        super();
        this._dict = null;
    }


    /**
     * checks if the specifield list of values points to a leaf or not.
     * returns null if the path is invalid, true if it is a leaf and otherwise false.
     * @param {array} path array of strings.
     * @param {string} strengType the type of stren (dp, m, s), so we can follow the filters correctly (dp & s have BCA, leaf in s, not in dp)
     */
    isLeaf(path, strengType) {
        if (path) {
            let currentPos = this.getDict();
            for(let item of path) {
                if (currentPos === null || currentPos === undefined) return null;
                if (!this.matchesFilter(currentPos, strengType)) return null;      // this node is not allowed for the specified streng type, so stop
                currentPos = currentPos[item];
            }
            // leafs have a property 'label'. Non leafs are pure dictionaries with only single letters as props
            // some leafs (BCA) are condionall leafs, depending on the strengtype, so also check for that.
            return currentPos != null && currentPos.label !== undefined && (!currentPos.forceLeafFor || currentPos.forceLeafFor.includes(strengType));
        }
        return false;
    }

    /**
     * checks if the specifield list of values points to a leaf or not.
     * returns true if it is a leaf and otherwise false.
     * Note: this function doesn't take into account the possibility that the
     * path might be incorrect for the strengtype that currently contains the obs. Strengtype can determine the path cause of
     * the code BCA can be a leaf at 3 and 4 items, depending on if the strengtype is stationary or not.
     * @param {array} path array of dictionary items previously built with translatePath.
     */
    dictPathIsLeaf(path) {
        let leaf = this.getLeafFromDictPath(path);
        return leaf != null;
    }

    /**
     * checks if the node at the specified path specifies an observation type that
     * is considured continuous (has an element with value '1')
     * @param {array} path array of strings
     */
    isContinuous(path) {
        if (path) {
            let currentPos = this.getDict();
            if (currentPos == null) return false;
            for(let item of path) {
                currentPos = currentPos[item];
                if (currentPos === null || currentPos === undefined) return false;
                if ('1' in currentPos) return true;
            }
        }
        return false;
    }

    /**
     * returns the definition at the specified path. If the item is not a leaf,
     * return null
     * @param {array} path array of strings.
     */
    getLeaf(path, strengType) {
        if (path) {
            let currentPos = this.getDict();
            if (currentPos === null) return null;
            for(let item of path) {
                currentPos = currentPos[item];
                if (currentPos === null || currentPos === undefined) return null;
                if (!this.matchesFilter(currentPos, strengType)) return null;             // this node is not allowed for the specified streng type, so stop
            }
            if (currentPos.label !== undefined) {               // it's a leaf
                if (currentPos.forceLeafFor && !currentPos.forceLeafFor.includes(strengType)) {     // this is a conditional leaf, but for the wrong streng type, so not allowed
                    return;
                }
                return currentPos;
            }
        }
        return null;
    }

    /**
     * returns the definition at the end of the path.
     * @param {array} path list of dict objects that was previously built using translatePath
     */
    getLeafFromDictPath(path) {
        if (path && path.length > 0) {
            const res = path[path.length-1];
            if (res.label !== undefined) {
                return res;
            }
        }
        return null;
    }

    /**
     * returns a list of labels. for each item in the path, 1 label
     * @param {array} path 
     */
    getLabelsFromDictPath(path) {
        let result = [];
        for(let item of path) {
            if (item.label === undefined) { 
                if (item._ref !== undefined) {
                    item = item._ref;
                }
            }
            if (item.label !== undefined) {
                result.push(item.label);
            }
            else {
                result.push("");                            // found nothing, so add empty string so that we have equal nr items in result list
            }
            
        }
        return result;
    }

    /**
     * returns a list of dict objects that represent the path.
     * When the last object is a dict item, it's a leaf,
     * other objects are key-value pairs, with 1 _ref object that references the dict item.
     * Use getLeafFromDictPath and dictPathIsLeaf
     * @param {array} path array of strings, keys into the dict.
     */
    translatePath(path) {
        let result = [];
        if (path) {
            let currentPos = this.getDict();
            if (currentPos !== null) {
                for(let item of path) {
                    currentPos = currentPos[item];
                    if (currentPos === null || currentPos === undefined) {break;}
                    result.push(currentPos);
                }
            }
        }
        return result;
    }

    /**
     * returns the node at the specified path
     * @param {array} path array of strings
     */
    getNode(path) {
        if (path) {
            let currentPos = this.getDict();
            for(let item of path) {
                if (currentPos === null) return null;
                currentPos = currentPos[item];
            }
            if (currentPos === undefined) {                      // can be when last item was not in currentPos or currentPos was already an endnode
                return null;
            }
            if (currentPos.label !== undefined) {               // it's a leaf
                return currentPos;
            }
            return currentPos._ref;                                 // ref to the node
        }
        return null;
    }



    getDict() {
        if (this._dict) {
            return this._dict;
        }
        else {
            if (this.data === null) {
                throw new Error("Codes list not yet loaded");
            }
            this._dict = this._getSubDict(this.data.children);
            return this._dict;
        }
    }

    _getSubDict(list) {
        let res = {};
        for (const item of list) {
            if (item.children && item.children.length > 0) {
                res[item.value] = this._getSubDict(item.children);
                res[item.value]["_ref"] = item;                         // so we still have a reference to the object, but hidden. This allows us to find non-leaf objects quickly
                if (item.forceLeafFor) {                                // BCA is terminator for stationary, not for dp. 
                    res[item.value]["forceLeafFor"] = item.forceLeafFor;
                    res[item.value]['label'] = item.leafLabel ?? item.label;
                    if (item.directionCount) res[item.value]['directionCount'] = item.directionCount;
                    if (item.valueCount) res[item.value]['valueCount'] = item.valueCount;
                    if (item.valueRequired) res[item.value]['valueRequired'] = item.valueRequired;
                    if (item.valueType) res[item.value]['valueType'] = item.valueType;
                    if (item.valueLabel) res[item.value]['valueLabel'] = item.valueLabel;
                }
            }
            else {
                res[item.value] = item;                                 // store a ref to the item itself, so we can return it
            }
        }
        return res;
    }

    getValueCount(node, strengType) {
        if (!node) return 0;
        if (typeof node.valueCount === 'object' && node.valueCount !== null) {
            return node.valueCount[strengType];
        }
        else {
            return node.valueCount ?? 0;
        }
    }

    getDirectionCount(node, strengType) {
        if (!node) return 0;
        if (typeof node.directionCount === 'object' && node.directionCount !== null) {
            return node.directionCount[strengType];
        }
        else {
            return node.directionCount ?? 0;
        }
    }

    getValueRequired(node, strengType) {
        if (Array.isArray(node.valueRequired)) {
            return node.valueRequired;
        }
        else if (typeof node.valueRequired === 'object' && node.valueRequired !== null) {
            return node.valueRequired[strengType];
        }
        else {
            return null;
        }
    }

    /**
     * returns true if the node has no filter or if the filter matches the requested value.
     * @param {object} node observation definition item
     * @param {string} filterValue m, s, dp
     */
    matchesFilter(node, filterValue) {
        return node.filter == undefined || node.filter == filterValue || (Array.isArray(node.filter) && node.filter.includes(filterValue))
    }

    /**
     * returns true if the node has children within the specified context (BCA is terminator for stationary, not for streng)
     * @param {object} child observation definition item
     * @param {string} strengType m, s, dp
     */
    hasChildren(node, strengType) {
        return (node.children && node.children.length > 0 && (!node.forceLeafFor || !node.forceLeafFor.includes(strengType)) );
    }

    /**
     * returns the list of children, if there is any and if it is allowed in the current contxt (stationary has terminator on BCA)
     * @param {object} child observation definition item
     * @param {string} strengType m, s, dp
     */
     getChildren(node, strengType) {
        if (node.children && node.children.length > 0 && (!node.forceLeafFor || !node.forceLeafFor.includes(strengType)) ){
            return (node.children);
        }
        return null;
    }
   
}

export const codeSelectorService = new CodeSelectorService();