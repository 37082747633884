/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
//import VideoBox from 'mdi-material-ui/VideoBox';
//import { TopSideIcon, RightSideIcon, LeftSideIcon, TopTractorIcon, SideCamIcon, TopCamIcon, SideTractrorIcon, FrontTractorIcon } from '../../../icons';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
//import Speedometer from './speedometer_component';
//import ImageRotationComponent from './image_rotation_component';
import Paper from '@material-ui/core/Paper';
import { documentControlService } from '../../document_control_service';
//import CustomSlider from './custom_slider_component';
//import Gauge from './gauge_component';
//import { moveContent } from '../../../services/lists';
//import BatteryIndicator from './battery_indicator_component';
//import CamerapPosition from './camera_pos_component';
import DashboardBody from './dashboard_body_component';



const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: "row",
        marginBottom: theme.spacing(1),
    },
    mainBox: {
        flexDirection: "row",
        display: 'flex',
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    colBox: {
        flexDirection: "column",
        display: 'flex',
        paddingTop: '4px',
        alignItems: 'stretch',
        justifyContent: 'center'
    },
    colBox2: {
        flexDirection: "column",
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop: '4px',
    },
    colBox3: {
        flexDirection: "column",
        display: 'flex',
        paddingTop: '4px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    rowBox: {
        flexDirection: "row",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    },
    divider: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
});


class Dashboard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
     
        this.btnRadius = 5;                                          // fast pass along param for functions
    }

    componentDidMount() {
        documentControlService.events.addListener('onRobotDataLoaded', this.handleRobotDataLoaded);
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onRobotDataLoaded', this.handleRobotDataLoaded);
    }

    get totalButtonCount() {
        const robot = documentControlService.robotDs;
        if (!robot) {
            return 0;
        }
        let result = 0;
        if (robot.hasDistance()) {
            result++;
        }
        if (robot.hasPitch()) {
            result++;
        }
        if (robot.hasRoll()) {
            result++;
        }
        if (robot.hasYaw()) {
            result++;
        }
        if (robot.hasCameraPan() && robot.hasCameraTilt()) {
            result++;
        }
        else if (robot.hasCameraPan() || robot.hasCameraTilt()) {
            result++;
        }
        if (robot.hasLensPressure()) {
            result++;
        }
        if (robot.hasTractorPressure()) {
            result++;
        }
        if (robot.hasWheelPressure()) {
            result+=2;
        }
        if (robot.hasBattery() || robot.hasLifeLeft()) {
            result += 1;
        }
        if (robot.hasLaserMeasure()) {
            result++;
        }
        
        return result;
    }

    render() {
        const { classes } = this.props;
        
        let totalButtonCount =  this.totalButtonCount;
        
        let height =  this.props.allowedHeight;
        let layout = 0;                                 // start with the normal layout, make narrower as needed
        this.btnRadius = (height / 4) - 12;
        if (this.btnRadius > 30) {                               // buttons are too large, switch to a narower view
            this.btnRadius = (height / 6) - 12;
            layout = 1;
            if (this.btnRadius > 35) {
                if (totalButtonCount > 7) {
                    this.btnRadius = ((height - (28 * Math.trunc(totalButtonCount/2))  ) / totalButtonCount);
                    layout = 3;
                    if (this.btnRadius > 35) {
                        this.btnRadius = (height / (totalButtonCount * 2)) - 10;
                        layout = 2;
                    }
                }
                else {
                    this.btnRadius = (height / (totalButtonCount * 2)) - 10;
                    if (this.btnRadius > 40) {                                          // ad a max, otherwise we get to big for small nr of gauges
                        this.btnRadius = 40;
                    }
                    layout = 2;
                }
            }
        }
        else if (this.btnRadius < 0) {                  // sanity check, for init startup
            this.btnRadius = 0;
        }

        return (
            <div className={classes.root} >
                <Paper className={classes.mainBox} >
                    <DashboardBody layout={layout} btnRadius={this.btnRadius} streng={this.props.streng}/>
                </Paper>
            </div>
        );
    }

    handleRobotDataLoaded = () => {
        this.forceUpdate();                                             // need to refresh cause only now we know which controls to show when loading data from file
    }

}

Dashboard.propTypes = {
    readOnly: PropTypes.bool,
    allowedHeight: PropTypes.number.isRequired,             // the height that the dashboard can use, determins the eventual size of the buttons
    streng: PropTypes.object,                               // ref to the streng, so we refresh when streng has changed
};

export default withTranslation()(withStyles(styles)(Dashboard));