/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    text: {
        textAlign: 'center'
    }
});

class OkCancelDialog extends React.Component {
    /*constructor(props) {
        super(props);
    }*/


    render() {
        const { t } = this.props;
        let okText = this.props.okText ?? t("Ok");
        let cancelText = this.props.cancelText ?? t("Cancel");      
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="confirmation-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Typography color="primary" variant="h5">{this.props.question}</Typography>
                        {this.props.children}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCancel} color="primary">
                        {cancelText}
                    </Button>
                    <Button onClick={this.handleOk} color="primary">
                        {okText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    
    handleOk = () => {
        if (this.props.onClose) this.props.onClose(true);
    }

    handleCancel = () => {
        if (this.props.onClose) this.props.onClose(false);
    }
}

OkCancelDialog.propTypes = {
    title: PropTypes.string,
    question: PropTypes.string,
    open: PropTypes.bool.isRequired,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(OkCancelDialog));