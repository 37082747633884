import { configDefaults } from "../shared_components/services/config_settings";
import {isMobile} from 'react-device-detect';
import { setServerConfig } from "../shared_components/services/server_config_service";


const defaults = {

};

class ConfigService {

    constructor() {
        this.isDemo = false;                                            // for compatibility with the desktop version
        this.isCloud = true;                                            // this is website, so is cloud
        this.isSite = true;
        this.isMobile = isMobile;                                       // desktop can never be mobile, we can 
        this.isViewer = true;
    }


    load() {
        let loc = window.location.hostname;
        //app-dot-cami-global.ew.r.appspot.com/home
        loc = loc.substring(8);
        loc = loc.replace('.appspot.com', '');
        if (loc.endsWith(".ew.r")) {
            loc = loc.substring(0, loc.length-5);
        }
        if (loc === "cami-global") {                                    // small hack: need to put in code, easiest for now
            setServerConfig('https://api-dot-cami-global.ew.r.appspot.com', "AIzaSyDhVr0PFQ5yBzV6yb_GxYZae95aBO55R88");
        }
        
    }

    /**
     * gets the value, returns the default if no value found yet.
     * We need to do it like this, cause the default store only returns the default value if there was no store
     * yet. After an upgrade, new fields could have been added, which would return undefined. Can't have that.
     * @param {string} field the fieldname
     */
    get(field)  {
        let result = localStorage.getItem(field);
        if (result === undefined || result === null) {
            const fields = field.split('.');
            if (fields.length > 0) {
                result = configDefaults;
                for(const f of fields) {
                    result = result[f];
                    if (result === undefined || result === null) {
                        return null;
                    }
                }
            }
        }
        else {
            try {
                result = JSON.parse(result);
            }
            catch(error) {
                console.log(error);
                return null;
            }
        }
        return result;
    }

    set(field, value) {
        localStorage.setItem(field, JSON.stringify(value));
    }

    clear(field) {
        localStorage.removeItem(field);
    }
}

export const configService = new ConfigService();