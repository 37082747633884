

/**
 * provides gps functionality
 */
class GpsService {
    constructor() {

    }

    /**
     * get the current gps position. full state object.
     * if not active, doesn't return value (null).
     */
    get currentPos () {
        return null;
    }

    /**
     * returns true when there is a gps available, otherwise false.
     */
    get isAvailable() {
        return false;
    }


    /**
     * start reading data from the gps.
     */
    start() {
        
    }

    async stop() {
        
    }
}

export const gpsService = new GpsService();