/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';



const styles = (theme) => ({
    root: {
        display: 'grid',
        gridTemplate: '1fr / 1fr',                  // 1 col, 1 row
        placeItems: 'center',
    },
    bottom: {
        gridColumn: "1 / 1",
        gridRow: "1 / 1",
        zIndex: 1,
    },
    topUp: {
        gridColumn: "1 / 1",
        gridRow: "1 / 1",
        zIndex: 2,
        color: "green"
    },
    topDown: {
        gridColumn: "1 / 1",
        gridRow: "1 / 1",
        zIndex: 2,
        color: 'red'
    }
});


/**
 * displays an svg icon and, when enabled, anothe icon on top of it, but only part of the
 * icon and in an other color (green to red, depending on the value).
 * the value determines the height of the secondary icon being shown. This allows us
 * to build a gauge by using 2 svg icons
 */
class DuoMeasuredIcon extends React.Component {
    constructor(props) {
        super(props);
        this.upPathRef = React.createRef();               // to quickly set the value
        this.downPathRef = React.createRef();               // to quickly set the value
        this.lastUpVal = 0;                                 // so we can render the correct value again, no need for state, cause setting this value directly on the html, which is a lot faster in rendering
        this.lastDownVal = 0;
    }

    componentDidMount() {
        //this.setValue(0);
    }

    render() {
        const { classes, showValue } = this.props;
        if (showValue) {
            const upStyle = {color: this.getColor(this.lastUpVal), clipPath: this.getClipPath(this.lastUpVal, 'up') }; 
            const downStyle = {color: this.getColor(this.lastDownVal), clipPath: this.getClipPath(this.lastDownVal, 'down') }; 
            return (
                <div className={classes.root}>
                    <SvgIcon className={classes.bottom} fontSize="large">
                        <path d={this.props.icon}/>
                    </SvgIcon>

                    <SvgIcon className={classes.topDown} fontSize="large" >
                        <path d={this.props.icon} ref={this.upPathRef} style={upStyle}/>
                    </SvgIcon>

                    <SvgIcon className={classes.topUp} fontSize="large" >
                        <path d={this.props.icon} ref={this.downPathRef} style={downStyle}/>
                    </SvgIcon>
                </div>
            );
        }
        else {
            return (
                <div className={classes.root}>
                    <SvgIcon className={classes.bottom} fontSize="large">
                        <path d={this.props.icon}/>
                    </SvgIcon>
                </div>
            );
        }
        
    }

    

    setValue(upVal, downVal) {
        this.lastUpVal = upVal;
        this.lastDownVal = downVal;
        if (this.downPathRef.current) {
            const path = this.downPathRef.current;
            path.style.color = this.getColor(downVal);
            path.style.clipPath = this.getClipPath(downVal, 'down');
        }
        if (this.upPathRef.current) {
            const path = this.upPathRef.current;
            path.style.color = this.getColor(upVal);
            path.style.clipPath = this.getClipPath(upVal, 'up');
        }
    }

    /**
     * gets the color for the current value
     */
    getColor(value) {
        let color = 'lightgreen';
        const limits = this.props.limits;
        if (value >= limits.error) {
            color = 'red';
        }
        else if (value >= limits.warning) {
            color = 'orange';
        }
        return color;
    }

    getClipPath(value, direction) {
        const limits = this.props.limits;
        value = 100 - (100 * value);            // convert to percentage and invert value so we can use it as a value in the clippath
        value /= 2;                                          // we can only use half of the image (other half is used for other direction), so we need to adjust the value accordingly
        switch (direction) {
            case 'up':
                return `inset(${value}% 0px 50% 0px)`;       
            case 'down':
                return `inset(50% 0px ${value}% 0px)`;       
            default:
                return "";
        }
    }
}

DuoMeasuredIcon.propTypes = {
    icon: PropTypes.any,
    limits: PropTypes.object,                          // {max: "max value that can be displayed", warning: "value at which orange color is used", error: "value at which red color is used"}
    showValue: PropTypes.bool,                      // when true, the overlay is shown. default false
};

DuoMeasuredIcon.defaultProps = {
    showValue: false
}

export default withStyles(styles)(DuoMeasuredIcon);