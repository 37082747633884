
/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


////////////////////////////////////////////////////////////////////
////                                                            ////
////        Warning: all labels are used as indexes             ////
////        for translation. So don't change text directly      ////
////        in this file, but do it in the language files       ////
////        instead.                                            ////
////                                                            ////
////////////////////////////////////////////////////////////////////


import i18n from "i18next";
import { assetsService } from '../../services/assets_service';
import { configService } from "../../services/config_service";
import { dialogService } from "../../services/dialog_service";
import { errExtractor } from "./error_extractor";

export const inspectionMethodOptions = [
    { value: "A", label: "Rechtstreekse inspectie van de afvoerleiding, man-inspectie" }, 
    { value: "B", label: "Inspectie door een op afstand bediende tractor met camera"}, 
    { value: "C", label: "Inspectie enkel vanuit de rioolput (vb. Stationaire inspectie (inzoomen))"}
];

export const inspectionMethodOptions_S = [
    { value: "C", label: "Inspectie enkel vanuit de rioolput (vb. Stationaire inspectie (inzoomen))"}
];

export const inspectionMethodOptions_M = [
    { value: "A", label: "Rechtstreekse inspectie van de afvoerleiding, man-inspectie" }, 
    { value: "B", label: "Inspectie door een op afstand bediende tractor met camera"}, 
    { value: "C", label: "Inspectie enkel vanuit de rioolput (vb. Stationaire inspectie (inzoomen))"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"}
];

export const inspectionPurposeOptions = [
    { value: "A", label: "Een eindcontrole van nieuwe aanleg"}, 
    { value: "B", label: "Het einde van een garantieperiode"}, 
    { value: "C", label: "Een routine inspectie van de toestand"}, 
    { value: "D", label: "Het vermoeden van een structureel probleem"}, 
    { value: "E", label: "Het vermoeden van een operationeel probleem"}, 
    { value: "F", label: "Het vermoeden van een infiltratieprobleem"}, 
    { value: "G", label: "De eindcontrole van renovatie of reparatie (renovatie, reparatie, reiniging)"}, 
    { value: "H", label: "De eigendomsoverdracht"}, 
    { value: "I", label: "Het plannen voor investering"}, 
    { value: "J", label: "Een monsteronderzoek"}, 
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"}
];

export const inspectionPurposeOptions_S = [
    { value: "C", label: "Een routine inspectie van de toestand"}, 
    { value: "D", label: "Het vermoeden van een structureel probleem"}, 
    { value: "E", label: "Het vermoeden van een operationeel probleem"}, 
    { value: "F", label: "Het vermoeden van een infiltratieprobleem"}, 
    { value: "G", label: "De eindcontrole van renovatie of reparatie (renovatie, reparatie, reiniging)"}, 
    { value: "H", label: "De eigendomsoverdracht"}, 
    { value: "I", label: "Het plannen voor investering"}, 
    { value: "J", label: "Een monsteronderzoek"}, 
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"}
];

var inspectors = null;

/**
 * load inspectors list
 * The file is loaded from the user's data folder. If the file
 * does not yet exist there, it is first copied from the appPath foldert
 * this is async
 */
export async function loadInspectorsList() {
    if (inspectors === null) {
        if (!configService.isViewer) {                                          // viewers don't have access to any inspectors lists
            return new Promise((resolve, reject) => {
                assetsService.loadAsset('inspectors.json').then((data) => {
                    inspectors = data;
                    resolve(inspectors);
                }).catch((error) => {
                    dialogService.error(window.title, i18n.t("inspector_list_load_failed", {error: errExtractor.get(error)}));
                    inspectors = [];
                    resolve(inspectors);
                });
            });
        }
        else {
            inspectors = [];
            return Promise.resolve(inspectors);
        }
    }
    else {
        return Promise.resolve(inspectors);
    }
}

/**
 * returns the list of inspectors. 
 * Should be loaded first with loadInspectorsList so that things can go in sync 
 */
export function inspectorsList() {
    return inspectors ?? [];                                            // when null, return empty list, this way, don't need to do anything for viewers/cloud
}

export async function loadInspectorsListFromCloud() {
    const result = await assetsService.loadJSONFromCloud('inspectors.json');
    if (result) {
        inspectors = result;
        return result;
    }
    else {
        return null;
    }
}

export async function saveInspectorsList(data) {
    await assetsService.saveAsset('inspectors.json', JSON.stringify(data));
    inspectors = data;
}

export async function saveInspectorsListToCloud(data) {
    await assetsService.saveJSONToCloud('inspectors.json', data);
}


export const directionOptions = [
    { value: "A", label: 'Stroomafwaarts'}, 
    { value: "B", label: 'Stroomopwaarts'},
    { value: "C", label: "Niet bekend"}
];
export const locationTypeOptions = [
    { value: "A", label: "In een weg"}, 
    { value: "B", label: "In een voetpad naast de weg"}, 
    { value: "C", label: "In een berm naast de weg"}, 
    { value: "D", label: "In een ander voetgangersgebied"}, 
    { value: "E", label: "In een veld"}, 
    { value: "F", label: "Op een eigendom met gebouwen"}, 
    { value: "G", label: "In tuinen"}, 
    { value: "H", label: "Onder een permanent gebouw"}, 
    { value: "I", label: "Bosland"}, 
    { value: "J", label: "Moeilijk toegankelijk (vb. autosnelweg of operationele spoorweg,....)"}, 
    { value: "K", label: "Onder een waterweg (rivier, kanaal, …)"}, 
    { value: "X", label: "Speciaal type gedefiniëerd door de opdrachtgevende instantie(vb. XA)"}, 
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"}
];
export const propertyOwnershipOptions = [
    { value: "A", label: 'Openbare eigendom'}, 
    { value: "C", label: 'Privé-eigendom'},
    { value: "C", label: "Niet bekend"}
];
export const axialRefPointOptions = [
    { value: "A", label: "De binnenzijde van de wand van de beginput (cf. Nat. Bijlage)"},
    { value: "B", label: "De bovenkant van het eind van de leiding binnen het beginpunt (cf. Nat. Bijlage)"},
    { value: "C", label: "Het midden van de beginrioolput  (≠ Midden v/h deksel)"},
    { value: "D", label: "Het midden van de inkomende en uitgaande buizen, gemeten in het stroomprofiel"},
    { value: "Z", label: "Anders (+ Algemene opmerking ADE) (vb. Midden v/h deksel)"},
]

export const storageVideoOptions = [
    { value: "A", label: "VHS - videocassette band"}, 
    { value: "B", label: "Video CD"}, 
    { value: "C", label: "Video  DVD"}, 
    { value: "D", label: "Data CD"}, 
    { value: "E", label: "Data DVD"}, 
    { value: "F", label: "Externe HD"}, 
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)  (vb. Externe harddisk)"},
];
export const storageImagesOptions = [
    { value: "A", label: "Fotofilm (papierafdruk)"}, 
    { value: "C", label: "Windows Meta File - WMF"}, 
    { value: "D", label: "Grafic Image File - GIF"}, 
    { value: "E", label: "JPEG"}, 
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)  (vb. Externe harddisk)"}, 

];
export const positionVideoImagesOptions = [
    { value: "A", label: "De opnametijd vanaf het begin van de band in HH:MM:SS"}, 
    { value: "B", label: "Een apparaatafhankelijke numerieke teller"}, 
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"}

];
export const shapeOptions = [
    { value: "A", label: "Rond"}, 
    { value: "B", label: "Rechthoekig"}, 
    { value: "C", label: "Eivormig"}, 
    { value: "D", label: "U-vormig"}, 
    { value: "E", label: "Boogvormig"}, 
    { value: "F", label: "Ovaal"}, 
    { value: "X", label: "Plaatselijke doorsnede- code, door beheerder voorafgegaan door een X (bijv. XA)"}, 
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"}
];

export const shapeOptionsM = [
    { value: "A", label: "Rechthoekig"},
    { value: "B", label: "Rond"},
    { value: "C", label: "Driehoekig"},
    { value: "D", label: "Ovaal"},
    { value: "E", label: "Zeshoekig"},
    { value: "F", label: "Achthoekig"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"},   
]

export const materialOptions = [
    { value: "AA", label: "Asbest cement"},
    { value: "AB", label: "Bitumen"},
    { value: "AC", label: "Gespoten vezels"},
    { value: "AD", label: "Baksteen"},
    { value: "AE", label: "Gebakken klei (gres)"},
    { value: "AF", label: "Cementmortel"},
    { value: "AG", label: "Beton"},
    { value: "AH", label: "Gewapend beton"},
    { value: "AI", label: "Opgespoten beton"},
    { value: "AJ", label: "Betonnen segmenten"},
    { value: "AK ", label: "Vezelcement"},
    { value: "AL", label: "Vezelversterkte kunststoffen"},
    { value: "AM", label: "Gietijzer"},
    { value: "AN", label: "Grijs gietijzer"},
    { value: "AO", label: "Smeedijzer"},
    { value: "AP", label: "Staal"},
    { value: "AQ", label: "Ongeïdentificeerd type ijzer of staal"},
    { value: "AR", label: "Metselwerk (Bepleisterd)"},
    { value: "AS", label: "Metselwerk (Onbepleisterd)"},
    { value: "AT", label: "Epoxy"},
    { value: "AU", label: "Polyester"},
    { value: "AV", label: "Polyetheen PE"},
    { value: "AW", label: "Polypropyleen PP"},
    { value: "AX", label: "PVC-U"},
    { value: "AY", label: "Ongeïdentificeerd type kunststof"},
    { value: "AZ", label: "Ongeïdentificeerd materiaal"},
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"}
];
export const liningTypeOptions = [
    { value: "", label: ""},
    { value: "A", label: "Binnenbekleding aangebracht tijdens fabricage"},
    { value: "B", label: "Opgespoten binnenbekleding (vb. mortel of spuitbeton)"},
    { value: "C", label: "Ter plaatse uitgeharde binnenbekleding (vb. TUB)"},
    { value: "D", label: "De binnenbekleding bestaat uit segmenten (vb. Schelpdelen GVP, keramische tegels)"},
    { value: "E", label: "Binnenbekleding door aparte buizen ('kort' of 'lang')"},
    { value: "F", label: "Binnenbekleding d.m.v. een doorlopende buis (vb. Aan elkaar gelaste buizen )"},
    { value: "G", label: "Strak aansluitende binnenbekleding (vb. Voorgedeformeerde leiding [geplooid of uitgerokken] )"},
    { value: "H", label: "Spiraalvormig gewikkelde binnenbekleding (Spiraalwikkelbuis)"},
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"},
];
export const liningMaterialOptions = [
    { value: "", label: ""},
    { value: "AA", label: "Asbest cement"},
    { value: "AB", label: "Bitumen"},
    { value: "AC", label: "Gespoten vezels"},
    { value: "AD", label: "Baksteen"},
    { value: "AE", label: "Gebakken klei (gres)"},
    { value: "AF", label: "Cementmortel"},
    { value: "AG", label: "Beton"},
    { value: "AH", label: "Gewapend beton"},
    { value: "AI", label: "Opgespoten beton"},
    { value: "AJ", label: "Betonnen segmenten"},
    { value: "AK ", label: "Vezelcement"},
    { value: "AL", label: "Vezelversterkte kunststoffen"},
    { value: "AM", label: "Gietijzer"},
    { value: "AN", label: "Grijs gietijzer"},
    { value: "AO", label: "Smeedijzer"},
    { value: "AP", label: "Staal"},
    { value: "AQ", label: "Ongeïdentificeerd type ijzer of staal"},
    { value: "AR", label: "Metselwerk (Bepleisterd)"},
    { value: "AS", label: "Metselwerk (Onbepleisterd)"},
    { value: "AT", label: "Epoxy"},
    { value: "AU", label: "Polyester"},
    { value: "AV", label: "Polyetheen PE"},
    { value: "AW", label: "Polypropyleen PP"},
    { value: "AX", label: "PVC-U"},
    { value: "AY", label: "Ongeïdentificeerd type kunststof"},
    { value: "AZ", label: "Ongeïdentificeerd materiaal"},
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"}
];
export const sewerTypeOptions = [
    { value: "A", label: "Afvoerleiding of riool onder vrij verval"},
    { value: "B", label: "Persleiding"},
    { value: "C", label: "Vacuümleiding"},

];
export const sewerUsageOptions = [
    { value: "A", label: "Riolering ontworpen om alleen afvalwater af te voeren"},
    { value: "B", label: "Riolering ontworpen om alleen hemelwater af te voeren"},
    { value: "C", label: "Gecombineerde riolering (Gemengd stelsel)"},
    { value: "D", label: "Industriële riolering"},
    { value: "E", label: "Een overkluisde waterloop"},
    { value: "F", label: "Drainage van ondergrond of landbouwperceel"},
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"},
];

export const sewerUsageOptionsM = [
    { value: "A", label: "Riolering ontworpen om alleen afvalwater af te voeren"},
    { value: "B", label: "Riolering ontworpen om alleen hemelwater af te voeren"},
    { value: "C", label: "Een gecombineerde riolering (Gemengd stelsel)"},
    { value: "D", label: "Riool voor behandeld afvalwater"},
    { value: "E", label: "Een overkluisde riolering"},
    { value: "F", label: "De riool of inspectieput bevat 2 systemen, één voor hemelwater en één voor afvalwater"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"},
];

export const cleaningOptions = [
    { value: "A", label: 'Gereinigd'}, 
    { value: "B", label: 'niet gereinigd'}
];
export const connectionTypeOptions = [
    { value: "A", label: "Rioolput"},
    { value: "B", label: "Inspectieput"},
    { value: "C", label: "Toegangspunt t.b.v. reiniging"},
    { value: "D", label: "Kijkgat"},
    { value: "E", label: "Uitlaat"},
    { value: "F", label: "Sifon ter hoogte van de inspectieput"},
    { value: "G", label: "Sifon ter hoogte van het kijkgat"},
    { value: "H", label: "Sifon zonder toegang"},
    { value: "X", label: "Speciaal type gedefiniëerd door de opdrachtgevende instantie(vb. XA)"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"}
]

//export const surfaceHardeningOptions = ['Steenslag'];
export const precipitationOptions = [
    { value: "A", label: "Geen neerslag"},
    { value: "B", label: "Regen"},
    { value: "C", label: "Smeltende sneeuw of ijs"}
];
export const fluidControlOptions = [
    { value: "A", label: "Geen maatregelen genomen"},
    { value: "B", label: "Stroomopwaarts geblokkeerd"},
    { value: "C", label: "Stroomopwaarts gedeeltelijk geblokkeerd"},
    { value: "Z", label: "Anders (+ Algemene opmerking ADE)"},
];
export const temperatureOptions = [
    { value: "A", label: 'Boven het vriespunt'}, 
    { value: "B", label: 'onder het vriespunt'}
];

export const yearOfCommisioningOptions = [
    { value: "year", label: 'Specifiek jaar (vul verder in hieronder)'},
    { value: "2020-2029", label: '2020-2029'},
    { value: "2010-2019", label: '2010-2019'},
    { value: "2000-2009", label: '2000-2009'},
    { value: "1990-1999", label: '1990-1999'},
    { value: "1980-1989", label: '1980-1989'},
    { value: "1970-1979", label: '1970-1979'},
    { value: "1960-1969", label: '1960-1969'},
    { value: "1950-1959", label: '1950-1959'},
    { value: "BEFR_1949", label: 'voor 1949'},
    { value: "*UNKNOWN*", label: 'Onbekend'},
];

export const verticalRefPointOptions = [
    { value: "A", label: "De bodem van de laagst gelegen uitgaande buis"}, 
    { value: "B", label: "Het deksel"},
    { value: "C", label: "Het nationale referentiepunt"},
    { value: "D", label: "Lokaal referentiepunt"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"}
];

export const CircularRefPointOptions = [
    { value: "A", label: "De laagst gelegen uitgaande buis = 12 uur (cf. Nat. bijlage)"},
    { value: "B", label: "De laagst gelegen uitgaande buis = 6 uur"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"}
];

export const inspectionStageOptions = [
    { value: "A", label: "Van opdrachtgevende instantie naar de inspecteur"},
    { value: "B", label: "Van inspecteur naar opdrachtgevende instantie"},
    { value: "C", label: "Interne opvolging bij de beherende instantie"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"},
];

export const stepTypeOptions = [
    { value: "A", label: "Klimijzers met enkele breedte - klimijzers breed genoeg voor één voet"},
    { value: "B", label: "Klimijzers met dubbele breedte - breed genoeg voor twee voeten"},
    { value: "C", label: "Ladder"},
    { value: "D", label: "Teenholtes (prefab holtes in de wand)"},
    { value: "E", label: "Geen voorziening"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"}
];

export const stepMaterialOptions = [
    { value: "", label: ""},
    { value: "A", label: "Ijzer"},
    { value: "B", label: "Gegalvaniseerd staal"},
    { value: "C", label: "Roestvrij staal"},
    { value: "D", label: "Met kunststof bekleed materiaal"},
    { value: "E", label: "Kunststof (Synthetisch materiaal)"},
    { value: "F", label: "Aluminium"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"},
];

export const atmosphereOptions = [
    { value: "", label: ""},
    { value: "A", label: "Zuurstoftekort"},
    { value: "B", label: "Zwavelwaterstof"},
    { value: "C", label: "Methaan"},
    { value: "D", label: "Ander ontvlambaar gas"},
    { value: "E", label: "Geen gevaarlijke atmosfeer"},
    { value: "Z", label: "Anders (+ Algemene opmerking CDE)"}
];


export const descriptiveLocOptions = [
    { value: "A", label: "Deksel en raam"},
    { value: "B", label: "Stelconstructie"},
    { value: "C", label: "Schacht"},
    { value: "D", label: "Kegelstuk"},
    { value: "E", label: "Reductieplaat"},
    { value: "G", label: "Bordes"},
    { value: "G", label: "Kamer"},
    { value: "H", label: "Banket"},
    { value: "I", label: "Stroomprofiel"},
    { value: "J", label: "Bodem"}
]


/**
 * returns the label for the specified value or ''.
 * @param {string} value the value to search for (ex 'A')
 * @param {array of objects} list list of value-label pairs
 */
export function getLabel(value, list) {
    if (list && value) {
        let found = list.find(x => x.value === value);
        if (found) return i18n.t(found.label);
    }
    return "";
}