/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { AttachmentsBaseService } from '../../../components/document/attachments_base_service';
import { activeProjectService } from '../../../services/active_project_service';

class AttachmentsService extends AttachmentsBaseService {

    constructor() {
        super()
    }
    
    async load() {
        const project = activeProjectService.data;
        let result = [];
        let blockAdd = false;
        if (activeProjectService.loadedFrom === "cloud" && project.files && project.files.attachments) {        // only load cloud info if we are showing from cloud
            blockAdd = true;
            result = project.files.attachments.map((bucket) => {
                let label = bucket.name;
                label = label.slice(label.indexOf('_') + 1);                    // remove the first part before the first '_', cause that's the project number
                return {label: label, bucket: bucket} 
            });
        }
        return await super.loadLocal(result, blockAdd)
    }

}

export const attachmentsService = new AttachmentsService();