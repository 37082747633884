/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { robotPlayer } from '../../components/document/robot/robot_player_service';
import { cameraService } from '../../components/document/video/camera_service';
import { activeProjectService } from '../../services/active_project_service';
import { projectService } from '../services/project_service';
import i18n from "i18next";
import { errExtractor } from '../../shared_components/services/error_extractor';
import { dialogService } from '../../services/dialog_service';
import { robotRecorderClient } from './instrumentation/robot/robot_client_service';
import { engineService } from '../../components/video_engine/engine_service';
import { configService } from '../../services/config_service';
import { gpsService } from '../../services/gps/gps_service';
import { timeService } from '../services/time_service';
import { strandPropsService } from '../services/strand_props_service';
import { distanceService } from '../services/distance_service';
const events = require('events');


/**
 * ties up all the different data gathering services (other than text)
 * and controls where the data is going to and coming from (from file or device)
 */
class DocumentControlService {
    constructor() {
        this.canEdit = false;                           // when true, the document is in edit mode and stuff can be recorded, otherwise stuff can only be played.
        this.liveTractor = false;                       // when true, will force tractor as recorder (for reverse tractor record)
        this.controlState = 'stopped';                  // stores the current state of the machine (record, play,...)
        this.events = new events.EventEmitter();        // callback for visualizing robot data and video time. also used by dashboard, when visible
        this.onControlStateChanged = null;  // callback for when the controlstate has changed from here. used by the document component to update the ui when needed
        this.onVerifyDimensions = null;     // called when the lidar indicated that the dimensions of the strand might be wrong. is called async so that the user can provide new values before recording starts.
        this.robotDs = null;                // the datasource for the robot
        this.currentTime = null;            // stores the current time stamp, so we can get to this value from editor and observations
        this.documentPos = null;            // when there is an object, document components should store their state in this object when unmounting. When creating and there is an object, they should use the info to restore their state, like selected streng,... This is used for switching between fullscrreen
        this.mounted = false;               // true when fully mounted. other parts of the system can use this to determine if they want to access video or tractor (when mount failed, error is shown, no need to show again.)
        strandPropsService.events.addListener('sizeEdited', this.handleStrandSizeChanged);  // when the size of the strand has changed and we have lidar data, we need to reload the data cause it changes the centralization.
    }

    /**
     * 
     * @param {bool} canEdit when true, video & tractor recorders will be loaded, otherwise players.
     * @param {bool} liveTractor when true, will force tractor as recorder
     */
    async mount(canEdit, liveTractor=false) {
        cameraService.onRecordingDone = this.internalHandleRecordStopped;        // setup handler for when recordings are done, do here, instead of constructor, maybe cameraEngine not yet running in constructor
        try {
            this.canEdit = canEdit;
            this.liveTractor = liveTractor;
            distanceService.current = 0;

            if (!configService.isViewer) {                      // need to start gps here, originally done in engineService, but that is too late, need to have gps available when project opens with last streng already recorded, and it will open in read-mode
                gpsService.start();
            }
            if (this.canEdit || liveTractor) {     
                this.robotDs = robotRecorderClient;
                this.startTractor();                            // important: start robot before engine cause engine will update robot
                if (!engineService.isRunning) {                        // dont restart if already running
                    await engineService.startInputs();
                }
                else {
                    engineService.reInit();               // mostly for the tractors
                }
            }
            else {
                this.robotDs = robotPlayer;
                this.startTractor();
            }
            this.mounted = true;
        }
        catch (error) {
            dialogService.error(i18n.t("Camera"), i18n.t("problem_loading_camera", {error: errExtractor.get(error)}));
        }
    }

    /**
     * 
     * @param {boolean} leaveCamera when true, the camera service will not be stopped. This can speed up switching between strengs
     */
    async unMount(leaveCamera = false) {
        this.mounted = false;
        this.stopTractor();                                         // stops the frontend of the robot which always needs to be replaced with an unmount, backend can remain (is in engineservice)
        distanceService.current = 0;
        if (engineService.isRunning && leaveCamera === false) {
            await engineService.stopInputs();
        }
        if (!configService.isViewer) {                              // no gps service in viewer
            await gpsService.stop();
        }
    }

    startTractor() {
        if (this.robotDs) {                                         // this should normally not happen, but is technically possible (browser requesting a robot recorder)
            this.robotDs.onData = this.handleRoboData;              // important: set before calling start, otherwise we might mis the first call.
            let needCallLoaded = false;
            if (this.robotDs.onDataLoaded !== undefined) {          // is undefined if doesn't exist (recorder), if exists, it is at least null
                this.robotDs.onDataLoaded = this.handleRobotDataLoaded;
                this.robotDs.onDataLoading = this.handleRoboDataLoading;
            }
            else {
                needCallLoaded = true;
                this.handleRoboDataLoading();                       // for recorder, need this to load lidar data properly when switching tabs from existing to new strand
            }
            this.robotDs.start();
            if (needCallLoaded) {
                this.handleRobotDataLoaded();
            }
        }
    }

    /**
     * stops the robot client (which receives from the engine process)
     */
    stopTractor() {
        if (this.robotDs) {
            this.robotDs.stop();
            this.robotDs.onData = null;
            if (this.robotDs.onDataLoaded) {
                this.robotDs.onDataLoaded = null;
                this.robotDs.onDataLoading = null;
            }
            this.robotDs = null;
        }
    }

    /**
     * used to switch between live camera
     * @param {bool} live when true, data comes from tractor directly, otherwise from file
     */
    setTractorSource(live) {
        let changed = false;
        if (this.robotDs === robotRecorderClient && live === false) {       // switch to player
            this.stopTractor();
            this.robotDs = robotPlayer;
            this.robotDs.onDataLoaded = this.handleRobotDataLoaded;
            this.robotDs.onDataLoading = this.handleRoboDataLoading;
            changed = true;
        }
        else if (this.robotDs === robotPlayer && live === true) {           // switch to live.
            this.stopTractor();
            this.robotDs = robotRecorderClient;
            changed = true;
        }
        if (changed) {
            this.robotDs.onData = this.handleRoboData;      // important: set before calling start, otherwise we might mis the first call.
            this.robotDs.start();                           // this will load the data or start reading from serial
        }
    }

    /**
     * called by the document to switch between video and live feed.
     * When going to live feed, makes certain that engine is running.
     * @param {bool} live when true, data comes from tractor and video directly, otherwise from file
     */
    async setLiveInputSource(live) {
        if (live && engineService.isRunning === false) {
            await engineService.startInputs();
        }
        this.setTractorSource(live);
    }

    /**
     * checks if the new controlstate is allowed. 
     * Will ask the user for feedback if necessary. 
     * returns true if allowed, otherwise false.
     * @param {string} value new control state
     */
    async checkControlStateChangeAllowed(value) {
        if (value === "record") {
            const streng = activeProjectService.activeStreng;
            if (streng) {
                if (this.canEdit === false) {
                    return new Promise((resolve, reject) => {
                        const message =  i18n.t('Er bestaat reeds een video opname voor deze streng/put');
                        const detail = i18n.t("De opname overschrijven?  Selecteer 'ja' om een nieuwe opname te maken, 'nee' om de bestaande te behouden.");
                        dialogService.askYesNo(i18n.t('Record'), message, detail, (response) => {
                            resolve(response.response === 0);
                        });
                    });
                }
                return Promise.resolve(true);                       // there is a streng and no video yet, so record is allowed
            }
            return Promise.resolve(false);                          // there is no streng so can't record
        }
        else if (value === "reverseTractor" && activeProjectService.activeStrengHasReverseRobotData()) {
            return new Promise((resolve, reject) => {
                const message = i18n.t('Er bestaat reeds een opname van de tractor die in omgekeerde richting reed.');
                const detail = i18n.t("De opname overschrijven?  Selecteer 'ja' om een nieuwe opname te maken, 'nee' om de bestaande te behouden.");
                dialogService.askYesNo(i18n.t('Record'), message, detail, (response) => {
                    resolve(response.response === 0);
                });
            });
        }
        return Promise.resolve(true);
    }

    /**
     * returns true if the operatoin was succesfull
     * @param {string} value new control state
     */
    async setControlState(value) {
        try {
            if (value !== this.controlState) {
                
                if (value === 'record') {
                    await this.startRecord();                      
                }
                else if (value === 'reverseTractor') {
                    this.startReverseRecord();
                }
                else if (value === 'stop') {
                    value = this.stop(value);
                }
                this.controlState = value;
                this.events.emit("controlState", value);       // -> not yet used
            }
            return true;
        }
        catch (error) {
            dialogService.error(i18n.t("Control"), `${error}`);
            return false;
        }
    }

    async startRecord() {
        if (this.canEdit === false) throw new Error("can't record in view mode");                                    // these are mostly internal errors, only possible when something has been programmed wrong, so can remain in enlgish
        if (!engineService.isRunning) throw new Error("can't record, no camera available");
        if (activeProjectService.activeStrengIdx === -1) throw new Error("can't record, no streng selected");
        
        const curStreng = activeProjectService.activeStreng;
        if (!curStreng) throw new Error("can't record, no streng selected");

        await this.verifyRobotState();
        curStreng.actualInspectionLength = 0;              // when recording new data, always make certain that this is reset. If there is no robot but prebiously there was, data needs to be reset.
        curStreng.recStartTime = Date.now();
        const camDetails = cameraService.getCameraDetails();                        // when recording starts, also store the size of the video. Previously, this was only done when first played, but with new live/video modus toggle, some videos never get loaded 1 time. also stores calibration in project for later use
        curStreng.cams = camDetails;                                               // important: must be done before camera recording starts, cause this contains info used by the camera
        curStreng.tractor = engineService.getTractorDetails();
        projectService.updateStartObservation(curStreng);
        activeProjectService.markDirty();

        await engineService.startRecord();                                            // need to start camera service before tractor recording, otherwise time will not yet be at 0
    }

    /**
     * called before recording, to make certain that the document is in a correct state
     * as specified by the robot: 
     * - are the dimensions of the strand correct, if not ask to correct
     */
    async verifyRobotState() {
        const doCheck = configService.get('lidar.verifyDimensionsBeforeRecord');
        if (doCheck && engineService.tractor && engineService.tractor.lidarProvider) {
            const streng = activeProjectService.activeStreng;
            let result = await engineService.tractor.lidarProvider.verifyDimensions(streng.Width, streng.height);
            if (result && result.result) {
                const adjusted = await this.onVerifyDimensions(+streng.Width, +streng.height, result.width, result.height);       // we get the corrected results back from the user
                if (adjusted) {
                    streng.Width = adjusted.width;
                    streng.height = adjusted.height;
                    strandPropsService.init(streng);                                            // dimensions have been updated, so also update the strandprops service so that the rest of the system nows this, not done automatically cause not through one of the normal channels (ui)
                    activeProjectService.markDirty();                                           // need to make certain that the changes are also saved.
                }
            }
        }
    }

    startReverseRecord() {
        if (this.canEdit === false && this.liveTractor === false) throw new Error("can't record in view mode");     // these are mostly internal errors, only possible when something has been programmed wrong, so can remain in enlgish
        if (activeProjectService.activeStrengIdx === -1) throw new Error("can't record, no streng selected");
        const streng = activeProjectService.activeStreng;
        streng.reverseTractorOffset = 0;
        activeProjectService.markDirty();
        this.robotDs.startReverseRecord();
    }

    stop(value) {
        if (engineService.isRecording) {                                            // stopping recording is an async matter, after video has truly stopped, the state is set to 'stopped'
            if (engineService.tractor && this.controlState === "record") {     // only do when recording stopped, not when reverse track recording stopped
                const streng = activeProjectService.activeStreng;
                streng.actualInspectionLength = distanceService.current;               // when recording new data, always make certain that this is reset. If there is no robot but prebiously there was, data needs to be reset.
                activeProjectService.markDirty();
            }
            engineService.stopRecord();
        }
        else if (this.controlState === "reverseTractor") {                          // only go to stopped when async message arrives from engine
            this.robotDs.stopReverseRecord(this.internalHandleRevRecordStopped);
        }
        else {
            this.gotoStopped();                                                     // stop state needs to be switched to stopped manually (if we don't, the video controller component will remain blocked cause it will thing that the stop has not yet fully been processed)
            value = "stopped";                                                      // gotoStopped also changes controlState, we want to keep this new state. if we don't do this, stopping video play back will not work properly
        }
        this.setTractorSource(this.liveTractor);              // need to do this, cause otherwise the tractor will not switch to player after record and 'forceLive' is not activated.
        return value;
    }


    handleRoboData = (value) => {
        if (value) {
            distanceService.current = value.distance;                  // so other parts of the system can get to this value  
            this.events.emit("onRobotData", value);
        }
    }

    handleRobotDataLoaded = () => {
        this.events.emit("onRobotDataLoaded");              // translate to an event so multiple consumers can monitor this event without knowing the actual source
    }

    handleRoboDataLoading = () => {
        this.events.emit("onRobotDataLoading");              // translate to an event so multiple consumers can monitor this event without knowing the actual source
    }

    /**
     * when the size of the strand has changed, we need to reload
     * lidar data cause it is dependent on these values.
     * We do this from here and not in the lidarPlayer & lidarRecorder
     * so that we can do this from 1 central place + if done in lidarPlayer,
     * we get a memory leak (lidarPlayer is dynamically created & destroyed, so any event-handlers to global objects would remain)
     * we can also trigger the correct events from here so that the UI components get reloaded correctly.
     */
    handleStrandSizeChanged = async () => {
        try {
            if (this.robotDs === robotPlayer) {
                if (robotPlayer.lidarPlayer) {
                    this.handleRoboDataLoading();                       // give the user some visual feedback that the data is reloading
                    try {
                        await robotPlayer.lidarPlayer.load();
                    }   
                    finally {
                        this.handleRobotDataLoaded();
                    }
                }
            }
            else if (this.robotDs === robotRecorderClient) {
                // not yet supported
                //if (engineService.tractor && engineService.tractor.lidarProvider) {
                //    engineService.tractor.lidarProvider.
               // }
            }
        }
        catch(error) {
            dialogService.error("Robot", errExtractor.get(error));
        }
    }

    gotoStopped() {
        if (this.onControlStateChanged) {
            this.onControlStateChanged('stopped');
        }
        //cameraService.stopRecord();                       // when stopped, make certain recording is also labeled as stopped. This is important cause stopRecord calls this to go to the stopped state
        if (this.robotDs) {
            this.robotDs._isRecording = false;
        }
        this.controlState = 'stopped';
    }

    /**
     * called when camera engine manager reports that all recordings are done and files should be processed
     * @param {lsit} files list of raw video files
     */
    internalHandleRecordStopped = async (files) => {
        try {
            if (this.onControlStateChanged) {
                this.onControlStateChanged('processing');
            }
            this.controlState = 'processing';
            for (const file of files) {
                if (typeof file === 'string') {                     // vitec card sends object to indicate it is vitec. These files need to be processed differently
                    await cameraService.processFile(file);
                }
                else {
                    await cameraService.processFile(file.file, true);
                }
            }
        }
        finally {
            this.gotoStopped();
        }
    }

    internalHandleRevRecordStopped = (result) => {
        if (result) {
            const streng = activeProjectService.activeStreng;
            streng.reverseTractorOffset = +result;                                           // we need the total length, so if the reverse tractor didn't stop at 0, we need to substract that 
            activeProjectService.markDirty();
        }
        this.gotoStopped();
    }

    /**
     * looks up the closest distance that is equal or smaller to the specified distance
     * and returns the timestamp.
     * This is used when editing the the distance
     * @param {number} distance the distance to search for
     */
    distanceToTime(distance) {
        const data = this.robotDs ? this.robotDs.data : null;                 // both recorder and player have a data field

        if (data && data.length > 0) {
            let rec = data.find((value) => value.distance >= distance)
            if (rec === undefined) {
                return data[data.length-1].timestamp;                        // got to the end of the list, so return that timstamp
            }
            else {
                return rec.timestamp;
            }
        }
        else {
            return 0;                                                       // no data to look through, can't set time
        }
    }

    /**
     * looks up the closest video time and returns the related distance.
     * @param {number} time the video time in seconds
     */
    timeToDistance(time) {
        const data = this.robotDs ? this.robotDs.data : null;                 // both recorder and player have a data field
        
        if (data && data.length > 0) {
            let rec = data.find((value) => value.timestamp >= time)
            if (rec === undefined) {
                return data[data.length-1].distance;                        // got to the end of the list, so return that timstamp
            }
            else {
                return rec.distance;
            }
        }
        else {
            return 0;                                                       // no data to look through, can't set time
        }
    }

    setCurrentTimestamp(value) {
        this.currentTime = value;
        timeService.current = value;

        // important: do first so that when any sensor-data views render again, they get the correct settings
        if (this.controlState !== "record") {                       // when playing or when in pause and user jumps, need to update the strand properties according to the current active (and passed) observations
            strandPropsService.updateFromObsList(activeProjectService.activeStreng, value);
        }        
        if (this.robotDs) {                                         // can be 0 when unloading, need to update the players so that they go to the correct pos and update the ui's
            this.robotDs.setCurrentTimestamp(value);
        }
        this.events.emit("onCurrentTime", value);
    }

    /**
     * emits the event onLengthChanged. Used by the controller to display the duration of a newly imported video.
     */
    emitLengthChanged() {
        this.events.emit("onLengthChanged");
    }

}

export const documentControlService = new DocumentControlService();