/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { dialogService } from '../../services/dialog_service';
import Snackbar from '@material-ui/core/Snackbar';
import {Alert, AlertTitle} from '@material-ui/lab';
import ConfirmationDialog from '../../shared_components/dialogs/confirmation_dialog';
//import { Typography } from '@material-ui/core';


const styles = (theme) => ({
    snackbarStyle: {
        flexDirection: 'column',
        alignItems: 'stretch'
    }
});


class Toast extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toasts: [],                         // need to have something in state so we force a repaint when the list has changed.
            questionOptions: null,              // if a question needs to be asked, these are all the required params
            isOpen: false
        }
        dialogService.onMessagesChanged = this.updateMessagesOnUI;
        dialogService.onAskQuestion = this.askQuestion;
    }

    componentDidMount() {
        this.updateMessagesOnUI();              // we need to make certain that all messages are loaded if there were already any in there.
    }

    componentWillUnmount() {
        if (dialogService.onMessagesChanged === this.updateMessagesOnUI) {
            dialogService.onMessagesChanged = null;
            dialogService.onAskQuestion = null;                             // no need to check anymore, if 1 handler is of this object, all are.
        }
    }

    render() {
        return (
            <React.Fragment>
                <Snackbar open={this.state.isOpen}
                    classes={{root: this.props.classes.snackbarStyle}}>
                    <React.Fragment>
                        {this.state.toasts.map((item, idx) => {
                            return(
                                <Alert onClose={this.closeToast2(item)} 
                                    key={idx}
                                    severity={item.severity}
                                    elevation={6}
                                    variant="filled">
                                    <AlertTitle>{item.title}</AlertTitle>
                                    {item.message}
                                </Alert>
                            )
                        })}
                        {/* this is required cause otherwise snacakbar gives a breaking error when all toasts are closed*/}
                        {(this.state.toasts.length === 0) && <div></div>}      
                    </React.Fragment>
                </Snackbar>
                {(this.state.questionOptions) && 
                    <ConfirmationDialog title={this.state.questionOptions.title}
                        question={this.state.questionOptions.question}
                        okText={this.state.questionOptions.okText}
                        cancelText={this.state.questionOptions.cancelText}
                        open={true}
                        onClose={this.closeDialog}
                    />
                }
            </React.Fragment>
        );
    }

    updateMessagesOnUI = () => {
        const toasts = dialogService.messages.slice(0);                 // make a copy of the list so the state always updates.
        this.setState({toasts, isOpen:toasts.length > 0 });
    }


    closeToast2 = (item) => () =>  {
        dialogService.removeMessage(item);          // this wil force a refresh of the list through handleMessagesChanged.
    }

    askQuestion = (options) => {
        this.setState({questionOptions: options});
    }

    closeDialog = (result) => {
        if (this.state.questionOptions.callback) {
            this.state.questionOptions.callback(result);
        }
        this.setState({questionOptions: null})
    }
}

export default withStyles(styles)(Toast);