/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import '../../../App.css';
//import PropTypes from 'prop-types';
import { BaseOverlay } from '../../overlays/base_overlay_component';


class WaterHeightMeasure extends BaseOverlay {
    constructor(props) {
        super(props);
    }

    
    buildSvgImage() {
        let size = this.ImageSize;
        const center = size / 2;
        const sectHeight = size/10;
        const sectHeightPart = sectHeight/2;
        const fullLineStyle = `stroke="white" stroke-width="1"`;
        const dashLineStyle = `stroke="white" stroke-width="1" stroke-dasharray="10,10"`;
        return (
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${size} ${size}">
            <defs>
                <clipPath id="clip">
                <circle cx="${center}" cy="${center}" r="${center}"/>
                </clipPath>
                <path id="icon" d="M22,4V2H2V4H11V18.17L5.5,12.67L4.08,14.08L12,22L19.92,14.08L18.5,12.67L13,18.17V4H22Z"/>
            </defs>
            <g clip-path="url(#clip)">
                <line x1="0" x2="${size}" y1="${sectHeight}" y2="${sectHeight}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*2}" y2="${(sectHeight)*2}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*3}" y2="${(sectHeight)*3}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*4}" y2="${(sectHeight)*4}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*5}" y2="${(sectHeight)*5}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*6}" y2="${(sectHeight)*6}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*7}" y2="${(sectHeight)*7}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*8}" y2="${(sectHeight)*8}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${(sectHeight)*9}" y2="${(sectHeight)*9}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${sectHeight-sectHeightPart}" y2="${sectHeight-sectHeightPart}" ${fullLineStyle}/>

                <line x1="0" x2="${size}" y1="${((sectHeight)*2)-sectHeightPart}" y2="${((sectHeight)*2)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*3)-sectHeightPart}" y2="${((sectHeight)*3)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*4)-sectHeightPart}" y2="${((sectHeight)*4)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*5)-sectHeightPart}" y2="${((sectHeight)*5)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*6)-sectHeightPart}" y2="${((sectHeight)*6)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*7)-sectHeightPart}" y2="${((sectHeight)*7)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*8)-sectHeightPart}" y2="${((sectHeight)*8)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*9)-sectHeightPart}" y2="${((sectHeight)*9)-sectHeightPart}" ${dashLineStyle}/>
                <line x1="0" x2="${size}" y1="${((sectHeight)*9)+sectHeightPart}" y2="${((sectHeight)*9)+sectHeightPart}" ${dashLineStyle}/>
            </g>
            <circle cx="${center}" cy="${center}" r="${center}" stroke="white" stroke-width="1" fill="transparent" />
            <use xlink:href="#icon" x="40" y="0" transform="scale(0.7)" stroke-width="0" fill="white"/>
        </svg>`);
    }

    internalHandleMouseDownOnImage(ev) {
        if (this.state.mode === "measure") {
            if (ev.button === 0 && ev.buttons === 1) {
                let pos = this.getRelMousePos(ev);
                pos = this.relativeToCenterImage(pos);

                let size = this.state.size;
                if (!size) {
                    size = this.props.height - 10;
                }
                if (pos.y < 0 || pos.y > size) { return; }                  // out of bounds
                let height = 100 / size * pos.y;
                if (this.props.onMeasured) {
                    this.props.onMeasured(100 - height);                    // we get the inverse number (so 70% empty, need to return 30% full)
                }
            }
        }
        else {
            super.internalHandleMouseDownOnImage(ev);
        }
    }
   

}

WaterHeightMeasure.propTypes = {
    
};

export default WaterHeightMeasure;