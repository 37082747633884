/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
//import { withTranslation } from 'react-i18next';
import TickedProgressBar from '../../../controls/ticked_progress_bar_component';


const styles = (theme) => ({
    root: {
        position: 'relative',
      },
      content: {
        top: "0px",
        bottom: "0px",
        position: "absolute",
        left: "0",
        right: "0",
        margin: "auto",
        width: "fit-content",
        height: 'fit-content',
      },
      value: {
          fontSize: 'x-small',
          color: theme.palette.text.secondary,
          textAlign: 'center'
      },
      icon: {
        color: theme.palette.text.secondary,
        height: "unset"
      },
      inner: {
        display: "flex",
        flexDirection: "column",
        marginBottom:'4px',
      }
});

const MAX_UPDATE_SPEED = 500;                       // nr milliseconds between ui updates

class Gauge extends React.PureComponent {
    constructor(props) {
        super(props);

        //let value = props.value  ?? 0;                  // make certain we have a value
        let value = 0;
        let valueTxt = value.toFixed(1);
        if (props.limits) {
            value -= props.limits.min;                 // the gauge ticks only start at min, so adjust
            if (value < 0) {
                value = 0;
            }
        }

        this.state = {
            value: value,                     // we show the value in respect to the minimum, so need to do an offset on the value
            valueTxt: valueTxt,
            ticks: this.buildTicks(props.limits),
            steps: props.limits ? props.limits.max - props.limits.min : 360,
        }

        if (props.handle) {
            props.handle(this);
        }
        this.throttleTimer = null;                  // set a top speed at how fast ui element is updated
        this.nextValue = null;                      // when throttling, need to know if and which value to set next
        this.nextValueTxt = null;
    }

    componentWillUnmount() {
        if (this.props.handle) {
            this.props.handle(null);
        }
    }

    componentDidMount() {
        if (this.props.handle) {
            this.props.handle(this);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.limits !== this.props.limits) {
            const limits = this.props.limits;
            if (limits) {
                this.setState({ticks: this.buildTicks(limits), steps: limits.max - limits.min});
            }
            else {
                this.setState({ticks: null, steps: 360});
            }
        }
    }


    render() {
        const { classes } = this.props;
        const imgSize = `${this.props.radius}px`;
        const Icon = this.props.image;                  // important Icon must be with capital cause that lets react know it is used as a component name instead of instance
        //TickedProgressBar
        const color = this.checkBounderies(null);
        return (
            <Tooltip title={this.props.tooltip}>
                {/* div is needed to show the tooltip */}
                <div style={{flex: '50%', position:'relative', display: 'flex', justifyContent:'center'}}>
                    <TickedProgressBar progress={this.state.value}
                        radius={this.props.radius}
                        cut={120}
                        rotate={-210}
                        steps={this.state.steps}
                        trackStrokeColor="#e6e6e6"
                        strokeColor={this.checkBounderies("#5d9cec")}
                        strokeLinecap="round"
                        trackTransition=""
                        strokeWidth={6}
                        trackStrokeWidth={2}
                        pointerStrokeWidth={2}
                        ticks={this.state.ticks}
                    >
                        <div className={classes.content}>
                            <div className={classes.inner}>
                                <div className={classes.value} style={{color: color}}>{this.state.valueTxt}</div>
                                {<Icon className={classes.icon} style={{ width: imgSize, color: color }}/>}
                            </div>
                        </div>
                    </TickedProgressBar>
                </div>
            </Tooltip>

        );
    }
    setValue = (value) => {
        value = value ?? 0;                             // make certain we have a value
        let valueTxt = value.toFixed(1);
        value -= (this.props.limits && this.props.limits.min) ? this.props.limits.min : 0;                 // the gauge ticks only start at min, so adjust
        if (value < 0) {
            value = 0;
        }
        
        if (value !== this.state.value) {
            if (!this.throttleTimer) {
  //              this.setState({value: value, valueTxt: valueTxt});
                this.createThrottleTimer();
            }
            else {
                this.nextValue = value;
                this.nextValueTxt = valueTxt;
            }
        }
    }

    createThrottleTimer() {
        this.throttleTimer = setTimeout(() => {
            if (this.nextValue) {
                this.setState({value: this.nextValue, valueTxt: this.nextValueTxt});
                this.nextValue = null;
                this.nextValueTxt = null;
                this.createThrottleTimer();
            }
            else {
                this.throttleTimer = null;
            }
        }, MAX_UPDATE_SPEED);
    }

    checkBounderies(asDefault) {
        const value = this.state.value;
        const limits = this.props.limits;
        if (limits) {
            if (value < limits.error.min - limits.min) {
                return 'red';
            }
            else if (value < limits.warning.min - limits.min) {
                return 'orange';
            }
            else if (value > limits.error.max - limits.min) {
                return 'red';
            }
            else if (value > limits.warning.max - limits.min) { 
                return 'orange';
            }
        }
        return asDefault;
    }

    buildTicks(limits) {
        if (limits) {
            let result = [];
            if (limits.min !== undefined) {
                result.push({value: 0, color: '#e6e6e6'})
            }
            if (limits.max !== undefined) {
                result.push({value: limits.max - limits.min, color: '#e6e6e6'})
            }
            if (limits.warning) {
                if (limits.warning.min !== undefined) {
                    result.push({value: limits.warning.min - limits.min, color: '#e6e6e6'})
                }
                if (limits.warning.max !== undefined) {
                    result.push({value: limits.warning.max - limits.min, color: '#e6e6e6'})
                }   
            }
            if (limits.error) {
                if (limits.error.min !== undefined) {
                    result.push({value: limits.error.min - limits.min, color: '#e6e6e6'})
                }
                if (limits.error.max !== undefined) {
                    result.push({value: limits.error.max - limits.min, color: '#e6e6e6'})
                }   
            }
            return result;
        }
        return null;
    }
}

Gauge.propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.number,
    tooltip: PropTypes.string,
    radius: PropTypes.number,
    handle: PropTypes.func,                         // used to let the parent get a ref to this object
    image: PropTypes.any,                           // the image that should be depicted. needs to be an asset path
    limits: PropTypes.object,            // object {min: x, max: x, warning: {min:x, max:x}, error: {min:x, max:x}} used to build the ticks and colors indicators
};

export default withStyles(styles)(Gauge);