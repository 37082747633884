/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { SonarPlayerBaseService } from '../../../../shared_components/document/instrumentation/sonarLidar/sonar_player_base_service';

/**
 * UI-process version of the TrackService. It is able to load data from file and receive
 * live recorded data from the TrackServerService (responsible for recording, runs in
 * engine process).
 */
export class SonarPlayerService extends SonarPlayerBaseService  {


}
