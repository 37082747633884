/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import CarClutch from 'mdi-material-ui/CarClutch';
import { NoClutchIcon } from '../../../icons';
import SpotlightBeam from 'mdi-material-ui/LedStrip';
import RobotMower from 'mdi-material-ui/RobotMower';

import React from 'react';
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import { getDashboardImg } from '../../../../services/image_service';
import SpeedController from './speed_controller_component';
import { remoteControlService, REMOTE_ACTIONS } from '../../../services/remote_control_service';
import TractorDirectionButtons from './tractor_direction_component';
import { documentControlService } from '../../document_control_service';


const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: "row",
    },
    col: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-between',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    button: {
        padding: 0,
        borderWidth: "1px",
    },
    tractorIcon: {
        height: '14px',
        width: '14px',        
        marginRight: -theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    
});


class RobotController extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isClutchOn: remoteControlService.isClutchOn ?? false,
            laserOn: remoteControlService.laserOn ?? false,
        }
        this.tractorImg = getDashboardImg('top.svg');
        const robot = documentControlService.robotDs;
        this.needsClutch = robot.needsClutch();
        this.hasClutch = robot.hasClutch();
        this.clutchActiveBtnsMap = robot.sensorLimits?.clutch ?? null;     // some devices (easysight amphibious) specify which direction buttons are allowed depending on the value of the clutch
        this.speedLinkMode = robot.speedMode();
        this.hasLaser = robot.hasLaserMeasure();

        this.txtClutch = props.t("Clutch on/off");
        this.txtLaser = props.t("Laser on/off");
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
    }

    render() {
        const { classes } = this.props;
        const isClutchOn = this.state.isClutchOn;
        const clutchIcon = isClutchOn ? <CarClutch fontSize="large"/> : <NoClutchIcon fontSize="large"/>
        const directionDisabled = this.hasClutch && this.needsClutch && (!isClutchOn);
        const allowedBtns = this.clutchActiveBtnsMap ? this.clutchActiveBtnsMap[isClutchOn] : null;
        let disabledReason = directionDisabled ? this.props.t("Clutch not on") : "";
        if (!disabledReason && allowedBtns) {
            disabledReason = this.props.t("This control is not allowed in the current state of the clutch");
        }
        const allowSpeedAdjust = this.speedLinkMode !== 'none'
        return (
            <div className={classes.root}>
                
                <RobotMower color="disabled" className={classes.tractorIcon} />

                <TractorDirectionButtons disabledButtons={directionDisabled} disabledReason={disabledReason} allowed={allowedBtns}/>

                <div className={classes.col}>
                    {(this.hasClutch) &&
                        <Tooltip title={this.txtClutch}>
                            <a>
                                <ToggleButton aria-label="clutch"
                                    id="clutch"
                                    value="clutch"
                                    color="default"
                                    selected={isClutchOn}
                                    disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.handleToggleClutch}
                                    classes={{root: classes.button}}
                                >
                                    {clutchIcon}
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }

                    {(this.hasLaser) &&
                        <Tooltip title={this.txtLaser}>
                            <a>
                                <ToggleButton aria-label="laser"
                                    id="laser"
                                    value="laser"
                                    color="default"
                                    selected={this.state.laserOn}
                                    disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.handleToggleLaser}
                                    classes={{root: classes.button}}
                                >
                                    <SpotlightBeam fontSize="large" />
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }

                </div>
                {(allowSpeedAdjust) && 
                    <SpeedController linkMode={this.speedLinkMode} disabled={allowedBtns && !allowedBtns.includes(REMOTE_ACTIONS.SPEED)} disabled2={allowedBtns && !allowedBtns.includes(REMOTE_ACTIONS.SPEED_REEL)}/>
                }
            </div>
        );
    }

    handleToggleClutch = () => {
        if (this.state.isClutchOn) {
            remoteControlService.requestState(REMOTE_ACTIONS.CLUTCH_OFF);
        }
        else {
            remoteControlService.requestState(REMOTE_ACTIONS.CLUTCH_ON);
        }
        this.setState({isClutchOn: !this.state.isClutchOn});
    }

    handleToggleLaser = () => {
        if (this.state.laserOn) {
            remoteControlService.requestState(REMOTE_ACTIONS.LASER_OFF);
        }
        else {
            remoteControlService.requestState(REMOTE_ACTIONS.LASER_ON);
        }
        this.setState({laserOn: !this.state.laserOn});
    }

    handleRemoteUpdate = (action, value) => {
        switch (action) {
            case REMOTE_ACTIONS.LASER_OFF:
                this.setState({laserOn: false});
                break;
            case REMOTE_ACTIONS.LASER_ON:
                this.setState({laserOn: true});
                break;
            case REMOTE_ACTIONS.CLUTCH_OFF:
                this.setState({isClutchOn: false});
                break;
            case REMOTE_ACTIONS.CLUTCH_ON:
                this.setState({isClutchOn: true});
                break;
            default:
                break;
        }
    }
}

RobotController.propTypes = {
};

export default withTranslation()(withStyles(styles)(RobotController));