/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import InspectionBar from './inspection/inspection_bar_component';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import { DocumentBaseComponent } from '../../components/document/document_base_component';
import CircularProgress from '@material-ui/core/CircularProgress';
import VideoSection from './video/video_section_component';
import RobotSectionBar from './instrumentation/robot_section_bar_component';
import ControllerBar from './instrumentation/controller/tablet/controller_bar_component';
import TabletCameraController from './instrumentation/controller/tablet/tablet_camera_controller_component';




const styles = (theme) => ({
    root: {                         //warning: can't add position and width here cause it gets overwritten then by other styles, need to apply position & width through 'style' on the element itself.
        display: 'grid',
        gridTemplateColumns: '58px auto 116px 58px',
        gridTemplateRows: 'auto 58px',
        gridTemplateAreas:`
                            'inspBar video controllerDetails robot'
                            'inspBar controller controller controller'
                          `,
        height: '100%',
        padding: theme.spacing(1)
    },
});


class TabletDocumentBody extends DocumentBaseComponent {

    constructor(props) {
        super(props);
        
        this.state = {
            videoHeight: window.innerHeight,            // for browser.localStorage, it returns a string, so make certain it is cnverted to number
            videoWidth: window.innerWidth,
            instrumentationHeight: null,                                            // the instrumentation box needs to know it's alloted height so it can calculate the size of the knobs (have fixed size), if we don't do this, we can't get the dashboard to size properly
        }
        this.VerplitPaneRef = React.createRef();
        this.inspectionBarRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowResized);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windowResized);
    }



    render() {
        const { t, classes } = this.props;
        if (this.props.readOnly === null) {                                       // important: this helps with the load of a project after start. if we don't do this, Inspections object gets rendered before data has been loaded. The inspection object doesn't change anything in the props, so id doesn't reload. same goes for a bunch of other stuff
            return(
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%', position: 'relative' }}> 
                <CircularProgress />
                {t("Loading project")}
            </Box>
            );
        }
        const liveFeed = this.props.canRecord || this.props.forceLiveFeed;
        return (

            <div className={classes.root} onClick={this.props.onToggleMinimalControls}>

                <VideoSection 
                    fullscreen={this.props.fullScreen}
                    readOnly={this.props.readOnly}
                    onToggleFullScreen={this.props.onToggleFullScreen}
                    videoWidth={this.state.videoWidth}
                    videoHeight={this.state.videoHeight}
                    liveFeed={(liveFeed) && !this.props.readOnly}
                    jumpToTime={this.props.jumpToTime}
                    videoControlState={this.props.videoControlState}
                    onCurrentTimeChanged={this.props.onCurrentTimeChanged}
                    onDurationChanged={this.props.onDurationChanged}
                    onEnded={this.props.onEnded}
                    activeStreng={this.props.activeStreng}
                    forceLiveFeed={this.props.forceLiveFeed}
                    onToggleForceLiveFeed={this.props.onToggleForceLiveFeed}
                    measureData={this.props.measureData}
                    onMeasured={this.handleMeasured}
                    onVideoLoaded={this.props.onVideoLoaded}
                    onSelectChannel={this.props.onSelectChannel}
                    initLoaded={this.props.initLoaded}
                    visible={true}
                    onOpenVideoScreen={this.props.onOpenVideoScreen}
                    minimalControls={this.props.minimalControls}
                    tabletMode={true}
                />

                <InspectionBar direction={this.props.direction}
                    innerRef={this.inspectionBarRef}
                    readOnly={this.props.readOnly}
                    liveFeed={liveFeed}
                    onDirectionChanged={this.props.onDirectionChanged}
                    minimalControls={this.props.minimalControls}
                    onToggleMinimalControls={this.props.onToggleMinimalControls}
                    onPositionChanged={this.props.onPositionChanged}
                    onStartMeasure={this.props.onStartMeasure}
                    onStopMeasure={this.props.onStopMeasure}
                    onStartMeasureAngle={this.props.onStartMeasureAngle}
                    onTerminated={this.props.onTerminated}
                    onStartMeasureDepth={this.props.onStartMeasureDepth}
                    onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                    onStartMeasureFreeform={this.props.onStartMeasureFreeform} 
                    activeStreng={this.props.activeStreng}
                />


                <RobotSectionBar liveFeed={liveFeed}
                    readOnly={this.props.readOnly}
                    direction={this.props.direction}
                    onDirectionChanged={this.props.onDirectionChanged}
                    changePosAllowed={this.props.videoControlState !== "record" && this.props.videoControlState !== "reverseTractor"}
                    onPositionChanged={this.props.onPositionChanged}
                    initLoaded={this.props.initLoaded}
                    onOpenVideoScreen={this.props.onOpenVideoScreen}
                />

                <ControllerBar buttonState={this.props.videoControlState}
                    onPositionChanged={this.props.onPositionChanged}
                    streng={this.props.activeStreng}
                    onButtonPressed={this.props.onControlChanged}
                    forceLiveFeed={this.props.forceLiveFeed}
                    canRecord={this.props.canRecord}
                    readOnly={this.props.readOnly}
                    minimalControls={this.props.minimalControls}/>

                <TabletCameraController hideControls={(this.props.readOnly && !this.props.forceLiveFeed) || (!this.props.canRecord && !this.props.forceLiveFeed) }
                    initLoaded={this.props.initLoaded}/>
            </div>


          
        );
    }

    handleMeasured = (value) => {
        if (this.props.onMeasured) {
            this.props.onMeasured(value);
        }
        if (this.inspectionBarRef.current) {
            this.inspectionBarRef.current.setState({showObsList: true});            // need to show the observation list again cause the measurement was done.
        }
    }

    windowResized = () => {
        this.setState({ 
            videoHeight: window.innerHeight,            // for browser.localStorage, it returns a string, so make certain it is cnverted to number
            videoWidth: window.innerWidth
        });
    }

}

TabletDocumentBody.propTypes = {
    onVideoLoaded: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    onDirectionChanged: PropTypes.func,
    direction: PropTypes.string,
    onPositionChanged: PropTypes.func,                   // when this observation is expanded, let the system go to the video point at which it was recorded.
    onStartMeasure: PropTypes.func,                 // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                  // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func,
    onTerminated: PropTypes.func,                   // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
    fullscreen: PropTypes.bool,
    onToggleFullScreen: PropTypes.func,
    canRecord: PropTypes.bool,
    forceLiveFeed: PropTypes.bool,                                  // true when user pressed the 'camera' button
    onToggleForceLiveFeed: PropTypes.func,
    videoControlState: PropTypes.any,
    onCurrentTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onEnded: PropTypes.func,
    activeStreng: PropTypes.any,

    onMeasured: PropTypes.func,
    measureData: PropTypes.any,
    onVideoLoaded: PropTypes.func,                                  // called when a new video has been assigned to the streng and the UI should reload the streng cause of new conditions (video available)
    onSelectChannel: PropTypes.func,                                // called when the selected channel is changed (the camera/video feed currently displaying in this video section)
    initLoaded: PropTypes.bool,                                     // allows us to reload the channels after initial load of project and engine
    jumpToTime: PropTypes.number,
    onControlChanged: PropTypes.func,
    onOpenVideoScreen: PropTypes.func,                      // callback for the button to open new screen for video

    minimalControls: PropTypes.bool,                                // when false, show sidebars, otherwise hide as much as possible
    onToggleMinimalControls: PropTypes.func,                        // called when clicked on body
};

export default withTranslation()(withStyles(styles)(TabletDocumentBody));

/*



*/