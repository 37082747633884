/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InlineSVG from 'svg-inline-react';
import Box from '@material-ui/core/Box';
import { toDegrees } from '../services/cal';


//icons
import ArrowTopRightBottomLeft from 'mdi-material-ui/ArrowTopRightBottomLeft';
import Cached from 'mdi-material-ui/Cached';


/**
 * 
 * @param {number} center 
 * @param {number} pos x,y to translate
 * @param {number} angle 
 */
function rotate(center, pos, angle) {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = (cos * (pos.x - center.x)) + (sin * (pos.y - center.y)) + center.x;
    const ny = (cos * (pos.y - center.y)) - (sin * (pos.x - center.x)) + center.y;
    return {x: nx, y: ny};
}

/**
 * similar to BaseMeasure, but instead of drawing lines, an svg is drawn that can be scaled. 
 */
export class BaseOverlay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.startPos = null;                               // the starting position of the mouse when a mouse action started (resize, move)
        this.mouseAction = null;                              // current action that the mouse is taking while in layout mode: resize or move
        this.corner = null;                                 // the current corner being moved
        this.state = {
            mode: 'layout',                                 // allowed values: 'layout=place overlay' & 'measure=perform measurement'
            layoutMode: 'resize',                           // switch between resize and rotate while doing layout
            x: props.overlayX,
            y: props.overlayY,
            size: props.overlaySize,
            angle: 0
        }
        this.rootRef = React.createRef();
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyboard, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.overlayX != this.props.overlayX && prevProps.overlayX === this.state.x) {
            this.setState({x: this.props.overlayX});
        }
        if (prevProps.overlayY != this.props.overlayY && prevProps.overlayY === this.state.y) {
            this.setState({y: this.props.overlayY});
        }
        if (prevProps.overlaySize != this.props.overlaySize && prevProps.overlaySize === this.state.size) {
            this.setState({size: this.props.overlaySize});
        }
    }

    async componentWillUnmount() { 
        window.removeEventListener('keydown', this.handleKeyboard, true);
    }


    /**
     * gets the size of the image.
     */
    get ImageSize() {
        return this.state.size ? this.state.size : this.props.height - 10;
    }

    handleKeyboard = (ev) => {
        if (ev.code == "esc" && ev.ctrlKey == false) {
            this.closeMeasure();
        }
    }

    calculateAngle(point, size, posX, posY) {
        let relPos = {x: point.x - (posX + (size/2)), y: (posY + (size/2)) - point.y};      //first convert startpos to x,y taht is relative to the center of the image (rotating point)
        let absRelPos = {x: Math.abs(relPos.x), y: Math.abs(relPos.y)};
        let angle = toDegrees(Math.atan(absRelPos.y/absRelPos.x));                                                    // angle = tan(x) = opposite/adjacent => x = math.cotan(y/x)
        //console.log(JSON.stringify(relPos));
        let q = 1;                                                                                                   // quadranet
        if (relPos.x == 0 && relPos.y > 0) {   //90
            angle = 90;
        }
        else if (relPos.x < 0 && relPos.y > 0) {   //q2
            angle = 180 - angle;
            q = 2;
        }
        else if (relPos.x < 0 && relPos.y == 0) {   //180
            angle = 180
            q = 2;
        }
        else if (relPos.x < 0 && relPos.y < 0) {   //q3
            angle = angle + 180;
            q = 3;
        }
        else if (relPos.x == 0 && relPos.y < 0) {   //270
            angle = 270
            q = 3;
        }
        else if (relPos.x > 0 && relPos.y < 0) {   //q4
            angle = 360 - angle;
            q = 4;
        }
        else if (relPos.x > 0 && relPos.y == 0) {
            q = 4;
            angle = 360;
        }
        //else is q 1
        return {angle: angle, pos: relPos, q: q};
    }

    /**
     * calculates the rotation angle that needs to be applied after the drag.
     * method:
     * 1. calculate angle of start point relative to center of image
     * 2. calcualte angle of end point relative to cent of image
     * 3. calcualte dif of 2 angles -> this is the difference that needs to be applied to the current angle value.
     * @param {object} ev event args
     */
    handleRotate(ev) {
        if (ev.button == 0 && this.startPos != null) {
            const size = this.ImageSize;
            const posX = this.state.x ? this.state.x  : (this.props.width - size) / 2;
            const posY = this.state.y ? this.state.y  : 5;
            this.tempPoint = this.getRelMousePos(ev);


            const start = this.calculateAngle(this.startPos, size, posX, posY);
            const end = this.calculateAngle(this.tempPoint, size, posX, posY)
            //console.log(`start: ${start.angle}, end: ${end.angle}`);
            let dif;
            if (start.q == 4 && end.q == 1) {
                dif = end.angle  + (360 - start.angle);
            }
            else if (start.q == 1 && end.q == 4) {
                dif = (360 - end.angle) + start.angle;
            }
            else {
                dif = end.angle - start.angle;
            }
            let result = this.state.angle - dif;
            if (result <= -360) {                                                       //always calculat only 1 turn
                result += 360;
            }
            else if (result >= 360) {
                result -= 360;
            }
            let cursor;
            let qSize = size/4;
            if (end.pos.x <= qSize && end.pos.x >= -qSize) {
                cursor = 'ew-resize';
            }
            else if (end.pos.y <= qSize && end.pos.y >= -qSize) {
                cursor = 'ns-resize';
            }
            else if ((end.pos.y >= 0 && end.pos.x < 0) || (end.pos.y <= 0 && end.pos.x > 0)) {
                cursor = 'nesw-resize';
            }
            else {
                cursor = 'nwse-resize';
            }
            this.rootRef.current.style.cursor = cursor;
            this.setState({angle: result});
            //console.log(result);
            this.startPos = this.tempPoint;                                             // othewise we add first values again and again
        }
    }

    render() {
        const boxStyle = {
            margin: 'auto',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            position: 'absolute',
            background: "transparent",
            borderWidth: "1px",
            borderColor: "blue",
            borderStyle: "solid",
            //mixBlendMode: "exclusion",
            overflow: "visible",
            zIndex: 10000,
        }
        if (this.props.needsMargin === false) {
            delete boxStyle.margin;
        }
        const iconStyle = {
            right: '10px',
            top: "10px",
            position: 'absolute',
            padding: '4px',
            color: 'black',
            width: '24px',
            height: '24px',
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '0px',
        }

        const size = this.ImageSize;
        const posX = this.state.x ? this.state.x  : (this.props.width - size) / 2;
        const posY = this.state.y ? this.state.y  : 5;
        const svgParentStyle = {
            width: size,
            height: size,
            left: posX,
            top: posY,
            overflow: 'hidden',
            position: 'absolute'
        };
        if (this.state.mode == "measure") {
            svgParentStyle['cursor'] = "crosshair";
        }
        else {
            svgParentStyle['cursor'] = 'all-scroll'
        }
        if (this.state.angle) {
            svgParentStyle['transform'] = `rotate(${this.state.angle}deg)`;
        }

        const image = this.buildSvgImage();
        return (
            <React.Fragment>
                <Box ref={this.rootRef} 
                    style={boxStyle}
                    width={this.props.width}
                    height={this.props.height}
                    onMouseUp={this.handleStop}
                    onMouseMove={this.handleMouseMove}
                >
                    <InlineSVG src={image} 
                        ref={this.imageRef}
                        element="div" 
                        style={svgParentStyle} 
                        onMouseDown={this.handleMouseDownOnImage}
                    />
                    {this.buildIcons(posX, posY, size)}
                    {(this.props.showCloseButton) && 
                        <IconButton
                            onClick={this.closeMeasure} 
                            style={iconStyle}>
                            &times;
                        </IconButton>
                    }
                </Box>
                {this.buildRenderObjects()}
            </React.Fragment>
        );
    }

    buildIcons(posX, posY, size) {

        let posYIcons =  posY < 12 ? size + posY - 24 : posY;            // put the icons below when going too high
        const checkStyle = {
            left: posX + size + 4,
            top: posYIcons,
            color: 'black',
            width: '24px',
            height: '24px',
            fontSize: '15px',
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '0px',
            
        }
        let leftTop, rightTop, leftBottom, rightBottom;
        if (this.state.layoutMode == "resize") {
            leftTop = "nwse-resize";
            rightTop = "nesw-resize";
            leftBottom = "nesw-resize";
            rightBottom = "nwse-resize";
        }
        else {
            leftTop = "nesw-resize";
            rightTop = "nwse-resize";
            leftBottom = "nwse-resize";
            rightBottom = "nesw-resize";
        }
        if (this.state.mode == "layout") {
            return (
                <React.Fragment>
                    <Box width="12px" height="12px" position="absolute" left={`${posX}px`}           top={`${posY}px`}           bgcolor="black" borderColor="white" border={1} style={{cursor:leftTop}} onMouseDown={this.handleStartResize('LT')} onMouseUp={this.handleStopResize} />
                    <Box width="12px" height="12px" position="absolute" left={`${posX}px`}           top={`${posY + size-12}px`} bgcolor="black" borderColor="white" border={1} style={{cursor:rightTop}} onMouseDown={this.handleStartResize('LB')}  onMouseUp={this.handleStopResize}/>
                    <Box width="12px" height="12px" position="absolute" left={`${posX + size-12}px`} top={`${posY}px`}           bgcolor="black" borderColor="white" border={1} style={{cursor:leftBottom}} onMouseDown={this.handleStartResize('RT')}  onMouseUp={this.handleStopResize}/>
                    <Box width="12px" height="12px" position="absolute" left={`${posX + size-12}px`} top={`${posY + size-12}px`} bgcolor="black" borderColor="white" border={1} style={{cursor:rightBottom}} onMouseDown={this.handleStartResize('RB')}  onMouseUp={this.handleStopResize}/>
                    <IconButton 
                        onClick={this.closeLayout} 
                        style={checkStyle}>
                        &#x2714;
                    </IconButton>
                    
                    {this.buildExtraIcons(checkStyle)}
                </React.Fragment>
            );
        }
        else {
            return(
                <React.Fragment>
                    {this.buildMeasureIcons(checkStyle)}
                </React.Fragment>
            );
        }
    }

    /**
     * returns the list of icons to show besides the one to close layout mode. 
     * normally returns the rotate icon. So if you override this without calling base, rotate is disabled.
     */
    buildExtraIcons(checkStyle) {
        let toolIcon;
        if (this.state.layoutMode == "resize") {
            toolIcon =  <Cached fontSize="inherit"/>;
        }
        else {
            toolIcon =  <ArrowTopRightBottomLeft fontSize="inherit"/>;
        }
        return (
            <IconButton 
                onClick={this.toggleLayoutMode} 
                style={checkStyle}>
                {toolIcon}
            </IconButton>
        );
    }
 
    /**
     * returns the list of icons to be displayed while measuring.
     */
    buildMeasureIcons(checkStyle) {
        return null;
    }

    /**
     * inheriters should re-implement this function. It should return the image that needs to be used.
     */
    buildSvgImage() {
        return "";
    }

    /**
     * if descendentants need any helper ui elements for calculating the result (like a canvas/image)
     * they can return them here.
     */
    buildRenderObjects() {
        return null;
    }

    closeLayout = (ev) => {
        this.internalCloseLayout();
        ev.preventDefault();
    }

    toggleLayoutMode = (ev) => {
        if (this.state.layoutMode == 'resize') {
            this.setState({layoutMode: 'rotate'});
        }
        else {
            this.setState({layoutMode: 'resize'});
        }
        ev.stopPropagation();
        ev.preventDefault();
    }

    handleStopResize = (ev) => {
        ev.preventDefault();
    }

    internalCloseLayout() {
        this.setState({mode: "measure"});
    }

    closeMeasure = (ev)  => {
        if (this.props.onMeasured) {
            this.props.onMeasured(null);
        }
        ev.stopPropagation();
        ev.preventDefault();
    }

    handleMouseDownOnImage = (ev) => {
        //console.log("mousedownonimage");
        this.internalHandleMouseDownOnImage(ev);
        ev.stopPropagation();
        ev.preventDefault();
    }

    /**
     * so we can have inheritance
     * @param {object} ev event data
     */
    internalHandleMouseDownOnImage(ev) {
        if (this.state.mode == "layout" && ev.button == 0 && ev.buttons == 1) {
            this.startPos = this.getRelMousePos(ev);
            this.mouseAction = "move";
        } 
    }

    handleStop = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        this.internalHandleStop(ev);
    }

    internalHandleStop(ev) {
        if (this.state.mode == "layout") {
            this.startPos = null;
            this.mouseAction = null;
            this.rootRef.current.style.cursor = '';
        }
    }

    handleMouseMove = (ev) => {
        this.internalMouseMove(ev);
    }

    internalMouseMove(ev) {
        if (this.state.mode == "layout" && ev.button == 0 && ev.buttons == 1) {
            if (this.mouseAction == "move") {
                this.handleMove(ev);
            }
            else if (this.mouseAction == "resize") {
                if (this.state.layoutMode == "resize") {
                    this.handleMoveResize(ev);
                }
                else if (this.state.layoutMode == "rotate") {
                    this.handleRotate(ev);
                }
            }
        }
    }

    handleMove(ev) {
        this.tempPoint = this.getRelMousePos(ev);
        let xDif = this.startPos.x - this.tempPoint.x;
        let yDif = this.startPos.y - this.tempPoint.y;

        const size = this.ImageSize;
        const posX = this.state.x ? this.state.x  : (this.props.width - size) / 2;
        const posY = this.state.y ? this.state.y  : 5;

        this.setState({x: posX - xDif, y: posY - yDif });
        this.startPos = this.tempPoint;                                             // othewise we add first values again and again
    }


    handleMoveResize(ev) {
        if (ev.button == 0 && this.startPos != null) {
            this.tempPoint = this.getRelMousePos(ev);
            let xDif = this.startPos.x - this.tempPoint.x;
            let yDif = this.startPos.y - this.tempPoint.y;
            let dif;
            //if (xDif === 0) { dif = yDif; }
            //else if (yDif === 0) { dif = xDif; }
            //else { dif =  Math.max(xDif, yDif); }

            const size = this.ImageSize;
            const posX = this.state.x ? this.state.x  : (this.props.width - size) / 2;
            const posY = this.state.y ? this.state.y  : 5;

            if (this.corner === "LT") {
                dif = (xDif + yDif) / 2;
                this.setState({x: posX - dif, y: posY - dif, size: size + dif });
            }
            else if (this.corner === "RT") {
                dif = (-xDif + yDif) / 2;
                this.setState({y: posY - dif, size: size + dif });
            }
            else if (this.corner === "LB") { 
                dif = (xDif - yDif) / 2;
                this.setState({x: posX - dif, size: size + dif });
            }
            else if (this.corner === "RB") {
                dif = (xDif + yDif) / 2;
                this.setState({size: size - dif});
            }
            this.startPos = this.tempPoint;                                             // othewise we add first values again and again
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    handleStartResize = (corner) => (ev) => {
        if (ev.button == 0 && ev.buttons == 1) {
            this.startPos = this.getRelMousePos(ev);
            this.mouseAction = "resize";
            this.corner = corner;
            this.rootRef.current.style.cursor = ev.target.style.cursor;
            ev.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
            ev.preventDefault();
        } 
    }


    /**
     * calculate the distance between point1 and point 2, than close the measure.
     * for distance calc, see: https://stackoverflow.com/questions/28986872/finding-distance-between-two-points-on-image-even-when-image-is-scaled
     */
    calculateDistance() {
        throw new Error("to implement");
    }

    /**
     * calculate the mouse position relative to the canvas.
     * @param {object} ev mouse event data
     */
    getRelMousePos(ev) {
        let rect  = this.rootRef.current.getBoundingClientRect();
        return { x: ev.clientX - rect.left, y: ev.clientY - rect.top };
    }

    /**
     * translates a point, relative to the root object so that it is relative to the  image with rotation taken into account.
     * @param {object} pos x, y coordinates
     */
    relativeToCenterImage(pos) {
        const size = this.ImageSize;
        let imageX  = this.imageRef.current.props.style.left;       // use value assigned to image, so we are always certain of correct value. state.posX might be undefined if image wasn't moved
        let imageY  = this.imageRef.current.props.style.top;
        pos = { x: pos.x - imageX, y: pos.y - imageY };
        if (this.state.angle) {
            pos = rotate({x: size/2,y :size/2}, pos, this.state.angle);
        }
        return pos;
    }
}

BaseOverlay.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    overlaySize: PropTypes.number,
    overlayX: PropTypes.number,
    overlayY: PropTypes.number,
    onMeasured: PropTypes.func,                 // callback, called when measuring is done. param: value, number: the measurement in mm, as converted by the calibration value
    showCloseButton: PropTypes.bool,
    needsMargin: PropTypes.bool,
};

BaseOverlay.defaultProps = {
    showCloseButton: true,
    needsMargin: true
}
