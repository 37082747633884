/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import momentUtils from "@date-io/moment";
import { codeSelectorService } from './code_selector_service';
import i18n from "i18next";
import { ProjectBaseService } from '../../services/project_base_service';
import { configService } from "../../services/config_service";

const moment = new momentUtils();

export function isEmpty(val) {
    return ( val === null || val === "" || val === undefined );
}


export function isEmptyOr0(val) {
    return ( val === 0 || val === null || val === "" || val === undefined );
}

export const PROJECT_VERSION = "1.2.2";
export const ERROR_KEYS = ["A-A","A-B", "A-C","A-D","A-E","A-F","A-G","A-H","A-I","A-J","A-K","A-L","A-M","A-N","A-O","A-P","B-A","B-B","B-C","B-D","B-E","B-F","B-G","B-H","D-D"]
export const TERMINATE_KEY = 'B-D-C';                                   // for pipe inspections
export const TERMINATE_KEY2 = 'D-D-C';                                  // for manhole inspections
export const CHANGE_SHAPE_KEY = 'A-E-C';
export const PIPE_INSPECTIONS = ['dp', 's'];                            // are similar in many ways 

class ProjectService extends ProjectBaseService {

    // constructor() {
    //     super();
    // }

    /**
     * create a new empty project data structure
     */
    createNew() {
        const mtime = moment.date(new Date());
        const codeInsp = configService.get("general.lastInspector") ?? "";
        //const equipment = configService.get("general.lastEquipmentSerialNr") ?? "";
        const purpose = configService.get("general.lastInspectionPurpose") ?? "A";
        let result = super.createNew();
        Object.assign(result, {
            creationDate: mtime.format("YYYY-MM-DD"),                           // primarily for internal use, so we know when the file was created. Also used to generate the name of the video file
            version: PROJECT_VERSION,
            main: {
                norm: "NBN EN 13508-2:2003 + A1:2011",
                managingInstance: "",
                location: "",
                codeInsp: codeInsp,
                purpose: purpose,
                opdrCodeOpdrGever: "",
                opdrCodeBedrijf: "",
                assistant: "",
                //equipmentSerialNr: equipment
            },
            strengs: [
                this.createNewStreng(1)
            ]
        });
        return result;
    }

    /**
     * creates a new streng
     * @param {string/nr} label to id the streng visually
     * @param {streng object} template optional data object that will first be copied into the new streng. this can be used to supply other default values. The observations list is always new.
     */
    createNewStreng(id, template=null) {
        const startObs = this.createNewObservation(0,0, ["B", 'D', 'B']);       // common for both cases, but could be not used (for template type m)
        const equipment = configService.get("general.lastEquipmentSerialNr") ?? "";
        startObs.remark = "Start";
        const mtime = moment.date(new Date());
        if (template) {
            let result = Object.assign({}, template);
            if (template.type === "dp") {
                result.observations = [
                    startObs,
                    this.createNewObservation(0,0, ['B', 'C', 'D', 'A'])
                ];
            }
            else {
                result.observations = [];
            }
            result.id = id;
            result.ref = id > 9 ? `L-${id}` : `L-0${id}`;
            result.inspectionDate = mtime.format("YYYY-MM-DD");
            result.inspectionTime = mtime.format("HH:mm:ss");
            result.depthStartPointRel = "999.00";                          // request by customer, should be reset
            result.depthEndPointRel = "999.00";                            // request by customer, should be reset
            result.actualInspectionLength = 0;
            result.reverseTractorOffset = 0;
            result.videoLength = 0;
            result.cams = {};
            return result;
        }
        return {
            type: 'dp',                                                    // supported values 'dp' (streng), 'm'(pit)
            id: id,
            observations: [
                startObs,
                this.createNewObservation(0, 0, ['B', 'C', 'D', 'A'])
            ],
            ref: id > 9 ? `L-${id}` : `L-0${id}`,
            startPointRef: '',
            startPointCoord: '',
            pointRef1: '',
            coordPoint1: '',
            pointRef2: '',
            coordPoint2: '',
            pointRef3: '',
            coordPoint3: '',
            distanceStartLateral: "0.00",
            clockPosStartLateral: "",
            startpointLateralInspection: '',
            lateralStrengRef: '',
            location: '',
            inspectionDirection: 'A',
            inspMethod: 'B',
            locationType: 'A',
            district: '',                                           // wijk
            sewerName: '',
            propertyOwnership: 'A',
            axialRefPoint: 'B',
            inspectionDate: mtime.format("YYYY-MM-DD"),
            inspectionTime: mtime.format("HH:mm:ss"),
            recStartTime: 0,                                                // the time at which recording started. We use this to make certain that the time displayed on the video is correct (for rendering videos)
            storageVideo: configService.isCloud ? 'Z' : 'F',
            storageVideoZ: 'cloud',
            storageImages: configService.isCloud ? 'Z' : 'E',
            storageImagesZ: 'cloud',
            positionVideoImages: 'A',
            imagePacketRef: '',
            expectedInspectionLength: "200.00",
            actualInspectionLength: 0,
            reverseTractorOffset: 0,                                        // the offset that needs to be substracted from 0 in order to get the real starting point of the reverse tractor record. This is used to put the correct labels (peak in chart corresponds to position in video)
            shape: 'A',
            height: "400",
            Width: "400",
            material: 'AE',
            liningType: '',
            liningMaterial: '',
            lengthOfTubePart: "2500",
            depthStartPointRel: "999.00",
            depthEndPointRel: "999.00",
            sewerType: 'A',
            sewerUsage: 'A',
            strategic: '',
            cleaning: 'A',
            remarks: '',
            yearOfCommisioning: "*UNKNOWN*",
            connectionType: 'A',
            //wallThickness: 0,
            //surfaceHardening: 'Steenslag',
            precipitation: 'A',
            temperature: 'A',
            fluidControl: 'A',
            coverLevel: "0.00",                                                  // for inspection of pits
            verticalRefPoint: 'A',
            CircularRefPoint: 'A',
            inspectionStage: 'B',
            litShape: 'B',
            litMaterial: 'AG',
            litHeight: "0",
            litWidth: "0",
            stepType: 'C',
            stepMaterial: 'A',
            videoLength: 0,                                                 // stored when recording. Used for rendering to xml. All video files of a streng are of the same length
            atmosphere: '',
            cams: {},                                                       // dict of camera def objets. For each camera channel an object under key 'channel' (name of cam). contains, widht, height, tonv,...
            tractor: {},                                                    // extra info stored about the tractor like limits. This is set when recording starts
            equipmentSerialNr: equipment
            /*
            old, is now replaced with 'cams', where multiple entries are allowed.
            videoWidth: 0,                                                  // not part of standard, but used by the system to quickly get these values for rendering stuff. Automatically filled in
            videoHeight: 0,                                                 // not part of standard, but used by the system to quickly get these values for rendering stuff. Automatically filled in
            tOnV: "",                                                       // default is empty, meaning not set. When video is recorded, specifies if tractor data was burned on the video during recordin or not.
            iOnV: "",                                                       // default is empty, meaning not set. When video is recorded and flag is true, first 10 sec of video contains summary details about inspection
            addI: "",                                                       // when true, report builder will add inspeciton info to start of video. only valid when iOnV=false
            addT: "",                                                       // when true, report builder will add tractor data to video. only valid when iOnV=false
            scalingFactor1: 0,                                              // camera calibration data for onscreen measurement, so settings are stored for recording, this way, global settings can change, measurement on video after recording stays accurate.
            scalingFactor2: 0,                                              // see scalingFactor1
            scalingDistance1: 0,                                            // see scalingFactor1
            scalingDistance2: 0,                                            // see scalingFactor1
            */
        };
    }

    /**
     * 
     * @param {number} time the position in the video at which the observation was made
     * @param {number} location: the distance traveled by the robot, so it can be rendered in the xml reports
     */
    createNewObservation(time, location, values=[], descriptiveLoc=null) {
        if (descriptiveLoc) {
            return {values: values, time: time, includePicture: false, joint: false, longitude: location, descriptiveLoc: descriptiveLoc}; //descriptiveLoc is only for pits
        }
        else {
            return {values: values, time: time, includePicture: false, joint: false, longitude: location}; 
        }
    }

    /**
     * checks if all the data is supplied for the observation (all fields are filled in).
     * @param {object} obs the observation data object.
     * @param {object} def the definition of the observation data.
     */
    isObservationComplete(obs, def, strengType) {
        if (obs && def) {
            const valueCount = codeSelectorService.getValueCount(def, strengType);
            const directionCount = codeSelectorService.getDirectionCount(def, strengType);
            if (def.requireType && def.requireType === "minOneOf") {
                if (isEmpty(obs.waarde1) && isEmpty(obs.waarde2)) {return false;}
            }
            else {
                if (valueCount > 0 && this.isObsValueRequired(def, 0, strengType) && isEmpty(obs.waarde1)) {return false;}
                if (valueCount > 1 && this.isObsValueRequired(def, 1, strengType) && isEmpty(obs.waarde2)) {return false;}
            }
            if (directionCount > 0 && this.isObsDirectionRequired(def, 0) && isEmpty(obs.direction1)) return false;
            if (directionCount > 1 && this.isObsDirectionRequired(def, 1) && isEmpty(obs.direction2)) return false;
            if (def.remark && isEmpty(obs.remark)) return false;
            return true;
        }
        return false;
    }

    isObsValueRequired(obsDef, idx, strengType) {
        const valueRequired = codeSelectorService.getValueRequired(obsDef, strengType);
        if ((valueRequired && valueRequired.length > idx && valueRequired[idx] === false)
            || (obsDef.requireType && obsDef.requireType==="minOneOf") ) {
            return false;
        }
        return true;
    }

    isObsDirectionRequired(obsDef, idx) {
        if (obsDef.directionRequired && obsDef.directionRequired.length >idx && obsDef.directionRequired[idx] === false ) {
            return false;
        }
        return true;
    }


    isMainComplete(data) {
        if(isEmpty(data.norm)) return false;
        if(isEmpty(data.managingInstance)) return false;
        if(isEmpty(data.location)) return false;
        if(isEmpty(data.codeInsp)) return false;
        if(isEmpty(data.opdrCodeBedrijf)) return false;
        if(isEmpty(data.purpose)) return false;
        if(data.purpose === "Z" && isEmpty(data.purposeZ)) return false;
        return true;
    }

    isStrengComplete(data) {
        if(isEmpty(data.ref)) return false;
        if (PIPE_INSPECTIONS.includes(data.type)) {
            if(isEmpty(data.pointRef1)) return false;
            if(isEmpty(data.pointRef2)) return false;
            if(isEmpty(data.inspectionDirection)) return false;
            if(isEmpty(data.axialRefPoint)) return false;
            if (data.axialRefPoint === "Z" && isEmpty(data.axialRefPointZ)) return false;
            if(isEmpty(data.sewerType)) return false;
        }
        else {
            if(isEmpty(data.litShape)) return false;
            if (data.litShape === "Z" && isEmpty(data.litShapeZ)) return false;
            if(isEmpty(data.litMaterial)) return false;
            if (data.litMaterial === "Z" && isEmpty(data.litMaterialZ)) return false;
            if(isEmpty(data.litWidth)) return false;

            if(isEmpty(data.stepType)) return false;
            if (data.stepType === "Z" && isEmpty(data.stepTypeZ)) return false;
        }
        if(isEmpty(data.location)) return false;
        if(isEmpty(data.locationType)) return false;
        if (data.locationType === "Z" && isEmpty(data.locationTypeZ)) return false;
        if(isEmpty(data.propertyOwnership)) return false;
        if(isEmpty(data.inspectionDate)) return false;
        if(isEmpty(data.inspectionTime)) return false;
        if(isEmpty(data.inspectionStage)) return false;
        if(isEmpty(data.inspMethod)) return false;
        if (data.inspectionStage === "Z" && isEmpty(data.inspectionStageZ)) return false;
        if(isEmpty(data.shape)) return false;
        if (data.shape === "Z" && isEmpty(data.shapeZ)) return false;
        if(isEmpty(data.height)) return false;
        if(isEmpty(data.material)) return false;
        if (data.material === "Z" && isEmpty(data.materialZ)) return false;
        if(isEmpty(data.sewerUsage)) return false;
        if (data.sewerUsage === "Z" && isEmpty(data.sewerUsageZ)) return false;
        if(isEmpty(data.cleaning)) return false;
        if(isEmpty(data.yearOfCommisioning)) return false;
        if (data.yearOfCommisioning === "year" && isEmpty(data.yearOfCommisioningY)) return false;
        if(isEmpty(data.precipitation)) return false;
        if(isEmpty(data.temperature)) return false;
        if(isEmpty(data.fluidControl)) return false;
        if (data.fluidControl === "Z" && isEmpty(data.fluidControlZ)) return false;
        return true;

    }
   

    checkVersion(result) {
        if (result.version === undefined) {
            throw new Error (i18n.t("not a project file"));
        }
        const versions = result.version.split('.');
        if (versions.length !== 3) {
            throw new Error (i18n.t("invalid version format"));
        }
        if (+(versions[0]) > 1) {
            throw new Error (i18n.t("newer project version"));
        }
    }

   
    /**
     * adds observations to a streng that are used to indicate the end of the recording.
     * @param {object} streng the streng to add observations to
     */
    addEndOfRecordingObservations(streng, videoPos, position) {
        let firstLetter = PIPE_INSPECTIONS.includes(streng.type) ? "B": "D";           // when pit, first letter is D, when streng, it's B 
        const terminatorKey = firstLetter === 'B' ? TERMINATE_KEY : TERMINATE_KEY2;                             // there is a different terminator for manhole and for pipe inspections
        const isTerminated = streng.observations.find(x => x.values.join('-').startsWith(terminatorKey));       // if the inspection was terminated with a B-D-C code, don't add 'end' items
        if (!isTerminated) {
            if (streng.type === "dp") {                                                                       // 'D' section dont have this code
                const descriptiveLoc = (streng.type === "m") ? 'A' : null;
                const endObs = this.createNewObservation(videoPos,position, [firstLetter, 'D', 'B'], descriptiveLoc);   // no start either
                endObs.remark = i18n.t("End");
                streng.observations.push(endObs);

                const cdObs = this.createNewObservation(videoPos,position, [firstLetter, 'C', 'E', 'A']);
                cdObs.waarde1 = streng.pointRef2;
                cdObs.waarde2 = streng.coordPoint2;
                streng.observations.push(cdObs);
            }
        }
    }

    closeContinuousObservations(streng, videoPos, position) {
        const lst = streng.observations.sort((a, b) => a.longitude - b.longitude);  // make certain that items are sorted on where they occur
        let runningObs = {};                                                        // dict of observations for which we found an end and are expecting a beginning, so we don't add closures for items that are already closed
        let changed = [];
        for(let i=lst.length-1; i>=0;i--) {
            const obs = lst[i];
            if (codeSelectorService.isContinuous(obs.values)) {
                if (obs.continuousIdx === undefined) {                              // something strange going on here.
                    continue;
                }
                let key = obs.values.join("").substr(0, 3) + obs.continuousIdx.toString();  
                if (obs.values.indexOf('1') > -1) {                                 // it's a terminator, set a runningObs
                    runningObs[key] = true;
                }
                else if (key in runningObs) {                                        // it's ok.
                    delete runningObs[key];
                }
                else { 
                    const newObs = this.createNewObservation(videoPos,position, [key[0], key[1], key[2], '1']);
                    newObs['continuousIdx'] = obs.continuousIdx;
                    streng.observations.push(newObs);
                    changed.push(newObs);
                }
            }
        }
        return changed;
    }

    /**
     * updates the last obs in the list with key BCD (not DCD cause that dont exist)
     * @param {object} streng the streng to process
     */
    updateStartObservation(streng) {
        if( streng.type === "dp"){
            let key = `B-C-D`;
            for(let i=streng.observations.length-1; i>=0; i--) {
                const obs = streng.observations[i];
                if(obs.values.join("-").startsWith(key)) {
                    let newObs = Object.assign({}, obs);                        // create a copy of the object, this way, the ui will update
                    newObs.waarde1 = streng.pointRef1;
                    newObs.waarde2 = streng.coordPoint1;
                    streng.observations[i] = newObs;
                    break;
                }
            }
        }
    }

    /**
     * get the video channel that has the index value 0 (primary) out of the 
     * dictionary of cameras.
     * @param {object} streng a streng object that contains the field 'cams'
     */
    getPrimaryChannel(streng) {
        let channel = null;
        for(channel in streng.cams) {                                           // find the primary channel (object has idx value 0)
            if(Object.keys(streng.cams).length === 1 || streng.cams[channel].idx === 0) {
                break
            };
        }
        return channel;
    }

    /**
     * returns a list of all the camera channels and their camera types. Used by video player to show selection box
     * @param {object} streng the streng object that contains a cams dict
     */
    getAllVideoChannelCamTypes(streng) {
        let result = [];
        for (let channel in streng.cams) {
            result.push({channel: channel, cameraType: streng.cams[channel].cameraType});
        }
        return result;
    }

    /**
     * removes any cloud information from the project, like id, pu
     * @param {object} project full project data
     */
    clearCloudFromProject(project) {
        delete project.id;
        delete project.uploadedAt;
        delete project.uploadedBy;
        delete project.cloudState;
        delete project.files;
        for(const streng of project.strengs) {
            delete streng.trackDataUrl;
            delete streng.trackRevDataUrl;
            delete streng.robotDataUrl;
            delete streng.robotRevDataUrl;
            delete streng.videoUrl;
            delete streng.lidarDataUrl;
            delete streng.lidarRevDataUrl;
            delete streng.sonarDataUrl;
        }
    }

    /**
     * calculates stats about the project and stores them in the project object.
     * This is for faster showing to the user. 
     * calculates: 
     * - unique street names (multiple strengs can be for the same street)
     * - maxErr: max nr of errors found in a streng
     * - totalErr: total nr of errors found in all strengs
     * - strengCount: nr of strengs
     * - strengsWError: nr of strengs with an error
     * @param project the project to process
     */
    calculateStats(project) {
        let locations = {};                                 //dict to quickly find duplicates
        let stats = {locations: [], 
            maxErr: 0,
            totalErr: 0,
            locationsTotalErr: [],                          // totalErrors per location, for faster rendering of info
            strengCount: 0,
            strengsWError: 0,
        };
        let errorCodeIdx = project.main.purpose == "A"? 0: 1;                                   // if new streng, use idx 0, otherwise error idx 1
        if (project.strengs) {
            stats.strengCount = project.strengs.length;
            for (const streng of project.strengs) {
                if (streng.location && !(streng.location in locations)) {
                    locations[streng.location] = 0;                                             // keep a count of the nr of errors per location
                    stats.locations.push(streng.location);
                }
                let strCount = 0;
                for(const obs of streng.observations) {
                    if (obs.values[obs.values.length-1] !== "1") {                          // if the last item in the key is 1, it's a terminator for continuous-observations, so skip that
                        let key = obs.values.join("-");
                        let found = ERROR_KEYS.find((value) => key.startsWith(value));            // find the first item in the list that's the same as the start of the key
                        const def = codeSelectorService.getLeaf(obs.values, streng.type);
                        if (found && def && def.errRating && def.errRating[errorCodeIdx]) {                                // found a problem observation
                            strCount++;
                            stats.totalErr++;
                            locations[streng.location]++;
                        }
                    }
                }
                if (strCount > 0) {
                    stats.strengsWError++;
                    if (strCount > stats.maxErr) {
                        stats.maxErr = strCount;
                    }
                }
            }
            for (const loc of stats.locations) {                                            // copy over all the counts for the streets in the correct order
                stats.locationsTotalErr.push(locations[loc]);
            }
        }
        return stats;
    }

}

export const projectService = new ProjectService();