/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import FilePdfOutline from 'mdi-material-ui/FilePdfBox';

//imports
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import MaterialTable from "material-table";
import { userService } from '../services/user_service';
import { withRouter } from 'react-router-dom';
import ConfirmationDialog from '../dialogs/confirmation_dialog';
import { dialogService } from '../../services/dialog_service';
import { errExtractor } from '../services/error_extractor';
import RecipientDetails from './recipient_details_component';
import EmailSendOutline from 'mdi-material-ui/EmailSendOutline';
import { configService } from '../../services/config_service';
import i18n from 'i18next';
import { tableIcons } from '../services/table_service';




const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    text: {
        textAlign: 'center',
        margin: '0px',
        wordBreak: 'break-word'
    },
    spinnerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: "absolute",
        left: '0px',
        top: '0px',
        right: '0px',
        bottom: '0px',
        background: '#ffffffBF',
        zIndex: 10000
    }

});

class DocsList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.dataToDelete = null;                                                   // we need to store this while asking the user to continue
        this.state = {
            isAskToDeleteOpen: false,
            deleting: false,                                                        // so we can show a spinner (and blok ui) while deleting.
            deleteProgression: 0,                                                   // to show actual progression of the deletion process.
        }
    }

    
    getColumns() {
        if (!this.props.columns || this.props.columns.length === 0) {
            const { t } = this.props;
            const start = [
                { title: t("Date published"), 
                  field: "publishedAt", 
                  type: "date", 
                  render: this.renderPublishedAt,
                  defaultSort: 'desc' },
                { title: t("Name"), field: "name", defaultSort: 'desc', width: 450,
                    cellStyle: {wordBreak: 'break-word'} 
                }
            ];
            const end = [
                { title: t("Locatie"), field: "location", grouping: true },
                { title: t("Beherende instantie"), field: "managingInstance", grouping: true },
                { title: t("Opdrachtref. inspecteur"), field: "opdrCodeBedrijf", grouping: true },
                { title: t("Opdrachtref. opdrachtgever"), field: "opdrCodeOpdrGever", grouping: true },
            ]
            if (configService.isViewer) {
                return [...start, 
                    { title: t("Creator"), field: "companyName", grouping: true },
                    ...end
                ];
            }
            else {
                return [...start, ...end];
            }
        }
        else {
            return this.props.columns;
        }
    }

    render() {
        const { t } = this.props;
        const isEditor = userService.getCurrentUser().role === userService.Editor;
        const columns = this.getColumns();
        const customActions = this.props.customActions;     
        let actions = [
            {
                action: rowData => ({
                    icon: () => <Visibility color="action"/>,
                    tooltip: t('Open details'),
                    onClick: this.props.onShowDetails
                }),
                position: "row"
            },
            {
                action: rowData => ({
                    icon: () => <FilePdfOutline color="action"/>,
                    tooltip: t('Show pdf report'),
                    onClick: this.props.onShowPdf,
                    disabled: this.props.onIsShowPdfDisabled(rowData)
                }),
                position: "row"
            },
            ...customActions
        ];
        let detailPanel = null;
        if (isEditor) {
            detailPanel = [{
                                icon: () => <div style={{ width: '0px' }} />,
                                render: this.renderRecipients,
                            }];
            actions.push({                                          // edit list of recipients is only allowed by editors
                action: this.buildRecipientsToggle,
                position: "row"
            });
            if (this.props.onDeleteDocument) {
                actions.push( {
                    icon: () => <DeleteOutline color="action"/>,
                    tooltip: t('Delete report'),
                    onClick: (event, data) => this.askDelete(data)
                });
            }
        }
        let infoBoxVisibility = 'hidden';
        if (this.state.deleting) {
            infoBoxVisibility = 'visible';
        }
        return (
            <React.Fragment>
                <MaterialTable
                    icons={tableIcons}
                    columns={columns}
                    data={this.props.reports}
                    detailPanel={detailPanel}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: t('Actions')
                        },
                        body: {
                            emptyDataSourceMessage: this.props.noValuesText,
                            filterRow: {
                                filterTooltip: t('Filter')
                            }
                        }
                    }}
                    options={{
                        search: true,
                        showTitle: false,
                        actionsColumnIndex: -1,
                        selection: isEditor,
                        showTextRowsSelected: false,
                        detailPanelColumnAlignment: 'right',
                    }}
                    actions={actions}
                />
                <ConfirmationDialog title={t("Delete")} 
                    question={t("ask_to_delete_reports")} 
                    open={this.state.isAskToDeleteOpen}
                    onClose={this.handleDeleteDlgClose}
                />
                <Box className={this.props.classes.spinnerBox}
                    visibility={infoBoxVisibility}>
                    <CircularProgress size={24} variant="static" value={this.state.deleteProgression}/>
                    <Typography variant="h4" style={{marginTop: "15px"}}>
                        {t("Deleting reports on server...")}
                    </Typography>
                </Box>
            </React.Fragment>
        );
    }

    renderPublishedAt = row => {
        //let d = moment.parse(row["publishedAt"])
        let d = new Date(row["publishedAt"]);
        d = d.toLocaleDateString(i18n.language, {});
        return (<span>{d}</span>);
    }

    buildRecipientsToggle = (rowData) => {
        const { t } = this.props;
        let sendToColor = 'secondary';
        let sendToTip = 'There are no report recipients defined yet';
        if (rowData.recipients && rowData.recipients.length > 0) {
            sendToColor = 'action';
            sendToTip = 'Send report to';
        }
        return ({
            icon: () => <EmailSendOutline color={sendToColor} />,
            tooltip: t(sendToTip),
            onClick: (event, rowData) => this.toggleDetails(rowData),
        })
    }

    /**
     * ask the user if he wants to delete all the selected items.
     * @param {list} data list of selected report objects.
     */
    askDelete = (data) => {
        this.dataToDelete = data;
        this.setState({isAskToDeleteOpen: true });
    }

    handleDeleteDlgClose = async (result) => {
        this.setState({isAskToDeleteOpen: false });
        if (result) {
            this.setState({deleting: true, deleteProgression: 0});
            let nrDeleted = 0;
            for(const item of this.dataToDelete) {
                try {
                    await this.props.onDeleteDocument(item);
                    nrDeleted += 1;
                    this.setState({deleteProgression: this.dataToDelete.length / 100 * nrDeleted});     // always needs to be expressed in %
                }
                catch(error) {
                    const { t } = this.props;
                    dialogService.error(t("Delete"), t("failed_to_delete_report", {error: errExtractor.get(error), doc: item.id}));
                }
            }
            this.setState({deleting: false});
        }
    }

    toggleDetails = (rowData) => {
        if(rowData.tableData.showDetailPanel) {
            rowData.tableData.showDetailPanel = '';
        }
        else {
            rowData.tableData['showDetailPanel'] = this.renderRecipients;
        }
        this.forceUpdate();
    }

    /**
     * renders the list of recipients currently defined for the project. Allows add/remove
     * @param {object} rowData the data for a single file
     */
    renderRecipients = (rowData) => {
        return (
            <Box padding="16px" paddingLeft="24px" paddingRight="24px" style={{backgroundColor:"rgb(224, 224, 224)"}} >
                <RecipientDetails 
                    value={rowData.recipients} 
                    invalidValues={rowData.invalidRecipients} 
                    valuesKey={rowData.managingInstance}
                    onValueChanged={(ev, value) => this.handleRecipientsChanged(rowData, value)}
                />
            </Box>
        );
    }

    /**
     * either the list has shrunk by 1 or grown by 1, detect what happened and react
     * @param {object} rowData project data
     * @param {array} value new list of emails
     */
    async handleRecipientsChanged(rowData, value) {
        // if the list of recipients goes from empty to not empty or visa versa, we need to update the full ui so that the icon is updated
        const needsUpdateUI = ((!rowData.recipients || rowData.recipients.length === 0) && value.length > 0) || (rowData.recipients && rowData.recipients.length > 0 && value.length === 0);

        let difference;
        let direction;
        if (rowData.recipients && rowData.recipients.length > value.length) {
            difference = rowData.recipients.filter(x => !value.includes(x));        // get difference, return all elements that are in rowData.recpients, not in value (so that have been removed)
            direction = 'removed';
        }
        else if (rowData.recipients) {
            difference = value.filter(x => !rowData.recipients.includes(x));
            direction = 'added';
        }
        else {
            difference = value;
            direction = 'added';
        }
        rowData.recipients = value;                                                 // important: do before event, cause this is the way that they can get the full list and not just the dif
        if (this.props.onRecipientsChanged) {
            await this.props.onRecipientsChanged(rowData, difference, direction);
        }    
        if (needsUpdateUI) {
            this.forceUpdate();
        }
    }
}

DocsList.defaultProps = {
    customActions: []
}

DocsList.propTypes = {
    noValuesText: PropTypes.string,
    reports: PropTypes.array,
    onDeleteDocument: PropTypes.func,                           // called async, for deleting a single document.
    onShowDetails: PropTypes.func,
    onShowPdf: PropTypes.func,                                  // called when the action to show the pdf file for a project is triggered
    columns: PropTypes.array,                                   // columns to be shown. Are set by default
    customActions: PropTypes.array,                             // extra actions that need to be added
    onRecipientsChanged: PropTypes.func,              // let caller store the value.
    onIsShowPdfDisabled: PropTypes.func                         // called to check if a project has a pdf file to show (in cloud or on disk)
};

export default withTranslation()(withStyles(styles)(withRouter(DocsList)));