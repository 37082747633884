/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { codeSelectorService } from '../../../services/code_selector_service'
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

//icons

import Magnify from 'mdi-material-ui/Magnify';
import ChevronRight from 'mdi-material-ui/ChevronRight';
import ChevronDown from 'mdi-material-ui/ChevronDown';

const styles = (theme) => ({
    root: {
    },
    iconButton: {
        marginLeft: theme.spacing(1)
    },
    treeItem: {
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    selectedTreeItem: {
        alignItems: 'center',
        display: 'flex',
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    TreeItemInPath: {
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        fontWeight: 'bold'
    }
});

function PaperComponent(props) {
    return (
        <Draggable handle="#simple-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}


class CodeSelectorTreeDlg extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dlgOpen: false,
            values: [...props.values],                                                              // make a copy of the list so we can modify it while searching for a new value.
            canPressOk: codeSelectorService.isLeaf(props.values, props.strengType)                  // only true when a full path is selected (last item in path has no children)
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown, true);
        if (this.props.isOpen === true) {
            this.setState({dlgOpen: true});                                                     // do after create, otherwise we get stuck in a state update loop that react cant handle
            if (this.props.onTreeOpened) this.props.onTreeOpened();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        if (e.key =="F3") {
            this.setState({ dlgOpen: true });
        }
        else if (this.state.dlgOpen === true) {
            if (e.keyCode === 13) {                                 // enter
                e.preventDefault();
                e.stopPropagation();                                // stop from going further, the dialog is open, so codeSelector no longer needs to handle it further, which would otherwise happen, so he would show the popupmenu
                if (this.state.canPressOk) this.handleOk();
            }
            else if(e.keyCode === 27) {                             // esc
                this.handleTreeClose();
                e.stopPropagation(); 
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values != this.props.values) {
            this.setState({values: this.props.values, canPressOk: codeSelectorService.isLeaf(this.props.values, this.props.strengType)});
        }
        if (prevState.dlgOpen != this.state.dlgOpen && this.state.dlgOpen === true && this.props.onTreeOpened) {
            this.props.onTreeOpened();
        }
    }

    render() {
        const { t } = this.props;
        let tree = null;
        if(this.state.dlgOpen == true) {
            tree = this.renderTree(this.props.tree);
        }
        return (
            <React.Fragment>
                <Tooltip title={t("Open boomstructuur om waarnemingstype te zoeken (F3)")}>
                    <IconButton size='small'
                        className={this.props.classes.iconButton}
                        onClick={this.showTreeSearch} >
                        <Magnify fontSize='small' />
                    </IconButton>
                </Tooltip>
                <Dialog onClose={this.handleTreeClose} aria-labelledby="simple-dialog-title" open={this.state.dlgOpen} PaperComponent={PaperComponent}>
                    <DialogTitle style={{ cursor: 'move' }} id="simple-dialog-title">{t("Zoek type waarneming")}</DialogTitle>
                    <DialogContent style={{overflow:'auto'}}>
                        {tree}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleTreeClose} >
                            {t("Sluiten")}
                        </Button>
                        {(this.props.showOk) && 
                            <Button onClick={this.handleOk} color="primary" disabled={!this.state.canPressOk}>
                                {t("Ok")}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    /**
     * 
     * @param {object} parent the parent of the current list that needs to be
     * @param {int} level the idx into the values list that we are currently
     */
    renderTree(parent, level = 0, idx={value: 0}) {                         // using an object for the idx, so we can pass it along byref (let the same var increment over multiple calls)
        const { t } = this.props;
        if (!parent) {                                                      // parent can be null
            return null;
        }
        let itemStyle;
        if (level > 0) {
            itemStyle = {marginLeft: `${level * 15}px`}
        }
        else {
            itemStyle = {marginLeft: `0px`}
        }
        let result = parent.children.filter(child => {
            return codeSelectorService.matchesFilter(child, this.props.strengType);
        }).map((child) => {
            idx.value++;
            if (codeSelectorService.hasChildren(child, this.props.strengType)) {
                let isExpanded = false;
                if (this.state.values.length > level) {
                    let val = this.state.values[level];
                    isExpanded = (val !== null && val.toLowerCase() == child.value.toLowerCase());       // val could be null if something went wrong during deletes
                }
                if (isExpanded) {
                    let children = this.renderTree(child, level + 1, idx);
                    return(
                        <React.Fragment>
                            <div key={idx.value} className={this.props.classes.TreeItemInPath} style={itemStyle} onClick={this.removeItem(level)}>
                                <ChevronDown/>
                                {child.value}={t(child.label)}
                            </div>
                            {children}
                        </React.Fragment>
                    );
                }
                else {
                    return(
                        <div key={idx.value} className={this.props.classes.treeItem} style={itemStyle} onClick={this.selectItem(child, level)}>
                            <ChevronRight/>
                            {child.value}={t(child.label)}
                        </div>
                    );
                }
            }
            else {
                let classname = this.props.classes.treeItem;
                if (this.state.values.length > level && this.state.values[level].toLowerCase() == child.value.toLowerCase()) {
                    classname = this.props.classes.selectedTreeItem;
                }
                return(
                    <div key={idx.value} className={classname} style={itemStyle} onClick={this.selectLeaf(child, level)}>
                        <ChevronRight style={{visibility: 'hidden'}}/>
                        {child.value}={t(child.label)}
                    </div>
                )
            }
        });
        return result;
    }

    removeItem = (level) => () => {
        let newValues = this.state.values.slice(0, level);
        this.setState({values: newValues, canPressOk: false});
        this.props.onRemoveItems(level);
    }


    selectItem = (child, level) => () => {
        let newValues = [...this.state.values.slice(0, level), child.value];
        this.setState({values: newValues, canPressOk: false});
        this.props.onSetItem(newValues, false);
    }

    selectLeaf = (child, level)=> () => {
        let newValues = [...this.state.values.slice(0, level), child.value];
        this.setState({values: newValues, canPressOk: true});
        this.props.onSetItem(newValues, true);
    }

    showTreeSearch = () => {
        this.setState({ dlgOpen: true });
    }

    handleTreeClose = () => {
        this.setState({ dlgOpen: false });
    }

    handleOk = () => {
        this.setState({ dlgOpen: false });
        //this.props.onValuesChanged(this.state.values);
        if (this.props.onClose) this.props.onClose();

    }
}

CodeSelectorTreeDlg.defaultProps = {
    showOk: true
}

CodeSelectorTreeDlg.propTypes = {
    values: PropTypes.array.isRequired,
    tree: PropTypes.object,
    onRemoveItems: PropTypes.func.isRequired,
    onSetItem: PropTypes.func.isRequired,
    onTreeOpened: PropTypes.func,                    // used to reset that the tree must be opened (after it has been opened, the user must be able to close it again.)
    strengType: PropTypes.string,                            // dp = streng, m = put, determins which fields are visible
    onClose: PropTypes.func,
    showOk: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(CodeSelectorTreeDlg));