/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import Settings from 'mdi-material-ui/Cog';
 
import React from 'react';
import '../../App.css';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CloudDocsList from '../../shared_components/documents/cloud_docs_list_component';
import DocumentsBar from '../../shared_components/documents/documents_bar_component';
import { Copyright } from '../../shared_components/start/copyright_component';
import Dashboard from '../../shared_components/documents/dashboard/dashboard_component';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import { configService } from '../../services/config_service';
import { downloadResource } from '../../shared_components/services/download_service';


const styles = (theme) => ({
    root: {
        minHeight: '100vh',
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
    },
    docRoot: {
        flex: 1
    },
    logo: {
        height: '70px',
        pointerEvents: "none"
   }

});


class Home extends React.PureComponent {
    constructor(props) {
        super(props);

        let selectedTab = configService.get("general.homeTab") ?? 'index';
        this.state = {
            selectedTab: selectedTab
        }
    }

    /**
     * load data here, constructor gets called multiple times, causing data to be loaded multiple times
     */
    componentDidMount() {
    }


    render() {
        const { t } = this.props;
        let tabs = [
            <Tab key="index"  label={t("Index")}  value="index"/>,
            <Tab key="dashboard"  label={t("Dashboard")}  value="dashboard"/>
        ];
        return (
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" className={this.props.classes.root}>
                <DocumentsBar
                    currentTab={this.state.selectedTab}
                    tabs={tabs}
                    onTabChanged={this.handleTabChange}
                    toolbarBtns={[
                        <Tooltip title={t("Settings")} key={4}>
                            <IconButton
                                aria-label="settings"
                                onClick={() => this.props.history.push('/settings')}>
                                <Settings />
                            </IconButton>
                        </Tooltip>
                    ]}/>
                {(this.state.selectedTab === "index") &&
                    <CloudDocsList onOpenPdf={(url) => window.open(url, "_blank")}
                        onDownloadFiles={this.handleDownloadFiles}/>
                }
                {this.state.selectedTab === "dashboard" && 
                    <Dashboard />
                }
                <Box mt={2} >
                    {Copyright(t)}
                </Box>
            </Box>
        );
    }

    handleDownloadFiles = (urls) => {
        for(const url of urls) {
            if(url.includes(".zip")) {
                window.open(url);
            }
            else {
                downloadResource(url);
            }
        }
    }

    handleTabChange = (ev, value) => {
        configService.set("general.homeTab", value);
        this.setState({selectedTab: value});
    }
}

export default withTranslation()(withStyles(styles)(Home));