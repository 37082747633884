/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import '../../App.css';
import {  withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
//import IconButton from '@material-ui/core/IconButton';
import Account from '../auth/account_component';
import PropTypes from 'prop-types';
import { getLogoImg } from '../../services/image_service';

// icons
// import MoreIcon from '@material-ui/icons/MoreVert';

// images

const styles = (theme) => ({
    root: {

    },
    title: {

    },
    logo: {
        height: '28px',
        marginRight: theme.spacing(2),
        pointerEvents: "none"
    },
   
});


/**
 * toolbar for managing documents (home page)
 */
class DocumentsBar extends React.Component {
    // constructor(props) {
    //     super(props);
    // }


    render() {
        //const { t } = this.props;
        const body = this.renderBody();
        const logoImg = getLogoImg();
        return (
            <AppBar position="sticky" color="default">
                <Toolbar variant="dense">
                    {this.props.showLogo && 
                        <img key="img" src={logoImg} className={this.props.classes.logo} alt="logo" />
                    }
                    {body}
                    <div style={{ flexGrow: 1 }} />
                    {this.props.toolbarBtns}
                    <Account key="acount" canLogOut={this.props.canLogOut}/>
                    {/* <IconButton key="more" edge="end" color="inherit">
                        <MoreIcon />
                    </IconButton> */}
                </Toolbar>
            </AppBar>
        );
    }

    renderBody() {
        if (this.props.tabs && this.props.tabs.length > 0) {
            return (
                <Tabs key="tabs" onChange={this.props.onTabChanged} value={this.props.currentTab}>
                    {this.props.tabs}
                </Tabs>
            );
        }
        else {
            return (
                <Typography key="title" variant="h6" className={this.props.classes.title}>
                    Projects
                </Typography>
            )
        }
    }
}
DocumentsBar.propTypes = {
    canLogOut: PropTypes.bool,                      // desktop app can't log out, cause license determins who is logged in.
    showLogo: PropTypes.bool,
    tabs: PropTypes.array,                          // list of Tab objects
    currentTab: PropTypes.any,                      // value of currently selected tab
    onTabChanged: PropTypes.func,                   // callback for when tab value has changed.
    toolbarBtns: PropTypes.any                      // list of buttons that need to be shown on toolbar on the right side
};

DocumentsBar.defaultProps = {
    showLogo: true,
    canLogOut: true
}

export default withTranslation()(withStyles(styles)(DocumentsBar));