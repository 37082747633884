/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


export const TOTAL_HOR_MARKERS = 10;                                    // nr of horizontal markers to show.
export const TOTAL_VER_MARKERS = 10;                                    // nr of horizontal markers to show.

export const SIDE_BORDER_SIZE = 40;

function getTotalVerMarkers(noHeight, height) {
    let totalVerMarkers;
    if (noHeight) {
        totalVerMarkers = 2;
    }
    else if (height < 80) {
        totalVerMarkers = Math.trunc(TOTAL_VER_MARKERS / 4);
    }
    else if (height < 180) {
        totalVerMarkers = Math.trunc(TOTAL_VER_MARKERS / 2);
    }
    else {
        totalVerMarkers = TOTAL_VER_MARKERS
    }
    return totalVerMarkers;
}

/**
 * a generic interface for building an svg image based on track data.
 * can be used to build live data as well as ofline.
 * Alsways works on the activeProjectServices.activeStrengIdx -> so use this to control the streng that it renders for (remainder of original code where it was part of the component, can use a little refactoring)
 */
export class SvgTrackService  {
    constructor() {
        this.videoPosDistance = 0;                              // the distance traveled for the current position in the video.
        this.videoPosDistanceIdx = -1;                           // index of the current record in the data set closest to the current time (from props)
        this.dataWidth = 0;                                     // corresponds to total distance that the robot drove. This corresponds to the accumulated value of the last data point. This allows us to easily add points dynamically.
        this.renderedDataWidth = 0;                             // during recording, the width of the svg is a little bigger than the actual datasize. This is the size, expressed in data units, of the svg's width. (used to calculate the adjustment factor for values and to see if a full rebuild is required or not).
        this.renderedDataHeight = 0;                            // dataheight is max-min, but during live recording, we make the margin a little bigger, so we don't need to rerender all the time.
        this.dataHeight = 0;                                    // height of last point in the data path. This allows us to easily add points dynamically.
        this.dataHeightMin = Number.MAX_SAFE_INTEGER;           // need a large number to start with
        this.dataHeightMax = 0;
        this.widthAdjuster = 0;                                 // x = (total-svg-width/total-data-width) * x -> for during recording, so we can adjust the new data on the fly adjusted to the screensize
        this.heighthAdjuster = 0;                               // see widthAdjuster
        this.path = "";                                         // the string representation of the path so far, allows us to append to it.
        this.rangePath = null;                                  // for the full range
        this.standingWaterPaths = null;                         // list of paths to be rendered as places with standing water. 
        this.firstPoint = null;                                 // the first point in the line. so we can easily draw the ideal line 
        this.lastPoint = null;                                  // the liast point in the line. so we can easily draw the ideal line (when not in real time)
        this.lastHeight = 0;                                    // the height at the last point, this is needed when we want to show start & end labels and there was no adjustment of the height according to the streng
        
        this.svgHeight = 0;                                     // although any change for svgHeight & svgWidth triggers a rerender, these are not state props, cause whenever any of these change, the svg needs to be rebuild, so the path is updated, this is enough of a trigger.
        this.svgWidth = 0;
        this.isUpstream = false;                                  // determins the direction of the inspection, so we can interpret the data correctly
        this.liveFeed = false;                                  // need to know this for rendering data
        this.adjustHeightDif = false;                           // when true, the end of the recording has to match the expected value (depthStartPointRel/depthEndPointRel). To get there, all points along the line are adjusted accordingly. (Important: this needs to be different for reverse recordings) When false, no adjustement is done.
        this.distanceStart = 0;                                 // for labeling, the start of the x axis

        this.renderVideoPos = true;                             // set this to false for reports that don't need video pos info
        this.renderIdealLine = true;                            // show the ideal line.
        this.renderMarkers = false;                             // when true, text markers are rendered.
        this.renderStandingWater = false;                       // show the places where standing water is found, only available when not live
        this.renderLabels = false;                              // when true, labels will be added to the image
        this.renderStartEndLabels = false;                      // when true, the exact value of the start & end points will be shown.
        this.renderSeriesOutline = true;                        // when true, renders the gray series line that includes the errors
        this.renderSeriesSimple = true;                         // when true, renders the single pixel line of the series (ideal line)

        this.data = null;                                       // has to be assigned by the parent object
        this.streng = null;                                     // ref to the streng we are rendering for, controlled by the parent object
        this.xLabels = null;                                    // if assigned, should be a list of objects with {distance, label} values, where distance is expressed in flattened values. It is not garanteed that all values are shown, this depends on the width of the view
    }

    get width() {
        let width = +this.svgWidth;
        if (this.renderStartEndLabels) {
            width -= SIDE_BORDER_SIZE;
        }
        if (this.renderMarkers) {
            width -= SIDE_BORDER_SIZE;
        }
        return width;
    }


    /**
     * when switching from streng, need to make certain that the calculated data is reset 
     * before calculating stuff again (primarily for min & max values.)
     */
    resetData() {
        this.resetCalculatedData();
        this.videoPosDistance = 0;
        this.videoPosDistanceIdx = -1;
        this.data = null;
    }

    /**
     * reset the data that was calculated while rendering the svg.
     * this allows us to recalculate things
     */
    resetCalculatedData() {
        this.firstPoint = null;
        this.lastPoint = null;
        this.dataWidth = 0; 
        this.renderedDataWidth = 0;
        this.renderedDataHeight = 0;
        this.dataHeight = 0; 
        this.dataHeightMin = Number.MAX_SAFE_INTEGER;           // need a large number to start with
        this.dataHeightMax = 0;
        this.widthAdjuster = 0;                                 // x = (total-svg-width/total-data-width) * x -> for during recording, so we can adjust the new data on the fly adjusted to the screensize
        this.heighthAdjuster = 0;                               // see widthAdjuster
        this.standingWaterPaths = null;
        this.path = "";
        this.rangePath = null;
    }


    compileSvg() {
        let width = +this.width;
        const curPos = this.videoPosDistance * this.widthAdjuster;
        let heightMin = 0;
        let dataHeightDiv = this.dataHeightMax - this.dataHeightMin;
        let heightMax = dataHeightDiv * this.heightAdjuster;
        let noHeight = heightMax === 0;                                     // need to know this for later on, so keep in mem
        if (noHeight) {                                                     // no values to draw, give default height value to show something
            heightMax = this.svgHeight;
        }
        let videPosLine = "";
        let idealLine = "";
        let waterPaths = "";
        if (this.renderVideoPos && this.data) {                                                     // if there is no data yet, don't show the position line, cause we can't change pos yet
            videPosLine = `<line shape-rendering="crispEdges" x1="${curPos}" y1="${heightMin}" x2="${curPos}" y2="${heightMax}" style="stroke:red;stroke-width:2"/>`;
        }
        if (this.renderIdealLine && this.firstPoint && this.lastPoint) {
            idealLine = `<line shape-rendering="crispEdges" x1="${this.firstPoint.x}" y1="${this.firstPoint.y}" x2="${this.lastPoint.x}" y2="${this.lastPoint.y}" stroke="green" stroke-width="1" vector-effect="non-scaling-stroke" />`;
        }
        if (this.standingWaterPaths && !this.liveFeed) {
            for (const item of this.standingWaterPaths) {
                let color = this.getColorForPuddle(Math.abs(dataHeightDiv), item.height);
                waterPaths += `<path shape-rendering="crispEdges" fill="${color}" fill-opacity="0.5" stroke="${color}" stroke-width="1" vector-effect="non-scaling-stroke" d="${item.path}" />\n`;
            }
        }
        let viewBoxStart = 0;
        let startEndLabels = '';
        let x1 ='1';
        if (this.renderStartEndLabels && !this.liveFeed) {
            viewBoxStart = -SIDE_BORDER_SIZE;
            startEndLabels = this.buildStartEndLabels();
            x1 = '0';
        }
        let axis = ` <line shape-rendering="crispEdges" x1="${x1}" y1="${heightMin}" x2="${x1}" y2="${heightMax}" style="stroke:black;stroke-width:1"/>
                     <line shape-rendering="crispEdges" x1="${width}" y1="${heightMin}" x2="${width}" y2="${heightMax}" style="stroke:black;stroke-width:1"/>
                     <line shape-rendering="crispEdges" x1="${x1}" y1="${heightMax}" x2="${width}" y2="${heightMax}" style="stroke:black;stroke-width:1"/>`;
    
        let markers = "";
        let rangePath = null;
        if (!this.liveFeed) {
            if (this.renderMarkers) {
                markers = this.buildMarkers(width, heightMin, heightMax, noHeight);
                if (this.renderLabels) {
                    heightMax += 30;
                }
                width += SIDE_BORDER_SIZE;
            }
            else if (this.renderStartEndLabels) {                        // if there are no markers, there is no extra space to the right which we need to print the label, so add it
                width += SIDE_BORDER_SIZE;
            }
            if (this.rangePath && this.renderSeriesOutline) {
                rangePath = ` <path shape-rendering="crispEdges" fill="lightgrey" stroke="lightgrey" stroke-width="1" vector-effect="non-scaling-stroke" d="${this.rangePath}" />`
            }
        }
        let path = null;
        if (this.renderSeriesSimple || this.liveFeed) {
            path = `<path shape-rendering="crispEdges" fill="none" stroke="black" stroke-width="1" vector-effect="non-scaling-stroke" d="${this.path}" />`;
        }
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="${viewBoxStart} ${heightMin} ${width-viewBoxStart} ${heightMax}" style="flex:1;">
                            ${markers}
                            ${rangePath}                            
                            ${path}
                            ${waterPaths}
                            ${idealLine}
                            ${axis}
                            ${videPosLine}
                            ${startEndLabels}
                           </svg>`;
    }
    buildStartEndLabels() {
        const endVal = this.adjustHeightDif ? this.streng.depthEndPointRel : this.lastHeight.toFixed(2);
        let result = [
            `<text x="${this.firstPoint.x-40}" y="${this.firstPoint.y+5}" font-size="10" dx="0" shape-rendering="crispEdges" vector-effect="non-scaling-stroke"> ${this.streng.depthStartPointRel}</text>`,
            `<text x="${this.lastPoint.x+2}" y="${this.lastPoint.y+5}" font-size="10" dx="0" shape-rendering="crispEdges" vector-effect="non-scaling-stroke"> ${endVal}</text>`
        ];

        return result;
    }

    buildMarkers(width, heightMin, heightMax, noHeight) {
        let result = [];
        this.buildHorMarkers(width, heightMax, result);
        this.buildVerMarkers(width, heightMin, heightMax, noHeight, result);     
        return result.join("\n");
    }

    buildHorMarkers(width, heightMax, result) {
        if (this.xLabels) {
            const minDistance = 30;                                                     // min nr pixels between each label
            let distanceOfPrev = -minDistance;                                          // keep track of the distance to the prev label, so we don't put too many too close to each other. start with negative value so we can draw the first vale (0)
            this.xLabels.forEach((label) => {
                let value = label.distance * this.widthAdjuster;
                let distanceToPrev = value - distanceOfPrev;
                result.push(`<line shape-rendering="crispEdges" x1="${value}" y1="${heightMax}" x2="${value}" y2="${heightMax - 4}" style="stroke:black;stroke-width:1"/>`);
                if (this.renderLabels && distanceToPrev >= minDistance) {
                    result.push(`<text x="${value}" y="${heightMax + 10}" font-size="10" dx="0" shape-rendering="crispEdges" vector-effect="non-scaling-stroke" dominant-baseline="middle" text-anchor="middle"> ${label.label}</text>`);
                    distanceOfPrev = value;
                }
            });
        }
        else {
            let totalHorMarkers = width < 350 ? (width < 150 ? TOTAL_HOR_MARKERS / 4 : TOTAL_HOR_MARKERS / 2) : TOTAL_HOR_MARKERS;
            let textStepSize = this.dataWidth / totalHorMarkers;
            let stepSize = +width / totalHorMarkers;
            //let midStepSize = stepSize / 2;
            for (let i=0; i< totalHorMarkers; i++) {
                let value = i * stepSize;
                result.push(`<line shape-rendering="crispEdges" x1="${value}" y1="${heightMax}" x2="${value}" y2="${heightMax - 4}" style="stroke:black;stroke-width:1"/>`);
                if (this.renderLabels) {
                    let textValue = (i * textStepSize) + this.distanceStart;
                    result.push(`<text x="${value}" y="${heightMax + 10}" font-size="10" dx="0" shape-rendering="crispEdges" vector-effect="non-scaling-stroke" dominant-baseline="middle" text-anchor="middle"> ${textValue.toFixed(2)}</text>`);
                }
            }
            if (this.renderLabels) {
                let textValue = (totalHorMarkers * textStepSize) + this.distanceStart;
                result.push(`<text x="${width}" y="${heightMax + 10}" font-size="10" dx="0" shape-rendering="crispEdges" vector-effect="non-scaling-stroke" dominant-baseline="middle" text-anchor="middle"> ${textValue.toFixed(2)}</text>`);
            }
        }
    }

    buildVerMarkers(width, heightMin, heightMax, noHeight, result) {
        let totalVerMarkers = getTotalVerMarkers(noHeight, heightMax - heightMin);
        let textStepSize =  (this.dataHeightMax - this.dataHeightMin) / totalVerMarkers;
        //stepSize = Math.round(((heightMax - heightMin) / totalVerMarkers) * 100)/100;           // make certain we have only 2 precision, otherwise we get rounding erros
        let verStart = Math.round(this.dataHeightMax*100)/100;
        let verStartLabel = Math.round(this.dataHeightMin*100)/100;
        for (let i=1; i < totalVerMarkers; i++) {
            let textValue = verStartLabel +  (i * textStepSize)     //round to the nearest 2 digits
            let value = (verStart - textValue) * this.heightAdjuster;
            result.push(`<line shape-rendering="crispEdges" x1="1" y1="${value+1}" x2="${width}" y2="${value+1}" style="stroke:#cccccc;stroke-width:1"/>`);
            if (this.renderLabels) {
                
                result.push(`<text x="${+width + 2}" y="${value + 5}" font-size="10" dx="0" shape-rendering="crispEdges" vector-effect="non-scaling-stroke"> ${textValue.toFixed(3)}</text>`);
            }
        }
        if (this.renderLabels) {
            let textValue = this.dataHeightMax;
            result.push(`<text x="${+width + 2}" y="${heightMin + 10}" font-size="10" dx="0" shape-rendering="crispEdges" vector-effect="non-scaling-stroke"> ${textValue.toFixed(3)}</text>`);
        }
    }

    buildSvg() {
        if (this.svgWidth == 0 || this.svgHeight == 0 || this.streng === null) return;              // only try to build the svg if we have all the info. If we don't yet know the size of the element, can't yet render.
        this.resetCalculatedData();                                                                     // when building the svg, always make certain that the calcualted dat is reset, otherwise we get strange behavious such as ever growing boxes (due to extra added space for livefeed)
        if (!this.data) {                                                                                // mainly for when no data yet recorded 
            this.buildInitSvg();
        }
        else {
            const accums = this.calculateAcummulatedValues();
            this.renderedDataWidth = this.dataWidth;
            if (this.liveFeed) {
                if (this.dataWidth == 0 || this.dataWidth < +this.streng.expectedInspectionLength) {                  // at start of recording, use the expected length , if we go further than that, add a percentage of expected
                    this.renderedDataWidth = +this.streng.expectedInspectionLength;
                    this.adjustMinMaxHeightForRecord();
                }
                else if (this.dataWidth >= this.renderedDataWidth) {                                                // live feed, but there is no more room on the svg (total datawidth determins the width of the svg), so add some
                    this.renderedDataWidth += this.dataWidth - this.renderedDataWidth + (this.streng.expectedInspectionLength/10);    //add 10% + whatever is missing now.
                }
            }
            else if (this.renderMarkers || this.renderStartEndLabels) {                                             // when rendering markers or some labels. always add some space above and below to make the chart easier to read on paper
                this.adjustMinMaxHeightForRecord(20);
            }
            this.renderedDataHeight = (this.dataHeightMax - this.dataHeightMin);
            this.calcRelSizeAdjusters(accums);
            this.buildSeries(accums);
            this.buildStandingWaterSvgs(accums);
        }
        return this.compileSvg();
    }

    buildSeries(accums) {
        let pathParts;
        if (accums.length > 0) {                   
            const useMinMax = this.data.length > 0 && this.data[0].min !== undefined;
            let height = (this.dataHeightMax - accums[0].height) * this.heightAdjuster;                         // note: substract from dataHeighMax, cause height in svg is inverse, so 0 is at the top
            this.firstPoint = {x: 0, y:height};                                                                 // important: needs to be done after adjusting for the svg size
            pathParts = [`M 0 ${height}`];                                             
            if (useMinMax && !this.liveFeed) {
                //console.log("3: ", Math.min(...accums.map((el) => el.min)));
                let min = (this.dataHeightMax - accums[0].min) * this.heightAdjuster;
                let minParts = [`M 0 ${min}`];
                let max = (this.dataHeightMax - accums[0].max) * this.heightAdjuster;
                let maxParts = [` L 0 ${max}`];                                                                     // doing this one in reverse order, will be added to the minParts to build 1 big path
                for (let y of accums) {
                    height = (this.dataHeightMax - y.height) * this.heightAdjuster;                               // note: substract from dataHeighMax, cause height in svg is inverse, so 0 is at the top
                    min = (this.dataHeightMax -  y.min) * this.heightAdjuster;
                    max = (this.dataHeightMax - y.max) * this.heightAdjuster;
                    y.distance *= this.widthAdjuster;
                    y.distanceMin *= this.widthAdjuster;
                    y.distanceMax *= this.widthAdjuster;
                    pathParts.push(` L ${y.distance} ${height}`);
                    minParts.push(` L ${y.distanceMin} ${min}`);
                    maxParts.push(` L ${y.distanceMax} ${max}`);
                }
                this.rangePath = [...minParts, ...maxParts.reverse(), ' z'].join("");
            }
            else {
                for (let y of accums) {
                    height = (this.dataHeightMax - y.height) * this.heightAdjuster;                               // note: substract from dataHeighMax, cause height in svg is inverse, so 0 is at the top
                    y.distance *= this.widthAdjuster;
                    pathParts.push(` L ${y.distance} ${height}`);
                }
            }
            this.setLastPoint(accums[accums.length-1]);                                                         // important: needs to be done after adjusting for the svg size
            this.path = pathParts.join("");
        }
        else {
            this.path = "";
            this.rangePath = null;
        }
    }

    /**
     * calculate the adjustment factors to be applied to datapoints so that they 
     * are relative to the size of the svg
     */
    calcRelSizeAdjusters() {
        this.widthAdjuster = this.renderedDataWidth > 0 ? this.width / this.renderedDataWidth : 0;           // x = (total-svg-width/total-data-width) * x
        this.heightAdjuster = this.renderedDataHeight > 0 ? this.svgHeight / this.renderedDataHeight: 0;        // y = (total-svg-height/total-data-height) * x
    }

    buildInitSvg() {
        if (this.streng) {
            let startHeight = +this.streng.depthStartPointRel;
            let endHeight = +this.streng.depthEndPointRel;
            this.checkMinMax(startHeight);
            this.checkMinMax(endHeight);
            this.renderedDataWidth = 0;                                                                         // no data yet to render
            this.renderedDataHeight = (this.dataHeightMax - this.dataHeightMin);
            this.widthAdjuster = this.renderedDataWidth > 0 ? this.width / this.renderedDataWidth : 0;       // x = (total-svg-width/total-data-width) * x
            this.heightAdjuster = this.renderedDataHeight > 0 ? this.svgHeight / this.renderedDataHeight: 0;
            this.firstPoint = {x: 0, y:  (this.dataHeightMax - startHeight) * this.heightAdjuster};
            this.lastPoint = {x: this.width, y: (this.dataHeightMax - endHeight) * this.heightAdjuster};
        }
    }

    buildStandingWaterSvgs(list) {
        if (!this.renderStandingWater || list.length === 0 || this.liveFeed) {
            this.standingWaterPaths = null;
            return;
        }
        this.standingWaterPaths = [];
        let flowIsCorrect = true;                                       // difference between stream up and down cause when stream up, new points should be higher, when stream down, new points should be lower
        let prev = list[0].height;                                      // need a starting point to compare against, that's the first value.
        let startOfIncorrectFlow = 0;                                   // idx of current incorrect flow
        for(let i = 1; i < list.length; i++) {
            const point = list[i];
            let elevation = point.height - prev;
            if (((elevation < 0 && this.isUpstream) || (elevation > 0 && !this.isUpstream) )) {    // waterflow is reversed
                if (flowIsCorrect) {                                                                // just reversed
                    flowIsCorrect = false;
                    startOfIncorrectFlow = i;
                }
            }
            else {                                                                                  // waterflow is correct direction
                if (!flowIsCorrect) {                                                               // we have a reversal, find other side 
                    flowIsCorrect = true;
                    this.buildPuddleData(startOfIncorrectFlow, list, i);
                    
                }
            }
            prev = point.height;
        }
        if (!flowIsCorrect) {
            this.buildPuddleData(startOfIncorrectFlow, list, list.length);
        }
    }

    buildPuddleData(startOfIncorrectFlow, list, i) {
        let subList = null;
        if (this.isUpstream) {
            for (let end = i; end < list.length; end++) {
                if (list[end].height >= list[startOfIncorrectFlow-1].height) {                                   // found a previous point that is higher than the current point, so found the puddle
                    subList = list.slice(startOfIncorrectFlow-1, end);
                    const startHeight = list[startOfIncorrectFlow-1].height;
                    subList.push({height: startHeight, distance: this.getPuddleEndDistance(startHeight, list, end)});
                    break;
                }
            }
            if (subList == null) { 
                subList = list.slice(startOfIncorrectFlow, list.length); 
                const startHeight = list[startOfIncorrectFlow-1].height;
                subList.push({height: startHeight, distance: subList[subList.length-1].distance});
                subList.push({height: startHeight, distance: this.getPuddleEndDistance(startHeight, list, startOfIncorrectFlow)});
            }
        }
        else {
            for (let start = startOfIncorrectFlow-1; start >= 0; start--) {
                if (list[start].height >= list[i-1].height) {                                   // found a previous point that is higher than the current point, so found the puddle
                    const startHeight = list[i-1].height;
                    subList = [{height: startHeight, distance: this.getPuddleEndDistance(startHeight, list, start+1)}]
                    subList = subList.concat(list.slice(start + 1, i));
                    break;
                }
            }
            if (subList == null) { 
                subList = list.slice(0, i); 
                subList.push({height: subList[subList.length-1].height, distance: 0});
            }
        }
        this.standingWaterPaths.push({height: this.getHeigthDif(subList), path: this.buildStandingWaterSvg(subList)});
    }

    /**
     * get min,max and then dif
     * @param {list} list list of objects, type {height:0, distance:0}
     */
    getHeigthDif(list) {
        list = list.map(x => x.height);                 // only need to return the height dif
        let min = Math.min(...list);
        let max = Math.max(...list);
        return max-min;
    }

    getPuddleEndDistance(neededHeight, list, lastIdx) {
        const first = list[lastIdx-1];
        const last = list[lastIdx];
        const heightDiv = last.height - first.height;
        const procentToAdd = ((neededHeight - first.height)) / heightDiv;

        const distanceDiv = last.distance - first.distance;
        return first.distance + (procentToAdd * distanceDiv);
    }

    /**
     * builds a single path of a single puddle
     * @param {array} list of points
     */
    buildStandingWaterSvg(list) {
        if (list.length > 0) {
            let item = list[0];
            let height = (this.dataHeightMax - item.height) * this.heightAdjuster;                      // y is drawn reverse in svg
            let pathParts = [`M ${item.distance} ${height}`];
            for (let i = 1; i < list.length; i++) {
                item = list[i];
                height = (this.dataHeightMax - item.height) * this.heightAdjuster;
                pathParts.push(` L ${item.distance} ${height}`);
            }
            return pathParts.join("");
        }
        return "";
    }

    /**
     * get the color for a puddle with the specified height
     * @param {number} totalHeight total height span of the sewer
     * @param {number} height heigt of the puddle
     */
    getColorForPuddle(totalHeight, height) {
        let relHeight = height/totalHeight * 100;
        if (relHeight <= 5 ) {
            return "lightblue";
        }
        else if (relHeight <= 10) {
            return "blue";
        }
        else if (relHeight <= 20) {
            return "orange";
        }
        else {
            return "red";
        }
    }

    /**
     * set the coordinates of the last point. this will be used to render the ideal line
     * @param {object} last : x,y pos of last point
     */
    setLastPoint(last) {
        let lastX = last.distance;
        this.lastHeight = last.height;                                              // needed to show the label
        let lastY =  (this.dataHeightMax - last.height) * this.heightAdjuster;
        if (this.liveFeed) {                                                // when in livefeed, the last point for the ideal line is still determined by the expected depth of the endpoint.
            let idealWidth = +this.streng.expectedInspectionLength * this.widthAdjuster;
            if (idealWidth > lastX) {
                let y = (this.dataHeightMax - (+this.streng.depthEndPointRel)) * this.heightAdjuster;                          // take abs value, so it doesn't matter which direction (upstream, downstream) we are working
                this.lastPoint = {x: idealWidth, y: y};
            }
            else {
                this.lastPoint = {x: lastX, y: lastY};
            }
        }
        else {
            this.lastPoint = {x: lastX, y: lastY};
        }
    }

    dataReduceWithAdjust = (r, a) => { 
        let height = a.height + r[0].height;
        r.push({height: height, distance: a.distance});
        return r;
    }
    dataReduceWithAdjustMinMax = (r, a) => { 
        let height = a.height + r[0].height;
        let min = a.min + r[0].height;
        let max = a.max + r[0].height;
        //console.log(`${a.distance}, ${height}, ${min}, ${max}`);
        r.push({height: height, distance: a.distance, min: min, max: max, distanceMin: a.distanceMin, distanceMax: a.distanceMax});
        return r;
    }

    dataReduce = (r, a) => { 
        let height = a.height + r[0].height;
        this.checkMinMax(height);
        r.push({height: height, distance: a.distance});
        return r;
    }

    dataReduceMinMax = (r, a) => { 
        let height = a.height + r[0].height;
        let min = a.min + r[0].height;
        let max = a.max + r[0].height;
        this.checkMinMax(height);
        r.push({height: height, distance: a.distance, min: min, max: max, distanceMin: a.distanceMin, distanceMax: a.distanceMax});
        return r;
    }

    /**
     * adjusts all the graph points so that the last point really ends where it is expected.
     * The user supplies the height of the start & end point. We calcualte the height of the end point
     * by adding all the different measurements to the start. normally we should get the same result as endoint,
     * but this is usually not the case, so adjust all the heights of the points so that the do end there.
     * @param {array} result list of graph points that have been calculated.
     */
    applyCompensationForHeightDrift(result, useMinMax, depthEndPointRel) {
        let last = result[result.length-1];
        let heightDifEndPit = depthEndPointRel - last.height;
        let toAddPerTick = heightDifEndPit / last.distance;
        //console.log(toAddPerTick);
        if (last.distance !== 0) {                                                  // cant / 0
            if (useMinMax) {
                for (const item of result) {
                    item.height += (toAddPerTick * item.distance);
                    item.min += (toAddPerTick * item.distanceMin);
                    item.max += (toAddPerTick * item.distanceMax);
                    this.checkMinMax(item.height);
                    this.checkMinMax(item.max);
                    this.checkMinMax(item.min);
                }
            }
            else {
                for (const item of result) {
                    item.height += toAddPerTick * item.distance;
                    this.checkMinMax(item.height);
                }
            }
        }
    }


    /**
     * extract height info (min, max) from the current data set
     * and return an adjusted data list. 
     * The end point is only added if not live (not recording data)
     * this also depends on the direction that is being measured.
     */
    calculateAcummulatedValues() {
        let start;
        let useMinMax = this.data.length > 0 && this.data[0].min !== undefined;
        let depthEndPointRel = +this.streng.depthEndPointRel;    
        let height = +this.streng.depthStartPointRel;                 
        this.checkMinMax(height);
        this.checkMinMax(depthEndPointRel);                                     // always need to keep the end point also in mind for min-max
        if (useMinMax && !this.liveFeed) {
            start = [{height: height, distance: 0, min: height, max: height, distanceMin: 0, distanceMax: 0}];
        }
        else {
            start = [{height: height, distance: 0}];
        }
        let result;
        if (this.liveFeed) {
            result = this.data.reduce(this.dataReduce, start);
        }
        else if (this.adjustHeightDif) {
            const reducer = useMinMax ? this.dataReduceWithAdjustMinMax : this.dataReduceWithAdjust;
            result = this.data.reduce(reducer, start);                          // this actually translates the list into a new list where all the values are added
            this.applyCompensationForHeightDrift(result, useMinMax, depthEndPointRel);
        }
        else {
            const reducer = useMinMax ? this.dataReduceMinMax : this.dataReduce;
            result = this.data.reduce(reducer, start);
        }
        let prev = result[result.length - 1];
        this.dataWidth = prev.distance;                                         // for live feed, so we can dynamically add to the end easily
        this.dataHeight = prev.height;
        return result;
    }
 
    
    /**
     * checks if the value is outside the bounds of current min & max. If so, min and/or max are adjusted
     * so the bounds are correct.
     * @param {number} value height to compare
     */
    checkMinMax(value) {
        let result = false;
        if (value < this.dataHeightMin){
            this.dataHeightMin = value;
            result = true;
        }
        if (value > this.dataHeightMax) {
            this.dataHeightMax = value;
            result = true;
        }
        return result;
    }

    adjustMinMaxHeightForRecord(percentage=10) {
        let extraSpace = (this.dataHeightMax - this.dataHeightMin) / 100 * percentage;
        this.dataHeightMin -= extraSpace;                                    // add 10%
        this.dataHeightMax += extraSpace;                                    // add 10%
    }


    addData(record) {
        if (!record) throw new Error("missing record");
        this.dataWidth += record.distance;
        if (this.dataWidth > this.renderedDataWidth) {              // need a full rerender cause the relative size has changed.
            //don't need to adjust width, this is done in build svg
            return this.buildSvg();
        }
        else {
            this.dataHeight += record.height;
            if (this.checkMinMax(this.dataHeight)) {                 // if height adjustment factor has changed, also need to re-render the datapoints. During live recording, they have some extra space, so there is room to grow before rebuilding svg
                this.adjustMinMaxHeightForRecord();                     // we went out of space, adjust here for the width (so we don't do it every call which would happen if we do it in buildSvg -> only can happen upon border override, but in build svg we would ahve to check every value)
                return this.buildSvg();
                
            }
            else{
                
                let adjustedHeight =  (this.dataHeightMax - this.dataHeight) * this.heightAdjuster;
                let adjustedWidth = this.dataWidth * this.widthAdjuster;
                this.path += ` L ${adjustedWidth} ${adjustedHeight}`
                return this.compileSvg();
            }
        }
    }
}
