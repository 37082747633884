import { getHeaders } from "./fetch_services";
import Geocode from "react-geocode";
import { API_URL, GOOGLE_API_KEY } from "./server_config_service";
import { configService } from "../../services/config_service";
import { similarity } from "./strings";
import { getSelectedLanguage } from "../../services/translation_service";
import { toRadian } from './cal'

let geoCodeInitDone = false;



async function initGeoCode() {
    if (!geoCodeInitDone) {
        geoCodeInitDone = true;
        Geocode.setApiKey(GOOGLE_API_KEY);
        Geocode.setLocationType("ROOFTOP");
        let region = configService.get("geo.region");
        if (!region) {
            const geoInfo = await getCurrentGeoLocation(false);
            if (geoInfo && geoInfo.countryCode) {
                region = geoInfo.countryCode;
                configService.set("geo.region", region);
            }
        }
        if (region) {
            Geocode.setRegion(region);
        }
        Geocode.setLanguage(getSelectedLanguage());                     // needed to make certain that we collect the data in the right language. This language will ge stored in the statiscs as well, so the other side can query in the same language
    }
}

export async function getCurrentLocation() {
    return new Promise((resolve, reject) => {

        async function getFromIp(orError) {
            const result = await fetch('http://ip-api.com/json', { method: 'GET', mode: 'cors', headers: getHeaders(false) })
            if (result.status === 200) {
                const res = await result.json();
                resolve(res);
            }
            else {
                reject(orError ?? "failed to get location");
            }
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                resolve(pos.coords);
            }, (err) => {
                getFromIp(err);
            });
        }
        else {
            getFromIp(null);
        }
    });
}


/**
 * translates frome one language to another
 * @param {object} location a previous geo query result
 */
export async function geoTranslate(location, language) {
    await initGeoCode();
    Geocode.setLanguage(language);
    let search = [];
    if (location.route) {
        search = [location.route];
    }
    if (location.city) {
        search.push(location.city);
    }
    if (location.state) {
        search.push(location.state);
    }
    if (location.country) {
        search.push(location.country);
    }
    if (search.length === 0 && location.generic) {
        search.push(location.generic);
    }
    let result = await Geocode.fromAddress(search.join(', '));
    Geocode.setLanguage(getSelectedLanguage());
    if (result && result.results.length === 1) {
        result = extractDataFromGoogleGeoLocation(result.results[0]);                      // stil ned to convert back to a usable object
    }
    else {
        result = null;
    }
    return result;
}

/**
 * 
 * @param {bool} doInit when true, init code is done. This allows us to call the function from init itself, when needed.
 * @returns 
 */
export async function getCurrentGeoLocation(doInit = true) {
    if (doInit) {
        await initGeoCode();
    }
    let location = await getCurrentLocation();
    let lat, lon, useStreet;
    if (location.latitude && location.longitude) {                          // we got an (reasnably) acurate value from system or google
        lat = location.latitude;
        lon = location.longitude;
        useStreet = true;
    }
    else {                                                                  // got non accurate from ip
        lat = location.lat;
        lon = location.lon;
        useStreet = false;
    }
    const response = await Geocode.fromLatLng(lat, lon);
    return extractDataFromGoogleGeoLocation(response.results[0], null, useStreet);
}

/**
 * translates a set of coordinates into an address.
 * @param {number} lat latitude
 * @param {number} lon longitude
 */
export async function CoordinatesToAddress(lat, lon) {
    await initGeoCode();
    const response = await Geocode.fromLatLng(lat, lon);
    return extractDataFromGoogleGeoLocation(response.results[0]);
}


export function extractDataFromGoogleGeoLocation(response, requestedCity = null, useStreet = true) {
    let city, state, country, countryCode, province, route, postCode, generic, number;
    if (response.address_components) {
        for (let i = 0; i < response.address_components.length; i++) {
            for (let j = 0; j < response.address_components[i].types.length; j++) {
                switch (response.address_components[i].types[j]) {
                    case "locality":
                        city = response.address_components[i].long_name;
                        break;
                    case "administrative_area_level_1":
                        state = response.address_components[i].long_name;
                        break;
                    case "administrative_area_level_2":
                        province = response.address_components[i].long_name;
                        break;
                    case "postal_code":
                        postCode = response.address_components[i].long_name;;
                        break;
                    case "country":
                        country = response.address_components[i].long_name;
                        countryCode = response.address_components[i].short_name;
                        break;
                    case "route":
                        if (useStreet) {
                            route = response.address_components[i].long_name;
                        }
                        break;
                    case "street_number":
                        if (useStreet) {
                            number = response.address_components[i].long_name;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }
    else if (response.name) {
        generic = response.name;
    }
    if (route && !city && requestedCity) {                                   // occationally, google gives a result but skips the city, doesn't put it in the list, even though it gives ok on the website, so fix this.
        city = requestedCity;
    }
    const address = (useStreet) ? response.formatted_address : city;        // when useStreet is false, it means we need to force the address to only a city. this is for when we get the current position from a very coarse source.
    const coordinates = response.geometry?.location;
    return { address, city, state, country, countryCode, province, route, number, postCode, coordinates, isPartial: response.partial_match, generic };
}

function buildRegions(rec) {
    let result = [];
    if (rec.city) {
        result.push(rec.city);
    }
    if (rec.province) {
        result.push(rec.province);
    }
    if (rec.state) {
        result.push(rec.state);
    }
    if (rec.country) {
        result.push(rec.country);
    }
    return result;
}

async function resolveCity(city) {
    let res = await Geocode.fromAddress(city);
    if (res.length === 1) {
        res = extractDataFromGoogleGeoLocation(res.raw.results[0], city);
        if (similarity(res.city, city) > 0.8) {
            return res;
        }
    }
    return null;
}

/**checks if the locations in 
 * main.location and strengs.location are valid
 *  replaces any strings as needed.
 * When a city or street can't be resolved (multiple results), the user is 
 * asked to resolve.
 */
export async function resolveGeoLocations(prj) {
    if (!prj.statistics) {
        return new Error("statistics not calculated");
    }
    await initGeoCode();
    let streetsToReplace = {};
    let regions = [];
    let coordinates = {};                                           // dict of coordinates for all the streets, will be resolved later to a list
    for (let i = 0; i < prj.statistics.locations.length; i++) {
        let allowRegions = true;
        const route = prj.statistics.locations[i];
        let address;
        if (prj.main.location !== route) {
            address = `${prj.main.location}, ${route}`;
        }
        else if (prj.main.location) {
            address = `${prj.main.location}`;
        }
        let res;
        try {
            res = await Geocode.fromAddress(address);
        }
        catch(error) {
            console.log('skipping adres, geocode returned error: ', error);
            continue;
        }
        res = res.status === 'OK' ? res.results : res;
        if (!res || res.length !== 1) {
            let good = [];
            let cityOk = [];                                // if we cant find one for 'good', try to find one where the city is ok
            if (res.length > 1) {
                for (let resIdx = 0; resIdx < res.length; resIdx++) {
                    let resItem = extractDataFromGoogleGeoLocation(res[resIdx], prj.main.location);
                    if (resItem.route && resItem.city && similarity(resItem.route, route, resItem.number) >= 0.8 && similarity(resItem.city, prj.main.location) >= 0.8) {
                        good.push(resItem);
                    }
                    if (resItem.city && similarity(resItem.city, prj.main.location) >= 0.8) {
                        cityOk.push(resItem);
                    }
                }
            }
            if (good.length !== 1) {
                if (cityOk.length === 0) {
                    console.log("problem: ", prj.id, ', ', res);
                    continue;
                }
                else {
                    good = cityOk;                      // use the city, this is close enough for region resolving
                }
            }
            res = good[0];
        }
        else {
            res = extractDataFromGoogleGeoLocation(res[0], prj.main.location);
        }
        if (res.isPartial && (!res.route || !res.city || similarity(res.route, route, res.number) < 0.8 || similarity(res.city, prj.main.location) < 0.8)) {                                // couldn't find it poperly, don't know what to do with it.
            console.log('found only partial result for: ', address, ', ', res);
            continue;
        }
        if (res.route && res.route !== route) {                 // google corrected the value, store fixes
            if (similarity(res.route, route, res.number) >= 0.8) {         // the strings need to be closely related, otherwise we treat it as an issue
                if (res.number) {
                    streetsToReplace[route] = `${res.route} ${res.number}`;
                }
                else {
                    streetsToReplace[route] = res.route;
                }
                await logGeoChange(prj, null, prj.statistics.locations[i], res.route, 'statistics.locations');
                prj.statistics.locations[i] = res.route;
                console.log("streetname replace found: ", prj.id, ", from: ", route, " to: ", res.route);
            }
            else if (similarity(res.city, prj.main.location) > 0.8) {
                console.log("street ", route, " treated as new street in ", res.city);
                res.route = route;
            }
            else {
                allowRegions = false;
            }
        }
        if (res.city && res.city !== prj.main.location) {
            if (res.city === route && !res.route) {                             // the city was resolved ok, but it wasn't specified in main.location, but in streng.location. This can happen for small places (gehuchten), ex: Sint-Truiden, Engelmanshoven
                console.log("city found (and accepted) in streng.location: ", prj.id, ', ', prj.main.location, ", in streng: ", route);
            }
            else if (similarity(res.city, prj.main.location) >= 0.8) {         // the strings need to be closely related, otherwise we treat it as an issue
                console.log("city replace found: ", prj.id, ', ', prj.main.location, " to: ", res.city);
                await logGeoChange(prj, null, prj.main.location, res.city, 'main.location');
                prj.main.location = res.city;
            }
            else {
                allowRegions = false;
            }
        }
        if (!prj.statistics.regions && regions.length === 0) {
            if (!allowRegions) {
                let cityRes;
                if (address !== prj.main.location) {                                // when we searched for city and street, found something bogus. Still can try to resolve the city for the region.
                    cityRes = await resolveCity(prj.main.location);                 // try to resolve again, only for the city
                }
                if (cityRes) {
                    res = cityRes;
                    allowRegions = true;
                    console.log("street ", route, " treated as new street in ", res.city);
                }
            }
            if (allowRegions) {
                regions = buildRegions(res);
                prj.statistics.regions = regions;
            }
            else {
                console.log("problem addres differ too much: ", prj.id, ', ', address, " vs ", res.city, ', ', res.route, ", ", JSON.stringify(res));
                res.route = null;
            }
        }
        coordinates[res.route ?? route] = res.coordinates;
    }
    if (Object.keys(streetsToReplace).length > 0) {
        for (const streng of prj.strengs) {
            if (streng.location in streetsToReplace) {
                await logGeoChange(prj, streng.id, streng.location, streetsToReplace[streng.location], 'streng.city');
                streng.location = streetsToReplace[streng.location];
            }
        }
        prj.statistics.locations = [... new Set(prj.statistics.locations)];      // make certain that streets are unique, can happen if we corrected typos
    }
    const newCoord = [];
    for (const loc of prj.statistics.locations) {
        newCoord.push(coordinates[loc]);
    }
    prj.statistics.coordinates = newCoord;
    prj.statistics.language = getSelectedLanguage();                            // we need this so that the other side can query in the same language.
}


/*
 * 
 * @param {object} prj ref to the project (required)
 * @param {string} streng id of streng , otional
 * @param {string} or original text
 * @param {string} replacement new text
 * @param {strign} desc description for change
 * @returns 
 */
export async function logGeoChange(prj, streng, or, replacement, desc) {
    const str = streng?.toString() ?? null;
    const log = {
        prj: prj.id,
        streng: str,
        original: or,
        replacement: replacement,
        date: new Date(Date.now()).toLocaleDateString(),
        desc: desc
    }
    const result = await fetch(`${API_URL}/log/geochange`, { method: 'POST', mode: 'cors', body: JSON.stringify(log), headers: getHeaders() })
    if (result.status === 200) {
        return await result.json();
    }
    else {
        const error = await result.json();
        throw new Error(JSON.stringify(error));
    }
}



/**
 * calcualte distance between 2 points  
 * @param {number} lat1 latitude 1
 * @param {number} lon1 longitued 1
 * @param {number} lat2 latitude 2
 * @param {number} lon2 longitude 2
 * @returns distance between 2 points
 */
export function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRadian(lat2 - lat1);
    var dLon = toRadian(lon2 - lon1);
    lat1 = toRadian(lat1);
    lat2 = toRadian(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}