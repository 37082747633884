import { API_URL } from "./server_config_service";
import { getHeaders } from './fetch_services';
import i18n from "i18next";


class MicroServices {
    /**
     * gets all the projects that the current user can see. No details, only summary info
     */
    async zipProject(projectId, data) {
        return new Promise((resolve, reject) => {
            fetch(`${API_URL}/project/${projectId}/zip`, {method: 'POST', mode: 'cors', body: JSON.stringify(data), headers: getHeaders()})
                .then((result) => {
                    if (result.ok === true) {
                        result.text().then((data) =>{
                            console.log(data);
                            if (result.status === 200) {
                                resolve();
                            }
                            else {
                                //todo: add error message
                                reject(i18n.t("invalid response from server"));
                            }
                        });
                    }
                    else { 
                        result.json().then((error) => {
                            reject(error);
                        }).catch(() => {
                            reject(i18n.t("invalid response from server"));
                        });
                    }
                })
                .catch((error) => reject(error))
            ;
        });
    }
}

export const microServices = new MicroServices();