/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';

import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import StrengsSummary from './strengs_summary_component';


const styles = (theme) => ({
    root: {
    },
    
    
    
});


class StrengsSummaryDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    render() {
        const { t } = this.props;
        if (!this.props.open) {
            return null;
        }
        const fullScreen = window.innerWidth <= this.props.theme.breakpoints.values.sm;
        return (
            <Dialog
                maxWidth="md"
                aria-labelledby="confirmation-dialog-title"
                open={this.props.open}
                fullScreen={fullScreen}
            >
                <React.Fragment>
                    <StrengsSummary main={this.props.main} 
                        strengs={this.props.strengs}
                        onClickStreng={this.props.onClose}
                        onClickObs={this.props.onClickObs}
                        onShowAR={this.props.onShowAR}/>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t("Sluiten")}
                        </Button>
                    </DialogActions>
                </React.Fragment>
            </Dialog>
        );
    }

    
    handleClose = () => {
        if (this.props.onClose) this.props.onClose(-1);
    }

}

StrengsSummaryDialog.propTypes = {
    strengs: PropTypes.array,                    // streng data to show
    main: PropTypes.object,                      // main data of the project
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onClickObs: PropTypes.func,
    onShowAR: PropTypes.func,
};

export default withTheme(withTranslation()(withStyles(styles)(StrengsSummaryDialog)));