/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withTranslation } from 'react-i18next';
import { errExtractor } from '../services/error_extractor';
import { dialogService } from '../../services/dialog_service';

//icons 
import AlertCircleOutline from 'mdi-material-ui/AlertCircleOutline';
import { Tooltip } from '@material-ui/core';
import { assetsService } from '../../services/assets_service';


const styles = (theme) => ({
    textField: {
        width: '100%'
    }
});

let allRecipients = null;                                 // only load 1 time


class RecipientDetails extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            value: props.value ?? [],                       // list of email addresses. Stored in state so we can do a ui update from here locally instead of the entire list
        }
        this.allValues = {};                                // a dictionary of  opdrCodeOpdrGever-key and email-list of values.
    }
 
    componentDidMount() {
        if (allRecipients === null) {                               // not yet loaded
            assetsService.loadJSONFromCloud("recipients").then((result) => {
                allRecipients = result;
                if (result) {
                    let options = result[this.props.valuesKey];             // get list of possible values that user can select from
                    if (!options) { options = []};                          // need to set an empty list, can't assign undefined
                    this.setState({options: options});
                }
            }).catch((error) => {
                const { t } = this.props;
                dialogService.error(t("Cloud"), errExtractor.get(error));
            });
        }
        else {
            let options = allRecipients[this.props.valuesKey];             // get list of possible values that user can select from
            if (!options) { options = []};                          // need to set an empty list, can't assign undefined
            this.setState({options: options});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.valuesKey !== this.props.valuesKey) {
            if (allRecipients) {
                let options = allRecipients[this.props.valuesKey];
                if (!options) { options = []};                          // need to set an empty list, can't assign undefined
                this.setState({options: options});
            }
        }
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Autocomplete
                multiple
                freeSolo
                forcePopupIcon={true}
                disableClearable
                options={this.state.options}
                value={this.state.value}
                onChange={this.handleValueChanged}
                renderTags={(value, getTagProps) =>
                    value.map((email, index) => {
                        let icon;
                        const regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!(regx.test(email))) {
                            icon = <Tooltip title={t("email is not correctly formatted")}><div><AlertCircleOutline color="error"/></div></Tooltip> 
                        }
                        if (this.props.invalidValues && this.props.invalidValues.indexOf(email) > -1) {
                            icon = <Tooltip title={t("email not sent")}><div><AlertCircleOutline color="error"/></div></Tooltip> 
                        }
                        return(<Chip variant="outlined" style={{backgroundColor:"white"}} icon={icon} label={email} {...getTagProps({ index })} />);
                    })
                  }
                renderInput={params => (
                    <TextField
                        {...params}
                        label={t("Recipients")}
                        inputProps={{...params.inputProps, type:"email"}}
                        placeholder={t("Email address")}
                        helperText={t("Give project read access to")}
                        InputLabelProps={{...params.InputLabelProps,  shrink: true, style:{whiteSpace:'nowrap'} }}
                        className={this.props.classes.textField}
                        margin="dense"
                    />
                )}
            />
        );
    }

    handleValueChanged = async (ev, value) => {
        try {
            if (this.props.onValueChanged) {                        // call first, so if something goes wrong, the ui isn't updated
                await this.props.onValueChanged(ev, value);
            }
            this.setState({value: value});
            
            let changed = false;
            const opts = this.state.options;
            value.forEach((el) => {                                 // merge each element in the value list into the list of options, so it can be reused. no need to save to cloud, this is done over there.
                const idx = opts.indexOf(el);
                if (idx === -1) {
                    opts.push(el);                  
                    changed = true;
                }
            });
            if (changed) {
                if (!allRecipients) {                               // if null, init struct
                    allRecipients = {};
                }
                allRecipients[this.props.valuesKey] = opts;         // in case the list was loaded multiple times (quick open of emails, has happened)
                assetsService.saveJSONToCloud("recipients", allRecipients);
            }
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Recipients"), t("recipients_save_failed", {error: errExtractor.get(error)}));
        }    
    }

}

RecipientDetails.propTypes = {
    value: PropTypes.array.isRequired,              // list of emails that can view the project
    invalidValues: PropTypes.array.isRequired,      // list of emails that bounced
    onValueChanged: PropTypes.func.isRequired,
    valuesKey: PropTypes.string.isRequired,         // the key to use for finding/adding possible values
};


export default withTranslation()(withStyles(styles)(RecipientDetails));