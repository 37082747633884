/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//img 
import MapSearch from 'mdi-material-ui/MapSearch';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { cloudDocsService } from '../../services/cloud_documents_service';
import { isEmpty, projectService } from '../../services/project_service';
import { storageService } from '../../../services/storage_service';
import { dialogService } from '../../../services/dialog_service';
import { errExtractor } from '../../services/error_extractor';
import Paywall from '../../overlays/paywall_component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import StrengsSummary from '../../document/inspection/strengs/strengs_summary_component';
import StrengsSummaryDialog from '../../document/inspection/strengs/strengs_summary_dialog';
import ObservationImageDialog from '../../document/inspection/observation/observation_image_dialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "row",
        height: '100%'
    },
    list: {
        overflow: 'auto',
        flex: 1,
        minWidth: '300px'
    },
    listHeader: { 
        backgroundColor: theme.palette.background.paper,
        display: 'flex', 
        flexDirection: "row",
        justifyContent: "center",
        color: theme.palette.text.primary
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    selectAll: {
        marginRight: theme.spacing(2)
    },
    searchBtn: {
        position: 'absolute', 
        right: theme.spacing(1), 
        top: theme.spacing(1)
    }
});


class NewProjects extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            loaded: false,
            selectedIdx: -1,                                        // currently selected project
            selectedProject: null,                                  // the full object of the selected project (not the same as the object found in 'projects')
            strengDetailsTip: null,                                 // when set, a popup should open some details of the obs, like the image
            strengDetailsUrl: null,                                 // when set, show popup with image
            summaryDlgOpen: false,                                  // when set, show dialog for details.
            innerWidth: window.innerWidth,                          // for responsive behaviour
        }
    }

    /**
     * load data here, constructor gets called multiple times, causing data to be loaded multiple times
     */
    async componentDidMount() {
        const { t } = this.props;
        try {
            window.addEventListener('resize', this.updateDimensions);
            const result = await cloudDocsService.getDocs("new");
            let docs = [];                                                          // all the projects transformed, so they can be displayed
            let needsCal = false;                                                  // if some projects dont have (full) statistics yet, calculate them
            for (const el of result) {
                const doc = {
                    isRead: false,
                    name: el.name ? el.name.split('_').join(' ') : '',
                    rawData: el,                                                      // so we can quickly save recipient & id changes without having to reload the data all the time. WARNING: could be mem issue
                    date: el.publishedAt ? new Date(el.publishedAt) : t('Not yet published')
                };
                docs.push(doc);
                if (!el.statistics) {                                               // temp solution while stats are not yet calculated upon upload
                    needsCal = true;
                }
            }
            docs = docs.sort((a, b) => b.date - a.date)
            this.setState({ projects: docs, loaded: true });
            if (needsCal) {
                await this.calculateStats();
            }
        }
        catch(error) {
            this.setState({ loaded: true });
            dialogService.error(t("Dashboard"), t("docs_load_failed", { error: errExtractor.get(error) }));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    /**
     * calculates the statistics for the items that dont have them yet and store the 100 first items as well (so others dont have to recalculate)
     */
    async calculateStats() {
        const items = [...this.state.projects];
        let count = 0;
        for(let i=0; i < items.length; i++) {
            try {                                                                       // try to keep things running, but errors arent fatal here
                const el = items[i].rawData;
                if (!el.statistics){
                    const project = await cloudDocsService.getProject(el.id, false);
                    project.statistics = projectService.calculateStats(project);
                    items[i].rawData = project;
                    count++;
                    if (count < 100) {
                        await cloudDocsService.saveStats(project);
                    }
                }
            }
            catch(error) {
                console.log(error);
            }
        }
        this.setState({projects: items});
        //this.forceUpdate();
    }

    /**
     * called when the window is resized. Used to trigger responsive behaviour.
     */
    updateDimensions = () => {
        const width = window.innerWidth;
        this.setState({innerWidth: width});
    }


    render() {
        const { t, classes } = this.props;
        let selected = this.state.selectedProject;
        let rightSide = null;
        const wideScreen = this.state.innerWidth > this.props.theme.breakpoints.values.sm;
        if (wideScreen) {
            rightSide = this.buildRightSide(selected);
        }
        const leftSide = this.buildleftPart(wideScreen);
        return (
            <Paper className={classes.paper}>
                {leftSide}
                {rightSide}
                {(selected && this.state.summaryDlgOpen) &&
                    <StrengsSummaryDialog open={this.state.summaryDlgOpen}
                        main={selected?.main} 
                        strengs={selected?.strengs}
                        onClickObs={this.handleShowObsDetails}
                        onClose={this.handleCloseStrengSummary}/>
                }
                <ObservationImageDialog open={!!this.state.strengDetailsTip || !!this.state.strengDetailsUrl}
                    image={this.state.strengDetailsUrl}
                    label={this.state.strengDetailsTip}
                    onClose={this.handleCloseObsDetails}/>
                <Backdrop className={classes.backdrop} open={!this.state.loaded}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Paper>
        );
    }

    /**
     * builds the right view
     * @param {object} selected the selected project   
     */
    buildRightSide(selected) {
        const { t, classes } = this.props;
        if (selected) {
            return( <Box style={{display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto'}}>
                        <StrengsSummary main={selected.main} 
                            strengs={selected.strengs}
                            onClickObs={this.handleShowObsDetails}
                            onClickStreng={this.handleOpenPrj}
                        />
                    </Box>
            );
        }
        else {
            return( <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                        <Typography variant="h6">{t("No project selected")}</Typography>
                    </Box>
            );
        }
    }

    buildleftPart(wideScreen) {
        const { t, classes } = this.props;
        let paywall = null;
        let listStyle = null;
        //paywall = <Paywall marginTop={80}/>
        //listStyle = {paddingBottom: '0px'};          // when paywall visible, bottom of list should be removed, otherwise there remains some room to clic
        let result =
            <List className={classes.list} style={listStyle}
                subheader={
                    <ListSubheader component="div" className={classes.listHeader} id="nested-list-subheader">
                        {/* <Checkbox className={classes.selectAll} /> */}
                        <DialogTitle >{t("New projects")}</DialogTitle>
                        <Tooltip title={t("Do a geographic search")}>
                            <IconButton 
                                className={classes.searchBtn}
                                aria-label="search"
                                onClick={this.props.onSearch}>
                                <MapSearch />
                            </IconButton>
                        </Tooltip>
                    </ListSubheader>
                }>
                {this.state.projects.map((prj, idx) => { return this.renderProject(prj, idx)} )}
                {paywall}
            </List>
        ;

        if (this.state.projects.length === 0) {
            const rightCss = wideScreen ? '50%': '0px';                             // when small screen, left side not visible
            result =
                <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                    {result}
                    <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, position: 'absolute', bottom: '0px', top: '0px', left: '0px', right: rightCss, maxWidth: "calc(1280px / 2)", marginLeft: "auto"}}>
                        <Typography variant="h6">{t("No new projects available")}</Typography>
                    </Box>
                    {paywall}
                </div>
        }
        return result;
    }

    renderProject(prj, idx) {
        const { t, classes } = this.props;
        let streets = '';
        if (prj.rawData.statistics && prj.rawData.statistics.locations) {
            streets = ' - ' + prj.rawData.statistics.locations.join(', ');
        }
        let prjDate = '';
        if (prj.date) {
            if (prj.date instanceof Date) {
                prjDate = prj.date.toLocaleDateString();
            }
            else if (prj.date instanceof String) {
                prjDate = prj.date;
            }
        }
        let prjColor = null;
        if (prj.rawData.statistics) {
            if (prj.rawData.statistics.maxErr > 2) {
                prjColor = {color: 'red'};
            }
            else if (prj.rawData.statistics.maxErr > 0) {
                prjColor = {color: 'orange'};
            }
        }
        return (
            <React.Fragment key={idx}>
                <ListItem alignItems="flex-start"
                    button
                    selected={this.state.selectedIdx === idx}
                    onClick={this.selectProject(prj, idx)}
                >
                    <ListItemAvatar>
                        <Radio checked={!prj.isRead} style={prjColor} color="default"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div>{prj.name}</div>
                                <Typography component="span"
                                    color="textSecondary"
                                    style={{marginLeft: '4px'}}
                                    variant="body2">{prjDate}</Typography>
                            </div>}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >{prj.rawData.main.location}

                                </Typography>
                                {streets}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </React.Fragment>
        );
    }

    selectProject = (prj, idx) => async () => {
        const { t } = this.props;
        try {
            this.setState({ loaded: false });
            let project;
            if (!prj.rawData.strengs) {                                                         // no need to relod the full project if it is already loaded. this can be if there were no stats known for the project.
                project = await cloudDocsService.getProject(prj.rawData.id);
            }
            else {
                project = prj.rawData;
            }
            let summaryDlgOpen = this.state.innerWidth <= this.props.theme.breakpoints.values.sm;
            this.setState({selectedProject: project, selectedIdx: idx, loaded: true, summaryDlgOpen: summaryDlgOpen});
            setTimeout(() => {
                prj.isRead = true;
                this.forceUpdate();
            }, 1000);
        }
        catch(error) {
            this.setState({ loaded: true });
            dialogService.error(t('Dashboard'), t("select_project_problem", { error: errExtractor.get(error) }));
        }
    }

    handleCloseStrengSummary = (goToStreng) => {
        this.setState({summaryDlgOpen: false});
        this.handleOpenPrj(goToStreng);
    }

    handleOpenPrj = (streng) => {
        const prj = this.state.selectedProject;
        if (prj && streng > -1 && streng < prj.strengs.length) {
            //this.props.history.push({pathName: `/doc/${prj.id}`, search: '', state: {streng: streng}});
            this.props.history.push( `/doc/${prj.id}`, {streng: streng});
        }
    }

    /**
     * looks up the image for the specified obs index (detail.image) for the specified streng in the selected project
     * and shows the details.
     * @param {number} strengIdx index of the streng to show details of
     * @param {object} detail details to show. contains fields key, tooltip, image. 
     */
    handleShowObsDetails = async (strengIdx, detail) => {
        const prj = this.state.selectedProject;
        const streng = prj.strengs[strengIdx];
        let img = null;
        if (detail.image > -1 && prj.files && prj.files.images)  {
            const searchStr = `_${streng.id}_${detail.image}.jpg`
            img = prj.files.images.find((value) => value.name.endsWith(searchStr));
            if (img) {
                img = await storageService.tryGetUrl(img);
            }
        }
        this.setState({strengDetailsTip: detail.tooltip, strengDetailsUrl: img});
    }

    handleCloseObsDetails = () => {
        this.setState({strengDetailsTip: null, strengDetailsUrl: null});
    }

    hanldeIsShowPdfDisabled = (rowData) => {
        if (rowData.rawData.files) {                                            // when not yet published and viewed by company editor, this doc is in list, but will not yet have files.
            return isEmpty(rowData.rawData.files.pdfReport);
        }
        return true;
    }

    showPdf = (event, rowData) => {
        const files = rowData.rawData.files;
        if (files.pdfReport) {
            storageService.getUrl(files.pdfReport, "application/pdf").then((url) => {
                if (this.props.onOpenPdf) {
                    this.props.onOpenPdf(url);
                }
            }).catch((error) => {
                const { t } = this.props;
                dialogService.error("pdf", t("cant_find_file_on_server", { error: errExtractor.get(error), doc: files.pdfReport.name }));
            });
        }
    }



}

NewProjects.propTypes = {
    onOpenPdf: PropTypes.func,                          // (url: string) => {} called when the pdf needs to be opened, platform specific, 
    onSearch: PropTypes.func,                           // called when the user wants to perform a search, need to switch view
};

export default withTheme(withTranslation()(withStyles(styles)(withRouter(NewProjects))));