/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withTranslation } from 'react-i18next';
import { codeSelectorService } from '../../../services/code_selector_service';




const styles = (theme) => ({
    listItemText: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end'
    },
    listItemSecondaryText: {
        marginRight: theme.spacing(1)
    },
    selectedlistItem : {
        background: 'aliceblue'
    }
});


class CodeSelectorListPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selectedValue,
            list: props.popupOpen ? this.getData(props.currentList) : []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.currentList !== this.props.currentList || prevProps.popupOpen !== this.props.popupOpen) && this.props.popupOpen) {
            this.setState({list: this.getData(this.props.currentList)});
        }
        if (prevProps.selectedValue != this.props.selectedValue) {
            this.setState({selected: this.props.selectedValue});
        }
    }

    getData(list) {
        if (list) {
            return list.filter(child => {
                return codeSelectorService.matchesFilter(child, this.props.strengType);
            })
        }
        return [];
    }

   
    render() {
        const { t } = this.props;
        let listItems = null;
        if (this.props.currentList && this.props.popupOpen) {
            listItems = this.state.list.map((child, idx) => {
                let className = this.state.selectedIdx == idx ? this.props.classes.selectedlistItem : null;
                return (
                    <ListItem button key={idx}
                        className={className}
                        selected={this.state.selected === child.value}
                        onClick={this.handleValueClicked(child)}
                        onFocus={this.handleValueSelected(child)}>
                        <ListItemText
                            primary={t(child.label)}
                            secondary={child.value}
                            classes={{ secondary: this.props.classes.listItemSecondaryText }}
                            className={this.props.classes.listItemText}
                        />
                    </ListItem>
                )
            });
        }
        return (
            <Popover
                onKeyDown={this.handleKeyboard}
                open={this.props.popupOpen}
                anchorEl={this.props.popupAnchorEl}
                onClose={this.props.onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List dense component="nav" >
                    {listItems}
                </List>
            </Popover>
        );
    }

    handleValueClicked = (item) => () => {
        this.props.onValueChanged(item);
    }

    handleValueSelected = (item) => () => {
        this.setState({selected: item});
    }

    handleKeyboard = (e) => {
        if (e.key == "Enter") {
            if (this.state.selected) {
                let idx = this.getIdx();
                if (idx > -1) {
                    this.props.onValueChanged(this.state.list[idx]);
                }
                this.props.onClose();
            }
        }
        else if (e.keyCode === 27) {                                                     // esc
            this.props.onClose();
        }
        else if (e.keyCode === 40) {                                                    // arrow down
            this.moveDown();
        }
        else if (e.keyCode === 38) {                                                    // arrow up
            this.moveUp();
        }
        else if (e.keyCode >= 65 && e.keyCode <= 90) {        // letters
            this.searchFirst(e.key.toUpperCase());             // keydown doesn't store the key capitalize, instead has the shift key value.
        }
        else if ((e.keyCode >= 48 && e.keyCode <= 59) || (e.keyCode >= 96 && e.keyCode <= 105)) {        //nr
            this.searchFirst(e.key);
        }
    }

    getIdx() {
        return this.state.list.findIndex((child, idx) => { return child.value === this.state.selected});
    }

    moveUp() {
        let idx = this.getIdx();
        idx = idx > 0 ? idx-1 : this.state.list.length-1;
        this.setState({selected: this.state.list[idx].value});
    }

    moveDown() {
        let idx = this.getIdx();
        idx = (idx < this.state.list.length-1 || idx === -1) ? idx+1 : 0;
        this.setState({selected: this.state.list[idx].value});
    }

    searchFirst(letter) {
        let idx = this.state.list.findIndex((child, idx) => { return child.value.startsWith(letter)});
        if (idx > -1) {
            this.setState({selected: this.state.list[idx].value});
        }
    }
}

CodeSelectorListPopup.propTypes = {
    popupOpen: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string,
    popupAnchorEl: PropTypes.object, 
    currentList: PropTypes.array,
    onClose: PropTypes.func.isRequired, 
    onValueChanged: PropTypes.func.isRequired, 
    strengType: PropTypes.string                            // dp = streng, m = put, determins which fields are visible
};
export default withTranslation()(withStyles(styles)(CodeSelectorListPopup));