/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import FilePlus from 'mdi-material-ui/FilePlus'; 
import Paperclip from 'mdi-material-ui/Paperclip'; 
import DeleteIcon from 'mdi-material-ui/Delete'; 

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ConfirmationDialog from '../../dialogs/confirmation_dialog';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { dialogService } from '../../../services/dialog_service';
import { activeProjectService } from '../../../services/active_project_service';
import { errExtractor } from '../../services/error_extractor';
import { configService } from '../../../services/config_service';
import { attachmentsService } from "./attachments_service";
import Tooltip from '@material-ui/core/Tooltip';


const styles = (theme) => ({
    rootPopover: {
    },
    popoverBox: {
        margin: theme.spacing(2)
    },
    btn: {
    },
    noFilesItem: {
        fontStyle: 'italic',
        color: theme.palette.text.secondary
    }

});

class Attachments extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dlgRemoveOpen: false,
            open: false,
            anchorEl: null,
            files: []                                                       // list of files that are attached
        };

        this.toRemove = null;                                               // file currently selected for removal
    }

    async componentDidMount() {
        if (this.props.initLoaded) {
            await this.initAfterLoad();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.initLoaded !== this.props.initLoaded && this.props.initLoaded) {
            await this.initAfterLoad();
        }
    }

    async initAfterLoad() {
        const readOnly = activeProjectService.loadedFrom === 'cloud' || configService.isViewer;     // when viewer/cloud, load the data, so we can show the correct icon state
        if (readOnly) {
            const files = await attachmentsService.load();
            this.setState({files: files});
        }
    }

    render() {
        const { t, classes } = this.props;
        const files = this.state.files;
        const readOnly = activeProjectService.loadedFrom === 'cloud' || configService.isViewer;
        return (
            <React.Fragment>
                <Tooltip title={t("Documenten")}>
                    <IconButton onClick={this.toggleMenu} edge="end" disabled={readOnly && files.length === 0} color="inherit">
                        <Paperclip />
                    </IconButton>
                </Tooltip>
                <Popover open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {(this.state.open) && 
                        <Box flex={1} display="flex" flexDirection="column" alignItems="stretch" className={classes.rootPopover} >
                            <List component="nav" aria-label="attachments">
                                {(files.length === 0) &&
                                    <ListItem >
                                        <ListItemText primary={t("no_files_yet")} className={classes.noFilesItem} />
                                    </ListItem>
                                }
                                {files.map((file) => { return(
                                    <ListItem button onClick={this.handleShowFile(file)}>
                                        <ListItemText primary={file.label} />
                                        {(!readOnly) && 
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={this.handleStartRemove(file)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                )})}

                                {(!readOnly) && 
                                    <React.Fragment>
                                        <Divider />
                                        <ListItem button onClick={this.handleAddDoc}>
                                            <ListItemIcon>
                                                <FilePlus />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Add')} />
                                        </ListItem>
                                    </React.Fragment>
                                }
                            </List>
                        </Box>
                    }
                </Popover>
                <ConfirmationDialog title={t('Documenten')}
                    question={t('Dit document verwijderen van het project?')}
                    open={this.state.dlgRemoveOpen}
                    onClose={this.handleRemoveDoc}
                />

            </React.Fragment>
        );
    }

    handleShowFile = (file) => () => {
        attachmentsService.showFile(file);
    }

    handleStartRemove = (file) => () => {
        this.toRemove = file;
        this.setState({dlgRemoveOpen: true});
    }

    handleRemoveDoc = (result) => {
        this.setState({dlgRemoveOpen: false});
        try {
            if (result) {
                attachmentsService.remove(this.toRemove);
                this.state.files.splice(this.state.files.indexOf(this.toRemove), 1);
                this.forceUpdate();
            }
            this.toRemove = null;
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Documenten"), errExtractor.get(error));
        }
    }

    toggleMenu = async (event) => {
        const newVal = !this.state.open;
        let anchorEl = null;
        if (newVal) {
            anchorEl = event.currentTarget;
        }
        if (activeProjectService.loadedFrom === 'cloud' || configService.isViewer) {
            this.setState({ open: newVal, anchorEl: anchorEl });
        }
        else {
            let files = [];
            if (newVal) {
                files = await attachmentsService.load();                            // load when needed, this way, we don't load too much data at start. is also better for cloud cause it loads project data later
            }
            this.setState({ open: newVal, anchorEl: anchorEl, files: files });
        }
    }

    handleClose = () => {
        this.setState({ open: false, anchorEl: null });
    }

    handleAddDoc = async () => {
        const { t } = this.props;
        let files = dialogService.selectFile();   
        if (!Array.isArray(files)) {
            files = [files];
        }
        let newList = [...this.state.files];                            // amke a local copy so we can trigger a statechange again
        for(const file of files) {
            try {
                const toAdd = await attachmentsService.add(file);
                newList.push(toAdd);
            }
            catch(error) {
                dialogService.error(t("Documenten"), errExtractor.get(error));
            }
        }
        this.setState({files: newList});
    }

}

Attachments.defaultProps = {

}

Attachments.propTypes = {
    initLoaded: PropTypes.bool.isRequired
}

export default withTranslation()(withStyles(styles)(withRouter(Attachments)));