/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { documentControlService } from '../document_control_service';
import { configService } from '../../../services/config_service';
import { pitchInDegrees } from '../../services/cal';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
    },
    infoRow: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        margin: "10px"
    }
});

class RobotData extends React.PureComponent {
    constructor(props) {
        super(props);

        this.InclinationAsPercentage = configService.get("robot.InclinationAsPercentage");
        this.state = {
        }

        this.data = null;                             // data to be displayed by the robot component, dont use state, is too slow, updating html directly
        this.pitch = 0;                                // pitch to display. Can be expressed in degrees or %

        this.pitchRef = React.createRef();
        this.distanceRef = React.createRef();
        this.rollRef = React.createRef();
        this.camTiltRef = React.createRef();
        this.camPanRef = React.createRef();
    }

    componentDidMount() {
        documentControlService.events.addListener('onRobotData', this.handleRobotDataArrived);
        if (documentControlService.robotDs) {
            let data = documentControlService.robotDs.getLastEvent();
            if (data) {
                this.handleRobotDataArrived(data);
            }
        }
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onRobotData', this.handleRobotDataArrived);
    }

    render() {
        const robotFrameStyle = {
            position: "absolute",
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'space-between',
            top: '0px',
            bottom: "0px",
            left: '0px',
            right: '0px',
            color: 'white',
            margin: 'auto',
            width: this.props.width,
            height: this.props.height,
            textShadow: '0px 0px 1px #000',
            fontSize: '20px'
        };
        if (this.props.inView) {
            delete robotFrameStyle.margin;
        }
        if (this.data) {
            const {pitchTxt, rollTxt, distanceTxt, tiltTxt, panTxt} = this.buildText();
            return (
                <div style={robotFrameStyle}>
                    <div className={this.props.classes.infoRow}>
                        <div ref={this.pitchRef}>{pitchTxt}</div>
                        <div ref={this.rollRef}>{rollTxt}</div>
                        <div ref={this.distanceRef}>{distanceTxt}</div>
                    </div>
                    <div className={this.props.classes.infoRow}>
                        <div ref={this.camTiltRef}>{tiltTxt}</div>
                        <div ref={this.camPanRef}>{panTxt}</div>
                    </div>      
                </div>
            );
        }
        else {
            const { t } = this.props;
            return (
                <div style={robotFrameStyle}>
                    <div>{t("Geen tractor gevonden")}</div>
                </div>
            );
        }
    }

    handleRobotDataArrived = (data) => {
        let pitch = data.pitch;
        if (this.InclinationAsPercentage && pitch != null) {        // != null also checks for undefined
            pitch = pitchInDegrees(pitch);
        }
        const prevData = this.data;
        this.data = data;
        this.pitch = pitch;
        const firstDataSet = !prevData;                            // first time we have data, need to do a repaint
        if (firstDataSet) {
            this.forceUpdate();
        }
        else {
            const {pitchTxt, rollTxt, distanceTxt, tiltTxt, panTxt} = this.buildText();
            if ((data.pan !== prevData.pan || !this.camPanRef.current.innerHTML) && this.camPanRef.current) {
                this.camPanRef.current.innerHTML = panTxt;
            }
            if ((data.tilt !== prevData.tilt || !this.camTiltRef.current.innerHTML) && this.camTiltRef.current) {
                this.camTiltRef.current.innerHTML = tiltTxt;
            }
            if ((data.distance !== prevData.distance || !this.distanceRef.current.innerHTML) && this.distanceRef.current) {
                this.distanceRef.current.innerHTML = distanceTxt;
            }
            if ((data.roll !== prevData.roll || !this.rollRef.current.innerHTML) && this.rollRef.current) {
                this.rollRef.current.innerHTML = rollTxt;
            }
            if ((data.pitch !== prevData.pitch || !this.pitchRef.current.innerHTML) && this.pitchRef.current) {
                this.pitchRef.current.innerHTML = pitchTxt;
            }
        }
    }

    buildText() {
        const data = this.data;
        const pitch = this.pitch;
        const pitchUnit = (this.InclinationAsPercentage) ? '%' : '°';
        const pitchTxt = pitch != null ? `P: ${pitch.toFixed(2)}${pitchUnit}` : "";
        const rollTxt = data.roll != null ? `R: ${data.roll.toFixed(2)}°` : "";     // != checks null or undefined.
        const distanceTxt = data.distance != null ? `D: ${data.distance.toFixed(2)}m` : ""; 
        const tiltValue = !isNaN(data.tilt) ? data.tilt.toFixed(2) : data.tilt;
        const tiltTxt = data.tilt != null ? `CT: ${tiltValue}°` : "";
        const panValue = !isNaN(data.pan) ? data.pan.toFixed(2) : data.pan;
        const panTxt = data.pan != null ? `CP: ${panValue}°` : "";
        return {pitchTxt, rollTxt, distanceTxt, tiltTxt, panTxt};
    }
}

RobotData.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    inView: PropTypes.bool                                          // 
};

export default withTranslation()(withStyles(styles)(RobotData));