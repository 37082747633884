/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { TabletSlider } from './tablet/tablet_slider_component';


const styles = (theme) => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class TimeSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            max: 0,
        }
    }

    componentDidMount() {
        if (this.props.onSetter) {
            this.props.onSetter.current = this.setValue;
        }
    }

    componentWillUnmount() {
        if (this.props.onSetter) {
            this.props.onSetter.current = null;
        }
    }


    render() {
        const Comp = this.props.tabletMode ? TabletSlider : Slider;
        return (
            <div className={this.props.classes.root}>
                <Comp value={this.state.value} 
                    onChange={this.props.onChange} 
                    aria-labelledby="position" 
                    min={0}
                    step={0.01}
                    max={this.state.max} 
                />
            </div>
        );
    }

    setValue = (value, max) => {
        if (value === 0 && max === 0) {
            max = 0.001;                                                           // small fix: when prev-pos != 0 and we now go to pos 0 and max 0 (go to live view), than the slider position on the ui doesn't update correctly. if we instead set max to a very small number, it does work
        }
        if (max !== undefined) {
            this.setState({value: value, max: max});                                // for recording, max is always updated.
        }
        else {
            this.setState({value: value});                                          // this is the normal case while playing: max has already been set and doesn't cahnge
        }
    }
}

TimeSlider.defaultProps = {
    tabletMode: false,
}

TimeSlider.propTypes = {                
    onSetter: PropTypes.object,                                 // object of type {current: null} -> to set the callback for storing a new value fast without rerender
    onChange: PropTypes.func,                                   // calback for when value has been changed by user.
    tabletMode: PropTypes.bool,
};


export default withStyles(styles)(TimeSlider);