/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
    menuText: {
        flexGrow: 1,
        textAlign: 'left'
    },
    menuTextSmall: {
        flex: 1,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    collapsableToolbar: {
        color: 'white',
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        backgroundColor: theme.palette.action.focus,
    },
    defaultToolbar: {
        color: theme.palette.action.active
    }
});


class MainLayout extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            appbarHeight: 48
        }

        this.appbar = React.createRef();
    }

    componentDidMount() {
        this.updateHeight();    
    }

    updateHeight() {
        const height = this.appbar.current && !this.props.toolbarIsCollapsed ? this.appbar.current.offsetHeight : 48;
        if (this.state.appbarHeight !== height) {
            this.setState({ appbarHeight: height });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.allowCollaps !== this.props.allowCollaps) {
            this.updateHeight();
        }
    }

    render() {
        const { allowCollaps, toolbarIsCollapsed, classes } = this.props;
        const color = allowCollaps ? 'transparent' : 'default';
        const toolbarStyle = allowCollaps ? classes.collapsableToolbar : classes.defaultToolbar;
        let toolbar =   <AppBar position="relative"
                            ref={this.appbar}
                            color={color}>
                            <Toolbar variant="dense"
                                classes={{root: toolbarStyle}}>
                                {this.props.leftBtns}
                                {(allowCollaps) &&                          // in tablet mode, there are buttons on top of the toolbar halfway in, so only show the title at the left part
                                    <div style={{flex: 1, display: 'flex'}}>
                                        <Typography variant="h6" color="inherit" className={this.props.classes.menuTextSmall}>
                                            {this.props.title}
                                        </Typography>
                                        <div  style={{flex: 0.5, alignSelf: "stretch"}}/>
                                        <div  style={{flex: 1, alignSelf: "stretch"}}/>
                                    </div>
                                }
                                {(!allowCollaps) &&
                                    <Typography variant="h6" color="inherit" className={this.props.classes.menuText}>
                                        {this.props.title}
                                    </Typography>
                                }
                                
                                {this.props.rightBtns}
                            </Toolbar>
                        </AppBar>
        if (allowCollaps) {
            toolbar = <Fade in={!toolbarIsCollapsed} timeout={500} >{toolbar}</Fade>
        }
        let bodyStyle;
        if (allowCollaps) {
            bodyStyle = {height: '100%', backgroundColor: 'black'} ;
        }
        else {
            bodyStyle = {height: `calc(100% - ${this.state.appbarHeight}px)` };   
        }

        return (
            <React.Fragment>
                {toolbar}
                <div style={bodyStyle}>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

MainLayout.propTypes = {
    title: PropTypes.string.isRequired,
    leftBtns: PropTypes.any,
    rightBtns: PropTypes.any,
    allowCollaps: PropTypes.bool,                           // when true, appbar can be hidden
    toolbarIsCollapsed: PropTypes.bool,                            // when true, appbar is hidden
    onClickBody: PropTypes.func,                                        // called when clicked on body
};

MainLayout.defaultProps = {
    allowCollaps: false,
    toolbarIsCollapsed: false,
}

export default withStyles(styles)(MainLayout);