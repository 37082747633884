/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
//import ButtonGroup from '@material-ui/core/ButtonGroup';


const styles = (theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr 1fr",             // 3 cols of equal size
        gridTemplateRows: "1fr 1fr 1fr",
        justifyItems: "stretch"
    },
    box: {

    }
    
});


class CrosshairButtons extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        return (
            <div className={this.props.classes.root}>
                
                <div style={{gridColumn: "1", gridRow: "2"}}>{this.props.leftBtn}</div>
                <div style={{gridColumn: "3", gridRow: "2"}}>{this.props.rightBtn}</div>

                <div style={{gridColumn: "2", gridRow: "1"}}>{this.props.topBtn}</div>
                <div style={{gridColumn: "2", gridRow: "2"}}>{this.props.centerBtn}</div>
                <div style={{gridColumn: "2", gridRow: "3"}}>{this.props.bottomBtn}</div>

            </div>

        );
    }

}

CrosshairButtons.propTypes = {
    bottomBtn:PropTypes.any,
    centerBtn:PropTypes.any,
    topBtn:PropTypes.any,
    rightBtn:PropTypes.any,
    leftBtn:PropTypes.any,
};

export default withTranslation()(withStyles(styles)(CrosshairButtons));