/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import '../../App.css';
import { documentControlService } from '../../shared_components/document/document_control_service';
import { configService } from '../../services/config_service';


/* View in fullscreen */
function openFullscreen() {
    var isFullscreen = document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || false;
    if (isFullscreen) { return; }
    let el = document.body;
    if (el.requestFullscreen) {
        return el.requestFullscreen();
    } else if (el.mozRequestFullScreen) { /* Firefox */
        return el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        return el.webkitRequestFullscreen();
    } else if (document.msRequestFullscreen) { /* IE/Edge */
        return el.msRequestFullscreen();
    }
  }
  
  /* Close fullscreen */
  function closeFullscreen() {
    var isFullscreen = document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || false;
    if (!isFullscreen) { return; }
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }


/**
 * platform specific base class for the document.
 */
export class DocumentBaseComponent extends React.Component {
    constructor(props) {
        super(props);
        this.isDesktop = false;                     // for rendering the account button
        this.isFullScreen = false;                  // so we can prevent 
    }



    /**
     * set fullscreen mode on/off.
     * see: https://www.w3schools.com/jsref/prop_screen_width.asp
     * https://www.w3schools.com/howto/howto_js_fullscreen.asp
     * @param {bool} newValue true: set fullscreen, otherwise back to original
     */
    setFullScreen(newValue) {
        let width;
        let height;
        if (this.isFullScreen === newValue) {                                   // nothing to change
            return;
        }
        if (newValue) {
            const openResult = openFullscreen()
            if (openResult) {
                openResult.then(() => {
                    window.addEventListener('fullscreenchange', this.handleCloseFullScreen);
                }).catch(error => console.log(error));
            }
            width = window.screen.width;
            height = window.screen.height;
            documentControlService.documentPos = {};                            // let others know they need to store position/state info, so they can rebuild correctly after fullscreen
        }
        else {
            window.removeEventListener('fullscreenchange', this.handleCloseFullScreen);     // no need to call the handler anymore, is closed through button press, handling everythin here
            closeFullscreen();
            width = configService.get('recorder.verticalSplitWidth');
            height = configService.get('recorder.horizontalSplitWidth');
        }
        this.isFullScreen = newValue;
        this.setState({ fullScreen: newValue, verSplit: width, horSplit: height });
    }

    handleCloseFullScreen = (event) => {
        var isFullscreen = document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || false;
        if (!isFullscreen) {                                                             // function gets called when going to ful screen and when pressing esc, only want to handle 'esc' and normal close
            window.removeEventListener('fullscreenchange', this.handleCloseFullScreen);
            const width = configService.get('recorder.verticalSplitWidth');
            const height = configService.get('recorder.horizontalSplitWidth');
            this.isFullScreen = false;
            this.setState({ fullScreen: false, verSplit: width, horSplit: height });
        }
    }

    onUnload() {
     /*   if (!configService.isSite) {
            screenManager.saveLastKnownState();
            screenManager.closeAll();
        }
        */
    }

    async onLoad() {
        /*
        if (!configService.isSite) {
            await screenManager.restoreLastKnownState();
        }
        */
    }

}
