import { configService } from '../../services/config_service';

const events = require('events');

export const REMOTE_ACTIONS = {
    CLUTCH_ON: 0,
    CLUTCH_OFF: 1,
    STOP: 2,
    SPEED: 3,                                       // param: 0-255
    SPEED_REEL: 4,                                       // param: 0-255
    FORWARD: 5,
    BACK: 6,
    LEFT: 7,
    RIGHT: 8,
    CAM_FOCUS_IN: 9,
    CAM_FOCUS_OUT: 10,
    CAM_ZOOM_IN: 11,
    CAM_ZOOM_OUT: 12,
    CAM_ZOOM_STOP: 13,

    CAM_ZOOM: 32,

    CAM_UP: 14,
    CAM_DOWN: 15,
    CAM_LEFT: 16,
    CAM_RIGHT: 17,
    CAM_MOVE_STOP: 18,
    CAM_CENTER: 19,
    HEAD_LIGHT: 20,
    REAR_LIGHT: 21,

    LIGHT_LEVEL: 22,                                // param: 0-255
    ELEVATOR_STOP: 23,
    ELEVATOR_UP: 24,
    ELEVATOR_DOWN: 25,

    SCAN_HOR: 26,
    SCAN_VER: 27,

    HEATER_ON: 28,
    HEATER_OFF: 29,

    LASER_ON: 30,
    LASER_OFF: 31,
    
    AUX_LIGHT_LEVEL: 33,                                // param: 0-255
}

export const DRIVE_STATE = {
    STOPPED: 0,
    FORWARD: 1,
    BACK: 2,
}

const TURN_DIRECTION = {
    LEFT:0,
    RIGHT: 1,
    NONE: 2
}

class RemoteControlService {
    constructor() {
        this.events = new events.EventEmitter();            // events: onData -> from remote to ui, onAction -> from ui to remote

        this.drivingState = DRIVE_STATE.STOPPED;            // current state of the tractor, so we can send the correct messages
        this.turnDirection = TURN_DIRECTION.NONE;
        this.speed = 0;                                     // keep track of the speed so that we can reset it when changing direction
        this.speedReel = 0;                                 // the reel's speed can be set individually
        this.speedLinkType = 'single';                      // determins how the speeds of the reel and tractor are related to each other
        this.speedLinkMode = 'dual';                        // allowed values: dual, none, single, clutchToggled

        this.scanDir = null;                                // keep track of the state of the remote, in case of UI needs to (re)load in the future
        this.isHeaterOn = null;
        this.lightLevel = null;
        this.auxLightLevel = null;                          // some devices have an auxiliry light (backside) that can independitly be controlled
        this.lightsLinked = false;                         // control them individualy or together.
        this.camZoom = null;
        this.camFocus = null;
        this.camMove = null;
        this.elevator = null;
        this.isClutchOn = null;
        this.laserOn = null;
        this.zoomLevel = null;
        this.stopOnSecondBtn = true;
    }

    start() {
        this.stopOnSecondBtn = configService.get('robot.remote.stopOnSecondBtn') ?? true;
    }

    /**
     * sends a stop message to all vital parts of the system so that the machine is certain to have come to a halt.
     * This is used when we first connect to a machine to make certain that it is stopped.
     */
    stopAll() {
        this.setStop();
        
        this.events.emit("onData", REMOTE_ACTIONS.SPEED, 0);
        this.speed = 0;
        this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, 0);
        this.speedReel = 0;
        
        this.events.emit("onData", REMOTE_ACTIONS.CAM_ZOOM_STOP);
        this.camZoom = 'stop';
        this.camFocus = 'stop';

        this.events.emit("onData", REMOTE_ACTIONS.CAM_MOVE_STOP);
        this.camMove = REMOTE_ACTIONS.CAM_MOVE_STOP;

        this.events.emit("onData", REMOTE_ACTIONS.ELEVATOR_STOP);
        this.elevator = REMOTE_ACTIONS.ELEVATOR_STOP;

        this.events.emit("onData", REMOTE_ACTIONS.CLUTCH_OFF);
        this.isClutchOn = false;
    }

    setDirection(direction) {
        
        switch (direction) {
            case REMOTE_ACTIONS.FORWARD:
                this.goForward();
                break;
            case REMOTE_ACTIONS.BACK:
                this.goBackward();
                break;
            case REMOTE_ACTIONS.LEFT:
                if (this.turnDirection == TURN_DIRECTION.LEFT && this.stopOnSecondBtn) {
                    this.setStop();
                }
                else {
                    this.turnDirection = TURN_DIRECTION.LEFT;
                    this.events.emit("onData", REMOTE_ACTIONS.LEFT);
                }
                break;
            case REMOTE_ACTIONS.RIGHT:
                if (this.turnDirection == TURN_DIRECTION.RIGHT && this.stopOnSecondBtn) {
                    this.setStop();
                }
                else {
                    this.turnDirection = TURN_DIRECTION.RIGHT;
                    this.events.emit("onData", REMOTE_ACTIONS.RIGHT);
                }
                break;
            default:
                this.setStop();
                break;
        }
    }

    goForward() {
        if (this.drivingState === DRIVE_STATE.FORWARD && this.stopOnSecondBtn) {
            this.setStop();
        }
        else {
            if (this.drivingState === DRIVE_STATE.BACK) {
                this.setStop();
            }
            this.events.emit("onData", REMOTE_ACTIONS.FORWARD);
            this.drivingState = DRIVE_STATE.FORWARD;
            if (this.speedLinkMode === "dual" && this.speedLinkType == 'single') {                               // if there is only 1 side pulling, need to make certain that the other side is no longer active
                if (this.speedReel > this.speed) {
                    this.speed = this.speedReel;
                } 
                this.speedReel = 0;
                this.updateSpeed();                                                // resend the speed so that the tractor react immediatly
            }
            else if (this.speedLinkMode === "clutchToggled" && this.isClutchOn) {
                this.speedReel = 0;
                this.events.emit("onData", REMOTE_ACTIONS.SPEED, this.speed);
            }
            else {
                this.updateSpeed();                                                // resend the speed so that the tractor react immediatly
            }
        }
    }

    goBackward() {
        if (this.drivingState === DRIVE_STATE.BACK && this.stopOnSecondBtn) {
            this.setStop();
        }
        else {
            if (this.drivingState === DRIVE_STATE.FORWARD) {
                this.setStop();
            }
            this.events.emit("onData", REMOTE_ACTIONS.BACK);
            this.drivingState = DRIVE_STATE.BACK;   
            if (this.speedLinkMode === "dual" && this.speedLinkType == 'single') {                               // if there is only 1 side pulling, need to make certain that the other side is no longer active
                if (this.speed > this.speedReel) {
                    this.speedReel = this.speed;
                }
                this.speed = 0;
                this.updateSpeed();                                                // resend the speed so that the tractor react immediatly
            }
            else if (this.speedLinkMode === "clutchToggled" && !this.isClutchOn) {
                this.speed = 0;
                this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, this.speedReel);
            }
            else {
                this.updateSpeed();                                                // resend the speed so that the tractor react immediatly
            }
        }
    }

    setStop() {
        this.drivingState = DRIVE_STATE.STOPPED;
        this.turnDirection = TURN_DIRECTION.NONE;
        this.events.emit("onData", REMOTE_ACTIONS.STOP);
    }

    setSpeed(value) {
        if (this.speedLinkMode == "dual") {
            if (this.speedLinkType === 'single') {
                if (this.drivingState === DRIVE_STATE.BACK) {
                    this.events.emit("onData", REMOTE_ACTIONS.SPEED, 0);
                    this.speed = 0;
                }
                else {
                    this.events.emit("onData", REMOTE_ACTIONS.SPEED, value);
                    this.speed = value;
                }
                
                if (this.drivingState === DRIVE_STATE.BACK) {
                    this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, value);
                    this.speedReel = value;
                }
                else {
                    this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, 0);
                    this.speedReel = 0;
                }
            }
            else if (this.speedLinkType === 'linked') {
                this.events.emit("onData", REMOTE_ACTIONS.SPEED, value);
                this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, value);
                this.speedReel = value;
                this.speed = value;
            }
            else {
                //unlinked, set tractor speed
                this.events.emit("onData", REMOTE_ACTIONS.SPEED, value);
                this.speed = value;
            }
        }
        else if (this.speedLinkMode !== "none") {
            //unlinked, set tractor speed
            this.events.emit("onData", REMOTE_ACTIONS.SPEED, value);
            this.speed = value;
        }
    }

    setSpeed2(value) {
        if (this.speedLinkMode !== "none") {
            this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, value);
            this.speedReel = value;
        }
    }

    setSpeedLinkType(value) {
        if (this.speedLinkType === 'single' && value === 'linked') {
            this.setSpeed(this.speed);
        }
        else if ((this.speedLinkType === 'linked' && value === 'single') || (this.speedLinkType === 'unlinked' && value === 'single'))
        {
            if (this.drivingState === DRIVE_STATE.BACK) {
                this.speed = 0;
                this.events.emit("onData", REMOTE_ACTIONS.SPEED, 0);
            }
            else {
                this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, 0);
                this.speedReel = 0;
            }
        }
        else if (this.speedLinkType === 'linked' && value === 'unlinked') {         //everythning stays the same, both maintain the same speed

        }
        else if (this.speedLinkType === 'unlinked' && value === 'linked') {
            if (this.drivingState === DRIVE_STATE.BACK) {
                this.speed = this.speedReel;
                this.events.emit("onData", REMOTE_ACTIONS.SPEED, this.speedReel);
            }
            else {
                this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, this.speed);
                this.speedReel = this.speed;
            }
        }
        else if (this.speedLinkType === 'single' && value === 'unlinked') {          //everythning stays the same, both maintain the same speed

        }
        else {
            this.setSpeed(this.speed);
        }
        this.speedLinkType = value;
    }

    /**
     * deterimine if main and aux lights are linked or not
     * @param {bool} value 
     */
    setLightLinkType(value) {
        this.lightsLinked = !!value;                    // make certain it is a bool (truth operator)
        if (this.lightsLinked) {
            if (this.lightLevel < this.auxLightLevel) {
                this.events.emit("onData", REMOTE_ACTIONS.LIGHT_LEVEL, this.auxLightLevel);
                this.lightLevel = this.auxLightLevel;
            }
            else {
                this.events.emit("onData", REMOTE_ACTIONS.AUX_LIGHT_LEVEL, this.lightLevel);
                this.auxLightLevel = this.lightLevel;
            }
        }
    }

    setLightLevel(value) {
        this.events.emit("onData", REMOTE_ACTIONS.LIGHT_LEVEL, value);
        this.lightLevel = value;
        if (this.lightsLinked) {
            this.events.emit("onData", REMOTE_ACTIONS.AUX_LIGHT_LEVEL, value);
            this.auxLightLevel = value;
        }
    }

    setLightLevel2(value) {
        this.events.emit("onData", REMOTE_ACTIONS.AUX_LIGHT_LEVEL, value);
        this.auxLightLevel = value;
        if (this.lightsLinked) {
            this.events.emit("onData", REMOTE_ACTIONS.LIGHT_LEVEL, value);
            this.lightLevel = value;
        }
    }

    /**
     * called when we simply want to resend the current values to make certain that the machine has the correct settings for the speed.
     * no internal state is updated.
     */
    updateSpeed() {
        this.events.emit("onData", REMOTE_ACTIONS.SPEED, this.speed);
        this.events.emit("onData", REMOTE_ACTIONS.SPEED_REEL, this.speedReel);
    }
    /**
     * called by remote controllers to update the state.
     * @param {REMOTE_ACTIONS} action the action that the remote control requested
     * @param {number} value optional value
     */
    setState(action, value) {
        this.updateInnerState(action, value);
        this.events.emit("onData", action, value);
    }

    requestState(action, value) {
        this.updateInnerState(action, value);
        this.events.emit("onAction", action, value);
    }

    updateInnerState(action, value) {
        switch (action) {
            case REMOTE_ACTIONS.SCAN_HOR:
                this.scanDir = 'hor';
                break;
            case REMOTE_ACTIONS.SCAN_VER:
                this.scanDir = 'ver';
                break;
            case REMOTE_ACTIONS.HEATER_ON:
                this.isHeaterOn = true;
                break;
            case REMOTE_ACTIONS.HEATER_OFF:
                this.isHeaterOn = false;
                break;
            case REMOTE_ACTIONS.REAR_LIGHT:
                this.isFrontLight = false;
                break;
            case REMOTE_ACTIONS.HEAD_LIGHT:
                this.isFrontLight = true;
                break;
            case REMOTE_ACTIONS.LIGHT_LEVEL:
                this.lightLevel = value;
                break;
            case REMOTE_ACTIONS.AUX_LIGHT_LEVEL:
                this.auxLightLevel = value;
                break;
            case REMOTE_ACTIONS.CAM_ZOOM:
                this.zoomLevel = value;
                break;
            case REMOTE_ACTIONS.CAM_ZOOM_STOP:
                this.camZoom = 'stop';
                this.camFocus = 'stop';
                break;
            case REMOTE_ACTIONS.CAM_ZOOM_OUT:
                this.camZoom = 'out';
                break;
            case REMOTE_ACTIONS.CAM_ZOOM_IN:
                this.camZoom = 'in';
                break;
            case REMOTE_ACTIONS.CAM_FOCUS_IN:
                this.camFocus = 'in';
                break;
            case REMOTE_ACTIONS.CAM_FOCUS_OUT:
                this.camFocus = 'out';
                break;
            case REMOTE_ACTIONS.CAM_UP:
            case REMOTE_ACTIONS.CAM_DOWN:
            case REMOTE_ACTIONS.CAM_LEFT:
            case REMOTE_ACTIONS.CAM_RIGHT:
            case REMOTE_ACTIONS.CAM_MOVE_STOP:
                this.camMove = action;
                break;
            case REMOTE_ACTIONS.ELEVATOR_DOWN:
            case REMOTE_ACTIONS.ELEVATOR_UP:
            case REMOTE_ACTIONS.ELEVATOR_STOP:
                this.elevator = action;
                break;
            case REMOTE_ACTIONS.LASER_OFF:
                this.laserOn = false;
                break;
            case REMOTE_ACTIONS.LASER_ON:
                this.laserOn = true;
                break;
            case REMOTE_ACTIONS.CLUTCH_OFF:
                this.isClutchOn = false;
                break;
            case REMOTE_ACTIONS.CLUTCH_ON:
                this.isClutchOn = true;
                break;                

            default:
                break;
        }
    }
}

export const remoteControlService = new RemoteControlService();
