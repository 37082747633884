import { userService } from "./user_service";

//this file contains some helper functions for fetch.
export function getHeaders(authorised = true) {
    if (authorised === true) {
        if (userService.user) {
            return {
                'authorization': `Bearer ${userService.user.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            };
        }
        else {
            throw new Error("not logged in");
        }
    }
    else {
        return new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json'
        });
    }
}

export function getCsvHeaders(authorised = true) {
    if (authorised === true) {
        if (userService.user) {
            return new Headers({
                'Authorization': `${userService.user.token}`,
                Accept: 'application/json',
                'Content-Type': 'text/csv'
            });
        }
        else {
            throw new Error("not logged in");
        }
    }
    else {
        return new Headers({
            Accept: 'application/json',
            'Content-Type': 'text/csv'
        });
    }
}

export function getOctStrHeaders(authorised = true) {
    if (authorised === true) {
        if (userService.user) {
            return new Headers({
                'Authorization': `${userService.user.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/octet-stream'
            });
        }
        else {
            throw new Error("not logged in");
        }
    }
    else {
        return new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/octet-stream'
        });
    }
}