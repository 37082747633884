/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import Radiator from 'mdi-material-ui/Radiator';
import RadiatorDisabled from 'mdi-material-ui/RadiatorDisabled';

import Cctv from 'mdi-material-ui/Cctv';
import ArrowLeftRight from 'mdi-material-ui/ArrowLeftRight';
import ArrowUpDown from 'mdi-material-ui/ArrowUpDown';
import WallSconceRoundVariant  from 'mdi-material-ui/WallSconceRoundVariant';
import WallSconceRound from 'mdi-material-ui/WallSconceRound';

import MagnifyPlus from 'mdi-material-ui/MagnifyPlus';
import MagnifyMinus from 'mdi-material-ui/MagnifyMinus';

import FocusInIcon from 'mdi-material-ui/ImageFilterCenterFocusStrong';
import FocusOutIcon from 'mdi-material-ui/ImageFilterCenterFocusStrongOutline';

import React from 'react';
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import CameraDirectionButtons from './camera_direction_component';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider';
import { getDashboardImg } from '../../../../services/image_service';
import { remoteControlService, REMOTE_ACTIONS } from '../../../services/remote_control_service';
import LightController from './light_controller_component';
import { robotRecorderClient } from '../robot/robot_client_service';



const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: "row",
        color: theme.palette.action.active,
    },
    col: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-between',
        marginLeft: theme.spacing(0.5),
    },
    col2: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-between',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    button: {
        padding: 0
    },
    btnCenter: {
        borderRadius: '0px',
        padding: 0,
        color: "inherit",
    },
    btnTop: {
        padding: 0,
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomStyle: "unset",
        color: theme.palette.action.active,
    },
    btnBottom: {
        padding: 0,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderTopStyle: "unset",
        color: theme.palette.action.active,
    },
    titleIcon2: {
        height: '14px',
        width: '14px',        
        marginLeft: theme.spacing(0.5),
    },
    titleIcon: {
        height: '14px',
        width: '14px',        
        marginRight: -theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    sliderBox: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    zoomButton: {
        padding: 0,
        minWidth: '0px',
        borderStyle: "solid",
        borderWidth: "1px",
        padding: '0px',
        color: theme.palette.action.active,
        borderColor: theme.palette.action.disabledBackground,
    }
    
});


class CameraController extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isFrontLight: remoteControlService.isFrontLight ?? true,                         // when false: rearlight
            isHeaterOn: remoteControlService.isHeaterOn ?? false,
            scanDir: remoteControlService.scanDir ?? 'hor',                       // hor, ver
            camZoom: remoteControlService.camZoom ?? 'stop',                            // current state of camera zoom
            camFocus: remoteControlService.camFocus ?? 'stop',                            // current state of camera focus
        }
        this.tractorImg = getDashboardImg('top.svg');
        
        this.txtLightDirection = props.t("Switch between front or rear lights");
        this.txtHeater = props.t("Heater on/off");
        this.txtScanDir = props.t("Select scan direction");
        
        this.txtZoomIn = props.t("Zoom in");
        this.txtZoomOut = props.t("Zoom out");

        this.txtFocusIn = props.t("Focus in");
        this.txtFocusOut = props.t("Focus out");
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
    }

    render() {
        const { t, classes } = this.props;

        const lightImg = this.state.isFrontLight ? <WallSconceRoundVariant fontSize="large"/> : <WallSconceRound fontSize="large"/>
        const scanDirImg = this.state.scanDir === "hor" ? <ArrowLeftRight fontSize="large"/> : <ArrowUpDown fontSize="large"/>
        return (
            <div className={classes.root}>
                

                {(robotRecorderClient.canToggleLightDirection() || robotRecorderClient.canToggleScanDirection()) &&
                    <div className={classes.col} style={{flexDirection:"column-reverse"}}>

                        {(robotRecorderClient.canToggleLightDirection()) &&
                            <Tooltip title={this.txtLightDirection}>
                                <a>
                                    <ToggleButton aria-label="lightDirection"
                                        id="lightDirection"
                                        color="default"
                                        value="lightDir"
                                        disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                        onClick={this.handleToggleLightDir}
                                        classes={{root: classes.btnTop}}
                                    >
                                        {lightImg}
                                    </ToggleButton>
                                </a>
                            </Tooltip>
                        }

                        {this.renderHeater()}

                        {(robotRecorderClient.canToggleScanDirection()) &&
                            <Tooltip title={this.txtScanDir}>
                                <a>
                                    <ToggleButton aria-label="scan direction"
                                        id="scanDirection"
                                        color="default"
                                        value="scandir"
                                        disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                        onClick={this.handleSetScanDir}
                                        classes={{root: classes.btnBottom}}
                                    >
                                        {scanDirImg}
                                    </ToggleButton>
                                </a>
                            </Tooltip>
                        }
                    </div>
                }
            
                
                
            
                <Cctv color="disabled" className={classes.titleIcon2} />
                <div className={classes.col2} >

                    <Tooltip title={this.txtFocusIn}>
                        <a>
                            <ToggleButton aria-label="focus in"
                                id="focusIn"
                                value="focusIn"
                                color="default"
                                disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onMouseDown={this.handleFocusIn}
                                onTouchStart={this.handleFocusIn}
                                onMouseUp={this.handleStopZoom}
                                onTouchEnd={this.handleStopZoom}
                                selected={this.state.camFocus === 'in'}
                                className={classes.btnTop}
                            >
                                <FocusInIcon fontSize="large"/>
                            </ToggleButton>
                        </a>
                    </Tooltip>
                    
                    {(!robotRecorderClient.canToggleLightDirection() && !robotRecorderClient.canToggleScanDirection()) && this.renderHeater()}

                    <Tooltip title={this.txtFocusOut}>
                        <a>
                            <ToggleButton aria-label="focus out"
                                id="focusOut"
                                color="default"
                                value="focusOut"
                                disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onMouseDown={this.handleFocusOut}
                                onTouchStart={this.handleFocusOut}
                                onMouseUp={this.handleStopZoom}
                                onTouchEnd={this.handleStopZoom}            // for easysight, stop for zoom and focus is the same
                                selected={this.state.camFocus === 'out'}
                                className={classes.btnBottom}
                            >
                                <FocusOutIcon fontSize="large"/>
                            </ToggleButton>
                        </a>
                    </Tooltip>


                </div>

                <CameraDirectionButtons />

                <div className={classes.col2} >

                    <Tooltip title={this.txtZoomIn}>
                        <a>
                            <ToggleButton aria-label="zoom in"
                                id="zoomIn"
                                value="zoomIn"
                                color="default"
                                disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onMouseDown={this.handleZoomIn}
                                onTouchStart={this.handleZoomIn}
                                onMouseUp={this.handleStopZoom}
                                onTouchEnd={this.handleStopZoom}
                                selected={this.state.camZoom === 'in'}
                                className={classes.zoomButton}
                            >
                                <MagnifyPlus fontSize="large"/>
                            </ToggleButton>
                        </a>
                    </Tooltip>


                    <Tooltip title={this.txtZoomOut}>
                        <a>
                            <ToggleButton aria-label="zoom out"
                                id="zoomOut"
                                value="zoomOut"
                                color="default"
                                disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onMouseDown={this.handleZoomOut}
                                onTouchStart={this.handleZoomOut}
                                onMouseUp={this.handleStopZoom}
                                onTouchEnd={this.handleStopZoom}
                                selected={this.state.camZoom === 'out'}
                                className={classes.zoomButton}
                            >
                                <MagnifyMinus fontSize="large"/>
                            </ToggleButton>
                        </a>
                    </Tooltip>

                </div>
            
                
            </div>

        );
    }

    renderHeater() {
        const radiatorImg = this.state.isHeaterOn ? <Radiator fontSize="large"/> : <RadiatorDisabled fontSize="large"/>
        return (
            <Tooltip title={this.txtHeater}>
                <a>
                    <ToggleButton aria-label="heater"
                        id="heater"
                        value="heater"
                        selected={this.state.isHeaterOn}
                        disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                        onClick={this.handleToggleHeater}
                        classes={{root: this.props.classes.btnCenter}}
                    >
                        {radiatorImg}
                    </ToggleButton>
                </a>
            </Tooltip>
        ); 
    }

    handleZoomIn = () => {
        //console.log("zoom in");
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM_IN);
        this.setState({camZoom: 'in'});
    }

    handleZoomOut = () => {
        //console.log("zoom out");
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM_OUT);
        this.setState({camZoom: 'out'});
    }

    handleStopZoom = () => {
        //console.log("zoom stop");
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM_STOP);
        this.setState({camZoom: 'stop', camFocus: 'stop'});
    }

    handleFocusIn = () => {
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_FOCUS_IN);
        this.setState({camFocus: 'in'});
    }

    handleFocusOut = () => {
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_FOCUS_OUT);
        this.setState({camFocus: 'out'});
    }

    

    handleToggleLightDir = () => {
        const value = !this.state.isFrontLight;
        if (value) {
            remoteControlService.requestState(REMOTE_ACTIONS.HEAD_LIGHT);
        }
        else {
            remoteControlService.requestState(REMOTE_ACTIONS.REAR_LIGHT);
        }
        this.setState({isFrontLight: value});
    }

    handleToggleHeater = () => {
        const value = !this.state.isHeaterOn;
        if (value) {
            remoteControlService.requestState(REMOTE_ACTIONS.HEATER_ON);
        }
        else {
            remoteControlService.requestState(REMOTE_ACTIONS.HEATER_OFF);
        }
        this.setState({isHeaterOn: value});
    }

    handleSetScanDir = () => {
        if (this.state.scanDir === 'hor') {
            remoteControlService.requestState(REMOTE_ACTIONS.SCAN_VER);
            this.setState({scanDir: 'ver'});
        }
        else {
            remoteControlService.requestState(REMOTE_ACTIONS.SCAN_HOR);
            this.setState({scanDir: 'hor'});
        }
    }

    handleBtnPress = (value) => () => {
        remoteControlService.requestState(value);
        this.setState({value});
    }

    handleRemoteUpdate = (action, value) => {
        switch (action) {
            case REMOTE_ACTIONS.SCAN_HOR:
                this.setState({scanDir: 'hor'});
                break;
            case REMOTE_ACTIONS.SCAN_VER:
                this.setState({scanDir: 'ver'});
                break;

            case REMOTE_ACTIONS.HEATER_ON:
                this.setState({isHeaterOn: true});
                break;
            case REMOTE_ACTIONS.HEATER_OFF:
                this.setState({isHeaterOn: false});
                break;
            case REMOTE_ACTIONS.REAR_LIGHT:
                this.setState({isFrontLight: false});
                break;
            case REMOTE_ACTIONS.HEAD_LIGHT:
                this.setState({isFrontLight: true});
                break;
            case REMOTE_ACTIONS.CAM_ZOOM_STOP:
                this.setState({camZoom: 'stop'});
                break;
            case REMOTE_ACTIONS.CAM_ZOOM_OUT:
                this.setState({camZoom: 'out'});
                break;
            case REMOTE_ACTIONS.CAM_ZOOM_IN:
                this.setState({camZoom: 'in'});
                break;
            case REMOTE_ACTIONS.CAM_FOCUS_IN:
                this.setState({camFocus: 'in'});
                break;
            case REMOTE_ACTIONS.CAM_FOCUS_OUT:
                this.setState({camFocus: 'out'});
                break;
            case REMOTE_ACTIONS.SCAN_VER:
                this.setState({scanDir: 'ver'});
                break;
            case REMOTE_ACTIONS.SCAN_VER:
                this.setState({scanDir: 'ver'});
                break;
            case REMOTE_ACTIONS.SCAN_VER:
                this.setState({scanDir: 'ver'});
                break;
            case REMOTE_ACTIONS.SCAN_VER:
                this.setState({scanDir: 'ver'});
                break;
            default:
                break;
        }
    }

}

CameraController.propTypes = {
};

export default withTranslation()(withStyles(styles)(CameraController));