/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import Link from 'mdi-material-ui/Link';
import LinkOff from 'mdi-material-ui/LinkOff';
import CarLightHigh from 'mdi-material-ui/CarLightHigh';
//import FormatHorizontalAlignCenter from 'mdi-material-ui/FormatHorizontalAlignCenter';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
//import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { remoteControlService, REMOTE_ACTIONS } from '../../../services/remote_control_service';


const styles = (theme) => ({
    grid: {
        paddingBottom: theme.spacing(1),
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",             // 3 cols of equal size
        gridTemplateRows: "16px auto",
        justifyItems: "stretch",
        gridTemplateAreas: `
                            'link link'
                            'slider1 slider2'
        `
    },
    green: {
        color: 'green'
    },
    orange: {
        color: 'orange'
    },
    red: {
        color: 'red'
    },
    titleIcon2: {
        height: '14px',
        width: '14px',        
        marginRight: -theme.spacing(1),
        marginLeft: theme.spacing(2),
    },

});


/**
 */
class LightController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            linked: remoteControlService.lightsLinked,
            light1: remoteControlService.lightLevel ?? 0,
            light2: remoteControlService.auxLightLevel ?? 0,
        }
        this.linkTypeTip = props.t("Link light control");
        this.slider2Tip = props.t("Camera light intensity");

        this.txtLightIntensity = props.t("Light intensity");

        this.updateDelay = null;                           // to prevent the system from flooding too many updates (when the user continously scrolls)
        this.updateDelay2 = null;
        this.nextValue = null;
        this.nextValue2 = null;
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
    }


    render() {
        const { classes } = this.props;
        const linkIcon = this.getLinkIcon();
        
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <CarLightHigh color="disabled" className={classes.titleIcon2} />
                <div className={classes.grid}>
                    <div style={{gridArea: 'link', alignSelf: 'center', display: "flex", justifyContent: "center"}}>
                        <Tooltip title={this.linkTypeTip}>
                                <IconButton 
                                    size='small'
                                    onClick={this.toggleLinked}
                                    aria-label="link">
                                        {linkIcon}
                                </IconButton>
                            </Tooltip>
                    </div>

                    <div style={{gridArea: 'slider1'}}>
                        <Tooltip title={this.txtLightIntensity} placement="top">
                            <div style={{height: "100%", width: '100%'}}>
                                <Slider value={this.state.light1} 
                                    onChange={this.handleSetLight} 
                                    orientation="vertical"
                                    aria-labelledby="position" 
                                    min={0}
                                    max={255}
                                    step={5}
                                />
                            </div>
                        </Tooltip>
                    </div>

                    <div style={{gridArea: 'slider2'}}>
                        <Tooltip title={this.slider2Tip} placement="top">
                            <div style={{height: "100%", width: '100%'}}>
                                <Slider value={this.state.light2} 
                                    onChange={this.handleSetLight2} 
                                    orientation="vertical"
                                    aria-labelledby="position" 
                                    min={0}
                                    max={255} 
                                    step={5}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    
                </div>
            </div>
        );
    }

    getLinkIcon() {
        if (this.state.linked) {
            return <Link style={{height: '14px', width: '14px'}}/>        
        }
        else {
            return <LinkOff style={{height: '14px', width: '14px'}}/>
        }
    }

    toggleLinked = () => {
        let newValue = !this.state.linked;
        this.setState({linked: newValue});
        remoteControlService.setLightLinkType(newValue);
    }

    handleSetLight = (event, value) => {
        if (value !== this.state.light1) {
            if (!this.updateDelay) {                                   // make certain we don't flood the system with continuous messages
                remoteControlService.setLightLevel(value);
                this.updateDelay = setTimeout(() => {
                    if (this.nextValue) {
                        remoteControlService.setLightLevel(this.nextValue);
                        this.nextValue = null;
                    }
                    this.updateDelay = null;
                }, 150);
            }
            else {
                this.nextValue = value;
            }
            // state is updated through the handleREmoteUpdate call
        }
    }

    handleSetLight2 = (event, value) => {
        if (value !== this.state.light2) {
            if (!this.updateDelay2) {                                   // make certain we don't flood the system with continuous messages
                remoteControlService.setLightLevel2(value);
                this.updateDelay2 = setTimeout(() => {
                    if (this.nextValue2) {
                        remoteControlService.setLightLevel2(this.nextValue2);
                        this.nextValue2 = null;
                    }
                    this.updateDelay2 = null;
                }, 150);
            }
            else {
                this.nextValue2 = value;
            }
            // state is updated through the handleREmoteUpdate call
        }
    }

    handleRemoteUpdate = (action, value) => {
        switch (action) {
            case REMOTE_ACTIONS.LIGHT_LEVEL:
                this.setState({light1: value});
                break;
            case REMOTE_ACTIONS.AUX_LIGHT_LEVEL:
                this.setState({light2: value});
                break;
            default:
                break;
        }
        if (this.state.linked !== remoteControlService.lightsLinked) {
            this.setState({linked: remoteControlService.lightsLinked});
        }
    }

}

LightController.propTypes = {                
    //onSetter: PropTypes.object,                                 // object of type {current: null} -> to set the callback for storing a new value fast without rerender
    //onChange: PropTypes.func,                                   // calback for when value has been changed by user.
};


export default withTranslation()(withStyles(styles)(LightController));