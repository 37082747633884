/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2022] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import CircleHalfFull from 'mdi-material-ui/CircleHalfFull';
import CheckAll from 'mdi-material-ui/CheckAll';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { lidarService } from './lidar_service';
import { createImgObj } from '../../../services/draw_service';
import { toRadian } from '../../../services/cal';
import { strandPropsService } from '../../../services/strand_props_service';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
    checkbox: {
        color: 'black',
        width: '24px',
        height: '24px',
        fontSize: '15px',
        backgroundColor: 'white',
        borderRadius: '15px',
        padding: '0px',
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '2px',
        marginLeft: '2px',
    }

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class DeformationCalculator extends React.PureComponent {
    constructor(props) {
        super(props);
        this.valueEl = React.createRef();
    }

    async componentDidMount() {
        strandPropsService.events.addListener('size', this.handleSizeChanged);
        await this.setDeformation();
        setInterval(async () => {                                                     // refresh the value every second, don't overcalculate to perserve processing power
            await this.setDeformation();
        }, 1000);
    }
    
    componentWillUnmount() {
        strandPropsService.events.removeListener('size', this.handleSizeChanged);
    }


    render() {
        const { t } = this.props;
        return (
            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}> 
                <CircleHalfFull fontSize="inherit" />
                <div ref={this.valueEl} className={this.props.classes.text}>0%</div>
                {(this.props.showAccept) &&
                    <IconButton 
                        onClick={this.handleAccept} 
                        className={this.props.classes.checkbox}>
                        <CheckAll fontSize="inherit"/>
                    </IconButton>
                }
            </div>
        );
    }

    buildPath() {
        const centerWidth = (strandPropsService.width / 2);
        const centerHeight = (strandPropsService.height / 2);
        const points = [];
        for (let idx = 0; idx < lidarService.data.length; idx++) {
            const value = lidarService.data[idx] * 1000;                            // strandprops is in millimeters, lidar data in meters
            if (value !== 0) {
                //const corner = 360 / sonarService.data.length * idx;
                const corner = lidarService.angles[idx];
                const x = centerWidth + (Math.cos(toRadian(corner)) * value);
                const y =  centerHeight - (Math.sin(toRadian(corner)) * value);     // height is reversed in the canvas
                points.push({ x, y });
            }
            else {
                points.push({ x: centerWidth, y: centerHeight });
            }
        }
        let result = points.map((p) => `${p.x} ${p.y}`).join(" L");
        return `M${result} Z`;
    }

    /**
     * builds the image of the background object filled black. Used to calculate the % space used by the drawn area.
     * This represents the full area (100%)
     */
    idealImage() {
        const centerWidth = (strandPropsService.width / 2);
        const centerHeight = (strandPropsService.height / 2);
        switch(strandPropsService.shape) {                                          // lidar is not present on / cant be used with stationary periscope, but only on tractor using regular inspection, so we can assume A is 'round'
            case 'A':                           // round
            case 'F':                           // oval / eliptic
                if (strandPropsService.height !== strandPropsService.width) {           // ellipse
                    return (
                        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${strandPropsService.width} ${strandPropsService.height}">
                            <ellipse cx="${centerWidth}" cy="${centerHeight}" rx="${centerWidth}" ry="${centerHeight}" stroke-width="0" fill="black"/>
                        </svg>`);
                }
                else {
                    return (
                        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${strandPropsService.width} ${strandPropsService.height}">
                            <circle cx="${centerWidth}" cy="${centerHeight}" r="${centerWidth}"  stroke-width="0" fill="black"/>
                        </svg>`);
                }
        case 'B':                           // square
            return (
                `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${strandPropsService.width} ${strandPropsService.height}">
                    <rect x="0" y="0" width="${strandPropsService.width}" height="${strandPropsService.height}" rx="${centerWidth}" ry="${centerHeight}" stroke-width="0" fill="black"/>
                </svg>`);
        case 'C':                           // egg shaped
        case 'D':                           // U shaped
        case 'E':                           // arc-shaped
        default:                            // too complicated, asume cirkle
            return null;
            break;
        }
    }

    /**
     * for calculation, builds the svg of the selected image.
     */
    realImage() {
        const points = this.buildPath();
        return (
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${strandPropsService.width} ${strandPropsService.height}">
                <path d="${points}" stroke="black" stroke-width="1" fill="black"/>
            </svg>`
        );               // dirty trick: use stroke width of 1 to compensate for the fact that the inner side of the strand is always just a little smaller than the measure.
    }

    handleSizeChanged = async () => {
        await this.setDeformation();
    }

    handleAccept = async () => {
        if (this.props.onAccept) {
            const result = await this.calculateDeformation();
            this.props.onAccept(result);
        }
    }

    /**
     * 
     * @param {array} data list of image points
     */
     calculateNrBlack(data) {
        let result = 0;
        for(let x=0; x<data.length; x+=4) {
            if (data[x] === 0 && data[x+1]===0 && data[x+2] === 0) {
                result++;
            }
        }
        return result;
    }

    calculateNrDif(ideal, real) {
        if (ideal.length != real.length) {
            throw new Error("internal error: different sizes in the rendered images, should not happen");
        }
        let result = 0;
        for(let x=0; x < ideal.length; x+=4) {
            if (ideal[x] !== real[x] || ideal[x+1] !== real[x+1] || ideal[x+2] !== real[x+2]) {
                result++;
            }
        }
        return result;
    }

    async setDeformation() {
        const resUi = this.valueEl.current;
        if (resUi) {
            const result = await this.calculateDeformation();
            if (result != null) {
                resUi.innerHTML = `${result.toFixed(1)}%`;      
            }
            else {
                resUi.innerHTML = "--";
            }
            
        }
    }

    async calculateDeformation() {
        if (!lidarService.data) return Promise.resolve(null);
        const width = strandPropsService.width;
        const height = strandPropsService.height;
        if (width === 0 || height === 0) {                                      // if there is no width or height, we can't do the calculations
            return Promise.resolve(null);
        }
        const idealImg = this.idealImage();
        if (!idealImg) {                                                        // cant yet calculate the ideal image (shape is not yet supported)
            return Promise.resolve(null);
        }
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            let img1 = createImgObj(width, height, idealImg, () => {

                ctx.fillStyle = "white";                                        // set background
                ctx.fillRect(0, 0, width, height);
                ctx.drawImage(img1, 0, 0 , width, height);

                //this.saveImage(this.canvasEl.current, 1);
                const imgIdealData = ctx.getImageData(0,0, width, height);
                const totalCount = this.calculateNrBlack(imgIdealData.data);
                let img2 = createImgObj(width, height, this.realImage(), () => {
                    ctx.fillStyle = "white";                                        // set background
                    ctx.fillRect(0, 0, width, height);
                    ctx.drawImage(img2, 0, 0 , width, height);

                    //let url = canvas.toDataURL('image/jpeg', 1.0);
                    //let base64Data = url.replace(/^data:image\/jpeg;base64,/, "");              // remove Base64 stuff from the Image
                    //fs.writeFileSync('d:\\test.jpg', base64Data, 'base64');

                    //this.saveImage(this.canvasEl.current, 2);
                    const imgRealData = ctx.getImageData(0,0, width, height);
                    const selectedCount = this.calculateNrDif(imgIdealData.data, imgRealData.data);
                    const result = (100/totalCount) * selectedCount;
                    resolve(result);
                });
            });
        });
    }
}

DeformationCalculator.propTypes = {
    showAccept: PropTypes.bool,                      // when true, show a checkbox that allows the user to 'accept' the value as a measurement for an observation
    onAccept: PropTypes.func,
};

export default withStyles(styles)(DeformationCalculator);