/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Observation from './observation/observation_component';
import { projectService } from '../../services/project_service';
import { configService } from '../../../services/config_service';
import { codeSelectorService } from '../../services/code_selector_service';
import { documentControlService } from '../document_control_service';
import { activeProjectService } from '../../../services/active_project_service';
import ConfirmationDialog from '../../dialogs/confirmation_dialog';
import { withTranslation } from 'react-i18next';


import EyePlus from 'mdi-material-ui/EyePlus';
import { dialogService } from '../../../services/dialog_service';
import { distanceService } from '../../services/distance_service';

const styles = (theme) => ({
    root: {
        height: '100%',
        width: '100%'
    },
});


class Observations extends React.PureComponent {
    constructor(props) {
        super(props);

        const expandedIdx = props.obsIndex ?? -1;
        this.state = {
            expandedIdx: expandedIdx,                       // the index of the currently expended item.
            showEmptyError: false,                          // to let the active observation component know if it should show it's 'empty' errors or not (when first editing, don't show)
            deleteRequestOpen: false,                       // when true, a dialog is open to ask the user if the observation needs to be deleted
            idxToDelete: -1                                 // used to pass along which idx needs to be deleted (after requestdlg is closed)
        }

        this.treeOpen = null;                               // note, put here, not as state, cause a change here should not trigger repaint, it is used as an extra flag while creating a new item. used to signal the component that it should open it's tree. This is only used when a new item has been added with the "space" key.
        this.focusedValueIdx = null;                        // similar as treeOpen. Used to pass a var to the active observation when it needs to select put focus on a specific value.
        this.errorOnNonFullObservation = configService.get('observations.errorOnNonFullObservation');
        this.onlyNewObservationDuringRec = configService.get('observations.onlyNewObservationDuringRec');
    }


    componentDidMount() {
        if (this.props.captureKeyboard == true) window.addEventListener('keydown', this.handleKeyboard, true);
        activeProjectService.events.addListener("recordingReady", this.handleRecordingReady);
        activeProjectService.events.addListener("recordingStart", this.handleRecordingStarted);
        activeProjectService.events.addListener("obsGenerated", this.handleObsGenerated);
        activeProjectService.events.addListener("canContinueOnObs", this.handleCanContinueOnObs);
        if (this.props.addNewObs) {                                                                     // when in a dialog for new obs, automatically add a enw rec upon creation
            this._addItem(true);
        }
    }


    componentWillUnmount() {
        if (this.props.captureKeyboard == true) window.removeEventListener('keydown', this.handleKeyboard, true);
        activeProjectService.events.removeListener("recordingReady", this.handleRecordingReady);
        activeProjectService.events.removeListener("recordingStart", this.handleRecordingStarted);
        activeProjectService.events.removeListener("obsGenerated", this.handleObsGenerated);
        activeProjectService.events.removeListener("canContinueOnObs", this.handleCanContinueOnObs);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.captureKeyboard != this.props.captureKeyboard){
            if (this.props.captureKeyboard == true) {
                window.addEventListener('keydown', this.handleKeyboard, true);
            }
            else {
                window.removeEventListener('keydown', this.handleKeyboard, true);
            }
        }
        if (prevProps.streng !== this.props.streng) {                   // when switching streng, make certain that the currently open obs collapses. if we don't do this, the ob remains open and shows the errors for the wrong streng (distance might be reported out-of-bounds if previous streng was shorter is new one)
            this.setState({expandedIdx: -1});
        }
    }

    render() {
        const { t } = this.props;
        if (!this.props.initLoaded) {                               // don't load observations as long as not yet loaded, this way, we ensure that the sonar & lidar buttons are properly loaded when needed
            return null;
        }
        if (!this.props.observations) {
            return (
                <div>
                    {t("Geen data")}
                </div>
            );
        }
        return (
            <div className={this.props.classes.root}>
                {this.props.observations.map((obs, idx) => {
                    let treeOpen = null;                            // if not selected, tee shouldn't be open anyways
                    let focusedValueIdx = null;
                    if (this.state.expandedIdx === idx) {
                        focusedValueIdx = this.focusedValueIdx;
                        treeOpen = this.treeOpen;
                    }
                    return(
                        <Observation key={idx} 
                            data={obs} 
                            readOnly={this.props.readOnly}
                            isExpanded={this.state.expandedIdx == idx} 
                            showObsDetails={this.props.showObsDetails}
                            showEmptyError={this.state.showEmptyError}
                            onExpand={(value) => this.expandItem(idx, value)}
                            onDelete={(value) => this.handleDeleteRequest(idx, value)}
                            onPositionChanged={this.props.onPositionChanged}
                            onDistanceChanged={this.handleDistanceChanged}
                            onTerminated={this.props.onTerminated}
                            treeOpen={treeOpen}
                            focusedValueIdx={focusedValueIdx}
                            onFocusedInit={() => this.focusedValueIdx = null}
                            onTreeOpened={() => this.treeOpen = null}
                            onGotoNext={this.handleGotoNext}
                            onGotoPrev={this.handleGotoPrev}
                            onMoveDown={this.handleMoveDown}
                            onMoveUp={this.handleMoveUp}
                            strengType={this.props.strengType}
                            onStartMeasure={this.props.onStartMeasure}
                            onStopMeasure={this.props.onStopMeasure}
                            onStartMeasureAngle={this.props.onStartMeasureAngle}
                            onStartMeasureDepth={this.props.onStartMeasureDepth}
                            onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                            onStartMeasureFreeform={this.props.onStartMeasureFreeform}
                            onContinuousClosed={this.handleContinuousClosed}
                            onGetList={this.handleGetList}
                        />
                    )
                })}
                {(!this.props.readOnly) && 
                    <div style={{textAlign: 'center'}}>
                    <Tooltip title={t("Voeg nieuwe waarneming toe. 'space' voegt een nieuwe waarneming toe en toont de boom; 'ctrl+space' voegt een lege waarneming toe")}>
                        <IconButton onClick={this.addItem} >
                            <EyePlus/>
                        </IconButton>
                    </Tooltip>
                </div>
                }
                <ConfirmationDialog title={t("Waarneming verwijderen")} 
                    question={t("Click op 'ok' om de huidig geselecteerde waarneming te verwijderen.")} 
                    open={this.state.deleteRequestOpen}
                    onClose={this.handleCloseDlg}
                />
            </div>
        );
    }

    expandItem = (idx, value) => {
        if (this.checkObservationCanLooseFocus()) {
            if (value == true) {
                this.setState({expandedIdx: idx});
            }
            else {
                this.setState({expandedIdx: -1});
            }
        }
    }

    handleGetList = () => {
        return this.props.observations;
    }

    handleDeleteRequest = (idx, value) => {
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        this.setState({deleteRequestOpen: true, idxToDelete: idx});         // don't delete yet, user needs to confirm first
    }

    deleteItem(idx){
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        const removed = this.props.observations[idx];
        this.props.observations.splice(idx, 1);
        const newList = [...this.props.observations];                       // make a copy of the list so we can set the state (trigger an update)
        this.setState({expandedIdx: -1});
        this.props.onChanged(newList);
        activeProjectService.deleteContinuous(removed, newList);
    }

    handleCloseDlg = (ack) => {
        if(ack)  {
            this.deleteItem(this.state.idxToDelete);
        }
        this.setState({deleteRequestOpen: false, idxToDelete: -1});
    }

    /**
     * checks if the currently active observation can loose focus. 
     * This is not the case when the user has selected that an observation needs to 
     * returns false when not allowed.
     * Will also display an error box when not allowed
     */
    checkObservationCanLooseFocus() {
        if (!this.props.readOnly && this.errorOnNonFullObservation) {
            if (this.state.expandedIdx >= 0 && this.state.expandedIdx < this.props.observations.length) {
                const obs = this.props.observations[this.state.expandedIdx];
                const def = codeSelectorService.getLeaf(obs.values, this.props.strengType);
                if (!projectService.isObservationComplete(obs, def, this.props.strengType)) {
                    const { t } = this.props;
                    dialogService.error(t("Waarneming"), t("De huidige waarneming is nog niet volledig gedefinieerd. Je kan pas verder gaan wanneer alle velden zijn ingevuld (of wanneer je de record verwijderd)."));
                    this.setState({showEmptyError: true});
                    return false;
                }
            }
        }
        this.setState({showEmptyError: false});
        return true;
    }

    /**
     * checks if it's allowed to add a new item
     */
    checkAddAllowed() {
        if (this.onlyNewObservationDuringRec && documentControlService.controlState !== "record") {
            return false;
        }
        return true;
    }

    addItem = () => { 
        this._addItem();
    }

    _addItem(showTree=null) {
        if (!this.props.readOnly) {
            const allowed = this.checkAddAllowed();
            if (allowed && this.checkObservationCanLooseFocus()) {
                const descriptiveLoc = (this.props.strengType == "m") ? 'A' : null;
                const record = projectService.createNewObservation(documentControlService.currentTime, distanceService.current, [], descriptiveLoc);            
                this.addRecToList(record, showTree);
                activeProjectService.processNewContinuousRecord(record);
            }
            else if (!allowed) {
                const { t } = this.props;
                dialogService.error(t("Waarneming"), t("not_recording_so_no_obs"))
            }
        }
    }

    /**adds a newly created observation to the list and updates everything. Does not do anything for continuous observations */
    addRecToList(record, showTree=null, gotoRec=true) {
        this.props.observations.push(record);               // also add to current list. When modifying values of continuous obs (close, create new one), 2 obs are added to the list, if we don't do this, we loose the 1st item cause the prop isn't updated in time
        const newList = [...this.props.observations];
        newList.sort((a, b) => a.longitude-b.longitude);
        this.treeOpen = showTree;                           // don't set through state cause a reset should of this value should never trigger a repaint. it needs to be reset so that it doesnt' interfere with other commands.
        if (gotoRec) {
            const idx = newList.indexOf(record);
            this.setState({expandedIdx: idx});
        }
        this.props.onChanged(newList);
    }

    /**
     * called when an observation's longitude (tractor-distance) has been edited by the user.
     * This should trigger a resort of the items.
     * @param {object} item the record that was modified
     */
    handleDistanceChanged = (item) => {
        const newList = [...this.props.observations];
        newList.sort((a, b) => a.longitude-b.longitude);
        this.props.onChanged(newList);
    }

    handleKeyboard = (e) => {
        if (this.state.expandedIdx == -1) {                     // when not -1, the editor is open, so don't try to capture the space key, it only causes problems (no spaces allowed in boxes, can't use it to press button)
            if (e.keyCode === 32) {                             // space = add item
                this._addItem(e.ctrlKey === false);             // ctrl+space = add item and show treeselect
                e.stopPropagation();                            // don't let it bubble up, cause that could cause an expansionpanel on strengcomponent to toggle (if the chevron still has focus), which we dont want
            }
        }
    }

    /**
     * result of a tab action that needs to go to next record
     */
    handleGotoNext = () => {
        if (this.checkObservationCanLooseFocus()) {
            this.focusedValueIdx = 0;                           // request to go to start of editor
            if (this.state.expandedIdx < this.props.observations.length -1) {
                this.setState({expandedIdx: this.state.expandedIdx + 1}) ;
            }
            else {
                this.setState({expandedIdx: 0}) ;
            }
        }
    }

    /**
     * result of a ctrl+tab action that needs to go to prev record
     */
    handleGotoPrev = () => {
        if (this.checkObservationCanLooseFocus()) {
            this.focusedValueIdx = -2;                                          // -2 is to let the component know it needs to go to it's last input element
            if (this.state.expandedIdx > 0) {
                this.setState({expandedIdx: this.state.expandedIdx - 1}) ;
            }
            else {
                this.setState({expandedIdx: this.props.observations.length - 1}) ;
            }
        }
    }

    /**
     * result of a keydown. Need to go to next record, with same value index focused
     */
    handleMoveDown = (valueIdx) => {
        if (this.checkObservationCanLooseFocus()) {
            this.focusedValueIdx = valueIdx;
            if (this.state.expandedIdx <  this.props.observations.length -1) {
                this.setState({expandedIdx: this.state.expandedIdx + 1}) ;
            }
            else {
                this.setState({expandedIdx: 0}) ;
            }
        }
    }

    handleMoveUp = (valueIdx) => {
        if (this.checkObservationCanLooseFocus()) {
            this.focusedValueIdx = valueIdx;
            if (this.state.expandedIdx > 0) {
                this.setState({expandedIdx: this.state.expandedIdx - 1}) ;
            }
            else {
                this.setState({expandedIdx: this.props.observations.length - 1}) ;
            }
        }
    }

    /**
     * called when a recording is ready. This means that there are some new records at the end,
     * so move focus to the last record
     */
    handleRecordingReady = () => {
        //this.expandItem(this.props.observations.length - 1, true);
        this.forceUpdate();                                                     // also force an update so that the newly added records are forcebly refreshed.
    }

    /**
     * when the system reports that an auto generated observation has been added, we need to refresh 
     * cause this is not an observation that was created through this interface, so we don't know about it.
     * and need to let the user also know by expanding it.
     */
    handleObsGenerated = (idx) => {
        this.forceUpdate();
        this.setState({expandedIdx: idx})
    }

    /**
     * called by activeProjectService (via event) to verify for other services that it's ok to put 
     * focus on another item. Usually for Add observation event obsGenerated
     * @param {object} result 1 field: canContinue
     */
     handleCanContinueOnObs = (result) => {
        if (result.canContinue === true) {                      // if it's already false, don't screw upteh result
            result.canContinue = this.checkObservationCanLooseFocus();
        }
    }

    /**
     * called when r3ecording started. need to refresh the list. some objects might have been modified
     */
    handleRecordingStarted = () => {
        this.forceUpdate();
    }

    /**
     * called when user wants to close a running continuous observation
     * requestedAction: possible values: newContinuous, any other value
     * obsToClose: observation to close
     * @param {boolean} gotoRec when false, the newly created closed rec will not be focused, but focus remain on prev rec.
     * this is used when added a closure for an obs when a duplicate code was entered and the user selected that he wants to change the value of the
     * active observation (which triggers the closure of the prev). In this case, focus needs to remain on the newly created obs with duplicate code.
     */
    handleContinuousClosed = (obsToClose, requestedAction, time=null, distance=null, gotoRec=true) => {
        if (requestedAction != null) {
            let code = obsToClose.values.slice(0, 3);
            code.push("1");
            let record = projectService.createNewObservation(time ?? documentControlService.currentTime, distance ?? distanceService.current, code);
            record['continuousIdx'] = obsToClose.continuousIdx;
            this.addRecToList(record, null, gotoRec);
            activeProjectService.processNewContinuousRecord(record);
            activeProjectService.continuousChanged(record);
            if (requestedAction == "newContinuous") {
                const descriptiveLoc = (this.props.strengType == "m") ? 'A' : null;
                record = projectService.createNewObservation(documentControlService.currentTime, distanceService.current, code, descriptiveLoc);
                record.values = [...obsToClose.values];
                this.addRecToList(record);
                activeProjectService.processNewContinuousRecord(record);
            }
        }
    }
}

Observations.propTypes = {
    observations: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,                               // for viewer
    showObsDetails: PropTypes.bool,                         // hide/show code labels in a list.
    onChanged: PropTypes.func.isRequired,
    captureKeyboard: PropTypes.bool,                        // when true, observations is allowed to capture keyboard events. If we don't do this, we can't use a space when editing fields, cause observations traps it.
    onPositionChanged: PropTypes.func,                      // when this observation is expanded, let the system go to the video point at which it was recorded.
    strengType: PropTypes.string,                           // dp = streng, m = put, determins which fields are visible
    onStartMeasure: PropTypes.func,                         // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                          // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func,
    onTerminated: PropTypes.func,                   // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
    initLoaded: PropTypes.bool,                     // an observation can only be rendered when the tractor engine is loaded (buttons for sonar and lidar)
    streng: PropTypes.object,                       // keep a ref to the streng itself, so that we can reset the selected idx when the streng changes.
    addNewObs: PropTypes.bool,                          // when true upon opening, a new record will be created and put into focus for editing
    obsIndex: PropTypes.number,                         // when reopening the dialog after a measure, we need to open the same observation, which is done through this field
};

export default withTranslation()(withStyles(styles)(Observations));