/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { dialogService } from "../../../services/dialog_service";
import { errExtractor } from "../../services/error_extractor";
import i18n from "i18next";
import { engineService } from "../../../components/video_engine/engine_service";
import { documentControlService } from "../document_control_service";
import { cameraService } from "../../../components/document/video/camera_service";


/**
 * provides services to connect a video element with a webrtc connection
 * (usually for local connection to the camera engine process)
 */
export class WebRtcPlayer {
    constructor() {
        this.channel = null;
    }

    destroy() {
        this.unloadCamera();
    }

    /**
     * check if there is a peer connection and if so, close it.
     */
    unloadCamera() {
        try {                                           // this is all cleanup code, don't let the application crash because of it.
            cameraService.detachCamera(this.channel);               // need to make certain that there are no longer things hanging around there (for rtsp stream - feed from ffmpeg)
        }
        catch (error) {
            console.log(error);                         // needed to show user?
        }
    }

    /**
     * 
     * @param {object} video the html video object
     * @param {string} channel name of the video channel to start
     * @param {bool} startEngine when true, then engine will be started. has to be false when called from other than main process (ex: video screens)
     * @param {object} canvas optional canvas that should be used for rendering data over video with software. only possible when attaching to local resource, not from webrtc
     * @param {func} errCallback callback in case that the creation failed.
     */
    async start(video, channel, startEngine = true, canvas = null, errCallback = null) {
        this.channel = channel;
        try {
            if (startEngine) {
                await engineService.startInputs();
                const success = await this.internalStart(video, channel, canvas);
                if (!success && errCallback !== null) {
                    errCallback();
                }
            }
            else {
                await this.internalStart(video, channel, canvas);
            }
        }
        catch (error) {
            if (documentControlService.mounted === true) {              // only need to show the error if not yet shown
                this.handleVideoLoadError(error);
            }
        }
    }


    async startMonitor(video, channel) {
        this.channel = channel;
        try {
            console.log('start Player');
            await cameraService.attachCamera(video, channel, null);
        }
        catch (error) {
            if (documentControlService.mounted === true) {              // only need to show the error if not yet shown
                this.handleVideoLoadError(error);
            }
        }
    }

    async internalStart(video, channel, canvas) {
        console.log('start Player');
        return await cameraService.attachCamera(video, channel, canvas);
    }


    handleVideoLoadError = (error) => {
        console.log(error);
        dialogService.error(i18n.t("Video"), i18n.t("problem_loading_video", { error: errExtractor.get(error) }));
    }


}
