import i18n from "i18next";


export class ObsTranslations {
    constructor() {
        this.remark = i18n.t("Opmerking");
        this.removeObs = i18n.t("Verwijder deze observatie");
        this.textValue = i18n.t("text waarde");
        this.ready = i18n.t("Klaar");
        this.jointTip = i18n.t("Bepaal of de waarneming op de buis of een voeg was");
        this.onJoint = i18n.t("Aan de voeg");
        this.onStrand = i18n.t("Aan de buis");
        this.imageTip = i18n.t("Voeg foto toe / verwijder foto op huidige positie");
        this.withPic = i18n.t("Met foto");
        this.noPic = i18n.t("Zonder foto");
        this.sonarTip = i18n.t("Voeg sonar toe / verwijder sonar van waarnemning");
        this.withSonar = i18n.t("Met sonar");
        this.noSonar = i18n.t("Zonder sonar");
        this.locLabel = i18n.t("Beschrijvende locatie");
        this.relatedStart = i18n.t("Gerelateerd begin waarneming");
        this.measure = i18n.t("Meetwaarde");
        this.procent = i18n.t("Procent");
        this.degrees = i18n.t("Graden");
        this.number = i18n.t("Getal");
        this.multipleEnd = i18n.t("Meerdere Eindpunten");
        this.noStart = i18n.t("Eindpunt zonder start");
        this.multipleStartMsg = i18n.t("Meerdere startpunten met dezelfde index zijn niet toegestaan");
        this.notClosed = i18n.t("Deze observatie is doorlopend en nog niet gesloten");
        this.docChanged = i18n.t("Document wijzigen");
        this.obs = i18n.t("Waarneming");
        this.obsMeasureErrMsg = i18n.t("Er was een probleem tijdens het meten: ongeldige waarde.\nDe operatie is stop gezet.");

        this.withLidar = i18n.t("Met lidar");
        this.noLidar = i18n.t("Zonder lidar");
    }

    changeErr(err) {
        return i18n.t("doc_change_error", {error: err})
    }
}