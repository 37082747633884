/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Streng from './streng_component';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { activeProjectService } from '../../../../services/active_project_service';
import { projectService } from '../../../services/project_service';
import { cameraService }  from '../../../../components/document/video/camera_service';
import { configService } from '../../../../services/config_service';
import ConfirmationDialog from '../../../dialogs/confirmation_dialog';
import { documentControlService } from '../../document_control_service';
import { withTranslation } from 'react-i18next';
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from '../../../../services/dialog_service';

//icons
import Delete from 'mdi-material-ui/Delete';

const styles = (theme) => ({
    tabBar: {
        height: '100%',
        width: '100%'
    },
    tab: {
        minWidth: '32px'
    },
    textField: {
        width: '100%'
    },
    toolbar: {
        borderBottomStyle:"solid", 
        borderBottomWidth:"0.4px", 
        background: theme.palette.background.paper
    }
});


class Strengs extends React.PureComponent {
    constructor(props) {
        super(props);

        const selectedIdx = activeProjectService.activeStrengIdx;
        this.state = {
            selectedTab: selectedIdx,     // warning, this is initialized to -1 if list is empty (which can happen during debugging)
            tabs: this.props.strengs,
            deleteRequestOpen: false,                       // when true, a dialog is open to ask the user if the streng needs to be deleted
            forceStrengDetails: false,                      // used to pass on to the streng component that it is expected to show the strengdetails instead of the observations. Used when a new streng is added.
        }
        this.tabsActions = React.createRef();
    }

    componentDidMount() {
        activeProjectService.events.addListener("strengChanged", this.handleStrengChanged);
    }

    componentWillUnmount() {
        if (documentControlService.documentPos) {
            documentControlService.documentPos["strengIdx"] = this.state.selectedTab;
        }
        activeProjectService.events.removeListener("strengChanged", this.handleStrengChanged);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.strengs != this.props.strengs) {
            this.setState({
                selectedTab: this.props.strengs.length - 1,
                tabs: this.props.strengs
            })
            activeProjectService.activeStrengIdx = this.props.strengs.length - 1;           //always make certan that the correct value is set at the beginning. when this changes, not garagnteed that prevSTate.selectedtab is different.
        }
        if (prevProps.width != this.props.width && this.tabsActions) {                     // if we don't do this, the > arrow at the end of the tabs doesn't update correctly (hide/show)
            if (this.tabsActions.current) {
                this.tabsActions.current.updateIndicator();
            }
        }
        if(prevState.selectedTab != this.state.selectedTab || (prevState.deleteRequestOpen != this.state.deleteRequestOpen && prevState.deleteRequestOpen === true)) {      // when we deleted an item, it could be that the index remains the same, but we do need to do a refresh cause the objects ahve changed. only drawback to this way: a refresh also happens when the delete box closes without deleting on object (cancel)
            setImmediate(() => {
                activeProjectService.activeStrengIdx = this.state.selectedTab;
            });
        }
    }

    render() {
        const { t } = this.props;
        let currentStreng = null;
        let selectedIdx = this.state.selectedTab;
        if (this.state.selectedTab > -1) {
            currentStreng = this.state.tabs[this.state.selectedTab];
        }
        else {
            selectedIdx = 0;                                                                // tabs.value doesn't like -1, so we set it to 0, -1 indicates no selection, but tabs needs something
        }
        return (
            <React.Fragment>
                <Box display="flex" flexDirection="row" alignItems="center"  
                    /* position="sticky" zIndex={100} top="0px"
                    className={this.props.classes.toolbar} */
                >
                    <Tabs className={this.props.classes.tabBar}
                        flex={1}
                        action={this.tabsActions}
                        value={selectedIdx}
                        indicatorColor="primary"
                        onChange={this.handleTabChange}
                        aria-label="strengs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.state.tabs.map((tab, idx) => {
                            return (<Tab key={idx} label={idx + 1} classes={{ root: this.props.classes.tab }} />)
                        })}
                        {(!this.props.readOnly) &&                                                                // note: can't put tab in a fragment, it appear the onChange handler wont fire then.
                            <Tooltip title={t("Voeg streng/put toe")}  key={-1}>
                                <Tab label="+" classes={{ root: this.props.classes.tab }} />
                            </Tooltip>
                        }
                    </Tabs>
                    {(!this.props.readOnly) &&
                        [ 
                            <Tooltip title={t("Verwijder huidige streng/put")} key={-3}>
                                <IconButton style={{height: '24px', width: '24px', padding: '0px'}} size='small' onClick={this.handleDeleteStrengReq}><Delete /></IconButton>
                            </Tooltip>
                        ]
                    }
                </Box>
                <Streng onExpandChanged={this.props.onExpandChanged} 
                    streng={currentStreng}
                    readOnly={this.props.readOnly}
                    forceStrengDetails={this.state.forceStrengDetails}
                    onStrengDetailsForced = {this.resetForced}
                    direction={this.props.direction} 
                    onDirectionChanged={this.props.onDirectionChanged}
                    onPositionChanged={this.props.onPositionChanged}
                    captureKeyboard={this.props.captureKeyboard}
                    onStartMeasure={this.props.onStartMeasure}
                    onStopMeasure={this.props.onStopMeasure}
                    onStartMeasureAngle={this.props.onStartMeasureAngle}
                    onStartMeasureDepth={this.props.onStartMeasureDepth}
                    onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                    onStartMeasureFreeform={this.props.onStartMeasureFreeform}
                    onTerminated={this.props.onTerminated}
                    initLoaded={this.props.initLoaded}
                />
                <ConfirmationDialog title={t("Streng/put verwijderen")} 
                    question={t("click_to_remove", {current: currentStreng ? currentStreng.ref : t("geen"), idx: this.state.selectedTab + 1})} 
                    open={this.state.deleteRequestOpen}
                    onClose={this.handleDeleteDlgClose}
                />
            </React.Fragment>
        );
    }
    
    resetForced = () => {
        this.setState({forceStrengDetails: false});
    }


    /**
     * switch tabs. 
     * note: the last tab is the '+' sign, if that is clicked, we create a new tabl.
     */
    handleTabChange = (event, newValue) => {
        const { t } = this.props;
        if (cameraService.isRecording) {            // also don't allow switch while still processing
            dialogService.error(t("video"), t("Kan niet van streng veranderen tijdens opname. Stop eerst de opname."));
        } else if (documentControlService.controlState === 'processing') {
            dialogService.error(t("video"), t("Video is nog aan het verwerken."));
        } else {
            let streng;
            if (newValue == this.state.tabs.length) {                               // if it's the last index, user has requested to add a new item (+ sign)
                try {
                    const onNewStrengCopyPrevData = configService.get('strengs.onNewStrengCopyPrevData');
                    let template = onNewStrengCopyPrevData ? activeProjectService.activeStreng : null;;
                    const id = Math.max(...this.props.strengs.map(x => x.id)) + 1;     // always make certain we have a unique id. This is for fast referencing of the files.
                    streng = projectService.createNewStreng(id, template);
                    this.props.strengs.push(streng);                                //always add to the officiel list, so it can get saved.
                    const newTabs = [...this.props.strengs];                        //internally, work with a copy, so the ui can update.
                    activeProjectService.markDirty();
                    this.setState({ tabs: newTabs });
                }
                catch (error) {
                    const { t } = this.props;
                    dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
                }
            }
            this.changeTab(newValue);
        }
    }

    changeTab(newValue) {
        this.setState({ selectedTab: newValue, forceStrengDetails: !!configService.get("strengs.onNewStrengShowGeneral") });
        if (this.props.onDirectionChanged) {    
            this.props.onDirectionChanged(this.props.strengs[newValue].inspectionDirection);         // provide the new state to other ui elements
        }
    }

    /**
     * called by the activeProjectService when the currently selected active streng is changed.
     * This is triggered when the tab has changed or when the user selected to go to a streng through 
     * some other way (example strengs summary dialog). So we need to check if we need to do an update
     * or not.
     * @param {number} idx index of new active streng
     */
    handleStrengChanged = (idx) => {
        if (this.state.selectedTab != idx) {
            this.changeTab(idx);
        }
    }

    handleDeleteStrengReq = () => {
        this.setState({deleteRequestOpen: true});         // don't delete yet, user needs to confirm first
    }

    handleDeleteDlgClose = async (ack) => {
        this.setState({deleteRequestOpen: false});
        if (ack) {
            await this.deleteStreng();
        }
    }

    /**
     * delete the currently selected tab page
     */
    deleteStreng = async () => {
        if (this.state.selectedTab >= 0) {
            let streng = this.props.strengs[this.state.selectedTab];
            this.props.strengs.splice(this.state.selectedTab, 1);
            if (this.props.strengs.length == 0) {               // when there are no more strengs, create an empty one, otherwise the rest  of the application becomes unstable.
                const toAdd = projectService.createNewStreng(1);      
                this.props.strengs.push(toAdd);
            }
            activeProjectService.markDirty();
            const newTabs = [...this.props.strengs];           // make a copy of the list cause we need to refresh the state (ok, cause we don't have 1000s of ) tabs)
            let newPos = this.state.selectedTab;
            if (newPos == newTabs.length) {
                if (newPos > 0) {
                    newPos--;
                }
                else {
                    newPos = -1;
                }
            }
            documentControlService.events.emit("onDeleteStreng");                // we need to let the video player unload the video, if we don't do this, we get a nasty error
            const rootPath = activeProjectService.path;
            this.setState({ tabs: newTabs, selectedTab: newPos });
            setTimeout(async () => {                                             // don't do this immediatly, but let the video element have some time to reload. If we don't do this, we can get nasty errors while deleting the streng cause the video file is still opened
                await projectService.deleteDataForStreng(rootPath, streng);
            }, 0);
            
        }
    }
}

Strengs.propTypes = {
    onExpandChanged: PropTypes.func,
    strengs: PropTypes.array,
    direction: PropTypes.string,                            // allowed values: 
    onDirectionChanged: PropTypes.func,
    readOnly: PropTypes.bool,
    captureKeyboard: PropTypes.bool,                    // when true, observations is allowed to capture keyboard events. If we don't do this, we can't use a space when editing fields, cause observations traps it.
    onPositionChanged: PropTypes.func,                   // when this observation is expanded, let the system go to the video point at which it was recorded.
    onStartMeasure: PropTypes.func,                 // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                  // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func,
    width: PropTypes.any,                           // this is passed on to strengs so that the selected tab can be brought into view again.
    onTerminated: PropTypes.func,                   // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
    initLoaded: PropTypes.bool,                     // an observation can only be rendered when the tractor engine is loaded (buttons for sonar and lidar)
};

export default withTranslation()(withStyles(styles)(Strengs));