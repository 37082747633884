import { AssetsBaseService } from "../shared_components/services/assets_base_service";
import { dialogService } from "./dialog_service";

/**
 * platform specific version of assets service.
 */
class AssetsService extends AssetsBaseService {

    /**
     * loads a json data structure. In this case, always only from the cloud.
     * @param {string} name of the asset
     */
    async loadAsset(name) {
        return this.loadJSONFromCloud(name);
    }

    /**
     * saves the data to the cloud as a compamy wide global asset.
     * @param {string} name of the asset
     * @param {object} data to save
     */
    async saveAsset(name, data) {
        this.saveJSONToCloud(name, data).catch((error) => {
            dialogService.error(error);
        });
    }

}

export const assetsService = new AssetsService();