/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { activeProjectService } from '../../../../services/active_project_service';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from '../../../services/project_service';
import { errExtractor } from '../../../services/error_extractor';
import { withTranslation } from 'react-i18next';
import { dialogService } from '../../../../services/dialog_service';

const styles = (theme) => ({
    textField: {
        marginLeft: '15px'
    },
    cmbField: {
    },
    readOnly: {
        color: "rgba(0, 0, 0, 0.87)"
    }
});

const MAXYEAR = 2100;
const MINYEAR = 1000;                                   // minimum 4 digits needed


class StrengInfoComboBox extends React.Component {
    constructor(props) {
        super(props);

        const zField = props.field + "Z";
        const xField = props.field + "X";
        const yearField = props.field + "Y"
        const streng = props.streng;
        const valueYear = streng ? streng[yearField] : null;
        this.state = {
            value: streng ? streng[props.field] : null,
            valueZ: streng ? streng[zField] : null,
            valueX: streng ? streng[xField] : null,
            valueYear: valueYear,
            valueYearError: !this.isYearOk(valueYear)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.field !== this.props.field || prevProps.streng !== this.props.streng) && this.props.streng) {
            const zField = this.props.field + "Z";
            const xField = this.props.field + "X";
            const yearField = this.props.field + "Y"
            this.setState({value: this.props.streng[this.props.field],
                valueZ: this.props.streng[zField],
                valueX: this.props.streng[xField],
                valueYear: this.props.streng[yearField],
            });
        }
        if (prevProps.secondarySource !== this.props.secondarySource && this.props.secondarySource !== this.state.value && this.props.streng && this.props.streng === prevProps.streng) {     // this.props.streng can be null when loading 
            this.props.streng[this.props.field] = this.props.secondarySource;
            this.setState({value: this.props.secondarySource});
            activeProjectService.markDirty();
            //don't call onChange, cause we received it from parent, don't need to update him again.
        }
    }

    render() {
        const { t } = this.props;
        let value = this.state.value === null ? "" : this.state.value;
        let error = this.props.required && this.props.showMissingErrors && isEmpty(this.state.value);
        let cmb =   <TextField
                        required={this.props.required}
                        select
                        error={error}
                        id={this.props.field}
                        label={this.props.label}
                        InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                        disabled={this.props.readOnly}
                        value={value}
                        onChange={this.handleValueChanged}
                        className={this.props.classes.cmbField}
                        InputProps={{classes:{disabled: this.props.classes.readOnly}}}
                        SelectProps={{
                            native: true
                        }}
                        margin="dense"
                    >
                        {this.props.options.map((val, idx) => {
                            const label = val.label ? t(val.label) : "";
                            return(
                                <option key={idx} value={val.value}>{label}</option>
                            );
                        })}
                    </TextField>
        if (value == 'Z') {
            error = this.props.required && this.props.showMissingErrors && isEmpty(this.state.valueZ);
            return (
                <React.Fragment>
                    {cmb}
                    <TextField
                        required={true}
                        InputProps={{readOnly:this.props.readOnly}}
                        error={error}
                        id={this.props.field + 'Z'}
                        label={t("Opmerking")}
                        InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap'} }}
                        value={this.state.valueZ}
                        onChange={this.handleZChanged}
                        inputProps={{type: "text", maxLength: 200 }}
                        className={this.props.classes.textField}
                        margin="dense"
                    />
                </React.Fragment>
            );
        }
        else if (value === 'X') {
            error = this.props.required && this.props.showMissingErrors && isEmpty(this.state.valueX);
            return (
                <React.Fragment>
                    {cmb}
                    <TextField
                        required={true}
                        InputProps={{readOnly:this.props.readOnly}}
                        error={error}
                        id={this.props.field + 'X'}
                        label={t("Sub code")}
                        InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap'} }}
                        inputProps={{type: "text", maxLength: 2 }}
                        value={this.state.valueX}
                        onChange={this.handleXChanged}
                        className={this.props.classes.textField}
                        margin="dense"
                    />
                </React.Fragment>
            );
        }
        else if (value === "year") {
            error = this.props.required && this.props.showMissingErrors && isEmpty(this.state.valueYear);
            return(
                <React.Fragment>
                    {cmb}
                    <TextField
                        required={true}
                        InputProps={{readOnly:this.props.readOnly}}
                        id={this.props.field+'Y'}
                        label={t("Jaar")}
                        InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap'} }}
                        value={this.state.valueYear}
                        onChange={this.handleYearChanged}
                        className={this.props.classes.textField}
                        margin="dense"
                        type="number"
                        inputProps={{ min: MINYEAR, max: MAXYEAR, step: 1 }}
                        error={this.state.valueYearError}
                    />
                </React.Fragment>
            );
        }
        else {
            return (cmb);
        }
    }

    handleValueChanged = (ev) => {
        try {
            const newValue = ev.target.value;
            this.props.streng[this.props.field] = newValue;
            this.setState({value: newValue});
            activeProjectService.markDirty();
            if (this.props.onChange) {
                this.props.onChange(newValue);
            }
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

    handleZChanged = (ev) => {
        try {
            this.props.streng[ev.target.id] = ev.target.value;
            this.setState({valueZ: ev.target.value});
            activeProjectService.markDirty(ev.target.id);
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

    handleXChanged = (ev) => {
        try {
            this.props.streng[ev.target.id] = ev.target.value;
            this.setState({valueX: ev.target.value});
            activeProjectService.markDirty(ev.target.id);
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

    handleYearChanged = (ev) => {
        try {
            let value = +ev.target.value;
            this.props.streng[ev.target.id] = value;
            this.setState({valueYear: value, valueYearError: !this.isYearOk(value)});
            activeProjectService.markDirty(ev.target.id);
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

    /**
     * checks is the value is within range.
     * @param {text or number} value the value to check
     */
    isYearOk(value) {
        if (value !== null && value !== undefined) {
            if (value > MAXYEAR){
                return false;
            }
            else if (value < MINYEAR) {
                return false;
            }
        }
        return true;
    }

}

StrengInfoComboBox.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    streng: PropTypes.object,                 // a reference to the streng for which we are currently showing data.
    required: PropTypes.bool,
    options: PropTypes.array.isRequired,
    secondarySource: PropTypes.string,          // currently for inspectionDirection, so that there can be other UI elements that provide a value. When changed, this should become the new state
    onChange: PropTypes.func,                   // currently for inspectionDirection, so that other ui elements can be warned that the value has changed.
    showMissingErrors: PropTypes.bool,                       // when true, inputboxes that require a value but dont, should show an error
    readOnly: PropTypes.bool,                           // for viewer
};

StrengInfoComboBox.defaultProps = {
    required: false
}

export default withTranslation()(withStyles(styles)(StrengInfoComboBox));