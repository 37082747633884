/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import VideoBox from 'mdi-material-ui/VideoBox';
import { TopSideIcon, RightSideIcon, LeftSideIcon, TopTractorIcon, SideCamIcon, TopCamIcon, SideTractrorIcon, FrontTractorIcon } from '../../../icons';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Speedometer from './speedometer_component';
import ImageRotationComponent from './image_rotation_component';
import Paper from '@material-ui/core/Paper';
import { documentControlService } from '../../document_control_service';
import CustomSlider from './custom_slider_component';
import Gauge from './gauge_component';
import { moveContent } from '../../../services/lists';
import BatteryIndicator from './battery_indicator_component';
import CamerapPosition from './camera_pos_component';
import DistanceGauge from './distance_gauge_component';



const styles = (theme) => ({
    colBox: {
        flexDirection: "column",
        display: 'flex',
        paddingTop: '4px',
        alignItems: 'stretch',
        justifyContent: 'center'
    },
    colBox2: {
        flexDirection: "column",
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingTop: '4px',
    },
    colBox3: {
        flexDirection: "column",
        display: 'flex',
        paddingTop: '4px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    rowBox: {
        flexDirection: "row",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    },
    divider: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
});


class DashboardBody extends React.PureComponent {
    constructor(props) {
        super(props);

        this.lastTimestamp = 0;                                     // for calculating the speed
        this.resizeTimer = null;                                    // so we don't calculate the radius too often.
        this.state = {
        }
        this.speed = 0.0;
        this.data = {};
        this.speedHandle = null;                                    // for faster assigning of values without rerendering this element fully
        this.panHandle = null;
        this.camPosHandle = null;
        this.liftPosHandle = null;
        this.rollHandle = null;
        this.pitchHandle = null;
        this.yawHandle = null;
        this.tiltHandle = null;
        this.camPressureHandle = null;
        this.leftWheelPressureHandle = null;
        this.rightWheelPressureHandle = null;
        this.tractorPressureHandle = null;
        this.batteryHandle = null;                                  // battery only
        this.batteryHandle2 = null;                                 // combo battery & lifeLeft
        this.laserRangeHandle = null;

        this.panTxt = props.t('camera pan');                        // faster rendering
        this.tiltTxt = props.t('camera tilt');
        this.panPosTxt = props.t('camera position');                        // faster rendering
        this.yawTxt = props.t('tractor yaw');
        this.pitchTxt = props.t('tractor pitch');
        this.speedTxt = props.t("speed");
        this.rollTxt = props.t('tractor roll');
        this.rollAlert = props.t('Roll alert');
        this.pitchAlert = props.t('Pitch alert');
        this.camPressureTxt = props.t('pressure camera housing');
        this.leftWheelPressureTxt = props.t('pressure left wheel/track');
        this.rightWheelPressureTxt = props.t('pressure right wheel/track');
        this.tractrPressureTxt = props.t('pressure tractor');
        this.batteryTxt = props.t('battery level');
        this.batteryWarningTxt = props.t('Battery low');
        this.batteryMinTxt = props.t('Battery critical');
        this.laserRangeTxt = props.t("Afstand");
    }

    componentDidMount() {
        documentControlService.events.addListener('onRobotData', this.handleRobotDataArrived);
        if (documentControlService.robotDs) {
            let data = documentControlService.robotDs.getLastEvent();
            if (data) {
                this.handleRobotDataArrived(data);
            }
        }
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onRobotData', this.handleRobotDataArrived);
    }


    handleRobotDataArrived = (value) => {
        let speed = 0;
        if (!this.lastTimestamp) {
            this.lastTimestamp = Date.now();
        }
        else {
            const newTimestamp = Date.now();
            if (this.speedHandle) {
                const timeDif = (newTimestamp - this.lastTimestamp) / 3600000;      // comes in as msec, convert to H
                const distanceDif = (value.distance - this.data.distance) / 1000;  // it comes in as meter, convert to km (km/h)
                speed = (distanceDif / timeDif);
            }
            this.lastTimestamp = newTimestamp;
        }
        this.data = value;
        if (this.speedHandle) this.speedHandle.setValue(speed);
        if (this.camPosHandle) {
            this.camPosHandle.setValue(value.tilt ?? 0.0, value.pan ?? 0.0);
        }
        else if (this.panHandle) {
            this.panHandle.setValue(value.pan ?? 0);
        }
        else if (this.tiltHandle) {
            this.tiltHandle.setValue(value.tilt ?? 0);
        }
        if (this.liftPosHandle) this.liftPosHandle.setValue(value.liftPos);
        if (this.rollHandle) this.rollHandle.setValue(value.roll);
        if (this.pitchHandle) this.pitchHandle.setValue(value.pitch);
        if (this.yawHandle) this.yawHandle.setValue(value.yaw);
        if (this.camPressureHandle) this.camPressureHandle.setValue(value.lensPressure);
        if (this.batteryHandle) this.batteryHandle.setValue(value.battery);
        if (this.batteryHandle2) this.batteryHandle2.setValues(value.battery, value.lifeLeft);
        
        if (this.leftWheelPressureHandle) this.leftWheelPressureHandle.setValue(value.leftDrivePressure);
        if (this.rightWheelPressureHandle) this.rightWheelPressureHandle.setValue(value.rightDrivePressure);
        if (this.tractorPressureHandle) this.tractorPressureHandle.setValue(value.pressure);
        if (this.laserRangeHandle) this.laserRangeHandle.setValue(value.laserRange);
    }


    render() {
        switch (this.props.layout) {
            case 0:
                return this.render2Row();
            case 1:
                return this.render3Row();
            case 2:
                return this.render1Col();
            case 3:
                return this.render2Col();
            default:
                return null;
        }
    }

    renderControls() {
        let result = {leftSide: [], rightSide: [], slider: null, cam: []};
        
        this.renderSpeed(result.leftSide);
        this.renderRoll(result.leftSide);
        this.renderYaw(result.leftSide);
        this.renderPitch(result.leftSide);
        result.slider = this.renderSlider();

        this.renderCamera(result.cam);
        this.renderBattery(result.cam);
        this.renderMeasureDistance(result.cam);

        this.renderCamPressure(result.rightSide);
        this.renderTractorPressure(result.rightSide);
        this.renderLeftWheelPressure(result.rightSide);
        this.renderRightWheelPressure(result.rightSide);
        return result;
    }

    render2Row() {
        const { classes } = this.props;
        const ctrls = this.renderControls();
        this.reshuffleForFor2Row(ctrls);
        const {leftSide, cam, rightSide, slider} = ctrls;
        let result = [];
        if (leftSide.length <= 2) {
            result.push(
                <div className={classes.colBox} key={result.length}> {leftSide} </div>
            );
        }
        else {
            result.push(
                <div className={classes.colBox} key={result.length}>
                    <div className={classes.rowBox}>
                        {leftSide[0]}
                        {leftSide[1]}
                    </div>
                    <div className={classes.rowBox}>
                        {leftSide[2]}
                        {(leftSide.length > 3) && leftSide[3]}  
                    </div>
                </div>
            );
        }
        if (slider && rightSide.length === 0) {                                             // only put the slider here if there are no pressure gauges, otherwise better to put it between cam pos and pressure gauge
            result.push(<div className={classes.colBox2} key={result.length}> {slider} </div>)
        }
        if (cam.length > 0) {
            result.push(
                <div className={classes.colBox} key={result.length}>
                    <div className={classes.rowBox} style={{ marginTop: '2px', marginBottom: '2px' }}> {cam[0]} </div>
                    { (cam.length > 1) && 
                        <div className={classes.rowBox}> {cam[1]} </div>
                    }
                </div>
            );
        }
        if (slider && rightSide.length > 0) {  
            result.push(<div className={classes.colBox2} key={result.length}> {slider} </div>)
        }
        if (rightSide.length > 0) {
            result.push(
                <div className={classes.colBox} key={result.length}>
                    <div className={classes.rowBox} style={{ marginTop: '2px', marginBottom: '2px' }}> 
                        {rightSide[0]}
                        {(rightSide.length > 2) && rightSide[2]                     // switch this with the first in the second row, so that in case of 2 items, we don't consume 2 cols but 1
                        }                    
                    </div>
                    <div className={classes.rowBox}> 
                        {(rightSide.length > 1) && rightSide[1]}
                        {(rightSide.length > 3) && rightSide[3]}  
                    </div>
                    
                </div>
            );
        }
        return result;
    }

    /**
     * ctrls should contain the following fields
     * @param {array} leftSide items that normally go to the left if all are present
     * @param {array} cam items related to the camera position
     * @param {array} rightSide items that normally go to the right side if all are present.these are pressure related
     */
    reshuffleForFor2Row(ctrls) {
        const {leftSide, cam, rightSide} = ctrls;
        if (leftSide.length === 1) {                            // only speedometer (or other?), 3 spots available
            if (rightSide.length === 3) {                       // there are 3 pressure meters, fits perfectly
                moveContent(rightSide, leftSide);
            }
            else if (cam.length === 2) {                        // 2 cams, move to left, either with open spot or pressure to the right of the speedometer 
                if (rightSide.length === 1) {
                    moveContent(rightSide, leftSide);
                }
                else {
                    leftSide.push(<div key={leftSide.length} style={{flex:'50%'}}/>);
                }
                moveContent(cam, leftSide);
            }
            else if (rightSide.length === 2) {                   // there are 2 pressure gauges: top becomes either 1 cam or 0, bottom are the gauges
                if (cam.length === 1) {
                    moveContent(cam, leftSide);
                }
                else {
                    leftSide.push(<div key={leftSide.length} style={{flex:'50%'}}/>);
                }
                moveContent(rightSide, leftSide);
            }
            else if (cam.length === 1) {                          // max we can fill is 3
                if (rightSide.length === 1) {
                    moveContent(rightSide, leftSide);
                }
            }
            else if (rightSide.length === 1) {
                moveContent(rightSide, leftSide);
            }
        }
        else if (leftSide.length === 2) {                          // 2 top ones on the left
            if (cam.length > 0) {           
                moveContent(cam, leftSide);                         // add 1 or more cameras if possible
            }
            while (leftSide.length < 4 && rightSide.length > 0) {
                leftSide.push(rightSide.shift());
            }
        }
        else if (leftSide.length === 3) {
            if (cam.length === 1) {
                leftSide.splice(leftSide.length - 2, 0, cam.shift());
            }
            else if (cam.length === 2){
                leftSide.splice(2, 0, <div style={{flex:'50%'}}/>);  // insert an empty slot on leftside so that  the 2 last ones align nicely (usually speedo is to the left now)
            }
            else if (cam.length === 3) {
                leftSide.splice(2, 0, cam.pop());                   // move last from cam to 3 on leftside so that leftside is even number
            }
        }
        else if (rightSide.length === 2) {
            if (cam.length > 0) {
                rightSide.splice(0, 0, cam.shift());            // insert at pos 0 so that the camera gauges come to the left
            }
            if (cam.length > 0) {
                rightSide.splice(2, 0, cam.shift()); 
            }
        }
        else if (rightSide.length === 1 && cam.length === 1) {
            moveContent(cam, rightSide);
        }
        else if(rightSide.length % 2 === 0 && leftSide.length % 2 === 0 && cam.length === 1 ) {
            cam.push(<div key={cam.length} style={{flex:'50%'}}/>);
        }
        else if (rightSide.length === 0 && leftSide.length === 0 && cam.length === 3) {
            rightSide.push(cam.pop())
            rightSide.push(<div key={cam.length} style={{flex:'50%'}}/>);
        }
    }


    render3Row() {
        const { classes } = this.props;
        const ctrls = this.renderControls();
        this.reshuffleForFor3Row(ctrls);
        //const thirdLeft = Math.ceil(ctrls.leftSide.length / 3);
        //const thirdRight = Math.ceil(ctrls.rightSide.length / 3);
        return (
            <React.Fragment>
                <div className={classes.colBox3}>

                    {ctrls.leftSide.slice(0, 3).map((item, idx) => {
                        return(
                            <div className={classes.rowBox} key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>

                <div className={classes.colBox3}>
                    {ctrls.leftSide.slice(3, 6).map((item, idx) => {
                        return(
                            <div className={classes.rowBox} key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>

                <div className={classes.colBox3}>
                    {ctrls.leftSide.slice(6, 9).map((item, idx) => {
                        return(
                            <div className={classes.rowBox} key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>

                {(ctrls.slider) &&
                    <div className={classes.colBox2} > {ctrls.slider} </div>
                }

                <div className={classes.colBox3}>

                    {ctrls.rightSide.slice(0, 3).map((item, idx) => {
                        return(
                            <div className={classes.rowBox} key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>

                <div className={classes.colBox3}>
                    {ctrls.rightSide.slice(3, 6).map((item, idx) => {
                        return(
                            <div className={classes.rowBox} key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>
            </React.Fragment>
        );
    }

    /**
     * ctrls should contain the following fields
     * @param {array} leftSide items that normally go to the left if all are present
     * @param {array} cam items related to the camera position
     * @param {array} rightSide items that normally go to the right side if all are present.these are pressure related
     */
     reshuffleForFor3Row(ctrls) {
        const {leftSide, cam, rightSide} = ctrls;

        let nrEmptyInLastRow = (3 - (leftSide.length % 3)) % 3;                             // need to invert the nr (3-x), but value 3 needs to become 0 again, so take second mod
        let extraOnRightSide = rightSide.length % 3;
        if (nrEmptyInLastRow === cam.length) {
            moveContent(cam, leftSide);
        }
        else if (nrEmptyInLastRow === 1 && extraOnRightSide === 2 && cam.length === 2) {            // common situation: 2 pressure-gauges to the right, 4 position dials to the left, 2 cams
            const last = leftSide.pop()
            moveContent(cam, leftSide);
            leftSide.push(last);
        }
        else if (nrEmptyInLastRow > 0 && extraOnRightSide > 0) {
            moveContent(rightSide, leftSide);
            moveContent(cam, rightSide);
        }
        else if (rightSide.length != 3) {
            const rightSideAllPressureGaughes = rightSide.length === 2 && rightSide[0].props.type === "camPressure" && rightSide[1].props.type === "tractorPressure";
            if (rightSideAllPressureGaughes) {
                if (cam.length > 0) {                           // if there are any left in cam, add them to the end
                    moveContent(cam, rightSide);
                }
                while(rightSide.length % 3 !== 0) {
                    rightSide.push(<div />);    // insert an empty slot on leftside so that  the 2 last ones align nicely (usually speedo is to the left now)
                }
            }
            else {
                moveContent(cam, leftSide);
                nrEmptyInLastRow = (3 - (leftSide.length % 3)) % 3;                             // need to invert the nr (3-x), but value 3 needs to become 0 again, so take second mod
                extraOnRightSide = rightSide.length % 3;
                while (nrEmptyInLastRow > 0 && extraOnRightSide > 0) {
                    leftSide.push(rightSide.shift());
                    nrEmptyInLastRow--;
                    extraOnRightSide--;
                }
                while (leftSide.length % 3 !== 0) {                                         // make certain that we end up with something dividable by 3 so that it is rendered at the correct location
                    leftSide.push(<div key={leftSide.length} style={{flex:'50%'}}/>);
                }
            }
        }
        
        //if (nrEmptyInLastRow === 1) {
        //    leftSide.splice(leftSide.length - 2, 0, null);
       // }
        //else if (nrEmptyInLastRow === 2) {
        //    leftSide.splice(leftSide.length - nrEmptyInLastRow, 0, null, null);
       // }
     }


    render1Col() {
        const { classes } = this.props;
        const ctrls = this.renderControls();
        return(
            <React.Fragment>
                <div className={classes.colBox3}>
                    {ctrls.leftSide.map((item, idx) => {
                        return(
                            <div className={classes.rowBox}  key={idx}>
                                {item}
                            </div>
                        )})
                    }

                    {ctrls.cam.map((item, idx) => {
                        return(
                            <div className={classes.rowBox}  key={idx}>
                                {item}
                            </div>
                        )})
                    }

                    {ctrls.rightSide.map((item, idx) => {
                        return(
                            <div className={classes.rowBox}  key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>
                {(ctrls.slider) &&
                    <div className={classes.colBox2} > {ctrls.slider} </div>
                }
            </React.Fragment>
        ); 
    }

    render2Col() {
        const { classes } = this.props;
        const ctrls = this.renderControls();
        const all = [...ctrls.leftSide, ...ctrls.cam, ...ctrls.rightSide];
        if (all.length % 2 !== 0) {                                     // need to make it an even number so that the right column aligns up correctly
            all.push(<div key={all.length} style={{flex:'50%'}}/>);
        }
        const half = all.length / 2;
        return(
            <React.Fragment>
                <div className={classes.colBox3}>
                    {all.slice(0, half).map((item, idx) => {
                        return(
                            <div className={classes.rowBox} key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>
                {(ctrls.slider) &&
                    <div className={classes.colBox2} > {ctrls.slider} </div>
                }
                <div className={classes.colBox3} >
                    {all.slice(half, all.length).map((item, idx) => {
                        return(
                            <div className={classes.rowBox}  key={idx}>
                                {item}
                            </div>
                        )})
                    }
                </div>
            </React.Fragment>
        ); 
    }

    renderBattery(result) {
        const robot = documentControlService.robotDs;
        if (robot.hasBattery() && robot.hasLifeLeft()) {
            result.push(<BatteryIndicator value={this.data.battery} lifeLeft={this.data.lifeLeft} radius={this.props.btnRadius} tooltip={this.batteryTxt} handle={(handle) => this.batteryHandle2 = handle} 
                         key="battery" type="battery" warningTxt={this.batteryWarningTxt} minTxt={this.batteryMinTxt} showLifeLeft={true}/>);
        }
        else if (robot.hasBattery()) {
            result.push(<BatteryIndicator value={this.data.battery} radius={this.props.btnRadius} tooltip={this.batteryTxt} handle={(handle) => this.batteryHandle = handle} 
                         key="battery" type="battery" warningTxt={this.batteryWarningTxt} minTxt={this.batteryMinTxt}/>);
        }
    }


    renderLeftWheelPressure(result) {
        const robot = documentControlService.robotDs;
        const limits = robot.sensorLimits?.leftWheelPressure;
        if (robot.hasWheelPressure()) {
            result.push(<Gauge value={this.data.lensPressure} radius={this.props.btnRadius} tooltip={this.leftWheelPressureTxt} handle={(handle) => this.leftWheelPressureHandle = handle} 
                        image={LeftSideIcon} limits={limits} key="lWheelPressure" type="lWheelPressure"/>);
        }
    }

    renderRightWheelPressure(result) {
        const robot = documentControlService.robotDs;
        const limits = robot.sensorLimits?.rightWheelPressure;
        if (robot.hasWheelPressure()) {
            result.push(<Gauge value={this.data.lensPressure} radius={this.props.btnRadius} tooltip={this.rightWheelPressureTxt} handle={(handle) => this.rightWheelPressureHandle = handle} 
                        image={RightSideIcon} limits={limits} key="rWheelPressure" type="rWheelPressure"/>);
        }
    }

    renderCamPressure(result) {
        const robot = documentControlService.robotDs;
        const limits = robot.sensorLimits?.lensPressure;
        if (robot.hasLensPressure()) {
            result.push(<Gauge value={this.data.lensPressure} radius={this.props.btnRadius} tooltip={this.camPressureTxt} handle={(handle) => this.camPressureHandle = handle} 
                        image={VideoBox} limits={limits} key="camPressure" type="camPressure"/>);
        }
    }

    renderTractorPressure(result) {
        const robot = documentControlService.robotDs;
        const limits = robot.sensorLimits?.tractorPressure;
        if (robot.hasLensPressure()) {
            result.push(<Gauge value={this.data.lensPressure} radius={this.props.btnRadius} tooltip={this.tractrPressureTxt} handle={(handle) => this.tractorPressureHandle = handle} 
                        image={TopSideIcon} limits={limits} key="tractorPressure" type="tractorPressure"/>);
        }
    }

    renderMeasureDistance(result) {
        const robot = documentControlService.robotDs;
        if (robot.hasLaserMeasure()) {
            const max = robot.sensorLimits?.measureDistance;
            result.push(
                <DistanceGauge value={0} radius={this.props.btnRadius} tooltip={this.laserRangeTxt} handle={(handle) => this.laserRangeHandle = handle} key="laserRange" type="laserRange" max={max}/>
            );
        }
    }

    renderCamera(result) {
        //
        const robot = documentControlService.robotDs;
        if (robot.hasCameraPan() && robot.hasCameraTilt()) {
            result.push(<CamerapPosition pan={this.data.pan} tilt={this.data.tilt} radius={this.props.btnRadius} tooltip={this.panPosTxt} handle={(handle) => this.camPosHandle = handle} key="campos" 
                            type="campos" readOnly={this.props.readOnly}/>);
        }
        else if (robot.hasCameraPan()) {
            result.push(<ImageRotationComponent value={this.data.pan} radius={this.props.btnRadius} image={TopCamIcon} tooltip={this.panTxt} showStroke={false} 
                    handle={(handle) => this.panHandle = handle} key="pan" type="pan"/>);
        }
        else if (robot.hasCameraTilt()) { 
            result.push(<ImageRotationComponent value={this.data.tilt} radius={this.props.btnRadius} image={SideCamIcon} startLeft={true} tooltip={this.tiltTxt} showStroke={false} 
                    handle={(handle) => this.tiltHandle = handle} key="tilt"  type="tilt"/>);
        }
    }

    renderYaw(result) {
        const robot = documentControlService.robotDs;
        if (robot.hasYaw()) {
            result.push(<ImageRotationComponent value={this.data.yaw} radius={this.props.btnRadius} image={TopTractorIcon} tooltip={this.yawTxt} showStroke={false} 
                    handle={(handle) => this.yawHandle = handle} key="yaw" type="yaw"/>);
        }
    }
    renderPitch(result) {
        const robot = documentControlService.robotDs;
        if (robot.hasPitch()) {
            const max = robot.sensorLimits?.pitch;
            result.push(<ImageRotationComponent value={this.data.pitch} radius={this.props.btnRadius} image={SideTractrorIcon} startLeft={true} tooltip={this.pitchTxt} showStroke={true} 
                    handle={(handle) => this.pitchHandle = handle} max={max} key="pitch" type="pitch" alertMsg={this.pitchAlert} readOnly={this.props.readOnly}/>);
        }
    }
    renderSpeed(result) {
        const robot = documentControlService.robotDs;
        if (robot.hasDistance()) {
            result.push(<Speedometer value={this.speed} radius={this.props.btnRadius} tooltip={this.speedTxt} handle={(handle) => this.speedHandle = handle} key="speed" type="speed"/>);
        }
    }
    renderRoll(result) {
        const robot = documentControlService.robotDs;
        if (robot.hasRoll()) {
            const max = robot.sensorLimits?.roll;
            result.push(<ImageRotationComponent value={this.data.roll} radius={this.props.btnRadius} image={FrontTractorIcon} startLeft={true} tooltip={this.rollTxt} showStroke={true} 
                    handle={(handle) => this.rollHandle = handle} max={max} key="roll" type="roll" alertMsg={this.rollAlert} readOnly={this.props.readOnly}/>);
        }
    }
    renderSlider() {
        const robot = documentControlService.robotDs;
        if (robot.hasElevator()) {
            return (
                <CustomSlider value={this.data.liftPos} handle={(handle) => this.liftPosHandle = handle} type="elevator"/>
            );
        }
    }

}

DashboardBody.defaultProps = {
    btnRadius: 5,
 }

 
DashboardBody.propTypes = {
    readOnly: PropTypes.bool,
    allowedHeight: PropTypes.number.isRequired,             // the height that the dashboard can use, determins the eventual size of the buttons
    streng: PropTypes.object,                               // ref to the streng, so we refresh when streng has changed
    btnRadius: PropTypes.number,                         // radius of the button to use
    layout: PropTypes.number,                               // type of layout to use (see render)
    initLoaded: PropTypes.bool,                             // so we reload when the data has been loaded, needed for tablet mode, otherwise we miss the gauges
};

export default withTranslation()(withStyles(styles)(DashboardBody));