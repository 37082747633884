/**
 * build the filename for a video belonging to a specific streng.
 * @param {object} data root data object (should contain a 'main' field)
 * @param {object} streng streng object
 * @param {bool} storageFormat when true, the filename starts with VS_, otherwise with V_
 */
export function buildReportVideoFilename(data, streng, includeExt = false, storageFormat = false, channel = null) {
    const abh = data.main.codeInsp.split("/");
    const afb = data.creationDate.split("-").join("");                    // this removes all '-'
    const ext = includeExt ? ".mp4" : "";
    const start = storageFormat ? "VS" : "V";
    const abj = data.main.opdrCodeOpdrGever;
    const strengIdx = streng.id.toString().padStart(3, "0");
    const isMultiDef = Object.entries(streng.cams).length > 1;                        // when multiple cameras, need to render name of files a little different.
    channel = (isMultiDef && channel) ? `_${channel}` : "";                                 // only use _ if not null
    const result = `${start}_${abj}_${abh[0]}_${afb}_${strengIdx}${channel}`;
    return replaceInValidChars(result) + ext;
}

export const INVALID_PATH_CHARS = ['/', '\\', '?', '%', '*', ':', '|', '"', '<', '>', '.', ',', ';', '='];

export function replaceInValidChars(input) {
    for (const char of INVALID_PATH_CHARS) {
        input = input.replaceAll(char, '_');
    }
    return input;
}

/**
 * generate the filename for the fotos
 * @param {object} data the root data object, should contain a 'main'
 * @param {number/object} streng the index / id (1 based) of the streng or the streng itself when object is specified.
 * @param {object} obs the observation
 * @param {bool} includeExt when true, the extension will be included.
 */
export function buildReportFotoFilename(data, streng, obs, includeExt = true) {
    let abh = data.main.codeInsp.split("/");
    let afb = data.creationDate.split("-").join("");
    let ext = includeExt ? ".jpg" : "";

    let result;
    if (obs) {
        let recTime = streng.recStartTime + (obs.time);
        let recTimeStr = new Date(recTime).toISOString().substr(11, 11).split(":").join("").replace(".", "");       //replace all ':' , replace 1 '.'
        let obsIdx = streng.observations.indexOf(obs);
        result = `P_${data.main.opdrCodeOpdrGever}_${abh[0]}_${afb}_${recTimeStr}_${streng.id}_${obsIdx}`;
    }
    else {
        result = `PS_${data.main.opdrCodeOpdrGever}_${abh}_${afb}_${streng}`;               // streng here is expected to be a number (1 based streng idx)
    }
    return replaceInValidChars(result) + ext;
}

/**
 * generate the filename for the sonar images
 * @param {object} data the root data object, should contain a 'main'
 * @param {number/object} streng the index / id (1 based) of the streng or the streng itself when object is specified.
 * @param {object} obs the observation
 * @param {bool} includeExt when true, the extension will be included.
 */
 export function buildReportSonarFilename(data, streng, obs, includeExt = true) {
    return internalBuildReportImageFilename('sonar', data, streng, obs, includeExt);
}

/**
 * generate the filename for the lidar images
 * @param {object} data the root data object, should contain a 'main'
 * @param {number/object} streng the index / id (1 based) of the streng or the streng itself when object is specified.
 * @param {object} obs the observation
 * @param {bool} includeExt when true, the extension will be included.
 */
 export function buildReportLidarFilename(data, streng, obs, includeExt = true) {
    return internalBuildReportImageFilename('lidar', data, streng, obs, includeExt);
}

/**
 * generate a filename for the images that are generated from data
 * @param {string} front text to put in front of the filename (sonar/lidar)
 * @param {object} data the root data object, should contain a 'main'
 * @param {number/object} streng the index / id (1 based) of the streng or the streng itself when object is specified.
 * @param {object} obs the observation
 * @param {bool} includeExt when true, the extension will be included.
 */
 function internalBuildReportImageFilename(front, data, streng, obs, includeExt = true) {
    let abh = data.main.codeInsp.split("/");
    let afb = data.creationDate.split("-").join("");
    let ext = includeExt ? ".jpg" : "";

    let result;
    let recTime = streng.recStartTime + (obs.time);
        let recTimeStr = new Date(recTime).toISOString().substr(11, 11).split(":").join("").replace(".", "");       //replace all ':' , replace 1 '.'
        let obsIdx = streng.observations.indexOf(obs);
        result = `${front}_${data.main.opdrCodeOpdrGever}_${abh[0]}_${afb}_${recTimeStr}_${streng.id}_${obsIdx}`;
    return replaceInValidChars(result) + ext;
}

/**
 * generate the filename for the fotos
 * this was the original version, still available so we can build it to find the old files
 * @param {object} data the root data object, should contain a 'main'
 * @param {number/object} streng the index / id (1 based) of the streng or the streng itself when object is specified.
 * @param {object} obs the observation
 * @param {bool} includeExt when true, the extension will be included.
 */
export function oldBuildReportFotoFilename(data, streng, obs, includeExt = true) {
    let abh = data.main.codeInsp.split("/");
    let afb = data.creationDate.split("-").join("");
    let ext = includeExt ? ".jpg" : "";

    let result;
    if (obs) {
        let recTime = streng.recStartTime + (obs.time);
        let recTimeStr = new Date(recTime).toISOString().substr(11, 11).split(":").join("").replace(".", "");       //replace all ':' , replace 1 '.'
        result = `P_${data.main.opdrCodeOpdrGever}_${abh[0]}_${afb}_${recTimeStr}`;
    }
    else {
        result = `PS_${data.main.opdrCodeOpdrGever}_${abh}_${afb}_${streng}`;               // streng here is expected to be a number (1 based streng idx)
    }
    return replaceInValidChars(result) + ext;
}