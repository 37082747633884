/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Dashboard from './dashboard/dashboard_component';
import { configService } from '../../../services/config_service';
import { documentControlService } from '../document_control_service';
import { trackService } from './track/track_server_service';
import { layoutService } from '../layout/layout_service';
import { downloadService } from '../../services/download_service';
import { activeProjectService } from '../../../services/active_project_service';
import SplitPane from 'react-split-pane';
import RobotSectionBody from './robot/robot_section_body_component';


const styles = (theme) => ({
    root: {
    },
    track: {
        paddingBottom: theme.spacing(1),
        overflow: 'hidden',                             // important: needed to make certain that the sonar-canvas shrinks when making view smaller
        flex: 1,
        position: 'relative',
    },
    paper: {
        height: '100%',
        flexDirection: "row",
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        display: 'flex',
    },
});



/**
 * returns the display mode that should be used. Checks if there still is an old
 * config for radarDisplayMode, if so, uses that, udpates the config and remove
 * the old config
 */
function getDisplayMode() {
    let mode = configService.get('layout.document.radarDisplayMode');
    if (mode) {
        switch (mode) {
            case 'sonar':
                configService.set('layout.document.robotSection.parts.1.source', "sonar");
                mode = 'single';
                break;
            case 'lidar':
                configService.set('layout.document.robotSection.parts.1.source', "lidar");
                mode = 'single';
                break;
            case 'combo':
                configService.set('layout.document.robotSection.parts.1.source', "inclination");
                configService.set('layout.document.robotSection.parts.2.source', "lidar");
                configService.set('layout.document.robotSection.parts.3.source', "sonar");
            case 'duoVer':
                configService.set('layout.document.robotSection.parts.1.source', "lidar");
                configService.set('layout.document.robotSection.parts.2.source', "sonar");
            case 'duoHor':
                configService.set('layout.document.robotSection.parts.1.source', "lidar");
                configService.set('layout.document.robotSection.parts.2.source', "sonar");
            default:
                break;
        }
        configService.set('layout.document.robotSection.displayMode', mode);
        configService.clear('layout.document.radarDisplayMode');                                // old key, no longer used
    }
    else {
        mode = configService.get('layout.document.robotSection.displayMode');
    }
    const robot = documentControlService.robotDs;
    if (!robot.hasLidar() && !robot.hasSonar()) {                                               // if there is no sonar or lidar, no need to show multiple windows.
        return "single";
    }
    return mode;
}

class RobotSection extends React.PureComponent {
    constructor(props) {
        super(props);

        let dashboardOpen = configService.get('layout.document.robotSection.dashboardOpen');
        if (dashboardOpen === undefined) {
            dashboardOpen = false;
        }
        let horSplit1 = configService.get('layout.document.robotSection.HorSplit1');
        let horSplit2 = configService.get('layout.document.robotSection.HorSplit2');
        let verSplit1 = configService.get('layout.document.robotSection.VerSplit1');
        let displayMode = getDisplayMode();
        this.state = {
            dashboardOpen: dashboardOpen === true || dashboardOpen === "true",                     // to show/hide the dashboard
            displayMode: displayMode,                                                     // how the radar data is displayed: sonar & lidar togeter or individually and how
            horSplit1: horSplit1,
            horSplit2: horSplit2,
            verSplit1: verSplit1,
        }

        this.splitPaneRef1 = React.createRef();
        this.splitPaneRef2 = React.createRef();
        this.trackRef = React.createRef();                                     // ref to div that contains the body. Used to extract it's width, so we can set the max size of the splitpane (only available after 1st render, no problem if only used for max size) 
    }

    componentDidMount() {                               
        layoutService.events.on('changed', this.handleLayoutChanged);
        documentControlService.events.addListener('onRobotDataLoaded', this.handleRobotReady);
    }

    componentWillUnmount() {
        layoutService.events.removeListener('changed', this.handleLayoutChanged);
        documentControlService.events.removeListener('onRobotDataLoaded', this.handleRobotReady);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.allowedHeight !== 0 && this.props.allowedHeight != prevProps.allowedHeight) {
            const dif = (this.props.allowedHeight - prevProps.allowedHeight) / 2;
            let horSplit1 = this.state.horSplit1 + dif;
            let horSplit2 = this.state.horSplit2 + dif;
            if (horSplit1 < 30) horSplit1 = 30;
            if (horSplit2 < 30) horSplit2 = 30;

            if (this.state.horSplit1 !== horSplit1) {
                this.setState({horSplit1: horSplit1})
            }
            if (this.state.horSplit2 !== horSplit2) {
                this.setState({horSplit2: horSplit2})
            }
        }
        if (this.props.verSplit != prevProps.verSplit) {
            if (this.state.verSplit1 > this.props.verSplit) {
                this.setState({verSplit1: this.props.verSplit - 10})
            }
        }
        if (this.props.liveFeed !== prevProps.liveFeed) {                           // when switching between live and recorded, the robot config can change.
            if (this.props.liveFeed === true) {                                     // only need to do this when going to live mode. When going to video mode, the robotDataLoaded event comes after this. If we dont wait for the event,we get a unneeded jumps in the ui
                const displayMode = getDisplayMode();
                if (this.state.displayMode !== displayMode) {
                    this.setState({displayMode: displayMode});
                }
            }
        }
    }

    render() {
        const robot = documentControlService.robotDs;
        if (!robot || !this.props.initLoaded || this.props.allowedHeight === 0) {                                     // important: when not yet fully loaded (no engine config), dont render dashboard yet, cause otherwise we get 2 render passes, first for dashboard only, then a combo, which screws up the size of the dials onthe dashboard
            return null;
        }
        
        const allowedHeight = this.props.allowedHeight - this.props.theme.spacing(2);
        return (
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                { (this.state.dashboardOpen) &&
                    <Dashboard allowedHeight={allowedHeight} streng={this.props.streng} readOnly={this.props.readOnly || !this.props.liveFeed} />
                }
                <div className={this.props.classes.track} ref={this.trackRef}>
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    renderContent() {
        const { classes } = this.props;
        const allowedHeight = this.props.allowedHeight - this.props.theme.spacing(2);                       // the spacing is from the padding inside body? (originally from padding inside section itself)
        const verSplit = this.state.dashboardOpen ? this.props.verSplit - 1 : this.props.verSplit;          // versplit is only used as a repaint instruction for the radar. when dashboard opens/closes need to force a repaint as well, so use this trick
        let height, height2, width;
        let trackRef = this.trackRef.current;
        let maxWidth;
        switch (this.state.displayMode) {
            case 'single':
                return (this.renderSectionBody(1, allowedHeight, true, verSplit));
            case 'duoHor':
                height = this.state.horSplit1 ?? (allowedHeight / 2) - this.props.theme.spacing(1);
                return (
                    <SplitPane split="horizontal"
                        ref={this.splitPaneRef1}
                        maxSize={allowedHeight - 60}
                        minSize={30}
                        style={{ flex: 1, position: 'relative', overflow: 'unset' }}
                        size={height}
                        onChange={this.storeHorSplitPos1}
                        onDragStarted={() => (document.body.style.cursor = 'ns-resize')}
                        onDragFinished={() => (document.body.style.cursor = 'auto')}
                    >
                        {this.renderSectionBody(1, height - this.props.theme.spacing(2), false, verSplit)}
                        {this.renderSectionBody(2, allowedHeight-height-8, true, verSplit)}
                    </SplitPane>
                );
            case 'duoVer':
                maxWidth = trackRef ? trackRef.clientWidth : verSplit;
                width = this.state.verSplit1 ?? (maxWidth / 2) - this.props.theme.spacing(1);
                return (
                    <SplitPane split="vertical"
                        ref={this.splitPaneRef1}
                        maxSize={maxWidth - 30}
                        minSize={30}
                        style={{ flex: 1, position: 'relative', overflow: 'unset' }}
                        size={width}
                        onDragStarted={() => (document.body.style.cursor = 'ew-resize')}
                        onDragFinished={() => (document.body.style.cursor = 'auto')}
                        onChange={this.storeVerSplitPos1}
                    >
                        {this.renderSectionBody(1, allowedHeight, true, width)}
                        {this.renderSectionBody(2, allowedHeight, false, maxWidth - width)}
                    </SplitPane>
                );
                break;
            case 'tripleHor':
                height = this.state.horSplit1 ?? (allowedHeight / 3) - this.props.theme.spacing(1);
                height2 = this.state.horSplit2 ?? (allowedHeight / 3) - this.props.theme.spacing(1);
                return (
                    <SplitPane split="horizontal"
                        ref={this.splitPaneRef1}
                        maxSize={height + height2 + 8 - 30}
                        minSize={30}
                        style={{ flex: 1, position: 'relative', overflow: 'unset' }}
                        size={height}
                        onDragStarted={() => (document.body.style.cursor = 'ns-resize')}
                        onDragFinished={() => (document.body.style.cursor = 'auto')}
                        onChange={this.storeHorSplitPos1}
                    >
                        {this.renderSectionBody(1, height - this.props.theme.spacing(2), false, verSplit)}
                        <div style={{position: 'absolute', inset: '0px'}}>
                            <SplitPane split="horizontal"
                                ref={this.splitPaneRef2}
                                maxSize={allowedHeight - height - 65}
                                minSize={30}
                                style={{ flex: 1, position: 'relative', overflow: 'unset' }}
                                pane2Style={{ height: "10px" }}
                                size={height2}
                                onDragStarted={() => (document.body.style.cursor = 'ns-resize')}
                                onDragFinished={() => (document.body.style.cursor = 'auto')}
                                onChange={this.storeHorSplitPos2}
                            >
                                {this.renderSectionBody(2, height2 - this.props.theme.spacing(2), false, verSplit)}
                                {this.renderSectionBody(3, allowedHeight - height - height2 - this.props.theme.spacing(2), true, verSplit)}
                            </SplitPane>
                        </div>
                    </SplitPane>
                );
            case 'combo':
                height = this.state.horSplit1 ?? (allowedHeight / 2) - this.props.theme.spacing(1);
                maxWidth = trackRef ? trackRef.clientWidth : verSplit;
                width = this.state.verSplit1 ?? (maxWidth / 2) - this.props.theme.spacing(1);
                return (
                    <SplitPane split="horizontal"
                        ref={this.splitPaneRef1}
                        maxSize={allowedHeight - 60}
                        minSize={30}
                        style={{ flex: 1, position: 'relative', overflow: 'unset' }}
                        size={height}
                        onDragStarted={() => (document.body.style.cursor = 'ns-resize')}
                        onDragFinished={() => (document.body.style.cursor = 'auto')}
                        onChange={this.storeHorSplitPos1}
                    >
                        {this.renderSectionBody(1, height - this.props.theme.spacing(2), false, verSplit)}
                        <SplitPane split="vertical"
                            ref={this.splitPaneRef2}
                            maxSize={maxWidth-30}
                            minSize={30}
                            style={{ flex: 1, position: 'relative', overflow: 'unset' }}
                            size={width}
                            onDragStarted={() => (document.body.style.cursor = 'ew-resize')}
                            onDragFinished={() => (document.body.style.cursor = 'auto')}
                            onChange={this.storeVerSplitPos1}
                        >
                            {this.renderSectionBody(2, allowedHeight - height - this.props.theme.spacing(2), true, width)}
                            {this.renderSectionBody(3, allowedHeight - height - this.props.theme.spacing(2), false, maxWidth - width)}
                        </SplitPane>
                    </SplitPane>
                );
            default:
                console.log("internal error: unknown layout type for robot section");
                return (null);
        }
    }

    renderSectionBody(configId, allowedHeight, showDashboardFunctions, verSplit) {
        return (
            <RobotSectionBody liveFeed={this.props.liveFeed} 
                readOnly={this.props.readOnly}
                showDashboardFunctions={showDashboardFunctions}
                dashboardOpen={this.state.dashboardOpen}
                onToggleDashboard={this.handleToggleDashboard}
                showMainCrawlerFunctions={configId===1}
                onFindTimeForDistance={this.findTimeForDistance}
                allowedHeight={allowedHeight}
                resizeRequest={this.props.allowedHeight}        /* the allowedHeight sometimes changes together with the width, there is no trigger yet for the width, but it alwasy triggers props.allowedHeight, which can differ from the local allowedHeight, that didn't change. Need this to get the sonar/lidar crossection-view to resize when switching tabs or between live/video */
                configId={configId}
                initLoaded={this.props.initLoaded}
                verSplit={verSplit}
                streng={this.props.streng}
                direction={this.props.direction}
                onDirectionChanged={this.props.onDirectionChanged}
                changePosAllowed={this.props.changePosAllowed}
                onPositionChanged={this.props.onPositionChanged}
                hasReverseTrackData={this.props.hasReverseTrackData}
                onSelectLayout={this.handleSelectLayout}
                selectedLayout={this.state.displayMode}
                onToggleVideoSection={this.props.onToggleVideoSection}
                showVideoSection={this.props.showVideoSection}
                controlState={this.props.controlState}
                measureData={this.props.measureData}
                onMeasured={this.props.onMeasured}
            />
        );
    }

    /**
     * called when the robot data (and lidar,sonar data) is loaded (for video playback)
     * it's only at this moment that we can select the display mode
     */
    handleRobotReady = () => {
        const displayMode = getDisplayMode();
        if (this.state.displayMode !== displayMode) {
            this.setState({displayMode: displayMode});
        }        
    }

    storeVerSplitPos1 = (width) => {
        configService.set('layout.document.robotSection.VerSplit1', width);
        this.setState({ verSplit1: width });
    }

    storeHorSplitPos1 = (height) => {
        if (this.state.displayMode === 'tripleHor') {
            const dif = this.state.horSplit1 - height;
            let split2 = this.state.horSplit2 + dif;
            if (split2 < 30) {
                split2 = 30;
                height = this.state.horSplit1;                  // if we reached the max, don't allow resizing anymore
            }
            configService.set('layout.document.robotSection.HorSplit1', height);
            if (this.state.horSplit1 !== height || this.state.horSplit2 !== split2) {
                this.setState({ horSplit1: height, horSplit2: split2 });
            }
            else {
                this.forceUpdate();                             // need to update cause the splitpane did an update on the ui, but we prevented it
            }
        }
        else {
            configService.set('layout.document.robotSection.HorSplit1', height);
            this.setState({ horSplit1: height });
        }
    }

    storeHorSplitPos2 = (height) => {
        configService.set('layout.document.robotSection.HorSplit2', height);
        this.setState({ horSplit2: height });
    }

    /**
     * from trackview, when user wants to show dashboard
     */
    handleToggleDashboard = () => {
        configService.set('layout.document.robotSection.dashboardOpen', this.state.dashboardOpen);
        this.setState({ dashboardOpen: !this.state.dashboardOpen });
    }

   

    handleSelectLayout = (value) => {
        if (value) {                                            // when null, menu closed without value selected
            configService.set('layout.document.robotSection.displayMode', value);
            this.setState({displayMode: value});
        }
    }

    handleLayoutChanged = () => {
        let dashboardOpen = configService.get('layout.document.dashboardOpen');
        
        let horSplit1 = configService.get('layout.document.robotSection.HorSplit1');
        let horSplit2 = configService.get('layout.document.robotSection.HorSplit2');
        let verSplit1 = configService.get('layout.document.robotSection.VerSplit1');
        let displayMode = getDisplayMode();
        this.setState({
            dashboardOpen: dashboardOpen === true || dashboardOpen === "true", 
            displayMode: displayMode,                                                     // how the radar data is displayed: sonar & lidar togeter or individually and how
            horSplit1: horSplit1,
            horSplit2: horSplit2,
            verSplit1: verSplit1
            });
    }

    /**
     * converts a distance value to a timestamp, based on the tractor data
     * @param {number} distance the distance to convert to time
     */
    findTimeForDistance = (distance) => {
        const data = trackService.raw;
        if (data && this.props.onPositionChanged) {
            for (const rec of data) {
                if (distance <= rec.tractorDistance) {
                    this.props.onPositionChanged(rec.timestamp);
                    break;
                }
            }
        }
    }

    
}

RobotSection.propTypes = {
    liveFeed: PropTypes.bool,
    direction: PropTypes.string,                            // allowed values: 
    onDirectionChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    changePosAllowed: PropTypes.bool,                        // when true, clicking on the track allows changing position. This is not allowed when the application is recording.
    readOnly: PropTypes.bool,                               // for viewer
    initLoaded: PropTypes.bool.isRequired,                  // need to know when initial loading is done, so we can read the engine cofnig correctly
    allowedHeight: PropTypes.number.isRequired,             // the height that the dashboard can use, determins the eventual size of the buttons
    verSplit: PropTypes.number,                             // needed for radar to repaint
    onToggleVideoSection: PropTypes.func,                   // called to show/hide the video section
    showVideoSection: PropTypes.bool,                       // when true, the video section is shown, otherwise it is collapsed
    onOpenVideoScreen: PropTypes.func,                      // callback for the button to open new screen for video
    streng: PropTypes.object,                               // ref to the streng, so we refresh when streng has changed
    hasReverseTrackData: PropTypes.bool,                    // true when the current streng has reverse robot/track data
    controlState: PropTypes.string,                         // so other parts can see when a reverse-tractor recording is starting
    measureData: PropTypes.any,                             // when lidar is available, we can perform measurements with that sensor, so we need to respond to measument requests
    onMeasured: PropTypes.func,                             // called when measurement is done on the lidar image
};

export default withTheme(withTranslation()(withStyles(styles)(RobotSection)));