/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */



import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InlineSVG from 'svg-inline-react';
import { activeProjectService } from '../../../../services/active_project_service';
import ReactResizeDetector from 'react-resize-detector';
import { trackService } from './track_server_service';
import { SIDE_BORDER_SIZE, SvgTrackService } from './svg_track_service';
import { withTranslation } from 'react-i18next';
import { dialogService } from '../../../../services/dialog_service';
import { errExtractor } from '../../../services/error_extractor';
import { documentControlService } from '../../document_control_service';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = (theme) => ({
    root: {
       
    },
});

const svgParentStyle = {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex'
};


class TrackView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.prevTime = 0;                                      // previous value for current time
        this.gotoDistance = null;                               // when assigned, a jump to position was requested. This is stored so we can go to the closest distance (some timestamps can have multiple distances)
        this.svg = new SvgTrackService();                       // responsible for building the svg image of the track
        this.svg.liveFeed = props.liveFeed;
        this.svg.isUpstream = props.direction === "B";
        this.svg.renderStandingWater = props.renderStandingWater;
        this.svg.renderVideoPos = !props.reportMode;
        this.svg.renderMarkers = props.renderMarkers;
        this.svg.renderLabels = props.renderMarkers;
        this.svg.renderStartEndLabels = props.renderStartEndLabels;
        this.svg.adjustHeightDif = props.adjustHeightDif;
        this.svg.renderSeriesOutline = props.renderSeriesOutline;
        this.svg.renderSeriesSimple = props.renderSeriesSimple;
        this.svg.distanceStart = props.distanceStart;
        this.svg.renderObsDistances = props.showObsDistances;
        if (props.reportMode === false) {                           // need to make a difference between live view and when used on a report
            this.svg.streng = activeProjectService.activeStreng;
            this.svg.data = trackService.data;
        }
        else {
            this.svg.streng = props.streng;
            this.svg.data = props.trackData;
            this.svg.svgWidth = "651.188";  //-30
            this.svg.svgHeight = '605.953';
        }
        if (props.showObsDistances && props.streng) {               // do last so that all other svg fields are set (required for report, otherwise svg.steng will not yet be set)
            this.svg.xLabels = this.buildObsDistances(props.streng.observations);
        }
        let trackSource = (props.reportMode === true) ? this.svg.buildSvg() : '';       // needs to be called afte all svg fields have been set

        this.state = {
            trackSource: trackSource,
            isLoading: props.reportMode === false && documentControlService.robotDs?.isLoading ? true : false,  // when true, loading data from the robot
        }

    }

    componentDidMount() {
        try {
            if (this.props.reportMode === false) {                                                  // in report mode trackdata is already loaded
                documentControlService.events.addListener('onRobotDataLoaded', this.handleRobotDataLoaded);
                documentControlService.events.addListener('onRobotDataLoading', this.handleRobotLoading);
                this.handleRobotDataLoaded();                                                       // in case the data was already loaded
                trackService.onUpdateData = this.handleUpdateData;
                if (this.props.strengDetails == null) {                                 // only track active streng and changes when not in report mode and no specific streng was selected.
                    activeProjectService.events.on('dirty', this.handleStrengContentChanged);
                    activeProjectService.events.on('strengChanged', this.handleStrengChanged);
                }
                documentControlService.events.on('onCurrentTime', this.handleCurrentTimeChanged);
            }
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Tractor"), errExtractor.get(error) );
        }
    }

    componentWillUnmount() {
        trackService.onUpdateData = null;
        activeProjectService.events.removeListener('dirty', this.handleStrengContentChanged);           // if we have or not, always try to remove.
        activeProjectService.events.removeListener('strengChanged', this.handleStrengChanged);
        documentControlService.events.removeListener('onCurrentTime', this.handleCurrentTimeChanged);
        documentControlService.events.removeListener('onRobotDataLoaded', this.handleRobotDataLoaded);
        documentControlService.events.removeListener('onRobotDataLoading', this.handleRobotLoading);
    }

    componentDidUpdate(prevProps, prevState) {
        let needRebuild = false;
        if (prevProps.direction !== this.props.direction) {                  // if there is a change in direction, triggered outside this component, need to rebuild the svg cause calcualtion of standing water has changed.
            let isUpstream = this.props.direction === "B";
            if (isUpstream !== this.svg.isUpstream) {
                this.svg.isUpstream = isUpstream;
                needRebuild = true;
            }
        }
        if (prevProps.liveFeed !== this.props.liveFeed) {                   // changed livefeed type, so reload data
            this.svg.liveFeed = this.props.liveFeed;
            this.handleStrengChanged();                                     // calling strengchanged, cause both trigger the same action
        }
        if (prevProps.reportMode !== this.props.reportMode) {
            if (this.props.reportMode === false && this.props.strengDetails == null) {
                activeProjectService.events.on('dirty', this.handleStrengContentChanged);
                activeProjectService.events.on('strengChanged', this.handleStrengChanged);
            }
            else {
                activeProjectService.events.removeListener('dirty', this.handleStrengContentChanged);           // if we have or not, always try to remove.
                activeProjectService.events.removeListener('strengChanged', this.handleStrengChanged);
            }
        }
        if (prevProps.streng !== this.props.streng && this.props.reportMode === true) {
            this.svg.streng = this.props.streng;
            if (this.props.showObsDistances) {
                this.svg.xLabels = this.buildObsDistances(this.props.streng.observations);
            }
            needRebuild = true;
        }
        if (prevProps.trackData !== this.props.trackData && this.props.reportMode === true) {
            this.svg.data = this.props.trackData;
            needRebuild = true;
        }
        if (prevProps.sampleDistance !== this.props.sampleDistance) {
            this.svg.data = trackService.data;
            needRebuild = true;
        }
        if (prevProps.renderStandingWater != this.props.renderStandingWater) {
            this.svg.renderStandingWater = this.props.renderStandingWater;
            needRebuild = true;
        }
        if (prevProps.adjustHeightDif != this.props.adjustHeightDif) {
            this.svg.adjustHeightDif = this.props.adjustHeightDif;
            needRebuild = true;
        }
        if (prevProps.renderSeriesOutline !== this.props.renderSeriesOutline) {
            this.svg.renderSeriesOutline = this.props.renderSeriesOutline;
            needRebuild = true;
        }
        if (prevProps.renderSeriesSimple !== this.props.renderSeriesSimple) {
            this.svg.renderSeriesSimple = this.props.renderSeriesSimple;
            needRebuild = true;
        }
        if (prevProps.renderMarkers != this.props.renderMarkers) {
            this.svg.renderMarkers = this.props.renderMarkers;
            this.svg.renderLabels = this.props.renderMarkers;
            needRebuild = true;
        }
        if (prevProps.renderStartEndLabels !== this.props.renderStartEndLabels) {
            this.svg.renderStartEndLabels = this.props.renderStartEndLabels;
            needRebuild = true;
        }
        if (prevProps.showReverse !== this.props.showReverse) {
            this.handleRobotDataLoaded();
        }
        if (prevProps.showObsDistances !== this.props.showObsDistances) {
            if (this.props.showObsDistances) {
                this.svg.xLabels = this.buildObsDistances(this.svg.streng.observations);
            }
            else {
                this.svg.xLabels = null;
            }
            needRebuild = true;
        }
        if (needRebuild) {
            this.setState({ trackSource: this.svg.buildSvg() });
        }
    }

    render() {
        if (this.state.isLoading && !this.props.reportMode) {
            const { t } = this.props;
            return(
                <div className={this.props.classes.root} style={{justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress color="secondary" />
                    <div>{t("Loading")}</div>
                </div>
            );
        }
        return (
            <div style={{ flex: 1, height: '100%', position: 'relative', width: "100%" }}>
                {(!this.props.reportMode) && 
                    <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
                }
                <InlineSVG src={this.state.trackSource} element="div" style={svgParentStyle} onClick={this.handleClick} />
            </div>
        );
    }

    buildObsDistances(obsList) {
        let offset = 0;
        let raw = this.props.rawTrackData ? this.props.rawTrackData : trackService.raw;
        if (!raw || raw.length === 0) {
            return null;
        }
        let uniqueValues = [...new Set(obsList.map((el) => el.longitude))];      // get the list of unique distances so we know where to put stuff
        if (this.props.showReverse) {
            if (!this.svg.streng) {
                return;
            }
            offset = raw[raw.length-1].tractorDistance - this.svg.streng.actualInspectionLength;     // check if we are short in distance or not
            if (offset > 0) {       
                uniqueValues.splice(0,0, -offset);                                  // when returning reverse data, and went further back then original startpoint, we need to add the real start
            }
            else {
                while(uniqueValues.length > 0 && uniqueValues[0] < offset) {        // tractor didnt go back to the full start, so the beginning of hte original is dropped
                    uniqueValues.shift();
                }
            }
    
            raw.forEach((el) => {                                      // build a lookup from original tractor distances to flattened distances
                el.tractorDistance -= offset;
            });
            let result = uniqueValues.map((val) => {                                 // combine the original distances from the observations with the flattened distances.
                let distances = raw.map((el, idx) => { return {distance: Math.abs(el.tractorDistance - val), idx: idx}});
                distances.sort((a, b) => a.distance - b.distance);
                const mappedDistance = raw[distances[0].idx].flatHorDist;
                return {label: val.toFixed(2), distance: mappedDistance};
            });
            return result;
        }
        else {
            let lookup = {};
            raw.forEach((el) => {                                      // build a lookup from original tractor distances to flattened distances
                lookup[el.tractorDistance] = el.flatHorDist;
            });
            let result = uniqueValues.map((el) => {                                 // combine the original distances from the observations with the flattened distances.
                return {label: el, distance: lookup[el]}
            });
            return result;   
        }


        /**
         * 
         * let offset = 0;                                                          // when the tractor data comes from the reverse data set, there can be difference in total length for which we need to adjust
        const raw = trackService.raw;
        if (this.props.showReverse && raw.length > 0) {
            offset = raw[raw.length-1].tractorDistance - this.svg.streng.actualInspectionLength;
        }
        let uniqueValues = [...new Set(obsList.map((el) => el.longitude))];      // get the list of unique distances so we know where to put stuff
        let lookup = {};
        raw.forEach((el) => {                                      // build a lookup from original tractor distances to flattened distances
            lookup[Math.round(el.tractorDistance *100)/100] = el.flatHorDist;
        });
        let result = uniqueValues.map((el) => {                                 // combine the original distances from the observations with the flattened distances.
            const idx = Math.round((el + offset) * 100) / 100;
            return {label: el, distance: lookup[idx]}
        });
        return result;
         */
    }

    handleResize = (width, height) => {
        this.svg.svgWidth = width;
        this.svg.svgHeight = height - 7;
        this.setState({ trackSource: this.svg.buildSvg() });
    }

    handleUpdateData = (record) => {
        if (record) {
            this.setState({ trackSource: this.svg.addData(record) });
        }
    }

    /**
     * called when any xml data in the project has changed.
     * used to update the svg whenever certain fields in the streng have changed.
     * @param {string} field the name of the field that changed
     */
    handleStrengContentChanged = (field) => {
        const fields = { "depthStartPointRel": true, "depthEndPointRel": true, "expectedInspectionLength": true };     //dict of values that needs to be found
        if (field in fields) {
            this.setState({ trackSource: this.svg.buildSvg() });
        }
    }

    /**
     * reload the entire image after the trackService has loaded the data.
     */
    handleStrengChanged = () => {
        setTimeout(() => {                                                                                          // doing this to make it easier to debug. when run without this, the debugger hangs on breakpoints.
            try {
                if (this.props.reportMode === false) {
                    this.svg.streng = activeProjectService.activeStreng;
                }
                this.svg.resetData();
                trackService.reset();
                this.svg.data = trackService.data;                                                                  // need to reset the data, otherwise we lost the reference
                this.svg.isUpstream = this.svg.streng && this.svg.streng.inspectionDirection === "B";
                this.setState({ trackSource: this.svg.buildSvg() });
            }
            catch(error) {
                const { t } = this.props;
                dialogService.error(t("Tractor"), errExtractor.get(error) );
            }
        }, 0);
    }

    handleRobotDataLoaded = () => {
        setTimeout(async () => {                                                                                          // doing this to make it easier to debug. when run without this, the debugger hangs on breakpoints.
            try {
                this.setState({ isLoading: false });
                if (this.props.showReverse) {
                    await trackService.load(this.svg.streng, true);                                                     // need the reverse data
                }
                else {
                    const robot = documentControlService.robotDs;
                    if ((this.props.liveFeed === false || this.props.readOnly) && robot && robot.data) {
                        trackService.loadData(robot.data);
                    }
                    else {
                        trackService.reset();
                    }
                }
                this.svg.resetData();
                this.svg.data = trackService.data;
                if (this.props.showObsDistances && this.svg.streng) {
                    this.svg.xLabels = this.buildObsDistances(this.svg.streng.observations);
                }
                this.setState({ trackSource: this.svg.buildSvg() });
            }
            catch(error) {
                const { t } = this.props;
                dialogService.error(t("Tractor"), errExtractor.get(error) );
            }
        }, 0);
    }

    handleRobotLoading = () => {
        this.setState({isLoading: true});
    }

    handleCurrentTimeChanged = (value) => {
        if (this.prevTime !== value || this.gotoDistance) {
            const data = trackService.raw;
            let newPos = 0;
            let advanced = false;
            if (value === 0) {
                this.svg.videoPosDistanceIdx = -1;
            }
            else {
                if (value < this.prevTime || this.svg.videoPosDistanceIdx === data.length-1) {       // going back, easiest is to just jump back to start and calculate from there (but slower then trying to walk back). also capture any search errorst that went all the way to the end
                    this.svg.videoPosDistanceIdx = -1;
                }
                let idx = this.svg.videoPosDistanceIdx + 1;
                while (idx < data.length) {
                    let item = data[idx];
                    let distanceOk = this.gotoDistance === null || (this.gotoDistance >= newPos && this.gotoDistance < newPos + item.distance);
                    if (item.timestamp >= value && distanceOk) break;        // important: must be >=, otherwise we skip items when clicking on the trackview
                    newPos = item.flatHorDist;
                    advanced = true;
                    this.svg.videoPosDistanceIdx = idx;
                    idx++;
                }
            }
            if (advanced) {
                this.svg.videoPosDistance = newPos;
                this.setState({ trackSource: this.svg.compileSvg() });
            }
            this.prevTime = value;
            this.gotoDistance = null;
        }
    }

    handleClick = (ev) => {
        if (!this.props.reportMode) {
            if (this.props.onPositionChanged) {
                let distance = ev.nativeEvent.offsetX;                          // the distance that the user wants to jump too.
                if (this.svg.renderStartEndLabels) {
                    distance -= SIDE_BORDER_SIZE;
                }
                distance /= this.svg.widthAdjuster;                             // adjust distance from screen coordinates, this gives us the flattened horizontal ditance, still need to translate
                const time = trackService.flattenedDistanceToTime(distance);
                this.gotoDistance = distance;
                    this.props.onPositionChanged(time);
            }
        }
    }
}

TrackView.propTypes = {
    streng: PropTypes.object,                               // optional ref to the streng. If not set, the active streng will be used (and tracked), 
    trackData: PropTypes.array,                             // optional list of trackdata to display. only used if streng is assigned. (no livefeed but for reporting)
    rawTrackData: PropTypes.array,                          // when mapping obs.distance to map-distance, we need the raw data, when not in live-view, use the supplied data
    liveFeed: PropTypes.bool,
    direction: PropTypes.string,                            // allowed values: 
    onDirectionChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    reportMode: PropTypes.bool,                             // when true, the component is used on a report and all interactive stuff should be turned off. 
    renderStandingWater: PropTypes.bool,                    // when true, standing water (puddles) will be rendered
    renderMarkers: PropTypes.bool,                           // when true, text markers are rendered.
    renderStartEndLabels: PropTypes.bool,                    // when true, text markers are rendered for the start & end position to label the exact values.
    changePosAllowed: PropTypes.bool,                        // when true, clicking on the track allows changing position. This is not allowed when the application is recording.
    adjustHeightDif: PropTypes.bool,                        // when true, the line will be adjusting so that the end appears to stop at the expected height
    distanceStart: PropTypes.number,                        // value that should be used as the start of the chart. Defaults to 0, needs to be set for reverse tractor record.
    readOnly: PropTypes.bool,                           // for viewer
    onFindTimeForDistance: PropTypes.func,                  // called when the user selects a distance which needs to be converted to time
    sampleDistance: PropTypes.number,                       // when user changes distance between 2 points, we need to redraw. the new data is in the trackservice. this is only for non-report mode
    renderSeriesOutline: PropTypes.bool,                    // when true, the errors are shown
    renderSeriesSimple: PropTypes.bool,                     // when true, the calculated single line is shown
    showReverse: PropTypes.bool,                            // when true, reverse tractor data will be shown (when available) from the currently active streng
    showObsDistances: PropTypes.bool,                       // when true, the x-axis labels show the distances of the observations

};

TrackView.defaultProps = {
    strengDetails: null,
    reportMode: false,
    renderStandingWater: false,
    renderMarkers: false,
    renderStartEndLabels: false,
    distanceStart: 0,
    sampleDistance: 0.1,
    renderSeriesSimple: true,
    showReverse: false,
    showObsDistances: true,
}

export default withTranslation()(withStyles(styles)(TrackView));