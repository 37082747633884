/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
*/

//icons
import CameraOutline from 'mdi-material-ui/CameraOutline';

import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import { IconButton, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { userService } from '../services/user_service';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import ConfirmationDialog from '../dialogs/confirmation_dialog';
import AccountDetailsDialog from './account_details_dialog';
import { authService } from '../services/auth_service';
import { dialogService } from '../../services/dialog_service';
import Badge from '@material-ui/core/Badge';
import { errExtractor } from '../services/error_extractor';
import { storageService } from '../../services/storage_service';
import isElectron from 'is-electron';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
    rootPopover: {
    },
    popoverBox: {
        margin: theme.spacing(2)
    },
    btn: {
    },
    accountBtn: {
        marginTop: theme.spacing(1)
    },
    subIcon: {
        background: theme.palette.background.paper,
        boxShadow: '0 1px 1px 0 rgba(65,69,73,0.3), 0 1px 3px 1px rgba(65,69,73,0.15);',
        padding: '2px',
        '&:hover': {
            background: theme.palette.background.paper,
        }
    },
    avatar: {
        backgroundColor: theme.palette.action.active,
        height: '24px',
        width: '24px'
    }

});



/**
 * UI element for managing the currently active user: config, logout..
 */
class Account extends React.PureComponent {
    constructor(props) {
        super(props);

        this.allowUploadFoto = isElectron();           // uploading from web page is not yet supported
        this.state = {
            user: null,
            avatar: "",
            dlgLogOutOpen: false,
            dlgAccountDtlsOpen: false,
            open: false,
            anchorEl: null
        };
    }

    componentDidMount() {
        // so that it is only called 1 time (constructor can be called multiple times)
        const user = userService.getCurrentUser();
        this.setState({ user: user });                                // make certain that it is always stored, even if avatar fails
        if (user) {
            storageService.getAvatarUrl(user.id).then((url) => {
                this.setState({ avatar: url });
            });
        }
    }

    render() {
        const { t } = this.props;
        const user = this.state.user;
        const fullName = user ? user.username : "";

        let badgeContent = null;
        if (this.allowUploadFoto) {
            badgeContent = <IconButton className={this.props.classes.subIcon}
                                onClick={this.selectImg}
                                disabled={!this.allowUploadFoto}>
                                <CameraOutline fontSize="small" />
                            </IconButton>
        }
        return (
            <React.Fragment>
                <IconButton onClick={this.toggleMenu} edge="start" color="inherit">
                    <Avatar src={this.state.avatar} alt={fullName} className={this.props.classes.avatar} />
                </IconButton>
                <Popover open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box flex={1} display="flex" flexDirection="column" alignItems="stretch" className={this.props.classes.rootPopover} >
                        <Box display="flex" flexDirection="column" alignItems="center" className={this.props.classes.popoverBox}>
                            <Badge
                                overlap="circle"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                                badgeContent={badgeContent}
                            >
                                <Avatar src={this.state.avatar} alt={fullName} style={{ height: '70px', width: '70px' }} />
                            </Badge>
                            <Typography style={{ textAlign: 'center', marginTop: '4px' }}>
                                <Box>{fullName}</Box>
                                <Box color="text.secondary">{user?.email}</Box>
                            </Typography>
                            <Button variant="outlined"
                                onClick={this.gotoAccountSettings}
                                disabled={!user}
                                className={this.props.classes.accountBtn}>
                                {t('Manage your account')}
                            </Button>
                        </Box>
                        {(this.props.canLogOut) &&
                            <React.Fragment>
                                <Divider />
                                {this.getlogInLogOutButton()}
                            </React.Fragment>
                        }
                        <Divider />
                        <Box display="flex" flexDirection="column" alignItems="center" className={this.props.classes.popoverBox}>
                            <Link href="https://cam-inspector.com/privacy.html" target="_blank" >
                                Privacy Policy
                            </Link>
                        </Box>
                    </Box>
                </Popover>
                <ConfirmationDialog title={t('Log out')}
                    question={t('Are you certain you want to log out?')}
                    open={this.state.dlgLogOutOpen}
                    onClose={this.handleLogOut}
                />
                {(this.state.dlgAccountDtlsOpen) &&
                    <AccountDetailsDialog open={this.state.dlgAccountDtlsOpen}
                        onClose={this.handleCloseAccountDetails}
                        user={this.state.user}
                        avatar={this.state.avatar}
                        allowUploadFoto={this.allowUploadFoto}
                    />
                }
            </React.Fragment>
        );
    }

    getlogInLogOutButton() {
        const { t } = this.props;
        if (this.state.user) {
            return (
                <Box display="flex" flexDirection="column" alignItems="center" className={this.props.classes.popoverBox}>
                    <Button variant="outlined"
                        onClick={this.askLogOut}
                        className={this.props.classes.btn}>
                        {t('Log out')}
                    </Button>
                </Box>
            )
        }
        else {
            return (
                <Box display="flex" flexDirection="column" alignItems="center" className={this.props.classes.popoverBox}>
                    <Button variant="outlined"
                        onClick={() => { this.props.history.replace('/login') }}
                        className={this.props.classes.btn}>
                        {t('Log in')}
                    </Button>
                </Box>
            )
        }
    }

    toggleMenu = (event) => {
        const newVal = !this.state.open;
        this.setState({ open: newVal, anchorEl: newVal ? event.currentTarget : null });
    }

    handleClose = () => {
        this.setState({ open: false, anchorEl: null });
    }

    handleCloseAccountDetails = (result) => {
        this.setState({ dlgAccountDtlsOpen: false });
        if (result) {
            if (result.user) { this.setState({ user: result.user }) };
            if (result.avatar) { this.setState({ avatar: result.avatar }) };
        }
    }

    /**
     * ask the user if he is certain he wants to log out, if so, do it, otherwise block furhter handling
     */
    askLogOut = (event) => {
        this.setState({ dlgLogOutOpen: true });
    }

    handleLogOut = async (result) => {
        this.setState({ dlgLogOutOpen: false, open: false });
        if (result) {
            try {
                await authService.logout();
                this.props.history.replace('/login');
            }
            catch (error) {
                const { t } = this.props;
                dialogService.error(t("Log out"), errExtractor.get(error));
            }
        }
    }

    gotoAccountSettings = () => {
        this.setState({ dlgAccountDtlsOpen: true });
    }

    selectImg = async () => {
        if (!this.state.user) {
            dialogService.error("account", "not logged in");
            return;
        }
        const imgs = dialogService.openImage();
        if (imgs && imgs.length > 0) {
            await storageService.uploadAvatar(imgs[0], this.state.user.id);
            const url = await storageService.getAvatarUrl(this.state.user.id);
            this.setState({ avatar: url });
        }
    }
}

Account.defaultProps = {
    canLogOut: true
}

Account.propTypes = {
    canLogOut: PropTypes.bool
}

export default withTranslation()(withStyles(styles)(withRouter(Account)));