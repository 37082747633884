class ScreenManager {
    getDefinition() {
        return {};
    }

    closeAll() {
        
    }

    applyDefinition(value) {
    }

    openVideoScreen() {
        
    }
}

export const screenManager = new ScreenManager();