/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons:

import React from 'react';
import '../../../App.css';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { cameraService } from '../../../components/document/video/camera_service';
import { activeProjectService } from '../../../services/active_project_service';
import { errExtractor } from '../../services/error_extractor';
import { dialogService } from '../../../services/dialog_service';
import { storageService } from '../../../services/storage_service';
import { WebRtcPlayer } from './webrtc_player_service';
import { documentControlService } from '../document_control_service';
import i18n from "i18next";

const styles = (theme) => ({
    hiddenStyle: {visibility: "hidden"},
    canvas: {display: 'none'},
});

class VideoChannelPlayer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.isStreaming = false;                                           // to prevent to many calls to setwidth/setheight
        this.currentTimeOffset = 0;
        this.webRtc = new WebRtcPlayer();        
        this.videoEl = React.createRef();
        this.canvas = React.createRef();
        this.notVideoFromCamera = null;                                        // stores if the current feed is from the live camera. This allows us to optimeze closing/opening of connections with the camera (keep open when going from live to live)
    }

    componentDidMount() {
        if (this.props.VideoRef) {
            this.props.VideoRef(this.videoEl, this.props.channel);
        }
        documentControlService.events.on('onDeleteStreng', this.handleStrengDeleted);
        this.attachHandlersToVideo();
        this.loadVideo();                                                   // make certain that the video is loaded
    }

    componentWillUnmount() {  
        if (this.props.VideoRef) {
            this.props.VideoRef(null, this.props.channel);
        }
        documentControlService.events.removeListener('onDeleteStreng', this.handleStrengDeleted);
        this.webRtc.destroy();
    }


    attachHandlersToVideo() {
        const video = this.videoEl.current;                
        if (!video) throw new Error("internal error: no video object");
        video.addEventListener('canplay', (ev) => {
            if (!this.isStreaming) {
                this.adjustWidthAndHeight();
                this.isStreaming = true;
            }
            if (this.props.onDurationChanged) {
                this.props.onDurationChanged(video.duration);
            }
            if (this.props.canPlay) {
                this.props.canPlay();
            }
        });
        video.addEventListener('ended', () => {
            if(this.props.onEnded) {
                this.props.onEnded();
            }
        });
        video.addEventListener('error', () => {
            console.log(video.error);
        });
        if (this.props.onCurrentTimeChanged) {                                          // only primary video controls time
            video.addEventListener('timeupdate', () => {
                if(this.props.onCurrentTimeChanged) {                                   // this 
                    const value = video.currentTime - this.currentTimeOffset;
                    this.props.onCurrentTimeChanged(value);
                }
            });
        }
    }

    /**
     * called when a streng is deleted from the project. If we have a file ref, remove it now so that
     * things can reload
     */
    handleStrengDeleted = () => {
        if (this.notVideoFromCamera) {
            const video = this.videoEl.current;
            if (video) {
                video.srcObject = null;
                video.src = "";
                video.removeAttribute('src');
                video.load();                                                                               // clears the buffers
            }
        }
    }

    /**
     * called when component is updated.
     */
    loadVideo() {
        this.currentTimeOffset = 0;
        const video = this.videoEl.current;
        if (!video) throw new Error("internal error: no video object");
        this.isStreaming = false;
        const notVideoFromCamera = process.env.REACT_APP_IS_VIEWER === 'true' || this.props.liveFeed === false || activeProjectService.loadedFrom === "cloud";
        if (this.notVideoFromCamera !== notVideoFromCamera) {
            this.webRtc.unloadCamera();                                        // any previous connection needs to be unloaded
            this.notVideoFromCamera = notVideoFromCamera;
            if (notVideoFromCamera) {
                if (activeProjectService.loadedFrom === "cloud") {
                    this.loadVideoFromCloud(video);
                }
                else {
                    this.loadVideoFromFile(video);
                }
            }
            else {
                this.loadVideoFromCamera(video);
            }
        }
        else if (notVideoFromCamera) {
            if (activeProjectService.loadedFrom === "cloud") {
                this.loadVideoFromCloud(video);
            }
            else {
                this.loadVideoFromFile(video);
            }
        }
        return true;
    }
    
    loadVideoFromCamera(video) {
        this.props.onIsLoading(this.props.channel, true);
        this.webRtc.start(video, this.props.channel, true, this.canvas.current).then(() => {
            this.props.onIsLoading(this.props.channel, false);
        });
    }


    handleVideoLoadError = (error) => {
        console.log(error);
        const { t } = this.props;
        this.props.onIsLoading(this.props.channel, false);
        dialogService.error(i18n.t("Video"), i18n.t("problem_loading_video", {error: errExtractor.get(error)}));
    }

    loadVideoFromFile(video) {
        const filename1 = activeProjectService.getVideoFileForCurrentStreng(this.props.channel, false);
        cameraService.tryFixRecording(filename1, activeProjectService.getVideoFileForCurrentStreng(this.props.channel, true)).then(() => {
            video.srcObject = null;
            video.src = null;
            video.load();                                                                               // clears the buffers, in case a video was manually added (overriding previous one), file is same name, different content.
            video.src = filename1;
        }).catch((error) => {
            this.handleVideoLoadError(error);
        });
    }

    loadVideoFromCloud(video) {
        const streng = this.props.activeStreng;
        video.srcObject = null;
        video.src = null;
        video.load();
        if (streng && streng.videoUrl && this.props.channel in streng.videoUrl) {       // make certain that there was a video stored,.
            storageService.getUrl(streng.videoUrl[this.props.channel], "video/mp4").then((url) => {
                video.src = url;
            }).catch((error) => {
                this.handleVideoLoadError(error);
            });
        } else {
            video.src = null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let videoLoaded = false;                                                                             // so we don't try to reload too often.
        if (prevProps.width !== this.props.width) {
            this.setWidth(this.props.width);
        }
        if (prevProps.height !== this.props.height) {
            this.setHeight(this.props.height);
        }
        if (prevProps.isMain !== this.props.isMain || this.props.isVisible !== prevProps.isVisible || this.props.showSameSizedVideos !== prevProps.showSameSizedVideos ) {      // became sub element or was removed as sub element.
            this.adjustWidthAndHeight();
        }
        if (prevProps.channel !== this.props.channel) {
            this.notVideoFromCamera = null;                                                                 // important: when channel switches, need to force reload of live camera as well
            videoLoaded = this.loadVideo();;
        }
        if ((prevProps.liveFeed !== this.props.liveFeed && this.props.controlState !== "processing") || prevProps.activeStreng !== this.props.activeStreng) {
            if (!videoLoaded) { 
                videoLoaded = this.loadVideo(); ;
            }
        }
        if(prevProps.controlState != this.props.controlState) {
            if (prevProps.controlState === "processing") {                                                  // if the state was processing, that now there is a new file available, so load it.
                if (!videoLoaded) {   
                    videoLoaded = this.loadVideo();
                }
            }
            this.updateControlState();
        }
        if (prevProps.currentTime !== this.props.currentTime && this.props.liveFeed === false) {            // if we change position in the video...
            const video = this.videoEl.current;
            video.currentTime = this.props.currentTime;
        }
    }

    updateControlState() {
        const { t } = this.props;
        const video = this.videoEl.current;
        if (!video) {
            dialogService.error(i18n.t("Video"), "internal error: no video object");
            return;
        }
        if (!this.props.liveFeed) {                                                            // when live feed from camera (liveFeed), video can also be stopped/paused. Don't want that. Warning: livefeed canalso be undefined?
            if(this.props.controlState === "play") {
                const prom = video.play();
                if (prom) {                                                                     // not all versions of chrome (or others) return a promise here, newer do, so catch any errors.
                    prom.catch((err) => {
                        dialogService.error(i18n.t("Video"), errExtractor.get(err));
                    });
                }
            }
            else if(this.props.controlState === "pause") {
                video.pause();
            }
            else if(this.props.controlState === "stopped" || this.props.controlState === "stop") {
                video.pause();
                video.currentTime = 0;
            }
        }
        else if (this.props.controlState === "record") {                                       // recording has turned on, make certain that currenttime is back to 0
            this.currentTimeOffset = video.currentTime;
            if(this.props.onCurrentTimeChanged) {
                this.props.onCurrentTimeChanged(0);
            }
        }
    }

    adjustWidthAndHeight() {
        if (this.props.isVisible) {
            if (this.props.isMain || this.props.showSameSizedVideos) {
                this.setWidth(this.props.width);
                this.setHeight(this.props.height);
            }
            else  {
                this.removeAttribsOfSub();
                this.props.onSizePosChanged();
            }
        }
    }

    setWidth(width) {
        if (!this.props.isVisible || (!this.props.isMain && !this.props.showSameSizedVideos)) return;                                              // no need to do this if not visible
        const video = this.videoEl.current;                                             
        if (video == undefined) return;                                                 // can be if user is too fast (happens during debugging after reload quickly followed by a back action)
        let height = video.videoHeight / (video.videoWidth / width);
        if (height < this.props.height) {      // don't resize if it would make the image larger than fits in the box left by the horizonal splitter.
            video.setAttribute('width', width);
            video.setAttribute('height', height);
            if (this.props.onSizeSet) {
                this.props.onSizeSet(width, height);
            }
        }
    }

    setHeight(height) {
        if (!this.props.isVisible || (!this.props.isMain && !this.props.showSameSizedVideos)) return;                                              // no need to do this if not visible
        const video = this.videoEl.current;
        if (video == undefined) return;                                                 // can be if user is too fast (happens during debugging after reload quickly followed by a back action)
        let width = video.videoWidth / (video.videoHeight / height);
        if (width < this.props.width) {
            video.setAttribute('width', width);
            video.setAttribute('height', height);
            if (this.props.onSizeSet) {
                this.props.onSizeSet(width, height);
            }
        }
    }

    /**
     * called when switching videos (big with small). Makes certain that the old big one's width and height attribs are gone.
     */
    removeAttribsOfSub () {
        const video = this.videoEl.current;
        if (video) {
            video.removeAttribute('width');
            video.removeAttribute('height');
            this.forceUpdate();                                 // for the icon
        }
    }

    render() {
        const { classes } = this.props;
        const autoPlay = !(process.env.REACT_APP_IS_VIEWER === 'true' || this.props.liveFeed === false || activeProjectService.loadedFrom === "cloud");

        let videoStyle = this.props.isVisible ? this.props.videoClassName : classes.hiddenStyle;   // when 2 elements, put main in top left corner
        return (
            <React.Fragment>
                <video className={videoStyle} ref={this.videoEl} autoPlay={autoPlay} onLoadedMetadata={this.props.onMetaLoaded}/>
                <canvas ref={this.canvas} className={classes.canvas} />
            </React.Fragment>
        );
    }
    
}

VideoChannelPlayer.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    onSizeSet: PropTypes.func,
    liveFeed: PropTypes.bool,
    controlState: PropTypes.string,
    currentTime: PropTypes.number,
    onCurrentTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onEnded: PropTypes.func,
    activeStreng: PropTypes.object,                 // so we can do an update on the data when the strengchange has been processed by the document
    channel: PropTypes.string,                      // the video channel to show


    isMain: PropTypes.bool,                         // when true, this video is shown as the main video
    isVisible: PropTypes.bool,                      // when true, this video is visible, otherwise it is hidden
    videoClassName: PropTypes.any,                  // the classname to use when the video is visible
    onMetaLoaded: PropTypes.func,                   // callback for when meta is loaded, used for sub item
    VideoRef: PropTypes.func,                       // called when the video element is avaiable. used to store the ref to the object so video-player can find the subelement
    onSizePosChanged: PropTypes.func,               // called when the pos/size of this video element has changed, so that the parent can do a repaint to reposition the button to switch screens
    onIsLoading: PropTypes.func,                    // for live cameras, lets parent know when loading starts and stops. fields: channelname, isLoading
    showSameSizedVideos: PropTypes.bool,            // when true, we are showing the inview (second) video at the same size as the main one. this is for view-modes where there is enough room to put the 2 side by side.
};

export default withStyles(styles)(VideoChannelPlayer);