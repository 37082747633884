/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

//icons
import ArrowUpCircleOutline from 'mdi-material-ui/ArrowUpCircleOutline';
import { Typography } from '@material-ui/core';
import { dialogService } from '../../../../services/dialog_service';
import { toDegrees } from '../../../services/cal';

const DOT_DEGREES = 30;             // 360 / 12 -> 12 dots, each dot occupies 30 degrees

const styles = (theme) => ({
    root: {
        position: 'relative'
    },
    rootExpanded: {
        position: 'relative',
        marginLeft:'16px'
    },
    errorRoot: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: 'thin solid red'
    },
    dotWrapper: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        pointerEvents: 'none'
    },
    dot: {
        position: 'absolute',
        top: '0px',
        bottom: '0px',
        height: '6px',
        width: '6px',
        borderRadius: '6px',
        borderWidth: 'thin',
        borderStyle: 'solid',
        pointerEvents: 'auto',
        background: 'transparent',
        borderColor: 'transparent',
        backgroundClip: "content-box",          // this makes the hit area larger so that hover reacts faster
        '&:hover': {
            background: '#d9d9d9',
            borderColor: '#d9d9d9',
        }
    },
    icon: {
        position: 'absolute',
        top: '3px',
        bottom: '3px',
        left: '3px',
        right: '3px'
    },
    requiredTxt: {
        position: 'absolute',
        top: '0px',
        left: '4px',
        height: '10px',
        lineHeight: 'initial',
        userSelect: 'none'
    }
});



class DirectionSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

        //this.rootRef = React.createRef();
        this.prevKey = null;                                        // need 2 keypresses in sequence shortly after each other. this is to buffer the first one.
        this.keyPressTimeout = null;                                // timer for resetting the keypress, so we can stop it.
    }

    componentDidMount() {
        if (this.props.inner && this.props.inner.current) {
            this.props.inner.current.addEventListener("keydown", this.onKeypress);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isExpanded !== this.props.isExpanded) {
            if (this.props.isExpanded) {
                this.props.inner.current.addEventListener("keydown", this.onKeypress);
            }
            else {
                this.props.inner.current.removeEventListener("keydown", this.onKeypress);
            }
        }
    }

    componentWillUnmount() {
        if (this.props.inner && this.props.inner.current) {
            this.props.inner.current.removeEventListener("keydown", this.onKeypress);
        }
    }

    render() {
        if (this.props.isExpanded) {
            return this.renderExpanded();
        }
        else {
            return this.renderCollapsed();
        }
    }

    renderExpanded() { 
        const { t } = this.props;
        let dots = [];
        let dotStyle = null;
        for(let i = 0; i < 12; i++) {
            if (!!this.props.value && i === (this.props.value % 12)) {                                             // need to do mod 12 cause 12 is top pos = 0
                dotStyle = {left:(this.props.size  -6) /2, background: "#3366ff", borderColor: '#3366ff',};
            }
            else {
                dotStyle = {left:(this.props.size  -6) /2};
            }
            dots.push(
                <div className={this.props.classes.dotWrapper}
                    key={i}
                    style={{width: this.props.size, height: this.props.size, transform: `rotate(${30 * i}deg)`}}>
                    <div className={this.props.classes.dot} 
                            style={dotStyle}
                        />
                </div>
            );
        }
        let required = (this.props.required) ? <Typography variant="body2" className={this.props.classes.requiredTxt}>*</Typography> : null;
        //note: first div is given tabindex to make it focusable
        let body =  <div className={this.props.classes.rootExpanded} 
                        style={{width: this.props.size, height: this.props.size}}
                        tabIndex="0"
                        ref={this.props.inner}
                        onFocus={this.props.onFocus}
                        onClick={this.handleClick}
                    >
                        <ArrowUpCircleOutline 
                            className={this.props.classes.icon} 
                            style={{fontSize: `${this.props.size-6}px`, transform: `rotate(${30 * this.props.value}deg)`}}
                        />
                        {dots}
                        {required}
                    </div>
        if (this.props.error) {
            body =  <div className={this.props.classes.errorRoot}>
                        {body}
                    </div>
        }
        if (!this.props.readOnly) {
            body =  <Tooltip title={t("direction_sel_value_tip", {value: this.props.value ?? ""})}>{body}</Tooltip>
        }
        else {
        body  = <Tooltip title={t("direction_sel_value", {value: this.props.value ?? ""})}>{body}</Tooltip>
        }
        return (body);
    }

    /**
     * to calculate, use:
     * tan(angle) = opposite/adjacent
     * 
     * where: opposite = y (relative to center)
     *        adjacent = x (relative to center)
     * @param {object} ev event
     */
     handleClick = (ev) => {
        if (this.props.readOnly) {                                          // no editing allowed in readonly mode
            return;
        }
        const center = this.props.size / 2;
        const pos = this.getRelMousePos(ev);
        let angle;
        if (pos.x > center && pos.y < center) {                         // Q1  (right-up)
            angle = 90 - toDegrees(Math.atan((pos.y - center) / (center - pos.x)));
        } else if (pos.x < center && pos.y < center) {                  // Q2 (left-up)
            angle = 270 + toDegrees(Math.atan((center - pos.y) / (center - pos.x)));
        } else if (pos.x < center && pos.y > center) {                  // Q3 (left-down)
            angle = 270 - toDegrees(Math.atan((center - pos.y) / (pos.x - center)));
        } else if (pos.x > center && pos.y > center) {                  // Q4 (right-down)
            angle = 90 + toDegrees(Math.atan((pos.y - center) / (pos.x - center)));
        }
        if (angle != undefined) {
            let value = Math.round(angle / DOT_DEGREES);
            if (value === 0) {
                value = 12;
            }
            this.props.onValueChanged(value, true);
        }
        else {
            if (pos.x === center) {                                  // at 6 or 12 o clock
                if (pos.y >= center)  {                                     // 12
                    this.props.onValueChanged(12, true);
                }
                else {                                                      // 6
                    this.props.onValueChanged(6, true);
                }
            } else if (pos.y === center) {                                  // at 9 or 3 o clock
                if (pos.x >= center) {                                      // 3
                    this.props.onValueChanged(3, true);
                } else {                                                    // 9
                    this.props.onValueChanged(9, true);
                }
            }
        }
    }

    /**
     * calculate the mouse position relative to the canvas.
     * @param {object} ev mouse event data
     */
     getRelMousePos(ev) {
        let rect  = this.props.inner.current.getBoundingClientRect();
        return { x: ev.clientX - rect.left, y: ev.clientY - rect.top };
    }

    renderCollapsed() {
        return (
            <div className={this.props.classes.root}  style={{width: this.props.size, height: this.props.size}}>
                <ArrowUpCircleOutline 
                    className={this.props.classes.icon} 
                    style={{fontSize: `${this.props.size-6}px`, transform: `rotate(${30 * this.props.value}deg)`}}
                />
            </div>
        );
    }

    onKeypress =(event) => {
        if (this.props.readOnly) {                                          // no editing allowed in readonly mode
            return;
        }
        let char = event.which || event.keyCode;
        if ((char >= 48 && char <= 57) || (char >= 96 && char <= 105) || char === 10 || char === 13) {
            if (this.prevKey == null) {
                if (this.keyPressTimeout) {
                    clearTimeout(this.keyPressTimeout);
                }
                this.prevKey = event.key;                           //store the string part, easier to convert later on
                this.keyPressTimeout = setTimeout(() => {
                    this.prevKey = null;
                    this.keyPressTimeout = null;
                }, 2000);                     // after x seconds, first press is reset so it can be seen as a fresh start.
            }
            else {
                if (this.keyPressTimeout) {
                    clearTimeout(this.keyPressTimeout);
                }
                let newValue;
                if ((char >= 48 && char <= 57) || (char >= 96 && char <= 105)) {
                    newValue = +(this.prevKey + event.key);          // make it an int, so we can verify the value
                }
                else {
                    newValue = +(this.prevKey);
                }
                this.prevKey = null;
                if (newValue >= 1 && newValue <= 12) {
                    this.props.onValueChanged(newValue, false);
                }
                else {
                    dialogService.error("direction selection", `value out of range: ${newValue}. Should be between 01 and 12`);
                }
            }
        }
        else if (char === 8 || char === 46) {
            this.prevKey = null;
            this.props.onValueChanged(null, true);                          // true is actually to indicate that it came from the mouse, we mimic this cause the actual result of it is that focus remains on the same object, which is what we want cause it got cleared.
        }
    }

    focus() {
        if (this.props.inner.current) {
            this.props.inner.current.focus();
        }
    }
  
}

DirectionSelector.propTypes = {
    isExpanded: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.number.isRequired,
    value: PropTypes.number,
    onValueChanged: PropTypes.func,
    onFocus: PropTypes.func,
    error: PropTypes.bool,
    readOnly: PropTypes.bool,                           // for viewer
    inner: PropTypes.any                                // a ref to the inner object. This is a replacement for {ref}
};


//function DirectionSelectorForwardRef(props, ref) {
//    return <DirectionSelector {...props} inner={ref}/>
//}

//export default React.forwardRef(withTranslation()(withStyles(styles)(DirectionSelectorForwardRef)));
export default withTranslation()(withStyles(styles)(DirectionSelector));