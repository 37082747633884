/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


import TheatersIcon from '@material-ui/icons/Theaters';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import ControllerButtons from './controller_buttons_components';
import MinControllerButtons from './min_controller_buttons_components';
import { documentControlService } from '../../document_control_service';
import TimeInfo from "./time_info_component";
import TimeSlider from './time_slider_component';
import RobotController from './robot_controller_component';
import CameraController from './camera_controller_component';
import ElevatorController from './elevator_controller_component';
import { engineService } from '../../../../components/video_engine/engine_service';
import { robotRecorderClient } from '../robot/robot_client_service';
import LightController from './light_controller_component';


const styles = (theme) => ({
    root: {
        position: 'relative'                        //need this to make the root parent of the infoBox (otherwise, it goes up parents until it finds one with this prop)
    },
    paper: {
        flexDirection: "column",
        padding: theme.spacing(1)
    },
    paper2: {
        flexDirection: "column",
        padding: theme.spacing(1),
        overflowX: "auto",
        overflowY: "hidden",
    },
    topBar: {
        flexDirection: "row",
        display: 'flex',
    },
    topBarBasic: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'space-between',
    },
    topBar2: {
        flexDirection: "row",
        display: 'flex',
        justifyContent: 'space-evenly',
        minWidth: 'min-content'
    },
    infoBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: "absolute",
        left: '0px',
        top: '0px',
        right: '0px',
        bottom: '0px',
        background: '#ffffffB0',
    },
    record: {
        color: 'red'
    },
    timeInfoBar: {
        flexDirection: "column",
        display: 'flex',
        alignSelf: 'center',
        marginRight: theme.spacing(1)
    },
    titleIcon: {
        height: '14px',
        width: '14px',        
        marginLeft: theme.spacing(2),
        marginRight: '-6px',
    },
});


class Controller extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
        this.position = 0;                                                      // current play pos, for calculating tolernace levels
        this.setPosCallback = {current: null};                                  // the timeInfo components will register a callback at 'current' for fast setting of the value without complete rerender
        this.setLengthCallback = {current: null};
        this.setSliderCallback = {current: null};
        this.posTxt = props.t("Positie");                                          // language is only changed when unloaded, these text values are needed often while playback/record. It saves a lot of time
        this.lengthTxt = props.t("Lengte");

        const robot = documentControlService.robotDs;
        this.canMove = robot.hasDistance();
        this.hasElevator = robot.hasElevator();
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyboard, true);                 // check for keyboard shortcuts.
        documentControlService.events.on('onCurrentTime', this.handleCurrentTimeChanged);
        documentControlService.events.on("onLengthChanged", this.handleLengthChanged);
        if (this.setLengthCallback.current && this.props.streng) {
            this.doCallbacks();
        }
        const robot = documentControlService.robotDs;
        const canMove = robot.hasDistance();
        const hasElevator = robot.hasElevator();
        if (this.canMove !== canMove || this.hasElevator !== hasElevator) {
            this.hasElevator  = hasElevator;
            this.canMove = canMove;
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyboard, true);
        documentControlService.events.removeListener('onCurrentTime', this.handleCurrentTimeChanged);
        documentControlService.events.removeListener("onLengthChanged", this.handleLengthChanged);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.setLengthCallback.current && prevProps.streng !== this.props.streng && this.props.streng) {
            this.doCallbacks();
        }
        else if (prevProps.buttonState === "processing" && this.props.buttonState === "stopped") {
            this.doCallbacks();
        }
        if ((this.props.initLoaded !== prevProps.initLoaded && this.props.initLoaded) || this.props.forceLiveFeed !== prevProps.forceLiveFeed) {
            const robot = documentControlService.robotDs;
            this.canMove = robot.hasDistance();
            this.hasElevator = robot.hasElevator();
            this.forceUpdate();
        }
        if (this.props.forceLiveFeed !== prevProps.forceLiveFeed && !this.props.forceLiveFeed) {        // when switching back to video, need to make certain that the text is correct
            this.doCallbacks();   
        }
    }

    doCallbacks() {
        if (this.setPosCallback.current) {
            this.setPosCallback.current(0);
        }
        if (this.setLengthCallback.current) {
            this.setLengthCallback.current(this.props.streng.videoLength);
        }
        if (this.setSliderCallback.current) { 
            this.setSliderCallback.current(0, this.props.streng.videoLength);
        }
    }

    render() {
        const { t } = this.props;
        //console.log(`buttonstate: ${this.props.buttonState}, duration: ${this.props.duration}, canRecord: ${this.props.canRecord}, forceLiveFeed: ${this.props.forceLiveFeed}`);
        let recClass = null;
        if (this.props.buttonState === "record"){
            recClass = this.props.classes.record;
        }
        let reverseRecClass = null;
        if (this.props.buttonState === "reverseTractor") {
            reverseRecClass = this.props.classes.record;
        }      
        let videoLength = this.props.streng ? this.props.streng.videoLength : 0;
        let reverseTractorDisabled = this.props.buttonState ==='play' || this.props.buttonState ==='stop' || this.props.buttonState ==='record' || this.props.forceLiveFeed === false || !this.canReverseRecord();   //|| (! && this.props.buttonState!=='reverseTractor') || (this.props.canRecord == false && this.props.forceLiveFeed == false)

        // if (this.props.forceLiveFeed && robotRecorderClient.hasRemote()) {
        if (this.props.forceLiveFeed || this.props.canRecord) {
            if (engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasRemote()) {
                return this.renderFullControl(recClass, reverseRecClass, videoLength, reverseTractorDisabled);
            }
            else {
                return this.renderBasic(recClass, reverseRecClass, videoLength, reverseTractorDisabled);    
            }
        }
        else {
            return this.renderBasic(recClass, reverseRecClass, videoLength, reverseTractorDisabled);
        }
    }

    renderBasic(recClass, reverseRecClass, videoLength, reverseTractorDisabled)  {
        const { t } = this.props;
        return (
            <div className={this.props.classes.root} ref={this.props.rootRef}>
                <Paper className={this.props.classes.paper}>
                    <div className={this.props.classes.topBarBasic}>
                        <ControllerButtons
                            onBtnPress={this.handleBtnPress}
                            pauseBtnDisabled={this.props.buttonState!=='play' && this.props.buttonState!=='pause'}
                            stopBtnDisabled={this.props.buttonState!=='play' && this.props.buttonState!=='record' && this.props.buttonState!=='reverseTractor'}
                            playBtnDisabled={this.props.buttonState==='record' || this.props.buttonState==='stop' || this.props.buttonState==='reverseTractor' || videoLength == 0 || this.props.canRecord == true || this.props.forceLiveFeed == true}
                            recordBtnDisabled={this.props.buttonState==='play' || this.props.buttonState==='stop' || this.props.buttonState==='reverseTractor' || this.props.canRecord == false || (videoLength !== 0 && this.props.buttonState != "record")}
                            reverseTractorBtnDisabled={reverseTractorDisabled}
                            reverseRecClass={reverseRecClass}
                            hasReverseTrackData={this.props.hasReverseTrackData}
                            recClass={recClass}
                        />
                        <div className={this.props.classes.timeInfoBar}>
                            <TimeInfo text={this.posTxt} onSetter={this.setPosCallback}/>
                            <TimeInfo text={this.lengthTxt} onSetter={this.setLengthCallback}/>
                        </div>
                    </div>
                    <TimeSlider  onChange={this.handlePosChange}  onSetter={this.setSliderCallback} />

                    {(this.props.buttonState === "processing") &&
                        <div className={this.props.classes.infoBox}>
                            <CircularProgress size={24}/>
                            <Typography variant="caption">
                                {t("Video bestand aan het verwerken...")}
                            </Typography>
                        </div>
                    }
                </Paper>
            </div>
        );
    }

    renderFullControl(recClass, reverseRecClass, videoLength, reverseTractorDisabled) {
        const { t, classes } = this.props;
        return (
            <div className={classes.root} ref={this.props.rootRef}>
                <Paper className={classes.paper2}>
                    <div className={classes.topBar2}>
                        

                        {(this.canMove) && 
                            <RobotController/>
                        }

                        {(this.hasElevator) &&
                            <ElevatorController/>
                        }

                        <CameraController/>

                        <LightController/>


                        <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start'}}>
                            <TheatersIcon color="disabled" className={classes.titleIcon}/>
                            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems: "center"}}>

                                <MinControllerButtons
                                    onBtnPress={this.handleBtnPress}
                                    stopBtnDisabled={this.props.buttonState!=='play' && this.props.buttonState!=='record' && this.props.buttonState!=='reverseTractor'}
                                    recordBtnDisabled={this.props.buttonState==='play' || this.props.buttonState==='stop' || this.props.buttonState==='reverseTractor' || this.props.canRecord == false || (videoLength !== 0 && this.props.buttonState != "record")}
                                    reverseTractorBtnDisabled={reverseTractorDisabled}
                                    reverseRecClass={reverseRecClass}
                                    hasReverseTrackData={this.props.hasReverseTrackData}
                                    recClass={recClass}
                                />
                                <TimeInfo onSetter={this.setLengthCallback}/>
                            </div>
                        </div>
                    </div>

                    {(this.props.buttonState === "processing") &&
                        <div className={this.props.classes.infoBox}>
                            <CircularProgress size={24}/>
                            <Typography variant="caption">
                                {t("Video bestand aan het verwerken...")}
                            </Typography>
                        </div>
                    }
                </Paper>
            </div>
        );
    }

    /**
     * returns true if the current position is within the last 5% of the total time, with a max of 2 minutes.
     */
    canReverseRecord() {
        if (this.props.forceLiveFeed) {
            return true;
        }
        const streng = this.props.streng;
        if (streng.videoLength === 0) {
            return false;
        }
        let tolerance = (streng.videoLength / 100 * 5);
        if (tolerance > 120) tolerance = 120;
        return this.position + tolerance >= streng.videoLength;
    }

    handleCurrentTimeChanged = (value) => {
        //console.log(value);

        this.position = value;
        if (this.setPosCallback.current) {                      // pos label always needs updating cause pos has changed.
            this.setPosCallback.current(value);
        }
        if (this.props.buttonState === "record") {              // when recording, also need to update the length label
            if (this.setLengthCallback.current) {
                this.setLengthCallback.current(value);
            }
            if (this.setSliderCallback.current) {               // on slider both max and pos needs updating
                this.setSliderCallback.current(value, value);
            }
        }
        else if (this.setSliderCallback.current) {              // when playing, the slider only needs pos update
            this.setSliderCallback.current(value);
        }
    }

    handleLengthChanged = () => {
        if (this.setLengthCallback.current) {
            this.setLengthCallback.current(this.props.streng.videoLength);
        }
    }

    handlePosChange = (ev, value) => {
        if (this.props.onPositionChanged) {
            this.props.onPositionChanged(value);
        }
    }

    handleKeyboard = (ev) => {
        if (ev.code == "F5" && ev.ctrlKey == false && this.props.buttonState != 'stop') {
            this.props.onButtonPressed("record");
        }
        else if (ev.code == "F4" && ev.ctrlKey == false && (this.props.buttonState =='play' || this.props.buttonState == 'record' || this.props.buttonState =='reverseTractor')) {
            this.props.onButtonPressed("stop");
        }
    }

    handleBtnPress = (ev) => {
        this.props.onButtonPressed(ev.currentTarget.id);
    }
}

Controller.propTypes = {
    buttonState: PropTypes.string,                          // allowed values: 'play', 'record', 'pause', 'stop', 'stopped', 'processing'. note: stop is to request stop, stopped is when actually stopped.
    onPositionChanged: PropTypes.func,
    streng: PropTypes.object,                               // ref to the streng, primarily used to get to the duration of the video
    onButtonPressed: PropTypes.func,
    forceLiveFeed: PropTypes.bool,                          // when true, record is also possible. This is separate from canRecord, cause forceLiveFeed only allows revese record
    canRecord: PropTypes.bool,
    readOnly: PropTypes.bool,                           // for viewer
    hasReverseTrackData: PropTypes.bool,
    rootRef: PropTypes.object.isRequired,                   // a react.createRef object that will have a reference to the root div object. This is used to get the size of the object
    initLoaded: PropTypes.bool.isRequired,                  // need to know when initial loading is done, so we can read the engine cofnig correctly and render the correct controller interface
};

export default withTranslation()(withStyles(styles)(Controller));