/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { activeProjectService } from '../../../../services/active_project_service';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from '../../../services/project_service';
import InputAdornment from '@material-ui/core/InputAdornment';
import { errExtractor } from '../../../services/error_extractor';
import { withTranslation } from 'react-i18next';
import { dialogService } from '../../../../services/dialog_service';


const styles = (theme) => ({
    textField: {
        width: '100%'
    }
});


export class StrengInfoNumberField extends React.Component {
    constructor(props) {
        super(props);

        let value = props.streng ? props.streng[props.field]: null;
        if (value && typeof value === "number" && props.digits != undefined ) {
            value = value.toFixed(props.digits);
        }
        if (value) {
            value = +value;                                      // make certain that it is a number,just in case someone editied the file manually and did something wrong
        }
        this.state = {
            value: value
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.field !== this.props.field || prevProps.streng !== this.props.streng) && this.props.streng) {
            let value = this.props.streng[this.props.field];
            if (value && typeof value === "number" && this.props.digits != undefined ) {
                value = value.toFixed(this.props.digits);
            }
            this.setState({value: value});
        }
    }

    render() {
        let error = (this.props.required && this.props.showMissingErrors && isEmpty(this.state.value));
        if (!error && !isEmpty(this.state.value)) {
            error = !this.isValueOk(this.state.value);
        }
        let inputProps = {readOnly:this.props.readOnly};
        if (this.props.unit) {
            inputProps = {readOnly:this.props.readOnly, endAdornment: <InputAdornment position="end">{this.props.unit}</InputAdornment>}
        }
        return (
            <TextField
                required={this.props.required}
        
                error={error}
                id={this.props.field}
                label={this.props.label}
                InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                value={this.state.value}
                onChange={this.handleTextChanged}
                className={this.props.classes.textField}
                margin="dense"
                type="number"
                inputProps={{ min: this.props.min, max: this.props.max, step: this.props.step }}
                onBlur={this.handleFocusLost}
                InputProps={inputProps}
            />
        );
    }

    handleFocusLost = () => {
        if (typeof this.state.value === "number") {                                        // when the value is a number, it means the user has edited the value.
            this.setState({value: this.state.value.toFixed(this.props.digits)});           // if the value has changed, make certain we go back to 2 point precision again after loose focus. can't do that while editing, cause that screws up cursor pos end the field becomes uneditable
        }
    } 
    handleTextChanged = (ev) => {
        try {
            this.handleValueChanged(ev.target.value);
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

    handleValueChanged(value) {
        if (value !== "") {
            value = +value;
            this.props.streng[this.props.field] = value.toFixed(this.props.digits);
        }
        else {
            value = "";
            this.props.streng[this.props.field] = "";
        }
        this.setState({value: value}); 
        activeProjectService.markDirty(this.props.field);
        if (this.props.onValueChanged) {
            this.props.onValueChanged(value);
        }
    }

    /**
     * checks is the value is within range.
     * @param {text or number} value the value to check
     */
    isValueOk(value) {
        if (value !== null && value !== undefined) {
            if (this.props.max != undefined && value > this.props.max){
                return false;
            }
            else if (this.props.min !== undefined && value < this.props.min) {
                return false;
            }
        }
        return true;
    }

}

StrengInfoNumberField.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    streng: PropTypes.object,                 // a reference to the streng for which we are currently showing data.
    required: PropTypes.bool,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    digits: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    showMissingErrors: PropTypes.bool,                       // when true, inputboxes that require a value but dont, should show an error
    unit: PropTypes.string,
    onValueChanged: PropTypes.func,                     // optional callback for doing something extra when the value changes
    readOnly: PropTypes.bool,                           // for viewer
};

StrengInfoNumberField.defaultProps = {
    required: false,
}

export default withTranslation()(withStyles(styles)(StrengInfoNumberField));