/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import RecordCircle from 'mdi-material-ui/RecordCircle';
import Stop from 'mdi-material-ui/Stop';
import ArrowLeftBoldCircle from 'mdi-material-ui/ArrowLeftBoldCircle';
import ArrowLeftBoldCircleOutline from 'mdi-material-ui/ArrowLeftBoldCircleOutline';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    buttonBar: {
        flexDirection: "column",
        display: 'flex'
    },

    btnTop: {
        padding: '0px',
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomStyle: "unset",
        minWidth: '0px',
        borderStyle: "solid",
        borderWidth: "1px",
        color: theme.palette.action.active,
        borderColor: theme.palette.action.disabledBackground,
    },
    btnBottom: {
        padding: '0px',
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        minWidth: '0px',
        borderStyle: "solid",
        borderWidth: "1px",
        color: theme.palette.action.active,
        borderColor: theme.palette.action.disabledBackground,
    },
});


/* speed optimization. By putting the buttons in a sperate renderer, they don't need to be rendered each time the timer is updated. */
class MinControllerButtons extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
        this.txtRec = props.t("Record (F5)");
        this.txtReverse = props.t("Reverse tractor record");
        this.txtStop = props.t("Stop (F4)");
    }

    render() {
        const { classes } = this.props;
        
        let revereTractorIcon;
        if (this.props.hasReverseTrackData) {         
            revereTractorIcon = <ArrowLeftBoldCircleOutline fontSize="large"/>
        }
        else {
            revereTractorIcon = <ArrowLeftBoldCircle fontSize="large"/>
        }

        let recBtn;
        if (this.props.recordBtnDisabled && !this.props.reverseTractorBtnDisabled) {            // no more record allowed only reverse rec, so show that icon
            recBtn = <Tooltip title={this.txtReverse}>
                        <a>
                            <Button aria-label="reverse tractor"
                                id="reverseTractor"
                                disabled={this.props.reverseTractorBtnDisabled}
                                onClick={this.props.onBtnPress}
                                className={`${classes.btnTop} ${this.props.reverseRecClass}`}
                            >
                                {revereTractorIcon}
                            </Button>
                        </a>
                    </Tooltip>
        }
        else {
            recBtn = <Tooltip title={this.txtRec}>
                        <a>
                            <Button aria-label="record"
                                id="record"
                                disabled={this.props.recordBtnDisabled }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onClick={this.props.onBtnPress}
                                className={`${classes.btnTop} ${this.props.recClass}`}
                            >
                                <RecordCircle fontSize="large"/>
                            </Button>
                        </a>
                    </Tooltip>
        }

        return (
            <div className={this.props.classes.buttonBar}>
                
                { recBtn }
                <Tooltip title={this.txtStop}>
                    <a>
                        <Button aria-label="stop"
                            id="stop"
                            disabled={this.props.stopBtnDisabled}
                            onClick={this.props.onBtnPress}
                            className={classes.btnBottom}>
                            <Stop fontSize="large"/>
                        </Button>
                    </a>
                </Tooltip>
            </div>

        );
    }

}

MinControllerButtons.propTypes = {
    stopBtnDisabled:PropTypes.bool,
    recordBtnDisabled:PropTypes.bool,
    reverseTractorBtnDisabled:PropTypes.bool,
    reverseRecClass: PropTypes.any,
    hasReverseTrackData: PropTypes.bool,
    recClass: PropTypes.any,
    onBtnPress: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(MinControllerButtons));