/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */
//icons
import ArrowUpBold from 'mdi-material-ui/ArrowUpBold';
import ArrowDownBold from 'mdi-material-ui/ArrowDownBold';
import ArrowRightBold from 'mdi-material-ui/ArrowRightBold';
import ArrowLeftBold from 'mdi-material-ui/ArrowLeftBold';
//import Stop from 'mdi-material-ui/Stop';
import GamesIcon from '@material-ui/icons/Games';

import FocusInIcon from 'mdi-material-ui/ImageFilterCenterFocusStrong';
import FocusOutIcon from 'mdi-material-ui/ImageFilterCenterFocusStrongOutline';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import CrosshairButtons from '../../../layouts/crosshair_buttons_component';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { remoteControlService, REMOTE_ACTIONS } from '../../../services/remote_control_service';
//import { documentControlService } from '../../document_control_service';




const styles = (theme) => ({
    buttonBar: {
        flexDirection: "column",
        display: 'flex'
    },
    centerBtnRegular: {
        minWidth: 'unset',
        width: '37px',                           // set the width, otherwise we get 35, which doesn't align nicely
        color: 'inherit'
    },
    centerBtnTablet: {
        minWidth: 'unset',
        color: 'inherit',
        borderRadius: "0px",
        padding: '11px'
    },
    leftBtn: {
        minWidth: 'unset',
        borderRightStyle: 'unset',
        borderBottomRightRadius: "0px",
        borderTopRightRadius: "0px",
        color: 'inherit'
    },
    topBtn: {
        minWidth: 'unset',
        borderBottomStyle: 'unset',
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        color: 'inherit'
    },
    rightBtn: {
        minWidth: 'unset',
        borderLeftStyle: 'unset',
        borderBottomLeftRadius: "0px",
        borderTopLeftRadius: "0px",
        color: 'inherit'
    },
    bottomBtn: {
        minWidth: 'unset',
        borderTopStyle: 'unset',
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        color: 'inherit'
    },
    tabletMode: {
        backgroundColor: theme.palette.action.focus,
        borderStyle: 'unset'
    },
    regularMode: {
        padding: 'unset',
    }
});


/* group of up/down/left/right/center buttons */
class CameraDirectionButtons extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: null,                                        // for up-down 
            value2: null,                                       // for left-right
        }
    
        this.titleDown = props.t("Camera down");
        this.titleUp = props.t("Camera up");
        if (props.leftRightAsFocus === true)  {
            this.titleLeft = props.t("Focus out");
            this.titleRight = props.t("Focus in");
            this.actionLeft = REMOTE_ACTIONS.CAM_FOCUS_OUT;
            this.actionRight = REMOTE_ACTIONS.CAM_FOCUS_IN;
        }
        else {
            this.titleLeft = props.t("Camera left");
            this.titleRight = props.t("Camera right");
            this.actionLeft = REMOTE_ACTIONS.CAM_LEFT;
            this.actionRight = REMOTE_ACTIONS.CAM_RIGHT;
        }
        this.titleStop = props.t("Stop");
        this.titleCenter = props.t("Center camera");
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
        this.setValueState(remoteControlService.camMove);
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
    }

    render() {
        const { classes } = this.props;
        
        let leftBtnStyle, rightBtnStyle, topBtnStyle, BottomBtnStyle, centerBtnStyle;
        if (this.props.tabletMode) {
            leftBtnStyle = `${classes.leftBtn} ${classes.tabletMode}`;
            rightBtnStyle = `${classes.rightBtn} ${classes.tabletMode}`;
            topBtnStyle = `${classes.topBtn} ${classes.tabletMode}`;
            BottomBtnStyle = `${classes.bottomBtn} ${classes.tabletMode}`;
            centerBtnStyle = `${classes.centerBtnTablet} ${classes.tabletMode}`;
        }
        else {
            leftBtnStyle = `${classes.leftBtn} ${classes.regularMode}`;
            rightBtnStyle = `${classes.rightBtn} ${classes.regularMode}`;
            topBtnStyle = `${classes.topBtn} ${classes.regularMode}`;
            BottomBtnStyle = `${classes.bottomBtn} ${classes.regularMode}`;
            centerBtnStyle = `${classes.centerBtnRegular} ${classes.regularMode}`;
        }
        let leftIcon, rightIcon;
        if (this.props.leftRightAsFocus) {
            leftIcon = <FocusOutIcon fontSize="large"/>
            rightIcon = <FocusInIcon fontSize="large"/>
        }
        else {
            leftIcon = <ArrowLeftBold fontSize="large"/>
            rightIcon = <ArrowRightBold fontSize="large"/>
        }
            
        return (
            <div className={classes.buttonBar}>
                
                <CrosshairButtons 
                    leftBtn={
                        <Tooltip title={this.titleLeft}>
                            <a>
                                <ToggleButton aria-label="left"
                                    id="left"
                                    value="left"
                                    selected={this.state.value2 === this.actionLeft}
                                    disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onMouseDown={this.handleBtnClick(this.actionLeft)}
                                    onMouseUp={this.handleMouseUp}
                                    onTouchStart={this.handleBtnClick(this.actionLeft)}
                                    onTouchEnd={this.handleMouseUp}
                                    classes={{root: leftBtnStyle}}
                                >
                                    {leftIcon}
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                    rightBtn={
                        <Tooltip title={this.titleRight}>
                            <a>
                                <ToggleButton aria-label="right"
                                    id="right"
                                    value="right"
                                    selected={this.state.value2 === this.actionRight}
                                    disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onMouseDown={this.handleBtnClick(this.actionRight)}
                                    onMouseUp={this.handleMouseUp}
                                    onTouchStart={this.handleBtnClick(this.actionRight)}
                                    onTouchEnd={this.handleMouseUp}
                                    classes={{root: rightBtnStyle}}
                                >
                                    {rightIcon}
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                    topBtn={
                        <Tooltip title={this.titleUp}>
                            <a>
                                <ToggleButton aria-label="forward"
                                    id="forward"
                                    value="up"
                                    selected={this.state.value === REMOTE_ACTIONS.CAM_UP}
                                    disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onMouseDown={this.handleBtnClick(REMOTE_ACTIONS.CAM_UP)}
                                    onMouseUp={this.handleMouseUp}
                                    onTouchStart={this.handleBtnClick(REMOTE_ACTIONS.CAM_UP)}
                                    onTouchEnd={this.handleMouseUp}
                                    classes={{root: topBtnStyle}}
                                >
                                    <ArrowUpBold fontSize="large"/>
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                    centerBtn={
                        <Tooltip title={this.titleCenter}>
                            <a>
                                <Button aria-label="stop"
                                    id="stop"
                                    disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onMouseDown={this.handleBtnClick(REMOTE_ACTIONS.CAM_CENTER)}
                                    onMouseUp={this.handleMouseUp}
                                    onTouchStart={this.handleBtnClick(REMOTE_ACTIONS.CAM_CENTER)}
                                    onTouchEnd={this.handleMouseUp}
                                    classes={{root: centerBtnStyle}}
                                >
                                    <GamesIcon fontSize="large"/>
                                </Button>
                            </a>
                        </Tooltip>

                    }
                    bottomBtn={
                        <Tooltip title={this.titleDown}>
                            <a>
                                <ToggleButton aria-label="backward"
                                    id="backward"
                                    value="down"
                                    selected={this.state.value === REMOTE_ACTIONS.CAM_DOWN}
                                    disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onMouseDown={this.handleBtnClick(REMOTE_ACTIONS.CAM_DOWN)}
                                    onMouseUp={this.handleMouseUp}
                                    onTouchStart={this.handleBtnClick(REMOTE_ACTIONS.CAM_DOWN)}
                                    onTouchEnd={this.handleMouseUp}
                                    classes={{root: BottomBtnStyle}}
                                >
                                    <ArrowDownBold fontSize="large"/>
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                />
            </div>

        );
    }

    handleBtnClick = (value) => () => {
        if (value === REMOTE_ACTIONS.CAM_CENTER) {                     // can't add valueStop here cause that would also handle tractor, which we don't want, only for camera -> still relevant? is only for camera now
            remoteControlService.requestState(value);
            this.setState({value: null, value2: null});
        }
        else {
            remoteControlService.requestState(value);    
            this.setValueState(value);
        }
    }

    handleMouseUp = () => {
        this.setState({value: null, value2: null});
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_MOVE_STOP);  
    }


    handleRemoteUpdate = (action, value) => {
        
        if (action === REMOTE_ACTIONS.CAM_MOVE_STOP || action === REMOTE_ACTIONS.CAM_CENTER) {
            this.setState({value: null, value2: null});
        }
        else {
            const supportedActions = [REMOTE_ACTIONS.CAM_DOWN, REMOTE_ACTIONS.CAM_UP, this.actionLeft, this.actionRight];
            if (supportedActions.includes(action)) {
                this.setValueState(action);
            }
        }
    }

    setValueState (action) {
        const set1 = [REMOTE_ACTIONS.CAM_DOWN, REMOTE_ACTIONS.CAM_UP];
        if (set1.includes(action)) {
            this.setState({value: action});
        }
        else {
            this.setState({value2: action});
        }
    }

}

CameraDirectionButtons.propTypes = {
    disabledButtons: PropTypes.bool,
    leftRightAsFocus: PropTypes.bool,           // when true, left and right function as zoom
    tabletMode: PropTypes.bool,                 // when true, buttons are dranw differently
};


export default withTranslation()(withStyles(styles)(CameraDirectionButtons));