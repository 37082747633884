/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import RobotSection from './robot_section_component';
import Controller from './controller/controller_component';
import { activeProjectService } from '../../../services/active_project_service';


const styles = (theme) => ({
    root: {
    },
    
});


class Instrumentation extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            
            robotSectionAllowedHeight: 0,                                // to let the dashboard know how much room it is allowed to consume. needed cause the knobs set their size, causing problems otherwise
        }
        this.controllerRef = React.createRef();                         // a ref to the controller. this allows us to get it's size, needed to calculte the radius of the buttons on the dashboard
        this.rootRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.allowedHeight !== this.props.allowedHeight || prevProps.initLoaded !== this.props.initLoaded
             || prevProps.verSplit !== this.props.verSplit || prevProps.streng !== this.props.streng
             || prevProps.forceLiveFeed !== this.props.forceLiveFeed) {   // could be that the controller has added/removed a scrollbar due to vertical change
            let height =  this.props.allowedHeight;
            if (this.controllerRef.current) {
                height -= this.controllerRef.current.offsetHeight + 8 + this.props.theme.spacing(1) ;        // spacing is for border between dashboard and controller, extra 8 for the splitter + 8 for border
            }
            if (height !== this.state.robotSectionAllowedHeight) {
                this.setState({robotSectionAllowedHeight: height});
            }
        }
    }

    render() {
        const hasReverseTrackData = this.props.streng && activeProjectService.activeStrengHasReverseRobotData();
        return(
            <div style={{ height: "100%", display: 'flex', flexDirection: "column", justifyContent: "flex-end" }} ref={this.rootRef}>
                <RobotSection liveFeed={this.props.canRecord || this.props.forceLiveFeed}
                    readOnly={this.props.readOnly}
                    direction={this.props.direction}
                    onDirectionChanged={this.props.onDirectionChanged}
                    changePosAllowed={this.props.buttonState !== "record" && this.props.buttonState !== "reverseTractor"}
                    onPositionChanged={this.props.onPositionChanged}
                    initLoaded={this.props.initLoaded}
                    allowedHeight={this.state.robotSectionAllowedHeight}
                    verSplit={this.props.verSplit}
                    onToggleVideoSection={this.props.onToggleVideoSection}
                    showVideoSection={this.props.showVideoSection}
                    onOpenVideoScreen={this.props.onOpenVideoScreen}
                    streng={this.props.streng} 
                    hasReverseTrackData={hasReverseTrackData}
                    controlState={this.props.buttonState}
                    measureData={this.props.measureData}
                    onMeasured={this.props.onMeasured}
                />
                <Controller onButtonPressed={this.props.onButtonPressed}
                    readOnly={this.props.readOnly}
                    canRecord={this.props.canRecord}
                    initLoaded={this.props.initLoaded}
                    forceLiveFeed={this.props.forceLiveFeed}
                    onPositionChanged={this.props.onPositionChanged}
                    buttonState={this.props.buttonState}
                    hasReverseTrackData={hasReverseTrackData}
                    streng={this.props.streng} 
                    rootRef={this.controllerRef}/>
            </div>
        );
    }

}

Instrumentation.propTypes = {
    direction: PropTypes.string,                            // allowed values: 
    onDirectionChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    readOnly: PropTypes.bool,                               // for viewer
    initLoaded: PropTypes.bool.isRequired,                  // need to know when initial loading is done, so we can read the engine cofnig correctly
    measureData: PropTypes.any,                             // when lidar is available, we can perform measurements with that sensor, so we need to respond to measument requests
    onMeasured: PropTypes.func,                             // called when measurement is done on the lidar image
    buttonState: PropTypes.string,                          // allowed values: 'play', 'record', 'pause', 'stop', 'stopped', 'processing'. note: stop is to request stop, stopped is when actually stopped.
    streng: PropTypes.object,                               // ref to the streng, primarily used to get to the duration of the video. This is set after document component has loaded things like files. Also forces a refresh when the streng has changed, needed cause that can change the robot config
    onButtonPressed: PropTypes.func,
    forceLiveFeed: PropTypes.bool,                          // when true, record is also possible. This is separate from canRecord, cause forceLiveFeed only allows revese record
    canRecord: PropTypes.bool,
    allowedHeight: PropTypes.number,                        // needed for the radar & dashboard, to readjust sizes
    verSplit: PropTypes.number,                             // needed for radar to repaint
    onToggleVideoSection: PropTypes.func,                   // called to show/hide the video section
    showVideoSection: PropTypes.bool,                       // when true, the video section is shown, otherwise it is collapsed
    onOpenVideoScreen: PropTypes.func,                      // callback for the button to open new screen for video
};

export default withTheme(withTranslation()(withStyles(styles)(Instrumentation)));