/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { activeProjectService } from '../../../../services/active_project_service';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from '../../../services/project_service';
import { errExtractor } from '../../../services/error_extractor';
import { withTranslation } from 'react-i18next';
import { dialogService } from '../../../../services/dialog_service';

const styles = (theme) => ({
    textField: {
        width: '100%'
    }
});


class StrengInfoTextField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.streng ? props.streng[props.field] : null
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.field !== this.props.field || prevProps.streng !== this.props.streng) && this.props.streng) {
            this.setState({value: this.props.streng[this.props.field]});
        }
    }

    render() {
        let error = this.props.required && this.props.showMissingErrors && isEmpty(this.state.value);
        return (
            <TextField
                required={this.props.required}
                error={error}
                id={this.props.field}
                label={this.props.label}
                InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                InputProps={{readOnly:this.props.readOnly}}
                value={this.state.value}
                onChange={this.handleTextChanged}
                className={this.props.classes.textField}
                margin="dense"
            />
        );
    }

    handleTextChanged = (ev) => {
        try {
            const value = ev.target.value;
            this.props.streng[ev.target.id] = value;
            this.setState({value: value});
            activeProjectService.markDirty(ev.target.id);
            if (this.props.onValueChanged) {
                this.props.onValueChanged(value);
            }
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

}

StrengInfoTextField.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    streng: PropTypes.object,                 // a reference to the streng for which we are currently showing data.
    required: PropTypes.bool,
    showMissingErrors: PropTypes.bool,                       // when true, inputboxes that require a value but dont, should show an error
    readOnly: PropTypes.bool,                           // for viewer
    onValueChanged: PropTypes.func,                     // optional callback for doing something extra when the value changes
};

StrengInfoTextField.defaultProps = {
    required: false
}

export default withTranslation()(withStyles(styles)(StrengInfoTextField));