/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';
import { cloudDocsService } from '../services/cloud_documents_service';
import { dialogService } from '../../services/dialog_service';
import { errExtractor } from '../services/error_extractor';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    checkBox: {
        padding: '0px'
    },
    header: {
        fontWeight: 'bold',
        lineHeight: '24px',
        textTransform: 'capitalize',
        marginTop: theme.spacing(1)
    },
    subHeader: {
        lineHeight: '24px'
    },
    list: {
        //display: "flex",
        //flexDirection: "column",
        //flexWrap: "wrap",
        //maxHeight: "500px",
    },
    listItem: {
        padding: '0px'
    }
});

/**
 * removes the part before the first '_' in the text. 
 * This is to remove the projec number from google cloud files
 * @param {string} original the original name
 */
function extractName(original) {
    const idx = original.indexOf('_');
    return original.slice(idx + 1, original.length);
}

class DownloadDialog extends React.Component {
    constructor(props) {
        super(props);

        this.checked = [];
        this.state = {
            project: null
        }
    }

    componentDidMount() {
        if (this.props.downloadProject) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.downloadProject !== this.props.downloadProject) {
            if (this.props.downloadProject) {
                this.loadData();
            }
            else {
                this.setState({project: null});
            }
        }
    }

    loadData() {
        cloudDocsService.getProject(this.props.downloadProject, false).then((data) => {
            this.setState({project: data});
        }).catch((error) => {
            const { t } = this.props;
            dialogService.error(t("Download"), t("load_error", {pathToFile: this.props.downloadProject, error: errExtractor.get(error)}));
        });
    }
    

    render() {
        const { t } = this.props;
        const prj = this.state.project;
        if (!prj) {
            return null;
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="confirmation-dialog-title">{t("Files")}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        {t("Select the files you would like to download")}
                    </DialogContentText>
                    <List dense={true}
                    className={this.props.classes.list}>
                        <ListSubheader disableSticky={true} className={this.props.classes.header}>{t("project")}</ListSubheader>
                        <ListItem key={1} button onClick={this.handleSelect("pdf")}
                            className={this.props.classes.listItem}>
                            <ListItemIcon> {this.renderCheckBox("pdf")}</ListItemIcon>
                            <ListItemText primary={t("Pdf report")} />
                        </ListItem>
                        <ListItem key={2} button onClick={this.handleSelect('xml')}
                            className={this.props.classes.listItem}>
                            <ListItemIcon> {this.renderCheckBox('xml')}</ListItemIcon>
                            <ListItemText primary={t("Xml")} />
                        </ListItem>
                        {(prj.files && prj.files.attachments && prj.files.attachments.length > 0) &&
                            <ListItem key={3} button onClick={this.handleSelect('attachments')}
                                className={this.props.classes.listItem}>
                                <ListItemIcon> {this.renderCheckBox('attachments')}</ListItemIcon>
                                <ListItemText primary={t('Documenten')} />
                            </ListItem>
                        }
                        {(prj.strengs && prj.strengs.length > 0) &&
                            <React.Fragment>
                                <ListSubheader disableSticky={true} className={this.props.classes.header}>{t("Strengs / putten")}</ListSubheader>
                                <ListItem key={-1} button onClick={this.handleSelect("images")}
                                className={this.props.classes.listItem}>
                                    <ListItemIcon> {this.renderCheckBox("images")}</ListItemIcon>
                                    <ListItemText primary={t("Images")} />
                                </ListItem>
                                <ListItem key={-2} button onClick={this.handleSelect("videos")}
                                className={this.props.classes.listItem}>
                                    <ListItemIcon> {this.renderCheckBox("videos")}</ListItemIcon>
                                    <ListItemText primary={t("Videos")} />
                                </ListItem>
                            </React.Fragment>
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCancel} color="secondary">
                        {t("Sluiten")}
                    </Button>
                    <Button onClick={this.handleOk} color="primary">
                        {t("Download")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderCheckBox(el) {
        return (
            <Checkbox
                edge="start"
                className={this.props.classes.checkBox}
                checked={this.handleIsChecked(el)}
                tabIndex={-1}
                disableRipple
            />
        );
    }

    handleSelect = (obj) => () => {
        const idx = this.checked.indexOf(obj);
        if (idx === -1) {
            this.checked.push(obj);
        }
        else {
            this.checked.splice(idx, 1);
        }
        this.forceUpdate();
    }

    handleIsChecked = (obj) => {
        return this.checked.indexOf(obj) > -1;
    }

    handleOk = (event) => {
        if (this.props.onClose) {
            let list = this.checked;
            if (list.indexOf('videos') == -1 && list.indexOf('images') == -1 &&  list.indexOf('attachments') == -1)  {  // if zip needs to be made, caller figures it out, otherwise we give the files to downlaod
                list = [];
                if (this.checked.indexOf('pdf') > -1 ) {
                    list.push(this.state.project.files.pdfReport);
                }
                if (this.checked.indexOf('xml') > -1) {
                    list = list.concat(this.state.project.files.xml);
                }
            }
            this.props.onClose(list);
        }
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();
    }

    handleCancel = (event) => {
        if (this.props.onClose) this.props.onClose(null);
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();
    }
}

DownloadDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    downloadProject: PropTypes.string
};

export default withTranslation()(withStyles(styles)(DownloadDialog));