/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import TimeInfo from "../time_info_component";


import SingleButtonController from './single_button_controller_components';
import { documentControlService } from '../../../document_control_service';
import TimeSlider from '../time_slider_component';


const styles = (theme) => ({
    root: {
        gridArea: 'controller',                            // important: needed for the layout in tablet mode
        zIndex: 2,                                          // if we don't do this, the timer info is gone
        display: 'flex',
        flexDirection: 'row',
        justifyContent:"center",
        alignItems: 'stretch',
        color: 'white',
        overflow: 'hidden',
    },
    timeInfoBox: {
        backgroundColor: theme.palette.action.focus,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        justifyContent: "center",
        paddingTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
        borderRadius: '4px',
        borderTopLeftRadius: '0px',
    },
    toolbarBtn: {
        backgroundColor: theme.palette.action.focus,
        marginBottom: "0px",
        borderRadius: '4px',
        justifyContent: "center",
        '&:disabled': {
            color: 'rgba(255, 255,255, 0.26)',
            backgroundColor: theme.palette.action.focus,
        },
    },

});


/**
 * controller (start, rec, stop, pos,..), but represented in a small horizontal bar with expandable elements.
 * used for tablet mode. This is an altermative for Controller
 */
class ControllerBar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeButtonType: 'record',
            disableBtn: false,                                      // when true, the action button will be disabled
        }
        this.setLengthCallback = {current: null};                   // the timeInfo components will register a callback at 'current' for fast setting of the value without complete rerender
        this.setSliderCallback = {current: null};
    }

    componentDidMount() {
        documentControlService.events.on('onCurrentTime', this.handleCurrentTimeChanged);
        if (this.setLengthCallback.current && this.props.streng) {
            this.doCallbacks();
        }
        this.setActiveButtonType();
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onCurrentTime', this.handleCurrentTimeChanged);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.setLengthCallback.current && prevProps.streng !== this.props.streng && this.props.streng) {
            this.doCallbacks();
        }
        if (prevProps.forceLiveFeed !== this.props.forceLiveFeed || prevProps.buttonState !== this.props.buttonState || prevProps.streng !== this.props.streng || prevProps.canRecord !== this.props.canRecord || prevProps.readOnly !== this.props.readOnly ) {
            this.setActiveButtonType();
        }
    }

    render() {
        const { classes, minimalControls } = this.props;
        return (
            <Fade in={!minimalControls} timeout={500} >
                <div className={classes.root} onClick={this.handleRootClick}>
                <div style={{flex: 1}}/>
                    <SingleButtonController onBtnPress={this.handleBtnPress} buttonType={this.state.activeButtonType} disabled={this.state.disableBtn}/>
                    <div className={classes.timeInfoBox}>
                        <TimeInfo onSetter={this.setLengthCallback}/>
                    </div>
                    
                    <div style={{flex: 1, justifyContent: "center", display: "flex", flexDirection: "column", padding: "8px"}}>
                        {(!this.props.forceLiveFeed && !this.props.canRecord) &&
                            <TimeSlider  onChange={this.handlePosChange}  onSetter={this.setSliderCallback} tabletMode={true} />
                        }
                    </div>
                </div>
            </Fade>
        );
    }

    /**
     * need to make certain that clicks don't bubble up any furhter to the parent cause any 
     * click that goes up, causes the controls to show/hide, which we don't want when people
     * click/touch here
     */
     handleRootClick = (event) => {
        event.stopPropagation();
    }

    handleBtnPress = (ev) => {
        this.props.onButtonPressed(ev.currentTarget.id);
    }
    
    doCallbacks() {
        if (this.setLengthCallback.current) {
            this.setLengthCallback.current(this.props.streng.videoLength);
        }
        if (this.setSliderCallback.current) { 
            this.setSliderCallback.current(0, this.props.streng.videoLength);
        }
    }

    setActiveButtonType() {
        let result;
        let disabled = false;
        switch (this.props.buttonState) {
            case "play":
                if (this.props.forceLiveFeed) {
                    result = 'play';
                    disabled = true;
                }
                else {
                    result = 'pause';
                }
                break;
            case "record":
                result = 'stop';
                break;
            case "pause":
                result = 'play';
                disabled = this.props.forceLiveFeed;
                break;
            case "stop":
            case "stopped":
                if (this.props.canRecord && !this.props.readOnly) {
                    result = 'record';
                }
                else if (!this.props.forceLiveFeed) {
                    result = 'play';
                }
                else {
                    result = 'play';    
                    disabled = true;
                }
                break;
            default:
                result = 'disabled';
                break;
        }
        this.setState({activeButtonType: result, disableBtn: disabled});
    }

    handleCurrentTimeChanged = (value) => {
        //console.log(value);

        this.position = value;
        //if (this.setPosCallback.current) {                      // pos label always needs updating cause pos has changed.
        //    this.setPosCallback.current(value);
        //}
        if (this.setLengthCallback.current) {
            this.setLengthCallback.current(value);
        }
        if (this.setSliderCallback.current) {              // when playing, the slider only needs pos update
            this.setSliderCallback.current(value);
        }
    }

    handlePosChange = (ev, value) => {
        if (this.props.onPositionChanged) {
            this.props.onPositionChanged(value);
        }
    }
}

ControllerBar.propTypes = {
    buttonState: PropTypes.string,                          // allowed values: 'play', 'record', 'pause', 'stop', 'stopped', 'processing'. note: stop is to request stop, stopped is when actually stopped.
    onPositionChanged: PropTypes.func,
    streng: PropTypes.object,                               // ref to the streng, primarily used to get to the duration of the video
    onButtonPressed: PropTypes.func,
    forceLiveFeed: PropTypes.bool,                          // when true, record is also possible. This is separate from canRecord, cause forceLiveFeed only allows revese record
    canRecord: PropTypes.bool,
    readOnly: PropTypes.bool,                           // for viewer
    //rootRef: PropTypes.object.isRequired,                   // a react.createRef object that will have a reference to the root div object. This is used to get the size of the object
    minimalControls: PropTypes.bool,                                // when false, show sidebars, otherwise hide as much as possible
};

export default withTranslation()(withStyles(styles)(ControllerBar));