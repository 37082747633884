/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons:
import Filmstrip from 'mdi-material-ui/Filmstrip';
import Monitor from 'mdi-material-ui/Monitor';
import Cctv from 'mdi-material-ui/Cctv';
import Fullscreen from 'mdi-material-ui/Fullscreen';
import FullscreenExit from 'mdi-material-ui/FullscreenExit';
import GaugeEmpty from 'mdi-material-ui/GaugeEmpty'; 

import React from 'react';
import '../../../App.css';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { configService } from '../../../services/config_service';
import CameraSelect from './video_source_toolbar_camera_select';
import Fade from '@material-ui/core/Fade';


const styles = (theme) => ({
    root: {
        position: "absolute",
        width: 'min-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: ' 4px',
        flexDirection: 'row',
        display: 'flex',
        top: '0px',
        left: '0px',
        right: '0px',
        justifyContent: 'center',
        alignItems: "flex-start",
        borderRadius: "4px",
        opacity: "0.1",
        '&:hover': {
            opacity: "1"
        }
    },
    notSelected: {
        background: "#FFFFFF50",
        color: '#555555',
        '&$selected': {
            background: "#FFFFFF10",
        }
    },
    selected: {
        background: "#FFFFFF50",
        color: 'blue',
        '&$selected': {
            color: 'blue',
          }
    },
    empty: {},
    fullscreen: {
        width: '40px',
        height: '40px',
        padding: '0px',
        background: "#FFFFFF50"
    },
    newScreen: {
        width: "40px",
        height: "40px",
        padding: "0px",
        minWidth: "0px",
        color: 'rgba(0, 0, 0, 0.38);'
    },
    tabletMode: {
        //backgroundColor: theme.palette.action.focus,
        backgroundColor: 'transparent',
        borderStyle: 'unset',
        color: 'white',
        padding: '12px'
    },
});


class VideoSourceToolbar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            config: null,
        }
     
        
    }

    render() {
        if (this.props.tabletMode) {
            const rootStyle = {zIndex: 1200, marginTop: '0px'};                                                     // need to make certain we are above the toolbar
            return (
                <Fade in={!this.props.minimalControls} timeout={500}>
                    {this.renderToolbar(rootStyle)}
                </Fade>
            );
        }
        else {
            const rootStyle = {background: "white"};
            return this.renderToolbar(rootStyle);
        }
    }

    renderToolbar(rootStyle=null) {
        const { t, classes } = this.props;
        let iconSize = 'default';
        let fullscreenClass = this.props.classes.fullscreen;
        let newScreenClass = this.props.classes.newScreen;
        let toggle1Style = classes.notSelected, toggle2Style = classes.notSelected;
        if (this.props.isLiveFeed) {
            toggle2Style = classes.selected;
        }
        else {
            toggle1Style = classes.selected;
        }
        if (this.props.tabletMode) {
            toggle1Style = `${toggle1Style} ${classes.tabletMode}`;
            toggle2Style = `${toggle2Style} ${classes.tabletMode}`;
            fullscreenClass = classes.tabletMode;
            newScreenClass = classes.tabletMode;
            //iconSize = 'large';
        }
        let isLive = this.props.forceLiveFeed;
        let fullscreenIcon = !this.props.isFullScreen ? <Fullscreen fontSize={iconSize}/> : <FullscreenExit fontSize={iconSize}/>;
        return (
            <div className={this.props.classes.root} style={rootStyle}>
                {(!this.props.readOnly) &&
                    <React.Fragment>
                        <ToggleButtonGroup size="small" value={isLive} exclusive onChange={this.props.onToggleForceLiveFeed}>
                            <Tooltip title={t("klick om opgenomen data als bron te gebruiken wanneer beschikbaar. Als icoon blauw is, komt beeld en tractor data van disk")}>
                                <ToggleButton key={1} value={false} 
                                    selected={isLive===false} 
                                    
                                    className={toggle1Style}
                                    classes={{root: toggle1Style, selected: this.props.empty}}
                                >
                                    <Filmstrip fontSize={iconSize}/>
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title={t("klick om altijd live camera en tractor data als bron te gebruiken. Als icoon blauw is, is huidige beeld en tractor data live")}>
                                <ToggleButton key={2} value={true}  selected={isLive===true} 
                                    className={toggle2Style}
                                    classes={{root: toggle2Style, selected: this.props.empty}}
                                >
                                    <Cctv fontSize={iconSize}/>
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                        <Divider orientation="vertical" style={{width: '4px'}}/>
                    </React.Fragment>
                }

                <CameraSelect channels={this.props.channels} 
                    onSetChannel={this.props.onSetChannel} 
                    selectedChannel={this.props.selectedChannel}
                    allowInview={true}
                />


                <Tooltip title={t("Schakel tussen fullscreen en normale modus")}>
                    <ToggleButton key={2} value={true}  selected={this.props.isFullScreen} onChange={this.props.onToggleFullScreen} className={fullscreenClass}>
                        {fullscreenIcon}
                    </ToggleButton>
                </Tooltip>
                <Tooltip title={t("toggle_show_tractor_overlay")}>
                    <ToggleButton key={3} value={true}  
                        selected={this.props.showTractorData} 
                        disabled={this.props.disableToggleTractorData}
                        onChange={this.props.onToggleShowTractorData} 
                        className={fullscreenClass}>
                        <GaugeEmpty fontSize={iconSize}/>
                    </ToggleButton>
                </Tooltip>
                {(!configService.isSite && !configService.isViewer)  &&
                    <Tooltip title="Show video feed on a new window">
                        <Button  variant="outlined"
                                onClick={this.props.onOpenVideoScreen}
                                className={newScreenClass}>
                            <Monitor fontSize={iconSize}/>
                        </Button>
                    </Tooltip>
                }
            </div>
        );
    }

}

VideoSourceToolbar.propTypes = {
    forceLiveFeed: PropTypes.bool,
    isLiveFeed: PropTypes.bool,
    readOnly: PropTypes.bool,
    showTractorData: PropTypes.bool,
    onToggleShowTractorData: PropTypes.func,
    onToggleForceLiveFeed: PropTypes.func,
    isFullScreen: PropTypes.bool,
    onToggleFullScreen: PropTypes.func,
    disableToggleTractorData: PropTypes.bool,
    onOpenVideoScreen: PropTypes.func,
    selectedChannel: PropTypes.string,
    onSetChannel: PropTypes.func,
    channels: PropTypes.array,                              // list of available channels definitions
    tabletMode: PropTypes.bool,                                     // for the toolbar, when true, displayed differently
    minimalControls: PropTypes.bool,                                // for the toolbar, when false, show sidebars, otherwise hide as much as possible
};

export default withTranslation()(withStyles(styles)(VideoSourceToolbar));