/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react'; 
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import Slider from '@material-ui/core/Slider';


const styles = (theme) => ({
    root: {
        
    },
});


/**
 * UI element for managing the currently active user: config, logout..
 */
class CustomSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        }
        this.tooltip = props.t("camera lift");                          // only need to translate at creation, language wont change after that, is faster in rendering

        if (props.handle) {
            props.handle(this);
        }
    }

    componentWillUnmount() {
        if (this.props.handle) {
            this.props.handle(null);
        }
    }

    componentDidMount() {
        if (this.props.handle) {
            this.props.handle(this);
        }
    }


    render() {
        return <Tooltip title={this.tooltip}>
            <a style={{ height: '100%' }}>
                <Slider orientation="vertical" defaultValue={this.state.value} disabled={true} />
            </a>
        </Tooltip>
    }

    setValue = (value) => {
        if (value !== this.state.value) {
            this.setState({value: value});
        }
    }
}

CustomSlider.propTypes = {
    value: PropTypes.number,
    handle: PropTypes.func,                             // used to let the parent get a ref to this object
};

export default withTranslation()(withStyles(styles)(CustomSlider));