/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { activeProjectService } from '../../../../services/active_project_service';
import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from '../../../../services/dialog_service';
import { storageService } from "../../../../services/storage_service";
import { RobotBase } from './robot_base_service';
import { lidarService } from '../sonarLidar/lidar_service';
import { sonarService } from '../sonarLidar/sonar_service';
import { SonarPlayerService } from '../../../../components/document/robot/sonarLidar/sonar_player_service';
import { LidarPlayerService } from '../../../../components/document/robot/sonarLidar/lidar_player_service';
const csv = require( "csvtojson" );


/**
 * platform generic base class for the robot player.
 */
export class RobotPlayerBase extends RobotBase  {
    constructor() {
        super();
        this.currentIdx = -1;
        this.currentTimeStamp = 0;                      // provided by external video time
        this.onDataLoaded = null;                       // callback for robotSection (view) so that it knows when the data has been fully loaded (and can do a refresh of the layout, depending on the available data of the robot)
        this.onDataLoading = null;                      // callback for lidarRoll (and optionally others), so the system knows when a load starts and can show a loading icon
        this.sonarPlayer = null;                        // when set, there is sonar. this object manages the data 
        this.lidarPlayer = null;                        // when set, there is sonar. this object manages the data 
        this.headers = [];                              // to easily find what data is available
        this._hasSonar = false;                         // store this so we can get to the value before the entire data set is loaded. previously we checked if the sonar service already existed, which gavae a problem while switching tabs because ui was faster than loading of data
        this._hasLidar = false;                         // see _hasSonar
        this.isLoading = false;                         // so others know that we are in the process of loading (in case they got created afer the event was triggered, before data is loaded)
    }

    /**
     * when this service starts, load the data and start monitoring switches in selected streng.
     * when selection changes, need to reload data. Only start monitoring when a document is loaded
     * that will play the robot data.
     */
    start() {
        const hasSonar = activeProjectService.activeStrengHasSonarData();
        this._hasSonar = hasSonar;
        const hasLidar = activeProjectService.activeStrengHasLidarData();
        this._hasLidar = hasLidar;
        this.isLoading = true;
        if (this.onDataLoading) this.onDataLoading();
        this.loadData().then(() =>{
            if (hasSonar) {
                this.sonarPlayer = new SonarPlayerService();
                return this.sonarPlayer.load();
            }
        }).then(() => {
            if (hasLidar) {
                this.lidarPlayer = new LidarPlayerService();
                return this.lidarPlayer.load();
            }
        }).then(() => {
            if (hasSonar) {                                 // we can only int the services when the data has been loaded, otherwise some data might still be missing.
                sonarService.init(this.sonarPlayer);
            }
            if (hasLidar) {
                if (this.lidarPlayer.version === 1) {                           // check if there needs to be a conversion on the data and do so if needed
                    this.lidarPlayer.convertData(this.data);
                }
                lidarService.init(this.lidarPlayer);
            }
            this.isLoading = false;
            if (this.onDataLoaded) this.onDataLoaded();
        }).catch(() => {
            this.isLoading = false;
            if (this.onData) this.onData(null);                                  // make certain that no previous data gets stuck, but the error is hwn that there is no data for this streng.
        });                   
    }
 
    stop() {
        this.currentIdx = -1;
        this.currentTimeStamp = 0;
        super.stop();
        if (this.lidarPlayer) {
            this.lidarPlayer.reset();
            this.lidarPlayer = null;
        }
        if (this.sonarPlayer) {
            this.sonarPlayer.reset();
            this.sonarPlayer = null;
        }
    }

    internalLoadData(streng, reverse, folder) {
        streng = streng ?? activeProjectService.activeStreng;
        const url = reverse ? streng.robotRevDataUrl : streng.robotDataUrl;
        return (url) ? storageService.readContent(url.bucket, url.name) : Promise.resolve("");
    }

    /**
     * loads tractor data and returns the value. 
     * @param {object/null} streng the streng to load data for. When null, activeStreng is used
     * @param {bool} autoplay when true, first event is sent through event engine after loading, so ui can show new value
     * @param {bool} reverse when true, reverse tractor data is recorded
     * @param {string/null} folder root folder to the project. When null, the active project is used
     */
    async loadData(streng=null, autoplay=true, reverse=false, folder=null) {
        return new Promise( (resolve, reject) => {
            try {
                this.internalLoadData(streng, reverse, folder).then((content) => {
                    if (content) {
                        const config = this.buildCsvConfig(content);
                        csv(config).fromString(content).then((data) => {
                            this.data = data;
                            if (autoplay && data && this.data.length > 0) {
                                this.currentIdx = 0;
                                this.playCurrent(true);                     // force playing, cause it's initial state
                            }
                            else {
                                this.currentIdx = -1;
                            }
                            resolve(data);
                        }).catch((error) => {
                            dialogService.error(i18n.t("Laden"), i18n.t("doc_load_error", {error: errExtractor.get(error)}));
                            reject();
                        });
                    }
                    else {
                        resolve([]);
                    }
                }).catch((error) => {
                    dialogService.error(i18n.t("Laden"), i18n.t("doc_load_error", {error: errExtractor.get(error)}));
                    reject();
                });
            }
            catch(error) {
                dialogService.error(i18n.t("Laden"), i18n.t("doc_load_error", {error: errExtractor.get(error)}));
                reject();
            }
        });
    }

    /**
     * checks the version of the file by reading the first line and checking the nr of columns
     * @param {string} content the csv data
     */
    buildCsvConfig(content) {
        const idx = content.indexOf('\n');
        let nrOfColumns = 6;                                        // default value = use original format
        let hasHeader = false;
        let itemsOnFirstLine;
        if (idx > 0) {                                              // if file is empty, use default, it doesn't matter
            const firstLine = content.slice(0, idx);
            itemsOnFirstLine = firstLine.split('|'); 
            nrOfColumns = itemsOnFirstLine.length;
            hasHeader = firstLine.startsWith('timestamp');          // if the first line starts with text, we have a header. Oldest formats didn't have one
            
        }

        if (hasHeader) {
            this.headers = itemsOnFirstLine;
            return ({
                delimiter: "|", 
                    eol: '\n',
                    colParser:{
                        "timestamp":"number",
                        "pitch":"number",
                        "roll":"number",
                        "distance":"number",
                        "tilt":"number",
                        "pan":"number",
                        "yaw": "number",
                        "aX": "number",
                        "aY": "number",
                        "aZ": "number",
                        "liftPos": "number",
                        "lensPressure": "number",
                        "pressure": "number",
                        "battery": "number",
                        "laserRange": "number",
                    }
            });
        }
        else if (nrOfColumns === 6) {
            this.headers = ['timestamp','pitch', 'roll', 'distance', 'tilt', 'pan'];
            return (
                {headers: this.headers, 
                    delimiter: "|", 
                    noheader: true, 
                    eol: '\n',
                    colParser:{
                        "timestamp":"number",
                        "pitch":"number",
                        "roll":"number",
                        "distance":"number",
                        "tilt":"number",
                        "pan":"number",
                    }
                }
            );
        } else if (nrOfColumns === 7) {
            this.headers = ['timestamp','pitch', 'roll', 'distance', 'tilt', 'pan', 'yaw'];
            return (
                {headers: this.headers, 
                    delimiter: "|", 
                    noheader: true, 
                    eol: '\n',
                    colParser:{
                        "timestamp":"number",
                        "pitch":"number",
                        "roll":"number",
                        "distance":"number",
                        "tilt":"number",
                        "pan":"number",
                        "yaw": "number",
                    }
                }
            );
        }
        else {
            throw new Error("internal error: unknown tractor data format");
        }
    }


    /**
     * returns the data event that was last played.
     * This is used by the ui component to perform a proper init when loaded
     * return null if there isn't any
     */
    getLastEvent() {
        if (this.data && this.currentIdx > -1 && this.currentIdx < this.data.length) {
            return this.updateRecForPlay(this.data[this.currentIdx]);
        }
        return null;
    }

    /**
     * allows inheriters to add/modify data at runtime.
     * this is used to add laser measurements to the robot data.
     * @param {object} rec the robot data record to play
     * @returns the updated data record
     */
    updateRecForPlay(rec) {
        return rec;
    }

    /**
     * find position in list for current time stamp and call callback with new data.
     * @param {bool} forced when true, the event will always be triggered, when false, only when current pos changed.
     */
    playCurrent(forced = false) {
        try {
            if (this.data) {
                const prevIdx = this.currentIdx;
                while(this.currentIdx < this.data.length - 1) {
                    let next = this.data[this.currentIdx+1];
                    if (next.timestamp >= this.currentTimeStamp) break;
                    this.currentIdx++;
                }
                if((prevIdx != this.currentIdx || forced) && this.currentIdx < this.data.length) {
                    const curRec = this.data[this.currentIdx];
                    if (curRec && this.onData) {
                        this.onData(this.updateRecForPlay(curRec));
                    }
                }
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    setCurrentTimestamp(value) {
        let forced = false;
        if(value < this.currentTimeStamp) {
            this.currentIdx = 0;          // going back in the dataset, so start search from beginning (easiest)
            if (value == 0) forced = true;                  // need to force playing the first item, cause there is no change in index nr, so otherwise no data would be played -> when playing ends, no going back to start automatically
        }
        this.currentTimeStamp = value;
        this.playCurrent(forced);

        if (this.lidarPlayer) {
            this.lidarPlayer.setCurrentTimestamp(value);
        }
        if (this.sonarPlayer) {
            this.sonarPlayer.setCurrentTimestamp(value);
        }
    }

    /**
     * never recording
     */
    isRecording() {
        return false;
    }

    hasLaserMeasure() {
        return this.headers.includes('laserRange');
    }

    hasPitch() {
        return this.headers.includes('pitch');
    }

    hasYaw() {
        return this.headers.includes('yaw');
    }

    hasRoll() {
        return this.headers.includes('roll');
    }

    hasCameraPan() {
        return this.headers.includes('pan');
    }

    hasCameraTilt() {
        return this.headers.includes('tilt');
    }

    hasDistance() {
        return this.headers.includes('distance');
    }

    hasSonar() {
        return this._hasSonar;
    }

    hasLidar() {
        return this._hasLidar;
    }

    hasElevator() {
        return this.headers.includes('liftPos');
    }

    hasAccelerometer() {
        return this.headers.includes('aX');
    }

    hasLensPressure() {
        return this.headers.includes('lensPressure');
    }

    hasTractorPressure() {
        return this.headers.includes('pressure');
    }

    hasWheelPressure() {
        return this.headers.includes('leftDrivePressure');
    }

    /**
     * the min/max for all supported sensors
     */
    get sensorLimits() {
        const streng = activeProjectService.activeStreng;
        if (streng && streng.tractor && streng.tractor.limits) {
            return streng.tractor.limits;
        }
        return {};
    }

}