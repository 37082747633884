/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { CameraBaseService } from '../../../shared_components/document/video/camera_base_service' ;

class CameraService extends CameraBaseService{
    constructor() {
        super();
    }

    async start() {
        return Promise.reject("not implemented");
    }

    async stop() {
        return Promise.reject("not implemented");
    }

    async tryFixRecording() {
        return Promise.resolve();
    }

    stopRecord() {
        // don't need to do anything, recording not yet supported through web. Needed for code protability with recorder
    }
    detachCamera(channel) {
    }
 
}
export const cameraService = new CameraService();