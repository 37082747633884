
/**
 * used to keep track of the current play/recording time pos.
 */
class TimeService {
    constructor() {
        this.current = 0;
    }
}

export const timeService = new TimeService();