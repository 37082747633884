/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import RecordCircle from 'mdi-material-ui/RecordCircle';
import Stop from 'mdi-material-ui/Stop';
import PlayCircle from 'mdi-material-ui/PlayCircle';
import PauseCircle from 'mdi-material-ui/PauseCircle';


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
    toolbarBtn: {
        backgroundColor: theme.palette.action.focus,
        marginBottom: "0px",
        borderRadius: '4px',
        justifyContent: "center",
        height: "100%,",
        '&:disabled': {
            color: 'rgba(255, 255,255, 0.26)',
            backgroundColor: theme.palette.action.focus,
        },
    },
});


/* speed optimization. By putting the buttons in a sperate renderer, they don't need to be rendered each time the timer is updated. */
class SingleButtonController extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
        this.txtRec = props.t("Record (F5)");
        this.txtReverse = props.t("Reverse tractor record");
        this.txtStop = props.t("Stop (F4)");
        this.txtPlay = props.t("Play");
        this.txtPause = props.t("Pause");
    }

    render() {
        const { classes, buttonType } = this.props;

        let btn;
        switch (buttonType) {
            case 'rec':
            case 'record':
                btn = <Tooltip title={this.txtRec}>
                        <a>
                            <IconButton aria-label="record"
                                id="record"
                                color="inherit"
                                onClick={this.props.onBtnPress}
                                disabled={this.props.disabled}
                                className={classes.toolbarBtn}
                            >
                                <RecordCircle fontSize="large"/>
                            </IconButton>
                        </a>
                    </Tooltip>
                break;
            case 'stop':
                btn =<Tooltip title={this.txtStop}>
                        <a>
                            <IconButton aria-label="stop"
                                id="stop"
                                color="inherit"
                                onClick={this.props.onBtnPress}
                                className={classes.toolbarBtn}>
                                <Stop fontSize="large"/>
                            </IconButton>
                        </a>
                    </Tooltip>
                break;
            case 'play':
                btn =<Tooltip title={this.txtPlay}>
                        <a>
                            <IconButton aria-label="play"
                                id="play"
                                color="inherit"
                                disabled={this.props.disabled}
                                onClick={this.props.onBtnPress}
                                className={classes.toolbarBtn}>
                                <PlayCircle fontSize="large"/>
                            </IconButton>
                        </a>
                    </Tooltip>
                break;
            case 'pause':
                btn =<Tooltip title={this.txtPause}>
                        <a>
                            <IconButton aria-label="pause"
                                id="pause"
                                color="inherit"
                                onClick={this.props.onBtnPress}
                                className={classes.toolbarBtn}>
                                <PauseCircle fontSize="large"/>
                            </IconButton>
                        </a>
                    </Tooltip>
                break;
            default:                                                                        // disabled
                btn = <Tooltip title={this.txtRec}>
                        <a>
                            <IconButton aria-label="disabled"
                                id="record"
                                disabled={true}
                                color="inherit"
                                onClick={this.props.onBtnPress}
                                className={classes.toolbarBtn}
                            >
                                <RecordCircle fontSize="large"/>
                            </IconButton>
                        </a>
                    </Tooltip>
                break;
        }
        
        return (btn);
    }

}

SingleButtonController.propTypes = {
    buttonType: PropTypes.string,                       // allowed: rec, stop, play
    disabled: PropTypes.bool,
    onBtnPress: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(SingleButtonController));