/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import CameraOutline from 'mdi-material-ui/CameraOutline';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { userService } from '../services/user_service';
import { dialogService } from '../../services/dialog_service';
import { storageService } from '../../services/storage_service';
import { errExtractor } from '../services/error_extractor';
import { loadImage } from '../../services/image_service';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    text: {
        textAlign: 'center'
    },
    subIcon: {
        background: theme.palette.background.paper,
        boxShadow: '0 1px 1px 0 rgba(65,69,73,0.3), 0 1px 3px 1px rgba(65,69,73,0.15);',
        padding: '2px',
        '&:hover': {
            background: theme.palette.background.paper,
        }
    },
    textBox: {
        marginLeft: theme.spacing(2)
    }
});

const INPUT_LABEL_PROPS = { shrink: true, style:{width: '135%'} };

class AccountDetailsDialog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            avatar: props.avatar,
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            username: props.user.username,
            pwd: "",
        }

        this.changeObj = {};                                        // keeps track of all the changes that need to be posted to server
        this.avatarFile = null;
    }


    render() {
        const { t } = this.props; 
        //const fullName = `${this.state.firstName ?? ""} ${this.state.lastName ?? ""}`;  
        
        let badgeContent = null;
        if (this.props.allowUploadFoto) {
            badgeContent = <IconButton className={this.props.classes.subIcon}
                            onClick={this.selectImg}>
                                <CameraOutline fontSize="small"/>
                            </IconButton>
        }
        return (
            <Dialog                
                aria-labelledby="account-details-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="account-details-dialog-title">{t("Account details")}</DialogTitle>
                <DialogContent dividers>
                    <Box display="flex" flexDirection="row" alignItems="flex-start">
                        <Badge
                            overlap="circle"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}
                            badgeContent={badgeContent}
                        >
                            <Avatar src={this.state.avatar} alt="Your image" style={{height: '170px', width: '170px'}} />
                        </Badge>
                        <Box display="flex" flexDirection="column" alignItems="stretch" className={this.props.classes.textBox}>    
                            <TextField
                                id="firstName"
                                label={t("First name")}
                                InputLabelProps={INPUT_LABEL_PROPS}
                                value={this.state.firstName}
                                onChange={this.handleTextChanged}
                                className={this.props.classes.textField}
                                margin="dense"
                            />
                            <TextField
                                id="lastName"
                                label={t("Last name")}
                                InputLabelProps={INPUT_LABEL_PROPS}
                                value={this.state.lastName}
                                onChange={this.handleTextChanged}
                                className={this.props.classes.textField}
                                margin="dense"
                            />
                            <TextField
                                id="username"
                                label={t("Gebruikersnaam")}
                                InputLabelProps={INPUT_LABEL_PROPS}
                                value={this.state.username}
                                onChange={this.handleTextChanged}
                                className={this.props.classes.textField}
                                margin="dense"
                            />
                            <TextField
                                id="pwd"
                                type="password"
                                autoComplete="new-password"
                                label={t("New password")}
                                InputLabelProps={INPUT_LABEL_PROPS}
                                value={this.state.pwd}
                                onChange={this.handlePwdChanged}
                                className={this.props.classes.textField}
                                margin="dense"
                            />
                        </Box>
                    </Box>
                    </DialogContent>
                <DialogActions>
                <Button onClick={this.handleCancel} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={this.handleOk} color="primary">
                        {t("Save changes")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    
    handleOk = async () => {
        let result = {user: null, avatar: null};
        try {
            if (Object.keys(this.changeObj).length > 0) {                           // if there were any changes, post them to server
                result.user = await userService.saveUser(this.changeObj, this.props.user.id);
                result.user['token'] = this.props.user.token;                       // always need to preserve the token, this is no longer provided in the return.
                userService.setCurrentUser(result.user);
            }
            if (this.avatarFile) {
                await storageService.uploadAvatar(this.avatarFile, this.props.user.id);
                result.avatar = await storageService.getAvatarUrl(this.props.user.id);
            }
            if (this.state.pwd) {                                                   // need to change the pwd
                await userService.changePwd(this.props.user.id, this.state.pwd);
            }
            if (this.props.onClose) this.props.onClose(result);
        }
        catch(error) {
            const { t } = this.props; 
            dialogService.error(t("Account details"), errExtractor.get(error));
        }
    }

    handleCancel = () => {
        if (this.props.onClose) this.props.onClose();
    }

    handlePwdChanged = (ev) => {
        try {
            this.setState({pwd: ev.target.value});
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Account details"), errExtractor.get(error));
        }
    }

    handleTextChanged = (ev) => {
        try {
            this.changeObj[ev.target.id] = ev.target.value;
            let newState = {};
            newState[ev.target.id] = ev.target.value;
            this.setState(newState);
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Account details"), errExtractor.get(error));
        }
    }

    selectImg = () => {
        if (!this.props.user) {
            dialogService.error("account", "not logged in");
            return;
        }
        const imgs = dialogService.openImage();
        if (imgs && imgs.length > 0) {
            loadImage(imgs[0]) 
                .then( (response) => {
                    this.avatarFile = imgs[0];
                    this.setState({avatar: `data:image/png;base64, ${response}`});
                }).catch( (error) => {
                    const { t } = this.props; 
                    dialogService.error(t("Account details"), errExtractor.get(error));
                });
        }
    }
}

AccountDetailsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    allowUploadFoto: PropTypes.bool.isRequired,
};

export default withTranslation()(withStyles(styles)(AccountDetailsDialog));