/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RobotToolbar from './robot_toolbar_component';
import Paper from '@material-ui/core/Paper';
import { trackService } from '../track/track_server_service';
import TrackView from '../track/track_view_component';
import { downloadService } from '../../../services/download_service';
import { configService } from '../../../../services/config_service';
import { activeProjectService } from '../../../../services/active_project_service';
import { documentControlService } from '../../document_control_service';
import SonarView from '../sonarLidar/sonar_component';
import { layoutService } from '../../layout/layout_service';
import LidarRollout from '../sonarLidar/lidar_rollout_component';
import { configDefaults } from '../../../services/config_settings';
import { lidarService } from '../sonarLidar/lidar_service';
import { getLidarRolloutMinMax } from '../sonarLidar/imaging_service';


const styles = (theme) => ({
   
    paper: {
        height: '100%',
        flex: 1,
        flexDirection: "row",
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        display: 'flex',
        padding: theme.spacing(1)
    },

});


/**
 * base class for the ui element that contains a single item in the robot section. 
 * an item can be a sonar view or track view. This always houses a toolbar
 */
class RobotSectionBody extends React.PureComponent {
    constructor(props) {
        super(props);

        
        const defaults = configDefaults.layout.document.robotSection.parts[1];

        let showPuddles = configService.get(`layout.document.robotSection.parts.${props.configId}.inclination.showPuddles`);
        let adjustHeightDif = configService.get(`layout.document.robotSection.parts.${props.configId}.inclination.adjustHeightDif`);
        let seriesStyle = configService.get(`layout.document.robotSection.parts.${props.configId}.inclination.seriesStyle`);
        let showGrid = configService.get(`layout.document.robotSection.parts.${props.configId}.inclination.showGrid`);
        let showStartEndLabels = configService.get(`layout.document.robotSection.parts.${props.configId}.inclination.showStartEndLabels`);
        let sampleDistance = +configService.get(`layout.document.robotSection.parts.${props.configId}.inclination.sampleDistance`);
        let sonarZoom = +configService.get(`layout.document.robotSection.parts.${props.configId}.sonar.zoom`);
        let lidarZoom = +configService.get(`layout.document.robotSection.parts.${props.configId}.lidar.zoom`);
        let lidarRollZoom = +configService.get(`layout.document.robotSection.parts.${props.configId}.lidarRoll.zoom`);
        let source = configService.get(`layout.document.robotSection.parts.${this.props.configId}.source`);
        let lidarRollSnap = configService.get(`layout.document.robotSection.parts.${props.configId}.lidarRoll.snapToHeight`);

        if (lidarRollZoom === 0) lidarRollZoom = 100;                                               // can be 0 if never init
        let lidarGrayMode = defaults.lidarRoll.grayMode;
        let lidarFilterRange = lidarService.provider?.minMax ??  defaults.lidarRoll.filterRange;
        let lidarColors = defaults.lidarRoll.colors;
        let lidarColorStops = defaults.lidarRoll.colorStops;
        this.state = {
            source: source,                                                                           // currently selected data source being displayed. values: 'sonar', 'lidar', 'inclination'
            showPuddles: showPuddles === true || showPuddles === "true",
            adjustHeightDif: adjustHeightDif === true || adjustHeightDif === "true",                // when true, height will be adjusted so that it fits within the restrictions given in he streng
            seriesStyle: seriesStyle,
            showStartEndLabels: showStartEndLabels === true || showStartEndLabels === 'true',
            showGrid: showGrid === true || showGrid === 'true',
            sampleDistance: sampleDistance,
            showReverse: false,                                                                     // when true (and possible), reverse tractor data is shown.
            showObsDistances: true,                                                                 // when true, labels on x axis show the locations of the observation occurances + tractor reported start & end point, otherwise it's the flattened distance
            sonarZoom: sonarZoom,
            lidarZoom: lidarZoom,
            lidarRollZoom: lidarRollZoom,
            lidarRollSnap: lidarRollSnap,
            lidarGrayMode: lidarGrayMode === true || lidarGrayMode === "true",
            lidarFilterRange: lidarFilterRange,
            maxLidarFilterRange: lidarFilterRange,
            lidarColors: lidarColors,
            lidarColorStops: lidarColorStops,
            showDeformationChanged: false,
            showMeasDistCrossSection: false,                                                            // when true, the cross-section lidar will allow/show measuring a distance between 2 points on the wall
            showMeasDistRollout: false,                                                                 // same as showMeasDistCross section, but for rollout
            showMeasAngleRollout: false,
        }

        this.trackRef = React.createRef();                                                          // for the export
    }

    getSource() {
        const robot = documentControlService.robotDs;
        const robotHasSonar = robot.hasSonar();
        const robotHasLidar = robot.hasLidar();

        let source = this.state.source;
        if (!robotHasLidar && !robotHasSonar) {                 // when no sonar or lidar, force to inclination, can't depict anything else
            source = 'inclination';
        }
        return source;
    }

    componentDidMount() {                               
        documentControlService.events.addListener('onRobotDataLoaded', this.handleDataLoaded);  // when playing data, this object is rendered before all the data is loaded, but the data determins the render, so redraw
        layoutService.events.on('changed', this.handleLayoutChanged);
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onRobotDataLoaded', this.handleDataLoaded);
        layoutService.events.removeListener('changed', this.handleLayoutChanged);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showPuddles != prevState.showPuddles) {
            configService.set(`layout.document.robotSection.parts.${this.props.configId}.inclination.showPuddles`, this.state.showPuddles);
        }
        if (this.state.seriesStyle != prevState.seriesStyle) {
            configService.set(`layout.document.robotSection.parts.${this.props.configId}.inclination.seriesStyle`, this.state.seriesStyle);
        }
        if (this.state.sampleDistance != prevState.sampleDistance) {
            configService.set(`layout.document.robotSection.parts.${this.props.configId}.inclination.sampleDistance`, this.state.sampleDistance);
        }
        if (this.state.showStartEndLabels != prevState.showStartEndLabels) {
            configService.set(`layout.document.robotSection.parts.${this.props.configId}.inclination.showStartEndLabels`, this.state.showStartEndLabels);
        }
        if (this.state.showGrid != prevState.showGrid) {
            configService.set(`layout.document.robotSection.parts.${this.props.configId}.inclination.showGrid`, this.state.showGrid);
        }
        if (this.state.source != prevState.source) {
            configService.set(`layout.document.robotSection.parts.${this.props.configId}.source`, this.state.source);
        }
    }

    getZoom() {
        switch (this.getSource()) {
            case 'sonar-cross-section':
                return this.state.sonarZoom;
            case 'lidar-cross-section':
                return this.state.lidarZoom;
            case 'lidar-rollout':
                return this.state.lidarRollZoom;
            case 'inclination': 
            default:
                return 0;
        }
    }

    render() {
        const { t, classes } = this.props;
        let showPuddles = this.props.liveFeed ? false : this.state.showPuddles;
        let showStartEndLabels = this.props.liveFeed ? false : this.state.showStartEndLabels;
        const robot = documentControlService.robotDs;
        if (!robot || !this.props.initLoaded) {                                     // important: when not yet fully loaded (no engine config), dont render dashboard yet, cause otherwise we get 2 render passes, first for dashboard only, then a combo, which screws up the size of the dials onthe dashboard
            return null;
        }
        const robotHasSonar = robot.hasSonar();
        const robotHasLidar = robot.hasLidar();
        const zoom = this.getZoom();
        const source = this.getSource();
        return (
            <Paper className={this.props.classes.paper}>
                <RobotToolbar liveFeed={this.props.liveFeed}
                    readOnly={this.props.readOnly}
                    dashboardOpen={this.props.dashboardOpen}
                    showMainCrawlerFunctions={this.props.showMainCrawlerFunctions}
                    showDashboardFunctions={this.props.showDashboardFunctions}
                    onToggleDashboard={this.props.onToggleDashboard}
                    onFindTimeForDistance={this.props.onFindTimeForDistance}
                    allowedHeight={this.props.allowedHeight}
                    onSelectSource={this.handleSelectSource}
                    selectedSource={source}
                    showPuddles={showPuddles}
                    onToggleShowPuddles={this.handleToggleShowPuddles}
                    adjustHeightDif={this.state.adjustHeightDif}
                    onToggleHeightDif={this.handleToggleHeightDif}
                    seriesStyle={this.state.seriesStyle}
                    onToggleSeriesStyle={this.handleToggleSeriesStyle}
                    sampleDistance={this.state.sampleDistance}
                    onSampleDistanceChange={this.handleSampleDistanceChange}
                    onToggleGrid={this.handleToggleGrid}
                    showGrid={this.state.showGrid}
                    hasReverse={this.props.hasReverseTrackData}
                    showReverse={this.state.showReverse && this.props.hasReverseTrackData}
                    onToggleShowReverse={this.handleToggleShowReverse}
                    showStartEndLabels={showStartEndLabels}
                    onToggleStartEndLabels={this.handleToggleStartEndLabels}
                    onExportImage={this.handleExportImage}
                    showObsDistances={this.state.showObsDistances}
                    onToggleShowObsDistances={this.handleToggleShowObsDistance}
                    onSelectLayout={this.props.onSelectLayout}
                    onToggleVideoSection={this.props.onToggleVideoSection}
                    showVideoSection={this.props.showVideoSection}
                    hasSonar={robotHasSonar}
                    hasLidar={robotHasLidar}
                    onZoomChanged={this.handleZoomChanged}
                    zoom={zoom}
                    onLidarRollSnapChanged={this.handleLidarRollSnapChanged}
                    lidarRollSnap={this.state.lidarRollSnap}
                    onOpenVideoScreen={this.props.onOpenVideoScreen}

                    lidarGrayMode={this.state.lidarGrayMode}
                    onLidarGrayModeChanged={this.handleLidarGrayModeChanged}
                    lidarFilterRange={this.state.lidarFilterRange}
                    maxLidarFilterRange={this.state.maxLidarFilterRange}
                    onLidarFilterRangeChanged={this.handleLidarFilterRangeChanged}
                    lidarColors={this.state.lidarColors}
                    onLidarColorsChanged={this.handleLidarColorsChanged}
                    lidarColorStops={this.state.lidarColorStops}
                    onLidarColorStopsChanged={this.handleLidarColorStopsChanged}
                    onShowDeformationChanged={this.handleShowDeformationChanged}
                    onShowMeasureDistanceCrossSectionChanged={this.handleMeasDistCrossSectionChanged}
                    onShowMeasureDistanceRolloutChanged={this.handleMeasDistRolloutChanged}
                    onShowMeasureAngleRolloutChanged={this.handleMeasAngleRolloutChanged}
                    /* onToggleTrackSonarView={this.handleToggleTrackSonarView}
                    showTrack={showTrack}
                    
                    
                    
                                        
                    RadarDisplayMode={this.state.radarDisplayMode}
                    
                    
                */
                />         
                {this.renderBody(source)}
            </Paper>
            
        );
    }

    handleSelectSource = (value) => {
        if (value) {
            this.setState({source: value});
            this.handleDataLoaded(value);                        // when switching source, need to make certain that the correct robot data has been loaded, which could be that it wasn't the case when somehting else was previously shown
        }
    }

    renderBody(source) {
        const { allowedHeight, verSplit} = this.props;
        switch (source) {
            case 'sonar-cross-section':
                return (
                    <SonarView metersInDiameter={this.state.sonarZoom} allowedHeight={allowedHeight} showLidar={false} showSonar={true} verSplit={verSplit}
                        resizeRequest={this.props.resizeRequest}/>
                );
            case 'lidar-cross-section':
                return (
                    <SonarView metersInDiameter={this.state.lidarZoom} 
                        allowedHeight={allowedHeight} 
                        showLidar={true} 
                        showSonar={false} 
                        verSplit={verSplit}
                        resizeRequest={this.props.resizeRequest} 
                        measureData={this.props.measureData} 
                        onMeasured={this.props.onMeasured}
                        showDeformationChanged={this.state.showDeformationChanged}
                        showMeasDistCrossSection={this.state.showMeasDistCrossSection}/>
                );
            case 'lidar-rollout':
                    return (
                        <LidarRollout liveFeed={this.props.liveFeed} 
                            streng={this.props.streng} 
                            allowedHeight={allowedHeight} 
                            snapToHeight={this.state.lidarRollSnap} 
                            zoom={this.state.lidarRollZoom}
                            grayMode={this.state.lidarGrayMode}
                            filterRange={this.state.lidarFilterRange}
                            colors={this.state.lidarColors}
                            colorStops={this.state.lidarColorStops}
                            verSplit={this.props.verSplit}
                            controlState={this.props.controlState}
                            showReverse={this.state.showReverse && this.props.hasReverseTrackData}
                            showMeasDistRollout={this.state.showMeasDistRollout}
                            showMeasAngleRollout={this.state.showMeasAngleRollout}
                        />
                    );
                break;
            case 'inclination': 
                let showPuddles = this.props.liveFeed ? false : this.state.showPuddles;
                let showStartEndLabels = this.props.liveFeed ? false : this.state.showStartEndLabels;
                return(
                    <TrackView innerRef={this.trackRef} 
                        liveFeed={this.props.liveFeed}
                        readOnly={this.props.readOnly}
                        direction={this.props.direction}
                        onDirectionChanged={this.props.onDirectionChanged}
                        changePosAllowed={this.props.changePosAllowed}
                        onPositionChanged={this.props.onPositionChanged}
                        renderStandingWater={showPuddles}
                        adjustHeightDif={!this.state.adjustHeightDif}
                        renderSeriesOutline={this.state.seriesStyle !== "ideal"}
                        renderSeriesSimple={this.state.seriesStyle !== "error"}
                        sampleDistance={this.state.sampleDistance}
                        renderMarkers={this.state.showGrid}
                        renderStartEndLabels={showStartEndLabels}
                        showReverse={this.state.showReverse && this.props.hasReverseTrackData}
                        showObsDistances={this.state.showObsDistances}
                    />
                );
            default:
                return(null);
        }
    }

    handleToggleHeightDif = () => {
        this.setState({adjustHeightDif: !this.state.adjustHeightDif});
    }

    handleToggleShowPuddles = () => {
        this.setState({ showPuddles: !this.state.showPuddles });
    }

    handleToggleSeriesStyle = () => {
        switch (this.state.seriesStyle) {
            case "error":
                this.setState({ seriesStyle: "error+ideal" });
                break;
            case "error+ideal":
                this.setState({ seriesStyle: "ideal" });
                break;
            case "ideal":
                this.setState({ seriesStyle: "error" });
                break;
            default:
                break;
        }
    }

    handleSampleDistanceChange = (value) => {
        trackService.recalculateData(value);
        this.setState({sampleDistance: value});
    }

    handleToggleGrid = () => {
        this.setState({ showGrid: !this.state.showGrid });
    }

    handleToggleShowReverse = async () => {
        if (!this.state.showReverse && this.props.hasReverseTrackData) {
            this.setState({showReverse: true});
            if (this.getSource() === 'lidar-rollout') {
                const reverseProvider = await lidarService.loadReverseProvider(this.props.streng);
                this.setLidarConfig(reverseProvider);
            }
        }   
        else {
            this.setState({showReverse: false});
        }
    }

    setLidarConfig(provider) {
        const config = provider.config;
        if (config) {
            let maxFilterRange = provider.minMax;
            let lidarGrayMode = config.grayMode;
            let lidarColors = config.colors;
            let lidarColorStops = config.colorStops;
            let lidarFilterRange = config.filterRange;
            if (lidarFilterRange[0] === -1 && lidarFilterRange[1] === -1) {     // never been initialized, do it now
                lidarFilterRange = maxFilterRange;    
            }

            this.setState({
                lidarGrayMode: lidarGrayMode === true || lidarGrayMode === "true",
                lidarFilterRange: lidarFilterRange,
                maxLidarFilterRange: maxFilterRange,
                lidarColors: lidarColors,
                lidarColorStops: lidarColorStops,
            });
        }
    }

    handleToggleStartEndLabels = () => {
        this.setState({ showStartEndLabels: !this.state.showStartEndLabels });
    }

    handleExportImage = () => {
        const trackView = this.trackRef.current;
        if (trackView) {
            const value = trackView.svg.compileSvg();
            const name = activeProjectService.projectName + ' - ' + this.props.streng?.ref;
            if (downloadService.downloadSvg) {                      // we can save to file (electron), so do this, othwerwise save to url (web)
                downloadService.downloadSvg(value, name);
            }
            else {
                downloadService.downloadSvgAsUrl(value, name);
            }
        }
    }

    handleToggleShowObsDistance = () => {
        this.setState({showObsDistances: !this.state.showObsDistances});
    }

    handleZoomChanged = (value) => {
        switch (this.getSource()) {
            case 'sonar-cross-section':
                configService.set(`layout.document.robotSection.parts.${this.props.configId}.sonar.zoom`, value);
                this.setState({sonarZoom: value});
                break;
            case 'lidar-cross-section':
                configService.set(`layout.document.robotSection.parts.${this.props.configId}.lidar.zoom`, value);
                this.setState({lidarZoom: value});
                break;
            case 'lidar-rollout':
                configService.set(`layout.document.robotSection.parts.${this.props.configId}.lidarRoll.zoom`, value);
                this.setState({lidarRollZoom: value});
                break;
            case 'inclination': 
            default:
                return 0;
        }
    }

    getLidarProvider() {
        if (this.props.showReverse) {
            return lidarService.reverseProvider;
        }
        else {
            return lidarService.provider;
        }
    }

    handleLidarRollSnapChanged = (event) => {
        const value = event.target.checked;
        configService.set(`layout.document.robotSection.parts.${this.props.configId}.lidarRoll.snapToHeight`, value);
        this.setState({lidarRollSnap: value});
    }

    handleLidarGrayModeChanged = (event) => {
        const value = event.target.checked;
        const provider = this.getLidarProvider();
        const config = Object.assign({}, provider.config);                 // need new object so it saves
        config.grayMode = value;
        provider.config = config;
        this.setState({lidarGrayMode: value});
    }

    handleLidarFilterRangeChanged = (value) => {
        const provider = this.getLidarProvider();
        const config = Object.assign({}, provider.config);                 // need new object so it saves
        config.filterRange = value;
        provider.config = config;
        this.setState({lidarFilterRange: value});
    }

    handleLidarColorsChanged = (value) => {
        const provider = this.getLidarProvider();
        const config = Object.assign({}, provider.config);                 // need new object so it saves
        config.colors = value;
        provider.config = config;
        this.setState({lidarColors: value});
    }

    handleLidarColorStopsChanged = (event, value) => {
        const provider = this.getLidarProvider();
        const config = Object.assign({}, provider.config);                 // need new object so it saves
        config.colorStops = value;
        provider.config = config;
        this.setState({lidarColorStops: value});
    }

    handleShowDeformationChanged = (value) => {
        this.setState({showDeformationChanged: value});
    }

    handleMeasDistCrossSectionChanged = (value) => {
        this.setState({showMeasDistCrossSection: value});
    }

    handleMeasDistRolloutChanged = (value) => {
        this.setState({showMeasDistRollout: value});
    }

    handleMeasAngleRolloutChanged = (value) => {
        this.setState({showMeasAngleRollout: value});
    }

    handleLayoutChanged = () => {
        let sonarZoom = +configService.get(`layout.document.robotSection.parts.${this.props.configId}.sonar.zoom`);
        let lidarZoom = +configService.get(`layout.document.robotSection.parts.${this.props.configId}.lidar.zoom`);
        let showPuddles = configService.get(`layout.document.robotSection.parts.${this.props.configId}.inclination.showPuddles`);
        let seriesStyle = configService.get(`layout.document.robotSection.parts.${this.props.configId}.inclination.seriesStyle`);
        let sampleDistance = +configService.get(`layout.document.robotSection.parts.${this.props.configId}.inclination.sampleDistance`);
        let showGrid = configService.get(`layout.document.robotSection.parts.${this.props.configId}.inclination.showGrid`);
        let showStartEndLabels = configService.get(`layout.document.robotSection.parts.${this.props.configId}.inclination.showStartEndLabels`);
        let adjustHeightDif = configService.get(`layout.document.robotSection.parts.${this.props.configId}.inclination.adjustHeightDif`);
        let source = configService.get(`layout.document.robotSection.parts.${this.props.configId}.source`);
        let lidarRollSnap = configService.get(`layout.document.robotSection.parts.${this.props.configId}.lidarRoll.snapToHeight`);

        this.setState({
            source: source,
            sonarZoom: sonarZoom, 
            lidarZoom: lidarZoom,
            showPuddles: showPuddles === true || showPuddles === "true", 
            adjustHeightDif: adjustHeightDif === true || adjustHeightDif === "true",
            seriesStyle: seriesStyle,
            sampleDistance: sampleDistance,
            showGrid: showGrid,
            showStartEndLabels: showStartEndLabels,
            lidarRollSnap: lidarRollSnap,
        });
            
    }

    /**
     * called when the robot data has been laoded from csv
     * selectedSource: set when called when switching source (after data is loaded).
     */
     handleDataLoaded = (selectedSource) => {
         const source = selectedSource ?? this.getSource();
         if (source === 'inclination') {             // only for inclination data, ohterwise we don't need to force an update
            if (this.state.sampleDistance !== 0.1) {            // when robot data hans been loaded (project loaded), reset sample distance
                this.setState({sampleDistance: 0.1});
            } else {
                this.forceUpdate();
            }
         }
         else if (source === "lidar-rollout") {
            this.setLidarConfig(this.getLidarProvider());
         }
    }
}

RobotSectionBody.propTypes = {
    configId: PropTypes.number.isRequired,                  // the id to use when searching the config. this way, each part has it's own config
    liveFeed: PropTypes.bool,
    readOnly: PropTypes.bool,                               // for viewer
    onSelectLayout: PropTypes.func,                         // called when the user selected a different data source to display
    selectedLayout: PropTypes.string,                       // currently selected layout
    //props for when the main-crawler toolbar is shown
    showMainCrawlerFunctions: PropTypes.bool,               // true for the body part that is at the top, shows the generic toolbar buttons
    showDashboardFunctions: PropTypes.bool,                 // when true, the functions for the dashboard on the left are also shown (for the bottom left part)
    dashboardOpen: PropTypes.bool,                          
    onToggleDashboard: PropTypes.func,                        // called when user wants to show the dashboard
    onFindTimeForDistance: PropTypes.func,                  // called when the user selects a distance which needs to be converted to time
    allowedHeight: PropTypes.number.isRequired,             // the height that the dashboard can use, determins the eventual size of the buttons
    direction: PropTypes.string,                            // allowed values: 
    onDirectionChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    changePosAllowed: PropTypes.bool,                        // when true, clicking on the track allows changing position. This is not allowed when the application is recording.
    verSplit: PropTypes.number,                             // needed for repaints
    initLoaded: PropTypes.bool.isRequired,                  // need to know when initial loading is done, so we can read the engine cofnig correctly
    
    
    onToggleVideoSection: PropTypes.func,                   // called to show/hide the video section
    showVideoSection: PropTypes.bool,                       // when true, the video section is shown, otherwise it is collapsed
    onOpenVideoScreen: PropTypes.func,                      // callback for the button to open new screen for video
    streng: PropTypes.object,                               // ref to the streng, so we refresh when streng has changed
    hasReverseTrackData: PropTypes.bool,                    // true when the current streng has reverse robot/track data

    resizeRequest: PropTypes.any,                           // called when the child (for sonarView) needs to recaculate it's size again (widh changed)
    controlState: PropTypes.string,                         // so that lidarRollout/trackview can see when a reverse tractor recording is happening
    measureData: PropTypes.any,                             // when lidar is available, we can perform measurements with that sensor, so we need to respond to measument requests
    onMeasured: PropTypes.func,                             // called when measurement is done on the lidar image
};

export default withTheme(withStyles(styles)(RobotSectionBody));