/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withTranslation } from 'react-i18next';
import { documentControlService } from '../../document_control_service';
import { distanceService } from '../../../services/distance_service';

const styles = (theme) => ({
    root: {
    },
   
});




class TractorResetDlg extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: distanceService.current
        }
    }


    render() {
        const { t } = this.props;
        return (
            <Dialog
                maxWidth="xs"
                onClose={this.handleCancel}
                aria-labelledby="reset-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="reset-dialog-title">{t("Tractor afstand")}</DialogTitle>
                <DialogContent>
                    {(!this.props.liveFeed) && 
                        <DialogContentText id="alert-dialog-description">
                            {t("Let op: dit veranderd enkel de positie in de video, er wordt geen data verstuurd naar een tractor.")}
                        </DialogContentText>
                    }
                    <TextField label={t("Nieuwe afstand:")}
                        type="number"
                        autoFocus
                        value={this.state.value}
                        onChange={this.handleChange}
                        
                        InputLabelProps={{shrink: true}}
                        onKeyDown={this.handleKeyboard}
                        InputProps={{endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                    onFocus: this.handleFocused}}
                    />
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={this.handleOk} color="primary">
                        {t("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleFocused = (ev) => {
        ev.target.select();                          // selec the whole text when focused
    }

    handleChange = (ev) => {
        this.setState({value: ev.target.value});
    }

    handleCancel = () => {
        this.props.onClose(null);
    }

    handleOk = () => {
        this.props.onClose(this.state.value);
    }

    handleKeyboard = (e) => {
        if (e.key == "Enter") {
            this.handleOk();
            e.preventDefault();
            e.stopPropagation();  
        }
    }

}

TractorResetDlg.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};

TractorResetDlg.defaultProps = {
    liveFeed: PropTypes.bool
}

export default withTranslation()(withStyles(styles)(TractorResetDlg));