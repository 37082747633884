import { configService } from "../../services/config_service";


export function say(text) {
    const speech = new SpeechSynthesisUtterance();
    const lang = configService.get("general.language");
    speech.lang = lang ?? 'en-US';
    speech.text = text;
    speech.volume = 1;
    speech.rate = 1;
    speech.pitch = 1;                

    window.speechSynthesis.speak(speech);
}