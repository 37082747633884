/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import '../../../App.css';
import { BaseOverlay } from '../../overlays/base_overlay_component';




class DeformationMeasure extends BaseOverlay {
    constructor(props) {
        super(props);

        Object.assign(this.state, {
            measuring: ''                              // the value currently being measured.
        });

        this.valueX = null;                             // value measured for X
    }

    
    buildSvgImage() {
        let size = this.ImageSize;
        const center = size / 2;
        const sectR = center/10;
        const sectRPart = sectR/2;
        const fullLineStyle = `stroke="white" stroke-width="1" fill="transparent" mix-blend-mode="exclusion"`;
        const selectedFullLineStyle = `stroke="yellow" stroke-width="1" fill="transparent" mix-blend-mode="exclusion"`;
        const dashLineStyle = `stroke="white" stroke-width="1" stroke-dasharray="10,10" fill="transparent" mix-blend-mode="exclusion"`;
        const textStyle = `font-size="20" fill="white" mix-blend-mode="exclusion"`;
        const selectedTextStyle = `font-size="20" fill="yellow" mix-blend-mode="exclusion"`;
        return (
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${size} ${size}">
            <defs>
                <clipPath id="clip">
                <circle cx="${center}" cy="${center}" r="${center}"/>
                </clipPath>
            </defs>
            <circle cx="${center}" cy="${center}" r="${sectR}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*2}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*3}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*4}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*5}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*6}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*7}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*8}" ${fullLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${(sectR)*9}" ${fullLineStyle}/>
            
            <circle cx="${center}" cy="${center}" r="${sectR-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*2)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*3)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*4)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*5)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*6)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*7)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*8)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*9)-sectRPart}" ${dashLineStyle}/>
            <circle cx="${center}" cy="${center}" r="${((sectR)*9)+sectRPart}" ${dashLineStyle}/>

            <g clip-path="url(#clip)">
                <line x1="0" x2="${size}" y1="0" y2="${size}" ${fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${size}" y2="0" ${fullLineStyle}/>
                <line x1="${center}" x2="${center}" y1="0" y2="${size}" ${this.state.measuring == 'x' ? selectedFullLineStyle : fullLineStyle}/>
                <line x1="0" x2="${size}" y1="${center}" y2="${center}" ${this.state.measuring == 'y' ? selectedFullLineStyle : fullLineStyle}/>
            </g>

            <text x="${center -18}" y="18" ${this.state.measuring == "x" ? selectedTextStyle : textStyle } > 
                <tspan>X</tspan>
            </text>
            <text x="${center + 4}" y="${size}" ${this.state.measuring == "x" ? selectedTextStyle : textStyle } > 
                <tspan>X</tspan>
            </text>

            <text x="${size - 18}" y="${center + 20}" ${this.state.measuring == "y" ? selectedTextStyle : textStyle } > 
                Y
            </text>
            <text x="${4}" y="${center - 4}" ${this.state.measuring == "y" ? selectedTextStyle : textStyle } > 
                Y
            </text>

            <circle cx="${center}" cy="${center}" r="${center}" stroke="white" stroke-width="1" fill="transparent" />
        </svg>`);
    }

    calculatePos(size, mid, pos) {
        
        if (pos < 0) {                                          // out of bounds
            pos = 0;
        }
        else if (pos > size) {
            pos = size;
        }
        if (pos > mid) {                                        // the center of the cirkel is 0, so we need to adjust the position to the middle point. 
            pos -= mid;
        }
        else {
            pos = mid - pos;
        }
        return pos;
    }

    /**
     * translates x and y in relation to the center of the circle and calculates the radius
     * @param {object} pos coordinate
     */
    calculateRadius(pos) {

        let size = this.state.size;
        if (!size) {
            size = this.props.height - 10;
        }
        const mid = size / 2;

        let x = this.calculatePos(size, mid, pos.x);
        let y = this.calculatePos(size, mid, pos.y);
        let radius = Math.sqrt((x*x) + (y*y));
        let result = (100 / mid) * radius;
        if (result > 100){                                          // if user clicked outside of circle, set to max
            result = 100;
        }
        return result;
    }

    //for calculation, see: https://nl.wikipedia.org/wiki/Hellingsgraad
    internalHandleMouseDownOnImage(ev) {

        if (this.state.mode == "measure") {
            if (ev.button == 0 && ev.buttons == 1) {
                let pos = this.getRelMousePos(ev);
                pos = this.relativeToCenterImage(pos);
                if (this.state.measuring === 'x') {
                    this.valueX = this.calculateRadius(pos);             // warning: the formule switches x and y compared to screen coordinates
                    this.setState({measuring: 'y'});
                }
                else {
                    let valueY = this.calculateRadius(pos);              // warning: the formule switches x and y compared to screen coordinates
                    if (this.props.onMeasured) {
                        const divBy = this.valueX + valueY;
                        let result;
                        if (divBy != 0) {
                            result = (Math.abs(this.valueX - valueY) / divBy) * 100;
                        }
                        else {
                            result = 0;
                        }
                        this.props.onMeasured(result);
                    }
                }
            }
        }
        else {
            super.internalHandleMouseDownOnImage(ev);
        }
    }
   
    internalCloseLayout() {
        super.internalCloseLayout();
        this.setState({measuring: 'x'});
    }

}

DeformationMeasure.propTypes = {
    
};

export default DeformationMeasure;