/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ShieldAccount from 'mdi-material-ui/ShieldAccount';
import AccountPlus from 'mdi-material-ui/AccountPlus';
import AccountStarOutline from 'mdi-material-ui/AccountStarOutline';

import React from 'react';
import '../../App.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../dialogs/confirmation_dialog';
import MaterialTable, { MTableToolbar } from "material-table";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { userService } from '../services/user_service';
import { tableIcons } from '../services/table_service';
import { dialogService } from '../../services/dialog_service';
import { errExtractor } from '../services/error_extractor';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    title: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: "700"
    },

    regBtn: {
        marginTop: theme.spacing(1)
    }
});


class CompanySettings extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            company: null,
            isAskToDeleteOpen: false,
            allowProFeatures: false,
            nrLicensesUsed: 0,
        }
    }

    async componentDidMount() {
        let users = await userService.getUsers();                     // get all users for this company.
        users = users.filter((usr) => usr.email);                       // only show users with an email (so no inpsector licenses)
        let company = null;
        try {
            company = await userService.getCompany();
        }
        catch (error) {
            console.log(`non fatal error: ${errExtractor.get(error)}`);
        }
        let nrLicensesUsed = (!company) ? 0 : users.reduce((prev, current) => {
            if (current.proAccount) {
                return prev + 1;
            }
            return prev;
        }, 0);
        if (company && nrLicensesUsed > company.nrProLicenses) {
            const { t } = this.props;
            let tooMany = nrLicensesUsed - company.nrProLicenses;
            dialogService.error(t("Users"), t("too_many_pro_users", {removed: tooMany}));
            nrLicensesUsed = company.nrProLicenses;
            for (const user of users) {
                if (user.proAccount) {
                    user.proAccount = false;
                    await this._saveUser(user, user.id);
                    tooMany--
                    if (tooMany === 0) {
                        break;
                    }
                }
            }
        }
        this.setState({ users: users, company: company, allowProFeatures: company.nrProLicenses > 0, nrLicensesUsed: nrLicensesUsed });
    }


    render() {
        const { t } = this.props;
        if (!this.props.isCurrentTab) {
            return null;
        }
        const company = this.state.company;
        const companyName = company ? company.name : 'unknown';
        let actions = [
            {
                icon: () => <DeleteOutline color="action" />,
                tooltip: t('Remove user'),
                onClick: (event, data) => this.askDelete(data)
            }
        ];
        const badgeEdit = this.state.allowProFeatures ? 'onUpdate' : 'never';
        let columns = [
            {
                title: '', render: this.renderAdminBadge, width: 40,
                editable: badgeEdit, type: 'boolean', field: 'proAccount',
                editComponent: props => (
                    <Tooltip title="Pro account">
                        <Checkbox
                            checked={props.value}
                            disabled={this.state.nrLicensesUsed >= company?.nrProLicenses && props.value === false}
                            onChange={e => props.onChange(e.target.checked)}
                        />
                    </Tooltip>
                )
            },
            { title: t('Email address'), field: 'email', editable: 'onAdd' },
            { title: t('First name'), field: 'firstName', editable: 'onUpdate' },
            { title: t('Last name'), field: 'lastName', editable: 'onUpdate' },
        ]
        if (this.state.allowProFeatures) {
            columns.push(
                {
                    title: t('Region'), field: 'region',
                    editable: (_, rowData) => rowData && rowData.proAccount === true
                }
            );
        }

        return (
            <div className={this.props.classes.root}>
                <MaterialTable
                    title={companyName}
                    icons={tableIcons}
                    columns={columns}
                    data={this.state.users}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: t('Actions')
                        },
                        body: {
                            emptyDataSourceMessage: 'No users',
                            filterRow: {
                                filterTooltip: t('Filter')
                            }
                        }
                    }}
                    options={{
                        search: true,
                        actionsColumnIndex: -1,
                        selection: true,
                        showTextRowsSelected: false,
                    }}
                    components={{
                        Toolbar: this.buildToolbar,
                    }}
                    actions={actions}
                    editable={{
                        onRowAdd: this.addUser,
                        onRowUpdate: this.updateUser,
                    }}
                />
                <ConfirmationDialog title={t("Delete")}
                    question={t("ask_to_remove_user", { company: companyName })}
                    open={this.state.isAskToDeleteOpen}
                    onClose={this.handleDeleteDlgClose}
                />
            </div>
        );
    }

    buildToolbar = (props) => {
        const { t } = this.props;
        if (this.state.allowProFeatures) {
            const company = this.state.company;

            return (
                <div>
                    <MTableToolbar {...props} />
                    <div style={{ paddingLeft: '24px', marginTop: '-8px' }}>
                        {t("nr_pro_viewer_licenses_left_of_total", { total: company.nrProLicenses, available: company.nrProLicenses - this.state.nrLicensesUsed})}
                    </div>
                </div>
            );
        }
        else {
            return (<MTableToolbar {...props} />);
        }
    }

    renderAdminBadge = (rowData) => {
        const { t } = this.props;
        if (!rowData) {
            return null;
        }
        if (rowData.companyRole === "companyAdmin") {
            return (
                <Tooltip title={t("This is the administrator account")}>
                    <ShieldAccount style={{ verticalAlign: 'middle' }} />
                </Tooltip>
            );
        }
        else if (rowData.invitedTo) {
            return (
                <Tooltip title={t("This user has not yet accepted his invitation")}>
                    <AccountPlus style={{ verticalAlign: 'middle' }} />
                </Tooltip>
            );
        }
        else if (rowData.proAccount === true) {
            return (
                <Tooltip title={t("This user has not yet accepted his invitation")}>
                    <AccountStarOutline style={{ verticalAlign: 'middle' }} />
                </Tooltip>
            );
        }
    }

    /**
     * ask the user if he wants to delete all the selected items.
     * @param {list} data list of selected report objects.
     */
    askDelete = (data) => {
        this.dataToDelete = data;
        this.setState({ isAskToDeleteOpen: true });
    }

    addUser = async (newUser) => {
        const { t } = this.props;
        try {
            const curUser = userService.getCurrentUser();
            if (!curUser) { throw new Error("missing user") }
            let users = [...this.state.users];
            if (users.find((el) => el.email === newUser.email)) {
                throw new Error(t("user_already_part_of_comp"))
            }
            let data = { ...newUser };
            delete data.status;                                                 // not needed for update
            const result = await userService.tryAddUserToCompany(data);
            data['invitedTo'] = this.state.company?.id;                         // so we indicate that the user is invited
            if (result.result === "existing") {
                dialogService.error(t("New user"), t("user_already_member_of_other"));
            }
            else {
                users.push(result.value);
                this.setState({ users: users });
                if (result.result === "invited") {
                    dialogService.info(t("New user"), t("user_invited"));
                }
                else {
                    dialogService.info(t("New user"), t("user_created"));
                }
            }
        }
        catch (error) {
            dialogService.error(t("New user"), t("error_creating_user", { error: errExtractor.get(error) }));
        }
    }

    updateUser = async (newData, oldData) => {
        const { t } = this.props;
        try {
            let data = { ...newData };
            await this._saveUser(data);
            const idx = this.state.users.indexOf(oldData);
            const users = [...this.state.users];
            users[idx] = newData;
            let nrLicensesUsed = this.state.nrLicensesUsed;
            if (newData.proAccount !== oldData.proAccount) {
                if (newData.proAccount) {
                    nrLicensesUsed++;
                }
                else {
                    nrLicensesUsed--;
                }
            }
            this.setState({ users: users, nrLicensesUsed: nrLicensesUsed });
        }
        catch (error) {
            dialogService.info(t("New user"), t("error_updating_user", errExtractor.get(error)));
        }
    }

    async _saveUser(data) {
        if (!data.proAccount && data.region) {                              // only pro accounts can have a region
            data.region = "";
        }
        delete data.status;                                                 // not needed for update
        delete data.password;                                               // dont want to update this here, can be null, can't have that
        await userService.saveUser(data, data.id);
    }

    handleDeleteDlgClose = async (result) => {
        const { t } = this.props;
        this.setState({ isAskToDeleteOpen: false });
        if (result && this.dataToDelete) {
            let users = [...this.state.users];                          // copy so we can update the ui
            for (const user of this.dataToDelete) {
                if (user.companyRole === 'companyAdmin' || user.role === 'admin') {
                    dialogService.error(t("Delete user"), t("cant_delete_admin"));
                }
                else if (user.role === 'inspector') {
                    dialogService.error(t("Delete user"), t("cant_delete_inspector"));
                }
                else {
                    try {
                        await userService.deleteUser(user.id);
                        const idx = users.indexOf(user);
                        if (idx > -1) {
                            users.splice(idx, 1);
                        }
                    }
                    catch (error) {
                        dialogService.error(t("Delete user"), errExtractor.get(error));
                    }
                }
            }
            this.setState({ users: users });
        }
        this.dataToDelete = null;
    }
}

CompanySettings.propTypes = {
    isCurrentTab: PropTypes.bool.isRequired
};

export default withTranslation()(withStyles(styles)(withRouter(CompanySettings)));