/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { RobotPlayer } from '../../../../components/document/robot/robot_player_service';
import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { TrackBaseService } from './track_base_service';
import { dialogService } from '../../../../services/dialog_service';

export const MIN_HOR_DIST_PER_REC = 0.10;                                                    // x nr of m min distance to travel before generating a new data point

/**
 *provides methods for recording/managing/working with track data
 note: recording is done from engine process
 is exported so that data can be loaded independenly from a project (reports)
 */
export class TrackServerService extends TrackBaseService  {
    constructor() {
        super();
        this.tempDataHorDist = 0;                                                   // sum of traveled horizontally, for this tick, so we know when to flush data
        this.horDist = 0;                                                           // total flattened out horizontal distance since recording started
        this.deltaDistance = 0;                                                     // sum of distance traveled by the tractor for this click
        this.verDist = 0;                                                           // total sum of traveled vertically
        this.prevDistance = 0;                                                      // previously received distance value, so we can calculate the distance traveled between last update (so we can calculate the triangle of the last traveled distance, not the whole distance)
        this.tempDataTimestamp = null;                                              // first timestamp, we use this to track if there is still buffered data when recording stops
        this.recording = false;                                                     // only need to remember when we need to update the ui while recording
    }


    /**
     * start recording track data.
     */
    startRecord(streng) {
        try{
            //note: don't reset the data upon record start, that would force some components to loose ref to the data list. instead, a reset needed to be done first.
            this.data.length = 0;                                                      // remove any previous data in the buffer, this might cause fuckups in the render
            this.verDist = 0;                                                           // reset temp buffers when record starts
            this.tempDataHorDist = 0;
            this.horDist = 0;
            this.deltaDistance = 0;
            this.prevDistance = 0;
            this.tempDataTimestamp = null;
            this.currentTime = 0;
            this.recording = true;
            
        }
        catch(error) {
            this.fp = null;                                                 // something went wrong, don't try to write to file
            dialogService.error(i18n.t("Track"), i18n.t("rec_error", {error: errExtractor.get(error)}));
        }
    }

    stopRecord() {                                             
        if(this.recording) {                                           // note: can be called when not recording, so make certain that is handled ok.
            if (this.tempDataTimestamp !== null) {              // still temp data buffered, flush this.
                this.flushTempData();
            }
            this.recording = false;
        }
    }

    /**
     * called when the document is put in edit mode (upon create). Make certain
     * that all data is re-init.
     */
    reset() {
        super.reset();
        this.tempDataHorDist = 0;
        this.verDist = 0; 
        this.horDist = 0;
        this.deltaDistance = 0;
        this.prevDistance = 0;
        this.tempDataTimestamp = null;
    }


    /**
     * called when the robot has recorded new data element.
     * calculate the vertical distance traveled, based on the inclination and horizontal distance traveled.
     * @param {object} value the recorded robot data, with a timestamp.
     */
    handleRobotData(value) {
        if (value) {
            let trigData = this.calculateTrigData(value, this.prevDistance);
            if (trigData) {
                if (this.recording) {                           // don't try to do a calculation if not recording or there was no distance traveled, but don't skip the first record. This helps preserve resources when the robot is standing still, but record is still running
                    if (this.tempDataTimestamp == null){
                        this.tempDataTimestamp = this.currentTime;
                    }
                    this.deltaDistance += trigData.deltaDistance;
                    this.tempDataHorDist += trigData.distance;
                    this.horDist += trigData.distance;
                    this.verDist += trigData.height;
                    this.raw.push({...trigData, timestamp: this.currentTime, tractorDistance: value.distance, flatHorDist: this.horDist, verDist: this.verDist});
                    if (this.tempDataHorDist >= MIN_HOR_DIST_PER_REC) {
                        this.flushTempData();
                    }
                    this.prevDistance = value.distance;
                }
            }
        }
    }

    flushTempData() {
        const record = {timestamp: this.currentTime, height: this.verDist, distance: this.horDist};
        this.data.push(record);                                                         // store it in the list so the view can rebuild the entire thing if required
        if (this.onUpdateData) {
            this.onUpdateData(record);
        }
        this.tempDataTimestamp = null;
        this.tempDataHorDist = 0;
        this.deltaDistance = 0;
    }
}

export const trackService = new TrackServerService();