import { DownloadBaseService } from "../../services/download_base_service";

export function downloadUrl(url, filename) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.target = '_blank';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);        //this is the 'a' object
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
}

export function downloadResource(url, filename) {
    // If no filename is set, use filename from URL
    if (!filename) filename = url.match(/\/([^/#?]+)[^/]*$/)[1];

    fetch(url, {
        headers: new Headers({
            Origin: window.location.origin,
        }),
        mode: 'cors',
    })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            downloadUrl(url, filename);
        })
        .catch(e => console.error(e));
}



class DownloadService extends DownloadBaseService {

    /**
     * web version to save the svg image to a file
     * @param {string} value the value to download to a file
     * @param {string} filename proposed name of the file
     */
    downloadSvgAsUrl(value, filename) {
        // If no filename is set, use filename from URL
        if (!filename) filename = url.match(/\/([^/#?]+)[^/]*$/)[1];
    
        const blob = new Blob([value], { type: "image/svg+xml" });
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, filename);
    }
}

export const downloadService = new DownloadService();