import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from '../shared_components/translations/en';
import nl from '../shared_components/translations/nl';
import { configService } from "./config_service";

const isDev = process.env.REACT_APP_DEBUG;

export const options =
  [ 
    { value: 'en', label: 'English' },
    { value: 'nl', label: 'Dutch' }
 ];



i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en,
            nl
        },
        lng: configService.get('general.language'),
        fallbackLng: "en",
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true,
        },
        keySeparator: false,
        nsSeparator: false,
        debug: isDev
    });

export default i18n;    

export const LanguageOptions = [ 
    { value: 'en', label: 'English' },
    { value: 'nl', label: 'Nederlands' },
    { value: 'it', label: 'Italiana' }  
];

export function getSelectedLanguage() {
    let lng = configService.get("general.language");
    if (!lng)  {
        lng = "en";
    }
    return lng;
}