/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';


const styles = (theme) => ({

});


/**
 * similar to BaseMeasure, but instead of drawing lines, an svg is drawn that can be scaled. 
 */
class Paywall extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {

    }
    render() {
        const boxStyle = {
            margin: 'auto',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            position: 'sticky',
            background: "rgba(180, 180, 180, 0.96)",
            overflow: "hidden",
            zIndex: 100,
            height: "100%",
            display: 'flex'
        }
        if (this.props.needsMargin === false) {
            delete boxStyle.margin;
        }
        if (this.props.marginTop) {
            boxStyle['marginTop'] = `${this.props.marginTop}px`;
        }
        return (
            <React.Fragment>
                <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
                <Box
                    style={boxStyle}
                    width={this.props.width}
                    height={this.props.height}
                >
                    {/* the next div needs margin-bottom=-10px to make certain that the list at the bottom is also covered (dashboard) */}
                    <div style={{flex: 1, display: "flex", flexDirection: 'column', justifyContent: 'center', marginBottom: '-10px', background: 'transparent'}}>  
                        <div>Enkel beschikbaar voor pro accounts</div> 
                        <div>Activeer pro-account</div>
                    </div>
                </Box>
            </React.Fragment>
        );
    }

    handleResize = (width, height) => {
        this.setState({ width: width, height: height });
    }

}

Paywall.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    marginTop: PropTypes.number,
};

export default withTranslation()(withStyles(styles)(Paywall));