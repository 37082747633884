export let API_URL = process.env.REACT_APP_DEBUG ? "http://localhost:8080" : process.env.REACT_APP_IS_STAGING ? "https://apistaging-dot-camera-inspector.appspot.com" : "https://api-dot-camera-inspector.appspot.com";

//export const API_URL = "https://api-dot-camera-inspector.appspot.com";
export let GOOGLE_API_KEY = "AIzaSyDhVr0PFQ5yBzV6yb_GxYZae95aBO55R88";

export function setServerConfig(api, google) {
    console.log("using api: ", api, ", google key: ", google);
    GOOGLE_API_KEY = google;
    API_URL = api;
}

export const API_SERVICES = [
    {label: "ITDV", url: "https://api-dot-camera-inspector.appspot.com", googleKey: "AIzaSyDhVr0PFQ5yBzV6yb_GxYZae95aBO55R88"},
    {label: "demo", url: "https://api-dot-cami-global.ew.r.appspot.com", googleKey: "AIzaSyDhVr0PFQ5yBzV6yb_GxYZae95aBO55R88"}
]