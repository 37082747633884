/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { documentControlService } from '../document/document_control_service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { dialogService } from '../../services/dialog_service';
import { errExtractor } from '../services/error_extractor';

const styles = (theme) => ({
    docRoot: {
        flex: 1,
        height: '30px'
    },
    dialogPaper: { height: 'calc(100% - 64px);' }
});

class CalibrateAcceleroDialog extends React.Component {
    constructor(props) {
        super(props);
        this.startTxt = props.t("calibrate_accelero_start");

        this.state = {
            sampling: false,
            samplingDone: false,
        }
        this.samples = [];
    } 

    componentDidMount() {
        documentControlService.events.on('onRobotData', this.handleRobotData);      // we need to read the data for a short while so we also account for jitter
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onRobotData', this.handleRobotData); 
    }


    render() {
        const { t } = this.props;
        if (!this.props.open) {
            return null;
        }
        let bodyTxt;
        if (!this.state.sampling && !this.state.samplingDone) {
            bodyTxt = this.startTxt;
        }
        else if (this.state.sampling) {
            bodyTxt = t("Please wait while sampling...");
        }
        else {
            bodyTxt = t("calibrate_done");
        }
        const closeTxt = this.state.samplingDone ? t("Sluiten") : t("Cancel");
        return (
            <Dialog
                open={this.props.open}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("Calibrate the accelerometer")}</DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {(this.state.sampling) &&
                            <CircularProgress />
                        }
                        {bodyTxt}
                    </DialogContentText>
                </DialogContent>

                
                <DialogActions>
                    <Button autoFocus onClick={this.handleClose} disabled={this.state.sampling}>
                        {closeTxt}
                    </Button>
                    <Button autoFocus onClick={this.handleCalibrate} color="primary" autoFocus disabled={this.state.sampling}>
                        {t("Calibreer")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose = (event) => {
        if (this.props.onClose) this.props.onClose(null);
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();
    }

    handleCalibrate = (event) => {
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();

        if (!this.state.sampling) {
            this.setState({sampling: true});
            setTimeout(this.handleCalibrationDone, 3000);
        }
    }

    handleCalibrationDone = () => {                                                      // let the system sample for a couple of seconds.
        const { t } = this.props;
        try {
            if (this.samples.length === 0) {
                throw new Error("no samples found, couldn't calibrate");
            }
            if (this.props.onChanged) {
                const minX = Math.min(...this.samples.map(el => el.aX));
                const maxX = Math.max(...this.samples.map(el => el.aX));
                const minY = Math.min(...this.samples.map(el => el.aY));
                const maxY = Math.max(...this.samples.map(el => el.aY));
                const minZ = Math.min(...this.samples.map(el => el.aZ));
                const maxZ = Math.max(...this.samples.map(el => el.aZ));
                this.props.onChanged({minX, maxX, minY, maxY, minZ, maxZ});
            }
            this.samples = [];                                                      // in case it is done again
            this.setState({samplingDone: true, sampling: false});
        }
        catch(error) {
            dialogService.error(t("Calibreer"), errExtractor.get(error));
        }
    }

    /**
     * when calibrating, we take a nr of samples from the robot
     * @param {object} data data values from robot
     */
    handleRobotData = (data) => {
        if (this.state.sampling) {
            this.samples.push({aX: data.aX, aY: data.aY, aZ: data.aZ});
        }
    }
}

CalibrateAcceleroDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onChanged: PropTypes.func.isRequired,                                           // called when the calibration value needs to be saved.
};

export default withTranslation()(withStyles(styles)(CalibrateAcceleroDialog));