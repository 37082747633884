/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import Link from 'mdi-material-ui/Link';
import LinkOff from 'mdi-material-ui/LinkOff';
import FormatHorizontalAlignCenter from 'mdi-material-ui/FormatHorizontalAlignCenter';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { remoteControlService, REMOTE_ACTIONS, DRIVE_STATE } from '../../../services/remote_control_service';


const styles = (theme) => ({
    root: {
        paddingBottom: theme.spacing(1),
        display: 'grid',
        gridTemplateColumns: "1fr 1fr",             // 3 cols of equal size
        gridTemplateRows: "16px auto",
        justifyItems: "stretch",
        gridTemplateAreas: `
                            'link link'
                            'slider1 slider2'
        `
    },
    green: {
        color: 'green'
    },
    orange: {
        color: 'orange'
    },
    red: {
        color: 'red'
    }

});


/**
 */
class SpeedController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            linkType: 'single',                 // single, linked, unlinked
            speed: remoteControlService.speed,
            speed2: remoteControlService.speedReel,
            drivingState: remoteControlService.drivingState,      // the direction (forward, back), so we can adjust slider.disable according to the direction for single line
        }
        this.linkTypeTip = props.t("selectSpeedControl");
        this.slider1Tip = props.t("Robot speed");
        this.slider2Tip = props.t("Cable speed");

        this.speedUpdateDelay = null;                           // to prevent the system from flooding too many speed updates (when the user continously scrolls)
        this.speedUpdateDelay2 = null;
        this.nextSpeed = null;
        this.nextSpeed2 = null;
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
    }


    render() {
        const { classes } = this.props;
        const linkIcon = this.getLinkIcon();
        const colorClass1 = this.getColorClass(this.state.speed);
        const colorClass2 = this.getColorClass(this.state.speed2);
        return (
            <div className={classes.root}>

                {(!this.props.linkMode || this.props.linkMode === 'dual') &&
                    <div style={{gridArea: 'link', alignSelf: 'center', display: "flex", justifyContent: "center"}}>
                        <Tooltip title={this.linkTypeTip}>
                                <IconButton 
                                    size='small'
                                    onClick={this.toggleLinked}
                                    aria-label="link">
                                        {linkIcon}
                                </IconButton>
                            </Tooltip>
                    </div>
                }

                <div style={{gridArea: 'slider1'}}>
                    <Tooltip title={this.slider1Tip} placement="top">
                        <div style={{height: "100%", width: '100%'}}>
                            <Slider value={this.state.speed} 
                                onChange={this.handleSpeed} 
                                orientation="vertical"
                                aria-labelledby="position" 
                                classes={{root: colorClass1}}
                                min={0}
                                max={255}
                                disabled={(this.props.linkMode === 'dual' && this.state.linkType === "single" && this.state.drivingState === DRIVE_STATE.BACK) || this.props.disabled}
                            />
                        </div>
                    </Tooltip>
                </div>

                <div style={{gridArea: 'slider2'}}>
                    <Tooltip title={this.slider2Tip} placement="top">
                        <div style={{height: "100%", width: '100%'}}>
                            <Slider value={this.state.speed2} 
                                onChange={this.handleSpeed2} 
                                orientation="vertical"
                                aria-labelledby="position" 
                                classes={{root: colorClass2}}
                                min={0}
                                max={255} 
                                disabled={(this.props.linkMode === 'dual' && this.state.linkType === "single" && this.state.drivingState !== DRIVE_STATE.BACK) || this.props.disabled2}
                            />
                        </div>
                    </Tooltip>
                </div>
                
            </div>
        );
    }


    getColorClass(value) {
        if (value > 200) {
            return this.props.classes.red;
        }
        else if (value > 150) {
            return this.props.classes.orange;
        }
        else if (value > 100) {
            return this.props.classes.green;
        }
        return null;
    }

    getLinkIcon() {
        switch (this.state.linkType) {
            case 'single':
                return <FormatHorizontalAlignCenter style={{height: '14px', width: '14px'}}/>                
            case 'unlinked':
                return <LinkOff style={{height: '14px', width: '14px'}}/>
            case 'linked':
                return <Link style={{height: '14px', width: '14px'}}/>        
            default:
                return null;
        }
    }

    toggleLinked = () => {
        let newValue;
        switch (this.state.linkType) {
            case 'single':
                newValue = 'unlinked';
                break;
            case 'unlinked':
                newValue = 'linked';
                break;
            case 'linked':
                newValue = 'single';
                break;
            default:
                return null;
        }
        this.setState({linkType: newValue});
        remoteControlService.setSpeedLinkType(newValue);
    }

    handleSpeed = (event, value) => {
        if (!this.speedUpdateDelay) {                                   // make certain we don't flood the system with continuous messages
            remoteControlService.setSpeed(value);
            this.speedUpdateDelay = setTimeout(() => {
                if (this.nextSpeed) {
                    remoteControlService.setSpeed(this.nextSpeed);
                    this.nextSpeed = null;
                }
                this.speedUpdateDelay = null;
            }, 150);
        }
        else {
            this.nextSpeed = value;
        }
    }

    handleSpeed2 = (event, value) => {
        if (!this.speedUpdateDelay2) {                                   // make certain we don't flood the system with continuous messages
            
            if (this.state.linkType === 'linked') {
                remoteControlService.setSpeed(value);
            }
            else {
                remoteControlService.setSpeed2(value);
            }

            this.speedUpdateDelay2 = setTimeout(() => {
                if (this.nextSpeed2) {
                    if (this.state.linkType === 'linked') {
                        remoteControlService.setSpeed(this.nextSpeed2);
                    }
                    else {
                        remoteControlService.setSpeed2(this.nextSpeed2);
                    }
                    this.nextSpeed2 = null;
                }
                this.speedUpdateDelay2 = null;
            }, 150);
        }
        else {
            this.nextSpeed2 = value;
        }
    }

    handleRemoteUpdate = (action, value) => {
        switch (action) {
            case REMOTE_ACTIONS.SPEED:
                this.setState({speed: value});
                break;
            case REMOTE_ACTIONS.SPEED_REEL:
                this.setState({speed2: value});
                break;
            default:
                break;
        }
        if (this.state.drivingState !== remoteControlService.drivingState) {
            this.setState({drivingState: remoteControlService.drivingState});
        }
    }

}

SpeedController.propTypes = {                
    disabled: PropTypes.bool,                                 // object of type {current: null} -> to set the callback for storing a new value fast without rerender
    disabled2: PropTypes.bool,                                   // calback for when value has been changed by user.
    linkMode: PropTypes.string,
};


export default withTranslation()(withStyles(styles)(SpeedController));