import { API_URL } from './server_config_service';
import { getHeaders } from './fetch_services';
import { userService } from './user_service';
import i18n from "i18next";

class AuthenticationService  {


    constructor() {
        this.apiOk = false;                                     // keeps track if we managed to reach an api, this is the first class that tries to reach the server. used to track if server is up.
    }

    

    pingApi(){
        return new Promise((resolve, reject) => {
            fetch(`${API_URL}/ping`)
                .then(() => resolve(true))
                .catch(() => reject())
            ;
        });
    }

    login(email, password){
        return new Promise( (resolve, reject) =>{
            let body = { email: email, password: password };
            let url = `${API_URL}/token`;
            fetch(url, {method: 'POST', mode: 'cors', body: JSON.stringify(body), headers: getHeaders(false)})
            .then((result) => { 
                if (result.ok === true) {
                    result.json().then((newUser) =>{
                        this.apiOk = true;
                        if (result.status === 200) {
                            // login successful if there's a jwt token in the response
                            if (newUser && newUser.token) {
                                userService.setCurrentUser(newUser);
                                resolve(newUser);
                            }
                            else{ reject(i18n.t("invalid response from server")); }
                        }
                        else { reject(newUser); }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }


    async loginApp(licenseId, accessToken) {

        let body = { licenseId: licenseId, accessToken: accessToken };
        const result = await fetch(`${API_URL}/applogin`, {method: 'POST', mode: 'cors', body: JSON.stringify(body), headers: getHeaders(false)})
        if (result.status === 200) {
            const newUser = await result.json();
            if (newUser) {
                userService.setCurrentUser(newUser);
                this.apiOk = true;
            }
            return newUser;
        }
        throw new Error(i18n.t("invalid response from server"));
    }

    registerApp(license, installationKey, force){
        return new Promise( (resolve, reject) =>{
            let body = { accessToken: license.token, installationKey: installationKey, licenseId: license.licenseId };
            let url = `${API_URL}/apptoken`;
            let method = force ? 'PUT' : 'POST';
            fetch(url, {method: method, mode: 'cors', body: JSON.stringify(body), headers: getHeaders(false)})
            .then((result) => { 
                if (result.ok === true) {
                    result.json().then((newUser) =>{
                        this.apiOk = true;
                        if (result.status === 200) {
                            // login successful if there's a jwt token in the response
                            if (newUser && newUser.token) {
                                // installationKey is saved together with the user cause that's where we get it from the cloud.
                                userService.setCurrentUser(newUser);
                                resolve(newUser);
                            }
                            else{ reject(i18n.t("invalid response from server")); }
                        }
                        else { reject(newUser); }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    signUp(email, password, firstname, lastname) {
        return new Promise( (resolve, reject) =>{
            let body = { firstName: firstname, lastName: lastname, password: password, email: email };
            let url = `${API_URL}/api/register`;
            fetch(url, {method: 'POST', mode: 'cors', body: JSON.stringify(body), headers: getHeaders(false)})
            .then((result) => {
                if (result.ok === true) {
                    result.json().then((newUser) =>{
                        this.apiOk = true;
                        if (result.status === 200) {
                            // login successful if there's a jwt token in the response
                            if (newUser && newUser.token) {
                                userService.setCurrentUser(newUser);
                                resolve(newUser);
                            }
                            else{ reject(i18n.t("invalid response from server")); }
                        }
                        else { reject(newUser); }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    requestPwdReset(email) {
        return new Promise( (resolve, reject) =>{
            let body = { email: email };
            let url = `${API_URL}/users/pwd`;
            fetch(url, {method: 'DELETE', mode: 'cors', body: JSON.stringify(body), headers: getHeaders(false)})
            .then((result) => { 
                if (result.ok === true) {
                    result.json().then((emailSent) =>{
                        if (result.status === 200) {
                            resolve(emailSent);
                        }
                        else { reject(false); }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    }


    async logout() {
        // Expire session
        // const myUser = userService.getCurrentUser();
        //if (myUser) {
        //    await fetch(`${API_URL}/api/Logout`);
       // }
        // remove user from local storage to log user out
        userService.clearCurrentUser();
    }

    async getGoogleCredentials() {
        return new Promise((resolve, reject) => {
            fetch(`${API_URL}/auth/google`, {method: 'GET', mode: 'cors', headers: getHeaders()})
            .then((result) => {
                if (result.ok === true) {
                    result.json().then((data) =>{
                        if (result.status === 200) {
                            resolve(data);
                        }
                        else {
                            //todo: add error message
                            reject(i18n.t("invalid response from server"));
                        }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => reject(error));
        });
    }

    async getApiKey() {

        const result = await fetch(`${API_URL}/auth/apikey`, {method: 'GET', mode: 'cors', headers: getHeaders(true)})
        if (result.status === 200) {
            const result = await result.json();
            return result;
        }
        else {
            const result = await result.json();
            throw new Error(result);
        }
    }
}

export const authService = new AuthenticationService();