/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import Menu from 'mdi-material-ui/Menu'; 
import PageLayoutHeader from 'mdi-material-ui/PageLayoutHeader'; 
import PageLayoutBody from 'mdi-material-ui/PageLayoutBody'; 
import StrengDetailsIcon from 'mdi-material-ui/FormatListText';
import EyePlus from 'mdi-material-ui/EyePlus';
import PlusThick from 'mdi-material-ui/PlusThick';
import ViewList from 'mdi-material-ui/ViewList';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MainInfoDialog from './main_info_dialog';
import StrengsSummaryDialog from '../inspection/strengs/strengs_summary_dialog';
import { activeProjectService } from '../../../services/active_project_service';
import StrengInfoDialog from './strengs/streng_info_dialog';
import { projectService } from '../../services/project_service';
import { documentControlService } from '../document_control_service';
import { dialogService } from '../../../services/dialog_service';
import ObservationsDialog from './observations_dialog';
import { configService } from '../../../services/config_service';


const styles = (theme) => ({
    root: {
        color: 'white',
        gridArea: 'inspBar',
        //zIndex: 99999999999,
        display: 'flex',
        flexDirection: 'column'
    },
    toolbar: { 
        //paddingLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
    },
    toolbarBtnTop: {
        borderRadius: '4px',
    },
    toolbarBtn: {
        backgroundColor: theme.palette.action.focus,
        marginLeft: '0px',
        borderRadius: '4px',
        justifyContent: "center",
        height: "100%,",
        '&:disabled': {
            color: 'rgba(255, 255,255, 0.26)',
            backgroundColor: theme.palette.action.focus,
        },
    },
    toolbarBtnBottom: {
        borderRadius: '4px',
        marginLeft: '0px',
        backgroundColor: theme.palette.action.focus,
        '&:disabled': {
            color: 'rgba(255, 255,255, 0.26)',
            backgroundColor: theme.palette.action.focus,
        },
    },
});


/**
 * all inspection data, but represented in a vertial bar with expandable elements.
 * used for tablet mode. This is an altermative for Inspection
 */
class InspectionBar extends React.PureComponent {
    constructor(props) {
        super(props);

        const prj = activeProjectService.data;
        this.onlyAllowStrengsOnFullMain = configService.get('strengs.onlyAllowStrengsOnFullMain') && props.readOnly === false;  // viewer can always continue to the next section. All read-only is allowed to continue
        this.onlyAllowObsOnFullStreng = configService.get('observations.onlyAllowObsOnFulStreng') && props.readOnly === false;
        this.onlyNewObservationDuringRec = configService.get('observations.onlyNewObservationDuringRec');
        const showMainInfo = this.onlyAllowStrengsOnFullMain && prj && !projectService.isMainComplete(prj.main);
        const showStrengInfo = !showMainInfo && this.onlyAllowObsOnFullStreng && props.activeStreng && !projectService.isStrengComplete(props.activeStreng);
        this.state = {
            showMainInfo: showMainInfo,                        // when true, the main info of the project is shown for editing
            showMainInfoError: false,                   // when true, errors are shown on main info
            showStrengSummary: false,                   // when true, strengs summary is shown
            showStrengInfo: showStrengInfo,             // when true, streng info dialog is open
            showStrengInfoError: false,                 // when true, errors are shown on streng popup
            selectedObs: null,                          // the observation to show the details for
            addNewObs: false,                           // when true, the observations editor dialog will add a new record upon opening
            showObsList: false,                         // when true, the list of logged obersvations is shown
            gotoAtEndMeasure: null,                     // when a measurement is done, we need to go back to the observation
        }

        this.obsDlgRef =  React.createRef();

    }

  

    render() {
        const { t, classes, minimalControls } = this.props;
        const prj = activeProjectService.data;
        return (
            <div className={classes.root} onClick={this.handleRootClick}>
                <Fade in={minimalControls} timeout={500}>
                    <Tooltip title={t("Toon project details")}>
                        <IconButton edge="start"
                            
                            aria-label="back"
                            color="inherit"
                            className={classes.toolbarBtn}
                            onClick={this.props.onToggleMinimalControls }
                        >
                            <Menu fontSize="large"/>
                        </IconButton>
                    </Tooltip>
                </Fade>
                {this.renderObsIcons()}
                <Fade in={!minimalControls} timeout={500} mountOnEnter={true} unmountOnExit={true}>
                    <div className={classes.toolbar}>
                        <Tooltip title={t("Toon project details")}>
                            <IconButton edge="start"
                                aria-label="back"
                                color="inherit"
                                className={classes.toolbarBtn}
                                onClick={this.handleShowMainInfo }
                            >
                                <PageLayoutHeader fontSize="large"/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t("Show streng summary")}>
                            <IconButton edge="start"
                                aria-label="back"
                                color="inherit"
                                className={classes.toolbarBtn}
                                onClick={this.handleShowStrengSummary }
                            >
                                <StrengDetailsIcon fontSize="large"/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t("Toon streng details")}>
                            <IconButton edge="start"
                                aria-label="back"
                                color="inherit"
                                className={classes.toolbarBtn}
                                onClick={this.showStrengDetails }
                            >
                                <PageLayoutBody fontSize="large"/>
                            </IconButton>
                        </Tooltip>


                        {(!this.props.readOnly) &&
                            <Tooltip title={t("Voeg streng/put toe")}>
                                <IconButton edge="start"
                                    aria-label="back"
                                    color="inherit"
                                    className={classes.toolbarBtn}
                                    onClick={this.handleAddStreng }
                                >
                                    <PlusThick fontSize="large"/>
                                </IconButton>
                            </Tooltip>
                        }

                    </div>
                </Fade>
                {(this.state.showMainInfo) &&
                    <MainInfoDialog open={this.state.showMainInfo} onClose={this.handleCloseMainInfo} showMissingErrors={this.state.showMainInfoError} readOnly={this.props.readOnly}/>
                }
                {(this.state.showStrengSummary) &&
                    <StrengsSummaryDialog open={this.state.showStrengSummary} 
                        strengs={prj?.strengs}
                        main={prj?.main}
                        onClose={ this.handleCloseSummaryDialog }
                        //onClickObs={this.handleShowObsDetails}
                    />
                }
                {(this.state.showStrengInfo)  &&
                    <StrengInfoDialog open={this.state.showStrengInfo} 
                        onDirectionChanged={this.props.onDirectionChanged}
                        onClose={this.handleCloseStrengInfo}
                        readOnly={this.props.readOnly}
                        showMissingErrors={this.state.showStrengInfoError}
                    />
                }
                {(this.state.showObsList) && 
                    <ObservationsDialog open={this.state.showObsList} 
                        onClose={this.handleCloseObsList}
                        readOnly={this.props.readOnly}
                        onPositionChanged={this.props.onPositionChanged}
                        onStartMeasure={this.props.onStartMeasure}
                        onStopMeasure={this.handleStopMeasure}
                        onStartMeasureAngle={this.props.onStartMeasureAngle}
                        onStartMeasureDepth={this.handleStartMeasureDepth}
                        onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                        onStartMeasureFreeform={this.props.onStartMeasureFreeform}
                        onTerminated={this.props.onTerminated}
                        addNewObs={this.state.addNewObs}
                        innerRef={this.obsDlgRef}
                        obsIndex={this.state.gotoAtEndMeasure}
                    />
                }
            </div>
        );
    }

    /**
     * looks up the image for the specified obs index (detail.image) for the specified streng in the selected project
     * and shows the details.
     * @param {number} strengIdx index of the streng to show details of
     * @param {object} detail details to show. contains fields key, tooltip, image. 
     */
    //handleShowObsDetails = async (strengIdx, detail) => {
   //     try {
   //         
   //     }
   //     catch (error) {
   //         const { t } = this.props;
   //         dialogService.error(t("Search"), errExtractor.get(error));
   //     }
   // }

    renderObsIcons() {
        const { t, classes, minimalControls } = this.props;
        let result = <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                        <Tooltip title={t("Show list of observations")}>
                            <IconButton edge="start"
                                aria-label="back"
                                color="inherit"
                                className={classes.toolbarBtnBottom}
                                onClick={this.handleShowObsList }
                            >
                                <ViewList fontSize="large"/>
                            </IconButton>
                        </Tooltip>

                        {(!this.props.readOnly) &&
                            <Tooltip title={t("Voeg nieuwe waarneming toe")}>
                                <IconButton edge="start"
                                    aria-label="back"
                                    color="inherit"
                                    className={classes.toolbarBtnBottom}
                                    onClick={this.handleAddObs }
                                >
                                    <EyePlus fontSize="large"/>
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
        if (this.props.liveFeed) {
            return result;
        }
        else {
            return (
                <Fade in={!minimalControls} timeout={500}>{result}</Fade>
            );
        }
    }

    /**
     * need to make certain that clicks don't bubble up any furhter to the parent cause any 
     * click that goes up, causes the controls to show/hide, which we don't want when people
     * click/touch here
     */
    handleRootClick = (event) => {
        event.stopPropagation();
    }

    handleShowMainInfo = () => {
        this.setState({showMainInfo: true});
    }

    handleCloseMainInfo = () => {
        const prj = activeProjectService.data;
        if (prj && this.onlyAllowStrengsOnFullMain && !projectService.isMainComplete(prj.main)) {
            this.setState({showMainInfo: true, showMainInfoError: true});
        }
        else {
            this.setState({showMainInfo: false, showMainInfoError: false});
        }
    }

    showStrengDetails = () => {
        this.setState({showStrengInfo: true});
    }

    handleCloseStrengInfo = () => {
        if (this.props.activeStreng && this.onlyAllowObsOnFullStreng && !projectService.isStrengComplete(this.props.activeStreng)) {
            this.setState({showStrengInfo: true, showStrengInfoError: true});    
        }
        else {
            this.setState({showStrengInfo: false, showStrengInfoError: false});
        }
    }

    handleShowStrengSummary = () => {
        this.setState({showStrengSummary: true});
    }

    handleCloseSummaryDialog = (result) => {
        this.setState({showStrengSummary: false});
        if (result > -1) {
            activeProjectService.activeStrengIdx = result;
        }
    }


    handleShowObsList = () => {
        this.setState({showObsList: true, gotoAtEndMeasure: null});
    }

    handleCloseObsList = () => {
        if (this.obsDlgRef.current.obsRef.current.checkObservationCanLooseFocus()) {
            this.setState({showObsList: false, addNewObs: false});
        }
    }

    handleAddObs = () => {
        if (!this.props.readOnly) {
            this.setState({showObsList: true, addNewObs: true, gotoAtEndMeasure: null});
        }
    }

    handleStartMeasureDepth = (callbackDone)  => {
        if (this.props.onStartMeasureDepth) {
            const curObsIdx = this.obsDlgRef.current.obsRef.current.state.expandedIdx;
            this.setState({showObsList: false, gotoAtEndMeasure: curObsIdx});
            this.props.onStartMeasureDepth(callbackDone);
        }
    }

    /**
     * this is onl called when the measurement is stopped from the obs which shouldn't happen in tablet mode, but just to be save
     */
    handleStopMeasure = () => {
        if (this.props.onStopMeasure) {
            this.props.onStopMeasure();
        }
        this.setState({showObsList: true});
    }

    handleAddStreng = () => {
        const strengs = activeProjectService.data.strengs;
        const onNewStrengCopyPrevData = configService.get('strengs.onNewStrengCopyPrevData');
        let template = onNewStrengCopyPrevData ? this.props.activeStreng : null;;
        const id = Math.max(...strengs.map(x => x.id)) + 1;     // always make certain we have a unique id. This is for fast referencing of the files.
        const streng = projectService.createNewStreng(id, template);
        strengs.push(streng);                                //always add to the officiel list, so it can get saved.
        activeProjectService.markDirty();
        activeProjectService.activeStrengIdx = strengs.length - 1;
        this.setState({showStrengInfo: true}); 
    }

   
}

InspectionBar.propTypes = {
    direction: PropTypes.string,                            // allowed values: 
    readOnly: PropTypes.bool,
    onDirectionChanged: PropTypes.func,
    minimalControls: PropTypes.bool,                                // when false, show sidebars, otherwise hide as much as possible
    onToggleMinimalControls: PropTypes.func,
    onPositionChanged: PropTypes.func,                  // when this observation is expanded, let the system go to the video point at which it was recorded.
    onStartMeasure: PropTypes.func,                     // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                      // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func, 
    onTerminated: PropTypes.func,        
    liveFeed: PropTypes.bool,                           // when true, showing live camera feed, so keep obs icons visible, otherwise hide them togehter with minimalControls
    activeStreng: PropTypes.any,
};

export default withTranslation()(withStyles(styles)(InspectionBar));