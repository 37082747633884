/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { codeSelectorService } from './code_selector_service';
import i18n from "i18next";
import { errExtractor } from './error_extractor';
import { projectService, PROJECT_VERSION } from './project_service';
import { dialogService } from '../../services/dialog_service';
import { cloudDocsService } from './cloud_documents_service';
import { configService } from '../../services/config_service';
import { strandPropsService } from './strand_props_service';

const events = require('events');

/**
 * base class for the ActiveProjectService.
 * This provides all the platform generic stuff. Descendents should implement platform specific stuff (getting data from disk,...)
 */
export class ActiveProjectBaseService {


    constructor() {
        //this.path = null;
        this.projectName = null;
        this.data = null;
        this.loadedFrom = null;                                         // when data gets loaded, this indicates from where. allowed: 'cloud', 'file'. This is so we know how to handle everything correctly (cloud is always read-only).
        this.dirtyTimer = null;
        this._activeStrengIdx = -1;                                     // the current streng. stored here so it can easily be shared accross components working on the currently active doc, managed by Strengs component.
        this.events = new events.EventEmitter();                        // both videoplayer as robotplayer need to monitor activeStrengIdx.
        this.continuousIdxGen = [];                                     // used to track all the currently open continuous observations, so we can assign correct index nr to objects.
    }

    get activeStrengIdx () {
        return this._activeStrengIdx;
    }
    set activeStrengIdx(value) {
        this._activeStrengIdx = value;
        this.buildContinuousIdxGenList();
        strandPropsService.init(this.activeStreng);                                 // when the streng has changed, the streng props also need to be reset to init
        this.events.emit("strengChanged", value);
    }

    get activeStreng () {
        if (this.data && this._activeStrengIdx >= 0 && this._activeStrengIdx < this.data.strengs.length) {
            return this.data.strengs[this._activeStrengIdx];
        }
        return null;
    }

    /**
     * checks if there is a data file available for the currently active streng
     */
    activeStrengHasVideo() {
        const streng = this.activeStreng;
        return (streng && streng.videoUrl != null && Object.keys(streng.videoUrl).length > 0);     // important: != cause we want to check if video has a value !== would not work for undefined
    }

    /**
     * checks if there is a robot data file available for the currently active streng
     */
    activeStrengHasReverseRobotData() {
        const streng = this.activeStreng;
        return (streng && streng.robotRevDataUrl != null);
    }

    activeStrengHasSonarData() {
        const streng = this.activeStreng;
        return (streng && streng.sonarDataUrl != null);
    }

    activeStrengHasLidarData() {
        const streng = this.activeStreng;
        return (streng && streng.lidarDataUrl != null);
    }
   

    /**
     * creates a new project and saves it.
     * is async cause it might also save to the cloud
     * @param {string} dest the path to store all the info in. This may end in '.cami', it will be stripped.
     */
    async createNew(dest) {
        this.projectName = dest;
        this.data = projectService.createNew();
        this.loadedFrom = 'file';
        this.save();
    }

    /**
     * saves the current project.
     * this only clears the timer, inheritor does the save.
     * @param {bool} blockCloud when true: no cloud update is done. This is for when project is created, in which case, cloud-save is already done
     */
    save() {                                                             
        if (this.dirtyTimer) {                                                                                   // when it's saved, no longer need to have any timers running to save changes,
            clearTimeout(this.dirtyTimer);
        }
        this.dirtyTimer = null;
    }

    /**
     * loads the project data.
     * inheritors should further implement, also set the projectName
     * @param {string} id id of the project
     */
    load(id) {
        this.data = projectService.load(id);
        this.loadedFrom = 'file';
    }

    /**
     * loads the project data.
     * inheritors should further implement, also set the projectName
     * @param {string} id id of the project
     * @returns true upon success, false otherwise
     */
    async loadFromCloud(id) {
        return new Promise((resolve, reject) => {
            try {
                cloudDocsService.getProject(id).then((data) => {
                    projectService.checkVersion(data);
                    this.tryUpgradeForCloud(data);                          // need to make certain that older file formats that haven't been converted yet, are loaded correctly.
                    this.path = null;
                    this.data = data;
                    this.projectName = data.name;
                    this.loadedFrom = 'cloud';
                    resolve(true);
                }).catch((error) => {
                    this.data = null;
                    dialogService.error(i18n.t("Openen"), i18n.t("load_error", {pathToFile: id, error: errExtractor.get(error)}));
                    resolve(false);
                });
            }
            catch(error) {
                dialogService.error(i18n.t("Openen"), i18n.t("load_error", {pathToFile: id, error: errExtractor.get(error)}));
                resolve(false);
            }
        });
    }

    /**
     * called when a project is loaded from cloud
     * Will make certain that the project is consistent with the latest version of the project file definition.
     * does not store anything
     */
     tryUpgradeForCloud(data) {
        if (data.version === "1.1.0") {                        // earliest version we need to try and upgrade
            this.updateStrengs_110_to_121(data);
            data.version = "1.2.1";
        }
        else if (data.version === "1.2.0") {                        // earliest version we need to try and upgrade
            this.updateStrengs_120_to_121(data);
            data.version = "1.2.1";
        }
        if (data.version === "1.2.1") {
            this.updateStrengs_121_to_122(data);
            data.version = "1.2.2";
        }
    }

    updateStrengs_110_to_121(data) {
        for(const streng of data.strengs) {
            streng['inspMethod'] = data.main.inspectionMethode;
            for(const channel in streng.cams) {                         // need to make certain that these fields are real booleans, cause something went wrong in the past with this
                const cam = streng.cams[channel];
                cam.tOnV = cam.tOnV === 'true' || cam.tOnV === true;
                cam.iOnV = cam.iOnV === 'true' || cam.iOnV === true;
                cam.addI = cam.addI === 'true' || cam.addI === true;
                cam.addT = cam.addT === 'true' || cam.addT === true;
            }
        }
        delete data.main.inspectionMethode;
    }

    updateStrengs_120_to_121(data) {
        for(const streng of data.strengs) {                             // need to make certain that these fields are real booleans, cause something went wrong in the past with this
            for(const channel in streng.cams) {
                const cam = streng.cams[channel];
                cam.tOnV = cam.tOnV === 'true' || cam.tOnV === true;
                cam.iOnV = cam.iOnV === 'true' || cam.iOnV === true;
                cam.addI = cam.addI === 'true' || cam.addI === true;
                cam.addT = cam.addT === 'true' || cam.addT === true;
            }
        }
    }

    updateStrengs_121_to_122(data) {
        for(const streng of data.strengs) {                             // need to make certain that these fields are real booleans, cause something went wrong in the past with this
            streng.equipmentSerialNr = data.main.equipmentSerialNr;
        }
        delete data.main.equipmentSerialNr;
    }

    /**
     * saves the current project to the cloud
     */
    async saveToCloud() {
        try {
            if (this.data.id) {
                await cloudDocsService.updateProject(this.data);
            }
            else {
                const cloudData = await cloudDocsService.addProject(this.data);
                // important: keep original data, there might be references to it
                this.data.id = cloudData.id;
                this.markDirty();
            }
        }
        catch (error) {
            //todo: inform user that cloud project is no longer in sync with local
            console.log(error);
        }
    }

    /**
     * called by the components when they modify the data to indicate that it needs to be saved.
     * The timer delay prevents from saving too often.
     * @param {string} field the name of the field that has changed, if known. This is used to allow
     * UI elements to perform updates upon changes to a particular field (ex: track-view uses this to 
     * monitor for changes in expected distance, start & end height offsets)
     */
    markDirty(field=null) {
        if (this.dirtyTimer) {
            clearTimeout(this.dirtyTimer);
        }
        this.dirtyTimer = setTimeout(() => {
            try {
                this.dirtyTimer = null;
                this.save();
            }
            catch(error) {
                dialogService.error(i18n.t("Bewaren"), i18n.t("save_doc_err", {error: errExtractor.get(error)} ));
            }
        }, 2000);
        this.events.emit("dirty", field);
    }



    /**
     * emits an event to all interested observatsions (those that are continuous, but not closures) 
     * to indicate that an observation that is a continuous (closure or not) has been added/changed/removed.
     * This is only used by the currently loaded observation components, so it's always but a fractio of the
     * dataset and always from the same list (each observation knows the list it belongs to)
     * @param {object} changed the observation data object that changed, null in case of delete
     * @param {list} oldKey in case that the code of an observation changed, the previous code
     * @param {list} oldKey in case that the code of an observation changed, the previous code
     */
    continuousChanged(changed, oldKey=null, oldIdx=null) {
        let params = {changed, oldKey, oldIdx};
        this.events.emit("continuousChanged", params);
    }

    /**
     * checks if the new record is a continuous observation and if so, assigns an index number to it.
     * also checks for duplicate running continous obs.
     * @param {object} record the newly created observation
     */
    processNewContinuousRecord(record) {
        const isContinuous = codeSelectorService.isContinuous(record.values);
        if (isContinuous) {
            if (record.values[record.values.length-1] !== "1") {
                this.continuousIdxGen.push(record);
                record['continuousIdx'] = this.continuousIdxGen.length;
            }
            else if (record.continuousIdx) {                                    // cant be 0, so that's ok
                this.continuousIdxGen[record.continuousIdx-1] = null;             // close it so that findDuplicateToClose doesn't trip on it.
            }
            this.continuousChanged(record);
        }
    }

    /**
     * checks if the new obs has the same code as an already running
     * continuous observation. If so, the user is asked what to do: close the previous
     * one or keep both open. The first case is for changing values (ex water level), the second
     * is for a new situation, ex: a second crack
     * @param {object} record the newly created observation
     * @returns the duplicate object or null
     */
    findDuplicateToClose(record) {
        return new Promise((resolve, reject) => {
            const key = record.values.join('');
            const found = this.continuousIdxGen.findIndex((value) => {
                if (value !== record && value != null && value != undefined) {
                    const keyOfValue = value.values.join('');
                    return (key === keyOfValue);
                }
                return false;
            });
            if (found > -1) {
                const duplicate = this.continuousIdxGen[found];
                const message = i18n.t("duplicate_cont_obs_message");
                const details = i18n.t("duplicate_cont_obs_details");
                const responses = [
                    i18n.t("duplicate_cont_obs_change_value_of_prev"),
                    i18n.t("duplicate_cont_obs_new_obs")
                ];
                dialogService.ask(i18n.t('Observation'), message, details, responses, (selectedIdx) => {
                    if (selectedIdx.response === 0) {                                                     // user wants to change the value of prev obs, so close prev obs.
                        resolve(duplicate);
                    }
                    else {
                        resolve (null);                                                                     // callback is called out of sync, so this statement is reuired, cant rely on end of code block
                    }
                });
            }
            else {
                resolve(null);
            }
        });
    }

    /**
     * called when a continuous observation is changed to non-continuous
     * @param {object} record the observation being modified
     */
    clearContinuousRecord(record, prevKey) {
        this.continuousChanged(record, prevKey, record.continuousIdx);
        let idx = this.continuousIdxGen.indexOf(record);
        if (idx === this.continuousIdxGen.length -1) {                                  // it's the last one, can be cleared?
            this.continuousIdxGen.pop();
            this.removeDeletedIdxGen();
        }
        else if (idx > -1) {
            this.continuousIdxGen[idx] = undefined;
        }
        delete record.continuousIdx;
    }

    /**
     * pops the records from this.continuousIdxGen from as long as the value is undefined.
     * when null -> existing, but closed
     */
    removeDeletedIdxGen() {
        while(this.continuousIdxGen.length > 0 && this.continuousIdxGen[this.continuousIdxGen.length - 1] === undefined) {
            this.continuousIdxGen.pop();
        }
    }


    /**
     * called when observation is removed. Makes certain that the internal list is kept in sync.
     * @param {object} record observation being deleted
     * @param {lsit} list the list of all the records, used in case that a terminator is deleted, so we can restore the obs in the continuousIdxGen list
     */
    deleteContinuous(record, list) {
        const isContinuous = codeSelectorService.isContinuous(record.values);
        if (isContinuous) {
            if (record.values[record.values.length-1] !== "1") {
                if (this.continuousIdxGen.length === +record.continuousIdx) {                            // it's the last item on the list remove it.
                    this.continuousIdxGen.pop();
                    this.removeDeletedIdxGen();
                }
                else {
                    this.continuousIdxGen[record.continuousIdx-1] = undefined;                               // in the middle of the list, remove it.
                }
            }
            else {
                const startObs = list.find((item) => item.continuousIdx === record.continuousIdx);
                this.continuousIdxGen[record.continuousIdx-1] = startObs; 
            }
            this.continuousChanged(record);
        }
    }

    /**
     * called whenever switched from streng. Makes certain that the list continuousIdxGen
     * is in sync with the currently selected streng.
     */
    buildContinuousIdxGenList() {
        const streng = this.activeStreng; 
        this.continuousIdxGen = [];
        for(const obs of streng.observations) {
            const isContinuous = codeSelectorService.isContinuous(obs.values);
            if (isContinuous) {
                if (obs.values[obs.values.length-1] === "1") {
                    this.continuousIdxGen[obs.continuousIdx-1] = null;
                }
                else {
                    while (+obs.continuousIdx > this.continuousIdxGen.length) {
                        this.continuousIdxGen.push(undefined);
                    }
                    this.continuousIdxGen[(+obs.continuousIdx)-1] = obs;
                }
            }
        }
    }

    /**
     * stores the size & length of the video in the current streng (when need be). 
     * This makes it easier for other parts of the system to use this info without having
     * to render the video first.
     */
    storeVideoSize(streng, video, channel) {
        if (!streng) return;
        if (!video) return;                                                 // can happen if you open and go back too fast
        const def = streng.cams[channel];
        if (!def || def.height !== video.videoHeight || def.width !== video.videoWidth) { 
            streng.cams[channel] = {idx:0, width: video.videoWidth, height: video.videoHeight, tOnV: false, iOnV: false, addI: true, addT: true, scalingFactor1: 0, scalingFactor2: 0};
            this.markDirty();
        }
        if (streng.videoLength !== video.duration) {                        // all channels should have a video file of same length (migth differ a few frames, but not so important)
            streng.videoLength = video.duration;
            this.markDirty();
        }
    }

    /**
     * adds the observatio to the currently active streng and raises an event to let
     * ui know.
     * Note: this method should be used by non UI services that want to add observations.
     * @param {obj} obs the new observaion to add
     */
    addObsToStreng(obs) {
        const streng = this.activeStreng;
        streng.observations.push(obs);
        streng.observations.sort((a, b) => a.longitude-b.longitude);
        const idx = streng.observations.indexOf(obs);
        this.markDirty();
        this.events.emit("obsGenerated", idx);
    }

    /**
     * requests the editors that are showing an observation, if they are ok, with a new one
     * being added.
     * This is used by services that want to add new observations that need to become active (auto generators)
     * to ask editors if they are ok with switching to a newly active observation.
     * This allows obervations to run checkObservationCanLooseFocus.
     */
    canEditorsContinueOnObs() {
        let result = {canContinue: true}
        this.events.emit("canContinueOnObs", result);
        return result.canContinue;
    }


    /**
     * used to let other parts of the system know that the recording has stopped.
     * This is currently used to let the observations component know that it needs to reload the data and
     * need to move focus.
     */
    signalEndOfRecording() {
        this.events.emit("recordingReady");
    }

    /**
     * used to let obervations know that record has started. It should refresh it's ui completely cause some observation
     * records might have changed cause of the record start.
     */
    signalStartOfRecord() {
        this.events.emit("recordingStart");
    }

    /**
     * creates a string with the path and filename for the currently selected streng.
     */
    getRobotDataFileForCurrentStreng(reverse=false){
        throw new Error("implemented by inheritor");
    }

    /**
     * creates a string with the path and filename for the currently selected streng.
     */
    getVideoFileForCurrentStreng(channel, raw=true){
        throw new Error("implemented by inheritor");
    }


    /**
     * creates a string with the path and filename for a pdf file in this project
     */
    getPdfFilePath(){
        throw new Error("implemented by inheritor");
    }

    getReportPath() {
        throw new Error("implemented by inheritor");
    }

    /**
     * returns the path to the project's temp folder, and the filename appended to it when supplied.
     * @param {string} filename the filename to append to the path, optional
     */
    getTempPath(filename=null) {
        throw new Error("implemented by inheritor");
    }

    /**
     * returns all the unique values that were entered for the specified streng-field.
     * @param {string} field name of the field to filter on
     */
    getAllValuesForStrengField(field) {
        if (this.data && this.data.strengs) {
            let result = this.data.strengs.map(x => x[field]).filter((value, index, lst) => {
                return (typeof value === 'string') && value !== "" && value !== undefined && value !== null && lst.indexOf(value) === index;                                 // also skip empty values and duplicate
            });
            return result;
        }
        return [];
    }
   
}