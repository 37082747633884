/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import CrosshairsGps from 'mdi-material-ui/CrosshairsGps';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { activeProjectService } from '../../../../services/active_project_service';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from '../../../services/project_service';
import { errExtractor } from '../../../services/error_extractor';
import { withTranslation } from 'react-i18next';
import { dialogService } from '../../../../services/dialog_service';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { gpsService } from '../../../../services/gps/gps_service';

const styles = (theme) => ({
    textField: {
        width: '100%'
    },
    row : {
        flexDirection: 'row', 
        display:'flex', 
        alignItems: 'center', 
        justifyContent:'flex-end'
    }
});

/**
 * lambert conversion: https://github.com/vraida/Lambert-projection/blob/master/python_implementation/lib/lambert.py
 */
class StrengInfoCoordinatesField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.streng ? props.streng[props.field] : null
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.field !== this.props.field || prevProps.streng !== this.props.streng) && this.props.streng) {
            this.setState({value: this.props.streng[this.props.field]});
        }
    }

    render() {
        const { t } = this.props;
        let error = this.props.required && this.props.showMissingErrors && isEmpty(this.state.value);
        return (
            <div className={this.props.classes.row}>
                <TextField
                    required={this.props.required}
                    error={error}
                    id={this.props.field}
                    label={this.props.label}
                    InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                    InputProps={{readOnly:this.props.readOnly}}
                    value={this.state.value}
                    onChange={this.handleTextChanged}
                    className={this.props.classes.textField}
                    margin="dense"
                />
                {(!this.props.readOnly) && 
                    <Tooltip title={t("auto_fill_coordinates")}>
                        <span>
                            <IconButton 
                                aria-label="coordinaten" 
                                size="small" 
                                disabled={!gpsService.isAvailable}
                                onClick={this.handleGetCoordinates}>
                                <CrosshairsGps />
                            </IconButton>
                        </span>
                    </Tooltip>
                }
            </div>
        );
    }

    handleGetCoordinates = async () => {
        const { t } = this.props;
        try {
            const pos = await gpsService.currentPos();
            if (pos) {
                this.storeValue(`${pos.lat}, ${pos.lon}`);
            }
            else {
                dialogService.error(t("Gps"), t("invalid_gps_cooordinates"));
            }
        }
        catch(error) {
            dialogService.error(t("Gps"), errExtractor.get(error));
        }
    }

    handleTextChanged = (ev) => {
        this.storeValue(ev.target.value);
    }

    storeValue(value) {
        try {
            this.props.streng[this.props.field] = value;
            this.setState({value: value});
            activeProjectService.markDirty(this.props.field);
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

}

StrengInfoCoordinatesField.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    streng: PropTypes.object,                 // a reference to the streng for which we are currently showing data.
    required: PropTypes.bool,
    showMissingErrors: PropTypes.bool,                       // when true, inputboxes that require a value but dont, should show an error
    readOnly: PropTypes.bool,                           // for viewer
};

StrengInfoCoordinatesField.defaultProps = {
    required: false
}

export default withTranslation()(withStyles(styles)(StrengInfoCoordinatesField));