/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import CameraDirectionButtons from '../camera_direction_component';
import Tooltip from '@material-ui/core/Tooltip';
import { remoteControlService, REMOTE_ACTIONS } from '../../../../services/remote_control_service';
import { TabletSlider } from './tablet_slider_component';
import Fade from '@material-ui/core/Fade';
import { documentControlService } from '../../../document_control_service';
import clsx from "clsx";


const styles = (theme) => ({
    root: {
        opacity: 1,
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        gridColumnStart: 3,
        gridColumnEnd: 5,
        gridRowStart: 1,
        gridRowEnd: 3,
        zIndex: 3,                                              // important, otherwise the bottom bar blocks input on the camera-dir down button
        paddingTop: theme.spacing(3),
        color: 'white',
    },
    sliderBox: {
        paddingBottom: theme.spacing(3),
        flex: 1
    },
    sliderBox2: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        flex: 0.4
    },

});


class TabletCameraController extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            lightLevel: remoteControlService.lightLevel,
            zoomLevel: remoteControlService.zoomLevel,
        }
        this.txtLightIntensity = props.t("Light intensity");
        this.txtZoom = props.t("Zoom");
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
        this.tryInitZoom();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.initLoaded !== this.props.initLoaded && this.props.initLoaded) {
            this.tryInitZoom();
        }
    }

    tryInitZoom() {
        const robot = documentControlService.robotDs;
        const limits = robot?.sensorLimits?.zoom;
        if (limits && limits.default != undefined) {                // important: check on default cause we need to init the value differently when continious
            if (limits.type === 'continious') {
                this.setState({zoomLevel: [limits.default, limits.default]});
            }
            else {
                this.setState({zoomLevel: limits.default});
            }
        }
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
    }

    render() {
        const { classes, hideControls } = this.props;

        const robot = documentControlService.robotDs;
        const limits = robot?.sensorLimits?.zoom;
        const sliderBox2Style = limits?.type === 'continious' ? classes.sliderBox2 : classes.sliderBox;
        return (
            <Fade in={!hideControls} timeout={500} >
                <div className={classes.root} >

                    <div className={classes.sliderBox} onClick={this.handleSliderClick}>
                        <Tooltip title={this.txtLightIntensity}>
                            <div style={{height: '100%', width: '100%'}}>
                                <TabletSlider value={this.state.lightLevel}
                                    onChange={this.handleChangeLightLevel}
                                    orientation="vertical"
                                    aria-labelledby="position"
                                    
                                    min={0}
                                    step={17}
                                    max={255}
                                />
                            </div>
                        </Tooltip>
                    </div>



                    <div className={sliderBox2Style} onClick={this.handleSliderClick}>

                        <Tooltip title={this.txtZoom}>
                            <div style={{height: '100%', width: '100%'}}>
                                {this.renderZoomSlider(limits)}
                            </div>
                        </Tooltip>
                    </div>

                    <div onClick={this.handleSliderClick}>
                        <CameraDirectionButtons tabletMode={true} leftRightAsFocus={true}/>
                    </div>

                </div>

            </Fade>
        );
    }

    renderZoomSlider(limits) {
        let zoomValue = this.state.zoomLevel;
        if (limits) {
            switch (limits.type) {
                case 'continious':
                    return(
                        <TabletSlider value={this.state.zoomLevel}
                            ThumbComponent={(props) => {
                                const { children, className, ...other } = props;
                                let idx = 0;
                                if (!zoomValue) {
                                    idx = -1;
                                }
                                else if (zoomValue[0] != limits.default) {
                                    idx = 1;
                                }
                                const extraClassName = other["data-index"] === idx ? "hidden-thumb" : null;
                                return (
                                    <span {...other} className={clsx(className, extraClassName)}>
                                        {children}
                                    </span>
                                );
                            }}
                            onChange={this.handleContiniousZoom}
                            orientation="vertical"
                            onChangeCommitted={this.handleStopContiniousZoom}
                            aria-labelledby="position"
                            min={limits.min}
                            step={limits.step}
                            max={limits.max}
                        />
                    );
                    case 'continious':
                        return(
                            <TabletSlider value={this.state.zoomLevel}
                                onChange={this.handleZoom}
                                orientation="vertical"
                                aria-labelledby="position"
                                min={limits.min}
                                step={limits.step}
                                max={limits.max}
                            />
                        );
                default:
                    break;
            }
        }
        return (
            <TabletSlider value={this.state.zoomLevel}
                onChange={this.handleZoom}
                orientation="vertical"
                
                aria-labelledby="position"
                min={0}
                step={1}
                max={255}
            />
        );      
    }

    /**
     * need to make certain that clicks don't bubble up any furhter to the parent cause any 
     * click that goes up, causes the controls to show/hide, which we don't want when people
     * click/touch here
     */
     handleSliderClick = (event) => {
        event.stopPropagation();
    }

    handleZoom = (event, value) => {
        //console.log("zoom in");
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM, value);
        this.setState({ zoomLevel: value });
    }


    handleChangeLightLevel = (event, value) => {
        remoteControlService.requestState(REMOTE_ACTIONS.LIGHT_LEVEL, value);
        this.setState({ lightLevel: value });
    }

    handleContiniousZoom = (event, value) => {
        const robot = documentControlService.robotDs;
        const limits = robot?.sensorLimits?.zoom;
        const center = limits.default ?? 0;
        if (value[1] > center) {
            remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM_IN, value);
            this.setState({ zoomLevel: value});
        }
        else if (value[0] < center) {
            remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM_OUT, value);
            this.setState({ zoomLevel: value});
        }
        else {
            remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM_STOP);
            this.setState({ zoomLevel: [center, center] });
        }
    }

    handleStopContiniousZoom = (event, value) => {
        const robot = documentControlService.robotDs;
        const limits = robot?.sensorLimits?.zoom;
        const center = limits.default ?? 0;
        remoteControlService.requestState(REMOTE_ACTIONS.CAM_ZOOM_STOP);
        this.setState({ zoomLevel: [center, center] });
    }



    handleRemoteUpdate = (action, value) => {
        switch (action) {
            case REMOTE_ACTIONS.LIGHT_LEVEL:
                this.setState({ lightLevel: value });
                break;
            case REMOTE_ACTIONS.CAM_ZOOM:
                this.setState({ zoomLevel: value });
                break;
            default:
                break;
        }
    }

}

TabletCameraController.propTypes = {
    hideControls: PropTypes.bool,                                    // hide the controls when in video play mode
    initLoaded: PropTypes.bool.isRequired,                  // need to know when initial loading is done, so we can read the engine cofnig correctly (robot)
};

export default withTranslation()(withStyles(styles)(TabletCameraController));