/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//img 
//import logoImg from '../../images/name_logo.png';
import Download from 'mdi-material-ui/Download';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
//import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import DocsList from './docs_list_component';
import DownloadDialog from './dowload_dialog';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { cloudDocsService } from '../services/cloud_documents_service';
import { isEmpty } from '../services/project_service';
import { storageService, TEMP_ZIP_BUCKET } from '../../services/storage_service';
import { dialogService } from '../../services/dialog_service';
import { errExtractor } from '../services/error_extractor';
import { userService } from '../services/user_service';
import Typography from '@material-ui/core/Typography';
import { microServices } from '../services/micro_services';
import { configService } from '../../services/config_service';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = (theme) => ({
    root: {
        flex: 1,
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
    },
    docRoot: {
        flex: 1
    },
    logo: {
        height: '70px',
        pointerEvents: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

});


class CloudDocsList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            reports: [],
            downloadDlgOpen: false,
            downloadProject: null,                                  // for letting the download dlg know for which project to select files from
            downloading: true,                                      // so we can indicate when the data has not yet been retrieved from the server
        }
    }

    /**
     * load data here, constructor gets called multiple times, causing data to be loaded multiple times
     */
    componentDidMount() {
        cloudDocsService.getDocs().then((result) => {
            let docs = result.map((el) => {       // at the moment, only show published items
                return {
                    publishedAt: el.publishedAt,
                    id: el.id,
                    recipients: el.recipients,
                    invalidRecipients: el.invalidRecipients,
                    location: el.main.location,
                    managingInstance: el.main.managingInstance,
                    opdrCodeBedrijf: el.main.opdrCodeBedrijf,
                    opdrCodeOpdrGever: el.main.opdrCodeOpdrGever,
                    name: el.name,
                    companyName: el.companyName,
                    rawData: el,                                                      // so we can quickly save recipient & id changes without having to reload the data all the time. WARNING: could be mem issue
                }
            });
            this.setState({ reports: docs, downloading: false });
        }).catch((error) => {
            const { t } = this.props;
            this.setState({ downloading: false });
            dialogService.error(t("Reports"), t("docs_load_failed", { error: errExtractor.get(error) }));
        });
    }


    render() {
        const { t } = this.props;
        let content;
        if (!userService.getCurrentUser()) {
            content = <Typography>Not logged in</Typography>
        }
        else if (this.state.reports && this.state.reports.length > 0) {
            content = 
            <Container maxWidth="lg" className={this.props.classes.docRoot}>
                <DocsList onDeleteDocument={this.deleteReport}
                    onShowDetails={this.handleShowDetails}
                    onShowPdf={this.showPdf}
                    onIsShowPdfDisabled={this.hanldeIsShowPdfDisabled}
                    reports={this.state.reports}
                    noValuesText={t("No reports have been shared yet")}
                    onRecipientsChanged={this.saveRecipients}
                    customActions={[
                        {
                            icon: () => <Download color="action" />,
                            tooltip: t('Download files'),
                            onClick: (event, data) => this.handleStartDownload(data),
                            position: "row"
                        }
                    ]}
                />
                <DownloadDialog open={this.state.downloadDlgOpen}
                    onClose={this.handleCloseDownloadDlg}
                    downloadProject={this.state.downloadProject} />
            </Container>
        }
        else {
            content =
                <React.Fragment>
                    <div style={{ flex: 1 }} />
                    <Container maxWidth="sm">
                        <Box>
                            <DocsList noValuesText={t("No reports have been shared yet")}
                            />
                        </Box>
                    </Container>
                    <div style={{ flex: 1 }} />
                    <Backdrop className={this.props.classes.backdrop} open={this.state.downloading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </React.Fragment>
        }
        return (
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch" alignSelf="stretch" className={this.props.classes.root}>
                {content}
            </Box>
        );
    }

    handleStartDownload = (project) => {
        this.setState({ downloadDlgOpen: true, downloadProject: project.id })
    }

    handleCloseDownloadDlg = async (result) => {
        const { t } = this.props;
        const project = this.state.downloadProject;
        try {
            this.setState({ downloadDlgOpen: false });
            if (result && this.props.onDownloadFiles) {
                if (result.indexOf('videos') > -1 || result.indexOf('images') > -1 || result.indexOf('attachments') > -1) {             // need the zip
                    const bucket = { bucket: TEMP_ZIP_BUCKET, name: storageService.getProjectZipFile(result, project) };
                    let url = await storageService.tryGetUrl(bucket);
                    if (url) {
                        this.props.onDownloadFiles([url]);
                    }
                    else {
                        const user = userService.getCurrentUser();
                        if (!user) {
                            throw new Error("No user logged in, can't generate zip request");
                        }
                        if (!user.email) {
                            throw new Error(t("no_zip_missing_email"));
                        }
                        const request = {   "videos": result.indexOf('videos') > -1, 
                                            "images": result.indexOf('images') > -1, 
                                            "xml": result.indexOf('xml') > -1, 
                                            "pdf": result.indexOf('pdf') > -1, 
                                            "attachments": result.indexOf('attachments') > -1, 
                                            "InclinationAsPercentage": configService.get("robot.InclinationAsPercentage") }
                        await microServices.zipProject(project, request);
                        dialogService.info(t("Download"), t("project_zip_being_prepared"));
                    }
                }
                else {
                    let urls = [];
                    for (const bucket of result) {
                        const url = await storageService.getUrl(bucket);
                        urls.push(url);
                    }
                    this.props.onDownloadFiles(urls);
                }
            }
        }
        catch (error) {
            dialogService.error(t("Download"), t("file_download_problem", { error: errExtractor.get(error) }));
        }
    }

    handleShowDetails = (ev, doc) => {
        this.props.history.push(`/doc/${doc.id}`);
    }

    hanldeIsShowPdfDisabled = (rowData) => {
        if (rowData.rawData.files) {                                            // when not yet published and viewed by company editor, this doc is in list, but will not yet have files.
            return isEmpty(rowData.rawData.files.pdfReport);
        }
        return true;
    }

    showPdf = (event, rowData) => {
        const files = rowData.rawData.files;
        if (files.pdfReport) {
            storageService.getUrl(files.pdfReport, "application/pdf").then((url) => {
                if (this.props.onOpenPdf) {
                    this.props.onOpenPdf(url);
                }
            }).catch((error) => {
                const { t } = this.props;
                dialogService.error("pdf", t("cant_find_file_on_server", { error: errExtractor.get(error), doc: files.pdfReport.name }));
            });
        }
    }

    /**
     * deletes the report. Error handling not needed here, is done by caller.
     * @param {object} report the report to delete
     */
    deleteReport = async (report) => {
        try {
            await cloudDocsService.delete(report.id);
            const idx = this.state.reports.indexOf(report);                     // remove it from the list, don't need to recreate the list. caller is doing await and will refresh automatically after all are done.
            if (idx >= 0) {                                                     // just a sanity check
                let newReports = [...this.state.reports];
                newReports.splice(idx, 1);
                this.setState({ reports: newReports });
            }
        }
        catch (error) {
            dialogService.error("Reports", errExtractor.get(error));
        }
    }

    /**
     * don't do any error handling. Is done in caller so that ui doesn't get updated
     * incorrectly when there is an error
     * @param {object} report the data object created in buildReportObj
     * @param {array} list list of emails that changed
     * @param {string} operation possible values: 'added' or 'removed'
     */
    saveRecipients = async (report, list, operation) => {
        if (report.id) {                                                                            // the report is already saved in the cloud, so update in cloud and file
            if (!navigator.onLine) {
                throw new Error("internet connection required to modify list of receivers for published projects");
            }
            if (report.rawData.cloudState === "published") {
                if (operation === "added") {
                    report.rawData = await cloudDocsService.addRecipients(report.id, list);             // need to keep track of the list of failed recipients
                }
                else {
                    await cloudDocsService.removeRecipients(report.id, list);
                }
            }
            else {
                report.rawData.recipients = report.recipients;                                                  // save the new list in the rawdata so we can save to file again and upload the correct data to cloud
                const updateRec = { id: report.id, recipients: report.recipients };
                await cloudDocsService.updateProject(updateRec);
            }
        }
    }
}

CloudDocsList.propTypes = {
    onOpenPdf: PropTypes.func,                          // (url: string) => {} called when the pdf needs to be opened, platform specific, 
    onDownloadFiles: PropTypes.func                     // (list: bucket[]) => {} called when files need to be downloaded for the user. 
};

export default withTranslation()(withStyles(styles)(withRouter(CloudDocsList)));