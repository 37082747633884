import { configService } from '../../../../services/config_service';

/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */
const events = require('events');


/**
 * providers a generic interface to connect to for the ui elements. They connect to this service and
 * the service will provide data from the player or recorder, according to the settings. Data is also
 * buffered so that it wont play to fast (cause of too much data too fast or scrolling too fast).
 */
class SonarService {
    constructor() {
        this.data = [];                                           // current active data set
        this.roll = 0;                                              // roll value, specific to the sonar
        this.yaw = 0;
        this.range = 0;                                             // range of the sonar as recorded in the data
        this.nextData = null;                                       // next line to send as event
        this.events = new events.EventEmitter();                    // callback for visualizing the data.
        this.timer = null;
        this.provider = null;                                       // when set, we can record data

        this.channels = 0;                                                // nr of channels (directions) supported by the sonar device.
        this.valuesPerChannel = 0;                                        // nr of values in a single channel. needed to determine the distance for a single value. When 0, it is variable and determined by the channel itself
        this.AngleAdjust = 0;                                       // some sonars aren't aligned properly with the device

        this._lowCutoff = null;                                     // can't yet be init, comes from congiService, which does not yet exist
        this._highCutoff = null;
        this._grayMode = null;
    }
  
    /**
     * called when the robot recorder/player is initialized. Allows us to set the correct size.
     * @param {number} length nr of samples to describe a circle
     */
    init(provider) {
        if (this.provider !== provider) {                       // when first starting up, this can happen cause init is called when the backend tractor is started and the frontend robotClient is also started
            if (this.provider) {
                this.provider.onData = null;                    // remove any previous callbacks so we don't get duplicate data by accident
                this.channels = 0;
                this.valuesPerChannel = 0;
                this.AngleAdjust = 0;
            }
            this.provider = provider;
            if (provider) {
                this.provider.onData = this.handleData;
                this.channels = provider.channels;
                this.AngleAdjust = provider.AngleAdjust;
                this.valuesPerChannel = provider.valuesPerChannel;
                if (this.provider.playCurrent) {                                    // the recorder doesn't have a playCurrent
                    this.provider.playCurrent(true);                                // when we init the data from the source, make certain we render the start picture correctly
                }
            }
            this._lowCutoff = configService.get("sonar.lowCutoff");             // can't do this on construction cause then there is no configservice yet
            this._highCutoff = configService.get("sonar.highCutoff");
            this._grayMode = configService.get("sonar.grayMode");
        }
    }

    clear() {
        if (this.provider) {
            this.provider.onData = null;
            this.provider = null;
        }
        this.data = null;
        this.currentIdx = 0;
    }

    handleData = (values) => {
        this.AngleAdjust = this.provider.AngleAdjust;               // angleAdjust can depend on the values reported by the sonar
        if (!this.timer) {                                          // use a timer to make certain we only update the ui in steps, not at every change.
            this.timer = setTimeout(() => {
                this.timer = null;
                if (this.nextData) {                                  // if there are new values to send, do it now
                    this.data = this.nextData.data;
                    this.roll = this.nextData.roll;
                    this.range = this.nextData.range;
                    this.yaw = this.nextData.yaw;
                    this.events.emit("onSonarData");
                    this.nextData = null;
                }
            }, 150);                                                // don't update the ui too often.
            this.data = values.data;
            this.roll = values.roll;
            this.range = values.range;
            this.yaw = values.yaw;
            this.events.emit("onSonarData");
        }
        else {
            this.nextData = values;
        }
    }


    /**value that should be used as the base line (0)*/
    get lowCutoff() {
        return this._lowCutoff;
    }
    set lowCutoff(value) {
        this._lowCutoff = value;
        configService.set("sonar.lowCutoff", value);
        this.events.emit("onSonarConfig");
    }

    /**value that should be used as the top line (255)*/
    get highCutoff() {
        return this._highCutoff;
    }
    set highCutoff(value) {
        this._highCutoff = value;
        configService.set("sonar.highCutoff", value);
        this.events.emit("onSonarConfig");
    }

    /**value that should be used as the top line (255)*/
    get grayMode() {
        return this._grayMode;
    }
    set grayMode(value) {
        this._grayMode = value;
        configService.set("sonar.grayMode", value);
        this.events.emit("onSonarConfig");
    }

}

export const sonarService = new SonarService();