/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import ChevronDown from 'mdi-material-ui/ChevronDown';
import StrengDetailsIcon from 'mdi-material-ui/FormatListText';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MainInfo from './main_info_comonent';
import Strengs from './strengs/strengs_component';
import { activeProjectService } from '../../../services/active_project_service';
import { projectService } from '../../services/project_service';
import { configService } from '../../../services/config_service';
import { documentControlService } from '../document_control_service';
import { withTranslation } from 'react-i18next';
import { dialogService } from '../../../services/dialog_service';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import StrengsSummaryDialog from './strengs/strengs_summary_dialog';



const styles = (theme) => ({
    root: {
        height: '100%',
        overflow: 'auto',
        paddingBottom: '1px'                    // to show the bottom line properly
    },
    rootWithPadding: {
        height: '100%',
        overflow: 'auto',
        paddingRight: theme.spacing(1) 
    },
    paper: {
        height: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: "bold"
    },
    strengsDetails: {
        flexDirection: 'column'
    }
});


function isOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

class Inspection extends React.PureComponent {
    constructor(props) {
        super(props);
        let data;
        if (activeProjectService.data) {
            data = activeProjectService.data
        }
        else {
            data = {main: {}, strengs: []};
        }
        const expandedIdx = (documentControlService.documentPos && documentControlService.documentPos.inspectionExpIdx) != undefined ? documentControlService.documentPos.inspectionExpIdx : 'panel1';
        this.state = {
            expanded: expandedIdx,
            inOverflow: false,
            showMissingErrors: false,                                       //when true, user tried to switch, but not allowed, so show all (missing) errors
            data: data,
            showSummary: false                                              // when true, the strengs summary popup is shown.
        }
        this.rootRef = React.createRef();
    }


    componentDidMount() {
        this.checkOverflow();
        if (activeProjectService.data) {
            this.setState({
                data: activeProjectService.data
            });
        }
        activeProjectService.events.addListener("obsGenerated", this.handleObsGenerated);
    }

    componentWillUnmount() {
        if (documentControlService.documentPos) {
            documentControlService.documentPos["inspectionExpIdx"] = this.state.expanded;
        }
        activeProjectService.events.removeListener("obsGenerated", this.handleObsGenerated);
    }

    checkOverflow = () => {
        const root = this.rootRef.current;
        if (root) {
            setTimeout(() => {
                this.setState({inOverflow: isOverflown(root)});
            }, 250);
        }
    }


    render() {
        const { t } = this.props;
        let rootClass = this.props.classes.root;
        if (this.state.inOverflow) {
            rootClass = this.props.classes.rootWithPadding;
        }
        return (
            <div className={rootClass} ref={this.rootRef} >
                <ExpansionPanel expanded={this.state.expanded === 'panel1'} onChange={this.switchExpanded('panel1')}>
                    <ExpansionPanelSummary
                        expandIcon={<ChevronDown />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className={this.props.classes.heading}>{t("Hoofd gegevens")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <MainInfo data={this.state.data.main} 
                            showMissingErrors={this.state.showMissingErrors}
                            readOnly={this.props.readOnly}
                            />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.expanded === 'strengs'} onChange={this.switchExpanded('strengs')}>
                    <ExpansionPanelSummary
                        expandIcon={<ChevronDown />}
                        aria-controls="strengs"
                        id="strengs"
                    >
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography className={this.props.classes.heading}>{t("Strengs / putten")}</Typography>
                            <Tooltip title={t("Show streng summary")}>
                                <IconButton 
                                    style={{marginLeft: '4px'}}
                                    onClick={this.openSummaryDialog}
                                    aria-label="show summary"
                                    size='small'
                                    color="default">
                                    <StrengDetailsIcon style={{height: '14px', width: '14px'}}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={this.props.classes.strengsDetails}>
                        <Strengs onExpandChanged={this.checkOverflow} 
                            strengs={this.state.data.strengs}
                            width={this.props.width}
                            direction={this.props.direction} 
                            onDirectionChanged={this.props.onDirectionChanged}
                            readOnly={this.props.readOnly}
                            captureKeyboard={this.state.expanded === 'strengs'}
                            onPositionChanged={this.props.onPositionChanged}
                            onStartMeasure={this.props.onStartMeasure}
                            onStopMeasure={this.props.onStopMeasure}
                            onStartMeasureAngle={this.props.onStartMeasureAngle}
                            onStartMeasureDepth={this.props.onStartMeasureDepth}
                            onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                            onStartMeasureFreeform={this.props.onStartMeasureFreeform}
                            onTerminated={this.props.onTerminated}
                            initLoaded={this.props.initLoaded}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <StrengsSummaryDialog open={this.state.showSummary} 
                    strengs={this.state.data.strengs}
                    main={this.state.data.main}
                    onClose={ this.handleCloseSummaryDialog }
                />
            </div>
        );
    }

    openSummaryDialog = (ev) => {
        this.setState({showSummary: true});
        ev.stopPropagation();
    }

    handleObsGenerated = () => {
        if (this.state.expandedIdx !== 'strengs') {                 // make certaint aht the input is visible
            this.setState({expanded: 'strengs'});   
        }
    }

    handleCloseSummaryDialog = (result)=> {
        this.setState({showSummary: false});
        if (result > -1) {
            activeProjectService.activeStrengIdx = result;
            this.setState({expanded: 'strengs'});               // need to make certain that the strengs are visiable when selecting an item from the list.
        }
    }

    switchExpanded = (panelName) => (event, isExpanded) => {
        const { t } = this.props;
        if (this.state.expanded == "panel1" && (panelName != "panel1" || isExpanded === false)) {   // when go from main to strengs (or close main), check if allowed.
            const onlyAllowStrengsOnFullMain = configService.get('strengs.onlyAllowStrengsOnFullMain') && this.props.readOnly === false;  // viewer can always continue to the next section. All read-only is allowed to continue
            if (onlyAllowStrengsOnFullMain && projectService.isMainComplete(this.state.data.main) === false) {
                this.setState({showMissingErrors: true});
                dialogService.error(t("Hoofd gegevens"), t("Er ontbreekt nog data in de hoofd gegevens sectie. Je kan pas verder gaan als alles is ingevuld"));
                return;
            }
        }
        this.setState({ expanded: isExpanded ? panelName : false, showMissingErrors: false });
        this.checkOverflow();
    }
}

Inspection.propTypes = {
    direction: PropTypes.string,                            // allowed values: 
    readOnly: PropTypes.bool,
    onDirectionChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,                   // when this observation is expanded, let the system go to the video point at which it was recorded.
    onStartMeasure: PropTypes.func,                 // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                  // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func,
    width: PropTypes.any,                           // this is passed on to strengs so that the selected tab can be brought into view again.
    onTerminated: PropTypes.func,                   // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
    initLoaded: PropTypes.bool,                     // an observation can only be rendered when the tractor engine is loaded (buttons for sonar and lidar)
};

export default withTranslation()(withStyles(styles)(Inspection));