/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2022] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withTranslation } from 'react-i18next';
import { documentControlService } from '../../document_control_service';

const styles = (theme) => ({
    root: {
    },

    row2: {
        display: 'flex', 
        flexDirection: 'row', 
        margiTop: theme.spacing(2),
    },
    leftInput: {
        marginRight: theme.spacing(2),
    }
   
});




class LidarVerifyDimensionsDlg extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            prevWidth: 0,
            prevHeight: 0,
            open: false
        }
        this.entryDoneResolver = null;                         // callback assigned when we have a trigger to open
    }

    componentDidMount() {
        documentControlService.onVerifyDimensions = this.handleVerifyDimensions;
    }

    componentWillUnmount() {
        documentControlService.onVerifyDimensions = null;
    }

    render() {
        if (!this.state.open) {
            return null;
        }
        const { t, classes } = this.props;
        return (
            <Dialog
                maxWidth="xs"
                onClose={this.handleCancel}
                aria-labelledby="verifyDimensions-dialog-title"
                open={this.state.open}
            >
                <DialogTitle id="verifyDimensions-dialog-title">{t("Verify strand dimensions")}</DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <TextField label={t("Old width")}
                            className={classes.leftInput}
                            type="number"
                            id="oldWidth"
                            disabled
                            value={this.state.prevWidth}
                            InputLabelProps={{shrink: true}}
                            InputProps={{endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                        onFocus: this.handleFocused}}
                        />

                        <TextField label={t("New width")}
                            type="number"
                            autoFocus
                            value={this.state.width}
                            onChange={this.handleChangeWidth}
                            InputLabelProps={{shrink: true}}
                            onKeyDown={this.handleKeyboard}
                            InputProps={{endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                        onFocus: this.handleFocused}}
                        />
                    </div>

                    <div className={classes.row2}>
                        <TextField label={t("Old height")}
                            className={classes.leftInput}
                            type="number"
                            disabled
                            value={this.state.prevWidth}
                            InputLabelProps={{shrink: true}}
                            InputProps={{endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                        onFocus: this.handleFocused}}
                        />

                        <TextField label={t("New height")}
                            type="number"
                            value={this.state.height}
                            onChange={this.handleChangeHeight}
                            InputLabelProps={{shrink: true}}
                            onKeyDown={this.handleKeyboard}
                            InputProps={{endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                        onFocus: this.handleFocused}}
                        />
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary" id="cancel">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={this.handleOk} color="primary" id="ok">
                        {t("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleFocused = (ev) => {
        ev.target.select();                          // selec the whole text when focused
    }

    handleChangeWidth = (ev) => {
        this.setState({width: +ev.target.value});
    }

    handleChangeHeight = (ev) => {
        this.setState({height: +ev.target.value});
    }

    handleCancel = () => {
        this.setState({open: false});
        if (this.entryDoneResolver) {
            this.entryDoneResolver(null);
        }
    }

    handleOk = () => {
        this.setState({open: false});
        if (this.entryDoneResolver) {
            this.entryDoneResolver({width: this.state.width, height: this.state.height});
        }
    }

    handleKeyboard = (e) => {
        if (e.key == "Enter") {
            this.handleOk();
            e.preventDefault();
            e.stopPropagation();  
        }
    }

    handleVerifyDimensions = async (prevWidth, prevHeight, width, height) => {
        let waitForHandler = new Promise((resolve) => {
            this.entryDoneResolver = resolve;
        });
        this.setState({open: true, prevWidth, prevHeight, width, height});
        let result = await waitForHandler;                           // wait till the entry has been fully completed. this allows us to block the start of recording until the user is ready.
        return result;
    }

}

LidarVerifyDimensionsDlg.propTypes = {
};

LidarVerifyDimensionsDlg.defaultProps = {
    
}

export default withTranslation()(withStyles(styles)(LidarVerifyDimensionsDlg));