/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons 
import FormatAlignBottom from 'mdi-material-ui/FormatAlignBottom';
import FormatAlignTop from 'mdi-material-ui/FormatAlignTop';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography'; 
import i18n from "i18next";


const styles = (theme) => ({
    root: {
        
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "600"
    },

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class ObservationsTitleBox extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    render() {
        const { t } = this.props;
        let detailsBtn = null;
        let detailsBtnTip = null;
        if (this.props.showObsDetails) {
            detailsBtn = <FormatAlignTop style={{height: '14px', width: '14px'}}/>;
            detailsBtnTip = i18n.t("Hide the descriptions of the observations");
        }
        else {
            detailsBtn = <FormatAlignBottom style={{height: '14px', width: '14px'}}/>;
            detailsBtnTip = i18n.t("Show the descriptions of the observations");
        }
        return (
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography className={this.props.classes.heading}>{i18n.t("Waarnemingen")}</Typography>
                <Tooltip title={detailsBtnTip}>
                    <IconButton 
                        style={{marginLeft: '4px'}}
                        aria-label="show detail"
                        size='small'
                        onClick={this.props.onToggleObsDetails}
                        color="default">
                        {detailsBtn}
                    </IconButton>
                </Tooltip>
            </Box>
        );
    }
}

ObservationsTitleBox.propTypes = {
    onToggleObsDetails: PropTypes.func,         // called when icon to toggle obs details is pressed
    showObsDetails: PropTypes.bool,              // value of icon button
}

export default withStyles(styles)(ObservationsTitleBox);