/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

 //icons
 import Link from 'mdi-material-ui/Link';
 import LinkOff from 'mdi-material-ui/LinkOff';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import { activeProjectService } from '../../../../services/active_project_service';
import Tooltip from '@material-ui/core/Tooltip';
import { documentControlService } from '../../document_control_service';
import Grow from '@material-ui/core/Grow';
import { hhmmss, hhmmssToSec } from '../../../services/time';
import { configService } from '../../../../services/config_service';


const styles = (theme) => ({
    root: {
        marginLeft: theme.spacing(2), 
        marginRight: theme.spacing(1)
    },
    locationInput: {
        borderBottomWidth: '0px',
        borderBottomColor: 'gray',
        "&:hover": {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid'    
        }
    },
    locationInputError: {
        borderBottomColor: 'red',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid'  
    },
    focusedLocationInput: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid'
    }, 
    focusedLocationError: {
        borderBottomWidth: '1px',
        borderBottomColor: 'red',
        borderBottomStyle: 'solid'
    }
});


/**
 * UI element for managing the currently active user: config, logout..
 */
class LocationEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        const distance = props.data ? props.data.longitude : 0;
        const time = props.data ? props.data.time : 0;
        this.state = {
            distance: distance.toFixed(2),
            time: hhmmss(time),
            errorDistance: this.getErrorStateDistance(distance),
            errorTime: this.getErrorStateTime(time),
            linked: configService.get("observations.distanceTimeEditLinked"),
            hasFocus: false
        }
    }

    componentDidMount() {
        this.updateErrors();                                    // need to update erros after mount cause othrewise the active-streng hasnt' updated yet and we get an incorrect error
    }
    

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.updateErrors();
        }
    }

    updateErrors() {
        const distance = this.props.data ? this.props.data.longitude : 0;
        const time = this.props.data ? this.props.data.time : 0;
        this.setState({distance: distance.toFixed(2), 
            time: hhmmss(time),
            errorDistance: this.getErrorStateDistance(distance),
            errorTime: this.getErrorStateTime(time),
        });
    }

    handleStrengChanged = (streng) => {
        this.updateErrors();
    }

    render() {
        const { t } = this.props;
        let tooltipDistance = (this.state.errorDistance) ? this.state.errorDistance : t("De afstand in meter waarop de waarneming werd gemaakt");
        let tooltipTime = (this.state.errorTime) ? this.state.errorTime : t("The time in the video at which the observation occured");

        let linkIcon;
        if (this.state.linked) {
            linkIcon = <Link style={{height: '14px', width: '14px'}}/>
        }
        else {
            linkIcon = <LinkOff style={{height: '14px', width: '14px'}}/>
        }
        const defaultInputClass = this.state.hasFocus ? this.props.classes.focusedLocationInput : this.props.classes.locationInput;
        const inputTagClassesDistance = {
            root: this.state.errorDistance ?  this.props.classes.locationInputError : defaultInputClass, 
            focused: this.state.errorDistance ? this.props.classes.focusedLocationError : this.props.classes.focusedLocationInput
        };
        const inputTagClassesTime = {
            root: this.state.errorTime ?  this.props.classes.locationInputError : defaultInputClass, 
            focused: this.state.errorTime? this.props.classes.focusedLocationError : this.props.classes.focusedLocationInput
        };
        
        const inputPropsTime = this.buildInputProps(this.state.time, false);

        const inputPropsDistance = this.buildInputProps(this.state.distance, true);
        const endAdornerDistance = this.state.hasFocus ? <InputAdornment position="end">m</InputAdornment>  : null;

        return (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" 
                marginRight="8px"
                onFocus={() => this.setState({hasFocus: true})} 
                onBlur={() => this.setState({hasFocus: false})}>
                <Tooltip title={tooltipDistance}>
                    <InputBase 
                        inputRef={this.props.navDistance}
                        label={t("Afstand")}
                        endAdornment={endAdornerDistance}
                        value={this.state.distance} 
                        onChange={this.handleDistanceChanged}
                        onFocus={this.props.onFocus} 
                        classes={inputTagClassesDistance}
                        inputProps={inputPropsDistance}
                        readOnly={this.props.readOnly}
                    />
                </Tooltip>
                
                <Grow in={this.state.hasFocus} unmountOnExit={true} timeout={500}>
                    <Box display="flex" flexDirection="row" alignItems="center" marginRight="8px">
                        <Tooltip title={t("distanceTimeLinkBtnTip")}>
                            <IconButton style={{marginLeft: '4px'}}
                                size='small'
                                onClick={this.toggleLinked}
                                disabled={this.props.readOnly}
                                aria-label="link">{linkIcon}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={tooltipTime}>
                            <InputBase 
                                inputRef={this.props.navTime}
                                label={t("Time")}
                                endAdornment={<InputAdornment position="end">h:m:s</InputAdornment>}
                                value={this.state.time} 
                                onChange={this.handleTimeChanged}
                                onFocus={this.props.onFocus} 
                                classes={inputTagClassesTime}
                                inputProps={inputPropsTime}
                                readOnly={this.props.readOnly}
                            />
                        </Tooltip>
                    </Box>
                </Grow>
            </Box>
            
        );
    }

    toggleLinked = () => {
        const newValue = !this.state.linked;
        configService.set("observations.distanceTimeEditLinked", newValue);
        this.setState({linked: newValue});
    }

    buildInputProps(value, asFirst) {
        const charLength = `${value}`.length;                // 2 extra for the units
        const minWidth = (asFirst && this.state.hasFocus === false) ? '6ch' : '1ch';
        const inputProps = {
            style: {
                width: `${charLength}ch`, 
                minWidth: minWidth, 
                boxSizing: 'border-box',
                textAlign: 'right'
            }, 
            'aria-label': 'location',
            type: "text"
        }
        //if (!asFirst) {
        //    inputProps['pattern'] = '^((?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$)';
       // }
        return inputProps;
    }

    handleDistanceChanged = (ev) => {
//        const { t } = this.props;
        let value = ev.target.value;
        this.setState({distance: value, errorDistance: this.getErrorStateDistance(value)});                              // must be done before modifying value (change to int), otherwise it becomes hard to enter values like '1.2' -> fails at '1.'
        if (!isNaN(value)) {
            value = +value;                                         // turn into number
            this.props.data.longitude = value;                    // always store as number, cause we do some calculations on this.
            if (this.state.linked) {
                const time = documentControlService.distanceToTime(value);
                this.props.data.time = time;
                this.setState({time: hhmmss(time)});
                if (this.props.onTimeChanged) {
                    this.props.onTimeChanged(time);
                }
            }
            if (this.props.onDistanceChanged) {
                this.props.onDistanceChanged(value);
            }
            activeProjectService.markDirty();
        }
    }

    handleTimeChanged = (ev) => {
        //const { t } = this.props;
        const stringValue = ev.target.value;
        let value = hhmmssToSec(stringValue);
        this.setState({time: stringValue, errorTime: this.getErrorStateTime(value, stringValue)});                              // must be done before modifying value (change to int), otherwise it becomes hard to enter values like '1.2' -> fails at '1.'
        if (value !== null && !isNaN(value)) {              // when null, could not be converted, so don't do anything with the value.
            this.props.data.time = value;                    // always store as number, cause we do some calculations on this.
            if (this.state.linked) {
                const distance = documentControlService.timeToDistance(value);
                this.props.data.longitude = distance;
                this.setState({distance: distance.toFixed(2)});
                if (this.props.onDistanceChanged) {
                    this.props.onDistanceChanged(value);
                }
            }
            if (this.props.onTimeChanged) {
                this.props.onTimeChanged(value);
            }
            activeProjectService.markDirty();
        }
    }

    getErrorStateDistance(value) {
        const { t } = this.props;
        if (isNaN(value)) {
            return t("number expected");
        }
        else {
            value = +value;                                         // turn into number
            let max = 0;                                                                                        // normally there should always be a streng, if there isn't, don't allow location change
            const streng = activeProjectService.activeStreng;
            if (streng) {
                max = +streng.actualInspectionLength > 0 ? +streng.actualInspectionLength : +streng.expectedInspectionLength;
            }
            if (value < 0 || value > max ) {
                return t("out of range", {min: 0, max: max});
            }
            else {
                return null;
            }
        }
    }

    getErrorStateTime(value, stringValue) {
        const { t } = this.props;
        if (stringValue !== undefined) {                                                                    // no need to check string value if it wasn't entered (and thus not defined). could also be called for the init state, which is from the number
            if (/^((?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$)/.test(stringValue) === false) {
                return t("time_expected");
            }
        }
        let max = 0;                                                                                        // normally there should always be a streng, if there isn't, don't allow location change
        const streng = activeProjectService.activeStreng;
        if (streng) {
            max = streng.videoLength > 0 ? +streng.videoLength : documentControlService.currentTime;                  // while recording, the streng doesn't yet know the videolength, but various other locations already know the current timestamp, which is the current max, so use that.
        }
        if (value < 0 || value > max ) {
            return t("out of range", {min: "00:00:00", max: hhmmss(max)});
        }
        else {
            return null;
        }
    }
}

LocationEditor.propTypes = {
    data: PropTypes.object,
    onDistanceChanged: PropTypes.func,                          // called when the duration part has changed, so the observation can update it's internal value (for read-only)
    onTimeChanged: PropTypes.func,                              // called when the time part has changed.
    onFocus: PropTypes.func,
    navDistance: PropTypes.any,                                         // the ref object to attach to the distance-input, so the parent can do navigation
    navTime: PropTypes.any,                                         // the ref object to attach to the time-input, so the parent can do navigation
    readOnly: PropTypes.bool,                           // for viewer
};

export default withTranslation()(withStyles(styles)(LocationEditor));