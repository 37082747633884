/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { lidarService } from './lidar_service';
import { distance, getCircumference, measureScrollbarWidth, PI } from '../../../services/cal';
import { buildLidarRolloutColorConverter, buildValueColorConvertor, drawLidarRollout } from './imaging_service';
import { documentControlService } from '../../document_control_service';
import { StrandPropsService } from '../../../services/strand_props_service';
import { distanceService } from '../../../services/distance_service' 
import CircularProgress from '@material-ui/core/CircularProgress';
import { timeService } from '../../../services/time_service';
import LidarRolloutMeasureCanvas from './lidar_rollout_measure_canvas_component';


const styles = (theme) => ({
    root: {
        height: '100%',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        borderColor: "lightgray",
        borderStyle: "solid",        
        borderWidth: "0.1px",
    }, 

});

const MIN_DISTANCE = 0.5;                         // min distance that crawler needs to travel for the lidar record to be a valid minimum distance (long-pass-filter)


/**
 * UI element for managing the currently active user: config, logout..
 */
class LidarRollout extends React.PureComponent {
    constructor(props) {
        super(props);

        this.hiddenCanvasRef = React.createRef();                               // for renering parts of the image and resizing it.
        this.canvasRef = React.createRef();
        this.rootRef = React.createRef();
        this.scrollboxRef = React.createRef();                                  // so we can set the scrollpos programmatorically
        this.timeCanvasRef = React.createRef();                                  // to draw the time indicator
        this.measureCanvasRef = React.createRef();                               // to draw measurement lines

        this.colorDict = null;                                                  // mapping to use between values and colors
        if (props.colors && props.colorStops && props.colors.length > 0 && props.colorStops.length > 0) {
            this.colorDict = buildLidarRolloutColorConverter(props.grayMode, props.colors, props.colorStops);
        }
        this.state = {
            canvasHeight: 0,                                                    // height without zoom, based on 360 degrees, but if there are multiple diameters in a single tube, also depending on the diameters relative to each other
            canvasWidth: 0,                                                     // width without zoom, based on length of inspection (1 pixel = 1mm)
            isLoading: documentControlService.robotDs?.isLoading ? true : false,  // when true, loading data from the robot
        }
        this.scrollStart = 0;                                                     // the position in px (and mm) to start drawing from (zoom not yet applied)
        this.renderMap = null;                                                    // determins the blocks that need to be rendered, based on changes in the width/height of the strand
        this.distancePerPixel = 0;                                                  // size in millimeters per pixel
        this.liveRefresher = null;                                                  // timeout handler, for when we are showing live data
        this.prevLiveDistance = 0;                                                // the distance at which the previous lidar record was received, so we can calculate  difference

        this.curTime = null;                                                       // curren play time pos. Using time, less nr of jumps
        this.curTimeIdx = -1;                                                   // index of data line that is currently active, based on the current time

        this._normalizedData = null;                                                // need to make certain we don't have records that go back and stop at the max distance
        this.blockTimeUpdate = false;                                           // when jumping to a position, we get a more accurate value from teh actual pos, so when this happens, block a timeupdate


        this.scrollbarSize = measureScrollbarWidth();
    }

    componentDidMount() {
        if (this.props.liveFeed) {
            lidarService.events.on('onLidarData', this.handleLidarData);
        }
        lidarService.events.addListener('onGoto', this.handleGotoDistance);
        documentControlService.events.addListener('onRobotDataLoaded', this.handleRobotReady);
        documentControlService.events.addListener('onRobotDataLoading', this.handleRobotLoading);
        documentControlService.events.addListener('onCurrentTime', this.handleCurrentTimeChanged);
        this.buildRenderMap();
        this.setImageSize();
        this.setTimeCanvasSize();
        this.draw();
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onRobotDataLoaded', this.handleRobotReady);
        documentControlService.events.removeListener('onRobotDataLoading', this.handleRobotLoading);
        documentControlService.events.removeListener('onCurrentTime', this.handleCurrentTimeChanged);
        lidarService.events.removeListener('onGoto', this.handleGotoDistance);
        if (this.props.liveFeed) {
            lidarService.events.removeListener('onLidarData', this.handleLidarData);
        }
        if (this.liveRefresher) {                                               // so we don't accidentally repaint when already unloaded
            clearTimeout(this.liveRefresher);
            this.liveRefresher = null;
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        let needsDraw = false;
        let needsBuildRenderMap = false;
        let needsSetImageSize = false;
        let needsRobotRefresh = false;
        let needsResetTime = false;
        if (prevProps.liveFeed !== this.props.liveFeed)  {
            if (this.props.liveFeed && this.props.controlState !== "reverseTractor") {
                lidarService.events.on('onLidarData', this.handleLidarData);
                needsRobotRefresh = true;
            }
            else {
                lidarService.events.removeListener('onLidarData', this.handleLidarData);
                needsResetTime = true;
                needsBuildRenderMap = true;
                needsDraw = true;
            }
            this.curTimeIdx = -1;
            this.prevLiveDistance = 0;
        }
        else if (prevProps.streng !== this.props.streng) {
            this.curTimeIdx = -1;
            this.curTime = null;
            this.prevLiveDistance = 0;
            if (this.props.liveFeed) {
                needsRobotRefresh = true;
            }
            else {
                needsBuildRenderMap = true;
                needsDraw = true;
            }
        }
        if (prevProps.controlState !== this.props.controlState && this.props.controlState === "reverseTractor" && this.props.liveFeed) {
            lidarService.events.removeListener('onLidarData', this.handleLidarData);
        }
        if (prevProps.grayMode !== this.props.grayMode || prevProps.colors !== this.props.colors || prevProps.colorStops !== this.props.colorStops) {
            this.colorDict = buildLidarRolloutColorConverter(this.props.grayMode, this.props.colors, this.props.colorStops);
            needsDraw = true;
        }
        if (prevProps.filterRange !== this.props.filterRange) {
            needsDraw = true;
        }
        if (prevProps.verSplit !== this.props.verSplit) {
            if (this.props.liveFeed && prevProps.verSplit !== 0) {
                const diff = (prevProps.verSplit - this.props.verSplit) * 100 / this.getZoomValue();
                const newCanvasWidth = this.state.canvasWidth - diff;
                if (this.state.canvasWidth !== newCanvasWidth) {
                    ///console.log(newCanvasWidth);
                    this.setState({canvasWidth: newCanvasWidth});
                }
            }
            needsDraw = true;
            this.setTimeCanvasSize();
            this.realignMeasureCanvas();
        }
        if (prevProps.snapToHeight !== this.props.snapToHeight || prevProps.zoom !== this.props.zoom) {     // when zoom changes, need to rese the canvas size cause there can be a scrollbar added/removed
            needsDraw = true;
            this.setTimeCanvasSize();
            this.realignMeasureCanvas();
        }
        if (prevProps.zoom !== this.props.zoom) {
            const clientWidth = this.rootRef.current ? this.rootRef.current.clientWidth : Number.MAX_SAFE_INTEGER;          // when not yet rendered, don't show a scrollbar yet
            const zoomValue = this.getZoomValue();
            let newCanvasWidth = this.state.canvasWidth;
            if (this.props.liveFeed) {
                const prevAdjustedWindowSize = clientWidth * 100 / this.getZoomValueFrom(prevProps.zoom);
                const newAdjustedWindowSize = clientWidth * 100 / zoomValue;
                newCanvasWidth = this.state.canvasWidth - prevAdjustedWindowSize + newAdjustedWindowSize;
            }
            const needsScrollbar = (newCanvasWidth * zoomValue / 100) > clientWidth;
            const scrollbar = this.scrollboxRef.current;
            needsDraw = true;
            if (this.canvasWidth !== newCanvasWidth) {
                this.setState({canvasWidth: newCanvasWidth});
            }
            if (needsScrollbar && scrollbar) {
                scrollbar.scrollLeft = scrollbar.scrollLeft / prevProps.zoom * zoomValue;
            }
            else if (!needsScrollbar) {                         // when no scrollbar is needed, make certain that scrollstart is reset cause when we zoom and go from 'needing scroll' to 'not needing scroll', this value remains stuck, which screws up the drawing (missing left part)
                this.scrollStart = 0;
            }
        }
        if (prevProps.allowedHeight !== this.props.allowedHeight) {
            needsDraw = true;
            this.setTimeCanvasSize();
            this.realignMeasureCanvas();
        }
        if (prevState.canvasWidth !== this.state.canvasWidth && this.props.liveFeed) {
            const clientWidth = (this.rootRef.current ? this.rootRef.current.clientWidth : 0) * this.distancePerPixel;
            const zoomedCanvasWidth = this.state.canvasWidth * this.getZoomValue() / 100;
            const newScrollPos = zoomedCanvasWidth - clientWidth;
            const scrollbar = this.scrollboxRef.current;
            if (scrollbar) {
                scrollbar.scrollLeft = newScrollPos;
            }
            this.realignMeasureCanvas();
        }
        if (prevProps.showReverse !== this.props.showReverse) {
            this._normalizedData = null;                                                // needs to be reloaded
            await lidarService.loadReverseProvider(this.props.streng);
            needsRobotRefresh = true;
        }
        if (needsRobotRefresh) {
            this.handleRobotReady();                                                    // not triggered when in live mode.
        }
        else {
            if (needsBuildRenderMap) {
                this.buildRenderMap();
                this.setImageSize();
            }
            if (needsSetImageSize) {
                this.setImageSize();
            }
            if (needsDraw || this.state.canvasWidth !== prevState.canvasWidth) {                        // when an already loaded streng changes the datasource of a robot-view, this component gets loaded while the data is already available. In this case, canvasWidth is set after the last draw, which screws up the render, so need to re-render after the canvasWidth was set
                this.draw();
                this.drawTimeIndicator();
            }
            if (needsResetTime) {
                this.handleCurrentTimeChanged(timeService.current) ;
            }
        }
    }

    render() {
        const { t, classes } = this.props;

        let spinnerText = null;
        if (this.state.isLoading) {
            spinnerText = t("Loading");
        }
        else if (this.props.controlState === 'reverseTractor') {
            spinnerText = t("Recording");
        }
        if (spinnerText) {
            return(
                <div className={classes.root} style={{justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress color="secondary" />
                    <div>{spinnerText}</div>
                </div>
            );
        }
        const zoomValue = this.getZoomValue();
        const scrollbarSize = `${this.scrollbarSize}px`;
        const root = this.rootRef.current;
        const clientWidth = root ? root.clientWidth : Number.MAX_SAFE_INTEGER;          // when not yet rendered, don't show a scrollbar yet
        const clientHeight = root ? root.clientHeight : Number.MAX_SAFE_INTEGER;          // when not yet rendered, don't show a scrollbar yet
        const needsScrollbar = (this.state.canvasWidth * zoomValue / 100) > clientWidth;
        const needsVScrollbar = (this.state.canvasHeight * zoomValue / 100) > clientHeight;
        const canvasParentStyle = {position: 'absolute', overflowY: 'auto', overflowX:'hidden', left: '0px', right: '0px', top: '0px', bottom: needsScrollbar ? scrollbarSize : '0px'};
        const timeCanvasStyle = Object.assign({pointerEvents: 'none'}, canvasParentStyle);
        const overlayStyle = Object.assign({}, canvasParentStyle);
        overlayStyle.right = needsVScrollbar ? scrollbarSize : '0px';
        timeCanvasStyle.right = overlayStyle.right;
        let canvasStyle = null;
        let showScrollFiller = false;
        let hasVerScroll = false;
        if (this.props.snapToHeight) {
            canvasParentStyle['display'] = 'flex';
            showScrollFiller = needsScrollbar;
        }
        else {
            const canvasHeight = this.state.canvasHeight * zoomValue / 100;
            canvasStyle = { marginTop: 'auto', marginBottom: 'auto', position: 'absolute', display: 'block', height: canvasHeight};
            if (this.props.allowedHeight > canvasHeight) {
                canvasStyle = Object.assign(canvasStyle, {top: '0px', bottom: '0px'});
            }
            if (root) {
                hasVerScroll = canvasHeight > root.clientHeight;
            }
        }
        return (
            <div className={classes.root} >
                <div style={{flex: 1}} >
                    <div style={canvasParentStyle} ref={this.rootRef} onScroll={this.handleVScroll}>
                        <canvas ref={this.canvasRef} style={canvasStyle} onClick={this.handleGotoPos}/>
                        {(showScrollFiller) &&
                            <div id="fillerToKeepScrollInView" style={{height: scrollbarSize}}/>
                        }
                    </div>
                    {/* separate canvas for time so that the line isn't effected by the zoom and we don't have to worry about what is below the line */}
                    <canvas ref={this.timeCanvasRef} style={timeCanvasStyle}/>
                    <canvas ref={this.hiddenCanvasRef} style={{display: 'none'}} />
                    {/** canvas component for drawing selections to measure on the rollout */}
                    <LidarRolloutMeasureCanvas ref={this.measureCanvasRef} 
                        rootStyle={overlayStyle} 
                        showMeasDistRollout={this.props.showMeasDistRollout} 
                        showMeasAngleRollout={this.props.showMeasAngleRollout}
                        measureData={this.props.measureData} 
                        onMeasure={this.handleMeasureDistance} 
                        onRellToAbsCoord={this.handleRellToAbsCoord} 
                        onAbsToRelCoord={this.handleAbsToRelCoord}
                    />
                </div>
                {(needsScrollbar) &&
                    <div style={{overflowY:"auto", overFlowX: 'hidden', position: 'absolute', left: '0px', right: hasVerScroll ? scrollbarSize : '0px', bottom: '0px'}}
                        onScroll={this.handleScroll}
                        ref={this.scrollboxRef}
                    >
                        {/* this div is needed to fill the space so that a scrollbar is rendered */}
                        <div id="sroll" style={{height: "1px", width: this.state.canvasWidth * zoomValue /100}}/>
                    </div>
                }
            </div>
        );
    }

    realignMeasureCanvas() {
        const ref = this.measureCanvasRef.current;
        if (ref && ref.recalculateRelCoords) {
            ref.recalculateRelCoords();
        }
    }

    handleScroll = (e) => {
        const element = e.target;
        this.scrollStart = element.scrollLeft;
        this.draw();
        this.drawTimeIndicator();
        this.realignMeasureCanvas();
    }

    handleVScroll = (e) => {
        this.realignMeasureCanvas();
    }

    setTimeCanvasSize() {
        const timeCanvas = this.timeCanvasRef.current;
        const root = this.rootRef.current;
        if (!timeCanvas || !root) return;
        timeCanvas.width = root.clientWidth;
        timeCanvas.height = root.clientHeight;
    }

    setImageSize() {
        const canvas = this.canvasRef.current;
        const root = this.rootRef.current;
        if (!canvas || !root) return;
        const { streng } = this.props;
        const data = this.getData();

        let height = 360;
        let smallest = Number.MAX_SAFE_INTEGER;                                 // the smallest will be set
        if (this.renderMap) {
            for(const map of this.renderMap) {
                const circumference = getCircumference(map.width, map.height, map.shape);
                map['circumference'] = circumference;
                if (circumference < smallest) smallest = circumference;
            }
            let largest = 0;
            let largestCircumference = 0;
            for(const map of this.renderMap) {
                map['canvasHeight'] = (height * map.circumference) / smallest;
                if (map.canvasHeight > largest) {
                    largest = map.canvasHeight;
                    largestCircumference = map.circumference;
                }
            }
            canvas.height = largest;
            let distancePerPixelHeight = largestCircumference / largest;

            let canvasWidth;
            if (data && data.length > 0) {                                                      // this is valid for livefeed as well, if there is already data recorded
                let deltaDistances = data.reduce((accum, rec, idx) => {
                    if (idx == 0) {
                        accum.push(rec.distance * 1000);                                        // robot distance is in meters, height/width is expressed in mm, so we need to cast everything to mm
                    }
                    else {
                        accum.push((rec.distance - data[idx-1].distance) * 1000);
                    }
                    return accum;
                }, []).filter(x => x > MIN_DISTANCE);
                let distancePerPixelWidth = Math.min.apply(null, deltaDistances);

                let result;
                if (distancePerPixelWidth < distancePerPixelHeight) {
                    result = distancePerPixelWidth;
                }
                else {
                    result = distancePerPixelHeight;
                }
                canvasWidth = (+streng.actualInspectionLength * 1000) / result;
                this.distancePerPixel = result;
            }
            else {
                this.distancePerPixel = 1;                                                      // initial value: 1mm, this is used for init value for live feed, can be updated while receiving data.
                canvasWidth = (this.props.liveFeed ? distanceService.current * 1000 : +streng.actualInspectionLength * 1000);
            }
            if (this.props.liveFeed && canvasWidth === 0) {
                const root = this.rootRef.current;
                const visibleWindowWidth = root.clientWidth * 100 / this.getZoomValue();
                canvasWidth = visibleWindowWidth;
            }
            if (this.state.canvasWidth !== canvasWidth) {                                  // 9only update when needed, this triggers a repaint
                this.setState({canvasWidth: canvasWidth});
            }
        }
    }

    getData() {
        if (this.props.showReverse) {
            return this.normalizedData(lidarService.reverseProvider?.data);
        }
        else {
            return this.normalizedData(lidarService.provider?.data);
        }
    }

    /**
     * rebuilds the data list for optimal lidar rendering:
     * - all reverting points are removed (distance < prev-distance)
     * - if the device prefers reverse recording, but data is of regular recording & data goes from distance 0 - max - 0 (or negative or nearby)
     *   use the reverse data at the end, cause this is better quality
     * 
     * also:
     * - keep track of nr of same consecutive distances, when more than 1, but not too many (standing still), distribute the next
     * distance evenly over all previously same distances so that they also become visible
     */
     normalizedData(data) {
        if (!this._normalizedData) {
            if (data && data.length > 0) {
                //data = this.findRetourData(data);
                let idx = 0;
                let prevDistance = data[0].distance
                let nrSameDistance = 0;
                let distFirstSample = 0;
                while (idx < data.length) {
                    const newDistance = data[idx].distance;
                    if (newDistance < prevDistance) {
                        data.splice(idx, 1);
                        nrSameDistance = 1;
                        distFirstSample = newDistance;
                    }
                    else {
                        const distDelta = newDistance - distFirstSample;
                        if (distDelta < MIN_DISTANCE) {
                            nrSameDistance++;
                        }
                        else {
                            if (nrSameDistance > 1) {
                                const toDistribute = distDelta / nrSameDistance;
                                for(let i=nrSameDistance; i > 0; i--) {
                                    data[idx-i].distance = distFirstSample + (toDistribute * (nrSameDistance - i));
                                }
                            }
                            nrSameDistance = 1;
                            distFirstSample = newDistance;
                        }
                        prevDistance = data[idx].distance;
                        idx++;
                    }
                }
                this._normalizedData = data;
            }
            else {
                this._normalizedData = null;
            }
        }
        return this._normalizedData;
    }

    findRetourData(data) {
        const max = Math.max(...data.map(rec => rec.distance));
        const maxIdxs = data.reduce((acc, rec, idx) => {
            if (rec.distance === max) {
                acc.push(idx);
            } 
            return acc;
        }, []);
        let newData = data.slice(maxIdxs[maxIdxs.length-1]);
        if (newData && newData.length > 0) {
            newData.reverse();
            const offset = newData[0].distance;
            newData.map((rec) => {
                rec.distance -= offset;
            });
            return newData;
        }
        return null;
    }


    /**
     * goes over the project and searches for changes in diameter (or others) of the tube and stores any
     * changes in a list of render instructions (start & end) so that things can be rendered quickly.
     */
    buildRenderMap() {
        const data = this.getData();
        const strandProps = new StrandPropsService();
        const streng = this.props.streng;
        strandProps.init(streng);
        let lastDataIdx = 0;
        let result = [{width: strandProps.width, height: strandProps.height, shape: strandProps.shape, start: 0, end: null}];
        if (!this.props.liveFeed && data) {
            strandProps.events.on('size', (obs) => {
                for(let d=lastDataIdx; d<data.length; d++) {
                    if (data[d].timestamp >= obs.time) {
                        lastDataIdx = d;
                        break;
                    }
                }
                result[result.length-1].end = lastDataIdx;
                result.push({width: strandProps.width, height: strandProps.height, shape: strandProps.shape, start: lastDataIdx, end: null})
            });
            for (let i=0; i<streng.observations.length; i++) {
                const obs = streng.observations[i];
                strandProps.updateFromObs(obs);
            }
            result[result.length-1].end = data.length;
        }
        this.renderMap = result;
    }


    /**
     * actual zoom depends on wether snapToHeight is set or not.
     * @returns actual zoom
     */
    getZoomValue() {
        if (this.props.snapToHeight) {
            const root = this.rootRef.current;
            const canvas = this.canvasRef.current;
            if (canvas && root) {
                return 100 / canvas.height * root.clientHeight;
            }
            else {
                return this.props.zoom;
            }
        }
        else {
            return this.props.zoom;
        }
    }

    getZoomValueFrom(from) {
        if (this.props.snapToHeight) {
            const root = this.rootRef.current;
            const canvas = this.canvasRef.current;
            return 100 / canvas.height * root.clientHeight;
        }
        else {
            return from;
        }
    }

    /**
     * draw the lidar rollout map, based on the current data.
     * returns the height of the canvas (nr of points). This can be used to calculate the zoom value.
     */
    draw() {
        let result = 0;
        const canvas = this.canvasRef.current;
        const hidden = this.hiddenCanvasRef.current;
        const root = this.rootRef.current;
        if (canvas && hidden && root && this.renderMap) {
            const data = this.getData();
            if (data && data.length > 0 && this.colorDict && this.colorDict.data) {
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                hidden.height = 360;
                let ctxHidden = hidden.getContext('2d');
                const zoom = this.getZoomValue();
                const clientWidth = root.clientWidth;
                canvas.width = Math.ceil(clientWidth * 100 / zoom);                               // make the canvas the same width as the root box so we render exactly the amount needed, need the ceilingso that we don't loose a litle with the rounding
                const startPos = this.scrollStart * 100 / zoom;
                const endPos = startPos + (canvas.width / this.distancePerPixel);
                //console.log("endpos: ", endPos);

                let x = 0;                                                                          // hor position at which to draw the next block
                for(let i=0; i<this.renderMap.length; i++) {
                    const curMap = this.renderMap[i];
                    let mapStartDistance = data[curMap.start].distance * 1000;
                    const mapEndDistance = ((!curMap.end || !data[curMap.end-1]) ? data[data.length-1].distance : data[curMap.end-1].distance) * 1000;    // curMap.end can be null for live feed.
                    //console.log("mapEndDistance: ", mapEndDistance, 'curmap.end: ', curMap.end);
                    if (endPos < mapStartDistance) {                                       // we have found the end
                        break;
                    }
                    else if (startPos < mapEndDistance) {                                // we have found beginning or a block that still fits
                        if (startPos < mapStartDistance) {                                      // the record data can start at 1, but scrollpos is at 0, so we need to use 0 meters, not 1
                            mapStartDistance = startPos;
                        }
                        const curRenderStart = startPos > mapStartDistance ? startPos : mapStartDistance;
                        const curRenderEnd = endPos < mapEndDistance ? endPos : mapEndDistance;
                        hidden.width = (curRenderEnd - curRenderStart) / this.distancePerPixel;
                        if (hidden.width > 0) {
                            ctxHidden.clearRect(0, 0, hidden.width, hidden.height);
                            drawLidarRollout(ctxHidden, hidden.width, data, curMap.start, curRenderStart / 1000, curRenderEnd / 1000, this.distancePerPixel, this.colorDict.data, this.props.filterRange);
                            const dy = (canvas.height - curMap.canvasHeight) / 2;
                            //console.log('x: ', x, 'width: ', hidden.width);
                            ctx.drawImage(hidden, x, dy, hidden.width, curMap.canvasHeight);               // copy the image so we can stretch it (for the height)
                            x += hidden.width;
                        }
                    }
                }
                result = canvas.height;
            }
            else {
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
        if (this.state.canvasHeight !== result) {
            this.setState({canvasHeight: result});
        }
    }

    /**
     * draws a vertical line to indicate the current position within the data.
     * this is done by drawing a vertical red line on the canvas. The data below the line
     * is copied and buffered so it can be redrawn fast. This way,
     * we don't need to redraw the entire picture when the time has changed, only 2 lines.
     */
    drawTimeIndicator(jumpToPos=false) {
        const canvas = this.timeCanvasRef.current;
        const scrollbar = this.scrollboxRef.current;
        const data = this.getData();
        const lidarCanvas = this.canvasRef.current;
        let result = null;
        if (data && data.length > 0 && canvas && lidarCanvas) {             // need to check for datalength, cause ohteriwese 0 length, but idx of 0 is being retrieved for the distance

            const curDistance = this.curTimeIdx > -1 ? data[this.curTimeIdx].distance * 1000 : 0;

            let distanceVisible = curDistance - (scrollbar ? (scrollbar.scrollLeft / this.getZoomValue() * 100) : 0);
            const drawLine = (distanceVisible >= 0 && distanceVisible <= lidarCanvas.width) || jumpToPos;
            if (distanceVisible < 0 || distanceVisible > lidarCanvas.width) {
                const scrolledAway = Math.trunc(curDistance / lidarCanvas.width) * lidarCanvas.width;
                distanceVisible = curDistance - scrolledAway;
                if (jumpToPos && scrollbar) {
                    scrollbar.scrollLeft = scrolledAway * this.getZoomValue() / 100;
                }
            }
            distanceVisible = Math.round(distanceVisible * this.getZoomValue() / 100);
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (drawLine) {
                ctx.beginPath();
                ctx.moveTo(distanceVisible, 0);
                ctx.lineTo(distanceVisible, canvas.height);
                ctx.stroke();
            }
        }
        return result;
    }

    handleLidarData = (values, angles) => {
        try {                                                           // event handler, need to make certain it doesn't crash other partsof the system
            if (documentControlService.controlState === "record") {                                         // only when recording, otherwise this view gets cluttered
                const curDistance = distanceService.current * 1000;
                let deltaDistance = 0;
                deltaDistance = curDistance - this.prevLiveDistance;
                if (deltaDistance < this.distancePerPixel && deltaDistance > MIN_DISTANCE) {
                    this.distancePerPixel = deltaDistance;
                }
    
                const zoomVal = this.getZoomValue();
                const data = lidarService.provider?.data;                                           // is ok, this functio is for live recording, which is not yet supported in reverseRecording mode, so we can savely get from the regular provider
                const curEndDistance =  this.state.canvasWidth * zoomVal / 100;
                const root = this.rootRef.current;
                if ((curDistance / this.distancePerPixel) * zoomVal / 100 >= curEndDistance) {
                    const visibleWindowWidth = root.clientWidth;// / this.getZoomValue() * 100;
                    const toAdd = (visibleWindowWidth / this.distancePerPixel) * 100 / zoomVal;     // need to take the inverse of the zoom cause we only want to add 1 visible screen. if we dont counter-adjust here, it will be adjusted later on and the scrollbar will be of the wrong size when zoomed
                    const canvasWidth = this.state.canvasWidth + toAdd;
                    this.setState({canvasWidth: canvasWidth});
                    //this.scrollStart += (toAdd / zoomVal * 100);
                }
                if (data && data.length > 0 && !this.liveRefresher) {
                    this.liveRefresher = setTimeout(() => {                                                                  // don't redraw on every lidar line, that would be overkill, this way, it is throttled
                        this.liveRefresher = null;
                        this.draw();
                    }, 250);
                }
                this.prevLiveDistance = curDistance;
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    handleRobotReady = () => {
        this.setState({isLoading: false});
        this.buildRenderMap();
        this.setImageSize();
        this.setTimeCanvasSize();
        this.draw();
        this.drawTimeIndicator();
    }

    handleRobotLoading = () => {
        this._normalizedData = null;
        this.setState({isLoading: true});
    }

    handleCurrentTimeChanged = (value) => {
        if (this.curTime !== value && this.blockTimeUpdate === false) {
            const data = this.getData();
            if (data && data.length > 0) {
                if (value === 0) {
                    this.curTimeIdx = 0;
                }
                else {
                    if (value < this.curTime || this.curTimeIdx === -1) {                 // if we went back, start from the search from the beginning, otherwise continue
                        this.curTimeIdx = 0;
                    }
                    let rec = data[this.curTimeIdx];
                    const maxLen = data.length - 1;
                    while(rec && rec.timestamp < value && this.curTimeIdx < maxLen) {
                        this.curTimeIdx++;
                        rec = data[this.curTimeIdx];
                    }
                }
                this.drawTimeIndicator(true);
            }
            this.curTime = value;
        }
        this.blockTimeUpdate = false;
    }

    handleGotoDistance = (value) => {
        this.blockTimeUpdate = true;                                            // this event is always followed by a timeUpdate, but this is less accurate then the distance, so block it.
        const data = this.getData();
        if (data && data.length > 0) {
            if (value === 0) {
                this.curTimeIdx = 0;
            }
            else {
                if (value < this.curTime || this.curTimeIdx === -1) {                 // if we went back, start from the search from the beginning, otherwise continue
                    this.curTimeIdx = 0;
                }
                let rec = data[this.curTimeIdx];
                const maxLen = data.length - 1;
                while(rec && rec.distance < value && this.curTimeIdx < maxLen) {
                    this.curTimeIdx++;
                    rec = data[this.curTimeIdx];
                }
            }
            this.drawTimeIndicator(true);
        }
        this.curTime = value;
    }

    handleGotoPos = (ev) => {
        const scrollbar = this.scrollboxRef.current;
        const zoom = this.getZoomValue();
        let distanceNotVisible = scrollbar ? (scrollbar.scrollLeft / zoom * 100) : 0;
        let distance = distanceNotVisible + (ev.nativeEvent.offsetX / zoom * 100);
        lidarService.tryGotoPos(distance / 1000);
    }


    /**
     * converts the x,y canvas coordinate (relative) into a .
     * This is called by the lidar-rollout-measure-component, so that it can keep track of the position when scrolling/zooming
     * @param {number} x x pos to convert
     */
    handleRellToAbsCoord = (x, y) => {
        const zoom = this.getZoomValue();
        const scrollbar = this.scrollboxRef.current;
        
        let distanceNotVisible = scrollbar ? (scrollbar.scrollLeft / zoom * 100) : 0;
        let newX = distanceNotVisible + (x / this.getZoomValue() * 100);
        const vScrollbar = this.rootRef.current;
        distanceNotVisible = vScrollbar ? (vScrollbar.scrollTop / zoom * 100) : 0;
        let newY = distanceNotVisible + (y / this.getZoomValue() * 100);

        const canvasHeight = this.state.canvasHeight * zoom / 100;                   // use acatual zoom value, we want 
        if (this.props.allowedHeight > canvasHeight) { 
            newY += (canvasHeight - this.props.allowedHeight) / 2; 
        }
        return {x: newX, y: newY};
    }

    handleAbsToRelCoord = (x, y) => {
        const zoom = this.getZoomValue();
        const scrollbar = this.scrollboxRef.current;
        
        let distanceNotVisible = scrollbar ? (scrollbar.scrollLeft / zoom * 100) : 0;
        let newX = x - distanceNotVisible;
        newX = newX * zoom / 100;
        const vScrollbar = this.rootRef.current;
        distanceNotVisible = vScrollbar ? (vScrollbar.scrollTop / zoom * 100) : 0;
        let newY = y - distanceNotVisible;
        newY = newY * zoom / 100;

        const canvasHeight = this.state.canvasHeight * zoom / 100;                   // use acatual zoom value, we want 
        if (this.props.allowedHeight > canvasHeight) { 
            newY -= (canvasHeight - this.props.allowedHeight) / 2; 
        }
        return {x: newX, y: newY};
    }

    /**
     * measure and return the distance between thse 2 points, keeping into account the current zoom value
     * coordinates are relative to current zoom and scroll
     * @param {object} coord1 {x, y} of point 1
     * @param {object} coord2 {x, y} of point 2
     */
    handleMeasureDistance = (coord1, coord2) => {
        const new1 = this.handleRellToAbsCoord(coord1.x, coord1.y);
        const new2 = this.handleRellToAbsCoord(coord2.x, coord2.y);
        const result = distance(new1.x, new1.y, new2.x, new2.y)
        return result * this.distancePerPixel;
    }
}

LidarRollout.propTypes = {
    liveFeed: PropTypes.bool,
    streng: PropTypes.object,                               // the streng we are currently rendereing for, need this for expected or actual length values (for the canvas size)
    snapToHeight: PropTypes.bool,
    zoom: PropTypes.number,
    grayMode: PropTypes.bool,                   // when true, don't use colors
    filterRange: PropTypes.array,               // [low, high] cutoff values
    colors: PropTypes.array,                    // list of stops and the color for each stop
    colorStops: PropTypes.array,                // the % positions of each color, for rendering the color range
    allowedHeight: PropTypes.number,            // depending on the available height, we need to center the canvas or not
    verSplit: PropTypes.number,                 // global versplit, we use this to get notified when the size of the window has changed and need a redraw
    showReverse: PropTypes.bool,                // when true, reverse lidar data will be shown (when available) from the currently active streng
    showMeasDistRollout: PropTypes.bool,        // when true, the measurement result is shown & the user can perform measurements by clicking on the canvas
    showMeasAngleRollout: PropTypes.bool,            // when true, the measurement result is shown & the user can perform measurements by clicking on the canvas for measuring the angle between 2 lines
    measureData: PropTypes.any,                 // when lidar is available, we can perform measurements with that sensor, so we need to respond to measument requests
}

LidarRollout.defaultProps = {
    liveFeed: false,
    snapToHeight: false,
    zoom: 1,
    showMeasDistRollout: false,
    showMeasAngleRollout: false,

}

export default withTranslation()(withStyles(styles)(LidarRollout));