/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons:
import ArrowLeft from 'mdi-material-ui/ArrowLeft';
import Settings from 'mdi-material-ui/Cog';
import FileExport from 'mdi-material-ui/FileExport';

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { configService } from '../../services/config_service';
import MainLayout from '../layouts/main_layout_component';

import Tooltip from '@material-ui/core/Tooltip';
import { activeProjectService } from '../../services/active_project_service';
import { projectService, isEmptyOr0 } from '../services/project_service';
import { documentControlService } from './document_control_service';
import ReportDlg from '../../components/document/report_dialog';
import { withTranslation } from 'react-i18next';
import { DocumentBaseComponent } from '../../components/document/document_base_component';
import { measurementService } from './measurement_service';
import { errExtractor } from '../../shared_components/services/error_extractor';
import { dialogService } from '../../services/dialog_service';
import Account from '../auth/account_component';
import { getLogoImg } from '../../services/image_service';
import Attachments from './attachements/attachments_component';
import DefaultDocumentBody from './document_default_body_component';
import TabletDocumentBody from './tablet_document_body_component';
import { layoutService } from './layout/layout_service';
import LayoutManager from './layout/layout_manager_component';
import { screenManager } from '../../components/video_engine/screen_manager_service';
import { timeService } from '../services/time_service';
import { lidarService } from './instrumentation/sonarLidar/lidar_service';
import LidarVerifyDimensionsDlg from './instrumentation/sonarLidar/lidar_verify_dimensions_dlg';


const styles = (theme) => ({
    
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menuButtonLogo: {
        marginRight: theme.spacing(2),
        padding: "0px"
    },
    logo: {
        height: '28px'
    }
});


class Document extends DocumentBaseComponent {

    constructor(props) {
        super(props);

        const forceLiveFeed = configService.get('recorder.forceLiveFeed');
        const layoutMode = configService.get('layout.document.layoutMode') ?? 'default';
        
        this.state = {
            videoControlState: "stopped",                                           // keeps track of the current video state (pass on to children)
            canRecord: false,
            jumpToTime: 0,                                                          // to instruct video player to change play position. 
            direction: null,
            activeStreng: null,                                                     // for all components that need to reload some data after the document has handled it and when the active streng has changed. can't use index, cause that can remain the same when object changes (delete last-1)
            measureData: null,
            measuring: false,                                                       // when true, the user can click on the video to measure a distance.
            handleMeasuringDone: null,                                              // the callback to call when the measuring is done.
            measuringAngle: false,                                                  // when true, the user can click on the video to measure an angle.
            isReportDlgOpen: false,                                                 // when true, the dlg for generating reports is opened.
            forceLiveFeed: forceLiveFeed,                                           // when true, the display shows from the camera, even if video is available.
            fullScreen: false,                                                      // toggle between normal view or full screen, video only
            initLoaded: false,                                                      // so we can let the video-section reload the channel-names when initial load of engine and project are done
            layoutMode: layoutMode,                                                 // the currently selected layout mode. supported values: default, tablet
            minimalControls: false,                                                 // when layout mode is tablet, this property determins if toolbars are currently visible or not. this goes to true during recording or when user selects to. Different from fulscreen cause this still allows the border controls of the os (start menu)
        }
        this.readOnly = null;
        this.selectedChannel = 'cam1';                                              // the camera channel currently selected. this is just an init value, real value is loaded from configs when project is loaded, by videoSection, which updates this project
    }

    /**
     * 
     * @param {bool} readOnly readonly state is stored  
     */
    initAfterLoadData(readOnly) {
        let direction = null;
        this.readOnly = readOnly;
        if (activeProjectService.data && activeProjectService.data.strengs) {
            let idx = this.props.location.state?.streng ?? activeProjectService.data.strengs.length - 1;
            activeProjectService.activeStrengIdx = idx;
            if (idx > -1) {                                                     // -1 -> empty list
                direction = activeProjectService.activeStreng.inspectionDirection;
            }
        }
        let canRecord = !activeProjectService.activeStrengHasVideo() && !readOnly;
        let active = activeProjectService.activeStreng;
        let forceLiveFeed = readOnly ? false : this.state.forceLiveFeed;                        // when read-only, cant force livefeed.
        this.setState({activeStreng: active, 
                        canRecord: canRecord, 
                        direction: direction, 
                        forceLiveFeed: forceLiveFeed,
                    });
        documentControlService.mount(canRecord, forceLiveFeed).then(() => {
            documentControlService.onControlStateChanged = this.updateControlState;
            activeProjectService.events.addListener("strengChanged", this.handleStrengChanged);
            this.setState({initLoaded: true}); 
            return super.onLoad();
        }).catch((error) => {
            const { t } = this.props;
            dialogService.error(t("Document"), errExtractor.get(error));
        });

        lidarService.events.addListener('jumpToPos', this.handleJumpToPos);                     // systems with lidar use this way to jump to a position
    }

    componentDidMount() {
        layoutService.events.on('changed', this.handleLayoutChanged);
        if (this.props.match.params.edit && this.props.match.params.edit !== "true") {                                   // doc can be loaded in 2 ways: by caller (param edit == 'true'), or by doc self, param edit = id of doc to load, which is always loaded from cloud.
            activeProjectService.loadFromCloud(this.props.match.params.edit).then((result) => {
                if (result) {
                    this.initAfterLoadData(true);                                               // at the moment, documents opened from the cloud, are always read-only                                                
                }
                else {
                    this.goHome();
                }
            });                                                                     //no need to catch, already handled by load
        }
        else if (!this.props.match.params.edit) {
            this.goHome();
        }
        else {
            this.initAfterLoadData(process.env.REACT_APP_IS_VIEWER === 'true');     // data is already loaded, so we can load it already
        }
    }

    async componentWillUnmount() {
        this.onUnload();
        await documentControlService.unMount();
        documentControlService.onControlStateChanged = null;
        activeProjectService.events.removeListener("strengChanged", this.handleStrengChanged);
        layoutService.events.removeListener('changed', this.handleLayoutChanged);
        lidarService.events.removeListener('jumpToPos', this.handleJumpToPos);
    }

    onUnload() {
        if (activeProjectService.dirtyTimer) {                                  // the current active document has not yet been saved, so do this now before it's closed.
            activeProjectService.save();
        }
        super.onUnload();                                                       // platform specific unload function (screenManager)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.videoControlState !== this.state.videoControlState) {                                 // when recording stopped, we need to reload the document asif the streng has changed. that's because there is now a video, the state has changed, so update the ui
            if (this.state.videoControlState === "stopped" && prevState.videoControlState === "processing") {   // after record, there is always a procesing stage, this prepares videos. once ready, we can update the state.
                activeProjectService.signalEndOfRecording();
            }
            else if (this.state.videoControlState === "stopped" && prevState.videoControlState === "reverseTractor") {      // when reverse tractor  is done, recording is completely done, so reload streng to stop edit mode.
                this.handleStrengChanged(activeProjectService.activeStrengIdx);
            }
            else if (this.state.videoControlState === "record") {                               // don't let all child components update for only 1 deep child, so use events.
                activeProjectService.signalStartOfRecord();
            }
        }
        if (this.state.fullScreen === false && prevState.fullScreen !== this.state.fullScreen) {
            documentControlService.documentPos = null;                                          // fullscreen is done, no more need to store this info
        }
    }

    render() {
        const { t } = this.props;
        let projectName = activeProjectService.projectName;
        if (projectName) {
            let streng = this.state.activeStreng;
            if (streng) {
                projectName += ': ' + streng.ref;
            }
        }
        else {
            projectName = t("geen project geladen");
        }
        
        let leftBtn;
        if (configService.isSite) {                                                 // the site uses an image instead of the back button, makes more sense, less in desktop cause ther already is an image from the icon
            const logoImg = getLogoImg();
            leftBtn = <Tooltip title={t("Ga naar vorig scherm")}>
                        <IconButton 
                            className={this.props.classes.menuButtonLogo}
                            aria-label="back"
                            color="inherit"
                            onClick={this.goHome }
                            disabled={this.state.videoControlState === "record"}
                        >
                            <img key="img" src={logoImg} className={this.props.classes.logo} alt="logo" />
                        </IconButton>
                    </Tooltip>
        }
        else {
            leftBtn = <Tooltip title={t("Ga naar vorig scherm")}>
                        <IconButton edge="start"
                            className={this.props.classes.menuButton}
                            aria-label="back"
                            color="inherit"
                            onClick={this.goHome }
                            disabled={this.state.videoControlState === "record"}
                        >
                            <ArrowLeft />
                        </IconButton>
                    </Tooltip>
        }

        return (
            <MainLayout title={projectName}
                allowCollaps={this.state.layoutMode==='tablet'}
                toolbarIsCollapsed={this.state.minimalControls}
                leftBtns={leftBtn}
                rightBtns={
                    <React.Fragment>
                        <Attachments initLoaded={this.state.initLoaded}/>
                        {(!this.readOnly) &&
                            <Tooltip title={t("Maak xml/pdf rapporten")}>
                                <IconButton 
                                    edge="end"
                                    color="inherit"
                                    aria-label="xml/pdf report"
                                    disabled={this.state.videoControlState === "record"}
                                    onClick={this.handleBuildReports}>
                                    <FileExport />
                                </IconButton>
                            </Tooltip>
                        }
                        <div style={{ borderRight: '0.1em solid', borderRightColor: "inherit",  padding: '0.5em', alignSelf: 'stetch' }} />

                        <LayoutManager disabled={this.state.videoControlState === "record"}/>

                        <Tooltip title={t("Settings")}>
                            <IconButton 
                                aria-label="settings"
                                color="inherit"
                                disabled={this.state.videoControlState === "record"}
                                onClick={() => this.props.history.push('/settings')}>
                                <Settings />
                            </IconButton>
                        </Tooltip>
                        {(configService.isCloud || configService.isViewer) &&
                            <Account canLogOut={!this.isDesktop}/>
                        }
                    </React.Fragment>
                }>

                {this.renderBody()}
                {(this.state.isReportDlgOpen) &&
                    <ReportDlg open={this.state.isReportDlgOpen} onClose={() => this.setState({ isReportDlgOpen: false })} />
                }
                {(!configService.isViewer) && <LidarVerifyDimensionsDlg/> }
            </MainLayout>
        );
    }

    renderBody() {
        if (this.state.layoutMode === 'tablet') {
            return (
                <TabletDocumentBody onVideoLoaded={this.handleVideoLoaded}
                    onDirectionChanged={this.handleDirectionChanged}
                    onPositionChanged={this.handleJumpToPos}
                    onStartMeasure={this.handleStartMeasure}
                    onStopMeasure={this.handleStopMeasure}
                    onStartMeasureAngle={this.handleStartMeasureAngle}
                    onTerminated={this.handleTerminateRecFromObs}
                    onStartMeasureDepth={this.handleStartMeasureDepth}
                    onStartMeasureAreaSize={this.handleStartMeasureAreaSize}
                    onStartMeasureFreeform={this.handleStartMeasureFreeform}
                    direction={this.state.direction}
                    fullScreen={this.state.fullScreen}
                    onToggleFullScreen={this.toggleFullScreen}
                    canRecord={this.state.canRecord}
                    forceLiveFeed={this.state.forceLiveFeed}
                    onToggleForceLiveFeed={this.toggleForceLiveFeed}
                    videoControlState={this.state.videoControlState}
                    onCurrentTimeChanged={this.handlePlayPosChanged}
                    onDurationChanged={this.handleDurationChanged}
                    onEnded={this.handleVideoEnded}
                    activeStreng={this.state.activeStreng}
                    measureData={this.state.measureData}
                    onMeasured={this.handleMeasuringDone}
                    onSelectChannel={this.handleSelectChannel}
                    initLoaded={this.state.initLoaded}
                    jumpToTime={this.state.jumpToTime}
                    onControlChanged={this.handleControlChanged}
                    readOnly={this.readOnly}
                    onOpenVideoScreen={this.handleOpenVideoScreen}
                    minimalControls={this.state.minimalControls}
                    onToggleMinimalControls={this.handleToggleMinControls}/>
            );
        }
        else {
            return (
                <DefaultDocumentBody onVideoLoaded={this.handleVideoLoaded}
                    onDirectionChanged={this.handleDirectionChanged}
                    onPositionChanged={this.handleJumpToPos}
                    onStartMeasure={this.handleStartMeasure}
                    onStopMeasure={this.handleStopMeasure}
                    onStartMeasureAngle={this.handleStartMeasureAngle}
                    onTerminated={this.handleTerminateRecFromObs}
                    onStartMeasureDepth={this.handleStartMeasureDepth}
                    onStartMeasureAreaSize={this.handleStartMeasureAreaSize}
                    onStartMeasureFreeform={this.handleStartMeasureFreeform}
                    direction={this.state.direction}
                    fullScreen={this.state.fullScreen}
                    onToggleFullScreen={this.toggleFullScreen}
                    canRecord={this.state.canRecord}
                    forceLiveFeed={this.state.forceLiveFeed}
                    onToggleForceLiveFeed={this.toggleForceLiveFeed}
                    videoControlState={this.state.videoControlState}
                    onCurrentTimeChanged={this.handlePlayPosChanged}
                    onDurationChanged={this.handleDurationChanged}
                    onEnded={this.handleVideoEnded}
                    activeStreng={this.state.activeStreng}
                    measureData={this.state.measureData}
                    onMeasured={this.handleMeasuringDone}
                    onSelectChannel={this.handleSelectChannel}
                    initLoaded={this.state.initLoaded}
                    jumpToTime={this.state.jumpToTime}
                    onControlChanged={this.handleControlChanged}
                    readOnly={this.readOnly}
                    onOpenVideoScreen={this.handleOpenVideoScreen}/>
            );
        }
    }

    handleToggleMinControls = (event) => {
        if (!this.state.measureData) {                                          // don't change minimal controls setup while measuring something, this has been set to hidden
            this.setState({minimalControls: !this.state.minimalControls});
        }
    }
    

    goHome = () => {
        if (this.state.videoControlState !== "record") {
            if (configService.isSite) {
                this.props.history.replace("/home");
            }
            else {
                this.props.history.length >= 2 ? this.props.history.goBack(): this.props.history.push("/home");     // >= 2 cause otherwise we can't go back after reload in debug
            }
        }
    }

    toggleForceLiveFeed = async (event, value) => {
        if (value !== null) {
            let forceLiveFeed = value === true;                               //it provides the value as a string
            configService.set('recorder.forceLiveFeed', forceLiveFeed);
            documentControlService.liveTractor = forceLiveFeed;                 // need to store this value in the service, otherwise the reverse tractor record wont work.
            const isLiveCamera = this.state.canRecord || forceLiveFeed;
            await documentControlService.setLiveInputSource(isLiveCamera);
            this.setState({ forceLiveFeed: forceLiveFeed });
        }
    }

    toggleFullScreen = () => {
        const newValue = !this.state.fullScreen;
        this.setFullScreen(newValue);
    }

    handleSelectChannel = (value) => {
        this.selectedChannel = value;
    }
    
    /**
     * handels document control changes, not coming from documentControlService.
     * @param {string} value possible values: play, record, pause, stop
     */
    handleControlChanged = async (value) => {
        if (value !== this.state.videoControlState && !(value === "stop" && this.state.videoControlState === "stopped")) {      // don't do anything if we are stopped and asking to stop again, no need, causes reload if video element that is not needed
            let allowed = await documentControlService.checkControlStateChangeAllowed(value);
            if (allowed) {
                const result = await documentControlService.setControlState(value);
                if (result) this.setState({ videoControlState: documentControlService.controlState });     // use the service's state. It might have changed the value (from stop to stopped)
            }
        }
    }

    /**
     * calback for when the user requests to change the current time position.
     * This sends a message to the video player component to switch time position. This will then trigger handlePlayPosChanged.
     * We do it this way, otherwise the player component will perform an update on itself every time that the play pos changes (bad)
     */
    handleJumpToPos = (value) => {
        if (this.state.videoControlState !== "record") {                // while recording, we can't jump to a different position. If we were to, it would fuck things up considerably (robot time starts to jump and such, all internal time gets fucked).
            const robot = documentControlService.robotDs;
            if (Math.trunc(value*1000000)/1000000 !== timeService.current) {          // timeservice.current is only 5 digits after the comma, so compare corectly
                this.setState({ jumpToTime: value });
            }
            else if (robot && robot.playCurrent) {    // when livestreaming, can't jump
                documentControlService.robotDs.playCurrent(true);                   // force a replay of the current time so that the trackview gets updated. sometimes, htere are multiple distances on the same timestamp
            }
        }
    }

    handlePlayPosChanged = (value) => {
        if (this.state.canRecord === false && this.state.forceLiveFeed === false) {   // need to check for this, cause during live camera feed, position keeps updating as well, so while recording and always while in replay mode (cant edit, no life camera feed)
            documentControlService.setCurrentTimestamp(value);
        }
        else if (this.state.videoControlState === "record") {
            const activeStreng = this.state.activeStreng;
            if (activeStreng && (!activeStreng.videoLength || activeStreng.videoLength < value)) {
                activeStreng.videoLength = value;
            }
            documentControlService.setCurrentTimestamp(value);
        }
    }

    handleStrengChanged = () => {
        const { t } = this.props;
        try {
            this.handleControlChanged('stop').then(() => {                                      // when switching strands, make certain that playback is stopped on the previous one before switching
                let canRecord = !activeProjectService.activeStrengHasVideo() && !this.readOnly;
                this.loadStreng(canRecord).then(() => { }).catch((error) => {
                    dialogService.error(t("Document"), t("problem_switching_tabs", { error: errExtractor.get(error) }));
                });
            }).catch((error) => {
                dialogService.error(t("Document"), t("problem_switching_tabs", { error: errExtractor.get(error) }));
            });
        }
        catch (error) {
            dialogService.error(t("Document"), t("problem_switching_tabs", { error: errExtractor.get(error) }));
        }
    }

    loadStreng(allowRecord) {
        return new Promise((resolve, reject) => {
            //const isLiveCamera = allowRecord || this.state.forceLiveFeed;
            documentControlService.unMount(true).then(() => {              // always need to load something new, so unmount first, but if the new streng also wants to show the camera, dont' need to stop and reload it.
                documentControlService.mount(allowRecord, this.state.forceLiveFeed).then(() => {
                    this.handleStrengLoaded(allowRecord);
                    resolve();
                }).catch(() => { reject() });
            }).catch(() => { reject() });
        });
    }

    handleStrengLoaded(allowRecord) {
        if (allowRecord === false) {
            this.setState({ canRecord: allowRecord, jumpToTime: 0, activeStreng: activeProjectService.activeStreng });
        }
        else {
            this.setState({ canRecord: allowRecord, jumpToTime: 0, activeStreng: activeProjectService.activeStreng });         // when recording, the video length isnt' set automatically cause there is no video loaded, we get live camera feed, which has no length
        }
        documentControlService.setCurrentTimestamp(0);
    }

    handleDurationChanged = (value) => {
        // this should no longer happen while recording, videoLength is determined when recording, but can still happen for import?
        if (value !== Infinity && this.state.canRecord === false && this.state.activeStreng && this.state.activeStreng.videoLength !== value) {
            this.state.activeStreng.videoLength = value;
            if (!this.readOnly) {
                activeProjectService.markDirty();
            }
        }
    }

    /**
     * called when the video section has manually laoded a video file and assigned it to a streng.
     * this will force a recalculate of the settings for the currently active streng (can record,..)
     */
    handleVideoLoaded = () => {
        let allowRecord = !activeProjectService.activeStrengHasVideo() && !this.readOnly;
        this.handleStrengLoaded(allowRecord);
        documentControlService.emitLengthChanged();
    }

    handleVideoEnded = async () => {
        const result = await documentControlService.setControlState('stopped');
        if (result) this.setState({ videoControlState: 'stopped' });
    }

    /**
     * called when the documentControlService forces a controlstate update.
     */
    updateControlState = (value) => {
        //console.log(`nieuwe status: ${value}`)
        let canRecord = !activeProjectService.activeStrengHasVideo() && !this.readOnly;
        this.setState({ videoControlState: value, canRecord: canRecord });
        if (value === 'processing') {
            //need to add these asap, cause if user is really quick and changes tab before rec is fully done, things can go wrong 
            const newClosures = projectService.closeContinuousObservations(activeProjectService.activeStreng, this.state.activeStreng.videoLength, activeProjectService.activeStreng.actualInspectionLength);
            projectService.addEndOfRecordingObservations(activeProjectService.activeStreng, this.state.activeStreng.videoLength, activeProjectService.activeStreng.actualInspectionLength);
            activeProjectService.markDirty();
            //activeProjectService.signalEndOfRecording(); -> don't do yet, do when state has changed from processing to stopped
            for (const added of newClosures) {                                                                                              // some of the continuous obs changed (probably added closures, so update ui)
                activeProjectService.continuousChanged(added);
            }
            documentControlService.setCurrentTimestamp(0);
            if (documentControlService.robotDs) {                               // can be 0 when unloading
                documentControlService.robotDs.setCurrentTimestamp(0);      // also need to let the robot player/recorder know the new time (recorder for recording the timestamp, player for playing the correct part)
            }
        }
    }

    handleDirectionChanged = (value) => {
        this.setState({ direction: value });
    }

    handleBuildReports = async () => {
        this.setState({ isReportDlgOpen: true });
    }


    /**
     * checks if the user has requested to go to full screen when a measure starts and does so if needed.
     */
    tryGotoFullscreenForMeasure() {
        const fullScreen = configService.get('observations.fullscreenOnMeasureValue');
        if (fullScreen !== this.state.fullScreen) {
            this.setFullScreen(fullScreen);
        }
    }

    /**
     * check if there is a calibration that can be used to measure on the screen. Either manual calibration or for a laser
     */
    isNotCalibratedForMeasure() {
        const streng = this.state.activeStreng;
        if (streng.tractor && streng.tractor.laserCalibration) {            // there was a laser present in the video (or we have a laser during recording)
            const def = streng.tractor.laserCalibration;
            const distance1 = def.distance1;
            const distance2 = def.distance2;
            const radius1 = def.radius1;
            const radius2 = def.radius2;
            return isEmptyOr0(distance1) || isEmptyOr0(distance2) || isEmptyOr0(radius1) || isEmptyOr0(radius2);
        }
        else {
            
            const def = streng.cams[this.selectedChannel];
            const scalingFactor1 = def?.scalingFactor1;
            const scalingFactor2 = def?.scalingFactor2;
            return isEmptyOr0(scalingFactor1) || isEmptyOr0(scalingFactor2);
        }
    }

    /** called when a child component requests to start a measuring procedure (allow user to measue something by clicking on the video
     * callback: function that should be called when 
     * measureData: extra data required to know about the measurement: the idx of the field to store the value in (1, 2) and validifierData to verify to value (min,max, precision)
    */
    handleStartMeasure = (callback) => {
        
        if (this.isNotCalibratedForMeasure()) {                       // undefined for debugging (old config version)
            const { t } = this.props;
            if (this.state.canRecord && this.state.videoControlState === "stopped") {
                dialogService.error(t("Meting"), t("Een meting kan enkel gebeuren tijdens of na opname."));
            }
            else {
                dialogService.error(t("Meting"), t("Voor je een meting op de video kan doen, moet het systeem gecalibreerd worden. Dit kan je op de config pagina doen, onder de sectie 'camera'."));
            }
        }
        else {

            this.setState({ measureData: { type: "measure", handleMeasuringDone: callback }, minimalControls: true });
        }
        this.tryGotoFullscreenForMeasure();
    }

    /** called when a child component requests to start a measuring procedure (allow user to measue something by clicking on the video
     * callback: function that should be called when 
    */
    handleStartMeasureAngle = (callbackDone) => {
        const streng = this.state.activeStreng;
        const def = streng.cams[this.selectedChannel];
        const scalingFactor1 = def?.scalingFactor1;
        const scalingFactor2 = def?.scalingFactor2;
        if (isEmptyOr0(scalingFactor1) || isEmptyOr0(scalingFactor2)) {                       // undefined for debugging (old config version)
            const { t } = this.props;
            dialogService.error(t("Meting"), t("Voor je een meting op de video kan doen, moet het systeem gecalibreerd worden. Dit kan je op de config pagina doen, onder de sectie 'camera'."));
        }
        else {
            this.setState({ measureData: { type: "angle", handleMeasuringDone: callbackDone }, minimalControls: true });
        }
        this.tryGotoFullscreenForMeasure();
    }

    handleStartMeasureDepth = (callbackDone) => {
        const positionX = configService.get('recorder.overlayPosX');
        const positionY = configService.get('recorder.overlayPosY');
        const size = configService.get('recorder.overlaySize');
        this.setState({ measureData: { type: "depth", handleMeasuringDone: callbackDone, x: positionX, y: positionY, size: size }, minimalControls: true });    // for tablet mode: set to minimal controls for optimal screen usages, buttons are not available anyway
        this.tryGotoFullscreenForMeasure();
    }

    handleStartMeasureAreaSize = (callbackDone) => {
        const positionX = configService.get('recorder.overlayPosX');
        const positionY = configService.get('recorder.overlayPosY');
        let size = configService.get('recorder.overlaySize');
        this.setState({ measureData: { type: "deformation", handleMeasuringDone: callbackDone, x: positionX, y: positionY, size: size }, minimalControls: true });
        this.tryGotoFullscreenForMeasure();
    }

    handleStartMeasureFreeform = (callbackDone) => {
        const positionX = configService.get('recorder.overlayPosX');
        const positionY = configService.get('recorder.overlayPosY');
        let size = configService.get('recorder.overlaySize');
        this.setState({ measureData: { type: "freeform", handleMeasuringDone: callbackDone, x: positionX, y: positionY, size: size }, minimalControls: true });
        this.tryGotoFullscreenForMeasure();
    }

    /**
     * called when an overlay is done measuring. store the measured value and let the obs component refresh
     */
    handleMeasuringDone = (value) => {
        try {
            value = measurementService.measurementDone(value);
            this.state.measureData.handleMeasuringDone(value);
        }
        finally {
            measurementService.measureData = null;                          // this was measurement data from the observation: fieldidx, validation
            measurementService.obs = null;
        }
        this.handleStopMeasure();
    }

    //this.stopAllMeasurements();

    handleStopMeasure = () => {
        this.setState({ measureData: null });
        this.setFullScreen(false);                              // after measure, always exit fullscreen as well so we can see the result of the emasure.
    }


    /**
     * called when the b-d-c code is entered (terminate inspection). If we are recording, this is terminated now.
     */
    handleTerminateRecFromObs = () => {
        this.handleControlChanged('stop');                  
    }

    handleOpenVideoScreen = async () => {
        try {
            await screenManager.openVideoScreen();
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("video"), errExtractor.get(error));
        }
    }

    handleLayoutChanged = (layout) => {
        if (this.state.layoutMode !== layout.layoutMode) {
            this.setState({layoutMode: layout.layoutMode});
            this.handleStrengChanged();                             // need to reload the camera and such cause the video element has been unloaded and reloaded. this is the easiest way to unload and reload
        }
    }

}
export default withTranslation()(withStyles(styles)(Document));

/*



*/