import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';



export function NoClutchIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="m 10,18.84 4,1.86 V 23 L 8,20 V 14 H 5 V 10 H 8 V 8.8305085 l 2,0.8549152 V 18.84 m 2,-7.311186 V 17.2 l 3,1.4 0,-3.633898 z"
            />
            <path
                d="m 2.2715255,1.6016949 -1.28,1.27 19.7299995,19.7300001 1.27,-1.27 C 15.423652,14.753055 8.4087655,7.739753 2.2715255,1.6016949 Z"
            />
            <path
                d="M 10,6.84 8,4.8135594 V 4 L 14,1 V 3.3 L 10,5.16 M 19,10 H 15 V 5.41 L 12,6.8 v 2.1627119 l 3,2.8745761 v 0 L 17,14 h 2 z"
            />
        </SvgIcon>
    );
}


export function LeftSideIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 5,8 C 7.209139,8 9,9.790861 9,12 9,14.209139 7.209139,16 5,16 2.790861,16 1,14.209139 1,12 1,9.790861 2.790861,8 5,8 m 7,-7 c 2.209139,0 4,1.790861 4,4 0,2.209139 -1.790861,4 -4,4 C 9.790861,9 8,7.209139 8,5 8,2.790861 9.790861,1 12,1 m 7,7 c 2.209139,0 4,1.790861 4,4 0,2.209139 -1.790861,4 -4,4 -2.209139,0 -4,-1.790861 -4,-4 0,-2.209139 1.790861,-4 4,-4 m 0,2 c -1.104569,0 -2,0.895431 -2,2 0,1.104569 0.895431,2 2,2 1.104569,0 2,-0.895431 2,-2 0,-1.104569 -0.895431,-2 -2,-2 M 12,3 c -1.104569,0 -2,0.8954305 -2,2 0,1.1045695 0.895431,2 2,2 1.104569,0 2,-0.8954305 2,-2 0,-1.1045695 -0.895431,-2 -2,-2 z"
            />
        </SvgIcon>
    );
}

export function RightSideIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 5,8 C 7.209139,8 9,9.790861 9,12 9,14.209139 7.209139,16 5,16 2.790861,16 1,14.209139 1,12 1,9.790861 2.790861,8 5,8 m 7,-7 c 2.209139,0 4,1.790861 4,4 0,2.209139 -1.790861,4 -4,4 C 9.790861,9 8,7.209139 8,5 8,2.790861 9.790861,1 12,1 m 7,7 c 2.209139,0 4,1.790861 4,4 0,2.209139 -1.790861,4 -4,4 -2.209139,0 -4,-1.790861 -4,-4 0,-2.209139 1.790861,-4 4,-4 M 12,3 c -1.104569,0 -2,0.8954305 -2,2 0,1.1045695 0.895431,2 2,2 1.104569,0 2,-0.8954305 2,-2 0,-1.1045695 -0.895431,-2 -2,-2 m -7,7 c -1.1045695,0 -2,0.895431 -2,2 0,1.104569 0.8954305,2 2,2 1.1045695,0 2,-0.895431 2,-2 0,-1.104569 -0.8954305,-2 -2,-2"
            />
        </SvgIcon>
    );
}

export function TopSideIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 5,8 C 7.209139,8 9,9.790861 9,12 9,14.209139 7.209139,16 5,16 2.790861,16 1,14.209139 1,12 1,9.790861 2.790861,8 5,8 m 7,-7 c 2.209139,0 4,1.790861 4,4 0,2.209139 -1.790861,4 -4,4 C 9.790861,9 8,7.209139 8,5 8,2.790861 9.790861,1 12,1 m 7,7 c 2.209139,0 4,1.790861 4,4 0,2.209139 -1.790861,4 -4,4 -2.209139,0 -4,-1.790861 -4,-4 0,-2.209139 1.790861,-4 4,-4 M 5,10 c -1.1045695,0 -2,0.895431 -2,2 0,1.104569 0.8954305,2 2,2 1.1045695,0 2,-0.895431 2,-2 0,-1.104569 -0.8954305,-2 -2,-2 m 14,0 c -1.104569,0 -2,0.895431 -2,2 0,1.104569 0.895431,2 2,2 1.104569,0 2,-0.895431 2,-2 0,-1.104569 -0.895431,-2 -2,-2"
            />
        </SvgIcon>
    );
}



export function DuoHorView(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 21,3 H 3 C 2.4,3 2,3.4 2,4 v 6 c 0,0.6 0.4,1 1,1 h 18 c 0.6,0 1,-0.4 1,-1 V 4 C 22,3.4 21.6,3 21,3"
            />
            <path
                d="M 20.951708,12.965604 H 2.9517077 c -0.5522847,0 -1,0.447715 -1,0.999999 v 6 c 0,0.552285 0.4477153,1 1,1 H 20.951708 c 0.552285,0 1,-0.447715 1,-1 v -6 c 0,-0.552284 -0.447715,-0.999999 -1,-0.999999 m -1,5.999999 H 3.9517077 v -4 H 19.951708"
            />
        </SvgIcon>
    );
}


export function TripleHorView(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 20.951708,15.943611 H 2.9517077 c -0.5489359,0 -1,0.447715 -1,0.999999 l 0,3.021993 c 0,0.552285 0.4477153,1 1,1 H 20.951708 c 0.552285,0 1,-0.447715 1,-1 l 0,-3.021993 c 0,-0.552284 -0.451299,-0.987841 -1,-0.999999 m -1,3.021992 H 3.9517077 l 0,-1.021993 H 19.951708"
            />
            <path
                d="M 20.951708,9.3883549 H 2.9517083 c -0.548936,0 -1,0.4477156 -1,0.9999991 v 3.021993 c 0,0.552285 0.447715,1 1,1 H 20.951708 c 0.552285,0 1,-0.447715 1,-1 v -3.021993 c 0,-0.5522835 -0.451299,-0.9878411 -1,-0.9999991"
                style={{opacity:0.5}} />
            <path
                d="M 20.951708,2.8330995 H 2.9517081 c -0.548936,0 -1,0.447715 -1,0.999999 v 3.0219928 c 0,0.552285 0.447715,1 1,1 H 20.951708 c 0.552285,0 1,-0.447715 1,-1 V 3.8330985 c 0,-0.552284 -0.451299,-0.987841 -1,-0.999999"/>
        </SvgIcon>
    );
}

export function TopLargeDuoBottomHorView(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 21,3 H 3 C 2.4,3 2,3.4 2,4 v 6 c 0,0.6 0.4,1 1,1 h 18 c 0.6,0 1,-0.4 1,-1 V 4 C 22,3.4 21.6,3 21,3"
            />
            <path
                d="M 20.951708,12.965604 H 2.9517077 c -0.5522847,0 -1,0.447715 -1,0.999999 v 6 c 0,0.552285 0.4477153,1 1,1 h 6.822274 c 0.5522853,0 1.0000003,-0.447715 1.0000003,-1 v -6 c 0,-0.552284 -0.447715,-0.999999 -1.0000003,-0.999999 m -1,5.999999 h -4.822274 v -4 h 4.822274"
            />
            <path
                d="m 32.129434,12.965604 h -18 c -0.552285,0 -1,0.447715 -1,0.999999 v 6 c 0,0.552285 0.447715,1 1,1 h 6.822274 c 0.552285,0 1,-0.447715 1,-1 v -6 c 0,-0.552284 -0.447715,-0.999999 -1,-0.999999 m -1,5.999999 h -4.822274 v -4 h 4.822274"
            />
        </SvgIcon>
    )
}

export function LayoutMore(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3"/>
            <path
                d="m 8.9711538,21.990385 v 2 h -2 v -2 m 10.0000002,0 v 2 h -2 v -2 m -4,0 h 2 v 2 h -2 z"/>
        </SvgIcon>
    )
}


export function SonarAndLidarOverlay(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 21,2.5593221 H 3 c -0.5522847,0 -1,0.447715 -1,1 V 20 c 0,0.552285 0.4477153,1 1,1 h 18 c 0.552285,0 1,-0.447715 1,-1 V 3.5593221 c 0,-0.552285 -0.447715,-1 -1,-1 M 20,19 H 4 V 4.5593221 h 16"
            />
            <path
                d="M 16.730993,7.0709423 H 7.2690075 c -0.3153994,0 -0.5256663,0.2102666 -0.5256663,0.5256663 v 8.3661054 c 0,0.315399 0.2102669,0.525666 0.5256663,0.525666 h 9.4619855 c 0.3154,0 0.525666,-0.210267 0.525666,-0.525666 V 7.5966086 c 0,-0.3153997 -0.210266,-0.5256663 -0.525666,-0.5256663"
            />
        </SvgIcon>
    );
}


export function LidarView(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 21,2.5593221 H 3 c -0.5522847,0 -1,0.447715 -1,1 V 20 c 0,0.552285 0.4477153,1 1,1 h 18 c 0.552285,0 1,-0.447715 1,-1 V 3.5593221 c 0,-0.552285 -0.447715,-1 -1,-1 M 20,19 H 4 V 4.5593221 h 16"
            />

        </SvgIcon>
    );
}

export function LidarRollout(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="m 4.993564,10.003231 1.9999896,-0.00644 0.012882,3.9999792 -1.9999896,0.0064 m 5.9734036,-3.993007 1.999898,-0.020202 0.0404,3.9997955 -1.999898,0.0202 m 6.012891,-3.954374 1.999996,0.0041 -0.0083,3.999991 -1.999996,-0.0041 M 2,4 V 20 H 22 V 4 Z"/>
        </SvgIcon>
    );
}


export function SonarView(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 21,3 H 3 C 2.4,3 2,3.4 2,4 v 15.915254 c 0,0.6 0.4,1 1,1 h 18 c 0.6,0 1,-0.4 1,-1 V 4 C 22,3.4 21.6,3 21,3"
            />

        </SvgIcon>
    );
}

export function Lidar(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="m 7.2213118,17.500086 8.7398202,0.01873 c -1.179274,-0.744991 -2.761644,-1.469633 -4.529437,-1.45928 -1.4637234,0.0039 -2.8648222,0.481764 -4.2103832,1.440547 m 12.0841702,0.14611 0.211677,0.212586 c 0.275179,0.276362 0.330808,0.714889 0.167401,1.075164 -0.156321,0.353219 -0.510419,0.607019 -0.92051,0.591998 L 4.6219467,19.495632 C 4.3461908,19.48797 4.1059873,19.38846 3.922534,19.204218 L 3.7181252,18.899724 C 3.556264,18.538751 3.6137721,18.100467 3.8901341,17.825287 6.1794642,15.319959 8.7207149,14.04554 11.450141,14.05139 c 4.009271,0.01567 7.058024,2.794064 7.855341,3.594806 M 18.800539,5.8010479 c 3.175153,3.1887934 3.758755,8.0266661 1.721254,11.8053281 l -1.036883,-1.197235 -0.409242,-0.411 c 1.243663,-2.90355 0.67841,-6.4049483 -1.692371,-8.785914 A 8,8 0 0 0 6.0696146,7.1879775 C 3.6886489,9.5587584 3.1083915,13.057701 4.3395964,15.966556 3.815308,16.446266 3.3262687,16.975549 2.8936766,17.561522 0.87239335,13.774159 1.4696421,8.945889 4.6584355,5.770736 8.5771082,1.8688257 14.919796,1.9036337 18.800539,5.8010479 Z"
            />

        </SvgIcon>
    );
}

export const TopTractorIcon = ({ fill, size, transform }) => (
    <svg viewBox="0 0 100 100" width={size} height={size} style={{ transform: transform }} xmlns="http://www.w3.org/2000/svg">

        <g id="Layer_1">
            <rect fill={fill} x="26.15373" y="17.70368" width="47.69253" height="73.94077" id="svg_1" rx="5" />
            <rect fill={fill} x="41" y="2.04443" width="18" height="27" id="svg_6" rx="6" />
            <rect fill={fill} x="15.13317" y="16.18125" width="7.44336" height="30.4207" id="svg_11" rx="5" />
            <rect fill={fill} x="15.13317" y="64.10499" width="7.44336" height="30.4207" id="svg_12" rx="5" />
            <rect fill={fill} x="77.73608" y="16.18125" width="7.44336" height="30.4207" id="svg_13" rx="5" />
            <rect fill={fill} x="77.81385" y="64.10499" width="7.44336" height="30.4207" id="svg_14" rx="5" />
        </g>
    </svg>
);

export const FrontTractorIcon = ({ fill, size, transform }) => (
    <svg viewBox="0 0 100 100" width={size} height={size} style={{ transform: transform }} xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={fill} x="19.00828" y="35.95042" width="63.84823" height="32.39667" id="svg_1" rx="2" />
            <rect fill={fill} x="5.33379" y="49.87286" width="11.57024" height="35.1981" id="svg_3" rx="5" />
            <ellipse fill={fill} cx="50" cy="35.20662" id="svg_7" rx="18.26445" ry="18.26445" />
            <rect fill={fill} x="84.60971" y="49.87286" width="11.57024" height="35.1981" id="svg_8" rx="5" />
            <rect fill={fill} x="14.80192" y="60.0233" width="7.2261" height="8.3916" id="svg_9" />
            <rect fill={fill} x="80.08488" y="60.0233" width="7.2261" height="8.3916" id="svg_10" />
        </g>

    </svg>
);

export const SideTractrorIcon = ({ fill, size, transform }) => (
    <svg viewBox="0 0 100 100" width={size} height={size} style={{ transform: transform }} xmlns="http://www.w3.org/2000/svg">

        <g id="Layer_1">
            <rect fill={fill} x="10.77533" y="30.32503" width="83.13669" height="31.22234" id="svg_1" rx="5" />
            <ellipse fill={fill} cx="23.72879" cy="65.44872" id="svg_2" rx="21.53843" ry="21.53843" />
            <rect fill={fill} x="1.2545" y="5.28067" width="27.42805" height="18.33872" id="svg_6" rx="6" />
            <line stroke={fill} x1="15.77933" y1="17.52621" x2="25.81517" y2="33.29681" id="svg_7" />
            <ellipse fill={fill} cx="77.71155" cy="65.44872" id="svg_9" rx="21.53843" ry="21.53843" />
        </g>
    </svg>
);

export const FrontCamIcon = ({ fill, size, transform }) => (
    <svg viewBox="0 0 100 100" width={size} height={size} style={{ transform: transform }} xmlns="http://www.w3.org/2000/svg">
        <g id="Layer_1">
            <circle fill={fill} r="30.67687" cx="50" cy="50" id="svg_3" />
            <rect fill={fill} id="svg_1" height="4.65116" width="23.72093" y="75.42718" x="38.13954" />
            <rect fill={fill} x="18.74997" y="24.55445" width="62.50007" height="50.8911" id="svg_2" />
        </g>
    </svg>
);



export const TopCamIcon = ({ fill, size, transform }) => (
    <svg viewBox="0 0 130 126" width={size} style={{ transform: transform }} xmlns="http://www.w3.org/2000/svg">

        <g id="Layer_1">
            <rect fill={fill} id="svg_1" height="102.04069" width="57.14279" y="23.61229" x="36.4286"/>
            <ellipse fill={fill} ry="12.24488" rx="28.77547" id="svg_2" cy="21.75515" cx="64.99999"/>
        </g>
    </svg>
);

export const SideCamIcon = ({ fill, size, transform }) => (
    <svg viewBox="0 0 130 126" width={size}  style={{ transform: transform }} xmlns="http://www.w3.org/2000/svg">

        <g id="Layer_1">
            <rect fill={fill} id="svg_1" height="47.66667" width="102" y="39.16666" x="28" />
            <path fill={fill} id="svg_5" d="m28.16668,39.16668c0,0 -26.33543,0 -26.50002,-0.1667c0.16459,0.1667 26.50002,19.16669 26.33332,18.99999c0.1667,0.1667 0.1667,-18.83329 0.1667,-18.83329z" />
        </g>
    </svg>
);

export function StartEndLabel(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="m 4.6727608,19.144231 3.4495013,-7 -3.4495013,-7.0000003 h 7.8397752 c 0.477514,0 0.869503,0.3 1.161712,0.86 l 3.114529,6.1400003 -3.114529,6.14 c -0.292209,0.56 -0.684198,0.86 -1.161712,0.86 z"
            />
            
            <path
                d="M 5.4519229,12.100962 A 2.3509614,2.3509614 0 0 1 3.1009614,14.451923 2.3509614,2.3509614 0 0 1 0.75,12.100962 2.3509614,2.3509614 0 0 1 3.1009614,9.7500002 2.3509614,2.3509614 0 0 1 5.4519229,12.100962 Z"
            />
            <path
                d="m 23.20673,12.028846 a 2.3509614,2.3509614 0 0 1 -2.350962,2.350961 2.3509614,2.3509614 0 0 1 -2.350961,-2.350961 2.3509614,2.3509614 0 0 1 2.350961,-2.3509617 2.3509614,2.3509614 0 0 1 2.350962,2.3509617 z"
            />

        </SvgIcon>
    );
}

export function StartEndLabelOutline(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 5.4519229,12.100962 A 2.3509614,2.3509614 0 0 1 3.1009614,14.451923 2.3509614,2.3509614 0 0 1 0.75,12.100962 2.3509614,2.3509614 0 0 1 3.1009614,9.7500002 2.3509614,2.3509614 0 0 1 5.4519229,12.100962 Z"
            />
            
            <path
                d="m 23.20673,12.028846 a 2.3509614,2.3509614 0 0 1 -2.350962,2.350961 2.3509614,2.3509614 0 0 1 -2.350961,-2.350961 2.3509614,2.3509614 0 0 1 2.350961,-2.3509617 2.3509614,2.3509614 0 0 1 2.350962,2.3509617 z"
            />
            <path
                d="m 6.9631834,17.144231 h 6.0351176 l 2.485048,-5 -2.485048,-5 H 6.9631834 l 2.4850487,5 -2.4850487,5 m 6.0351176,2 H 4.4781349 l 3.1950623,-7 -3.1950623,-7 h 8.5201661 c 0.489909,0 0.873317,0.3 1.164422,0.86 l 3.095661,6.14 -3.095661,6.14 c -0.291105,0.56 -0.674513,0.86 -1.164422,0.86 z"
            />

        </SvgIcon>
    );
}


export function SeriesWide(props) {
    return (
        <SvgIcon {...props}>
            <path fillOpacity="0.5" strokeOpacity="0.5"
                d="m 12,6.4687864 c -1.3,0 -2.4,0.6591659 -2.8,1.8591659 H 6.8 C 6.4,7.1279523 5.3,6.4772353 4,6.4772353 2.3,6.4772353 1,10.3 1,12 c 0,1.7 1.3,5.882917 3,5.882917 1.3,0 2.4,-0.62332 2.8,-1.82332 h 2.4 c 0.4,1.2 1.5,1.877513 2.8,1.877513 1.3,0 2.4,-0.679418 2.8,-1.879418 h 2.4 c 0.4,1.2 1.5,1.863969 2.8,1.863969 1.7,0 3,-4.221661 3,-5.921661 0,-1.7 -1.3,-5.5889059 -3,-5.5889059 -1.3,0 -2.4,0.7382592 -2.8,1.9382592 h -2.4 c -0.4,-1.2 -1.5,-1.8805669 -2.8,-1.8805669"
            />

        </SvgIcon>
    );
}

export function SeriesDouble(props) {
    return (
        <SvgIcon {...props}>
            <path fillOpacity="0.5" strokeOpacity="0.5"
                d="m 12,6.4687864 c -1.3,0 -2.4,0.6591659 -2.8,1.8591659 H 6.8 C 6.4,7.1279523 5.3,6.4772353 4,6.4772353 2.3,6.4772353 1,10.3 1,12 c 0,1.7 1.3,5.882917 3,5.882917 1.3,0 2.4,-0.62332 2.8,-1.82332 h 2.4 c 0.4,1.2 1.5,1.877513 2.8,1.877513 1.3,0 2.4,-0.679418 2.8,-1.879418 h 2.4 c 0.4,1.2 1.5,1.863969 2.8,1.863969 1.7,0 3,-4.221661 3,-5.921661 0,-1.7 -1.3,-5.5889059 -3,-5.5889059 -1.3,0 -2.4,0.7382592 -2.8,1.9382592 h -2.4 c -0.4,-1.2 -1.5,-1.8805669 -2.8,-1.8805669"
            />

            <path 
                d="m 12.071691,9.049474 c -1.3,0 -2.4000007,0.8 -2.8000007,2 H 6.8716904 c -0.4,-1.2 -1.5,-2 -2.8,-2 -1.7,0 -3,1.3 -3,3 0,1.7 1.3,3 3,3 1.3,0 2.4,-0.8 2.8,-2 h 2.3999999 c 0.4,1.2 1.5000007,2 2.8000007,2 1.3,0 2.4,-0.8 2.8,-2 h 2.4 c 0.4,1.2 1.5,2 2.8,2 1.7,0 3,-1.3 3,-3 0,-1.7 -1.3,-3 -3,-3 -1.3,0 -2.4,0.8 -2.8,2 h -2.4 c -0.4,-1.2 -1.5,-2 -2.8,-2"
            />

        </SvgIcon>
    );
}


export const ArrowUpBoldPath = "M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z";
export const ArrowDownBoldPath = "M9,4H15V12H19.84L12,19.84L4.16,12H9V4Z";
export const ArrowRightBoldPath = "M4,15V9H12V4.16L19.84,12L12,19.84V15H4Z";
export const ArrowLeftBoldPath = "M20,9V15H12V19.84L4.16,12L12,4.16V9H20Z";
export const StopPath = "M18,18H6V6H18V18Z";
export const MeasureArrow = "m 3,18 h 16 v -2 l 3,3 -3,3 V 20 H 3 Z";