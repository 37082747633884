import React from 'react';
import Slider from "@material-ui/core/Slider";
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";

const style = {
    root: {
        height: 8,
        '&$vertical': {
            width: 8
        }
    },
    thumb: {
        height: 24,
        width: 24,
        border: '1px solid #fff',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover': {
            boxShadow: '0px 0px 0px 8px rgba(84, 199, 97, 0.16)'
        },
        '&$active': {
            boxShadow: '0px 0px 0px 12px rgba(84, 199, 97, 0.16)',
            border: '2px solid #fff',
        }
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)'
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 8,
        borderRadius: 4
    },
    vertical: {
        '& $rail': {
            width: 8
        },
        '& $track': {
            width: 8
        },
        '& $thumb': {
            marginLeft: -8,
            marginBottom: -11,

            "&.hidden-thumb ": {
                display: "none"
              }
        }
    }
}

export const TabletSlider = withStyles(theme => (style))(Slider);

export function ContiniousThumbComponent(props) {

    const { children, className, value, ...other } = props;
    let idx = 0;
    if (value[0] < value[1]) {
        idx = 1
    }
    const extraClassName = other["data-index"] === idx ? "first-thumb" : "second-thumb";
    return (
        <span {...other} className={clsx(className, extraClassName)}>
            {children}
        </span>
    );
}