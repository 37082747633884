/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TwoColLayout from '../../../layouts/two_col_layout_component';
import StrengInfoComboBox from './streng_info_combobox';
import StrengInfoTextField from './streng_info_textfield';
import StrengInfoAutoComplete from './streng_info_autocomplete_component';
import StrengInfoGPSAutoComplete from './streng_info_gps_autocomplete_component';
import StrengInfoNumberAutoLidar from './streng_info_number_autoLidar_component';
import StrengInfoNumberField from './streng_info_numberfield';
import StrengTimeField from './streng_info_timepicker';
import StrengDateField from './streng_info_datepicker';
import StrengInfoCoordinatesField from './streng_info_coordinates_field';
import { withTranslation } from 'react-i18next';
import { PIPE_INSPECTIONS } from '../../../../shared_components/services/project_service';
import { 
 directionOptions
,locationTypeOptions
,propertyOwnershipOptions
,axialRefPointOptions
,storageVideoOptions
,positionVideoImagesOptions
,shapeOptions
,shapeOptionsM
,materialOptions
,liningTypeOptions
,liningMaterialOptions
,sewerTypeOptions
,sewerUsageOptions
,sewerUsageOptionsM
,cleaningOptions
,connectionTypeOptions
//,surfaceHardeningOptions
,precipitationOptions
,temperatureOptions
,yearOfCommisioningOptions
,verticalRefPointOptions
,CircularRefPointOptions
,inspectionStageOptions
,stepTypeOptions
,stepMaterialOptions
,atmosphereOptions
,inspectionMethodOptions
,inspectionMethodOptions_S
,inspectionMethodOptions_M
,fluidControlOptions } from '../../../services/constants';
import { strandPropsService } from '../../../services/strand_props_service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { activeProjectService } from '../../../../services/active_project_service';
import { dialogService } from '../../../../services/dialog_service';
import { configService } from '../../../../services/config_service';


const styles = (theme) => ({
    paper: {
        height: '100%'
    },
    
});


class StrengInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {      
        
        }
    }

    render() {
        const { t } = this.props;
        let radioProps = null;
        if (this.props.readOnly) {
            radioProps = {'readOnly': 'readonly'};
        }
        const main = activeProjectService.data?.main;
        let stationaryDisabled = false;
        if (main) {
            stationaryDisabled = !(this.state.strengType === "s" || !['A', 'B'].includes(main.purpose));       // when purpose of inspection is A or B, stationary isnpections not allowed
        }
        const inspMethodOptions = this.props.strengType === "s" ? inspectionMethodOptions_S : inspectionMethodOptions;
        return (
            <React.Fragment>
                <FormControl component="fieldset" >
                    <FormLabel component="legend">{t("Type")}</FormLabel>
                    <RadioGroup aria-label="type" 
                        name="type" 
                        style={{flexDirection: 'row'}}
                        value={this.props.strengType} 
                        onChange={this.handleSetType}
                    >
                        <FormControlLabel value="dp" control={<Radio inputProps={radioProps}/>} label={t("Streng")} />
                        <FormControlLabel value="m" control={<Radio inputProps={radioProps}/>} label={t("Put")} />
                        <FormControlLabel value="s" control={<Radio inputProps={radioProps}/>} label={t("Stationair")} disabled={stationaryDisabled} />
                    </RadioGroup>
                </FormControl>
                <TwoColLayout
                    left={
                        <React.Fragment>
                        {(PIPE_INSPECTIONS.includes(this.props.strengType)) &&
                            <React.Fragment>
                            <StrengInfoTextField    required field="ref" label={t("Streng referentie")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField             field="startPointRef" label={t("Begin knooppuntreferentie")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoCoordinatesField      field="startPointCoord" label={t("Begin knooppunt coordinaat")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoAutoComplete required field="pointRef1" label={t("Knooppuntreferentie 1")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoCoordinatesField      field="coordPoint1" label={t("Coordinaat knooppunt 1")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoAutoComplete required field="pointRef2" label={t("Knooppuntreferentie 2")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoCoordinatesField      field="coordPoint2" label={t("Coordinaat knooppunt 2")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoAutoComplete          field="pointRef3" label={t("Knooppuntreferentie 3")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoCoordinatesField      field="coordPoint3" label={t("Coordinaat knooppunt 3")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField             field="startpointLateralInspection" label={t("Startpunt laterale inspectie")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField             field="lateralStrengRef" label={t("referentie laterale streng")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoNumberField           field="distanceStartLateral" label={t("Afstand begin lateraal")} streng={this.props.streng} min={0} max={9999.99} step={0.01} digits={2}  showMissingErrors={this.props.showMissingErrors}  unit="m" readOnly={this.props.readOnly}/>
                            <StrengInfoNumberField           field="clockPosStartLateral" label={t("Klokstand van begin lateraal")} streng={this.props.streng} min={1} max={12} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
        
                            <StrengInfoGPSAutoComplete required field="location" label={t("Locatie")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>



                            <StrengInfoComboBox     required field="locationType" label={t("Soort locatie")} streng={this.props.streng} options={locationTypeOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField             field="district" label={t("Wijk")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField             field="sewerName" label={t("Naam riolering")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="propertyOwnership" label={t("Grondeigendom")} streng={this.props.streng} options={propertyOwnershipOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="axialRefPoint" label={t("Axiaal referentiepunt")} streng={this.props.streng} options={axialRefPointOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="inspectionDirection" label={t("Inspectierichting")} streng={this.props.streng} options={directionOptions} secondarySource={this.props.direction} onChange={this.props.onDirectionChanged} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>

                            <StrengInfoComboBox     required field="inspMethod" label={t("Inspectie methode")} streng={this.props.streng} options={inspMethodOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>

                            <StrengDateField        required field="inspectionDate" label={t("Inspectiedatum")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengTimeField        required field="inspectionTime" label={t("Inspectietijd")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>

                            <StrengInfoTextField            field="equipmentSerialNr" label={t("Serie nr materiaal")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly} onValueChanged={this.handleSerialChanged}/>
                            </React.Fragment>
                        }
                        {(this.props.strengType==="m") &&
                            <React.Fragment>
                            <StrengInfoTextField    required field="ref" label={t("Put referentie")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoCoordinatesField      field="startPointCoord" label={t("Coordinaat put")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoAutoComplete required field="location" label={t("Locatie")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="locationType" label={t("Soort locatie")} streng={this.props.streng} options={locationTypeOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField             field="district" label={t("Wijk")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField             field="sewerName" label={t("Naam riolering")} streng={this.props.streng} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="propertyOwnership" label={t("Grondeigendom")} streng={this.props.streng} options={propertyOwnershipOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="connectionType" label={t("Verbindingtype")} streng={this.props.streng} options={connectionTypeOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoNumberField  required field="coverLevel" label={t("Dekselniveau")} streng={this.props.streng} min={-9999.99} max={9999.99} step={0.01} digits={2}  showMissingErrors={this.props.showMissingErrors} unit="m" readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="verticalRefPoint" label={t("Verticaal referentiepunt")} streng={this.props.streng} options={verticalRefPointOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="CircularRefPoint" label={t("Referentiepunt omtrek")} streng={this.props.streng} options={CircularRefPointOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="storageVideo" label={t("Opslag videobeelden")} streng={this.props.streng} options={storageVideoOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
        
                            <StrengInfoComboBox     required field="positionVideoImages" label={t("Plaatsbepaling videobeelden")} streng={this.props.streng} options={positionVideoImagesOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField    required field="inspectionDate" label={t("Inspectiedatum")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField    required field="inspectionTime" label={t("Inspectietijd")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="inspectionStage" label={t("Inspectiefase")} streng={this.props.streng} options={inspectionStageOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoComboBox     required field="inspMethod" label={t("Inspectie methode")} streng={this.props.streng} options={inspectionMethodOptions_M} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                            <StrengInfoTextField            field="equipmentSerialNr" label={t("Serie nr materiaal")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly} onValueChanged={this.handleSerialChanged}/>
                            </React.Fragment>
                        }
                        </React.Fragment>
                    }
                    right={
                        <React.Fragment>
                            {(PIPE_INSPECTIONS.includes(this.props.strengType)) &&
                            <React.Fragment>
                                <StrengInfoComboBox           field="storageVideo" label={t("Opslag videobeelden")} streng={this.props.streng} options={storageVideoOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox           field="positionVideoImages" label={t("Plaatsbepaling videobeelden")} streng={this.props.streng} options={positionVideoImagesOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="inspectionStage" label={t("Inspectiefase")} streng={this.props.streng} options={inspectionStageOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField          field="expectedInspectionLength" label={t("Verwachte inspectielengte")} streng={this.props.streng} min={0} max={999.99} step={0.01} digits={2} showMissingErrors={this.props.showMissingErrors} unit="m" readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField          field="actualInspectionLength" label={t("Eigenlijke inspectielengte")} streng={this.props.streng} min={0} max={999.99} step={0.01} digits={2} showMissingErrors={this.props.showMissingErrors} unit="m" readOnly={this.props.readOnly}/>

                                <StrengInfoComboBox required    field="shape" label={t("Vorm")} streng={this.props.streng} options={shapeOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly} onChange={this.handleShapeChanged}/>
                                <StrengInfoNumberAutoLidar required field="height" label={t("Hoogte")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly} onValueChanged={this.handleHeightChanged}/>
                                <StrengInfoNumberAutoLidar          field="Width" label={t("Breedte")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly} onValueChanged={this.handleWidthChanged}/>
                                <StrengInfoComboBox required    field="material" label={t("Materiaal")} streng={this.props.streng} options={materialOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox             field="liningType" label={t("Soort lining")} streng={this.props.streng} options={liningTypeOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox             field="liningMaterial" label={t("Liningmateriaal")} streng={this.props.streng} options={liningMaterialOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField          field="lengthOfTubePart" label={t("Lengte van het buisdeel")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField          field="depthStartPointRel" label={t("Diepte bij beginknoop relatief")} streng={this.props.streng} min={0} max={999.99} step={0.01} digits={2} showMissingErrors={this.props.showMissingErrors} unit="m" readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField          field="depthEndPointRel" label={t("Diepte bij eindknoop relatief")} streng={this.props.streng} min={0} max={999.99} step={0.01} digits={2} showMissingErrors={this.props.showMissingErrors} unit="m" readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox required    field="sewerType" label={t("Soort riool")} streng={this.props.streng} options={sewerTypeOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox required    field="sewerUsage" label={t("Gebruik riolering")} streng={this.props.streng} options={sewerUsageOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoTextField            field="strategic" label={t("Strategisch")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox required    field="cleaning" label={t("Reinigen")} streng={this.props.streng} options={cleaningOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox required    field="yearOfCommisioning" label={t("Jaar van ingebruikneming")} streng={this.props.streng} options={yearOfCommisioningOptions}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox required    field="precipitation" label={t("Neerslag")} streng={this.props.streng} options={precipitationOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox required    field="temperature" label={t("Temperatuur")} streng={this.props.streng} options={temperatureOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox required    field="fluidControl" label={t("Beheersing vloeistofstroom")} streng={this.props.streng} options={fluidControlOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>

                            </React.Fragment>
                            }
                            {(this.props.strengType==="m") &&
                            <React.Fragment>
                                <StrengInfoComboBox     required field="shape" label={t("Vorm van toegangsopening")} streng={this.props.streng} options={shapeOptionsM} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly} onChange={this.handleShapeChanged}/>
                                <StrengInfoNumberAutoLidar           field="height" label={t("Breedte van toegang")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly} onValueChanged={this.handleHeightChanged}/>
                                <StrengInfoNumberAutoLidar  required field="Width" label={t("wijdte van toegang of diameter")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly} onValueChanged={this.handleWidthChanged}/>
                                <StrengInfoComboBox     required field="material" label={t("Materiaal")} streng={this.props.streng} options={materialOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField           field="lengthOfTubePart" label={t("Lengte van het putdeel")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="sewerUsage" label={t("Gebruik riolering")} streng={this.props.streng} options={sewerUsageOptionsM} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoTextField             field="strategic" label={t("Strategisch")} streng={this.props.streng}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="cleaning" label={t("Reinigen")} streng={this.props.streng} options={cleaningOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="yearOfCommisioning" label={t("Jaar van ingebruikneming")} streng={this.props.streng} options={yearOfCommisioningOptions}  showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="litShape" label={t("Vorm deksel")} streng={this.props.streng} options={shapeOptionsM} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="litMaterial" label={t("Materiaal deksel")} streng={this.props.streng} options={materialOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField  required field="litWidth" label={t("Breedte deksel")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly}/>
                                <StrengInfoNumberField           field="litHeight" label={t("Wijdte deksel")} streng={this.props.streng} min={0} max={99999} step={1} digits={0} showMissingErrors={this.props.showMissingErrors} unit="mm" readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="stepType" label={t("Type klimijzers")} streng={this.props.streng} options={stepTypeOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox              field="stepMaterial" label={t("Materiaal klimijzers")} streng={this.props.streng} options={stepMaterialOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="precipitation" label={t("Neerslag")} streng={this.props.streng} options={precipitationOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="temperature" label={t("Temperatuur")} streng={this.props.streng} options={temperatureOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox     required field="fluidControl" label={t("Beheersing vloeistofstroom")} streng={this.props.streng} options={fluidControlOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>
                                <StrengInfoComboBox              field="atmosphere" label={t("Atmosfeer")} streng={this.props.streng} options={atmosphereOptions} showMissingErrors={this.props.showMissingErrors} readOnly={this.props.readOnly}/>

                            </React.Fragment>
                            }
                        </React.Fragment>

                    }
                />
                <StrengInfoTextField          field="remarks" label={t("Algemene opmerking")} streng={this.props.streng} readOnly={this.props.readOnly}/>
            </React.Fragment>
            
        );
    }

    handleSerialChanged = (value) => {
        configService.set("general.lastEquipmentSerialNr", value);        // store value for use as default for next new project
    }

    handleWidthChanged = (value) => {
        strandPropsService.width = +value;                              // will auto prevent update if obs has changed this value cause of playback
    }

    handleHeightChanged = (value) => {
        strandPropsService.height = +value;
    }

    handleShapeChanged = (value) => {
        strandPropsService.shape = value;
    }

    handleSetType = (ev) => {
        const { t } = this.props;
        if (this.props.readOnly) {                                      // not allowed to set in readonly mode
            return;
        }
        let value = ev.target.value;
        let typeStr = this.props.streng.type === "dp" ? t("Streng") : t("Put");
        let toStr = value === "dp" ? t("Streng") : t("Put");
        if (this.props.streng) {
            if (this.props.streng.observations.length > 0) {
                dialogService.askYesNo(t("Opgelet"), 
                    t('Waarnemingen verwijderen?'), 
                    t("change_strengtype_qst", {typeStr: typeStr, toStr: toStr}),
                    (response) => {
                        if (response.response === 0) {
                            this.props.streng.type = value;
                            this.props.streng.observations = [];
                            if (value === "s") {
                                this.props.streng.inspMethod = "C";                         // only value allowed for this field when switching to stationary
                            }
                            else {
                                this.props.streng.inspMethod = "B";                         // this is the default in all other cases.
                            }
                            activeProjectService.markDirty();
                            if (this.props.onTypeChanged) {
                                this.props.onTypeChanged(value, []);
                            }
                        }
                    }
                );
            }
            else {
                this.props.streng.type = value;
                if (this.props.onTypeChanged) {
                    this.props.onTypeChanged(value);
                }
                activeProjectService.markDirty();
            }
        }
    }
    
}

StrengInfo.propTypes = {
    streng: PropTypes.object,                               // a reference to the streng for which we are currently showing data.
    readOnly: PropTypes.bool,                           // for viewer
    direction: PropTypes.string,                            // allowed values: 
    onDirectionChanged: PropTypes.func,
    strengType: PropTypes.string,                            // dp = streng, m = put, determins which fields are visible
    showMissingErrors: PropTypes.bool,                       // when true, inputboxes that require a value but dont, should show an error
};

export default withTranslation()(withStyles(styles)(StrengInfo));