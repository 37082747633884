/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import '../../App.css';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MainLayout from '../../shared_components/layouts/main_layout_component';
import CompanySettings from '../../shared_components/settings/company_component';
import GeneralSettings from './general_component';
import { withTranslation } from 'react-i18next';

//icons:
import ArrowLeft from 'mdi-material-ui/ArrowLeft';
import { userService } from '../../shared_components/services/user_service';


const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column'
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`
    },

    tab: {
        flex: 1
    }
});



class Settings extends React.Component {
    constructor(props) {
        super(props);

        const user = userService.getCurrentUser();
        this.isCompanyAdmin = user && user.companyRole === "companyAdmin";
        this.state = {
            currentTab: "general"
        }
    }

    render() {
        const { t } = this.props;
        return (
            <MainLayout title="Settings"
                leftBtns={
                    <IconButton edge="start" 
                        className={this.props.classes.menuButton} 
                        color="inherit" 
                        aria-label="back"
                        onClick={this.goBack}>
                        <ArrowLeft/>
                    </IconButton>
                }>
                <Box display="flex" flexDirection="row" height="100%" textAlign="start" >
                    <Tabs orientation="vertical"
                        value={this.state.currentTab}
                        onChange={(ev, newVal) => this.setState({currentTab: newVal})}
                        variant="scrollable"
                        className={this.props.classes.tabs}>
                        <Tab label={t("Algemeen")} value="general"/>
                        {(this.isCompanyAdmin) && <Tab label={t("Users")} value="users"/> }
                    </Tabs>
                    <Box flex="1" overflow="auto">
                        <GeneralSettings isCurrentTab={this.state.currentTab === "general"}
                                    className={this.props.classes.tab}/>
                        {(this.isCompanyAdmin) &&
                            <CompanySettings isCurrentTab={this.state.currentTab === "users"}
                                className={this.props.classes.tab}/>
                        }
                    </Box>               
                </Box>
            </MainLayout>
        );
    }

    goBack = () => {
        this.props.history.goBack();
    }
}

export default withTranslation()(withStyles(styles)(Settings));