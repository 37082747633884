/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

function pad(num) {
    return ("0"+num).slice(-2);
}

function pads(num) {
    if (num >= 10) {
        return `${num}`.slice(0, 2);
    }
    return ("0"+num).slice(0, 2);
}

export function hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    secs = secs%60;
    var hours = Math.floor(minutes/60)
    minutes = minutes%60;
    return `${pad(hours)}:${pad(minutes)}:${pads(secs)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

export function hhmmssToSec(value) {
    let parts = value.split(":");
    if (parts.length === 3) {
        return (+parts[0] * 60 * 60) + (+parts[1] * 60) + +parts[2];
    }
    else {
        return null;
    }
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }