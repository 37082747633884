/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */
//icons
import ArrowUpBold from 'mdi-material-ui/ArrowUpBold';
import ArrowDownBold from 'mdi-material-ui/ArrowDownBold';
import DeskLamp from 'mdi-material-ui/DeskLamp';
import Stop from 'mdi-material-ui/Stop';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { remoteControlService, REMOTE_ACTIONS } from '../../../services/remote_control_service';


const styles = (theme) => ({
    root: {
        flexDirection: "row",
        display: 'flex'
    },
    buttonBar: {
        flexDirection: "column",
        display: 'flex'
    },
    centerBtn: {
        padding: 'unset',
        minWidth: 'unset',
        borderLeftWidth: "1px",
        borderLeftStyle: "solid",
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderColor: theme.palette.action.disabledBackground,
        borderRadius: "0px",
        color: theme.palette.action.active
    },
    
    topBtn: {
        padding: 'unset',
        minWidth: 'unset',
        borderBottomStyle: 'unset',
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        color: theme.palette.action.active
    },
    bottomBtn: {
        padding: 'unset',
        minWidth: 'unset',
        borderTopStyle: 'unset',
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        color: theme.palette.action.active
    },
    titleIcon: {
        height: '14px',
        width: '14px',        
        marginLeft: theme.spacing(2),
    },
});


/* speed optimization. By putting the buttons in a sperate renderer, they don't need to be rendered each time the timer is updated. */
class ElevatorController extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: remoteControlService.elevator,
        }

        this.titleMoveUp = props.t('Raise the camera');
        this.titleStop = props.t('Stop');
        this.titleLower = props.t('Lower the camera');
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                
                <DeskLamp color="disabled" className={classes.titleIcon} />

                <div className={classes.buttonBar}>
                    <Tooltip title={this.titleMoveUp}>
                        <a>
                            <ToggleButton aria-label="Up"
                                id="Up"
                                value="up"
                                selected={this.state.value === REMOTE_ACTIONS.ELEVATOR_UP}
                                disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onClick={this.handleBtnPress(REMOTE_ACTIONS.ELEVATOR_UP)}
                                className={classes.topBtn}
                            >
                                <ArrowUpBold fontSize="large"/>
                            </ToggleButton>
                        </a>
                    </Tooltip>

                    <Tooltip title={this.titleStop}>
                        <a>
                            <Button aria-label="stop"
                                id="stop"
                                disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onClick={this.handleBtnPress(REMOTE_ACTIONS.ELEVATOR_STOP)}
                                className={classes.centerBtn}
                            >
                                <Stop fontSize="large"/>
                            </Button>
                        </a>
                    </Tooltip>


                    <Tooltip title={this.titleLower}>
                        <a>
                            <ToggleButton aria-label="Down"
                                id="Down"
                                value="down"
                                selected={this.state.value === REMOTE_ACTIONS.ELEVATOR_DOWN}
                                disabled={this.props.disabledButtons }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                onClick={this.handleBtnPress(REMOTE_ACTIONS.ELEVATOR_DOWN)}
                                className={classes.bottomBtn}
                            >
                                <ArrowDownBold fontSize="large"/>
                            </ToggleButton>
                        </a>
                    </Tooltip>
                </div>
            </div>

        );
    }

    handleBtnPress = (value) => () => {
        remoteControlService.requestState(value);
        this.setState({value});
    }

    handleRemoteUpdate = (action, value) => {
        if (action == REMOTE_ACTIONS.ELEVATOR_DOWN || action == REMOTE_ACTIONS.ELEVATOR_UP || action == REMOTE_ACTIONS.ELEVATOR_STOP) {
            this.setState({value: action});
        }
    }

}

ElevatorController.propTypes = {
    disabledButtons:PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(ElevatorController));