export function compareLists(a, b) {
    let remaining = [...b];                 // make a copy so we can remove items as we find them.
    for(const item in a) {
        const idx = remaining.indexOf(item);
        if (idx > -1) {
            remaining.splice(idx, 1);
        }
        else {
            return false;
        }
    }
    if (remaining.length > 0) {
        return false;
    }
    return true;
}


/**
 * moves the content from 'from' to 'to'
 * @param {array} from items will be copied to 'to' and this list will become empty
 * @param {array} to items will be added to the back
 */
export function moveContent(from, to) {
    to.push(...from);
    from.splice(0, from.length);
}
