/**
 * converts the all the project data to a list of csv data (flattens it out)
 * @param {list} data the values of the result of analyser.buildMap
 */
export function projectsToCsv(data) {
    let result = [[
        'purpose',
        'location',
        'street',
        'startPointRef',
        'startPointCoord',
        'pointRef1',
        'coordPoint1',
        'pointRef2',
        'coordPoint2',
        'pointRef3',
        'coordPoint3',
        'inspectionDirection',
        'locationType',
        'inspectionDate',
        'inspectionTime',
        'actualInspectionLength',
        'sewerUsage',
        'type',
        'code',
        'location',
        'continuous',
        'Characterization1',
        'Characterization2',
        'measurement1',
        'measurement2',
        'direction1',
        'direction2',
        'joint',
        'remark'
    ].join('|')];
    let prjDict = {};                                       // need to keep track of all the projects, so we don't render the same project 2 times
    for(const item of data) {
        for (const project of item.projects) {
            if (project.id in prjDict) continue;
            prjDict[project.id] = project;
            let prjRec = [
                project.main.purpose,
                project.main.location,
            ];
            for (const streng of project.strengs) {
                let strengRec = [
                    streng.location,
                    streng.startPointRef,
                    streng.startPointCoord,
                    streng.pointRef1,
                    streng.coordPoint1,
                    streng.pointRef2,
                    streng.coordPoint2,
                    streng.pointRef3,
                    streng.coordPoint3,
                    streng.inspectionDirection,
                    streng.locationType,
                    streng.inspectionDate,
                    streng.inspectionTime,
                    streng.actualInspectionLength,
                    streng.sewerUsage,
                    streng.type,
                ];
                for (const obs of streng.observations) {
                
                    let rec = [...prjRec, ...strengRec, 
                        obs.values.slice(0, 3).join(''),
                        obs.longitude,
                        !!obs.continuousIdx,
                        obs.values.length > 3 ? obs.values[3] : "",
                        obs.values.length > 4 ? obs.values[4] : "",
                        obs.waarde1 ?? "",
                        obs.waarde2 ?? "" ,
                        obs.direction1 ?? "",
                        obs.direction2 ?? "",
                        obs.joint,
                        obs.remark ?? "",
                    ];
                    result.push(rec.join('|'));
                }
            }
        }
    }
    return result.join('\r\n');
}



/**
 * adjusts the value according to the specified calibration, if any.
 * when no calibration, the original value is returned. 
 * When value is between min & max, it becomes 0, otherwise, we use center to adjust value.
 * @param {number} value the value to adjust according to the calibration settings
 * @param {object} calibration optional, should contain: min, max, center values of the calibration.
 * @param {number} center when the center is no 0, use this.
 * @returns the value adjusted to the calibration
 */
export function adjustForCalibration(value, calibration, center=0) {
    if (calibration) {
        if (value < calibration.min || value > calibration.max) {
            return value - (center - calibration.center);
        }
        return center;
    }
    else {
        return value;
    }
}