/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react'; 
import { codeSelectorService } from '../../../services/code_selector_service';
import { isEmpty } from '../../../services/project_service';

/**
 * platform generic base class for robot recorders & players..
 */
export class ObservationFocusService {
    constructor(parent) {
        this.parent = parent;
        this.jRef = React.createRef();                                            // joint checkbox
        this.iRef = React.createRef();                                              // include-image-button 
        this.tfRef1 = React.createRef();                                            // textfield (value) 1
        this.tfRef2 = React.createRef();                                            // textfield (value) 2
        this.dRef1 = React.createRef();                                             // direction 1
        this.dRef2 = React.createRef();                                             // direction2
        this.rRef = React.createRef();                                              // remarks
        this.readyBtnRef = React.createRef();                                       // ready button
        this.lRef = React.createRef();                                              // descriptiveLoc
        this.cRef = React.createRef();                                              // continuous idx
        this.locRef = React.createRef();                                            // location/longitude
        this.timeRef= React.createRef();                                            // time 

        this.sonarRef = React.createRef();                                          // include sonar image
        this.lidarRef = React.createRef();                                          // include lidar image

        this.refs = [];                                                             // for easy nav
        this.tfRef1Idx = 1;
        this.tfRef2Idx = this.tfRef1Idx + 1;
        this.dRef1Idx = this.tfRef1Idx + 2;
        this.dRef2Idx = this.tfRef1Idx + 3;
        this.rRefIdx = this.tfRef1Idx + 4;
        this.cRefIdx = this.tfRef1Idx + 5;
        this.lRefIdx = this.tfRef1Idx + 6;
        this.jRefIdx = null;
        this.iRefIdx = null;
        this.readyBtnRefIdx = null;
        this.locRefIdx = null;
        this.timeRefIdx = null;
        this.sonarRefIdx = null;
        this.lidarRefIdx = null;
        this.currentlyFocused = 0;                                                // 0 = code selector, 1 =w1, 2= d1, 3=w2, 4=d2, 5=remark. For handling focus ourselves.
    }


    buildRefs(hasSonar, hasLidar) {
        if (this.parent.props.strengType === "m") {
            this.refs = [this.tfRef1, this.tfRef2, this.dRef1, this.dRef2, this.rRef, this.cRef, this.lRef, this.jRef, this.iRef];  // can't do this apparently in the constructor
        }
        else {
            this.refs = [this.tfRef1, this.tfRef2, this.dRef1, this.dRef2, this.rRef, this.cRef, this.jRef, this.iRef];  // can't do this apparently in the constructor
        }
        this.jRefIdx = this.refs.length - 1;                                                                            // at this stage, jRef is always next-to-last, but we work with index base 1
        this.iRefIdx = this.jRefIdx + 1;
        if (hasSonar) {
            this.sonarRefIdx = this.refs.length + 1;
            this.refs.push(this.sonarRef);    
        }
        if (hasLidar) {
            this.lidarRefIdx = this.refs.length + 1;
            this.refs.push(this.lidarRef);    
        }

        this.readyBtnRefIdx = this.refs.length + 1;                                                                     // 1 based
        this.locRefIdx = this.readyBtnRefIdx + 1;
        this.timeRefIdx = this.readyBtnRefIdx + 2;
        this.refs.push(this.readyBtnRef, this.locRef, this.timeRef);
    }

    /**moves focus to the last available component.
     * returns true if this is not the selectorComponent
     */
    focusLast() {
        for (let i = this.refs.length - 1; i >= 0; i--) {
            if (this.refs[i].current) {
                this.currentlyFocused = i + 1;
                this.refs[i].current.focus();
                return true;
            }
        }
        return false;
    }

    /**
     * moves focus to the first component that has an (empty) error.
     */
    focusFirstError() {
        const def = this.parent.state.observationDef;
        const obs = this.parent.props.data;
        const prevFocused = this.currentlyFocused;
        if (this.parent.state.valueComplete == false) this.currentlyFocused = 0;
        else if (def !== null && obs !== null) {
            const valueCount = codeSelectorService.getValueCount(def, this.parent.props.strengType);
            const directionCount = codeSelectorService.getDirectionCount(def, this.parent.props.strengType);
            if (valueCount > 0 && isEmpty(obs.waarde1)) this.currentlyFocused = this.tfRef1Idx;
            else if (directionCount > 0 && isEmpty(obs.direction1)) this.currentlyFocused = this.dRef1Idx;
            else if (valueCount > 1 && isEmpty(obs.waarde2)) this.currentlyFocused = this.tfRef2Idx;
            else if (directionCount > 1 && isEmpty(obs.direction2)) this.currentlyFocused = this.dRef2Idx;
            else if (def.remark == true && isEmpty(obs.remark)) this.currentlyFocused = this.rRefIdx;
        }
        if (prevFocused != this.currentlyFocused) {
            this.updateFocus();
        }
    }

    moveToFirstAfterCode() {
        const def = this.parent.state.observationDef;
        const obs = this.parent.props.data;
        if (this.parent.state.valueComplete == false) this.currentlyFocused = 0;
        else if (this.parent.state.values[this.parent.state.values.length - 1] === "1") {
            if (this.cRef.current) {
                this.currentlyFocused = this.cRefIdx;
            } else {
                this.currentlyFocused = this.readyBtnRefIdx;
            }
        }
        else if (def !== null && obs !== null) {
            const valueCount = codeSelectorService.getValueCount(def, this.parent.props.strengType);
            const directionCount = codeSelectorService.getDirectionCount(def, this.parent.props.strengType);
            if (valueCount > 0) this.currentlyFocused = this.tfRef1Idx;
            else if (directionCount > 0) this.currentlyFocused = this.dRef1Idx;
            else if (def.remark !== false) this.currentlyFocused = this.rRefIdx;                    // when false, there is no comment, when true, it is required, when not specified, it is optional, but still present
            else if (this.parent.props.strengType === "m") this.currentlyFocused = this.lRefIdx;
            else this.currentlyFocused = this.jRefIdx;
        }
        this.updateFocus();
    }

    /**
     * returns true if the currently focused element is an input element. This can be used for instance to generically handle an enter key
     * for all input elements.
     */
    currentlyFocusedIsInput() {
        if (this.currentlyFocused > 0 && this.currentlyFocused <= this.refs.length) {
            const current = this.refs[this.currentlyFocused - 1];
            return [this.tfRef1, this.tfRef2, this.rRef].indexOf(current) > -1;
        }
        return false;
    }

    /**returns false when the currently focused element is an input field and the cursor is not at the start (when atSTart =true)
     * or at the end (when atStart==false).
     * 
    */
    notInText(atStart) {
        if (this.currentlyFocused > 0 && this.currentlyFocused <= this.refs.length) {
            const current = this.refs[this.currentlyFocused - 1].current;
            if (current && (current.type == "textarea" || current.type == "text")) {
                if ((atStart && current.selectionStart !== 0) || (!atStart && current.selectionStart !== current.value.length)) return false;
            }

        }
        return true;
    }

    moveLeft() {
        this.currentlyFocused--;
        while (this.currentlyFocused > 0) {
            if (this.refs[this.currentlyFocused-1] && this.refs[this.currentlyFocused-1].current) {         // the item is visible, so tab to it.
                this.refs[this.currentlyFocused-1].current.focus();
                return;
            }
            this.currentlyFocused--;
        }
        if (this.currentlyFocused === 0)  {                         // should be the case when we get here.
            this.parent.setState({allowSelectorFocus: true, focusedValueIdx: this.parent.props.data.values.length-1});              // this allows the codeselector to capture focus. should move to end, if not, need to have a focusedValueIdx state
        }
    }

    moveRight() {
        this.currentlyFocused++;
        if (this.currentlyFocused < 0) return;                           // internal error actually
        while(this.currentlyFocused <= this.refs.length) {
            if (!this.refs[this.currentlyFocused-1]) { return }         // savety feature, so that it doesn't crash to much if things go wrong.
            if (this.refs[this.currentlyFocused-1].current) {
                this.refs[this.currentlyFocused-1].current.focus();
                return;
            }
            this.currentlyFocused++;
        }
        // when we get here, it's the end of the list, so either go back to start or move to next item
        this.currentlyFocused = 0;
        this.parent.setState({allowSelectorFocus: true, focusedValueIdx: 0});
    }

    updateFocus = () => {
        if (this.currentlyFocused === 0)  {
            this.parent.setState({allowSelectorFocus: true});              // this allows the codeselector to capture focus. should move to end, if not, need to have a focusedValueIdx state
        }
        else if (this.refs[this.currentlyFocused-1].current) {
            this.refs[this.currentlyFocused-1].current.focus();
        }
    }

    /**called when the codeselector wants to move left but it's already reached the start.
     * so either go back to previous rec or move to end of current
     */
    handleStartOfFocusListReached = () => {
        //this.props.onGotoPrev();
        this.currentlyFocused = this.refs.length + 1;                   // move back to end, do + 1 cause it does a substract first and the value is 1 based
        this.moveLeft();
    }
}