/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */
import CrosshairsGps from 'mdi-material-ui/CrosshairsGps';


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { gpsService } from '../../../services/gps/gps_service';
import { CoordinatesToAddress } from '../../services/geo_service';
import { dialogService } from '../../../services/dialog_service';
import { errExtractor } from '../../services/error_extractor';


const styles = (theme) => ({
    root: {
        
    },
    row : {
        flexDirection: 'row', 
        display:'flex', 
        alignItems: 'center', 
        justifyContent:'flex-end'
    }

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class GpsAdorner extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { t } = this.props;
        return (
            <div className={this.props.classes.row}>
                {this.props.editor}
                {(!this.props.readOnly) && 
                    <Tooltip title={t("auto_fill_location")}>
                        <span>
                            <IconButton 
                                aria-label="gps" 
                                size="small" 
                                disabled={!gpsService.isAvailable}
                                onClick={this.handleGetLocation}>
                                <CrosshairsGps />
                            </IconButton>
                        </span>
                    </Tooltip>
                }
            </div>
        );
    }

    handleGetLocation = async () => {
        const { t } = this.props;
        try {
            const pos = await gpsService.currentPos();
            if (pos) {
                if (pos.lat === null || pos.lon === null) {
                    throw new Error(t("invalid_gps_cooordinates"));
                }
                else {
                    const location = await CoordinatesToAddress(pos.lat, pos.lon);
                    if (this.props.onLocation) {
                        if (this.props.field == "route" && location.number != undefined) {                          // auto add number when route/street is needed
                            this.props.onLocation(`${location[this.props.field]} ${location.number}`);
                        }
                        else {
                            this.props.onLocation(location[this.props.field]);
                        }
                    }
                }
            }
            else {
                dialogService.error(t("Gps"), t("invalid_gps_cooordinates"));
            }
        }
        catch (error) {
            dialogService.error(t("Gps"), errExtractor.get(error));
        }
    }
}

GpsAdorner.propTypes = {
    editor: PropTypes.object,                               // the textfield to adorn
    readOnly: PropTypes.bool,                               // when readonly, no buttons shown
    field: PropTypes.string.isRequired,                     // the field to return: city or street
    onLocation: PropTypes.func,                             // called when a location is found, param; the string value
};

export default withTranslation()(withStyles(styles)(GpsAdorner));
