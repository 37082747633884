/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactResizeDetector from 'react-resize-detector';

const styles = (theme) => ({
    leftCol: {
        marginRight: theme.spacing(1),
        flex: 1,
        flexDirection: 'column',
        display: 'flex'
    },
    rightCol: {
        marginLeft: theme.spacing(1),
        flex: 1,
        flexDirection: 'column',
        display: 'flex'
    },

    leftColSingle: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex'
    },
    rightColSingle: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex'
    },


    rootTwoCol: {
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        flexGrow: 1,
        display: 'flex'
    },
    rootOneCol: {
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        flexGrow: 1,
        display: 'flex'
    }
});


class TwoColLayout extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rootStyle : this.props.classes.rootOneCol,
            leftChildStyle: null,
            rightChildStyle: null
        }
    }

    render() {
        return (
            <div className={this.state.rootStyle}>
                <ReactResizeDetector handleWidth onResize={this.onResize} />
                <div className={this.state.leftChildStyle}>
                   {this.props.left}
                </div>
                <div className={this.state.rightChildStyle}>
                    {this.props.right}
                </div>
            </div>
        );
    }

    /**
     * called when the root element gets resized. we use this to recalculate 1 or 2 col layout
     */
    onResize = (width) => {
        if (width > 320) {
            this.setState({rootStyle: this.props.classes.rootTwoCol, leftChildStyle: this.props.classes.leftCol, rightChildStyle: this.props.classes.rightCol});
        }
        else {
            this.setState({rootStyle: this.props.classes.rootOneCol, leftChildStyle: this.props.classes.leftColSingle, rightChildStyle: this.props.classes.rightColSingle});
        }
    }
}

TwoColLayout.propTypes = {
    left: PropTypes.any.isRequired,
    right: PropTypes.any.isRequired,
};

export default withStyles(styles)(TwoColLayout);