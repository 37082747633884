/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from "../../../../services/dialog_service";
import { sonarService } from './sonar_service';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '350px',
        padding: theme.spacing(1),
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    value: {
        marginBottom: theme.spacing(1),
        textAlign: 'center'
    }

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class SonarConfigPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        const sonar = sonarService.provider;
        if (sonar) {
            const limits = sonar.limits;
            this.state = {
                range: sonar.range && limits.range ? limits.range.indexOf(sonar.range) : 0,
                pulse: sonar.pulse ?? 0,
                gain: sonar.gain ?? 0,
                freq: sonar.frequency ?? 0,
                grayMode: sonarService.grayMode,
                low: sonarService.lowCutoff,                                      // min val
                high: sonarService.highCutoff,                                      // max val -> all above become max
            }
            this.canSetPulse = sonar.canSetPulse();
            this.canSetGain = sonar.canSetGain();
            this.canSetFreq = sonar.canSetFrequency();

        }
        else {
            this.state = {
                range: 0,
                pulse: 0,
                gain: 0,
                freq: 0,
                grayMode: false,
                low: 0,                                      // min val
                high: 255,                                      // max val -> all above become max
            }
    
            this.canSetPulse = false;
            this.canSetGain = false;
            this.canSetFreq = false;
        }

        this.rangeTxt = i18n.t('Range');
        this.pulseTxt = i18n.t('Pulse');
        this.gainTxt = i18n.t('Gain');
        this.FreqTxt = i18n.t('Frequency');
        this.grayModeTxt = i18n.t('Gray mode');
        this.lowTxt = i18n.t('Low');
        this.hightTxt = i18n.t('High');
    }


    render() {
        const { classes } = this.props;
        const limits = sonarService.provider.limits ?? {pulse: { max: 5, min: 1, step: 1}, range: []};
        const range = this.state.range > -1 ? limits.range[this.state.range] : 0;
        return (
            <Popover anchorEl={this.props.anchor}
                open={Boolean(this.props.anchor)}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                onClose={this.props.onClose}>
                <div className={classes.root}>
                    {(!this.props.playMode) &&
                        <div className={classes.box}>
                            <div>{this.rangeTxt}</div>
                            <div className={classes.value}>{range}mm</div>
                            <Slider
                                value={this.state.range}
                                orientation="vertical"
                                onChange={this.handleSetRange}
                                max={limits.range.length}
                                min={0}
                                step={1}
                            />
                        </div>
                    }
                    {(this.canSetPulse && !this.props.playMode) &&
                        <div className={classes.box}>
                            <div>{this.pulseTxt}</div>
                            <div className={classes.value}>{this.state.pulse}us</div>
                            <Slider
                                value={this.state.pulse}
                                orientation="vertical"
                                onChange={this.handleSetPulse}
                                max={limits.pulse.max}
                                min={limits.pulse.min}
                                step={limits.pulse.step}
                            />
                        </div>
                    }

                    {(this.canSetGain && !this.props.playMode) &&
                        <div className={classes.box}>
                            <div>{this.gainTxt}</div>
                            <div className={classes.value}>{this.state.gain}x</div>
                            <Slider
                                className={this.props.classes.zoomSlider}
                                value={this.state.gain}
                                orientation="vertical"
                                onChange={this.handleSetGain}
                                max={255}
                                min={140}
                                step={1}
                            />
                        </div>
                    }

                    {(this.canSetFreq && !this.props.playMode) &&
                        <div className={classes.box}>
                            <div>{this.FreqTxt}</div>
                            <div className={classes.value}>{this.state.freq}x</div>
                            <Slider
                                className={this.props.classes.zoomSlider}
                                orientation="vertical"
                                value={this.state.freq}
                                onChange={this.handleSetFreq}
                                max={1600}
                                min={1500}
                                step={1}
                            />
                        </div>
                    }


                    <div className={classes.box}>
                        <div>{this.lowTxt}</div>
                        <div className={classes.value}>{this.state.low}</div>
                        <Slider
                            className={this.props.classes.zoomSlider}
                            orientation="vertical"
                            value={this.state.low}
                            onChange={this.handleSetLow}
                            max={255}
                            min={0}
                            step={1}
                        />
                    </div>
                    <div className={classes.box}>
                        <div>{this.hightTxt}</div>
                        <div className={classes.value}>{this.state.high}</div>
                        <Slider
                            className={this.props.classes.zoomSlider}
                            orientation="vertical"
                            value={this.state.high}
                            onChange={this.handleSetHigh}
                            max={255}
                            min={0}
                            step={1}
                        />
                    </div>
                    <FormControlLabel
                        style={{alignSelf: 'flex-end'}}
                        control={
                            <Checkbox
                                checked={this.state.grayMode}
                                onChange={this.handleSetGrayMode}
                                name="grayMode"
                                color="primary"
                            />
                        }
                        label={this.grayModeTxt}
                    />
                </div>
            </Popover>
        );
    }

    handleSetRange = (event, value) => {
        try {
            const limits = sonarService.provider.limits;
            sonarService.provider.range = limits.range[value];
            this.setState({range: value});
        }
        catch(error) {
            dialogService.error(i18n.t("Sonar"), errExtractor.get(error));
        }
    }

    handleSetPulse = (event, value) => {
        try {
            sonarService.provider.pulse = value;
            this.setState({pulse: value});
        }
        catch(error) {
            dialogService.error(i18n.t("Sonar"), errExtractor.get(error));
        }
    }

    handleSetFreq = (event, value) => {
        try {
            sonarService.provider.frequency = value;
            this.setState({freq: value});
        }
        catch(error) {
            dialogService.error(i18n.t("Sonar"), errExtractor.get(error));
        }
    }

    handleSetGain = (event, value) => {
        try {
            sonarService.provider.gain = value;
            this.setState({gain: value});
        }
        catch(error) {
            dialogService.error(i18n.t("Sonar"), errExtractor.get(error));
        }
    }

    handleSetLow = (event, value) => {
        try {
            sonarService.lowCutoff = value;
            this.setState({low: value});
        }
        catch(error) {
            dialogService.error(i18n.t("Sonar"), errExtractor.get(error));
        }
    }

    handleSetHigh = (event, value) => {
        try {
            sonarService.highCutoff = value;
            this.setState({high: value});
        }
        catch(error) {
            dialogService.error(i18n.t("Sonar"), errExtractor.get(error));
        }
    }

    handleSetGrayMode = (event) => {
        try {
            const value = !this.state.grayMode;
            sonarService.grayMode = value;
            this.setState({grayMode: value});
        }
        catch(error) {
            dialogService.error(i18n.t("Sonar"), errExtractor.get(error));
        }
    }
}

SonarConfigPopup.propTypes = {
    anchor: PropTypes.any,                      // locatio for the popup
    onClose: PropTypes.func,
    playMode: PropTypes.bool,                   // when true, we are in replay mode and not in live mode, so we can't set the actual sonar in that case.
};

export default withStyles(styles)(SonarConfigPopup);