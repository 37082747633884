/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import i18n from "i18next";
import { dialogService } from '../../../services/dialog_service';




/**
 * used to load meta data from a video element
 */
class VideoMetaLoader extends React.Component {
    constructor(props) {
        super(props);
        this.video = React.createRef();
    }

    componentDidMount() {
        const video = this.video.current;
        video.addEventListener('loadedmetadata', (ev) => {
            if (this.props.onMeta) {
                this.props.onMeta(video);
            }
        });
        video.addEventListener('error', (error) => {                         // when there is an error loading the vidoe, make certain that the process doesn' stop.
            console.log("error loading video");
            console.log(video.error.message);
            dialogService.error(i18n.t("video"), i18n.t("unable_to_load_meta_data"));
            if (this.props.onMeta) {
                this.props.onMeta();
            }
        });
    }


    render() {
        return (
            <video ref={this.video} src={this.props.filename} autoPlay={false} style={{visibility: 'hidden'}}/>
        );
    }
}

VideoMetaLoader.propTypes = {
    filename: PropTypes.string.isRequired,
    onMeta: PropTypes.func,                             // called when meta data is loaded (video in param), or when failure occured (no param)
};


export default VideoMetaLoader;