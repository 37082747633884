/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { toRadian } from '../../../services/cal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from "i18next";
import CalibrateCamPosDialog from '../../../settings/calibrate_campos_dialog';
import { engineService, setActiveRobotCalibration, getActiveRobotCalibration } from '../../../../components/video_engine/engine_service';
import { documentControlService } from '../../document_control_service';

const styles = (theme) => ({
    root: {
        position: 'relative',
    },
    content: {
        top: "0px",
        bottom: "0px",
        position: "absolute",
        left: "0",
        right: "0",
        margin: "auto",
        width: "fit-content",
        height: 'fit-content',
    },
    value: {
        fontSize: 'x-small',
        color: theme.palette.text.secondary
    }
});


class CamerapPosition extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            popupPos: null,                         // for the context menu
            showCalibration: false,                 // when true, we show the dialog
        }

        this.tilt = props.tilt ?? 0;
        this.pan = props.pan ?? 0;
        this.canvasRef = React.createRef();

        if (props.handle) {                         // load at create to get fastest possible results
            props.handle(this);
        }
        this.calibrateTxt = i18n.t("Calibreer");
        this.panAdjust = 0;                         // some systems have the pan vertical, others horizontal as a start pos
    }

    componentWillUnmount() {
        if (this.props.handle) {
            this.props.handle(null);
        }
    }

    componentDidMount () {
        if (this.props.handle) {                    // can be the it was unmounted and mounted again, so re-register
            this.props.handle(this);
        }
        const robot = documentControlService.robotDs;
        this.panAdjust = robot ? (robot.sensorLimits.panAdjust ?? 0) : 0;
        this.setSize();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.radius !== prevProps.radius) {
            this.setSize();
        }
    }

    setSize() {
        const canvas = this.canvasRef.current;
        if (canvas && this.props.radius) {
            const size = (this.props.radius + 2) * 2;
            canvas.width = size;
            canvas.height = size;
            this.redraw();
        }
    }

    render() {
        return (
            <Tooltip title={this.props.tooltip}>
                <div onContextMenu={this.showMenu}>
                    <canvas ref={this.canvasRef}/>
                    <Menu open={this.state.popupPos !== null}
                        onClose={this.handleCloseMenu}
                        anchorReference="anchorPosition"
                        anchorPosition={ this.state.popupPos}>
                        <MenuItem onClick={this.handleCalibrate} disabled={this.props.readOnly}>{this.calibrateTxt}</MenuItem>
                    </Menu>
                    {(this.state.showCalibration) &&
                        <CalibrateCamPosDialog open={this.state.showCalibration} onClose={this.handleCloseCalibration} onChanged={this.handleReloadCalibration} />
                    }
                </div>
            </Tooltip>
        );
    }

    showMenu = (event) => {
        event.preventDefault();
        this.setState({popupPos: { top: event.clientY - 4, left: event.clientX - 2 }});
    }

    setValue = (tilt, pan) => {
        if ((tilt !== this.tilt) || (pan !== this.pan)) {   
            this.tilt = tilt;
            this.pan = pan;
            //console.log("tilt: ", tilt, ", pan: ", pan);
            this.redraw();
        }
    }

    redraw() {
        const canvas = this.canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const radius = this.props.radius; 
            const innerRad = radius / 6;
            const center = radius + 2;
            const pan = Math.cos(toRadian(this.tilt + 90)) * (radius - innerRad);         //+90 to move to the middle of the circle,  - innerRad cause we need room at the end for the dot to show, otherwise the dot falls out of the bounds of the outer circle
            
            ctx.strokeStyle = this.props.borderColor;               
            ctx.lineWidth = 2;
            ctx.beginPath();                                        // outer circle
            ctx.arc(center, center, radius, 0, Math.PI * 2);
            ctx.stroke();
             
            const height = innerRad*2;
            const width = canvas.width - 4;
            const x = 2;
            const y = radius + 2 - innerRad;
            ctx.translate(center, center);                          // need to assign the center of the rotation
            ctx.rotate(toRadian(this.pan+90-this.panAdjust));
            ctx.translate(-center, -center);                        // reset center to draw 
            ctx.beginPath();                                        // inner block start (tilt)
            ctx.moveTo(x, y + innerRad);
            ctx.lineTo(x, y + height - innerRad);
            ctx.arcTo(x, y + height, x + innerRad, y + height, innerRad);
            ctx.lineTo(x + width - innerRad, y + height);
            ctx.arcTo(x + width, y + height, x + width, y + height-innerRad, innerRad);
            ctx.lineTo(x + width, y + innerRad);
            ctx.arcTo(x + width, y, x + width - innerRad, y, innerRad);
            ctx.lineTo(x + innerRad, y);
            ctx.arcTo(x, y, x, y + innerRad, innerRad);
            ctx.stroke();

            ctx.strokeStyle = this.props.cameraColor;
            ctx.fillStyle = this.props.cameraColor;;
            ctx.lineWidth = 2;
            ctx.beginPath();                                    // pan (small inner circle)
            ctx.arc(center + pan, center, innerRad, 0, Math.PI * 2);
            ctx.stroke();
            ctx.fill();

            ctx.setTransform(1,0,0,1,0,0);                          // reset al the transforms (the rotate transform)

        }
    }

    handleCalibrate = () => {
        this.setState({popupPos: null, showCalibration: true});
    }

    handleCloseCalibration = () => {
        this.setState({popupPos: null, showCalibration: false});
    }

    handleReloadCalibration = (value) => {           
        if (value) {
            engineService.tractor.setCamPosCalibration(value);
            let calib = getActiveRobotCalibration();
            calib['camPos'] = value;
            setActiveRobotCalibration(calib);
        }
    }

    handleCloseMenu = () => {
        this.setState({popupPos: null});
    }
}

CamerapPosition.defaultProps = {
   borderColor: '#e6e6e6',
   cameraColor: "#5d9cec",
}

CamerapPosition.propTypes = {
    readOnly: PropTypes.bool,
    tilt: PropTypes.number,
    pan: PropTypes.number,
    tooltip: PropTypes.string,
    radius: PropTypes.number,
    handle: PropTypes.func,                             // used to let the parent get a ref to this object
    borderColor: PropTypes.string,
    cameraColor: PropTypes.string,
};

export default withStyles(styles)(CamerapPosition);