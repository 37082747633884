import { configService } from "../../../services/config_service";
import i18n from "i18next";
import { configDefaults } from "../../services/config_settings";
import { screenManager } from "../../../components/video_engine/screen_manager_service";
const events = require('events');


/**
 * provides features for managing the layout of the document.
 * this allows for quick adjustements in the layout to switch between tablet mode, wide-screen, normal modes,...
 */
class LayoutService {
    constructor() {
        this._presets = null;
        this.events = new events.EventEmitter();                            // when the selectedlayout has changed, raises 'changed'
    }

    load() {
        if (this._presets === null) {                                       // not yet loaded
            this._presets = configService.get('layout.document.presets');
            if (!this._presets || this._presets.length < 3) {
                this._presets = [
                    { name: 'default', layoutMode: 'default' },
                    { name: 'tablet', layoutMode: 'tablet' },
                    { name: 'wide screen', layoutMode: 'default' },
                ]
            }
        }
    }

    get presets() {
        this.load();
        return this._presets;
    }

    /**
     * stores the current layout under the specified name.
     * a layout is always based on one of the core parents (created by constructor), so we can reconstruct
     * the layouts based on these core parents. This way, when new features are added to the layouts, they
     * can get default values
     */
    storeCurrent(name) {
        const idx = this._presets.findIndex((item) => item.name === name);
        if (idx > -1 && idx < 3) {
            throw new Error(i18n.t("The standard layouts can't be replaced"));
        }

        let preset = {
            name: name, 
            layoutMode: configService.get('layout.document.layoutMode'),
            verSplit: configService.get('recorder.verticalSplitWidth'),
            horSplit: configService.get('recorder.horizontalSplitWidth'),
            showVideoSection: configService.get('layout.document.showVideoSection'),
            dashboardOpen: configService.get('layout.document.dashboardOpen'),
            robotSection: configService.get('layout.document.robotSection'),
            screens: screenManager.getDefinition(),
        };
        if (idx > -1) {                                                         // if name already used, replace, but only if not one of the default ones
            this._presets.splice(idx, 1, preset);
        }
        else {
            this._presets.push(preset);
        }
        configService.set("layout.document.presets", this._presets);
    }

    async applyPreset(value) {
        configService.set('layout.document.layoutMode', value.layoutMode);
        this.tryApplyPresetItem('recorder.verticalSplitWidth', value.verSplit, window.innerWidth / 2);
        this.tryApplyPresetItem('recorder.horizontalSplitWidth', value.horSplit, window.innerHeight / 2);
        this.tryApplyPresetItem('layout.document.showVideoSection', value.showVideoSection, configDefaults.layout.document.showVideoSection);
        this.tryApplyPresetItem('layout.document.dashboardOpen', value.dashboardOpen, configDefaults.layout.document.dashboardOpen);
        this.tryApplyPresetItem('layout.document.robotSection', value.robotSection, configDefaults.layout.document.robotSection);
        if (value.screens) {
            await screenManager.applyDefinition(value.screens);
        }
        else {
            screenManager.closeAll();                                                               // no screens, so close all
        }

        this.events.emit("changed", value);
    }

    tryApplyPresetItem(key, value, defaultVal) {
        let setter = value !== undefined ? value : defaultVal;
        configService.set(key, setter);
    }



    remove(idx) {
        this._presets.splice(idx, 1);
        configService.set("layout.document.presets", this._presets);
    }
}

export const layoutService = new LayoutService();