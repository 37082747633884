/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import { RobotPlayerBase } from '../../../shared_components/document/instrumentation/robot/robot_player_base_service';
import { storageService } from '../../../services/storage_service';
import { activeProjectService } from '../../../services/active_project_service';


export class RobotPlayer extends RobotPlayerBase  {
    constructor() {
        super();
    }


    /**
     * this is a re-implementatio of hte default one, cause the base implementation reads
     * data from google storage directly. This is not a good idea in web-app, so data is retrieved
     * from cloud
     * @param {object} streng the streng to get data for. when null, the active streng is used
     * @param {bool} reverse when true, the reverse data set is retrieved
     */
    internalLoadData(streng, reverse) {
        streng = streng ?? activeProjectService.activeStreng;
        if (reverse) {
            return storageService.getCsvData(streng.robotRevDataUrl);
        }
        else {
            return storageService.getCsvData(streng.robotDataUrl);
        }
    }
}

export const robotPlayer = new RobotPlayer();