/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { activeProjectService } from '../../../services/active_project_service';
import MainInfo from './main_info_comonent';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    text: {
        textAlign: 'center'
    }
});

class MainInfoDialog extends React.Component {
    constructor(props) {
        super(props);
        let data;
        if (activeProjectService.data) {
            data = activeProjectService.data
        }
        else {
            data = {main: {}, strengs: []};
        }
        this.state = {
            data: data,
        }
    }
    

    render() {
        const { t } = this.props;
        if (!this.props.open) {
            return null;
        }
        return (
            <Dialog
                maxWidth="lg"
                aria-labelledby="confirmation-dialog-title"
                open={this.props.open}
            >
                <DialogTitle id="confirmation-dialog-title">{t("Hoofd gegevens")}</DialogTitle>
                <DialogContent dividers>
                    <MainInfo data={this.state.data.main} 
                                showMissingErrors={this.props.showMissingErrors}
                                readOnly={this.props.readOnly}
                                />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCancel} color="primary">
                        {t("Sluiten")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleCancel = (event) => {
        if (this.props.onClose) this.props.onClose(null);
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();
    }
}

MainInfoDialog.defaultProps = {
    showMissingErrors: false,
    readOnly: false,
};

MainInfoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    showMissingErrors: PropTypes.bool,                                       //when true, user tried to switch, but not allowed, so show all (missing) errors
    readOnly: PropTypes.bool
};

export default withTranslation()(withStyles(styles)(MainInfoDialog));