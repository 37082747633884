/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */
//icons
import { ArrowUpBoldPath, ArrowDownBoldPath, ArrowRightBoldPath, ArrowLeftBoldPath, StopPath } from '../../../icons';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import CrosshairButtons from '../../../layouts/crosshair_buttons_component';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { remoteControlService, REMOTE_ACTIONS } from '../../../services/remote_control_service';
import { documentControlService } from '../../document_control_service';
import MeasuredIcon from '../../../controls/measured_icon_component';
import { ValueDirection } from '../../../controls/measured_icon_component';
import DuoMeasuredIcon from '../../../controls/duo_measured_icon_component';
import CalibrateAcceleroDialog from '../../../settings/calibrate_accelero_dialog';
import { engineService, setActiveRobotCalibration, getActiveRobotCalibration } from '../../../../components/video_engine/engine_service';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const styles = (theme) => ({
    buttonBar: {
        flexDirection: "column",
        display: 'flex'
    },
    centerBtn: {
        padding: 'unset',
        minWidth: 'unset',
        width: '37px',                           // set the width, otherwise we get 35, which doesn't align nicely
        color: theme.palette.action.active
    },
    leftBtn: {
        padding: 'unset',
        minWidth: 'unset',
        borderRightStyle: 'unset',
        borderBottomRightRadius: "0px",
        borderTopRightRadius: "0px",
        color: theme.palette.action.active
    },
    topBtn: {
        padding: 'unset',
        minWidth: 'unset',
        borderBottomStyle: 'unset',
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        color: theme.palette.action.active
    },
    rightBtn: {
        padding: 'unset',
        minWidth: 'unset',
        borderLeftStyle: 'unset',
        borderBottomLeftRadius: "0px",
        borderTopLeftRadius: "0px",
        color: theme.palette.action.active
    },
    bottomBtn: {
        padding: 'unset',
        minWidth: 'unset',
        borderTopStyle: 'unset',
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        color: theme.palette.action.active
    },
});

const supportedActions = [REMOTE_ACTIONS.BACK, REMOTE_ACTIONS.FORWARD, REMOTE_ACTIONS.LEFT, REMOTE_ACTIONS.RIGHT];

/* group of up/down/left/right/center buttons */
class TractorDirectionButtons extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: null,                                        // for up-down 
            value2: null,                                       // for left-right
            popupPos: null,                         // for the context menu
            showCalibration: false,                             // to calibrate the accelero meter
        }

        this.leftValue = React.createRef();                     // the arrow left. used to depict accelero, when used for tractor direction (instead of camera direction)
        this.rightValue = React.createRef();
        this.forwardValue = React.createRef();
        this.backValue = React.createRef();
        this.upDownValue = React.createRef();
        this.hasRobotDataListener = false;                      // documentControlService.robotDs can already be unloaded when this component unloads, so we can't count on that to unregister

    

        this.titleDown = props.t("Move Backward");
        this.titleUp = props.t("Move forward");
        this.titleLeft = props.t("Turn left");
        this.titleRight = props.t("Turn right")
        this.titleStop = props.t("Stop");
        this.calibrateTxt = props.t("Calibreer");
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
        this.handleRemoteUpdate(remoteControlService.drivingState);                 // set the init state properly
        const robot = documentControlService.robotDs;
        if (robot && robot.hasAccelerometer()) {
            documentControlService.events.addListener('onRobotData', this.handleRobotDataArrived);
            this.hasRobotDataListener = true;
        }
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
        if (this.hasRobotDataListener) {
            documentControlService.events.removeListener('onRobotData', this.handleRobotDataArrived);
        }
    }

    render() {
        const { classes } = this.props;

        const robot = documentControlService.robotDs;
        const showAccelero = robot && robot.hasAccelerometer();
        let sensorLimits = showAccelero ? robot.sensorLimits.accelero : null;
        if (!sensorLimits) {
            sensorLimits = { error: 0.4, warning: 0.2 };
        }
        const disabledReason = this.props.disabledReason ?  ', ' + this.props.disabledReason : null;
        const allowed = this.props.allowed;
        return (
            <div className={classes.buttonBar} onContextMenu={this.showMenu}>

                <CrosshairButtons 
                    leftBtn={
                        <Tooltip title={this.titleLeft + disabledReason}>
                            <a>
                                <ToggleButton aria-label="left"
                                    id="left"
                                    value="left"
                                    selected={this.state.value2 === REMOTE_ACTIONS.LEFT}
                                    disabled={this.props.disabledButtons || (allowed && !allowed.includes(REMOTE_ACTIONS.LEFT))}  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.handleBtnClick(REMOTE_ACTIONS.LEFT)}
                                    classes={{ root: classes.leftBtn }}
                                >
                                    <MeasuredIcon icon={ArrowLeftBoldPath} showValue={showAccelero} direction={ValueDirection.left} limits={sensorLimits} ref={this.leftValue} size="2.1875rem"/>
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                    rightBtn={
                        <Tooltip title={this.titleRight + disabledReason}>
                            <a>
                                <ToggleButton aria-label="right"
                                    id="right"
                                    value="right"
                                    selected={this.state.value2 === REMOTE_ACTIONS.RIGHT}
                                    disabled={this.props.disabledButtons || (allowed && !allowed.includes(REMOTE_ACTIONS.RIGHT))}  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.handleBtnClick(REMOTE_ACTIONS.RIGHT)}
                                    className={classes.rightBtn}
                                >
                                    <MeasuredIcon icon={ArrowRightBoldPath} showValue={showAccelero} direction={ValueDirection.right} limits={sensorLimits} ref={this.rightValue} size="2.1875rem" />
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                    topBtn={
                        <Tooltip title={this.titleUp + disabledReason}>
                            <a>
                                <ToggleButton aria-label="forward"
                                    id="forward"
                                    value="up"
                                    selected={this.state.value === REMOTE_ACTIONS.FORWARD}
                                    disabled={this.props.disabledButtons || (allowed && !allowed.includes(REMOTE_ACTIONS.FORWARD))}  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.handleBtnClick(REMOTE_ACTIONS.FORWARD)}
                                    className={classes.topBtn}
                                >
                                    <MeasuredIcon icon={ArrowUpBoldPath} showValue={showAccelero} direction={ValueDirection.top} limits={sensorLimits} ref={this.forwardValue}  size="2.1875rem"/>
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                    centerBtn={
                        <Tooltip title={this.titleStop + disabledReason}>
                            <a>
                                <Button aria-label="stop"
                                    id="stop"
                                    disabled={this.props.disabledButtons || (allowed && !allowed.includes(REMOTE_ACTIONS.STOP))}  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.handleBtnClick(REMOTE_ACTIONS.STOP)}
                                    className={classes.centerBtn}
                                >
                                    <DuoMeasuredIcon icon={StopPath} showValue={showAccelero} limits={sensorLimits} ref={this.upDownValue} />
                                </Button>
                            </a>
                        </Tooltip>

                    }
                    bottomBtn={
                        <Tooltip title={this.titleDown + disabledReason}>
                            <a>
                                <ToggleButton aria-label="backward"
                                    id="backward"
                                    value="down"
                                    selected={this.state.value === REMOTE_ACTIONS.BACK}
                                    disabled={this.props.disabledButtons || (allowed && !allowed.includes(REMOTE_ACTIONS.BACK))}  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.handleBtnClick(REMOTE_ACTIONS.BACK)}
                                    className={classes.bottomBtn}
                                >
                                    <MeasuredIcon icon={ArrowDownBoldPath} showValue={showAccelero} direction={ValueDirection.bottom} limits={sensorLimits} ref={this.backValue} size="2.1875rem" />
                                </ToggleButton>
                            </a>
                        </Tooltip>
                    }
                />
                <Menu open={this.state.popupPos !== null}
                    onClose={this.handleCloseMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={ this.state.popupPos}>
                    <MenuItem onClick={this.handleCalibrate} disabled={this.props.readOnly}>{this.calibrateTxt}</MenuItem>
                </Menu>
                {(this.state.showCalibration) &&
                    <CalibrateAcceleroDialog open={this.state.showCalibration} onClose={this.handleCloseCalibration} 
                        onChanged={this.handleReloadCalibration} profile={this.state.profile}
                    />
                }
            </div>

        );
    }

    showMenu = (event) => {
        event.preventDefault();
        this.setState({popupPos: { top: event.clientY - 4, left: event.clientX - 2 }});
    }

    handleCloseMenu = () => {
        this.setState({popupPos: null});
    }

    handleBtnClick = (value) => () => {
        remoteControlService.setDirection(value);               // this will call onData events which set the state
    }

    handleRemoteUpdate = (action, value) => {

        if (action === REMOTE_ACTIONS.STOP) {
            this.setState({ value: null, value2: null });
        }
        else {
            if (supportedActions.includes(action)) {
                this.setValueState(action);
            }
        }
    }

    setValueState(action) {
        const set1 = [REMOTE_ACTIONS.BACK, REMOTE_ACTIONS.FORWARD];
        if (set1.includes(action)) {
            this.setState({ value: action });
        }
        else {
            this.setState({ value2: action });
        }
    }

    handleRobotDataArrived = (value) => {
        let ax = value.aX;
        let ay = value.aY;
        let az = value.aZ;
        if (this.leftValue.current) {
            const x = ax < 0 ? -ax : 0;
            this.leftValue.current.setValue(x)
        }
        ;
        if (this.rightValue.current) {
            const x = ax > 0 ? ax : 0;
            this.rightValue.current.setValue(x)
        };
        if (this.forwardValue.current) {
            const z = az < 1 ? 1 - az : 0;
            this.forwardValue.current.setValue(z)
        };
        if (this.backValue.current) {
            const z = az > 1 ? az - 1 : 0;
            this.backValue.current.setValue(z);
        }
        if (this.upDownValue.current) {
            const valueUp = ay < 0 ? -ay : 0;
            const valueDown = ay > 0 ? ay : 0;
            this.upDownValue.current.setValue(valueUp, valueDown)
        };
    }

    handleCalibrate = () => {
        this.setState({popupPos: null, showCalibration: true});
    }

    handleCloseCalibration = () => {
        this.setState({popupPos: null, showCalibration: false});
    }

    handleReloadCalibration = (value) => {
        if (value) {
            engineService.tractor.setAcceleroCalibration(value);
            let calib = getActiveRobotCalibration();
            calib['accelero'] = value;
            setActiveRobotCalibration(calib);
        }
    }

}

TractorDirectionButtons.propTypes = {
    disabledButtons: PropTypes.bool,
    disabledReason: PropTypes.string,                   // the reason why it is disabled, so we can inform the user
    allowed: PropTypes.array,
};


export default withTranslation()(withStyles(styles)(TractorDirectionButtons));