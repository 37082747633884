/* global google */                         // need this for google
import Autocomplete from 'react-google-autocomplete';
import { getSelectedLanguage } from '../../../services/translation_service';
import { Loader } from '@googlemaps/js-api-loader';

export class GoogleAutoComplete extends Autocomplete {
    constructor() {
        super();
        this.handleLoadScript = this._handleLoadScript;
        this.language = getSelectedLanguage();
    }

    _handleLoadScript = () => {

        try {
            if (Loader.instance && Loader.instance.language !== this.language) {            // was already loaded but reloading for new language.
                document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
                    script.remove();
                });
                if (google) delete google.maps;
                Loader.instance = null;
            }
            if (!Loader.instance) {
                const loader = new Loader({
                    apiKey: this.props.apiKey,
                    libraries: ["places", "visualization", "geometry"],                                     // note: need visualization for heatmaps. since this lib can only be loaded 1 time, need to load all we can possibly need
                    language: this.language
                });
                return loader.load();
            }
            else {
                return Promise.resolve();
            }
        }
        catch (error) {
            console.log(error);
        }


    }
}