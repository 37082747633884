import React, { Component } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import { configService } from './services/config_service';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { userService } from './shared_components/services/user_service';
import Login from './shared_components/auth/login_component';
import ResetPwd from './shared_components/auth/reset_pwd_component';
import Register from './shared_components/auth/register_component';
import Home from './components/start/home_component';
import Settings from './components/settings/settings_component';
import ARPage from './components/ar/ar_page';
import Toast from './components/document/toast_component';
import Document from './shared_components/document/document_component';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

require('typeface-roboto');

const theme = createMuiTheme({
    colors: {
    }
});

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={( props) => {
                if(userService.getCurrentUser() )
                {return (<Component {...props}/>)}
                else {
                      return (<Redirect to= {{pathname: "/login",state: { from: props.location }}}  from={props.location} />)
                }
            }}
        />
    );
}

class App extends Component {

    constructor(props) {
        super(props);
        configService.load();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className="App">
                        <CssBaseline />
                        <Toast />
                        <Router>
                            <Switch>
                                <PrivateRoute exact path='/doc/:edit' component={Document} />
                                <Route exact path='/settings' component={Settings} />
                                <Route exact path='/register' component={Register} />
                                <Route exact path='/login' component={Login} />
                                <Route exact path='/reset' component={ResetPwd} />
                                <PrivateRoute exact path='/ar' component={ARPage} />
                                <PrivateRoute exact path='/home' component={Home} />
                                <Route exact path='/'>
                                    <Redirect path='/' to="/home" />
                                </Route>
                            </Switch>
                        </Router>
                    </div>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        );
    }
}



export default App;
