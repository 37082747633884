/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2022] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LidarDistRolloutDisplay from './lidar_rollout_dist_display_component';
import LidarAngleRolloutDisplay from './lidar_rollout_angle_display_component';
import { calculateAngle } from '../../../services/cal';


const styles = (theme) => ({
    root: {
        
    },
   

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class LidarRolloutMeasureCanvas extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            distanceValue: null,
            angleValue: null,
        }
        this.coord1 = null;
        this.coord2 = null;
        this.tempEndCoord = null;
        this.coord1Abs = null;                         // the real-world distance of the first coordinate, so we can change the position when scrolling/zooming
        this.coord2Abs = null;
        this.canvasRef = React.createRef();

        this.angleCoord1 = null;
        this.angleCoord2 = null;
        this.angleCoord3 = null;
        this.angleCoord4 = null;
        this.angleTempEndCoord = null;
        this.angleCoord1Abs = null;                         // the real-world distance of the first coordinate, so we can change the position when scrolling/zooming
        this.angleCoord2Abs = null;
        this.angleCoord3Abs = null;                         // the real-world distance of the first coordinate, so we can change the position when scrolling/zooming
        this.angleCoord4Abs = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showMeasDistRollout !== prevProps.showMeasDistRollout && this.props.showMeasDistRollout) {         // when showing again, render the previous line again.
            this.draw();
        }
        if (this.props.showMeasAngleRollout !== prevProps.showMeasAngleRollout && this.props.showMeasAngleRollout) {
            this.draw();
        }
    }

    render() {
        if (!this.props.showMeasDistRollout && !this.props.showMeasAngleRollout) {
            return null;
        }
        const { t } = this.props;
        const displays = [];
        this.renderMeasureDistance(displays);
        this.renderMeasuresAngle(displays);
        return (
            <div style={this.props.rootStyle}>
                {/*need an exta div to swith back to relative positioning first*/}
                <div style={{position: 'relative', width: '100%', height: '100%'}}>
                    <canvas ref={this.canvasRef} style={{width: '100%', height: '100%', cursor: "crosshair"}}
                        onClick={this.handleLidarClick} onMouseMove={this.handleLidarMouseMove}
                    />
                    {(displays.length > 0) &&  
                        <div style={{position: 'absolute', top: '16px', right: '16px'}}>
                            {displays}
                        </div>
                    }
                </div>
            </div>
        );
    }

    renderMeasureDistance(displays) {
        const isMeasuring = this.props.measureData?.type === "measure";
        if (this.props.showMeasDistRollout || isMeasuring) {
            displays.push(<LidarDistRolloutDisplay key={displays.length} showAccept={isMeasuring} onAccept={this.props.onMeasured} distanceValue={this.state.distanceValue}/>);
        }
    }

    renderMeasuresAngle(displays) {
        const isMeasuring = this.props.measureData?.type === "angle";
        if (this.props.showMeasAngleRollout || isMeasuring) {
            displays.push(<LidarAngleRolloutDisplay key={displays.length} showAccept={isMeasuring} onAccept={this.props.onMeasured} angleValue={this.state.angleValue}/>);
        }
        
    }

    handleLidarClick = (ev) => {
        const isMeasuring = this.props.measureData?.type === "measure";
        const isMeasuringAngle = this.props.measureData?.type === "angle";
        if (!this.props.onMeasure) {
            throw new Error("internal error: missing onMeasure")
        }
        let processed = false;
        if (this.props.showMeasDistRollout || isMeasuring) {
            this.handleClickForDistance(ev);
            processed = true;
        }
        else if (this.props.showMeasAngleRollout || isMeasuringAngle) {
            this.handleClickForAngle(ev);
            processed = true;
        }
        if (processed) {
            ev.stopPropagation();
            ev.preventDefault();
            this.draw();
        }
    }

    handleClickForDistance(ev) {
        const pos = this.getRelMousePos(ev);
        if (this.coord1 === null && this.coord2 === null) {
            this.coord1 = pos;                                                  // first time: first angle
            this.coord1Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
            this.setState({distanceValue: null});
        }
        else if (this.coord2 === null) {
            this.coord2 = pos;
            this.coord2Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
            this.setState({distanceValue: this.props.onMeasure(this.coord1, pos)});                                                 //second time, second angle
        }
        else {
            // third time, reset and store as first angle
            this.coord1 = pos;
            this.coord1Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
            this.coord2 =  null;
            this.tempEndCoord = null;
            this.setState({distanceValue: null});                                 
        }
    }

    handleClickForAngle(ev) {
        const pos = this.getRelMousePos(ev);
        if (this.angleCoord1 === null && this.angleCoord2 === null && this.angleCoord3 === null && this.angleCoord4 === null) {
            this.angleCoord1 = pos;       
            this.angleCoord1Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
            this.setState({angleValue: null});
        }
        else if (this.angleCoord2 === null && this.angleCoord3 === null && this.angleCoord4 === null) {
            this.angleCoord2 = pos;
            this.angleCoord2Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
        }

        else if (this.angleCoord3 === null && this.angleCoord4 === null) {
            this.angleCoord3 = pos;
            this.angleCoord3Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
        }

        else if (this.angleCoord4 === null) {
            this.angleCoord4 = pos;
            this.angleCoord4Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
            const angle = calculateAngle(this.angleCoord1, this.angleCoord2, this.angleCoord3, this.angleCoord4)
            this.setState({angleValue: angle}); 
        }

        else {
            // 5th time, reset and store as first angle
            this.angleCoord1 = pos;
            this.angleCoord1Abs = this.props.onRellToAbsCoord(pos.x, pos.y);
            this.angleCoord2 = null;
            this.angleCoord3 = null;
            this.angleCoord4 = null;
            this.angleTempEndCoord = null;
            this.setState({angleValue: null});
        }
    }

    handleLidarMouseMove = (ev) => {
        const isMeasuring = this.props.measureData?.type === "measure";
        const isMeasuringAngle = this.props.measureData?.type === "angle";
        if (this.props.showMeasDistRollout || isMeasuring) {
            if (this.coord1 !== null && this.coord2 === null) {
                const pos = this.getRelMousePos(ev);
                this.tempEndCoord = pos; 
                this.setState({distanceValue: this.props.onMeasure(this.coord1, pos)});
                ev.stopPropagation();
                ev.preventDefault();
                this.draw();
            }
        }
        else if (this.props.showMeasAngleRollout || isMeasuringAngle) {
            if (this.angleCoord1 !== null && this.angleCoord4 === null) {               // when the 4th coord is given, stop tracking moveemnt, the value has been chosen
                const pos = this.getRelMousePos(ev);
                this.angleTempEndCoord = pos; 
                if (this.angleCoord1 && this.angleCoord2 && this.angleCoord3) {
                    const angle = calculateAngle(this.angleCoord1, this.angleCoord2, this.angleCoord3, pos);
                    this.setState({angleValue: angle});
                }
                ev.stopPropagation();
                ev.preventDefault();
                this.draw();
            }
        }
    } 

    /**
     * calculate the mouse position relative to the canvas.
     * @param {object} ev mouse event data
     */
     getRelMousePos(ev) {
        const canvas = this.canvasRef.current;
        if (!canvas) throw new Error("Internal error: no canvas");
        let rect  = this.canvasRef.current.getBoundingClientRect();
        let result = { x: ev.clientX - rect.left, y: ev.clientY - rect.top };
        return result;
    }

    draw() {
        const isMeasuring = this.props.measureData?.type === "measure";
        const isMeasuringAngle = this.props.measureData?.type === "angle";
        const canvas = this.canvasRef.current;
        if (!canvas) return;
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        const ctx = canvas.getContext('2d');
        
        if (this.props.showMeasDistRollout || isMeasuring) {                        // draw regular measure of line
            const coord2 = this.coord2 ?? this.tempEndCoord;
            if (this.coord1 && coord2) {
                this.drawMeasureLine(ctx, this.coord1, coord2);
            }
        }
        else if (this.props.showMeasAngleRollout || isMeasuringAngle) {             // draw 2 lines to measure the angle between them
            const angleCoord2 = this.angleCoord2 ?? this.angleTempEndCoord;
            if (this.angleCoord1 && angleCoord2) {
                this.drawMeasureLine(ctx, this.angleCoord1, angleCoord2);
                if (this.angleCoord3) {
                    const angleCoord4 = this.angleCoord4 ?? this.angleTempEndCoord;
                    this.drawMeasureLine(ctx, this.angleCoord3, angleCoord4);

                    const avg1 = {x: (this.angleCoord1.x + angleCoord2.x) / 2, y: (this.angleCoord1.y + angleCoord2.y)};
                    const avg2 = {x: (this.angleCoord3.x + angleCoord4.x) / 2, y: (this.angleCoord3.y + angleCoord4.y)};
                    ctx.moveTo(avg1.x, avg1.y);
                    ctx.lineTo(avg2.x, avg2.y);
                }
            }
        }

        
        
    }

    drawMeasureLine(ctx, coord1, coord2) {
        if (!coord1 || !coord2) {                       // can't draw the line
            return;
        }
        ctx.lineWidth = 1;
        ctx.strokeStyle  = 'black';
        ctx.beginPath();
        ctx.moveTo(coord1.x, coord1.y);
        ctx.lineTo(coord2.x, coord2.y);
        ctx.stroke();

        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(coord1.x, coord1.y, 2, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(coord2.x, coord2.y, 2, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
    }

    /**
     * called by the parent when scrolling/zooming
     */
    recalculateRelCoords() {
        if (this.props.onAbsToRelCoord) {
            if (this.coord1Abs) {
                this.coord1 = this.props.onAbsToRelCoord(this.coord1Abs.x, this.coord1Abs.y);
            }
            if (this.coord2Abs) {
                this.coord2 = this.props.onAbsToRelCoord(this.coord2Abs.x, this.coord2Abs.y);
            }
        }

        if (this.angleCoord1Abs) {
            this.angleCoord1 = this.props.onAbsToRelCoord(this.angleCoord1Abs.x, this.angleCoord1Abs.y);
        }
        if (this.angleCoord2Abs) {
            this.angleCoord2 = this.props.onAbsToRelCoord(this.angleCoord2Abs.x, this.angleCoord2Abs.y);
        }
        if (this.angleCoord3Abs) {
            this.angleCoord3 = this.props.onAbsToRelCoord(this.angleCoord3Abs.x, this.angleCoord3Abs.y);
        }
        if (this.angleCoord4Abs) {
            this.angleCoord4 = this.props.onAbsToRelCoord(this.angleCoord4Abs.x, this.angleCoord4Abs.y);
        }
        this.draw();
    }
}

LidarRolloutMeasureCanvas.propTypes = {
    showMeasDistRollout: PropTypes.bool,            // when true, the measurement result is shown & the user can perform measurements by clicking on the canvas
    showMeasAngleRollout: PropTypes.bool,            // when true, the measurement result is shown & the user can perform measurements by clicking on the canvas for measuring the angle between 2 lines
    measureData: PropTypes.any,                     // when lidar is available, we can perform measurements with that sensor, so we need to respond to measument requests
    rootStyle: PropTypes.any,                       // style to apply to the root object
    onMeasure: PropTypes.func.isRequired,           // callback taht is called to perform the actual measurement, can't do this here, need the zoom info, which depends on ui elements of the parent, so he can do it
    onRellToAbsCoord: PropTypes.func.isRequired,    // called to convert x & y, so we can move the line when scrolling/zooming
    onAbsToRelCoord: PropTypes.func.isRequired,     // called to convert x & y, so we can move the line when scrolling/zooming
}

LidarRolloutMeasureCanvas.defaultProps = {
    showMeasDistRollout: false,
    showMeasAngleRollout: false,
    
}

export default withStyles(styles)(LidarRolloutMeasureCanvas);