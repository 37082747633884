/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import PanoramaHorizontal from 'mdi-material-ui/PanoramaHorizontal';
import PanoramaVertical from 'mdi-material-ui/PanoramaVertical';
import Droplet from 'mdi-material-ui/Water';
import Camera from 'mdi-material-ui/Camera';
import AugmentedReality from 'mdi-material-ui/AugmentedReality';
import FormatAlignBottom from 'mdi-material-ui/FormatAlignBottom';
import FormatAlignTop from 'mdi-material-ui/FormatAlignTop';


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';
import { codeSelectorService } from '../../../services/code_selector_service';
import { cameraService } from '../../../../components/document/video/camera_service';
import { dialogService } from '../../../../services/dialog_service';
import { Tooltip } from '@material-ui/core';
import { ERROR_KEYS, isEmpty, PIPE_INSPECTIONS } from '../../../services/project_service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { configService } from '../../../../services/config_service';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ReactResizeDetector from 'react-resize-detector';


const styles = (theme) => ({
    titleBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: 'center'
    },
    titleToggle: {
        position: 'absolute', 
        height: '64px', 
        marginLeft: theme.spacing(2),
    },
    titleToggleSmall: {
        position: 'absolute', 
        marginLeft: '0px',
    },
    rightSideHeaderToolbox: {
        alignSelf: "center",
        right: theme.spacing(1), 
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
    },
    list: {
        backgroundColor: theme.palette.background.paper,
    },
    text: {
        textAlign: 'center'
    },
    summaryTotalRow: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: theme.spacing(2),
    },
    summaryTotalCell: {
        marginRight: theme.spacing(1)
    },
    summaryTotalValueCell: {
        color: theme.palette.text.secondary,
        whiteSpace: "nowrap",
    },
    detailLength: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(2),
        alignItems: 'flex-end'
    },
    detailTitle: {
        flex: 1,
        marginRight: theme.spacing(1)
    }
});

const LOCATION_WIDTH = '180px';

class StrengsSummary extends React.Component {
    constructor(props) {
        super(props);
        
        this.scrollbox = React.createRef();             // we need to know when the scollbar is visible so that we can adjust the margin of the header
        this.scrollbarWidth = null;                     // so we only need to calculate this 1 time
        const showInventory = configService.get("general.showInventory") ?? false;
        const showAR = configService.get("general.showAR") ?? false;
        const showObsDetails = configService.get("observations.showObsDetails") ?? false;
        this.state = {
            data: [],                                    // the calculated data to display. This is calculated when opened.
            totalActualLength: 0,
            totalExpectedLength: 0,
            innerWidth: 0,                              // for responsive behaviour
            showInventory: showInventory,
            scrollbarVisible: false,                    // the project overview needs to align with the content, but this changes depending on the scrolbar visibility, so monitor
            showObsDetails: showObsDetails,             // show detailed labels or keys for obs
            showAR: showAR,                              // toggle to select if user wants to go to AR or document clicking on a streng
        }
    }

    componentDidMount() {
        if (this.props.strengs && this.props.main) {
            this.calculateData();
        }
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();                                                        // alo make certain taht this is called when loaded as well
        const scrollbox = this.scrollbox.current;
        if (scrollbox) {
            const scrollbarVisible = scrollbox.scrollHeight > scrollbox.clientHeight;
            this.setState({scrollbarVisible: scrollbarVisible});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.strengs != this.props.strengs) {
            this.calculateData();
        }
        if (prevProps.scrollbarVisible === this.props.scrollbarVisible) {               // another property has changed
            const scrollbox = this.scrollbox.current;
            if (scrollbox) {
                const scrollbarVisible = scrollbox.scrollHeight > scrollbox.clientHeight;
                if (this.state.scrollbarVisible !== scrollbarVisible) {
                    this.setState({scrollbarVisible: scrollbarVisible});
                }
            }
        }
    }

    /**
     * called when the window is resized. Used to trigger responsive behaviour. also see handleResize
     */
    updateDimensions = () => {
        const scrollbox = this.scrollbox.current;
        let width;
        if (scrollbox) {
             width = scrollbox.clientWidth;
        }
        else {
            width = window.innerWidth;
        }
        this.setState({ innerWidth: width });
    }

    /**
     * for handling the resize f the innter component
     * @param {*} width 
     * @param {*} height 
     */
    handleResize = (width, height) => {
        this.setState({innerWidth:width});              // 24: 3 spaces (top, center, bottom), / 4 cause radius also needs / 2
    }

    calculateData() {
        let result = [];
        let totalExpectedLength = 0;
        let totalActualLength = 0;
        if (this.props.strengs) {
            for (let strengIdx = 0; strengIdx < this.props.strengs.length; strengIdx++) {
                let streng = this.props.strengs[strengIdx];
                let details = this.getStrengDetails(streng);
                let color;
                if (details.length > 0) {
                    color = details.length > 2 ? 'red' : 'orange';
                }
                result.push({ streng, details, color, idx: strengIdx });
                if (!isEmpty(streng.expectedInspectionLength)) {
                    totalExpectedLength += +(streng.expectedInspectionLength);
                }
                if (!isEmpty(streng.actualInspectionLength)) {
                    totalActualLength += +(streng.actualInspectionLength);
                }
            }
        }
        result.sort((a, b) => {
            if (a.streng.ref > b.streng.ref) { return 1 }
            if (a.streng.ref < b.streng.ref) { return -1 }
            return 0;
        });
        this.setState({ data: result, totalExpectedLength: totalExpectedLength.toFixed(2), totalActualLength: totalActualLength.toFixed(2) });
    }

    getStrengDetails(streng) {
        //const { t } = this.props;
        let errResults = [];
        let otherObs = [];                                                                  // everything that is not an error
        let idx = 0;
        let errorCodeIdx = this.props.main.purpose == "A" ? 0 : 1;                                   // if new streng, use idx 0, otherwise error idx 1
        if (streng.errors) {                                                                // the streng object has been pre-processed and already knows the list of errors, use this.
            for (const err of streng.errors) {
                if (err.obs.values.length == 0) {                                           // found this in live data, possibly bad entry indata
                    continue;
                }
                const item = this.getObsDetails(err);
                errResults.push(item);
            }
            for (const inv of streng.inventory) {
                if (inv.obs.values.length == 0) {                                           // found this in live data, possibly bad entry indata
                    continue;
                }
                const item = this.getObsDetails(inv);
                otherObs.push(item);
            }
        }
        else {
            for (const obs of streng.observations) {
                if (obs.values.length > 0 && obs.values[obs.values.length - 1] !== "1") {                              // if the last item in the key is 1, it's a terminator for continuous-observations, so skip that
                    let key = obs.values.join("-");
                    let found = ERROR_KEYS.find((value) => key.startsWith(value));                // find the first item in the list that's the same as the start of the key
                    const def = codeSelectorService.getLeaf(obs.values, streng.type);
                    const {tooltip, label} = this.getObsTooltipAndLabel(obs, key);
                    let imgIdx = -1;
                    if (obs.includePicture) {
                        imgIdx = idx;
                    }
                    if (found && def && def.errRating && def.errRating[errorCodeIdx]) {     // found a problem observation -> there is an error rating for it.
                        errResults.push({ key: key, tooltip: tooltip, image: imgIdx });
                    }
                    else {
                        otherObs.push({ key: key, tooltip: tooltip, image: imgIdx, label: label });
                    }
                }
                idx++;
            }
        }
        return {errors: errResults, other: otherObs};
    }

    getObsDetails(item) {
        let key = item.obs.values.join("-");
        const {tooltip, label} = this.getObsTooltipAndLabel(item.obs, key);
        let imgIdx = -1;
        if (item.obs.includePicture) {
            imgIdx = { obsIdx: item.obsIdx, strengIdx: item.strengIdx };
        }
        return { key: key, tooltip: tooltip, image: imgIdx , label: label};
    }

    getObsTooltipAndLabel(obs, key) {
        const { t } = this.props;
        let tooltip = codeSelectorService.translatePath(obs.values);
        const tipParts = codeSelectorService.getLabelsFromDictPath(tooltip).map((el) => t(el));
        let remarkAtEnd = false;
        if (obs.values[obs.values.length-1] === 'Z' || (obs.values.length === 5 && obs.values[3] === 'Z') ) {                                      // it's an observation that needs a remark section. this is also included in the tooltip, remove this.
            let popped = tipParts.pop();                                                // if we need to remove 2, need to add the last one again for the tooltip, after extracting the label
            if (obs.values.length === 5 && obs.values[3] === 'Z') {                     // the 'z' is not at the end, so need to pop 2 times
                tipParts.pop();
                tipParts.push(popped);                                                  // re-add the last one, it was not the 'z'
            }
        
            remarkAtEnd = true;
        }
        let label;
        if (obs.values.length === 5) {
            label = tipParts[tipParts.length - 2] + " - " + tipParts[tipParts.length - 1];
        }
        else {
            label = tipParts[tipParts.length - 1];
        }
        tooltip = tipParts.join(" - ");
        if ((key == 'B-D-B' || remarkAtEnd) && obs.remark) {                                // it's a comment, add the comment text
            const toAdd = `: ${obs.remark}`;
            tooltip += toAdd;
            label += toAdd;
        }
        return {tooltip, label};
    }

    render() {
        const { t, classes } = this.props;
        let isReallySmall = this.state.innerWidth <= (this.props.theme.breakpoints.values.sm * 2 / 3);      // if smaller than 2/3 of sm -> mobile that can't show title properly on 1 line
        let titleStyle = {flex:1, paddingLeft: "96px"};
        let dialogContentStyle;
        if (isReallySmall) {
            titleStyle['marginTop'] = '8px';
            titleStyle.paddingLeft= '56px';
            dialogContentStyle = {paddingLeft: '0px', paddingRight: '0px'};
        }
        let detailsBtn, detailsBtnTip;
        if (this.state.showObsDetails) {
            detailsBtn = <FormatAlignTop />;
            detailsBtnTip = t("Hide the descriptions of the observations");
        }
        else {
            detailsBtn = <FormatAlignBottom />;
            detailsBtnTip = t("Show the descriptions of the observations");
        }

        return (
            <React.Fragment>
                <ReactResizeDetector handleHeight={false} handleWidth={true} onResize={this.handleResize} />
                <div className={classes.titleBar}>
                    <FormControlLabel
                        className={isReallySmall ? classes.titleToggleSmall : classes.titleToggle}
                        control={
                            <Switch
                                checked={this.state.showInventory}
                                onChange={this.handleToggleShowInventory}
                                name="inventory"
                                color="primary"
                            />
                        }
                        label={t("Inventory")}
                    />
                    <DialogTitle id="confirmation-dialog-title" style={titleStyle} >{t("Overview")}</DialogTitle>
                    <div className={classes.rightSideHeaderToolbox}>
                        {(configService.isSite) &&                  
                            // ar can only be done with mobile at the moment, so desktop definitely not        
                            <Tooltip title={t("show_in_ar")}>
                                <ToggleButton 
                                    aria-label="ar"
                                    value="check-ar"
                                    selected={this.state.showAR}
                                    onChange={this.handleShowAR}>
                                    <AugmentedReality />
                                </ToggleButton>
                            </Tooltip>
                        }
                        <Tooltip title={detailsBtnTip}>
                            <IconButton 
                                aria-label="show detail"
                                onClick={this.handleToggleObsDetails}
                                color="default">
                                {detailsBtn}
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                {this.renderProjectOverview(isReallySmall)}
                <DialogContent dividers
                    style={dialogContentStyle}
                    ref={this.scrollbox}>
                    <List className={this.props.classes.list}
                        dense={true}>
                        {this.state.data.map(this.renderStrengDetail)}
                    </List>
                </DialogContent>
            </React.Fragment>
        );
    }

    renderProjectOverview(isReallySmall) {
        const { t, classes } = this.props;
        const rootStyle = this.buildProjectOverviewStyle(isReallySmall);
        return (
            <Box style={rootStyle}>
                <div style={{ flexDirection: 'column', flex: 1, textAlign: 'left' }}>
                    <div className={classes.summaryTotalRow} >
                        <div className={classes.summaryTotalCell}>{t("Totale leidinglengte")}:</div>
                        <div className={classes.summaryTotalValueCell}>{this.state.totalExpectedLength} m</div>
                    </div>
                    <div className={classes.summaryTotalRow}>
                        <div className={classes.summaryTotalCell}>{t("Totale inspectielengte")}:</div>
                        <div className={classes.summaryTotalValueCell}>{this.state.totalActualLength} m</div>
                    </div>
                </div>

                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end', width: LOCATION_WIDTH }}>
                    <div>{t("Plaatsnaam")}:</div>
                    <div className={classes.summaryTotalValueCell}>{this.props.main?.location}</div>
                </div>
            </Box>
        );
    }

    buildProjectOverviewStyle(isReallySmall) {
        const { theme } = this.props;
        const scrollbarWidth = this.state.scrollbarVisible ? 0 : this.getScrollbarWidth();
        const summaryTable = {
            marginLeft: theme.spacing(12),
            marginRight: theme.spacing(7) - scrollbarWidth,
            display: 'flex',
            flexDirection: 'row'
        };
        const summaryTableSmall = {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(7) - scrollbarWidth,
            display: 'flex',
            flexDirection: 'row'
        };

        if (!isReallySmall) {
            const isLargeScreen = this.state.innerWidth > theme.breakpoints.values.sm;
            return isLargeScreen ? summaryTable : summaryTableSmall;
        }
        else {
            const summaryTableReallySmall = {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(3) - scrollbarWidth,
                display: 'flex',
                flexDirection: 'row'
            };
            return summaryTableReallySmall;
        }
    }

    getScrollbarWidth() {
        if (!this.scrollbarWidth) {
            let div = document.createElement('div');
            div.style.overflowY = 'scroll';
            div.style.width = '50px';
            div.style.height = '50px';
            document.body.append(div);
            let scrollWidth = div.offsetWidth - div.clientWidth;
            div.remove();
            this.scrollbarWidth = scrollWidth;
        }
        return this.scrollbarWidth;
    }

    renderStrengDetail = (el, idx) => {
        //const { classes } = this.props;
        let isLargeScreen = this.state.innerWidth > this.props.theme.breakpoints.values.sm;
        const icon = this.buildIcon(el, isLargeScreen);
        let secondary = this.buildSecondary(el, idx);
        let primary = this.buildPrimary(el, icon, isLargeScreen);
        return (
            <React.Fragment key={idx}>
                <ListItem alignItems="flex-start" button dense
                    onClick={this.handleGoto(idx)}>
                    {(isLargeScreen) && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText
                        primary={primary}
                        primaryTypographyProps={{ component: "div" }}
                        secondary={secondary}
                        secondaryTypographyProps={{ component: "div" }}
                    />
                </ListItem>
                <Divider variant="fullWidth" component="li" />
            </React.Fragment>
        );
    }

    buildIcon(el, isLargeScreen) {
        //const { classes } = this.props;
        let icon = null;
        let iconStyle = el.color ? { color: el.color } : null;
        let dropletStyle = { left: '4px', top: '4px', height: '16px', position: 'absolute' }
        if (!isLargeScreen) {
            dropletStyle.height = '8px';
            if (iconStyle) {
                iconStyle['height'] = '16px';
                iconStyle['marginLeft'] = '-4px';
            }
            else {
                iconStyle = { 'height': '16px', marginLeft: '-4px' };
            }
        }
        icon = (PIPE_INSPECTIONS.includes(el.streng.type)) ? <PanoramaHorizontal style={iconStyle} /> : <PanoramaVertical style={iconStyle} />
        if (el.streng.sewerUsage === 'B') {
            icon = <div><Droplet style={dropletStyle} />{icon}</div>
        }
        if (el.streng.type === 's') {
            let vLine = { top: '4px', height: (isLargeScreen) ? '10px' : '8px', position: 'absolute', borderRight: '2px solid gray',
                         width: (isLargeScreen) ? '22px' : '14px' };
            icon = <div> {icon} <div style={vLine}/> </div>
        }
        return icon;
    }

    buildPrimary(el, icon, isLargeScreen) {
        let result = el.streng.ref;
        if (!isLargeScreen) {
            result = <div style={{ display: 'flex', flexDirection: "row" }}>{icon}{el.streng.ref}</div>
        }
        return result;
    }

    buildSecondary(el, idx) {
        const { classes } = this.props;
        let details = this.state.showInventory ? el.details.other : el.details.errors;
        let secondary;
        if (el.streng.pointRef3) {
            secondary = `${el.streng.pointRef1} - ${el.streng.pointRef2} - ${el.streng.pointRef3}`;
        }
        else {
            secondary = `${el.streng.pointRef1} - ${el.streng.pointRef2}`;
        }
        secondary = <Box display="flex" flexDirection="column">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={classes.detailTitle}>{secondary}</div>
                <div className={classes.detailLength}>
                    <div>{el.streng.expectedInspectionLength} m</div>
                    <div>{el.streng.actualInspectionLength} m</div>
                </div>
                <div style={{ width: LOCATION_WIDTH, display: 'flex', justifyContent: 'flex-end' }}>
                    {el.streng.location}
                </div>
            </div>
            {(details && details.length > 0) &&
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                    {details.map((detail, idx2) => {
                        const icon = detail.image !== -1 ? <Camera /> : null;
                        const label = this.state.showObsDetails ? detail.label : detail.key;
                        return (
                            <Tooltip title={detail.tooltip} key={idx2}>
                                <Chip style={{maxWidth: '100%'}} label={label} icon={icon} onClick={this.handleClickChip(idx, detail)} />
                            </Tooltip>
                        )
                    })}
                </Box>
            }
        </Box>
        return secondary;
    }

    handleClickChip = (strengIdx, detail) => (ev) => {
        if (this.props.onClickObs) {
            const realIdx = this.state.data[strengIdx].idx;                     // we do a sort on the data, so need to map to the original index
            this.props.onClickObs(realIdx, detail);
            //e.preventDefault();
            ev.stopPropagation();
        }
    }

    handleGoto = (idx) => () => {
        if (cameraService.isRecording) {
            const { t } = this.props;
            dialogService.error(t("video"), t("Kan niet van streng veranderen tijdens opname. Stop eerst de opname."));
        }
        else if (this.state.showAR && this.props.onShowAR) {
            this.props.onShowAR(this.state.data[idx], this.state.showInventory);
        } else if (this.props.onClickStreng) {
            const realIdx = this.state.data[idx].idx;                     // we do a sort on the data, so need to map to the original index
            this.props.onClickStreng(realIdx);
        }
    }

    handleToggleShowInventory = () => {
        const newValue = !this.state.showInventory;
        this.setState({showInventory: newValue});
        configService.set("general.showInventory", newValue);
    }

    handleToggleObsDetails = (ev) => {
        const newValue = !this.state.showObsDetails;
        this.setState({showObsDetails: newValue});
        configService.set("observations.showObsDetails", newValue);
        ev.stopPropagation();
    }

    handleShowAR = () => {
        const newValue = !this.state.showAR;
        this.setState({showAR: newValue});
        configService.set("general.showAR", newValue);
    }
}

StrengsSummary.propTypes = {
    strengs: PropTypes.array.isRequired,                    // streng data to show
    main: PropTypes.object.isRequired,                      // main data of the project
    onClickObs: PropTypes.func.isRequired,
    onClickStreng: PropTypes.func,
    onShowAR: PropTypes.func,
};

export default withTheme(withTranslation()(withStyles(styles)(StrengsSummary)));