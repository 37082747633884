/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import SignalDistanceVariant from 'mdi-material-ui/SignalDistanceVariant';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import i18n from "i18next";
import { MeasureArrow } from '../../../icons';
import { ValueDirection } from '../../../controls/measured_icon_component';
import MeasuredIcon from '../../../controls/measured_icon_component';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { remoteControlService, REMOTE_ACTIONS } from '../../../services/remote_control_service';
import { engineService } from '../../../../components/video_engine/engine_service';


const styles = (theme) => ({
    value: {
        color: 'rgb(150, 150, 150)',
        textAlign: 'center',
        wordBreak: "break-word",
        overflowWrap: "break-word",
        flex: 1,
        width: 'min-content'
    }
});

class DistanceGauge extends React.PureComponent {
    constructor(props) {
        super(props);

        this.unit = "mm";
        const val = props.value ?? 0;
        this.state = {
            valueTxt: isNaN(val) ? val : Math.trunc(val).toLocaleString() + " " + this.unit,              // can be a string with error text (no laser dots)
            laserOn: remoteControlService.laserOn ?? false,
            popupPos: null,                                                                               // for the context menu
        }

        this.span = React.createRef();
        this.backValue = React.createRef();
        this.lastVal = val;                 // cause we can set the value outside of the setState mechanisme for faster rendering
        this.laserOnTxt = i18n.t("Laser on");
        this.laserOffTxt = i18n.t("Laser off");
        this.calibrateTxt = i18n.t("Calibreer");
        const robot = engineService.tractor?._recorder;
        this.laserFromVideo = robot && robot.config.hasLaserMeasure === 'fromVideo';         // when laser is from video, we allow calibrate                     
    }

    componentDidMount() {
        remoteControlService.events.on('onData', this.handleRemoteUpdate);
        if (this.props.handle) {
            this.props.handle(this);
        }
        if (this.backValue.current) {
            this.backValue.current.setValue(this.lastVal / this.props.max);
        }
    }

    componentWillUnmount() {
        remoteControlService.events.removeListener('onData', this.handleRemoteUpdate);
        if (this.props.handle) {
            this.props.handle(null);
        }
    }

    render() {
        const { classes, radius } = this.props;

        let imgSize;
        if (radius > 36) {
            imgSize = `${(radius - 2) * 2}px`;
        }
        else if (radius > 18) {
            imgSize = `${radius * 2}px`;
        }
        else {
            imgSize = `${(radius + 2) * 2}px`;
        }
        let fontSize = (this.props.radius / 2) - 1;
        if (fontSize < 7) {                                         // keep it readable
            fontSize = 7;
        }
        fontSize = `${fontSize}px`

        let marginBottom = `-${((radius * 2) * 2) / 3}px`;
        let textStyle = { fontSize: fontSize, marginBottom: marginBottom, display: 'flex' };

        const menuOpen = this.state.popupPos !== null;
        let actionTxt = this.state.laserOn ? this.laserOffTxt : this.laserOnTxt;
        return (
            <Tooltip title={this.props.tooltip}>
                {/* div is needed to show the tooltip */}
                <div style={{ flex: '50%', position: 'relative', display: 'flex', justifyContent: 'center', flexDirection: 'column', color: 'rgb(150, 150, 150)' }}
                    onContextMenu={this.showMenu}>
                    <div style={textStyle}>
                        <div className={classes.value}>
                                <span ref={this.span}>{this.state.valueTxt} </span>
                            </div>
                    </div>
                    <MeasuredIcon icon={MeasureArrow} showValue={true} direction={ValueDirection.right} ref={this.backValue} color="#5d9cec" size={imgSize} />
                    <Menu open={menuOpen}
                        onClose={this.handleCloseMenu}
                        anchorReference="anchorPosition"
                        anchorPosition={this.state.popupPos}>
                        <MenuItem onClick={this.handleToggleLaser} disabled={this.props.readOnly}>{actionTxt}</MenuItem>
                        <MenuItem onClick={this.handleCalibrate} disabled={this.props.readOnly || !this.laserFromVideo}>{this.calibrateTxt}</MenuItem>
                    </Menu>
                </div>
            </Tooltip>

        );
    }
    setValue = (value) => {
        value = value ?? 0;
        if (value < 0) {
            value = 0;
        }
        if (value !== this.lastVal) {
            const valueTxt = isNaN(value) ? value : Math.trunc(value).toLocaleString() + " " + this.unit;     // can be text, indicating an error
            if (this.span.current) {
                this.span.current.innerHTML = valueTxt;                    // fast update
            }
            else {
                this.setState({ valueTxt: valueTxt });
            }
            if (!isNaN(value) && this.props.max && this.backValue.current) {
                this.backValue.current.setValue(value / this.props.max);        // need a percentage
            }
            this.lastVal = value;
        }
    }

    handleToggleLaser = () => {
        if (this.state.laserOn) {
            engineService.stopLaser();
        }
        else {
            engineService.startLaser();
        }
        this.setState({laserOn: !this.state.laserOn, popupPos: null});
    }

    handleRemoteUpdate = (action, value) => {
        switch (action) {
            case REMOTE_ACTIONS.LASER_OFF:
                this.setState({laserOn: false});
                break;
            case REMOTE_ACTIONS.LASER_ON:
                this.setState({laserOn: true});
                break;
            default:
                break;
        }
    }

    handleCloseMenu = () => {
        this.setState({popupPos: null});
    }

    showMenu = (event) => {
        event.preventDefault();
        this.setState({popupPos: { top: event.clientY - 4, left: event.clientX - 2 }});
    }

    handleCalibrate = async () => {
        const service = engineService.tractor?._recorder?.laserPosService;
        if (service) {
            await service.calibrate();
        }
        this.handleCloseMenu();
    }

}

DistanceGauge.propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.number,
    tooltip: PropTypes.string,
    radius: PropTypes.number,
    handle: PropTypes.func,                             // used to let the parent get a ref to this object
    max: PropTypes.number,                              // max distance that can be measured
};

export default withStyles(styles)(DistanceGauge);