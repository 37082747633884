import { assetsService } from "../../services/assets_service";
import { dialogService } from "../../services/dialog_service";

/**
 * sync-version of loadActiveEngienConfig, for when it doesn't ahve to be loaded, but simply return what is already known
 */
export function getActiveEngineConfig() {
    return null;
}

export async function loadActiveEngineConfig() {
}

export function getAllVideoChannelCamTypes(configs) {
   return null;
}

export function getPrimaryChannel(configs) {
    return null;
}

export function getActiveRobotCalibration() {
    return null;
}

export function setActiveRobotCalibration() {
    return null;
}

export function saveActiveProfile() {
    /*
    not yet supported in the cloud to save
    try {
        assetsService.saveAsset('engine.json', JSON.stringify(engineConfigData));
    }
    catch (error) {
        dialogService.error(i18n.t("Hardware"), errExtractor.get(error));
    }
    */
}

class EngineService {

    constructor() {
    }

    /**
     * builds an object that can be used to pass to the engine process and which describes
     * the current project and streng. This can be used by the engine process to build file names and such
     * @returns {object} that describes the current project & streng and which can savely be passed to another process
     */
    buildProjectDef() {
    }

    /**
     * sends an async message to the main process with the instruction to
     * start the engines as described in the definition that gets loaded in this call.
     * @param {object} deviceConfig the engine configuration to start. When null, the active config will be loaded.
     */
    async startInputs(deviceConfig = null) {   
    }

    /**
     * create a new browser window to show video on a different window (so it can be put on a different monitor)
     */
    openVideoScreen() {
    }

    async stopInputs() {
    }


    loadDefinitions(deviceConfig, resolve, reject) {
        
    }

    async setPosition(pos) {
    }

    /**
     * sends instruction to start recording
     * @param {object} definition contains project & streng info
     */
    async startRecord() {
    }

    stopRecord() {
    }    
}

export const engineService = new EngineService();