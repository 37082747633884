export const PI = 3.1415926535897932384626433832795028841971693993751058209749445;              // more accurate value


export function toRadian(degrees) {
    return degrees * (PI / 180);
}

/**
 * calculates pitch in % (height/delta-distance*100)
 * @param {number} pitch pitch in degrees
 */
export function pitchInDegrees(pitch) {
    return (Math.sin(toRadian(pitch)) / Math.sin((PI/2) - toRadian(pitch))) * 100;
}

export function toDegrees(radians) {
    return radians * 180 / PI;
}

export function replaceEmptyWith0(value) {
    if (!value) return 0;
    return value;
}

//see: https://stackoverflow.com/questions/28986872/finding-distance-between-two-points-on-image-even-when-image-is-scaled
export function distance(x1, y1, x2,y2){
    var dx = x2-x1;
    var dy = y2-y1;
    return Math.sqrt(dx*dx+dy*dy);
}

export function logWithBase(n, b) {
    return Math.log(n) / Math.log(b);
}


/**
 * calculates a lookup table that performs a mapping between a linear scale and a regressive decaying scale.
 * This is used to calculate the distance between laser and wall, using 2 laser dots. Since the distance between the laser dots
 * is different, depending on the distance to the wall, we can't use a simple linear mapping, but need to use a regressive scale
 * where the decay factor also changes using a linear scale.
 * Basically, a map is made like this:
 * 10cm -> 250 dots between 2 laser points, decay: 0.35
 * 20cm -> 160, decay: 0.35
 * 30cm -> 107, decay: 0.30
 * 40cm -> 77, decay: 0.25
 * ...
 * The input parameters (fields of the calibration) are the nr of dots at:
 * - 10cm (minimum), 
 * - 20cm (1 unit above min)
 * - 100cm (max)
 * - 90cm (1 unit below max)
 * 
 * also check out these sites:
 * - https://www.thoughtco.com/exponential-decay-definition-2312215
 * - https://stackoverflow.com/questions/19472747/convert-linear-scale-to-logarithmic
 * - https://stackoverflow.com/questions/54130877/map-a-linear-scale-to-a-logarithmic-one-with-both-scales-having-variable-min-max
 * @param {object} cal data collected during a calibration stage
 */
export function calculateExponentialDecay(cal) {
    const linearStepSize = 100;             //expressed in mm

    // calculate the % change from min to 1-above and from 1-below-max to max (decay at start and at end)
    let changeStart = +cal.distance[0] - +cal.distance[1];
    let changeEnd = +cal.distance[2] - +cal.distance[3];
    let decayStart = changeStart / cal.distance[0];
    let decayEnd = changeEnd / +cal.distance[2];
            ////calculate the different decay steps using a linear decay
            //let nrSteps = (+cal.radius2 - +cal.radius1) / linearStepSize;                       // the nr of steps in mapping
            //let nrDecaySteps = nrSteps - 1;                                     // nr of steps in the decay
            //let decayOfDecay = (decayEnd - decayStart) / nrDecaySteps;
    //build the result
    let result = [];
    let prevDistance = +cal.distance[0];
    result.push({radius: +cal.radius1, distance: prevDistance});
    let radius = +cal.radius1; 
    for(let i=1; i < cal.distance.length; i++) {
        let distance = +cal.distance[i];
        let change = prevDistance - distance;
        let decay = change / prevDistance;
        result.push({radius: radius + (i*linearStepSize), distance: distance, decay: decay});
        prevDistance = distance;
    } 
    return result;
}

/**
 * calculates the distance between the wall and the laserhead 
 * note: for laser with 2 dots on the wall where the distance between the dots increases as the distance to the wall increases ( they go like this: \/  )
 * @param {number} distance distance between 2 laser points
 * @param {list of objects} cal result of calculateExponentialDecay
 */
 export function calculateRadius(distance, cal) {
    const linearStepSize = 100;

    if (distance > cal[0].distance) {
        return `+${cal[0].distance}`;
    }
    else if (distance < cal[cal.length-1].distance) {
        return `-${cal[cal.length-1].distance}`;
    }   
    else {
        let idx = cal.length-1;
        while(idx > 0 && cal[idx-1].distance < distance) {
            idx--;
        }
        let percent = distance / cal[idx-1].distance;
        let result2 = cal[idx].radius * percent; 
        let pos = logWithBase(distance/cal[idx].distance, 1-cal[idx].decay);
        let result = cal[idx].radius + (pos * linearStepSize);
        console.log("logarithmic: ", result, ", linear: ", result2);
        return result;
    }
}

/**
 * calcualtes the circumference of the strand. Used to draw lidar-rollout
 * @param {object} streng the streng to get the circumeference of
 */
export function getCircumference(width, height, shape) {
    let result;                      // height: circumference of the strand

    let wid = +width;
    let len = +height;
    if (len > wid) {
        wid = +height;
        len = +width;
    }
    switch (shape) {
        case 'A':                           // round
        result = PI * len;
            break;
        case 'B':                           // square
        result = wid * len;
            break;
        case 'C':                           // egg shaped
        case 'F':                           // oval / eliptic
            // note: see source code of https://www.eclecticsite.be/calc/ellipse.htm for details to calculate 
            result = PI * (3*((wid/2) + (len/2)) - Math.sqrt((3*wid/2 + len/2) * ( wid/2 + 3*len/2)));
            break;
        case 'D':                           // U shaped
        case 'E':                           // arc-shaped
            // to calculate: 
            // - take square of full image,
            // - take half of cirle of short side
            // - take half of square of short side
            // - substract half-circle from half square, substract this result from full square
            let halfCircle = (PI * len) / 2;
            let halfSquare = (len * len) / 2;
            result = (wid * len) - (halfSquare - halfCircle);
            break;
        default:                            // too complicated, asume cirkle
            result = PI * height;
            break;
    }
    return result;
}


let scrollbarWidth = null;

export function measureScrollbarWidth() {
    if (scrollbarWidth === null) {
        let scrollbox = document.createElement('div');                  // Add temporary box to wrapper
        scrollbox.style.overflow = 'scroll';
        document.body.appendChild(scrollbox);               // Append box to document
        scrollbarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
        document.body.removeChild(scrollbox);
    }
    return scrollbarWidth;
}

export function stdDef(values) {
    const mean = values.reduce((s, n) => s + n) / values.length;
    const variance = values.reduce((s, n) => s + (n - mean) ** 2, 0) / (values.length - 1);
    return Math.sqrt(variance);
}

export function calculateAngle(coord1, coord2, coord3, coord4) {
    const dx0 = coord2.x - coord1.x;
    const dy0 = coord2.y - coord1.y;
    const dx1 = coord4.x - coord3.x;
    const dy1 = coord4.y - coord3.y;
    const angle = Math.atan2(dx0 * dy1 - dx1 * dy0, dx0 * dx1 + dy0 * dy1);
    let res = toDegrees(angle);
    res = Math.abs(res);
    if (res > 90) {
        res = 180 - res;
    }
    return res;
}