/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { hhmmss } from '../../../services/time';


const styles = (theme) => ({
    root: {
        display: 'flex', 
        flexDirection:'row', 
        justifyContent: 'space-between'
    },
    leftTimeInfoCol: {
        marginRight: theme.spacing(1)
    },

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class TimeInfo extends React.Component {
    constructor(props) {
        super(props);
        this.span = React.createRef();                                          // keep a ref to the span that contains the text. this allows us to update the ui very fast without having to re-render all the time

        this.state = {
            value: "00:00:00"
        }
    }

    componentDidMount() {
        if (this.props.onSetter) {
            this.props.onSetter.current = this.setValue;
        }
    }

    componentWillUnmount() {
        if (this.props.onSetter) {
            this.props.onSetter.current = null;
        }
    }


    render() {
        return (
            <div className={this.props.classes.root}>
                {(this.props.text) &&
                    <span className={this.props.classes.leftTimeInfoCol}>{this.props.text}: </span>
                }
                <span ref={this.span}>{this.state.value}</span>
            </div>
        );
    }

    setValue = (value) => {
        if (isFinite(value)) {
            value = hhmmss(value);
        }
        else {
            value = "00:00:00";
        }
        if (this.span.current) {
            this.span.current.innerHTML = value;                    // fast update
        }
        else {
            this.setState({value: value});
        }
    }
}

TimeInfo.propTypes = {
    text: PropTypes.string,                                     // the label for the time info                        
    onSetter: PropTypes.object,                                 // object of type {current: null} -> to set the callback for storing a new value fast without rerender
};


export default withStyles(styles)(TimeInfo);