/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import CheckIcon from '@material-ui/icons/Check';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import VideoPlayer from './video_player_component';
import RobotData from './robot_overlay_component';
//import Paper from '@material-ui/core/Paper';
import VideoSourceToolbar from './video_source_toolbar_component';
import { withTranslation } from 'react-i18next';
import { configService } from '../../../services/config_service';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Measure from './measure_component';
import MeasureAngle from './measure_angle_component';
import WaterHeightMeasure from './water_height_measure_component';
import FreeformSurfaceMeasure from './freeform_surface_measure_component';
import DeformationMeasure from './deformation_measure_component';
import VideoSectionBase from '../../../components/document/video/video_section_base_component';
import { dialogService } from '../../../services/dialog_service';
import VideoMetaLoader from './video_meta_loader_component';
import { activeProjectService } from '../../../services/active_project_service';
import { getActiveEngineConfig, getAllVideoChannelCamTypes, getPrimaryChannel } from '../../../components/video_engine/engine_service';
import { errExtractor } from '../../services/error_extractor';
import { projectService } from '../../services/project_service';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import NestedMenuItem from "material-ui-nested-menu-item";


const styles = (theme) => ({
    root: {
        gridArea: 'video',                            // important: needed for the layout in tablet mode
    },
    recorder: {
        margin: 'auto',
        height: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});


/* speed optimization. By putting the buttons in a sperate renderer, they don't need to be rendered each time the timer is updated. */
class VideoSection extends VideoSectionBase {
    constructor(props) {
        super(props);

        this.state = {
            videoWidth: 600,
            videoHeight: 500,
            showTractor: this.getShowTractor('cam1'),
            popupPos: null,                                                              // when set, popup is open. specifies x, y pos
            loadedVideo: null,                                                          // when set, video that user selected to load but still need to extract meta data such as length and size
            channel: 'cam1',                                                            // the currently selected channel
            primaryChannel: 'cam1',                                                     // the channel that is primary in the engine (provides the time)
            inView: false,                                                              // when true, a second video is shown with other camera
            inViewChannel: null,                                                        // current view to use for inview
            channelsSwitched: false,                                                    // to switch between small and large view quickly without reloading the video
            channels: null,                                                             // list of all available channels. Depends on liveFeed
            isImportingVideo: false,                                                    // show backdrop when importing and converting video
        }

        this.stateBeforeMeasure = null;                                                 // stores state values as they were before the measure started so that they can be restored, in case inview was turned on.
    }

    componentDidMount() {
        if (this.props.initLoaded) {                                                    // if already loaded when component created, make certain that the channel info is loaded
            this.setSetlectedChannel();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.liveFeed !== this.props.liveFeed || prevProps.activeStreng !== this.props.activeStreng || prevProps.initLoaded !== this.props.initLoaded) {        
            if (this.props.initLoaded) { this.setSetlectedChannel();}                   // only try to load if the initial load has been done, if not done, we have no project or no config
        }
        if (prevProps.measureData !== this.props.measureData) {                         
            if (this.props.measureData) {                                               // when starting a measure, make certain that inview is turned off, and the channel thats was in the big video is made active.
                if (this.state.inView) {
                    this.stateBeforeMeasure = {channelsSwitched: this.state.channelsSwitched, channel: this.state.channel};
                    const channel = this.state.channelsSwitched ? this.state.inViewChannel : this.state.channel;
                    this.setState({channelsSwitched: false, inView: false, channel: channel});
                }
            }
            else if (this.stateBeforeMeasure) {                                         // when measuring stopped, make certain we go back to the previous state.
                this.setState({channelsSwitched: this.stateBeforeMeasure.channelsSwitched, inView: true, channel: this.stateBeforeMeasure.channel});
                this.stateBeforeMeasure = null;
            }
        }
    }

    setSetlectedChannel() {
        let channel = null;
        let allChannels = null;
        if (this.props.liveFeed) {                        // source is camera, so use device config to find the primary camera name
            const config = getActiveEngineConfig();
            channel = getPrimaryChannel(config);
            allChannels = getAllVideoChannelCamTypes(config);
        }
        else {
            channel = projectService.getPrimaryChannel(this.props.activeStreng);
            allChannels = projectService.getAllVideoChannelCamTypes(this.props.activeStreng);
        }
        this.setState({primaryChannel: channel, channel: channel, channels: allChannels, showTractor: this.getShowTractor(channel)})
        this.props.onSelectChannel(channel);
    }

    getShowTractor(channel) {
        if (this.props.activeStreng) {
            let showData = configService.get("robot.showTractorDataOverVideo");
            const def = this.props.activeStreng[channel];
            return showData && (this.props.liveFeed  || !def || def.tOnV === false || def.tOnV === "");
        }
        return false;
    }

    render() {
        const { t, classes } = this.props;
        
        let style;
        let videoWidth = this.props.videoWidth;
        let videoHeight = this.props.videoHeight;
        if (this.props.fullscreen) {
            const screensize = this.getScreenSize();
            style = { width: '100%', position: "fixed", overflow: 'hidden', height: "100%", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1200, boxShadow: "inherit", background: 'black' }
            videoWidth = screensize.width;                                      // we need exact numbes for this, 100% don't work
            videoHeight = screensize.height;
        }
        else {
            style = { width: '100%', boxShadow: "inherit", backgroundColor: "transparent"};
        }
        const def = this.props.activeStreng ? this.props.activeStreng[this.state.channel] : null;
        const tonv = def && def.tOnV === true;
        const channelNames = this.state.channels ? this.state.channels.map((el) => el.channel) : [];

        let disableAddI = false;
        let disableAddT = false;
        let addI = false;
        let addT = false;
        if (this.props.activeStreng && this.props.activeStreng.cams && this.state.channel && this.state.channel in this.props.activeStreng.cams) {
            disableAddI = this.props.activeStreng.cams[this.state.channel].iOnV;
            disableAddT = this.props.activeStreng.cams[this.state.channel].tOnV;
            addI = this.props.activeStreng.cams[this.state.channel].addI;
            addT = this.props.activeStreng.cams[this.state.channel].addT;
        }
        return (
            <div style={style}  className={classes.root}>
                <div className={this.props.classes.recorder}
                    onContextMenu={this.showPopup}>
                    {(!this.state.loadedVideo)  &&
                        <VideoPlayer
                            width={videoWidth}
                            height={videoHeight}
                            onSizeSet={this.storeVideoSize}
                            liveFeed={this.props.liveFeed}
                            controlState={this.props.videoControlState}
                            currentTime={this.props.jumpToTime}
                            onCurrentTimeChanged={this.props.onCurrentTimeChanged}
                            onDurationChanged={this.props.onDurationChanged}
                            onEnded={this.props.onEnded}
                            activeStreng={this.props.activeStreng}
                            channel={this.state.channel}
                            channel2={this.state.inViewChannel}
                            channels={channelNames}
                            primaryChannel={this.state.primaryChannel}
                            inView={this.state.inView}
                            onToggleChannels={this.handleSwitchChannels}
                            channelsSwitched={this.state.channelsSwitched}
                            isFullScreen={this.props.fullscreen}
                            visible={this.props.visible}
                        />
                    }
                    {(this.state.showTractor && this.props.visible) &&
                        <RobotData
                            width={this.state.videoWidth}
                            height={this.state.videoHeight}
                            inView={this.state.inView}
                        />
                    }
                    {this.buildMeasurOverlay()}
                    {(this.props.visible) &&
                        <VideoSourceToolbar forceLiveFeed={this.props.forceLiveFeed}
                            onToggleForceLiveFeed={this.props.onToggleForceLiveFeed}
                            isLiveFeed={this.props.liveFeed}
                            onToggleFullScreen={this.props.onToggleFullScreen}
                            isFullScreen={this.props.fullscreen}
                            readOnly={this.props.readOnly}
                            showTractorData={this.state.showTractor}
                            onToggleShowTractorData={this.handleToggleShowTractorData}
                            disableToggleTractorData={!this.props.liveFeed && tonv}
                            onOpenVideoScreen={this.props.onOpenVideoScreen}
                            selectedChannel={this.state.inView ? "_inview_" : this.state.channel}
                            onSetChannel={this.handleSetChannel}
                            channels={this.state.channels}
                            minimalControls={this.props.minimalControls}
                            tabletMode={this.props.tabletMode}
                        />
                    }
                    {(this.props.readOnly === false && this.props.visible) && 
                        <Menu open={this.state.popupPos !== null}
                            onClose={this.hanldeClose}
                            anchorReference="anchorPosition"
                            anchorPosition={ this.state.popupPos}>
                            <MenuItem onClick={this.handleLoadVideo(false)} disabled={this.props.videoControlState === "record"}>{t("Load video")}</MenuItem>

                            <NestedMenuItem
                                label={t("Load video with conversion")}
                                parentMenuOpen={this.state.popupPos}
                            >
                                <MenuItem onClick={this.handleLoadVideo("ultrafast")}>{t("ultra fast")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('superfast')}>{t("super fast")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('veryfast')}>{t("very fast")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('faster')}>{t("faster")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('fast')}>{t("fast")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('medium')}>{t("medium")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('slow')}>{t("slow")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('slower')}>{t("slower")}</MenuItem>
                                <MenuItem onClick={this.handleLoadVideo('veryslow')}>{t("very slow")}</MenuItem>
                            </NestedMenuItem>

                            <Divider />
                            <MenuItem onClick={this.handleRemoveVideo} disabled={this.props.videoControlState === "record" && this.props.liveFeed === false}>{t("Remove video")}</MenuItem>
                            <Divider />
                            <MenuItem onClick={this.handleToggleAddI} style={{justifyContent: 'space-between'}} disabled={disableAddI} >
                                <Typography variant="inherit">{t("Add intro to report video")}</Typography>
                                {(addI) &&
                                    <CheckIcon fontSize="small"/>
                                }
                            </MenuItem>
                            <MenuItem onClick={this.handleToggleAddT} style={{justifyContent: 'space-between'}} disabled={disableAddT}>
                                <Typography variant="inherit">{t("Add tractor data to report video")}</Typography>
                                {(addT) &&
                                    <CheckIcon fontSize="small"/>
                                }
                            </MenuItem>
                        </Menu>
                    }
                    {(this.state.loadedVideo) &&
                        <VideoMetaLoader filename={this.state.loadedVideo} onMeta={this.hanldeMetaAvailable}/>
                    }
                </div>
                <Backdrop className={this.props.classes.backdrop} open={this.state.isImportingVideo}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }

    handleToggleAddT = () => {
        this.setState({popupPos: null});                            // make certain that popup is closed
        this.props.activeStreng.cams[this.state.channel].addT =  !this.props.activeStreng.cams[this.state.channel].addT;
        activeProjectService.markDirty();
    }

    handleToggleAddI = () => {
        this.setState({popupPos: null});                            // make certain that popup is closed
        this.props.activeStreng.cams[this.state.channel].addI =  !this.props.activeStreng.cams[this.state.channel].addI;
        activeProjectService.markDirty();
    }

    showPopup = (event) => {
        event.preventDefault();
        this.setState({popupPos: { top: event.clientY - 4, left: event.clientX - 2 }});
    }

    hanldeClose = () => {
        if (this.state.popupPos != null) {
            this.setState({popupPos: null});
        }
    }

    handleRemoveVideo = () => {
        this.setState({popupPos: null});                            // make certain that popup is closed
        try {
            this.removeVideoFile(this.state.channel);
            if (this.props.onVideoLoaded) {                             // document needs to recalcualte if recording can happen (video now available)
                this.props.onVideoLoaded();
            }
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("video"), error);
        }
    }

    /**
     * called when user select menu item. Will open popup and copy the file.
     * afterwards, the ui will load the video so meta can be extracted
     * When a file is loaded as video source for a streng, any previous channels will
     * be removed and replaced with a single new channel for the video only 
     */
    handleLoadVideo = (withConversion) => async () => {
        this.setState({popupPos: null});                            // make certain that popup is closed
        const files = dialogService.openVideo();
        if (files && files.length === 1) {
            try {
                this.setState({isImportingVideo: true});
                const newChannel = 'cam1';                          // imported video files use a fixed camera name for now
                let outFile = await this.importVideoFile(files[0], newChannel, withConversion);
                this.setState({loadedVideo: outFile});
            }
            catch(error) {
                const { t } = this.props;
                dialogService.error(t("video"), errExtractor.get(error));
            }
            this.setState({isImportingVideo: false});
        }
    }

    /**
     * called when video is loaded that user wants to use as current streng video (not recorded but copeid from file)
     * and meta data is available
     */
    hanldeMetaAvailable = (video) => {
        if (video) {                                                    // when there is no video, an error occured.
            let streng = activeProjectService.activeStreng;
            activeProjectService.storeVideoSize(streng, video, 'cam1'); // for imported files, we use a hardcoded channel name
            if (this.props.onVideoLoaded) {                             // document needs to recalcualte if recording can happen (video now available)
                this.props.onVideoLoaded();
            }
        }
        this.setState({loadedVideo: null});                         // important: do last, it will re-render the video player. it should only now try to load the new file, if doneearlier, the component is possibly in wrong state (live) and things go wron
    }

    handleToggleShowTractorData = () => {
        const newValue = !this.state.showTractor;
        configService.set("robot.showTractorDataOverVideo", newValue);
        this.setState({showTractor: newValue});
    }

    /**
     * called when the video recorder/player has calculated the actual size of the video screen.
     * We use this to set the size of the overlay.
     */
    storeVideoSize = (width, height) => {
        if (this.state.videoWidth !== width || this.state.videoHeight !== height) {
            this.setState({ videoWidth: width, videoHeight: height });
        }
    }

    buildMeasurOverlay() {
        const measureData = this.props.measureData;
        if (measureData && this.props.visible) {
            const def = measureData;
            const type = def.type;
            if (type === "measure") {
                return (
                    <Measure
                        width={this.state.videoWidth}
                        height={this.state.videoHeight}
                        channel={this.state.channel}
                        onMeasured={this.props.onMeasured}
                        allowLaserControl={this.props.readOnly}                    // turning on/off the laser is only allowed during live recording, cause otherwise we get false results from the device.
                    />
                );
            }
            else if (type === "angle") {
                return (
                    <MeasureAngle
                        width={this.state.videoWidth}
                        height={this.state.videoHeight}
                        channel={this.state.channel}
                        onMeasured={this.props.onMeasured}
                    />
                );
            }
            else if (type === "depth") {
                return (
                    <WaterHeightMeasure
                        width={this.state.videoWidth}
                        height={this.state.videoHeight}
                        channel={this.state.channel}
                        overlaySize={def.size}
                        overlayX={def.x}
                        overlayY={def.y}
                        onMeasured={this.props.onMeasured}  
                    />
                );
            }
            else if (type === "deformation") {
                return (
                    <DeformationMeasure
                        width={this.state.videoWidth}
                        height={this.state.videoHeight}
                        channel={this.state.channel}
                        overlaySize={def.size}
                        overlayX={def.x}
                        overlayY={def.y}
                        onMeasured={this.props.onMeasured}
                    />
                );
            }
            else if (type === "freeform") {
                return (
                    <FreeformSurfaceMeasure
                        width={this.state.videoWidth}
                        height={this.state.videoHeight}
                        channel={this.state.channel}
                        overlaySize={def.size}
                        overlayX={def.x}
                        overlayY={def.y}
                        onMeasured={this.props.onMeasured} />
                );
            }
        }
        return null;
    }

    handleSetChannel = (event, value) => {
        if (value === null) return;                                                             // happens when on the currently selected button is clicked
        if (value === "_inview_") {
            let list = null;
            if (this.props.liveFeed) {
                let config = getActiveEngineConfig();
                if (config) {
                    list = config.filter(item => item.video).map(el => el.video.channel);         // extrat all the video channel names so that we can get the first that is not the channel for the main video
                }
            }
            if (!list) {
                const streng = this.props.activeStreng;
                if (streng && streng.cams) {
                    list = Object.keys(streng.cams);
                }
            }
            if (list) {
                this.setInview(list);
            }
            else {
                dialogService.error("internal error", "cant load engine config");
            }
        }
        else {
            this.setState({channel: value, inView: false, inViewChannel: null, channelsSwitched: false});
            if (this.props.onSelectChannel) {
                this.props.onSelectChannel(value);
            }
        }
    }

    setInview(list) {
        const inViewChannel = list.find(el => el !== this.state.channel);
        if (inViewChannel) {
            this.setState({inViewChannel: inViewChannel, inView: true});
        }
        else {
            dialogService.error("internal error", "multiple channels expected");
        }
    }

    handleSwitchChannels = () => {
        const switched = !this.state.channelsSwitched;
        this.setState({channelsSwitched: switched});
        if (this.props.onSelectChannel) {
            const newChannel = switched ? this.state.inViewChannel : this.state.channel;
            this.props.onSelectChannel(newChannel);
        }
    }

}

VideoSection.propTypes = {
    fullscreen: PropTypes.bool,
    readOnly: PropTypes.bool,
    liveFeed: PropTypes.bool,                                       // combo of readOnly, canRecord and forceLiveFeed
    videoWidth: PropTypes.number,
    videoHeight: PropTypes.number,
    jumpToTime: PropTypes.number,
    videoControlState: PropTypes.any,
    onToggleFullScreen: PropTypes.func,
    onCurrentTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onEnded: PropTypes.func,
    activeStreng: PropTypes.any,
    forceLiveFeed: PropTypes.bool,                                  // true when user pressed the 'camera' button
    onToggleForceLiveFeed: PropTypes.func,
    onMeasured: PropTypes.func,
    measureData: PropTypes.any,
    onVideoLoaded: PropTypes.func,                                  // called when a new video has been assigned to the streng and the UI should reload the streng cause of new conditions (video available)
    onSelectChannel: PropTypes.func,                                // called when the selected channel is changed (the camera/video feed currently displaying in this video section)
    initLoaded: PropTypes.bool,                                     // allows us to reload the channels after initial load of project and engine
    //isMinimal: PropTypes.bool,                                      // when true, nothing is visible, only the video elements are used to receive video from the internal camera if needed
    visible: PropTypes.bool,                                        // when not visible, still need to render some video elements so we have a stream to record (internal cameras)
    onOpenVideoScreen: PropTypes.func,                              // callback for the button to open new screen for video
    tabletMode: PropTypes.bool,                                     // for the toolbar, when true, displayed differently
    minimalControls: PropTypes.bool,                                // for the toolbar, when false, show sidebars, otherwise hide as much as possible
};

export default withTranslation()(withStyles(styles)(VideoSection));