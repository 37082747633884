class ErrorExtractorService {

    get(err) {
        if (err === undefined || err === null) {                    // just to be save, otherwise it causes an error on the in operator
            return "";
        }
        else if (typeof err === 'string'){
            return err;
        }
        else if (Array.isArray(err)) {
            return err.map((e) => this.get(e)).join(', ');
        }
        else if ('message' in err) {                            // must be before error, cause server returns objects with both, but valid is in message
            return this.get(err.message);
        }
        else if ('error' in err) {
            return this.get(err.error);
        }
        else if ('msg' in err) {
            return this.get(err.msg);
        }
        else if ('sqlMessage' in err) {
            return this.get(err.sqlMessage);
        }
        else {
            return 'unknown error';
        }

    }
}

export const errExtractor = new ErrorExtractorService();