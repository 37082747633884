/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { activeProjectService } from '../../../../services/active_project_service';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmpty } from '../../../services/project_service';
import { withTranslation } from 'react-i18next';
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from '../../../../services/dialog_service';


const styles = (theme) => ({
    textField: {
        width: '100%'
    }
});

// important: this class needs to be exported from here and also using 'default', but with all the adorners declared.
// this allows us to inherit from the class and use it as well.
export class StrengInfoAutoComplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.streng ? props.streng[props.field] : "",
            popupOpen: false,
            options: []
        }
    }

    componentDidMount() {
        this.setState({options: activeProjectService.getAllValuesForStrengField(this.props.field)});
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.field !== this.props.field || prevProps.streng !== this.props.streng) && this.props.streng) {
            let value = this.props.streng[this.props.field];
            if (!value) {
                value = "";
            }
            this.setState({value: value, options: activeProjectService.getAllValuesForStrengField(this.props.field)});
        }
    }

    render() {
        let error = this.props.required && this.props.showMissingErrors && isEmpty(this.state.value);
        return (
            <Autocomplete
                style={{flex: 1}}                               // need this for gpsAdorner, otherwise autocomplete don't fill space
                freeSolo
                disableClearable
                autoComplete
                clearOnEscape
                options={this.state.options}
                id={this.props.field}
                inputValue={this.state.value}
                onInputChange={this.handleTextChanged}
                onFocus={this.openPopup}
                onBlur={()=> {this.setState({popupOpen: false})}}
                open={this.state.popupOpen}
                onKeyDown={this.keyPressed}
                renderInput={params => (
                    <TextField
                        {...params}
                        required={this.props.required}
                        InputProps={{...params.InputProps, readOnly:this.props.readOnly}}
                        error={error}
                        label={this.props.label}
                        InputLabelProps={{...params.InputLabelProps,  shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                        className={this.props.classes.textField}
                        margin="dense"
                    />
                )}
            />
        );
    }

    openPopup = (ev) => {
        if (this.props.readOnly) {                                                              // not supported when readonly to edit
            return;
        }
        this.setState({popupOpen: true});
    }

    closePopup = (ev) => {
        this.setState({popupOpen: false});
    }

   
    handleTextChanged = (ev, newValue) => {
        if (this.state.value !== newValue && ev !== null) {                                                     // need to do this cause the event appears to fire at load time.
            this.setValue(newValue);
            if (ev.nativeEvent instanceof MouseEvent) {
                this.closePopup();
            }
            else if (this.state.popupOpen === false) {
                this.openPopup();
            }
        }
    }

    keyPressed = (ev) => {
        if (ev.keyCode === 9 || ev.keyCode === 13 || ev.keyCode === 27) {             // tab or enter
            this.closePopup();
        }
        else if (this.state.popupOpen === false && (ev.keyCode === 40 || ev.keyCode === 38)) {
            this.openPopup();
        }
    }

    setValue(value) {
        try {
            this.props.streng[this.props.field] = value;
            this.setState({value: value});
            activeProjectService.markDirty();   
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }


}

StrengInfoAutoComplete.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    streng: PropTypes.object,                 // a reference to the streng for which we are currently showing data.
    required: PropTypes.bool,
    showMissingErrors: PropTypes.bool,                       // when true, inputboxes that require a value but dont, should show an error
    readOnly: PropTypes.bool,                           // for viewer
};

StrengInfoAutoComplete.defaultProps = {
    required: false
}

export default withTranslation()(withStyles(styles)(StrengInfoAutoComplete));