/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import ViewCompact from 'mdi-material-ui/ViewCompact'; 
import DeleteIcon from 'mdi-material-ui/Delete'; 
import PlusBox from 'mdi-material-ui/PlusBox'; 

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ConfirmationDialog from '../../dialogs/confirmation_dialog';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { dialogService } from '../../../services/dialog_service';
import { errExtractor } from '../../services/error_extractor';
import { layoutService } from './layout_service';
import Tooltip from '@material-ui/core/Tooltip';
import OkCancelDialog from '../../dialogs/ok_cancel_dialog';
import Input from '@material-ui/core/Input';


const styles = (theme) => ({
    rootPopover: {
    },
    popoverBox: {
        margin: theme.spacing(2)
    },
    btn: {
    },
    noFilesItem: {
        fontStyle: 'italic',
        color: theme.palette.text.secondary
    }

});

class LayoutManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dlgRemoveOpen: false,
            dlgSaveOpen: false,
            open: false,
            anchorEl: null,
            layouts: [],                                                     // list of known layouts 
            newName: "",                                                     // the name of the new layout that will be saved
        };

        this.toRemove = null;                                               // file currently selected for removal
    }

    async componentDidMount() {
        this.setState({layouts: layoutService.presets});
    }

    render() {
        const { t, classes } = this.props;
        const layouts = this.state.layouts;
        return (
            <React.Fragment>
                <Tooltip title={t("Layout")}>
                    <IconButton onClick={this.toggleMenu} edge="end" color="inherit">
                        <ViewCompact />
                    </IconButton>
                </Tooltip>
                <Popover open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {(this.state.open) && 
                        <Box flex={1} display="flex" flexDirection="column" alignItems="stretch" className={classes.rootPopover} >
                            <List component="nav" aria-label="layouts">
                                {layouts.map((preset, idx, items) => this.renderItem(preset, idx, items))}

                                <Divider />
                                <ListItem button onClick={this.handleStartAddDoc}
                                    disabled={this.props.disabled}>
                                    <ListItemIcon>
                                        <PlusBox />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Save current')} />
                                </ListItem>
                            </List>
                        </Box>
                    }
                </Popover>
                <ConfirmationDialog title={t('Layout')}
                    question={t('remove_layout_q')}
                    open={this.state.dlgRemoveOpen}
                    onClose={this.handleRemovePreset}
                />
                {(this.state.dlgSaveOpen) &&
                    <OkCancelDialog title={t('Layout')}
                        open={true} 
                        onClose={this.handleAddDoc} 
                        question={t('What is the name for the new layout?')}
                    >
                        <Input placeholder={t("Name")} inputProps={{ 'aria-label': 'name' }} value={this.state.newName} onChange={this.handleNameChanged}/>
                    </OkCancelDialog>
                }

            </React.Fragment>
        );
    }

    handleNameChanged = (ev) => {
        let value = ev.target.value;
        this.setState({newName: value});
    }

    renderItem = (preset, idx, items) => {
        const item = 
                <ListItem button onClick={this.handleApplyPreset(preset)} key={idx}>
                    <ListItemText primary={preset.name} />
                    {(idx > 2) && 
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={this.handleStartRemove(preset)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    }
                </ListItem>
        if (idx !== 2 || items.length === 3) {                      // if there are more than 3 items, show a divider, top 3 are the static ones, below are the ones of the user
            return(item);
        }
        else {
            return(
                <React.Fragment>
                    {item}
                    <Divider/>
                </React.Fragment>        
            );
        }
    }

    handleApplyPreset = (preset) => async () => {
        try {
            if (this.props.disabled) {
                return;
            }
            this.setState({ open: false, anchorEl: null });
            await layoutService.applyPreset(preset);
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Layout"), errExtractor.get(error));
        }
    }

    handleStartRemove = (preset) => () => {
        this.toRemove = preset;
        this.setState({dlgRemoveOpen: true});
    }

    handleRemovePreset = (result) => {
        this.setState({dlgRemoveOpen: false});
        try {
            if (result) {
                const idx = this.state.layouts.indexOf(this.toRemove);
                layoutService.remove(idx);
                this.state.layouts.splice(idx, 1);
                this.forceUpdate();
            }
            this.toRemove = null;
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Layout"), errExtractor.get(error));
        }
    }

    toggleMenu = async (event) => {
        const newVal = !this.state.open;
        let anchorEl = null;
        if (newVal) {
            anchorEl = event.currentTarget;
        }
        this.setState({ open: newVal, anchorEl: anchorEl });
    }

    handleClose = () => {
        this.setState({ open: false, anchorEl: null });
    }

    handleStartAddDoc = () => {
        this.setState({dlgSaveOpen: true});
    }

    handleAddDoc = (result) => {
        this.setState({dlgSaveOpen: false});
        try {
            if (result) {
                layoutService.storeCurrent(this.state.newName);
                let newList = [...layoutService.presets];                            // amke a local copy so we can trigger a statechange again
                this.setState({layouts: newList});
            }
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Layout"), errExtractor.get(error));
        }

    }

}

LayoutManager.defaultProps = {

}

LayoutManager.propTypes = {
    disabled: PropTypes.bool,                       // when true, not allowed to switch between layouts
}

export default withTranslation()(withStyles(styles)(withRouter(LayoutManager)));