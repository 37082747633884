/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


import { engineService } from '../../../../components/video_engine/engine_service';
import { dialogService } from '../../../../services/dialog_service';
import { RobotBase } from './robot_base_service';
import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { lidarService } from '../sonarLidar/lidar_service';
import { sonarService } from '../sonarLidar/sonar_service';

/**
 * a robot service that receives the data from the engine processes through the pipe connection.
 */
export class RobotClient extends RobotBase {
    constructor() {
        super();
        this._isRecording = false;                                  // keep track if engine is recording somehow
        this.currentItem = {};
        this.onReverseRecordReady = null;                           // callback called when reverse recording is ready
    }

    /**
     * when this service starts, load the data or make connection to robot.
     */
    start() {
        this.data = null;                                         // need to init this to null, can only store recs while recording
        if (engineService.tractor) {
            if (engineService.tractor.lidarProvider) {                  // need to re-init the UI service cause we might have switched from video to live mode in the UI, in which case, the engine isn't restarted if it is already running, stil need to init the ui though
                lidarService.init(engineService.tractor.lidarProvider);
            }
            if (engineService.tractor.sonarProvider) {            
                sonarService.init(engineService.tractor.sonarProvider);
            }
        }
    }


    /**
     * returns the data event that was last played.
     * This is used by the ui component to perform a proper init when loaded
     * return null if there isn't any
     */
    getLastEvent() {
        return this.currentItem;
    }

    setCurrentTimestamp(value) {
        // dont do anything. this is only here so that we comply to the interface of a robot under
        // control of DocumentControlService
    }

    startRecord() {
        this.data = [];                                         // create at record, but don't destroy after recording is done, keep it, so that it can still be used to search in when user keeps camera on after record but wants to change time info
        this._isRecording = true;
        // dont reset the currentItem, cause then the user looses the view, which is the only thing it serves
    }


    /**
     *
     */
    isRecording() {
        return this._isRecording;
    }

    /**
     * send message to engine to start recording reverse
     */
    startReverseRecord() {
        engineService.startRevRecord().then(() => {
            this._isRecording = true;
        }).catch((error) => {
            dialogService.error(i18n.t("Tractor"), errExtractor.get(error));
        });
    }

    stopReverseRecord(onDone) {
        engineService.stopRevRecord().then(() => {
            this._isRecording = false;
            if (onDone) {
                onDone(true);
            }
        }).catch((error) => {
            onDone(false);
            dialogService.error(i18n.t("Tractor"), errExtractor.get(error));
        });
    }

    /**
     * processes robot data sent from engine process to ui process
     * @param {ojbect} data robot data
     */
    addRecord(data) {
        Object.assign(this.currentItem, data);                  // retrieve all the values and merge with those that didn't change.
        const rec = Object.assign({}, this.currentItem);                // create a new object for adding to the data points list, so that it can be consulted for time-v-distance translations during record
        if (this.onData) {                                                                                  // for in this process (overlay)
            this.onData(rec);
        }
        if (this.data) {                                        // only save the record while recording, otherwise we drop it cause it could fill things up
            this.data.push(rec);
        }
    }

    hasPitch() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasPitch();
    }

    hasYaw() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasYaw();
    }

    hasRoll() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasRoll();
    }

    hasCameraPan() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasCameraPan();
    }

    hasCameraTilt() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasCameraTilt();
    }

    hasDistance() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasDistance();
    }

    hasSonar() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasSonar();
    }

    hasLidar() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasLidar();
    }

    hasElevator() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasElevator();
    }

    canToggleLightDirection() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.canToggleLightDirection();
    }

    canToggleScanDirection() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.canToggleScanDirection();
    }

    hasClutch() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasClutch();
    }

    needsClutch() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.needsClutch();
    }

    hasRemote() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.remote != null;
    }

    hasAccelerometer() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasAccelerometer();
    }

    hasLensPressure() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasLensPressure();
    }

    hasTractorPressure() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasTractorPressure();
    }

    hasWheelPressure() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasWheelPressure();
    }

    hasBattery() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasBattery();
    }

    hasLifeLeft() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasLifeLeft();
    }

    hasLaserMeasure() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasLaserMeasure();
    }

    /**
     * for the button on the controller dashboard
     */
    hasCameraGotoCenter() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.hasCameraGotoCenter();
    }

    /**
     * gets the mode by which speed should be applied to the device.
     * possible values:
     * - single: only 1 speed control available
     * - dual: speed control available for tractor and wheel. can be controlled individually (and thus also combined)
     * - clutchToggled: speed control available for tractor and wheel but value of clutch determins which one is currently possible (clutch in-> tractor, clutch out-> wheel)
     * - none: no speed control allowed
     */
    speedMode() {
        return engineService.tractor && engineService.tractor._recorder && engineService.tractor._recorder.speedMode();
    }

    startLaser(autoStart=true) {
        engineService.startLaser(autoStart);
    }

    stopLaser() {
        engineService.stopLaser();
    }

    get sensorLimits() {
        if (engineService.tractor && engineService.tractor._recorder) {
            return engineService.tractor && engineService.tractor._recorder.sensorLimits;
        }
        return super.sensorLimits;
    }

}

export const robotRecorderClient = new RobotClient();