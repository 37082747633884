import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Copyright } from '../start/copyright_component';
import { authService } from '../services/auth_service';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { errExtractor } from '../services/error_extractor';
import Button from '@material-ui/core/Button';
import { dialogService } from '../../services/dialog_service';



const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        height: '50px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class ResetPwd extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
        }
    }


    render() {
        const { t } = this.props;
        return (
            <Container maxWidth="xs">
                <div className={this.props.classes.paper}>
                    <img src="https://storage.googleapis.com/cami_exec/name_logo.png"  alt="logo" 
                        className={this.props.classes.avatar}/>
                    <Typography variant="h6">
                        {t('Reset password')}
                    </Typography>
                    <form className={this.props.classes.form} noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Email address")}
                            name="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={(ev) => this.setState({email: ev.target.value})}
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={this.props.classes.submit}
                        >
                            {t('Request reset')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/login" 
                                    variant="body2">
                                    {t('Sign in')}
                                </Link>
                            </Grid>
                            <Grid item>
                               
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    {Copyright(t)}
                </Box>
            </Container>
        );
    }

    handleSubmit = async (ev) => {
        const { t } = this.props;
        try {
            ev.preventDefault();
            if (!this.state.email) {
                dialogService.error(t("Reset password"), t("Please provide a valid email address"))
            }
            let result = await authService.requestPwdReset(this.state.email);
            if (result) {
                dialogService.info(t("Reset password"), t("pwd_reset_success"))
            }
            else {
                dialogService.error(t("Reset password"), t("pwd_reset_failed"))
            }
        }
        catch(error) {
            dialogService.error(t("Reset password"), errExtractor.get(error));
        }
    }
}

export default withTranslation()(withStyles(styles)(ResetPwd));