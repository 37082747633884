
/**
 * used to keep track of the current play/recording distance traveled by the crawler. This allows everyone to get access
 * to this value.
 * 
 * Todo: move event handler here instead of documentControlService
 */
 class DistanceService {
    constructor() {
        this.current = 0;
    }
}

export const distanceService = new DistanceService();