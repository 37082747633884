/* 
 *   
 *   ELASTETIC CONFIDENTIAL
 *   ______________________
 *   
 *   [2019] - [2020] Elastetic GCV
 *   All Rights Reserved.
 *   
 *   NOTICE:  All information contained herein is, and remains
 *   the property of Elastetic GCV and its suppliers,
 *   if any.  The intellectual and technical concepts contained
 *   herein are proprietary to Elastetic GCV
 *   and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *   patents in process, and are protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material
 *   is strictly forbidden unless prior written permission is obtained
 *   from Elastetic GCV.
 */

import { activeProjectService } from '../../services/active_project_service';
import i18n from "i18next";
import { dialogService } from '../../services/dialog_service';

/**
 * provides services for measuring on the screen.
 */
class MeasurementService {

    constructor() {
        this.measureData = null;                                // field idx and validation data
        this.obs = null;                                        // observation currently measuring for.
    }


    /**
     * converts (for precision) and stores the value in the obs
     * @param {number} value the raw value
     */
    measurementDone(value) {
        if (value != null) {                                                // null indicates canceled operation
            const precision = (this.measureData.validifierData.precision !== undefined) ? this.measureData.validifierData.precision : 2;
            value =value.toFixed(precision);                                // we store a string, better control over number precision. Other parts of the system expect strings, so convert
            if (this.measureData.measuring === 1)  {
                this.obs.waarde1 =  value;
                activeProjectService.markDirty();
            }
            else if (this.measureData.measuring === 2) {
                this.obs.waarde2 =  value;
                activeProjectService.markDirty();
            }
            else {
                dialogService.error(i18n.t("Waarneming"), i18n.t("Er was een probleem tijdens het meten: ongeldige waarde.\nDe operatie is stop gezet."));
            }
        }
        return value;
    }
}

export const measurementService = new MeasurementService();