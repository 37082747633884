import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { Copyright } from '../start/copyright_component';
import { authService } from '../services/auth_service';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { errExtractor } from '../services/error_extractor';
import { dialogService } from '../../services/dialog_service';


//icons
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Register extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            firstname: '',
            lastname: ''
        }
    }


    render() {
        const { t } = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <div className={this.props.classes.paper}>
                    <Avatar className={this.props.classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("Sign up")}
                    </Typography>
                    <form className={this.props.classes.form} noValidate onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={t("First name")}
                                    value={this.state.firstname}
                                    onChange={(ev) => this.setState({firstname: ev.target.value})}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={t("Last name")}
                                    name="lastName"
                                    autoComplete="lname"
                                    value={this.state.lastname}
                                    onChange={(ev) => this.setState({lastname: ev.target.value})}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t("Email address")}
                                    name="email"
                                    autoComplete="email"
                                    inputProps={{type:"email"}}
                                    value={this.state.email}
                                    onChange={(ev) => this.setState({email: ev.target.value})}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t("Password")}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={this.state.password}
                                    onChange={(ev) => this.setState({password: ev.target.value})}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={this.props.classes.submit}
                        >
                            {t("Sign Up")}
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link component={RouterLink} to="/login" variant="body2">
                                    {t("Already have an account? Sign in")}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={5}>
                    {Copyright(t)}
                </Box>
            </Container>
        );
    }

    handleSubmit = (ev) => {
        authService.signUp(this.state.email, this.state.password, this.state.firstname, this.state.lastname)
        .then(() => {
            this.props.history.push('/home');
        })
        .catch((error) =>{
            const { t } = this.props;
            dialogService.error(t("Sign up"), t('register_failed_with_error', {error: errExtractor.get(error)}));
        });
        ev.preventDefault();
    }

}

export default withTranslation()(withStyles(styles)(Register));