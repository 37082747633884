/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

//icons
import RecordCircle from 'mdi-material-ui/RecordCircle';
import StopCircle from 'mdi-material-ui/StopCircle';
import PlayCircle from 'mdi-material-ui/PlayCircle';
import PauseCircle from 'mdi-material-ui/PauseCircle';
import ArrowLeftBoldCircle from 'mdi-material-ui/ArrowLeftBoldCircle';
import ArrowLeftBoldCircleOutline from 'mdi-material-ui/ArrowLeftBoldCircleOutline';

const styles = (theme) => ({
    buttonBar: {
        flexDirection: "row"
    }
});


/* speed optimization. By putting the buttons in a sperate renderer, they don't need to be rendered each time the timer is updated. */
class ControllerButtons extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { t } = this.props;
        
        let revereTractorIcon;
        if (this.props.hasReverseTrackData) {         
            revereTractorIcon = <ArrowLeftBoldCircleOutline fontSize="large"/>
        }
        else {
            revereTractorIcon = <ArrowLeftBoldCircle fontSize="large"/>
        }

        return (
            <div className={this.props.classes.buttonBar}>
                <Tooltip title={t("Pause")}>
                    <a>
                        <IconButton aria-label="pause"
                            id='pause'
                            disabled={this.props.pauseBtnDisabled}
                            onClick={this.props.onBtnPress}>
                            <PauseCircle fontSize="large"/>
                        </IconButton>
                    </a>
                </Tooltip>
                <Tooltip title={t("Stop (F4)")}>
                    <a>
                        <IconButton aria-label="stop"
                            id="stop"
                            disabled={this.props.stopBtnDisabled}
                            onClick={this.props.onBtnPress}>
                            <StopCircle fontSize="large"/>
                        </IconButton>
                    </a>
                </Tooltip>
                <Tooltip title={t("Play")}>
                    <a>
                        <IconButton aria-label="play"
                            id="play"
                            disabled={this.props.playBtnDisabled}
                            onClick={this.props.onBtnPress}>
                            <PlayCircle fontSize="large"/>
                        </IconButton>
                    </a>
                </Tooltip>
                {(!this.props.readOnly) && 
                    <React.Fragment>
                        <Tooltip title={t("Record (F5)")}>
                            <a>
                                <IconButton aria-label="record"
                                    id="record"
                                    disabled={this.props.recordBtnDisabled }  // when duration !=0 -> something has already been recorded, don't allow a new recording.
                                    onClick={this.props.onBtnPress}
                                    className={this.props.recClass}
                                >
                                    <RecordCircle fontSize="large"/>
                                </IconButton>
                            </a>
                        </Tooltip>
                        <Tooltip title="Reverse tractor record">
                            <a>
                                <IconButton aria-label="reverse tractor"
                                    id="reverseTractor"
                                    disabled={this.props.reverseTractorBtnDisabled}
                                    onClick={this.props.onBtnPress}
                                    className={this.props.reverseRecClass}
                                >
                                    {revereTractorIcon}
                                </IconButton>
                            </a>
                        </Tooltip>
                    </React.Fragment>
                }
            </div>

        );
    }

}

ControllerButtons.propTypes = {
    pauseBtnDisabled: PropTypes.bool,
    stopBtnDisabled:PropTypes.bool,
    playBtnDisabled:PropTypes.bool,
    recordBtnDisabled:PropTypes.bool,
    reverseTractorBtnDisabled:PropTypes.bool,
    reverseRecClass: PropTypes.any,
    hasReverseTrackData: PropTypes.bool,
    recClass: PropTypes.any,
    onBtnPress: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(ControllerButtons));