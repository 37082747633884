/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from "../../../../services/dialog_service";
import { toRadian } from '../../../services/cal';
import { RobotPlayer } from "../../../../components/document/robot/robot_player_service";
const csv = require( "csvtojson" );


/**
 *provides methods for recording/managing/working with track data
 seperated from trackview so we can keep data when ui not visible (switched to other view)
 */
export class TrackBaseService  {
    constructor() {

        this.data = [];                                                             // all the calculated data for the currently loaded project, set to the current distance
        this.raw = [];                                                              // trigometric data, calculated from the robot data. Stored here so we can easily translate tractor distance to flat-distance (triangles) for during playback + change the distances
        this.onUpdateData = null;                                                   // callback for the ui element, so it can do an update without a full refresh, for speed
        this.currentTime = 0;                                                       // updated by the document, when recording or pla
    }

    /**
     * returns height and distance calculated from the specified data point.
     * @param {object} value the recorded robot data, with a timestamp.
     */
    calculateTrigData(value, prevDistance) {
        let deltaDistance = value.distance - prevDistance;
        if (prevDistance === 0 || deltaDistance > 0) {
            let height = Math.sin(toRadian(value.pitch)) * deltaDistance;                    // a = b·sin(opposite angle) note: need to convert degree to radian
            let distance = Math.sqrt((deltaDistance * deltaDistance) - (height * height));                // A2 = B2 + C2 => b = sqrt(C2 - A2)
            return {height, distance, deltaDistance};
        }
        return null;
    }

   

    /**
     * loada the robot data and tanslates it into trackdata (inclination data)
     * @param {array} data list of robot data
     */
    loadData(data) {
        try {
            this.reset();
            let prevDistance = 0;
            let flatHorDist = 0;                                // horizontal distance, flattened out, needed to draw the lines, if we don't the lines will actually be longer than what the tractor reported (trigometry, sin, cos)
            let verDist = 0;
            for(const rec of data) {
                if (rec.distance >= 0) {                         //filter out negative values in the front which can screw up the length (alternative if this keeps causing problems: show the actual start on the chart isntead of 0)
                    let trigData = this.calculateTrigData(rec, prevDistance);
                    if (trigData) {
                        flatHorDist += trigData.distance;
                        verDist += trigData.height;
                        this.raw.push({...trigData, timestamp: rec.timestamp, tractorDistance: rec.distance, flatHorDist: flatHorDist, verDist: verDist});
                        prevDistance = rec.distance; 
                    }
                }
            }
            this.recalculateData();
        }
        catch(error) {
            dialogService.error(i18n.t("Laden"), i18n.t("doc_load_error", {error: errExtractor.get(error)}));
        }
    }

    /**
     * loads the data for the specified streng (robot data) and converts it into inclination
     * data and returns it
     * @param {object} streng the steng to load the data for
     * @param {bool} reverse when true, reverse data will be loaded
     * @returns inclination data that can be used in a TrackView
     */
    async load(streng, reverse, path=null) {
        const robotLoader = new RobotPlayer(); 
        await robotLoader.loadData(streng, false, reverse, path);                                  
        if (robotLoader.data) {
            if (reverse) {
                let points = [...robotLoader.data];                                           // reverse is inline
                points.reverse();
                let offset = points[0].distance;                                              // this is a reverse recording, so what is now the first record doesn't start at 0 for it's distance, which is what we need to adjust for now.
                points.forEach((rec) => {
                    rec.distance -= offset;
                });
                this.loadData(points);        
            }
            else {
                this.loadData(robotLoader.data);
            }
            return this.data;
        }
        return null;
    }

    /**
     * converts a flattened out distance value to a time 
     * @param {number} distance the horizontal, flattened distance to translate
     */
    flattenedDistanceToTime(distance) {
        if (this.raw) {
            for(const rec of this.raw) {
                if (rec.flatHorDist >= distance) {
                    return rec.timestamp;
                }
            }
        }
        return 0;
    }

    

    /**
     * called when the document is put in edit mode (upon create). Make certain
     * that all data is re-init.
     */
    reset() {
        this.data.length = 0;                               // just in case someone has a ref to this list
        this.raw.length = 0;
        this.currentTime = 0; 
    }

    /**
     * recalculates the track data based on the new distance.   
     * @param {list} robotData raw robot data
     * @param {number} distance the distance to use between each sample point
     */
     recalculateData(distance=0.1) {
        let tempDataHorDist = 0;                            // used to figure out when we need to collect a record. This is the distance that the tractor reports
        let tempDataVerDistMin = null;
        let tempDataVerDistMax = null;
        this.data.length = 0;

        for(const rec of this.raw) {
            tempDataHorDist += rec.deltaDistance;
            if (tempDataVerDistMin === null || tempDataVerDistMin > rec.verDist) {
                tempDataVerDistMin = rec.verDist;
            }
            if (tempDataVerDistMax === null || tempDataVerDistMax < rec.verDist) {
                tempDataVerDistMax = rec.verDist;
            }
            if (tempDataHorDist >= distance) {
                const record = {timestamp: rec.timestamp, height: rec.verDist, distance: rec.flatHorDist, min: tempDataVerDistMin, max: tempDataVerDistMax, distanceMin: rec.flatHorDist, distanceMax: rec.flatHorDist};
                this.data.push(record);
                tempDataHorDist = 0;
                tempDataVerDistMin = null;
                tempDataVerDistMax = null;
            }
        }

        const rec = this.raw[this.raw.length-1];
        if (tempDataHorDist !== 0) {
            this.data.push({timestamp: rec.timestamp, height: rec.verDist, distance: rec.flatHorDist, min: tempDataVerDistMin, max: tempDataVerDistMax, distanceMin: rec.flatHorDist, distanceMax: rec.flatHorDist});
        }
    }
}