/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import SignalDistanceVariant from 'mdi-material-ui/SignalDistanceVariant'; 

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { dialogService } from '../../services/dialog_service';
import { strandPropsService } from '../services/strand_props_service';
import { documentControlService } from '../document/document_control_service';
import Box from '@material-ui/core/Box';


export class BaseMeasure extends React.PureComponent {
    constructor(props) {
        super(props);

        // some tractors have a laser and can measure the distance this way, 
        // we only allow control of the laser during live feed
        // when robot supports laser control, we know it's in the engine-process, so the robotDs has a pipe with the engine which has the name of the channel. this way, we can verify that the user is trying to activate the laser on the same channel
        const hasLaser = documentControlService.robotDs.hasLaserMeasure();
        this.state = {
            hasLaser: hasLaser,
            laserOn: hasLaser && props.allowLaserControl,   // we always start with the laser on if there is any.
        }
        this.point1 = null;
        this.point2 = null;
        this.tempPoint = null;                              // while moving the mouse, but not yet committed.
        this.canvasRef = React.createRef();
        if (hasLaser && props.allowLaserControl) {
            documentControlService.robotDs.startLaser();
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyboard, true);
    }

    async componentWillUnmount() { 
        window.removeEventListener('keydown', this.handleKeyboard, true);
        if (this.props.allowLaserControl && this.state.laserOn) {
            documentControlService.robotDs.stopLaser();                                 // as soon as we are done with the laser, we can close it again
        }
    }

    handleKeyboard = (ev) => {
        if (ev.code === "esc" && ev.ctrlKey === false) {
            if (this.point1 !== null) {                    // ther is a current selection going on.
                this.resetLine();
            }
            else {
                this.closeMeasure();
            }
        }
    }

    resetLine() {
        this.point1 = null;
        this.point2 = null;
        this.tempPoint = null;
        this.drawLine();
    }

    render() {
        const cavnasStyle = {
            margin: 'auto',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            position: 'absolute',
            background: "transparent",
            borderWidth: "1px",
            borderColor: "blue",
            borderStyle: "solid",
            cursor: "crosshair",
            mixBlendMode: "exclusion",
        }
        const boxStyle = {
            margin: 'auto',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            position: 'absolute',
            background: "transparent",
            borderWidth: "1px",
            borderColor: "blue",
            borderStyle: "solid",
            //mixBlendMode: "exclusion",
            overflow: "visible",
            zIndex: 10000,
            pointerEvents: 'none'
        }
        if (this.props.needsMargin === false) {
            delete boxStyle.margin;
            delete cavnasStyle.margin;
        }
        const iconStyle = {
            right: '10px',
            top: "10px",
            position: 'absolute',
            color: 'black',
            width: '24px',
            height: '24px',
            backgroundColor: 'white',
            borderRadius: '15px',
            padding: '0px',
            pointerEvents: 'all',
        }
        let laserStyle;
        if (this.state.laserOn) {
            laserStyle = {right: '35px', top: "10px", position: 'absolute', padding: '4px', color: 'black', width: '24px', height: '24px', backgroundColor: 'white', borderRadius: '15px', padding: '0px', pointerEvents: 'all',};
        }
        else {
            laserStyle = {right: '35px', top: "10px", position: 'absolute', padding: '4px', color: 'blue', width: '24px', height: '24px', backgroundColor: 'white', borderRadius: '15px', padding: '0px', pointerEvents: 'all'};
        }
        return (
            <React.Fragment>
                <canvas ref={this.canvasRef} 
                    style={cavnasStyle}
                    width={this.props.width}
                    height={this.props.height}
                    onMouseUp={this.handleMouseUp}
                    onMouseMove={this.handleMouseMove}
                />
                {(this.props.showCloseButton) && 
                    <Box style={boxStyle}
                    width={this.props.width}
                    height={this.props.height}>
                        <IconButton 
                            onClick={this.closeMeasure} 
                            style={iconStyle}>
                            &times;
                        </IconButton>

                        { (this.props.allowLaserControl) &&
                            <IconButton 
                                onClick={this.toggleLaser} 
                                style={laserStyle}>
                                <SignalDistanceVariant/>
                            </IconButton>
                        }
                    </Box>
                }
            </React.Fragment>
        );
    }

    closeMeasure = ()  => {
        if (this.props.onMeasured) {
            this.props.onMeasured(null);
        }
    }

    toggleLaser = () => {
        const laserOn = !this.state.laserOn;
        if (laserOn) {
            documentControlService.robotDs.startLaser();
        } else {
            documentControlService.robotDs.stopLaser();
        }
        this.setState({laserOn});
    }

    handleMouseUp = (ev) => {
        if (ev.button == 0) {
            if (this.point1 == null) {
                this.point1 = this.getRelMousePos(ev);
                this.drawLine();
            }
            else if (this.point2 == null) {
                this.point2 = this.getRelMousePos(ev);
                this.drawLine();
                this.calculateDistance();                                       // will also close the measure
            }
            else {
                dialogService.error("internal error", `unexpected mouse event`);
            }
        } else {                                                                 // reset requested.
            this.resetLine();
        }
    }

    handleMouseMove = (ev) => {
        if (this.point1 != null) {
            this.tempPoint = this.getRelMousePos(ev);
            this.drawLine();
        }
    }

    /**
     * draw a line between the start en temp/end point
     */
    drawLine() {
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);                       // before we start, clear the prev drawing
        let point1 = this.getDrawPoint1();
        if (point1) {
            let point2 = this.getDrawPoint2();
            if (point2 == null) point2 = point1;                           // first draw, temppoint also not yet available
            ctx.beginPath();
            ctx.moveTo(point1.x, point1.y);
            ctx.lineTo(point2.x, point2.y);
            ctx.strokeStyle = "#ffffff";
            ctx.stroke();
        }
    }

    getDrawPoint1() {
        return this.point1;
    }

    getDrawPoint2() {
        return this.point2 != null ? this.point2 : this.tempPoint;    // could be that point2 not yet available
    }

    /**
     * calculate the distance between point1 and point 2, than close the measure.
     * for distance calc, see: https://stackoverflow.com/questions/28986872/finding-distance-between-two-points-on-image-even-when-image-is-scaled
     */
    calculateDistance() {
        throw new Error("to implement");
    }

    /**
     * calculate the mouse position relative to the canvas.
     * @param {object} ev mouse event data
     */
    getRelMousePos(ev) {
        let rect  = this.canvasRef.current.getBoundingClientRect();
        return { x: ev.clientX - rect.left, y: ev.clientY - rect.top };
    }

    getScalingFactor(def) {
        const scalingFactor1 = +def.scalingFactor1;
        const scalingFactor2 = +def.scalingFactor2;
        const scalingDistance1 = +def.scalingDistance1;
        const scalingDistance2 = +def.scalingDistance2;
        const distanceDif = Math.abs(scalingDistance1 - scalingDistance2);
        const factorDif = Math.abs(scalingFactor1 - scalingFactor2);

        let actualDistance;                       
        if (this.state.hasLaser && this.state.laserOn) {
            actualDistance = documentControlService.robotDs.currentItem.laserRange;
        }
        else {
            actualDistance = (strandPropsService.maxSize)/2;                         // radius, when shaped round.
        }
        let actualDif;
        let factorStart;
        if (Math.abs(scalingDistance1 - actualDistance) < Math.abs(scalingDistance2 - actualDistance)) {
            actualDif = scalingDistance1 - actualDistance;
            factorStart = scalingFactor1;
        }
        else {
            actualDif = scalingDistance2 - actualDistance;
            factorStart = scalingFactor2;
        }

        let percentageChangeDist = (1 / distanceDif) * actualDif;               // percengate change in distance, that needs to be applied to the factorchange that is added to the factor to get the same change
        let result = factorStart - (factorDif * percentageChangeDist)
        return result;
    }
}

BaseMeasure.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    onMeasured: PropTypes.func,                 // callback, called when measuring is done. param: value, number: the measurement in mm, as converted by the calibration value
    showCloseButton: PropTypes.bool,
    needsMargin: PropTypes.bool,
    channel: PropTypes.string,                  // the name of the camera/video channel
    allowLaserControl: PropTypes.bool,                 // do we allow the laser to be turned on or off (if there is a laser, we will always use it)
};

BaseMeasure.defaultProps = {
    showCloseButton: true,
    needsMargin: true,
    allowLaserControl: false
}
