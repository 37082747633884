/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from "../../../../services/dialog_service";
import { activeProjectService } from "../../../../services/active_project_service";
import { storageService } from "../../../../services/storage_service";
import { unpackLidar, centerLidar, mergeLidarLists } from "../../../services/lidar_data_service";
import { PlayerBaseService } from "./player_base_service";
import { StrandPropsService } from "../../../services/strand_props_service";

if (!Buffer) {                                                                  // so we can use Buffer in the browser
    const Buffer = require('buffer/').Buffer;
}

const LIDAR_VERSION = 3;                                        // most recent version currently active

/**
 * can load lidar data and play it, using the onData callback. Playback must be triggered by the tractor player.
 */
export class LidarPlayerBaseService extends PlayerBaseService  {
    
    constructor() {
        super();
        this._config = null;
        this.isReversed = false;
        this.minMax = [0,0];                        // the min and max values found in the lidar data. this is used to render the data (and define the min/max filters) in the rollout
        this.version = 3;                           // so we can catch the old (old) version 1 or 2 of the data file, probably only a couple of projects like this ever, but need to provide it.
        this.nrLinesToCombine = 1;                  // nr of raw lidar data lines that need to be combined into 1 line, for lidars that use multiple sensors 
        this.onWater = false;
    }

    /**
     * config object
     */
    get config() {
        return this._config;
    }

    set config(value) {
        this._config = value;
        this.trySaveConfig();
    }

    /**
     * for inheritors, so recorder can save, but not in viewer or website
     */
    trySaveConfig() {

    }

    /**
     * loads the data from the cloud, when available.
     * @param {object} streng the streng to load the data for
     * @param {bool} reverse if reverse data is requested
     */
    internalLoad(streng, reverse=false, folder=null) {
        streng = streng ?? activeProjectService.activeStreng;
        const url = reverse ? streng.lidarRevDataUrl : streng.lidarDataUrl;
        return (url) ? storageService.readBinaryContent(url.bucket, url.name) : Promise.resolve("");
    }

    

    /**
     * loads lidar data for the specified streng
     * returns the loaded data and also stores it in this.data.
     * This way, it can be used to rapidly load data async (for reporting) but also keep a cash of the last loaded data (for viewing)
     * @param {object} streng the streng to load the data for
     */
    load(streng=null, reverse=false, folder=null) {
        return new Promise(async (resolve, reject) => {
            this.data = [];                                                                     // before doing anythin, set the data to empty cause we are loading a new set and something could go wrong or the new data could be empty
            try {
                streng = streng ?? activeProjectService.activeStreng;
                const data = await this.internalLoad(streng, reverse, folder);
                const strandProps = new StrandPropsService();                       // we need this service to get the expected dimensions of the strand at a specific distance, this way we can find the center point better in case there is something extruding/pushed in on a side
                strandProps.init(streng);
                const parsed = this.parseData(data, streng, strandProps);
                this.data = parsed;
                resolve(parsed);
            }
            catch(error) {
                dialogService.error(i18n.t("Lidar"), i18n.t("doc_load_error", {error: errExtractor.get(error)}));
                resolve(null);
            }
        });
    }

    parseData(data, streng, strandProps) {
        let results = [];
        const buf = Buffer.from(data);                      // turn into buffer so it is easier to read
        let pos = this.readHeader(buf);
        const smallV = buf[pos++];
        if (smallV !== 1 && smallV !== 2 && smallV !== LIDAR_VERSION) throw new Error(i18n.t("Invalid lidar header, probably not a lidar file or corrupted"));
        this.version = smallV;
        
        let isReversed = false;
        let expectDistance = false;
        if (smallV >= 2) {
            expectDistance = true;
            isReversed = buf[pos++] === 1;
            if (smallV >= LIDAR_VERSION) {
                this.nrLinesToCombine = buf[pos++];
                this.onWater = buf[pos++] === 1;
            }
            pos = this.readConfig(buf, pos);
        }
        this.isReversed = isReversed;
        let min = Number.MAX_SAFE_INTEGER;
        let max = -1;
        while(pos < buf.length) {
            const dataLine = this.readDataLine(buf, pos, expectDistance, streng, strandProps);
            results.push(dataLine.recToAdd);
            pos = dataLine.pos;
            const valuesList = dataLine.recToAdd.values;
            for (let i=0; i < valuesList.length; i++) {
                const val = valuesList[i];
                if(val !== null && val !== 0) {                      // can be 0 or null when there is a section missing (too close to device)
                    min = (val < min) ? val : min;
                    max = (val > max) ? val : max;
                }
            }
        }
        if (isReversed) {                               // if the data was stored in reverse, than put it back in the correct order first
            results.reverse();
        }
        if (results.length > 0 && results[0].distance !== 0) {      // the first record is not at the 0 distance, which can be a problem for rendering (especially with reverse recording where this can happen with big numbers)
            const offset = results[0].distance;
            results.forEach((rec) => {
                rec.distance -= offset;
            });
        }
        this.minMax[0] = min;
        this.minMax[1] = max;
        return results;
    }

    readDataLine(buf, pos, expectDistance, streng, strandProps) {
        let distance = null;
        let timestamp;
        let finalValues = null;
        let finalAngles = null;

        timestamp = buf.readDoubleLE(pos);                          // first field is he id field, can be timestamp or distance (disatnce was added later)
        strandProps.updateFromObsList(streng, timestamp);           // make certain that the strandprops service is correctly updated
        pos += 8; 
        if (buf.length <= pos) throw new Error("Corrupt file: unexpected end-of-file reached");
        if (expectDistance) {
            distance = buf.readDoubleLE(pos);
            pos += 8; 
            if (buf.length <= pos) throw new Error("Corrupt file: unexpected end-of-file reached");
        }
        const lengths = [];
        for (let i=0; i < this.nrLinesToCombine; i++) {
            lengths.push(buf.readUInt32LE(pos));
            pos += 4;
            if (buf.length <= pos) throw new Error("Corrupt file: unexpected end-of-file reached");
        }  
        const width = strandProps.width / 1000;                  // need to go from mm to m
        const height = strandProps.height / 1000;
        for(const length of lengths) {
            let data = buf.slice(pos, pos + length);
            let values = [];
            let angles = [];
            unpackLidar(data, values, angles);
            centerLidar(values, angles, width, height, this.onWater);
            if (!finalValues) {                                             // this way, if only 1 line, we have the final values
                finalValues = values;
                finalAngles = angles;
            }
            else {
                const newVals = mergeLidarLists(finalValues, finalAngles, values, angles);
                finalValues = newVals.values;
                finalAngles = newVals.angles;
            }
            pos += length;
        }
        const recToAdd = {distance: distance, timestamp: timestamp, values: finalValues, angles: finalAngles};
        return {recToAdd, pos};
    }

    readHeader(buf) {
        let pos = 0;
        if (buf[pos++]!== 'l'.charCodeAt(0)) throw new Error(i18n.t("Invalid lidar header, probably not a lidar file or corrupted"));
        if (buf[pos++] !== 'i'.charCodeAt(0)) throw new Error(i18n.t("Invalid lidar header, probably not a lidar file or corrupted"));
        if (buf[pos++] !== 'd'.charCodeAt(0)) throw new Error(i18n.t("Invalid lidar header, probably not a lidar file or corrupted"));
        if (buf[pos++] !== 'a'.charCodeAt(0)) throw new Error(i18n.t("Invalid lidar header, probably not a lidar file or corrupted"));
        if (buf[pos++] !== 'r'.charCodeAt(0)) throw new Error(i18n.t("Invalid lidar header, probably not a lidar file or corrupted"));
        if (buf[pos++] !== 1) throw new Error(i18n.t("Invalid lidar header, probably not a lidar file or corrupted"));
        return pos;
    }

    /**
     * read the config section of the file
     * @param {array} buf raw data
     * @param {number} pos start pos within the buffer 
     */
    readConfig(buf, pos) {
        let result = {
            grayMode: buf[pos++] !== 0,
            filterRange: [],
            colors: [],
            colorStops: []
        };
        for(let i=0; i<2; i++) {
            result.filterRange.push(buf.readDoubleLE(pos));
            pos += 8;
        }
        for(let i=0; i<5; i++) {
            const number = buf.readUInt32LE(pos);
            const color = Number(number).toString(16);
            result.colors.push('#' + color);
            pos += 4;
        }
        for(let i=0; i<5; i++) {
            const stop = buf.readDoubleLE(pos);
            result.colorStops.push(stop);
            pos += 8;
        }
        this._config = result;
        return pos;
    }

    /** base class doesn't know the data structure */
    playRec(rec) {
        this.onData(rec.values, rec.angles);
    }

    /**
     * converts the old file format 1 to format 2. That is:
     * - include distance info
     * @param {array} robotData the robot data (maps timestamp to distance)
     */
    convertData(robotData) {
        let robotIdx = 0;
        for(const rec of this.data) {
            while(robotIdx < robotData.length && robotData[robotIdx].timestamp < rec.timestamp) {
                robotIdx++;
            }
            if (robotIdx < robotData.length) {
                rec.distance = robotData[robotIdx].distance;
            }
            else {
                break;
            }
        }
    }
}