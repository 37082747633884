/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import '../../../App.css';
import { activeProjectService } from '../../../services/active_project_service';
import { BaseMeasure } from '../../overlays/base_measure_component';
import { dialogService } from '../../../services/dialog_service';
import { strandPropsService } from '../../services/strand_props_service';
import { distance } from '../../services/cal';


class MeasureAngle extends BaseMeasure {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.point3 = null;
        this.point4 = null;
        this.firstPartDone = false;                     // when true, drawing second line
    }


    resetLine() {
        super.resetLine();
        this.point3 = null;
        this.point4 = null;
    }

    handleMouseUp = (ev) => {
        if (ev.button == 0) {
            if (this.point1 == null) {
                this.point1 = this.getRelMousePos(ev);
                this.drawLine();
            }
            else if (this.point2 == null) {
                this.point2 = this.getRelMousePos(ev);
                this.drawLine();
                this.firstPartDone = true;
            }
            else if (this.point3 == null) {
                this.point3 = this.getRelMousePos(ev);
                this.drawLine();
            }
            else if (this.point4 == null) {
                this.point4 = this.getRelMousePos(ev);
                this.drawLine();
                this.calculateDistance();                                       // will also close the measure
            }
            else {
                dialogService.error("internal error", `unexpected mouse event`);
            }
        } else {                                                                 // reset requested.
            this.resetLine();
        }
    }


    getDrawPoint1() {
        return this.firstPartDone ? this.point3 : this.point1;
    }

    getDrawPoint2() {
        if (this.firstPartDone) {
            return this.point4 != null ? this.point4 : this.tempPoint;    // could be that point2 not yet available
        }
        else {
            return this.point2 != null ? this.point2 : this.tempPoint;    // could be that point2 not yet available
        }
    }
    

    /**
     * calculate the distance between point1 and point 2, than close the measure.
     * for distance calc, see: https://stackoverflow.com/questions/28986872/finding-distance-between-two-points-on-image-even-when-image-is-scaled
     */
    calculateDistance() {
        const streng = activeProjectService.activeStreng;     // need this for the actual video size.
        if (!streng) throw new Error("internal error: no streng found");
        const def = streng.cams[this.props.channel];
        if (!def) throw new Error("internal error: no camera channel def found");

        const scalingFactor = this.getScalingFactor(def);
        const ratio = {x: def.width / this.props.width, y: def.height /this.props.height };
        this.point1 = {x: this.point1.x * ratio.x, y: this.point1.y * ratio.y};
        this.point2 = {x: this.point2.x * ratio.x, y: this.point2.y * ratio.y};
        this.point3 = {x: this.point3.x * ratio.x, y: this.point3.y * ratio.y};
        this.point4 = {x: this.point4.x * ratio.x, y: this.point4.y * ratio.y};
        const distance1 = distance(this.point1.x, this.point1.y, this.point2.x, this.point2.y) * scalingFactor;
        const distance2 = distance(this.point3.x, this.point3.y, this.point4.x, this.point4.y) * scalingFactor;
        const actuallength = distance2 - distance1;
        const adjacent = strandPropsService.maxSize;                                  // TODO: needs to be improved, depending on the type of streng + where clockwise it can be that width or height has to be used.
        let angle = Math.atan(actuallength / adjacent);                               // tan(angle) = opposite/adjacent
        if (this.props.onMeasured) {
            angle = angle * (180/Math.PI);                                          // still need to convert radian to degree
            this.props.onMeasured(angle);
        }
        this.resetLine();
    }

}

MeasureAngle.propTypes = {
    
};

export default MeasureAngle;