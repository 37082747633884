/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2022] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import VectorLine from 'mdi-material-ui/VectorLine';
import CheckAll from 'mdi-material-ui/CheckAll';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { lidarService } from './lidar_service';
import { toRadian } from '../../../services/cal';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
    checkbox: {
        color: 'black',
        width: '24px',
        height: '24px',
        fontSize: '15px',
        backgroundColor: 'white',
        borderRadius: '15px',
        padding: '0px',
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '2px',
        marginLeft: '2px',
    }

});


/**
 * UI element for managing the currently active user: config, logout..
 */
class LidarDistCrossSectionCalculator extends React.PureComponent {
    constructor(props) {
        super(props);
        this.valueEl = React.createRef();
    }

    componentDidMount() {
        this.setMeasure();
        setInterval(() => {                                                     // refresh the value every second, don't overcalculate to perserve processing power
            this.setMeasure();
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState)  {
        if (this.props.angle1 !== prevProps.angle1 || this.props.angle2 !== prevProps.angle2) {
            this.setMeasure();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}> 
                <VectorLine fontSize="inherit" />
                <div ref={this.valueEl} className={this.props.classes.text}>0mm</div>
                {(this.props.showAccept) &&
                    <IconButton 
                        onClick={this.handleAccept} 
                        className={this.props.classes.checkbox}>
                        <CheckAll fontSize="inherit"/>
                    </IconButton>
                }
            </div>
        );
    }

    handleAccept = async () => {
        if (this.props.onAccept) {
            const result = this.measure();
            if (result) {
                this.props.onAccept(result);
            }
        }
    }

    setMeasure() {
        const resUi = this.valueEl.current;
        if (resUi) {
            const result = this.measure();
            if (result != null) {
                resUi.innerHTML = `${result}mm`;      
            }
            else {
                resUi.innerHTML = "--";
            }
            
        }
    }

    /**
     * formula used:
     * m = distance to measure
     * h = distance 1
     * o = distance 2
     * angle = angle2 - angle1 (angle on the opposite side of m)
     * m = sqrt(h2 + o2 - (2*h*o*cos(angle))
     */
    measure() {
        let dist1, dist2;
        const angles = [this.props.angle1];
        let curAngle = 0;
        if (this.props.angle2) {
            angles.push(this.props.angle2);
        }
        for(let idx=0; idx < lidarService.data.length; idx++) {
            const corner = lidarService.angles[idx];
            const nextCorner = idx < lidarService.angles.length-1 ? lidarService.angles[idx+1] : lidarService.angles[0];
            if (corner <= angles[curAngle] && nextCorner > angles[curAngle]) {                                          // found something to draw
                if (dist1 == undefined) {
                    dist1 = lidarService.data[idx] * 1000;                                                                  // need it in mm
                }
                else {
                    dist2 = lidarService.data[idx] * 1000;                                                                  // need it in mm
                    break;
                }
                curAngle++;
                if (curAngle >= angles.length) {
                    break;
                }
            }
           
        }
        if (dist1 !== undefined && dist2 !== undefined) {
            const angleDif = toRadian(Math.abs(this.props.angle2 - this.props.angle1));
            const base = Math.pow(dist1, 2) + Math.pow(dist2, 2) - (2 * dist1 * dist2 * Math.cos(angleDif));
            return Math.round(Math.sqrt(base));
        }
        return null;
    }
}

LidarDistCrossSectionCalculator.propTypes = {
    showAccept: PropTypes.bool,                      // when true, show a checkbox that allows the user to 'accept' the value as a measurement for an observation
    onAccept: PropTypes.func,
    angle1: PropTypes.number,
    angle2: PropTypes.number,
};

export default withStyles(styles)(LidarDistCrossSectionCalculator);