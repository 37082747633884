import React from 'react';
import Typography from "@material-ui/core/Typography"
import Link from '@material-ui/core/Link';

export function Copyright(t) {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {t('copyright') }{' '}
            <Link color="inherit" href="https://elastetic.com">
                elastetic.com
                        </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}