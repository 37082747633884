import { toRadian, toDegrees, distance } from "./cal";

const CUTOFF_DISTANCE = 0.2;                    // distance at which a lidar point is considered invalid cause too close to the lidar (blocked by something). important for the dual-lidar, where each has a dead point
const ALLOWED_SIZE_DIF = 0.11;                  // % size difference allowed before the adjustment to the expected size on 1 side kicks in

/**
 * unpacks a lidar data line
 * @param {list} data bytearray, the input data
 * @param {array} values list of numbers, output, the distance measured at each angle
 * @param {array} angles list of numbers, the angle that correpsond to each value
 */
export function unpackLidar(data, values, angles) {
    
    for(let i=0; i < data.length; i+=8) {
        let value = data.readFloatLE(i);
        angles.push(value);

        value = data.readFloatLE(i+4);
        values.push(value/1000);                            // converts to meters, this is a more practial number to work with since most diameters are between 0.2 and 10 meters, otherwise it would be to big a nubmers for float32
    }
}

/**
 * packs the lidar data into a more compact format for storage
 * @param {array} values list of numbers, the distance measured at each angle
 * @param {array} angles list of numbers, the angle that correpsond to each value
 * @returns bytearray (Buffer)
 */
export function packLidar(values, angles) {
    let result = Buffer.alloc(values.length * 8);           // 4 bytes for value, 4 for angle
    for(let i=0; i < values.length; i++) {
        result.writeFloatLE(angles[i], i*8);
        result.writeFloatLE(values[i] * 1000, (i*8)+4);
    }
    return result;
}

export function getMinMaxFor(values, angles) {
    let minX, minY, maxX, maxY;
    for(let i = 0; i < values.length; i++) {
        let value = values[i];
        let corner = angles[i];
        const x = Math.cos(toRadian(corner)) * value;
        const y =  Math.sin(toRadian(corner)) * value;
        if (x < minX || minX === undefined) minX = x;
        if (y < minY || minY === undefined) minY = y;
        if (x > maxX || maxX === undefined) maxX = x;
        if (y > maxY || maxY === undefined) maxY = y;
    }
    return {minX, maxX, minY, maxY};
}

/**
 * modifies the values and angles list so that the data is centered.
 * process:
 * - calculate x, y for each value/angle
 * - get max/min for x & y
 * - average of max/min for x & y defines the new center
 * - for each value/angle, calculate distance & angle of new center to point:
 *   - x1 & y1 of value for old center
 *   - x2 & y2 of new center tov old center (are supplied by the coordinates of the new center
 *   - do x1 - x2 and y1 - y2 to get x & y of value tov new center - > this transposes the coordinates of the destination point from the old center (coordinate system) to the new center
 *   - distance formula d = sqrt( (x2-x1)2 + (y2-y1)2 )
 *   - for the angle, can be calculated from there
 * @param {array} values list of numbers, the distance measured at each angle
 * @param {array} angles list of numbers, the angle that correpsond to each value
 * @param {number} expWidth hte expected width of the strand, used to adjust the center
 * @param {number} expHeight hte expected height of the strand
 */
export function centerLidar(values, angles, expWidth, expHeight, isHalf=false) {
    let minX, minY, maxX, maxY;
    const coordinates = [];                                 // so we only have to calculate cos & sin of value tov old center
    for(let i = 0; i < values.length; i++) {
        let value = values[i];
        let corner = angles[i];
        const x = Math.cos(toRadian(corner)) * value;
        const y =  Math.sin(toRadian(corner)) * value;
        coordinates.push({x, y});
        if (x < minX || minX === undefined) minX = x;
        if (y < minY || minY === undefined) minY = y;
        if (x > maxX || maxX === undefined) maxX = x;
        if (y > maxY || maxY === undefined) maxY = y;
    }
    if (isHalf) {
        minY = -maxY;
    }
    const {centerX, centerY} = getCenterFor(minX, maxX, minY, maxY, expWidth, expHeight);

    for(let i = 0; i < coordinates.length; i++) {
        if (values[i] <= CUTOFF_DISTANCE) {                             // if we are at a dead spot, dont calculate, but indicate taht this is a dead spot, so that the values are skipped later on (when merged with list of other lidar)
            values[i] = null;
            angles[i] = null;
            continue;
        }
        const coord = coordinates[i];
        const newX = coord.x - centerX;                                         // translate the old coordinates to the new coordinate system
        const newY = coord.y - centerY;
        const newValue = Math.sqrt(Math.pow(newX, 2) + Math.pow(newY, 2));      // this is a distance calculation between the new x,y and the new (0,0)
        const newAngle = calculateAngle(newX, newY, newValue);
        values[i] = newValue;
        angles[i] = newAngle;
    }
}

function getCenterFor(minX, maxX, minY, maxY, expWidth, expHeight) {
    let centerX = (minX + maxX) / 2;
    let centerY = (minY + maxY) / 2;
    const realWidth = (Math.abs(minX) + Math.abs(maxX));
    const realHeight = (Math.abs(minY) + Math.abs(maxY));
    const halfExpHeight = expHeight / 2;
    const halfExpWidth = expWidth / 2;

    let allowedDif = expWidth * ALLOWED_SIZE_DIF;
    if (realWidth < expWidth - allowedDif || realWidth > expWidth + allowedDif) {       // width is out of range
        const divergenceMinX = Math.round((Math.abs(minX) - halfExpWidth) * 10000);           // *x and to int so that we only use x nr of digets
        const divergenceMaxX = Math.round((Math.abs(maxX) - halfExpWidth) * 10000);
        const divergenceDifX = Math.abs(divergenceMaxX - divergenceMinX);
        if (divergenceDifX > divergenceMinX || divergenceDifX > divergenceMaxX) {         // there must be a great difference on 1 side, if the distance to the left wall is about the same as the distance to the right wall, dont try to adjust
            if (divergenceMinX > divergenceMaxX) {
                centerX = maxX - halfExpWidth;
            }
            else if (divergenceMaxX > divergenceMinX) {
                centerX = minX + halfExpWidth;
            }
        }
        // both sides differ about the same size, nothing to be done about it, do regular center
    }

    allowedDif = expHeight * ALLOWED_SIZE_DIF;
    if (realHeight < expHeight - allowedDif || realHeight > expHeight + allowedDif) {       // width is out of range
        const divergenceMinY = Math.abs(Math.round((Math.abs(minY) - halfExpHeight) * 10000));           // *x and to int so that we only use x nr of digets
        const divergenceMaxY = Math.abs(Math.round((Math.abs(maxY) - halfExpHeight) * 10000));
        const divergenceDifY = Math.abs(divergenceMaxY - divergenceMinY);
        if (divergenceDifY > divergenceMinY || divergenceDifY > divergenceMaxY) {
            if (divergenceMinY > divergenceMaxY) {
                centerY = maxY - halfExpHeight;
            }
            else if (divergenceMaxY > divergenceMinY) {
                centerY = minY + halfExpHeight;
            }
        }
         // both sides differ about the same size, nothing to be done about it, do regular center, already set
    }
    return {centerX, centerY};
}

/**
 * calculate the angle value in the circle
 * depends on the quadrant
 * @param {number} a x value (cos), distance of side a
 * @param {number} b y value, (sin), distance of side b
 * @param {number} c new-value (radius) distance of side c
 */
function calculateAngle(a, b, c) {
    const angle = Math.atan2(b,a);
    const degrees = toDegrees(angle);
    if (degrees < 0) {
        return 360 + degrees;
    }
    else {
        return degrees;
    }
}

/**
 * re-order both lists so that the angles start at the lowest number and then go up
 * @param {list} values list of measurement value
 * @param {list} angles list of angles
 */
export function rotateToStart(values, angles) {
    const len = angles.length - 1;
    if (len > 1) {
        while(angles[0] > angles[len]) {
            angles.push(angles.shift());
            values.push(values.shift());
        }
    }
}


/**
 * merges set A and B of lidar data. 
 * @param {array} valuesA list of values for set A
 * @param {arra} anglesA list of angles for set A
 * @param {array} valuesB lsit of valeus for set B
 * @param {array} anglesB list of angles for set B
 * @returns { object} {values, angles}
 */
export function mergeLidarLists(valuesA, anglesA, valuesB, anglesB) {
    let posA = 0;
    let posB = 0;
    
    rotateToStart(valuesA, anglesA);
    rotateToStart(valuesB, anglesB);
    while (posA < valuesA.length && valuesA[posA] == null) posA++;
    while (posB < valuesB.length && valuesB[posB] == null) posB++;
    const values = [];
    const angles = [];
    while(posA < valuesA.length && posB < valuesB.length) {
        const angleA = anglesA[posA];
        const angleB = anglesB[posB];
        const valueA = valuesA[posA];
        const valueB = valuesB[posB];
        if (angleA < angleB) {
            values.push(valueA);
            angles.push(angleA);
            posA++;
        }
        else if (angleA > angleB) {
            values.push(valueB);
            angles.push(angleB);
            posB++;
        }
        else {                                      // angles are the same
            values.push((valueA + valueB) / 2);   // when the angles are the same, we take the average measurement
            angles.push(angleA);
            posA++;
            posB++;
        }
        while (posA < valuesA.length && valuesA[posA] == null) posA++;
        while (posB < valuesB.length && valuesB[posB] == null) posB++;
    }
    if (posA < valuesA.length) {
        values.push(...valuesA.slice(posA));
        angles.push(...anglesA.slice(posA));
    }
    else if (posB < valuesB.length) {
        values.push(...valuesB.slice(posB));
        angles.push(...anglesB.slice(posB));
    }
    return {values, angles};
}
