/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    docRoot: {
        flex: 1,
        height: '30px'
    },
    dialogPaper: {height: 'calc(100% - 64px);'}
});

class ObservationImageDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    

    render() {
        const { t, classes } = this.props;
        if (!this.props.open) {
            return null;
        }
        const fullScreen = window.innerWidth <= this.props.theme.breakpoints.values.sm;
        const paperStyle = fullScreen ? null : classes.dialogPaper;
        const ImgContHeight = fullScreen ? "calc(100% - 48px)" : "calc(100% - 24px)";
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="obs-detail-dialog-title"
                open={this.props.open}
                maxWidth="xl"
                fullScreen={fullScreen}
                classes={{paperScrollPaper: paperStyle}}
            >
                <DialogContent dividers style={{display: "flex", flexDirection: 'column', height: '100%'}}>
                    <div style={{flex: 1, height: ImgContHeight, textAlign: 'center'}}>
                        <img style={{height: "100%", objectFit: "contain"}} src={this.props.image}/>
                    </div>
                    <div>
                    <Typography>{this.props.label}</Typography>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleClose} color="primary">
                        {t("Sluiten")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClose = (event) => {
        if (this.props.onClose) this.props.onClose(null);
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();
    }
}

ObservationImageDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    image: PropTypes.string,
    label:  PropTypes.string,
};

export default withTheme(withTranslation()(withStyles(styles)(ObservationImageDialog)));