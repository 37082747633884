/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TickedProgressBar from '../../../controls/ticked_progress_bar_component';
import Tooltip from '@material-ui/core/Tooltip';
import i18n from "i18next";


const styles = (theme) => ({
    root: {
        position: 'relative',
      },
      content: {
        top: "0px",
        bottom: "0px",
        position: "absolute",
        left: "0",
        right: "0",
        margin: "auto",
        width: "fit-content",
        height: 'fit-content',
      },
      value: {
          fontSize: 'x-small',
          color: 'rgb(150, 150, 150)',
          textAlign: 'center'
      }
});

const MAX_UPDATE_SPEED = 500;                       // nr milliseconds between ui updates

class Speedometer extends React.PureComponent {
    constructor(props) {
        super(props);

        const val = props.value ?? 0.0;
        this.state = {
            value: val,
            valueTxt: val.toFixed(2),
        }

        if (props.handle) {
            props.handle(this);
        }
        this.throttleTimer = null;                  // set a top speed at how fast ui element is updated
        this.nextValue = null;                      // when throttling, need to know if and which value to set next
        this.nextValueTxt = null;                   // when throttling, need to know if and which value to set next
        this.unit = i18n.t("km/U");
    }

    componentWillUnmount() {
        if (this.props.handle) {
            this.props.handle(null);
        }
    }

    componentDidMount() {
        if (this.props.handle) {
            this.props.handle(this);
        }
    }


    render() {
        const { classes } = this.props;
        
        return (
            <Tooltip title={this.props.tooltip}>
                {/* div is needed to show the tooltip */}
                <div style={{flex: '50%', position:'relative', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                <TickedProgressBar progress={this.state.value}
                    radius={this.props.radius}
                    cut={120}
                    rotate={-210}
                    steps={5}
                    trackStrokeColor="#e6e6e6"
                    strokeColor="#5d9cec"
                    strokeLinecap="round"
                    trackTransition=""
                    strokeWidth={6}
                    trackStrokeWidth={2}
                    pointerStrokeWidth={2}
                >
                    <div className={classes.content}>
                        <div className="inner">
                            <div className={classes.value}>{this.state.valueTxt}<br/>{this.unit}</div>
                        </div>
                    </div>
                </TickedProgressBar>
                </div>
            </Tooltip>

        );
    }
    setValue = (value) => {
        value = value ?? 0;
        if (value < 0 || isNaN(value)) {
            value = 0;
        }
        const valueTxt = value.toFixed(2);
        if (value > 5) {                                    // speedometer cant handle more than 5km/h at themoment.
            value = 5;
        }

        if (value !== this.state.value || valueTxt !== this.state.valueTxt) {
            if (!this.throttleTimer) {
                this.setState({value: value, valueTxt: valueTxt});
                this.createThrottleTimer();
            }
            else {
                this.nextValue = value;
                this.nextValueTxt = valueTxt;
            }
        }
    }

    createThrottleTimer() {
        this.throttleTimer = setTimeout(() => {
            if (this.nextValue) {
                this.setState({value: this.nextValue, valueTxt: this.nextValueTxt});
                this.nextValue = null;
                this.nextValueTxt = null;
                this.createThrottleTimer();
            }
            else {
                this.throttleTimer = null;
            }
        }, MAX_UPDATE_SPEED);
    }
}

Speedometer.propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.number,
    tooltip: PropTypes.string,
    radius: PropTypes.number,
    handle: PropTypes.func,                             // used to let the parent get a ref to this object
};

export default withStyles(styles)(Speedometer);