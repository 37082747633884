/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import '../../App.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { configService } from '../../services/config_service';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { LanguageOptions } from '../../services/translation_service';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
    formControl: {
        margin: theme.spacing(3),
    },
    title: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: "700"
    },
    subTitle: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "500",
        marginTop: '15px'
    },

});


class GeneralSettings extends React.Component {
    constructor(props) {
        super(props);

        this.readOnly = process.env.REACT_APP_IS_VIEWER === 'true';

        const InclinationAsPercentage = configService.get('robot.InclinationAsPercentage');
        const lang = configService.get('general.language');
        this.state = {
            InclinationAsPercentage: InclinationAsPercentage,
            lang: lang
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState != this.state) {
            if (prevState.lang != this.state.lang) {
                configService.set("general.language", this.state.lang);
            }
            
            if (prevState.InclinationAsPercentage != this.state.InclinationAsPercentage) {
                configService.set('robot.InclinationAsPercentage', this.state.InclinationAsPercentage);
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className={this.props.classes.root} hidden={!this.props.isCurrentTab}>
                <FormControl component="fieldset" className={this.props.classes.formControl}>
                    <Typography className={this.props.classes.title}>{t("Algemeen")}</Typography>
                    <FormControl component="fieldset"  style={{marginBottom: '15px'}}>
                        <InputLabel htmlFor="language">{t("Taal")}</InputLabel>
                        <Select
                            value={this.state.lang}
                            onChange={this.setLanguage}
                            inputProps={{
                                name: 'language',
                                id: 'language',
                            }}
                        >
                            {LanguageOptions.map((x, idx) => {
                                return(<MenuItem key={idx} value={x.value}>{x.label}</MenuItem>);   
                            })}
                        </Select>
                        <FormHelperText>{t("Selecteer de taal voor de interface")}</FormHelperText>
                    </FormControl>

                    

                    <Typography className={this.props.classes.title} style={{marginTop: '15px'}}>{t("Tractor")}</Typography>

                    <FormControl component="fieldset" >
                        <InputLabel htmlFor="pitch">{t("Pitch")}</InputLabel>
                        <Select
                            value={this.state.InclinationAsPercentage}
                            onChange={(ev) => this.setState({InclinationAsPercentage: ev.target.value})}
                            inputProps={{
                                name: 'pitch',
                                id: 'pitch',
                            }}
                        >
                            <MenuItem key={0} value={false}>{t("graden")} (°)</MenuItem>   
                            <MenuItem key={1} value={true}>{t("Hellingsgraad")} (%)</MenuItem>   
                        </Select>
                        <FormHelperText>{t("Specifieer in welk formaat pitch wordt uitgedrukt.")}</FormHelperText>
                    </FormControl>

                </FormControl>
            </div>
        );
    }


    setLanguage = (ev) => {

        let value = ev.target.value;
        this.props.i18n.changeLanguage(value);
        this.setState({lang: ev.target.value});                             // system default is stored as the value as well.
    }
}

GeneralSettings.propTypes = {
    isCurrentTab: PropTypes.bool.isRequired
};

export default withTranslation()(withStyles(styles)(GeneralSettings));