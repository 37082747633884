/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { activeProjectService } from '../../../services/active_project_service';
import Observations from './observations_component';
import ObservationsTitleBox from './observation/observations_title_box_component';
import { configService } from '../../../services/config_service';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable handle="#observations-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    text: {
        textAlign: 'center'
    }
});

class ObservationsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.streng = activeProjectService.activeStreng;
        const showObsDetails = configService.get("observations.showObsDetails");
        this.state = {
            obs: this.streng?.observations ?? [],
            strengType: this.streng?.type ?? 'dp',
            showObsDetails: !!showObsDetails,                                               // in case of empty value, the !! turns it to true and back to false again.
        }

        this.obsRef = React.createRef();
    }

    

    render() {
        const { t } = this.props;
        if (!this.props.open) {
            return null;
        }
        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                aria-labelledby="observations-dialog-title"
                PaperComponent={PaperComponent}
                open={this.props.open}
            >
                <DialogTitle style={{ cursor: 'move' }} id="observations-dialog-title">
                    <ObservationsTitleBox onToggleObsDetails={this.handleToggleObsDetails} 
                            showObsDetails={this.state.showObsDetails}
                        />

                </DialogTitle>
                <DialogContent dividers>
                    <Observations observations={this.state.obs} 
                                innerRef={this.obsRef}
                                readOnly={this.props.readOnly}
                                showObsDetails={this.state.showObsDetails}
                                onChanged={this.handleObservationsChanged}
                                onPositionChanged={this.props.onPositionChanged}
                                strengType={this.state.strengType}
                                captureKeyboard={true}
                                onStartMeasure={this.props.onStartMeasure}
                                onStopMeasure={this.props.onStopMeasure}
                                onStartMeasureAngle={this.props.onStartMeasureAngle}
                                onTerminated={this.props.onTerminated}
                                onStartMeasureDepth={this.props.onStartMeasureDepth}
                                onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                                onStartMeasureFreeform={this.props.onStartMeasureFreeform}
                                initLoaded={true}
                                streng={this.streng}
                                addNewObs={this.props.addNewObs}
                                obsIndex={this.props.obsIndex}
                                />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCancel} color="primary">
                        {t("Sluiten")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleCancel = (event) => {
        if (this.props.onClose) this.props.onClose(null);
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();
    }

    handleToggleObsDetails = (ev) => {
        const newValue = !this.state.showObsDetails;
        this.setState({showObsDetails: newValue});
        configService.set("observations.showObsDetails", newValue);
        ev.stopPropagation();
    }

    handleObservationsChanged = (value) => {
        this.streng.observations = value;
        this.setState({obs: value});
        activeProjectService.markDirty();
    }
}

ObservationsDialog.defaultProps = {
    showMissingErrors: false,
};

ObservationsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    showMissingErrors: PropTypes.bool,                                       //when true, user tried to switch, but not allowed, so show all (missing) errors
    readOnly: PropTypes.bool,
    onPositionChanged: PropTypes.func,                  // when this observation is expanded, let the system go to the video point at which it was recorded.
    onStartMeasure: PropTypes.func,                     // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                      // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func, 
    onTerminated: PropTypes.func,                       // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
    addNewObs: PropTypes.bool,                          // when true upon opening, a new record will be created and put into focus for editing
    obsIndex: PropTypes.number,                         // when reopening the dialog after a measure, we need to open the same observation, which is done through this field
};

export default withTranslation()(withStyles(styles)(ObservationsDialog));