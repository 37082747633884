/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import SplitPane from 'react-split-pane';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { configService } from '../../services/config_service';

import Inspection from './inspection/inspection_component';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import { DocumentBaseComponent } from '../../components/document/document_base_component';
import CircularProgress from '@material-ui/core/CircularProgress';
import VideoSection from './video/video_section_component';
import Instrumentation from './instrumentation/instrumentation_component';
import { layoutService } from './layout/layout_service';




const styles = (theme) => ({
    root: {                         //warning: can't add position and width here cause it gets overwritten then by other styles, need to apply position & width through 'style' on the element itself.
        padding: theme.spacing(1)
    },
});


class DefaultDocumentBody extends DocumentBaseComponent {

    constructor(props) {
        super(props);
        let showVideo = configService.get('layout.document.showVideoSection');
        showVideo = showVideo === 'true' || showVideo === true;                     // need to make certain that strings are handled ok.
        let horSplit = showVideo ? +configService.get('recorder.horizontalSplitWidth') : 0;
        this.state = {
            verSplit: +configService.get('recorder.verticalSplitWidth'),            // for browser.localStorage, it returns a string, so make certain it is cnverted to number
            horSplit: horSplit,
            instrumentationHeight: null,                                            // the instrumentation box needs to know it's alloted height so it can calculate the size of the knobs (have fixed size), if we don't do this, we can't get the dashboard to size properly
            showVideoSection: showVideo,                                            // determins if the video section is visible or not
        }
        this.VerplitPaneRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowResized);
        layoutService.events.on('changed', this.handleLayoutChanged);
        if (this.props.initLoaded && this.state.instrumentationHeight === null && this.VerplitPaneRef.current) {
            setTimeout(() => {                                                                                          // need to call this out of sync so that the clientHeight ahs the correct value, if we don't do this, the bottom is still wrong
                this.setState({instrumentationHeight: this.VerplitPaneRef.current?.pane2.clientHeight - this.state.horSplit});
            }, 0);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windowResized);
        layoutService.events.removeListener('changed', this.handleLayoutChanged);
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.instrumentationHeight === null && this.VerplitPaneRef.current) {         // when loading, at first verSplitPane is not loaded, so need to wait until it is. only do 1 time at startup though so check if instrumentationHeight == null
            this.setState({instrumentationHeight: this.VerplitPaneRef.current?.pane2.clientHeight - this.state.horSplit});
        }
    }

    render() {
        const { t } = this.props;
        if (this.props.readOnly === null || !this.props.initLoaded) {                                       // important: this helps with the load of a project after start. if we don't do this, Inspections object gets rendered before data has been loaded. The inspection object doesn't change anything in the props, so id doesn't reload. same goes for a bunch of other stuff
            return(
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%', position: 'relative' }}> 
                <CircularProgress />
                {t("Loading project")}
            </Box>
            );
        }

        return (
            <SplitPane split="vertical"
                ref={this.VerplitPaneRef}
                maxSize={-170}
                minSize={100}
                primary="second"
                className={this.props.classes.root}
                style={{ height: '100%', position: 'relative' }}
                pane1Style={{ overflow: 'auto', minWidth: "100px" }}
                size={this.state.verSplit}
                onChange={this.storeVerSplitPos}
                onDragStarted={() => (document.body.style.cursor = 'ew-resize')}
                onDragFinished={() => (document.body.style.cursor = 'auto')}>
                <Inspection direction={this.props.direction}
                    width={this.state.verSplit}
                    readOnly={this.props.readOnly}
                    onDirectionChanged={this.props.onDirectionChanged}
                    onPositionChanged={this.props.onPositionChanged}
                    onStartMeasure={this.props.onStartMeasure}
                    onStopMeasure={this.props.onStopMeasure}
                    onStartMeasureAngle={this.props.onStartMeasureAngle}
                    onTerminated={this.props.onTerminated}
                    onStartMeasureDepth={this.props.onStartMeasureDepth}
                    onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                    onStartMeasureFreeform={this.props.onStartMeasureFreeform}
                    initLoaded={this.props.initLoaded}
                />
                {this.renderRightSide()}
            </SplitPane>
        );
    }

    renderRightSide() {
        let minSize = 50;
        let resizerStyle = null;
        let panel1Style = null;
        if (!this.state.showVideoSection) {
            minSize = 0;
            resizerStyle = {minHeight: '0px', height: '0px'};
            panel1Style = {display: 'none'};
        }
        return (
            <SplitPane split="horizontal" minSize={minSize}
                maxSize={-230}
                pane1Style={panel1Style}
                size={this.state.horSplit}
                resizerStyle={resizerStyle}
                style={{overflow: 'unset'}}
                onDragStarted={() => (document.body.style.cursor = 'ns-resize')}
                onDragFinished={() => (document.body.style.cursor = 'auto')}
                onChange={this.storeHorSplitPos}>
                <VideoSection 
                    fullscreen={this.props.fullScreen}
                    readOnly={this.props.readOnly}
                    onToggleFullScreen={this.props.onToggleFullScreen}
                    videoWidth={this.state.verSplit}
                    videoHeight={this.state.horSplit}
                    liveFeed={(this.props.canRecord || this.props.forceLiveFeed) && !this.props.readOnly}
                    jumpToTime={this.props.jumpToTime}
                    videoControlState={this.props.videoControlState}
                    onCurrentTimeChanged={this.props.onCurrentTimeChanged}
                    onDurationChanged={this.props.onDurationChanged}
                    onEnded={this.props.onEnded}
                    activeStreng={this.props.activeStreng}
                    forceLiveFeed={this.props.forceLiveFeed}
                    onToggleForceLiveFeed={this.props.onToggleForceLiveFeed}
                    measureData={this.props.measureData}
                    onMeasured={this.props.onMeasured}
                    onVideoLoaded={this.props.onVideoLoaded}
                    onSelectChannel={this.props.onSelectChannel}
                    initLoaded={this.props.initLoaded}
                    visible={this.state.showVideoSection}
                    onOpenVideoScreen={this.props.onOpenVideoScreen}
                />
                <Instrumentation readOnly={this.props.readOnly}
                    allowedHeight={this.state.instrumentationHeight}
                    verSplit={this.state.verSplit}
                    direction={this.props.direction}
                    measureData={this.props.measureData}
                    onMeasured={this.props.onMeasured}
                    onDirectionChanged={this.props.onDirectionChanged}
                    onPositionChanged={this.props.onPositionChanged}
                    initLoaded={this.props.initLoaded}
                    canRecord={this.props.canRecord}
                    forceLiveFeed={this.props.forceLiveFeed}
                    buttonState={this.props.videoControlState}
                    streng={this.props.activeStreng}
                    onButtonPressed={this.props.onControlChanged}
                    onToggleVideoSection={this.handleToggleVideoSection}
                    showVideoSection={this.state.showVideoSection}
                    onOpenVideoScreen={this.props.onOpenVideoScreen}
                />
            </SplitPane>
        );
    }

    
    storeVerSplitPos = (width) => {
        configService.set('recorder.verticalSplitWidth', width);
        this.setState({ verSplit: width });

    }

    storeHorSplitPos = (height) => {
        configService.set('recorder.horizontalSplitWidth', height);
        this.setState({ horSplit: height, instrumentationHeight: this.VerplitPaneRef.current?.pane2.clientHeight - height, });
    }

    windowResized = () => {
        this.setState({ instrumentationHeight: this.VerplitPaneRef.current?.pane2.clientHeight - this.state.horSplit });
    }

    handleToggleVideoSection = () => {
        const newValue = !this.state.showVideoSection;
        let horSplit = newValue ? +configService.get('recorder.horizontalSplitWidth') : 0;
        let instrumentationHeight;
        if (newValue) {
            horSplit = +configService.get('recorder.horizontalSplitWidth');
            instrumentationHeight = this.VerplitPaneRef.current?.pane2.clientHeight - horSplit;
        }
        else {
            horSplit = 0;
            instrumentationHeight = this.VerplitPaneRef.current?.pane2.clientHeight;
        }
        configService.set('layout.document.showVideoSection', newValue);
        this.setState({showVideoSection: newValue, horSplit: horSplit, instrumentationHeight});
    }

    handleLayoutChanged = (layout) => {
        const width = +configService.get('recorder.verticalSplitWidth');                    // get directly from configservice cause some layouts have this empty, in which case the layoutservice calculates and stores it
        const height = +configService.get('recorder.horizontalSplitWidth');
        let showVideo = configService.get('layout.document.showVideoSection');
        showVideo = showVideo === true || showVideo === "true";
        this.setState({ showVideoSection: showVideo, horSplit: showVideo ? height: 0, instrumentationHeight: this.VerplitPaneRef.current?.pane2.clientHeight - height, verSplit: width});
    }

}

DefaultDocumentBody.propTypes = {
    onVideoLoaded: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    onDirectionChanged: PropTypes.func,
    direction: PropTypes.string,
    onPositionChanged: PropTypes.func,                   // when this observation is expanded, let the system go to the video point at which it was recorded.
    onStartMeasure: PropTypes.func,                 // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                  // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func,
    onTerminated: PropTypes.func,                   // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
    fullscreen: PropTypes.bool,
    onToggleFullScreen: PropTypes.func,
    canRecord: PropTypes.bool,
    forceLiveFeed: PropTypes.bool,                                  // true when user pressed the 'camera' button
    onToggleForceLiveFeed: PropTypes.func,
    videoControlState: PropTypes.any,
    onCurrentTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onEnded: PropTypes.func,
    activeStreng: PropTypes.any,

    onMeasured: PropTypes.func,
    measureData: PropTypes.any,
    onVideoLoaded: PropTypes.func,                                  // called when a new video has been assigned to the streng and the UI should reload the streng cause of new conditions (video available)
    onSelectChannel: PropTypes.func,                                // called when the selected channel is changed (the camera/video feed currently displaying in this video section)
    initLoaded: PropTypes.bool,                                     // allows us to reload the channels after initial load of project and engine
    jumpToTime: PropTypes.number,
    onControlChanged: PropTypes.func,
    onOpenVideoScreen: PropTypes.func,                      // callback for the button to open new screen for video
};

export default withTranslation()(withStyles(styles)(DefaultDocumentBody));

/*



*/