/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import '../../../App.css';
import { activeProjectService } from '../../../services/active_project_service';
import { BaseMeasure } from '../../overlays/base_measure_component';
import { distance } from '../../services/cal';



class Measure extends BaseMeasure {
    constructor(props) {
        super(props);
    }

    

    /**
     * calculate the distance between point1 and point 2, than close the measure.
     * for distance calc, see: https://stackoverflow.com/questions/28986872/finding-distance-between-two-points-on-image-even-when-image-is-scaled
     */
    calculateDistance() {
        const streng = activeProjectService.activeStreng;     // need this for the actual video size.
        if (!streng) throw new Error("internal error: no streng found");
        const def = streng.cams[this.props.channel];
        if (!def) throw new Error("internal error: no camera channel def found");

        const scalingFactor = this.getScalingFactor(def);
        const ratio = {x: def.width / this.props.width, y: def.height /this.props.height };
        this.point1 = {x: this.point1.x * ratio.x, y: this.point1.y * ratio.y};
        this.point2 = {x: this.point2.x * ratio.x, y: this.point2.y * ratio.y};
        let value = distance(this.point1.x, this.point1.y, this.point2.x, this.point2.y) * scalingFactor;
        if (this.props.onMeasured) {
            this.props.onMeasured(value);
        }
        this.resetLine();
    }

}

Measure.propTypes = {
};

export default Measure;