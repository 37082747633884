/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';

export const ValueDirection = {
    top: 0, 
    left: 1, 
    bottom: 2,
    right: 3,
}

const styles = (theme) => ({
    root: {
        display: 'grid',
        gridTemplate: '1fr / 1fr',                  // 1 col, 1 row
        placeItems: 'center',
    },
    bottom: {
        gridColumn: "1 / 1",
        gridRow: "1 / 1",
        zIndex: 1,
    },
    top: {
        gridColumn: "1 / 1",
        gridRow: "1 / 1",
        zIndex: 2,
    }
});


/**
 * displays an svg icon and, when enabled, anothe icon on top of it, but only part of the
 * icon and in an other color (green to red, depending on the value).
 * the value determines the height of the secondary icon being shown. This allows us
 * to build a gauge by using 2 svg icons
 */
class MeasuredIcon extends React.Component {
    constructor(props) {
        super(props);
        this.pathRef = React.createRef();               // to quickly set the value
        this.lastValue = 50;
    }

    componentDidMount() {
        this.setValue(0);
    }

    render() {
        const { classes, showValue } = this.props;
        const style = showValue ? {color: this.getColor(), clipPath: this.getClipPath() } : {}; 
        if (this.props)

        return (
            <div className={classes.root} style={{fontSize: this.props.size}}>
                <SvgIcon className={classes.bottom} fontSize="inherit">
                    <path d={this.props.icon}/>
                </SvgIcon>
                {(showValue) &&
                    <SvgIcon className={classes.top} fontSize="inherit">
                        <path d={this.props.icon} ref={this.pathRef} style={style}/>
                    </SvgIcon>
                }
            </div>
        );
    }

    setValue(value) {
        this.lastValue = value;
        if (this.pathRef.current) {
            const path = this.pathRef.current;
            path.style.color = this.getColor();
            path.style.clipPath = this.getClipPath();
        }
    }

    /**
     * gets the color for the current value
     */
    getColor() {
        if (this.props.color) {
            return this.props.color;
        }
        let color = 'green';
        const limits = this.props.limits;
        if (this.lastValue >= limits.error) {
            color = 'red';
        }
        else if (this.lastValue >= limits.warning) {
            color = 'orange';
        }
        return color;
    }

    getClipPath() {
        const value = 100 - (100 * this.lastValue);             // convert to percentage and invert value so we can use it as a value in the clippath. Value is always between 0 and 1
        switch (this.props.direction) {
            case ValueDirection.top:
                return `inset(${value}% 0px 0px 0px)`;       
            case ValueDirection.left:
                return `inset(0px 0px 0px ${value}%)`;       
            case ValueDirection.bottom:
                return `inset(0px 0px ${value}% 0px)`;       
            case ValueDirection.right:
                return `inset(0px ${value}% 0px 0px)`;       
            default:
                return "";
        }
    }
}

MeasuredIcon.propTypes = {
    icon: PropTypes.any,
    limits: PropTypes.object,                          // {max: "max value that can be displayed", warning: "value at which orange color is used", error: "value at which red color is used"}. Either limits or color need to be set
    direction: PropTypes.number,                    // 0=top, 1=left, 2=bottom, 3=right
    showValue: PropTypes.bool,                      // when true, the overlay is shown. default false
    size: PropTypes.number,
    color: PropTypes.string,                            // color of top (value) icon. either color or limits needs to be set
};

MeasuredIcon.defaultProps = {
    showValue: false
}

export default withStyles(styles)(MeasuredIcon);