/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */
//icons
import ChevronDown from 'mdi-material-ui/ChevronDown';


import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography'; 
import Observations from '../observations_component';
import StrengInfo from './streng_info_component'
import { activeProjectService } from '../../../../services/active_project_service';
import { withTranslation } from 'react-i18next';
import { projectService } from '../../../services/project_service';
import { configService } from '../../../../services/config_service';
import { dialogService } from '../../../../services/dialog_service';
import ObservationsTitleBox from '../observation/observations_title_box_component';


const styles = (theme) => ({
    root: {
        height: '100%',
        width: '100%'
    },
    GeneralInfo: {
        boxShadow: 'none',
        margin: 0,
        padding: 0
    },
    GeneralInfoDetails: {
        flexDirection: 'column',
        padding: 0,
        margin: 0
    },
    GeneralInfoSummary: {
        padding: 0,
        margin: 0
    },
    textField: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "600"
    },
});


class Streng extends React.PureComponent {
    constructor(props) {
        super(props);

        const expandedIdx = 0;
        const showObsDetails = configService.get("observations.showObsDetails");
        this.state = {
            expandedIdx: expandedIdx,                                                     // determins the expanded item.
            observations: props.streng ? props.streng.observations : [],
            strengType: props.streng ? props.streng.type : 'dp',
            showMissingErrors: false,
            showObsDetails: !!showObsDetails,                                               // in case of empty value, the !! turns it to true and back to false again.
        }
    }

    componentDidMount() {
        activeProjectService.events.addListener("obsGenerated", this.handleObsGenerated);
    }

    componentWillUnmount() {
        activeProjectService.events.removeListener("obsGenerated", this.handleObsGenerated);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.streng != this.props.streng) {
            this.setState({
                observations: this.props.streng ? this.props.streng.observations : [],
                strengType: this.props.streng ? this.props.streng.type : 'dp',
            })
        }
        if (prevProps.forceStrengDetails != this.props.forceStrengDetails && this.props.forceStrengDetails === true) {
            if (this.state.expandedIdx != 0) {
                this.setState({expandedIdx: 0});                                                                            // make certain that the correct part is visible
            }
            if (this.props.onStrengDetailsForced) {
                this.props.onStrengDetailsForced();
            }
        }
    }

    render() {
        const { t } = this.props;
                
        return (
            <div className={this.props.classes.root}>
                <ExpansionPanel classes={{root: this.props.classes.GeneralInfo}}
                    expanded={this.state.expandedIdx == 0}
                    onChange={this.switchExpanded(0)}>
                    <ExpansionPanelSummary
                        expandIcon={<ChevronDown />}
                        aria-controls="strengs"
                        id="strengs"
                        classes={{root: this.props.classes.GeneralInfoSummary}}
                    >
                        <Typography className={this.props.classes.heading}>{t("Algemeen")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{root: this.props.classes.GeneralInfoDetails}}>
                        
                        <StrengInfo streng={this.props.streng} 
                            direction={this.props.direction} 
                            readOnly={this.props.readOnly}
                            onDirectionChanged={this.props.onDirectionChanged}
                            strengType={this.state.strengType}
                            showMissingErrors={this.state.showMissingErrors}
                            onTypeChanged={this.handleTypeChanged}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel classes={{root: this.props.classes.GeneralInfo}}
                    expanded={this.state.expandedIdx == 1}
                    onChange={this.switchExpanded(1)}>
                    <ExpansionPanelSummary
                        expandIcon={<ChevronDown />}
                        aria-controls="strengs"
                        id="strengs"
                        classes={{root: this.props.classes.GeneralInfoSummary}}
                    >
                        <ObservationsTitleBox onToggleObsDetails={this.handleToggleObsDetails} 
                            showObsDetails={this.state.showObsDetails}
                        />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails classes={{root: this.props.classes.GeneralInfoDetails}}>
                        <Observations 
                            observations={this.state.observations}
                            readOnly={this.props.readOnly}
                            onChanged={this.handleObservationsChanged}
                            onPositionChanged={this.props.onPositionChanged}
                            strengType={this.state.strengType}
                            captureKeyboard={this.props.captureKeyboard && this.state.expandedIdx == 1}
                            onStartMeasure={this.props.onStartMeasure}
                            onStopMeasure={this.props.onStopMeasure}
                            onStartMeasureAngle={this.props.onStartMeasureAngle}
                            onTerminated={this.props.onTerminated}
                            onStartMeasureDepth={this.props.onStartMeasureDepth}
                            onStartMeasureAreaSize={this.props.onStartMeasureAreaSize}
                            onStartMeasureFreeform={this.props.onStartMeasureFreeform}
                            showObsDetails={this.state.showObsDetails}
                            initLoaded={this.props.initLoaded}
                            streng={this.props.streng}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }


    handleObsGenerated = () => {
        if (this.state.expandedIdx !== 1) {                 // make certaint aht the input is visible
            this.setState({expandedIdx: 1});    
        }
    }

    handleToggleObsDetails = (ev) => {
        const newValue = !this.state.showObsDetails;
        this.setState({showObsDetails: newValue});
        configService.set("observations.showObsDetails", newValue);
        ev.stopPropagation();
    }

    switchExpanded = (panelIdx) => (event, isExpanded) => {
        const { t } = this.props;
        if (this.state.expandedIdx == 0 && (panelIdx == 1 || isExpanded === false)) {   // when go from main to strengs (or close main), check if allowed.
            const onlyAllowObsOnFulStreng = configService.get('observations.onlyAllowObsOnFulStreng') && this.props.readOnly === false;  // viewer can always continue to the next section. All read-only is allowed to continue
            if (onlyAllowObsOnFulStreng && projectService.isStrengComplete(this.props.streng) === false) {
                this.setState({showMissingErrors: true});
                dialogService.error(t("Streng gegevens"), t("Er ontbreekt nog data in de streng gegevens sectie. Je kan pas verder gaan als alles is ingevuld"));
                return;
            }
        }
        this.setState({ expandedIdx: isExpanded ? panelIdx : -1, showMissingErrors: false });
        if (this.props.onExpandChanged) {
            this.props.onExpandChanged();
        }
    }

    handleObservationsChanged = (value) => {
        if (!this.props.streng) {
            dialogService.error("internal error", `no streng loaded in props, reload file`);
            return;
        }
        this.props.streng.observations = value;
        if (this.state.expandedIdx == 1){                                                   // make certain that the panel is expanded when adding a new streng.
            this.setState({observations: value});
        }
        else {
            this.setState({observations: value, expandedIdx: 1});
        }
        activeProjectService.markDirty();
    }

    handleTypeChanged = (value, obs) => {
        if (obs !== undefined) {
            this.setState({strengType: value, observations: obs});
        }
        else {
            this.setState({strengType: value});
        }
    }
}

Streng.propTypes = {
    onExpandChanged: PropTypes.func,
    streng: PropTypes.object,                           // a reference to the streng for which we are currently showing data.
    readOnly: PropTypes.bool,                           // for viewer
    direction: PropTypes.string,                        // allowed values: 
    captureKeyboard: PropTypes.bool,                    // when true, observations is allowed to capture keyboard events. If we don't do this, we can't use a space when editing fields, cause observations traps it.
    onDirectionChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,                  // when this observation is expanded, let the system go to the video point at which it was recorded.
    onStartMeasure: PropTypes.func,                     // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                      // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,
    forceStrengDetails: PropTypes.bool,                 // when true, strengdetails should be shown. it wil alwasy trigger the onStrengDetailsForced so the caller can reset his request.
    onStrengDetailsForced: PropTypes.func,
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func, 
    onTerminated: PropTypes.func,                       // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
    initLoaded: PropTypes.bool,                     // an observation can only be rendered when the tractor engine is loaded (buttons for sonar and lidar)
};

export default withTranslation()(withStyles(styles)(Streng));