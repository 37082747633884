import { configService } from "../../services/config_service";
import { API_URL } from "./server_config_service";
import i18n from "i18next";
import { getHeaders } from './fetch_services';
import { dialogService } from "../../services/dialog_service";

class UserService {
    constructor() {
        this.user = null;
    }

    Editor = "inspector";
    Viewer = "viewer";

    getCurrentUser() {
        if (!this.user) {
            this.user = configService.get('user');
            //FIX: temp solution for testing
            //this.user = {id: "1", firstName: 'tester', lastName: "last", email: "test@tester.com", role: "inspector", companyId: 1, avatar: ""};
        }
        return this.user;
    }

    /**
     * get the user's details from the cloud with the specified id.
     * can be used to get newest rec for current user.
     */
    async getUser(id) {
        return new Promise((resolve, reject) => {
            fetch(`${API_URL}/users/${id}`, {method: 'GET', mode: 'cors', headers: getHeaders()})
            .then((result) => {
                if (result.ok === true) {
                    result.json().then((data) =>{
                        if (result.status === 200) {
                            resolve(data);
                        }
                        else {
                            //todo: add error message
                            console.log(data);
                            reject(i18n.t("invalid response from server"));
                        }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => reject(error));
        });
    }

    /**
     * gets all the users related to the same company as the current user.
     */
    async getUsers() {
        return new Promise((resolve, reject) => {
            const curUser = this.getCurrentUser();
            if (!curUser.companyId) {
                reject("not_linked_company");
                return;
            }
            fetch(`${API_URL}/company/${curUser.companyId}/users`, {method: 'GET', mode: 'cors', headers: getHeaders()})
            .then((result) => {
                if (result.ok === true) {
                    result.json().then((data) =>{
                        if (result.status === 200) {
                            resolve(data);
                        }
                        else {
                            //todo: add error message
                            console.log(data);
                            reject(i18n.t("invalid response from server"));
                        }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => reject(error));
        });
    }

    async tryAddUserToCompany(user) {
        const curUser = this.getCurrentUser();
        if (!curUser.companyId) {
            throw new Error("not_linked_company");
        }
        const result = await fetch(`${API_URL}/company/${curUser.companyId}/users`, {method: 'POST', mode: 'cors', body: JSON.stringify(user), headers: getHeaders()})
        if (result.status === 200) {
            const data = await result.json();
            return data;
        }
        throw new Error(i18n.t("invalid response from server"));
    }

    async getCompany() {
        const curUser = this.getCurrentUser();
        const companyId = curUser.companyId ? curUser.companyId : curUser.invitedTo;
        if (!companyId) {
            throw new Error("not_linked_company");
        }
        const result = await fetch(`${API_URL}/company/${companyId}`, {method: 'GET', mode: 'cors', headers: getHeaders()})
        if (result.status === 200) {
            return await result.json();
        }
        else {
            const err = await result.json();
            throw new Error(err.message);
        }
        //throw new Error(i18n.t("invalid response from server"));
    }

    async deleteUser(id) {
        
        const result = await fetch(`${API_URL}/users/${id}`, {method: 'DELETE', mode: 'cors', headers: getHeaders()})
        if (result.status === 200) {
            return await result.json();
        }
        else {
            const err = await result.json();
            throw new Error(err.message);
        }
    }

    /**
     * updates details of the current user's account.
     * @param {object} user the user data that needs to be changed
     * @param {string} id the id to save the data under
     */
    async saveUser(user, id) {
        return new Promise((resolve, reject) => {
            if (!id) { reject("no id"); }
            fetch(`${API_URL}/users/${id}`, {method: 'PUT', mode: 'cors', body: JSON.stringify(user), headers: getHeaders()})
            .then((result) => {
                if (result.ok === true) {
                    result.json().then((data) =>{
                        if (result.status === 200) {
                            resolve(data);
                        }
                        else {
                            //todo: add error message
                            console.log(data);
                            reject(i18n.t("invalid response from server"));
                        }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => reject(error));
        });
    }

    async changePwd(userId, newPwd) {
        return new Promise((resolve, reject) => {
            if (!userId) { reject("no id"); }
            const data = {password: newPwd};
            fetch(`${API_URL}/users/${userId}/pwd`, {method: 'PUT', mode: 'cors', body: JSON.stringify(data), headers: getHeaders()})
            .then((result) => {
                if (result.ok === true) {
                    result.json().then((data) =>{
                        if (result.status === 200) {
                            resolve(data);
                        }
                        else {
                            //todo: add error message
                            console.log(data);
                            reject(data ?? result.statusText ?? i18n.t("invalid response from server"));
                        }
                    });
                }
                else { 
                    result.json().then((error) => {
                        reject(error);
                    }).catch(() => {
                        reject(i18n.t("invalid response from server"));
                    });
                }
            })
            .catch((error) => reject(error));
        });
    }

    setCurrentUser(user) {
        this.user = user;
        configService.set('user', user);
    }

    clearCurrentUser() {
        configService.clear('user');
        this.user = null;
    }

    /**
     * checks if the user was invited to join a company, and if so resolves the question.
     * @param {object} user user object
     */
    async verifyUserInvitation(user) {
        if (user && user.invitedTo && user.id) {
            user = {...user};                                               // make a copy of user, 
            const company = await this.getCompany();
            return new Promise((resolve, reject) => {
                dialogService.askYesNo(i18n.t("Invitation"), i18n.t("Invitation"), i18n.t("user_invited_company", {company: company.name}), (result) => {
                    if (result.response === 0) {
                        user.companyId = user.invitedTo;
                        //dont reset invitedTo, tis is done on server and is needed to let server know this update needs to be done
                    }
                    else {
                        user.invitedTo = null;                          // reset when request rejected, so we dont try again.
                    }
                    this.saveUser(user, user.id).then(() => {           // always update, if the user rejected the request, we remove the request itself
                        delete user.invitedTo;
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                });
            });
        }
    }
}

export const userService = new UserService();