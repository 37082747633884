/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons:
import Video from 'mdi-material-ui/Video';
import OrbitVariant from 'mdi-material-ui/CameraFlip';
import ArrangeBringForward from 'mdi-material-ui/ArrangeBringForward';

import React from 'react';
import '../../../App.css';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

import Divider from '@material-ui/core/Divider';




const styles = (theme) => ({
    
});


class CameraSelect extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
     
        
    }


    render() {
        const { t } = this.props;
        const config = this.props.channels;
        let front = null;
        let back = null;
        let fisheye = null;

        if (config) {
            front = config.find((el) => el.cameraType === 'front');
            back = config.find((el) => el.cameraType === 'back');
            fisheye = config.find((el) => el.cameraType === 'fisheye');
        }

        let count = 0;
        if (front)  count++;
        if (back)  count++;
        if (fisheye)  count++;

        if (count > 1) {                            // only draw buttons if there are more than 1 
            return(
                <React.Fragment>
                    <ToggleButtonGroup size="small" value={this.props.selectedChannel} exclusive onChange={this.props.onSetChannel}>
                        {(front) && 
                            <Tooltip title={t("select_camera")}>
                                <ToggleButton key={1} value={front.channel} 
                                    selected={front.channel===this.props.selectedChannel} 
                                >
                                    <Video style={{transform: "scaleX(-1)"}}/>
                                </ToggleButton>
                            </Tooltip>
                        }
                        {(back) && 
                            <Tooltip title={t("select_camera")}>
                                <ToggleButton key={2} value={back.channel}  selected={back.channel===this.props.selectedChannel} >
                                    <Video />
                                </ToggleButton>
                            </Tooltip>
                        }
                        {(fisheye) && 
                            <Tooltip title={t("select_camera")}>
                                <ToggleButton key={3} value={fisheye.channel}  selected={fisheye.channel===this.props.selectedChannel} >
                                    <OrbitVariant />
                                </ToggleButton>
                            </Tooltip>
                        }
                        {(this.props.allowInview) && 
                            <Tooltip title={t("select_camera")}>
                                <ToggleButton key={3} value={"_inview_"}  selected={"_inview_"===this.props.selectedChannel} >
                                    <ArrangeBringForward />
                                </ToggleButton>
                            </Tooltip>
                        }
                    </ToggleButtonGroup>
                    <Divider orientation="vertical" style={{width: '4px', height: 'auto'}} />
                </React.Fragment>
            );
        }
        return null;
    }
}

CameraSelect.propTypes = {
    selectedChannel: PropTypes.string,
    onSetChannel: PropTypes.func,
    channels: PropTypes.array,                              // list of available channels definitions
    allowInview: PropTypes.bool.isRequired
};

export default withTranslation()(withStyles(styles)(CameraSelect));