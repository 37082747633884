
import logoImg from '../images/medium_logo.png';

import topImg from '../images/dashboard/top.svg';
import frontImg from '../images/dashboard/front.svg';
import sideImg from '../images/dashboard/side.svg';
import frontCamImg from '../images/dashboard/front_cam.svg';
import topCamImg from '../images/dashboard/top_cam.svg';
import sideCamImg from '../images/dashboard/side_cam.svg';

/**
 * loads image and returns base 64 of image
 * is done cause image-to-base don't run on safari
 * @param {string} path filepath
 */
export function loadImage(path) {
    return Promise.reject("not supported");
}

export function getLogoImg() {
    return logoImg;
}


/**
 * get the 
 * @param {string} name name of the image
 */
export function getDashboardImg(name) {
    switch (name) {
        case "top.svg": return topImg; break;
        case "front.svg": return frontImg; break;
        case "side.svg": return sideImg; break;
        case "front_cam.svg": return frontCamImg; break;
        case "top_cam.svg": return topCamImg; break;
        case "side_cam.svg": return sideCamImg; break;
        default:
            break;
    }
    throw new Error("cant find image: " + name);
}