/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
*/

// icons
import Flare from 'mdi-material-ui/Flare';

import React from 'react';
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import  { StrengInfoNumberField }  from './streng_info_numberfield';
import { engineService } from '../../../../components/video_engine/engine_service';
import { dialogService } from '../../../../services/dialog_service';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { errExtractor } from '../../../services/error_extractor';
import { lidarService } from '../../instrumentation/sonarLidar/lidar_service';


const styles = (theme) => ({
    textField: {
        width: '100%'
    },
    row : {
        flexDirection: 'row', 
        display:'flex', 
        alignItems: 'center', 
        justifyContent:'flex-end'
    }
});


class StrengInfoNumberAutoLidar extends StrengInfoNumberField {
    constructor(props) {
        super(props);

    }

    render() {
        const numberField = super.render();
        const rightBtn = this.renderRightButton();

        if (rightBtn) {
            return (
                <div className={this.props.classes.row}>
                    {numberField}
                    {rightBtn}
                </div>
            );
        }
        else {
            return numberField;
        }
    }

    renderRightButton() {
        const { t } = this.props;
        if (!this.props.readOnly && lidarService && lidarService.provider) {
            return (
                <Tooltip title={t("auto_fill_from_lidar")}>
                    <span>
                        <IconButton 
                            aria-label="lidar-data" 
                            id="autoFillFromLidar"
                            size="small" 
                            onClick={this.handleUpdateFromLidar}>
                            <Flare />
                        </IconButton>
                    </span>
                </Tooltip>
            );
        }
        return null;
    }
    
    handleUpdateFromLidar = () => {
        try {
            const dimensions = lidarService.getDimensions();
            if (dimensions) {
                const val = this.props.field === 'Width' ? dimensions.width : dimensions.height;
                this.handleValueChanged(val);
            }
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

}

export default withTranslation()(withStyles(styles)(StrengInfoNumberAutoLidar));