/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

/**
 * can load lidar data and play it, using the onData callback. Playback must be triggered by the tractor player.
 */
export class PlayerBaseService  {
    
    constructor() {
        this.data = [];                                                             // all the data for the currently loaded project
        this.currentTime = 0;                                                       // updated by the document, when recording or pla
        this.currentIdx = 0;
        this.onData = null;                                                         // callback towards the generic LidarService
    }

    /**
     * called when the document is put in edit mode (upon create). Make certain
     * that all data is re-init.
     */
    reset() {
        this.data = [];
        this.currentTime = 0; 
        this.currentIdx = 0;
    }

    setCurrentTimestamp(value) {
        let forced = false;
        if(value < this.currentTime) {
            this.currentIdx = 0;          // going back in the dataset, so start search from beginning (easiest)
            if (value == 0) forced = true;                  // need to force playing the first item, cause there is no change in index nr, so otherwise no data would be played -> when playing ends, no going back to start automatically
        }
        this.currentTime = value;
        this.playCurrent(forced);
    }

    getLast() {
        return this.data[this.currentIdx];
    }

    /**
     * find position in list for current time stamp and call callback with new data.
     * @param {bool} forced when true, the event will always be triggered, when false, only when current pos changed.
     */
     playCurrent(forced = false) {
        try {
            if (this.data) {
                const prevIdx = this.currentIdx;
                while(this.currentIdx < this.data.length - 1) {
                    let next = this.data[this.currentIdx+1];
                    if (next.timestamp >= this.currentTime) break;
                    this.currentIdx++;
                }
                if((prevIdx != this.currentIdx || forced) && this.currentIdx < this.data.length) {
                    const curRec = this.data[this.currentIdx];
                    if (curRec && this.onData) {
                        this.playRec(curRec);
                    }
                }
            }
        }
        catch(error) {
            console.log(error);
        }
    }

}