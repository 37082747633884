
/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


/* global google */                         // need this for google

// icons

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sleep } from '../../shared_components/services/sleep';
import { dialogService } from '../../services/dialog_service';
import { errExtractor } from '../../shared_components/services/error_extractor';
import Typography from '@material-ui/core/Typography';
import { calcCrow } from '../../shared_components/services/geo_service';
import {AbsoluteOrientationSensor} from 'motion-sensors-polyfill'


const SAMPLE_DURATION = 10;

const styles = (theme) => ({
    root: {

    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
});


/**
 * UI element for ar.
 */
class AugmentedReality extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            collecting: null,                           // can also be 0, 1 -> indicates start or direction step for which the system is currently collecting
            collectionCountDown: SAMPLE_DURATION,       // when collecting, this counter goes down so the user know how long the collection takes
            useCompass: true,                           // so we can switch between copmass and 2 gps points
        }
        this.coordinates = [[], []];                     // a list of 2 coordinate lists: for start and stop
        this.data = null;                               // berekende data die naar iframe moet
        this.heading = null;                            // for calculating the points using the heading and distance
    }

    render() {
        const { t } = this.props;
        return (
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
                <Step >
                    <StepLabel>{t("Intro")}</StepLabel>
                    {this.renderStepIntro()}
                </Step>
                <Step >
                    <StepLabel>{t("Starting point")}</StepLabel>
                    {this.renderStepStart()}
                </Step>
                {/* <Step >
                    <StepLabel>{t("Direction")}</StepLabel>
                    {this.renderStepDir()}
                </Step> */}

                <Step >
                    <StepLabel>{t("Klaar")}</StepLabel>
                    {this.renderStepReady()}
                </Step>
            </Stepper>
        );
    }

    renderStepIntro() {
        const { t, classes } = this.props;
        return (
            <StepContent>
                <Typography>{t("ar_step_intro")}</Typography>
                <div className={classes.actionsContainer}>
                    {this.renderStepButtons()}
                </div>
            </StepContent>
        );
    }

    renderStepStart() {
        const { t, classes } = this.props;
        return (
            <StepContent>
                <Typography>{t("ar_step_m_start")}</Typography>
                <div className={classes.actionsContainer}>
                    {this.renderMeasureButton(0)}
                    {this.renderStepButtons()}
                </div>
            </StepContent>
        );
    }

    renderStepDir() {
        const { t, classes } = this.props;
        return (
            <StepContent>
                <Typography>{t("ar_step_m_dir")}</Typography>
                <div className={classes.actionsContainer}>
                    {this.renderMeasureButton(1)}
                    {this.renderStepButtons()}
                </div>
            </StepContent>
        );
    }


    renderMeasureButton(idx) {
        const { t, classes } = this.props;
        return (
            <Button
                color="primary"
                variant="outlined"
                onClick={this.measure(idx)}
                disabled={this.state.collecting !== null}
                className={classes.button}
            >
                {(this.state.collecting !== null) &&
                    <div style={{ marginRight: '8px', position: "relative", display: "inline-flex" }}>
                        <CircularProgress size={20} />
                        <div style={{ top: 0, left: 0, bottom: 0, right: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant="caption" component="div" color="textSecondary">{this.state.collectionCountDown}</Typography>
                        </div>
                    </div>
                }
                {t("Measure")}
            </Button>
        );
    }

    renderStepReady() {
        const { t, classes } = this.props;
        return (
            <StepContent>
                <Typography>{t("ar_step_ready")}</Typography>
                <div className={classes.actionsContainer}>
                    {this.renderStepButtons()}
                </div>
            </StepContent>
        );
    }

    renderStepButtons() {
        const { t, classes } = this.props;
        let nextDisabled = (this.state.activeStep === 3 && (this.coordinates[0].length === 0 || this.coordinates[1].length === 0));
        if (!nextDisabled) {
            nextDisabled = this.state.collecting !== null;              // when collecting data, don't go to next step
        }
        return (
            <div>
                <Button
                    disabled={this.state.activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.button}
                    disabled={nextDisabled}
                >
                    {t("Back")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    className={classes.button}
                    disabled={nextDisabled}
                >
                    {this.state.activeStep === 3 ? t('Show') : t('Next')}
                </Button>
            </div>
        );
    }

    async requestMeasurePermissions() {
        if (navigator.permissions) {
            // https://w3c.github.io/orientation-sensor/#model
            const perms = [navigator.permissions.query({ name: "accelerometer" }),
                            navigator.permissions.query({ name: "magnetometer" }),
                            navigator.permissions.query({ name: "gyroscope" })
                        ];
            const results = await Promise.all(perms);
            if (!results.every(result => result.state === "granted")) {
                throw new Error("Permission to use sensor was denied.");
            }
        } else {
            console.log("No Permissions API, still try to start app.");
        }
    }

    /**
     * starts collecting gsp measurements for the specified step
     * @param {number} idx 0 or 1 for the step (start, direction)
     */
    measure = (idx) => async () => {
        this.setState({ collecting: 0 });
        try {
            await this.requestMeasurePermissions();
            await Promise.all([this.measureCurrentLocation(0), this.measureHeading()]);
            //await this.measureCurrentLocation(idx);
        }
        catch (error) {
            const { t } = this.props;
            if (this.coordinates[0].length === 0 || error != "timeout") {
                dialogService.error(t("Gps"), errExtractor.get(error));
            }
        }
        this.setState({ collecting: null });
    }

    async measureCurrentLocation(idx) {
        let startTime = 0;                                                              // only get start Time after we got the first coordinate, cause otherwise we don't know how long the first measurement took and how long the user waited before aproving the gps
        let newTime = 0;
        while (this.coordinates[idx].length < 100 && (newTime - startTime) < SAMPLE_DURATION * 1000) {      // measure for 20 seconds, but do
            if (startTime === 0) {
                startTime = Date.now();
                newTime = Date.now();
            }
            let gps = await this.getGPS();
            this.coordinates[idx].push(gps);
            let nextTime = Date.now();
            await sleep(SAMPLE_DURATION * 10);                                                           // give at least 0.3 seconds before measuring again, othrewise we can get timeouts easily
            const newCountDown = Math.trunc(SAMPLE_DURATION - ((newTime - startTime) / 1000));       // let the user know how much time is left.
            if (newCountDown != this.state.collectionCountDown) {
                this.setState({ collectionCountDown: newCountDown });
            }
            newTime = nextTime;
        }
    }

    async measureHeading() {
        let result = [];

        const options = { frequency: 60, referenceFrame: 'device' };
        const sensor = new AbsoluteOrientationSensor(options);
        sensor.addEventListener('reading', e => {
            var q = e.target.quaternion;
            let alpha = Math.atan2(2*q[0]*q[1] + 2*q[2]*q[3], 1 - 2*q[1]*q[1] - 2*q[2]*q[2])*(180/Math.PI);
            if(alpha < 0) alpha = 360+ alpha;
            result.push(alpha);
        });
        sensor.start();
        await sleep(SAMPLE_DURATION * 1000);                            // let it sample some
        sensor.stop();
        let sum = result.reduce((sum, a) => { return (sum + a) }, 0);
        this.heading = 360 -  (sum / result.length);                    // need to flip it, otherwise it goes in the wrong direction
        console.log('found compass', this.heading);
    }

    async getGPS() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                const options = { enableHighAccuracy: true, maximumAge: 0, timeout: SAMPLE_DURATION * 1000 };
                navigator.geolocation.getCurrentPosition(
                    (pos) => {
                        resolve(pos.coords);
                    }, (err) => {
                        reject(err);
                    }, options);
            }
            else {
                reject("no gps device available");
            }
        });
    }

    handleNext = () => {
        const stepCount = this.state.useCompass ? 2 : 3;
        if (this.state.activeStep < stepCount) {
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
        else {
            this.calculateData();
            //this.testCalc();
            this.props.history.push('/ar', {places: this.data});
        }
    }

    handleBack = () => {
        if (this.state.activeStep > 0) {
            this.setState({ activeStep: this.state.activeStep - 1 });
        }
    }

    calculateData() {
        try {
            let result = this.calculateAvgCoordinates();
            if (result.length === 2) {
                this.calculateAddStrengData(result);
            }
            else if (result.length == 1 && this.heading) {
                this.addStrengData(result);
            }
            this.data = result;
        }
        catch (error) {
            dialogService.error("Augmented reality", errExtractor.get(error));
        }
    }

    calculateAvgCoordinates() {
        let result = [];
        for (let elements of this.coordinates) {
            elements.splice(0, elements.length / 2);                        // remove firs half of coordinates, those are the lease accurate usually
            if (elements.length) {
                let sum = elements.reduce((sum, a) => {
                    return ({ lat: sum.lat + a.latitude, lng: sum.lng + a.longitude });
                }, { lat: 0, lng: 0 });
                let avg = { lat: sum.lat / elements.length, lng: sum.lng / elements.length };
                result.push(
                    {
                        name: 'start',
                        color: 'blue',
                        location: avg,
                    }
                )
            }
            else if (!this.heading) {
                throw new Error("no gps data");
            }
        }
        return result;
    }

    testCalc() {
        /*
        let testPoint = new google.maps.LatLng(51.002385100000026, 4.632476999999997);
        let resultPoint = google.maps.geometry.spherical.computeOffset(testPoint, 50, 44.78339601860219);
        let res = {lat: resultPoint.lat(), lng: resultPoint.lng()};
        console.log(res);


        resultPoint = google.maps.geometry.spherical.computeOffset(testPoint, 50, 221.50839316640798);
        res = {lat: resultPoint.lat(), lng: resultPoint.lng()};
        console.log(res);
        */
    }

    /**
     * uses the direction and distance to calcualte the other points. this is done with google api, it has this calculation
     */
    addStrengData(result) {
        let color = this.props.showInventory ? 'green' : 'red';
        const list = this.props.showInventory ? this.props.data.streng.inventory : this.props.data.streng.errors;
        const startPoint = new google.maps.LatLng(result[0].location.lat, result[0].location.lng);
        let lastDistance = 0;
        const totalInsplength = this.props.data.streng.actualInspectionLength;
        for (const rec of list) {
            const obs = rec.obs;
            if (obs.longitude != lastDistance) {                        // only show 1 item at the same pos
                const loc = google.maps.geometry.spherical.computeOffset(startPoint, obs.longitude, this.heading);
                if (list[list.length-1] === rec && obs.longitude >= totalInsplength) {       // last one
                    color = 'yellow';
                }
                result.push(
                    {
                        name: obs.values.join('-'),
                        color: color,
                        location: { lat: loc.lat(), lng: loc.lng() },
                    }
                );
            }
            lastDistance = obs.longitude;
        }
        if (color !== 'yellow') {                         // didn't add the last item in the list.
            const loc = google.maps.geometry.spherical.computeOffset(startPoint, totalInsplength, this.heading);
            result.push({name: 'end', color: 'yellow', location: { lat: loc.lat(), lng: loc.lng() } });
        }
    }

    /**
     * uses distance between 2 points to get direction and extrapolate other points along the same line
     */
    calculateAddStrengData(result) {
        const distance = calcCrow(result[0].location.lat, result[0].location.lng, result[1].location.lat, result[1].location.lng);
        if (distance === 0) {
            throw new Error("not enough distance between the 2 points");
        }
        const startPoint = new google.maps.LatLng(result[0].location.lat, result[0].location.lng);
        const endPoint = new google.maps.LatLng(result[1].location.lat, result[1].location.lng);
        this.heading = google.maps.geometry.spherical.computeHeading(startPoint, endPoint);
        this.addStrengData(result);
    }
}

AugmentedReality.propTypes = {
    data: PropTypes.array,                    // data to show
    showInventory: PropTypes.bool,       // deterimens what is shown
};

export default withTranslation()(withStyles(styles)(withRouter(AugmentedReality)));