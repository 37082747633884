/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from "../../../../services/dialog_service";
import { activeProjectService } from "../../../../services/active_project_service";
import { storageService } from "../../../../services/storage_service";
import { PlayerBaseService } from "./player_base_service";
import { readDegree16BE, readTemp16LE } from "../../../services/buffer_helpers";
import { configService } from "../../../../services/config_service";

if (configService.isCloud) {                                                                  // so we can use Buffer in the browser
    const Buffer = require('buffer/').Buffer;
}

/**
 * can load sonar data and play it, using the onData callback. Playback must be triggered by the tractor player.
 */
export class SonarPlayerBaseService extends PlayerBaseService  {
    
    constructor() {
        super();

        this.channels = 0;                                              // nr of channels (directions) supported by the sonar device.
        this.valuesPerChannel = 0;                                      // nr of values in a single channel. needed to determine the distance for a single value. When 0, it is variable and determined by the channel itself
        this.AngleAdjust = 0;                                           // adjustment applied to the yaw (so these are the degrees that are added to the angle, the degrees that the sonar image is rotated)
        this.baseYaw = 0;                                               // to turn absolute values into relative agains this base
    }

    /**
     * loads the data from the cloud, when available.
     * @param {object} streng the streng to load the data for
     * @param {bool} reverse if reverse data is requested
     */
    internalLoad(streng, folder = null) {
        streng = streng ?? activeProjectService.activeStreng;
        const url = streng.sonarDataUrl;
        return (url) ? storageService.readBinaryContent(url.bucket, url.name) : Promise.resolve("");
    }

    

    /**
     * loads sonar data for the specified streng
     * returns the loaded data and also stores it in this.data.
     * This way, it can be used to rapidly load data async (for reporting) but also keep a cash of the last loaded data (for viewing)
     * @param {object} streng the streng to load the data for
     */
    load(streng=null, folder = null) {
        return new Promise((resolve, reject) => {
            this.data = [];                                                                     // before doing anythin, set the data to empty cause we are loading a new set and something could go wrong or the new data could be empty
            try {
                this.internalLoad(streng, folder).then((data) => {
                    const parsed = this.parseData(data);
                    this.data = parsed;
                    resolve(parsed);
                }).catch((error) => {
                    dialogService.error(i18n.t("Laden"), i18n.t("doc_load_error", {error: errExtractor.get(error)}));
                    resolve(null);
                });
            }
            catch(error) {
                dialogService.error(i18n.t("Laden"), i18n.t("doc_load_error", {error: errExtractor.get(error)}));
                resolve(null);
            }
        });
    }

    parseData(data) {
        let results = [];
        const buf = Buffer.from(data);                      // turn into buffer so it is easier to read

        let pos = 0;
        const errMsg = "Invalid sonar header, probably not a sonar file or corrupted";
        if (buf[pos++]!== 's'.charCodeAt(0)) throw new Error(i18n.t(errMsg));
        if (buf[pos++] !== 'o'.charCodeAt(0)) throw new Error(i18n.t(errMsg));
        if (buf[pos++] !== 'n'.charCodeAt(0)) throw new Error(i18n.t(errMsg));
        if (buf[pos++] !== 'a'.charCodeAt(0)) throw new Error(i18n.t(errMsg));
        if (buf[pos++] !== 'r'.charCodeAt(0)) throw new Error(i18n.t(errMsg));
        if (buf[pos++] !== 1) throw new Error(i18n.t(errMsg));
        if (buf[pos++] !== 1) throw new Error(i18n.t(errMsg));

        this.channels = buf.readUInt32LE(pos);
        this.valuesPerChannel = buf.readUInt32LE(pos+4);
        const format = buf.readUInt8(pos+8);
        pos+=9;
        
        if (format === 1) {
            this.baseYaw = buf.readInt16LE(pos);
            pos+= 2;
            this.AngleAdjust = buf.readInt16LE(pos);
            pos+= 2;
            while(pos < buf.length) {
                let timestamp = buf.readDoubleLE(pos);
                pos += 8;
                const roll = readDegree16BE(buf, pos);
                const pitch = readDegree16BE(buf, pos+2);
                const yaw = readDegree16BE(buf, pos+4);
                const temp = readTemp16LE(buf, pos+6);
                //console.log("roll: ", roll, ", pitch: ", pitch, ", yaw: ", yaw-this.baseYaw, ", temp: ", temp);
                pos += 8;
                const range = buf.readUInt16LE(pos);
                pos += 2;
                if (buf.length <= pos) throw new Error("Corrupt file: unexpected end-of-file reached");
                let length = buf.readUInt32LE(pos);
                pos += 4;
                if (buf.length <= pos) throw new Error("Corrupt file: unexpected end-of-file reached");
                let data = buf.slice(pos, pos + length);
                results.push({timestamp: timestamp, data: data, roll: roll, pitch: pitch, yaw: yaw-this.baseYaw, temp: temp, range: range});
                pos += length;
            }
        }
        else {
            throw new Error("Unsupported sonar data format");
        }
        return results;
    }

    getLast() {
        const res = this.data[this.currentIdx];
        if (res) {
            return res;
        }
        return null;
    }

    playRec(rec) {
        this.onData(rec);
    }

    canSetPulse() {
        return false;
    }

    canSetGain() {
        return false;
    }
    
    canSetFrequency() {
        return false;
    }
}