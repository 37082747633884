import { API_URL } from '../shared_components/services/server_config_service';
import { getHeaders } from '../shared_components/services/fetch_services';
import i18n from "i18next";

const AVATAR_BUCKET = process.env.REACT_APP_DEBUG || process.env.REACT_APP_IS_STAGING ? 'dev_cami_avatars' : 'cami_avatars';
export const TEMP_ZIP_BUCKET = process.env.REACT_APP_DEBUG || process.env.REACT_APP_IS_STAGING ? 'dev_cami_temp_zip' : 'cami_temp_zip';

//provides access to the google cloud.
class StorageService {
    
    /**
     * gets a signed url (temporarily valid) for the specified file.
     * bucket-file  is returned by xxxCloudName()
     * @param {object} name name of the bucket & file: {bucket, name}
     */
    async getUrl(name) {
        return new Promise((resolve, reject) => {
            //for debug: remove until fetch
            /*resolve(
                "https://storage.googleapis.com/cami_video/test_test11_1.mp4?x-goog-signature=1101a5e6a5a9df3cf9cc086552f10919d2776506b9b1e60abd42800257bd0ed4bcf91b43dd8fb3eda91b8b8f715396cf8b07437eed55b8d1821c207d896bb8219ff09e7e5ffc964e73ef0e9f2233d2e034e52fb2b8349d95629260f6c4658e7ab4ec98a221d540e52d4afa2cd2518448017daa3f66697a80c109c0189bbd68e7ade5a0a93b3ed87eb904fbd625f7690b3444bd9c6ab298a56fd1fbe7936e0325a7c4673bcf08bfea70d10ea9eb389b93b5f0deb8616e386896eac2803b299fed5ef90f88e89cf849389cb80c9929c04e0fd41bb73832fd9bf861fc689d1e710d1cd676b309f18d47537dc12b2442dd5904e59e8ef5c685c9a73c82d90c7ea993&x-goog-algorithm=GOOG4-RSA-SHA256&x-goog-credential=cam-i-recorder%40camera-inspector.iam.gserviceaccount.com%2F20200729%2Feurope-west1%2Fstorage%2Fgoog4_request&x-goog-date=20200729T143450Z&x-goog-expires=86400&x-goog-signedheaders=host"
            );
            return;
            */

            fetch(`${API_URL}/storage/url/${name.bucket}/${name.name}`, {method: 'GET', mode: 'cors', headers: getHeaders()})
                .then((result) => {
                    if (result.ok === true) {
                        result.text().then((data) =>{
                            if (result.status === 200) {
                                resolve(data);
                            }
                            else {
                                //todo: add error message
                                reject(i18n.t("invalid response from server"));
                            }
                        }).catch((error) => {
                            reject(error);
                        });
                    }
                    else {
                        reject(i18n.t("invalid response from server"));
                    }
                })
                .catch((error) => reject(error))
            ;
        });
    }

    async tryGetUrl(name) {
        return new Promise((resolve, reject) => {
            fetch(`${API_URL}/storage/url/${name.bucket}/${name.name}`, {method: 'GET', mode: 'cors', headers: getHeaders()})
                .then((result) => {
                    if (result.ok === true) {
                        result.text().then((data) =>{
                            if (result.status === 200) {
                                resolve(data);
                            }
                            else {
                                resolve(null);
                            }
                        }).catch((error) => {
                            resolve(null);
                        });
                    }
                    else {
                        resolve(null);
                    }
                })
                .catch((error) => resolve(null))
            ;
        });
    }


    /**
     * gets the csv data for the specified bucket/file.
     * bucket-file  is returned by xxxCloudName()
     * @param {object} name name of the bucket & file: {bucket, name}
     */
    async getCsvData(name) {
        if (!name) {
            return Promise.resolve("");
        }
        return new Promise((resolve, reject) => {
           fetch(`${API_URL}/storage/csv/${name.bucket}/${name.name}`, {method: 'GET', mode: 'cors', headers: getHeaders()})
           .then((result) => {
               if (result.ok === true) {
                   result.text().then((data) =>{
                       if (result.status === 200) {
                           resolve(data);
                       }
                       else {
                           //todo: add error message
                           reject(i18n.t("invalid response from server"));
                       }
                   }).catch((error) => {
                       reject(error);
                   });
               }
               else {
                   reject(result.statusText ?? i18n.t("invalid response from server"));
               }
           })
           .catch((error) => reject(error));
        });
    }

    async readBinaryContent(bucket, name) {
        if (!bucket || !name) {
            return Promise.resolve("");
        }
        const url = await this.getUrl({bucket, name});
        const header = new Headers({
            Origin: window.location.origin,
        });
        let result = await fetch(url, {method: 'GET', mode: 'cors', headers: header});
        if (result.ok) {
            let data = await result.arrayBuffer();
            if (result.status === 200) {
                return data;
            }
        }
        throw new Error(i18n.t("invalid response from server"));
    }

    /**
     * get the url for the avatar image for the user with the specified name.
     * @param {string} name of the avatar (usually the id of the user)
     */
    async getAvatarUrl(name) {
        return this.getUrl({bucket: AVATAR_BUCKET, name: name});
    }

    getProjectZipFile(task, id) {
        let name = id;
        if (task.indexOf('images') > -1) {
            name = 'i_' + name;
        }
        if (task.indexOf('videos') > -1) {
            name = 'v_' + name;
        }
        if (task.indexOf('pdf') > -1) {
            name = 'p_' + name;
        }
        if (task.indexOf('xml') > -1) {
            name = 'x_' + name;
        }
        return name + '.zip';
    }
}

export const storageService = new StorageService();
