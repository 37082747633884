import { toRadian, PI } from '../../../services/cal';

/**
 * renders sonar data
 * @param {object} ctx canvas context
 * @param {number} width 
 * @param {number} height 
 * @param {number} scale nr of pixels in a tick
 * @param {number} tick nr of meters distance between tick circles
 * @param {array} colorDict 
 * @param {object} sonarService: object should have: lowCutoff, highCutoff, data, roll, range
 * @returns none
 */
export function drawSonar(ctx, width, height, scale, tick, colorDict, sonarService) {
    let row = 0;
    let col = 0;
    const data = sonarService.data;
    if (!data || width === 0 || height === 0) { return;}
    const channels = sonarService.channels;
    const valuesPerChannel = sonarService.valuesPerChannel;
    const distancePerValue = (sonarService.range/2) / valuesPerChannel;                            // sonarService.range/2 cause sonarService.range is the full size whie we are working with radians, which is half the size (circle), if we don't do this, things don't align and add up.
    const pixelsPerMm = scale / tick / 1000;
    const threshold = sonarService.lowCutoff;
    const highCutoff = sonarService.highCutoff;
    const valueRange = highCutoff - threshold;
    const highCutoffLeftOver = 255 - highCutoff;
    const centerWidth = Math.trunc(width/2);
    const centerHeight = Math.trunc(height/2);
    const tractorAngle = toRadian(sonarService.yaw) + toRadian(sonarService.AngleAdjust); 

    const imagedata = ctx.getImageData(0, 0, width, height);                        // required for rendering images: if we dont do this, it its a black background

    let angle1 = (0 / channels * (2*PI))  - tractorAngle;
    let angle2 = (1 / channels * (2*PI) )  - tractorAngle;
    let angle1Cos = Math.cos(angle1);
    let angle2Cos = Math.cos(angle2);
    let angle1Sin = Math.sin(angle1);
    let angle2Sin = Math.sin(angle2);

    ctx.lineWidth = 1;
    for (var i = 0, len = data.length; i < len; i++) {
        const val = data[i];
        const valAdjForLow = val - threshold;
        const valAdjForRange = valAdjForLow - highCutoffLeftOver;
        if (valAdjForLow > 0  && valAdjForLow <= valueRange) {
            let multiplier = distancePerValue * col * pixelsPerMm;                                    
            let multiplier2 = distancePerValue * (col + 1) * pixelsPerMm;  // take the next distance so we can draw a block

            let x1 = angle1Cos * multiplier;
            let x2 = angle2Cos * multiplier2; 
            let y1 = angle1Sin * multiplier;
            let y2 = angle2Sin * multiplier2;

            if (x2 < x1) {                                                          // make certain that vars are in correct order, otherwise our loop dont work
                const temp = x2;
                x2 = centerWidth + Math.ceil(x1);
                x1 = centerWidth + Math.floor(temp);
            }
            else {
                x1 = centerWidth + Math.floor(x1);
                x2 = centerWidth + Math.ceil(x2);
            }
            if (y2 < y1) {                                                          // make certain that vars are in correct order, otherwise our loop dont work
                const temp = y2;
                y2 = centerHeight + Math.ceil(y1);
                y1 = centerHeight + Math.floor(temp);
            }
            else {
                y1 = centerHeight + Math.floor(y1);
                y2 = centerHeight + Math.ceil(y2);
            }
            
            if ((x1 >= 0 && x1 < width && y1 >= 0 && y1 < height) && (x2 >= 0 && x2 < width && y2 >= 0 && y2 < height)){
                if (valAdjForRange > 0) {
                    let valAdjForLowHigh = Math.round(valAdjForRange / valueRange * 255) * 4;  // * 4 cause there are 4 bytes per color
                    if (valAdjForLowHigh > colorDict.length - 4) {                          // top of for max values
                        valAdjForLowHigh = colorDict.length - 4;
                    }

                    for(let xl=x1; xl<=x2; xl++) {
                        for(let yl=y1; yl<=y2; yl++) {
                            let pixelindex = (yl * width + xl) * 4;
                            imagedata.data[pixelindex] = colorDict[valAdjForLowHigh];
                            imagedata.data[pixelindex+1] = colorDict[valAdjForLowHigh+1];
                            imagedata.data[pixelindex+2] = colorDict[valAdjForLowHigh+2];
                            imagedata.data[pixelindex+3] = 0xFF;
                        }
                    }
                }
            }
        }
        col++;
        if (col >= valuesPerChannel) {
            row++;
            angle1 = angle2;                            // already did this calculation, so faster to copy it over
            //angle2 = ((row + 1) / channels * (2*PI)) + (PI/2);
            angle2 = ((row + 1) / channels * (2*PI)) - tractorAngle;

            angle1Cos = Math.cos(angle1);
            angle2Cos = Math.cos(angle2);
            angle1Sin = Math.sin(angle1);
            angle2Sin = Math.sin(angle2);
            col = 0;
        }
    }
    ctx.putImageData(imagedata, 0, 0);
}

/**
 * calculates the ideal ticksize, given the size of the strand
 * tick = nr of meters distance between tick circles
 * for reports at the moment
 * @param {number} size the total size (diameter) of the strand, expressed in mm
 */
export function getIdealTickSize(size) {
    size /= 2;                                              // /2 cause it's a circle and size gives the total size
    if (size < 500) {
        return 0.1;
    }
    else if (size < 2000) {
        return 0.5;
    }
    else if (size < 5500) {
        return 1;
    }
    else {
        return 2;
    }
}

/**
 * calculates the ideal scale, given the size of the window and the currently selected tick size
 * @param {number} size nr of pixels available 
 * @param {number} desiredMeters the nr of meters that should be visible across the diameter
 * @returns scale = nr of pixels between each tick (line), tick= nr of meters between each line
 */
export function getIdealScaleSize(size, desiredMeters) {
    const availablePixels = size / 2;                                               // diameter, so on both sides
    let scale, tick;
    if (desiredMeters <= 0.01) {
        tick = 0.005;
    }
    else if (desiredMeters < 0.05) {
        tick = 0.01;
    }
    else if (desiredMeters < 0.1) {
        tick = 0.02;
    }
    else if (desiredMeters < 0.4) {
        tick = 0.05;
    }
    else if (desiredMeters < 0.8) {
        tick = 0.1;
    }
    else if (desiredMeters < 1.4) {
        tick = 0.2;
    }
    else if (desiredMeters < 2) {
        tick = 0.3;
    }
    else if (desiredMeters < 3) {
        tick = 0.5;
    }
    else if (desiredMeters < 5) {
        tick = 1;
    }
    else if (desiredMeters < 7) {
        tick = 1.5;
    }
    else {
        tick = 2;
    }

    scale = availablePixels / desiredMeters  * tick;
    return {tick, scale}
}

/**
* draws the circles on the canvas that represent the ticks
* @param {object} ctx canvas.context
*/
export function drawTicks(ctx, width, height, scale, tick) {
   
   const centerWidth = (width/2);
   const centerHeight = (height/2);
   let maxSize = width > height ? width : height;
   let nrDecimals = (tick < 0.02) ? 3 : (tick <= 0.2) ? 2 : 1;
   let count = 1;
   ctx.lineWidth = 0.1;
   ctx.font = "8px Arial"
   ctx.fillStyle = "black";
   ctx.textAlign = "center";
   while (maxSize > 0) {
       ctx.beginPath();
       ctx.arc(width/2, height/2, scale * count, 0, PI * 2);
       ctx.stroke();

       const scaleCount = scale*count;
       const txt = (count*tick).toFixed(nrDecimals);                             // need to trunk in case of bad roundings for small tick sizes
       ctx.fillText(txt, centerWidth + scaleCount - 10, centerHeight - 5);
       ctx.fillText(txt, centerWidth - scaleCount + 10, centerHeight - 5);

       ctx.fillText(txt, centerWidth + 5, centerHeight - scaleCount + 10);
       ctx.fillText(txt, centerWidth + 5, centerHeight + scaleCount - 5);

       count++;
       maxSize -= scale;
   }
   ctx.beginPath();
   ctx.moveTo(0, height/2);
   ctx.lineTo(width, height/2);
   ctx.stroke();

   ctx.beginPath();
   ctx.moveTo(width/2, 0);
   ctx.lineTo(width/2, height);
   ctx.stroke();
}

/**
 * render lidar data
 * @param {object} ctx canvas context
 * @param {number} width 
 * @param {number} height 
 * @param {number} scale 
 * @param {number} tick 
 * @param {object} lidarService: object should have fields: data, angles
 * @returns none
 */
export function drawLidar(ctx, width, height, scale, tick, lidarService) {
    let inPath = false;
    ctx.lineWidth = 2;
    ctx.strokeStyle  = 'blue';
    let foundDot = false;                               // cant do stroke if no movements occured
    const pixelsPerMeter = scale / tick;
    if (!lidarService.data) return;

    const centerWidth = (width/2);
    const centerHeight = (height/2);
    for(let idx=0; idx < lidarService.data.length; idx++) {
        const value = lidarService.data[idx];
        if (value !== 0) {
            //const corner = 360 / sonarService.data.length * idx;
            const corner = lidarService.angles[idx];
            const multiplier = value * pixelsPerMeter;
            const x = centerWidth + (Math.cos(toRadian(corner)) * multiplier);
            const y =  centerHeight - (Math.sin(toRadian(corner)) * multiplier);     // height is reversed in the canvas
            if (!inPath) {
                if (!foundDot) {
                    ctx.beginPath();                        // only start a path if there is something to draw
                    foundDot = true;
                }
                inPath = true;
                ctx.moveTo(x, y);
            }
            else {
                ctx.lineTo(x, y);
            }
        }
        else {
            inPath = false;
        }
    }
    if (foundDot) {
        ctx.stroke();
    }
}

/**
 * render lidar data
 * @param {object} ctx canvas context
 * @param {number} width 
 * @param {number} height 
 * @param {number} scale 
 * @param {number} tick 
 * @param {object} lidarService: object should have fields: data, angles
 .* @param {list} angles 2 angles for the triangle that needs to be drawn
 * @returns none
 */
 export function drawLidarDistanceTriangle(ctx, width, height, scale, tick, lidarService, angles) {
    ctx.lineWidth = 1;
    ctx.strokeStyle  = 'red';
    const pixelsPerMeter = scale / tick;
    if (!lidarService.data) return;

    const centerWidth = (width/2);
    const centerHeight = (height/2);
    let curCorner = 0;                                  // current corner of the triangle that we are drawing

    ctx.beginPath();                        // only start a path if there is something to draw
    ctx.moveTo(centerWidth, centerHeight);
    for(let idx=0; idx < lidarService.data.length; idx++) {
        const corner = lidarService.angles[idx];
        const nextCorner = idx < lidarService.angles.length-1 ? lidarService.angles[idx+1] : lidarService.angles[0];
        if (corner <= angles[curCorner] && nextCorner > angles[curCorner]) {                                          // found something to draw
            const value = lidarService.data[idx];
            let x, y;
            if (value !== 0) {
                const multiplier = value * pixelsPerMeter;
                x = centerWidth + (Math.cos(toRadian(corner)) * multiplier);
                y =  centerHeight - (Math.sin(toRadian(corner)) * multiplier);     // height is reversed in the canvas
            }
            else {
                x = centerWidth;
                y = centerHeight;
            }
            ctx.lineTo(x, y);
            curCorner++;
            if (curCorner >= angles.length) {                                                   // a triangle only has 3 corners and we only get 2 angles max
                break;
            }
        }
       
    }
    ctx.lineTo(centerWidth, centerHeight);
    ctx.stroke();
}

/**
 * creates an ImageData Object that can be used as a mapping from sonar values to color
 */
export function buildValueColorConvertor(inGrayMode) {
    const canvas = document.createElement('canvas');                // the canvas to render the image on.
    canvas.height = 256;
    canvas.width = 1;
    const ctx = canvas.getContext("2d");
    const grd = ctx.createLinearGradient(0, 0, 1, canvas.height);             // Create gradient
    if (inGrayMode) {
        grd.addColorStop(0, "white");
        grd.addColorStop(1, "black");
    }
    else {
        grd.addColorStop(0, "white");
        grd.addColorStop(0.2, "DodgerBlue");
        grd.addColorStop(0.4, "LimeGreen");
        grd.addColorStop(0.6, "yellow");
        grd.addColorStop(0.8, "orange");
        grd.addColorStop(1, "red");
    }
    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, 1, 256);
    return ctx.getImageData(0, 0, 1, 256);
}

/**
 * renders a converter between byte values and colors.
 * @param {bool} inGrayMode render gray gradient when true
 * @param {array} colors list of colors to use
 * @param {array} colorStops same length as colors, position in gradient of each color
 * @param {arra} lowHigh min & max value (between 0 and 255)
 * @returns imageDAta that can be used as a map between byte values and colors
 */
export function buildLidarRolloutColorConverter(inGrayMode, colors, colorStops) {
    try {
        const range = 255;
        const canvas = document.createElement('canvas');                // the canvas to render the image on.
        canvas.height = range + 1;
        canvas.width = 1;
        const ctx = canvas.getContext("2d");
        const grd = ctx.createLinearGradient(0, 0, 1, canvas.height);             // Create gradient
        if (inGrayMode) {
            grd.addColorStop(0, "white");
            grd.addColorStop(1, "black");
        }
        else {
            for(let i=0; i < colors.length; i++) {
                const stop = colorStops[i] > 1 ? 1 : colorStops[i];             // in case something went wrong with the data and we get a size to big
                grd.addColorStop(stop, colors[i]);
            }
        }
        // Fill with gradient
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, 1, range + 1);
        return ctx.getImageData(0, 0, 1, range + 1);
    }
    catch(error) {
        console.log("internal error: ", error);
        return null;
    }
}

export function getLidarRolloutMinMax(data, start, end) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = -1;
    for(let counter = start; counter < end; counter++) {
        const rec = data[counter];
        for (let i=0; i<rec.values.length; i++) {
            const val = rec.values[i];
            min = (val < min) ? val : min;
            max = (val > max) ? val : max;
        }
    }
    return [min, max];
}


/**
 * 
 * @param {*} ctx 
 * @param {*} width 
 * @param {*} data 
 * @param {*} startIdx index in the data list to start looking for the startDistance
 * @param {number} startDistance distance at wich to start rendering
 * @param {*} endDistance last distance that needs to be rendered
 * @param {*} distancePerPixel nr of millimeters that 1 pixel represents
 * @param {*} colorDict 
 * @param {*} filterRange 
 */
export function drawLidarRollout(ctx, width, data, startIdx, startDistance, endDistance, distancePerPixel, colorDict, filterRange) {
    const height = 360;                                                             // there are 360 degrees in a circle. The lidar data always describes a full circle
    const imagedata = ctx.getImageData(0, 0, width, height);                        // required for rendering images: if we dont do this, it its a black background

    let prevDistance = 0;
    let accumFraction = 0;                                                          // not rendering sub-pixel, to keep distance of, we insert extra col when the fraction accumluator overflows to 1
    let x = 0;                                                                      // current column to draw on
    const spread = filterRange[1] - filterRange[0];
    let foundStart = false;                                                         // the startDistance might not correspond with an exact index nr in the data list, so we might have to render part of the previous record first, which we need to find (ex: 1 record renders 20 cols, previous view ended at col 5, so 15 more to render in the next scroll)
    for(let counter = startIdx; counter < data.length; counter++) {
        let rec = data[counter];
        let newDistance = rec.distance;                                             // so we can modify the value at the end (if the last record spans multiple columns and the first ones still need to be drawn)
        if (newDistance < startDistance) {                                          // don't draw when standing still and only start at the specified distance, so we only render the visible part
            prevDistance = newDistance;
            continue;
        }  
        else if (newDistance > endDistance) {                                       // found the end, so stop
            if (prevDistance === endDistance || prevDistance > endDistance) {       // when not at the end distance, stil some lines to fill
                break;
            }
            else {
                newDistance = endDistance;  
            }
        }
        else if (!foundStart) {
            foundStart = true;
            if (newDistance > startDistance) {                                      // we need to render part of the previous record first cause it has some columns that weren't rendered in the previous view
                prevDistance = startDistance;
            }
        }
        let nrCols = ((newDistance - prevDistance) * 1000) / distancePerPixel ;     // nr of columns that this rec will use, depends on the distance traveled, relative to the nr of pixels per mm
        let integerPart = Math.round(nrCols);                                       // total nr of columns to fill with full color, always at least 1 cause we got the smallest value here
        let fraction = nrCols - integerPart;                                        
        accumFraction += fraction;
        nrCols = integerPart;
        if (accumFraction > 1) {
            nrCols++;
            accumFraction -= 1;
        }
        else if (accumFraction < -1) {
            nrCols--
            accumFraction += 1;
        }                                  

        for(let xl=x; xl<x+nrCols; xl++) {
            let angleAccum = 0;                                                     // keep track of the difference between pixel pos and actual value, so we can add a pixel whenever we drift too far
            let prevAngle = 0
            let prevY = 0;
            const length = rec.values.length;
            for(let yl=0; yl<length; yl++) {
                let value = rec.values[yl];
                if (value < filterRange[0]) {                                       // limit values to the allowed range
                    value = filterRange[0];
                }
                else if (value > filterRange[1]) {
                    value = filterRange[1];
                }
                value = (Math.trunc(((value - filterRange[0]) / spread) * 255) * 4);

                let nrRows;
                if (yl < length - 1) {
                    nrRows = (rec.angles[yl] - prevAngle);
                    integerPart = Math.round(nrRows);
                    fraction = nrRows - integerPart;
                    angleAccum += fraction;
                    nrRows = integerPart;
                    if (angleAccum > 1) {
                        nrRows++;
                        angleAccum -= 1;
                    }
                    else if (angleAccum < -1) {
                        nrRows--;
                        angleAccum += 1;
                    }
                }
                else {
                    nrRows = 360 - prevY;                                       // need to make certain there is nothing left and the whole thing is filled in
                }

                for(let row=0; row < nrRows; row++) {
                    let pixelindex = ((prevY+row) * width + xl) * 4;
                    imagedata.data[pixelindex] = colorDict[value];
                    imagedata.data[pixelindex+1] = colorDict[value+1];
                    imagedata.data[pixelindex+2] = colorDict[value+2];
                    imagedata.data[pixelindex+3] = 0xFF;
                }
                prevY += nrRows;
                prevAngle = rec.angles[yl];
            }
        }
        x += nrCols;
        prevDistance = newDistance;
    }
    ctx.putImageData(imagedata, 0, 0);
}
