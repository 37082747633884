/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { StrengInfoAutoComplete } from './streng_info_autocomplete_component';
import GpsAdorner from '../gps_addorner_component';


const styles = (theme) => ({
    textField: {
        width: '100%'
    }
});


class StrengInfoGPSAutoComplete extends StrengInfoAutoComplete {
    constructor(props) {
        super(props);

    }

  

    render() {
        const autoComplete = super.render();
        return (
            <GpsAdorner readOnly={this.props.readOnly} field="route" onLocation={this.handleLocationChanged} editor={autoComplete}/>
        );
    }

    
    handleLocationChanged = (value) => {
        this.setValue(value);
    }

}

export default withTranslation()(withStyles(styles)(StrengInfoGPSAutoComplete));