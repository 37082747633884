/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import ChevronRight from 'mdi-material-ui/ChevronRight';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';
import ChevronUp from 'mdi-material-ui/ChevronUp';
import ChevronDown from 'mdi-material-ui/ChevronDown';
import RotateRight from 'mdi-material-ui/RotateRight';
import Textbox from 'mdi-material-ui/FormTextbox';
import SwapHor from 'mdi-material-ui/SwapHorizontal';
import Bullseye from 'mdi-material-ui/Bullseye';
import Flare from 'mdi-material-ui/Flare';
import ChartLine from 'mdi-material-ui/ChartLine';
import DotsVertical from 'mdi-material-ui/DotsVertical';
import Magnify from 'mdi-material-ui/Magnify';
import FilterHdrOutlinedIcon from '@material-ui/icons/FilterHdrOutlined';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import Ruler from 'mdi-material-ui/Ruler';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import { DuoHorView, SonarView, StartEndLabel, StartEndLabelOutline, SeriesDouble, SeriesWide, TripleHorView, TopLargeDuoBottomHorView, LayoutMore, LidarRollout } from '../../../icons';
import Monitor from 'mdi-material-ui/Monitor';
import TuneVertical from 'mdi-material-ui/TuneVertical';
import Grid from 'mdi-material-ui/Grid';
import GridOff from 'mdi-material-ui/GridOff';
import Crop from 'mdi-material-ui/Crop';
import CropFree from 'mdi-material-ui/CropFree';
import FileExport from 'mdi-material-ui/FileExport';
import ArrowRightBoldCircleOutline from 'mdi-material-ui/ArrowRightBoldCircleOutline';
import ArrowLeftBoldCircleOutline from 'mdi-material-ui/ArrowLeftBoldCircleOutline';
import Bird from 'mdi-material-ui/Bird';
import AlertCircleOutline from 'mdi-material-ui/AlertCircleOutline';
import DatabaseSettings from 'mdi-material-ui/DatabaseSettings';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CircleHalfFull from 'mdi-material-ui/CircleHalfFull';
import VectorLine from 'mdi-material-ui/VectorLine';
import AngleAcute from 'mdi-material-ui/AngleAcute';
import CheckboxOutline from 'mdi-material-ui/CheckboxOutline';
import CheckboxBlankOutline from 'mdi-material-ui/CheckboxBlankOutline';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import TractorResetDlg from '../track/tractor_reset_dlg';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { engineService } from '../../../../components/video_engine/engine_service';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import SonarConfigPopup from '../sonarLidar/config_sonar_component';
import LidarRolloutConfigPopup from '../sonarLidar/config_lidar_roll_component';
import { configService } from '../../../../services/config_service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (

        <Tooltip open={open} enterTouchDelay={0} placement="left" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const styles = (theme) => ({
    root: {

    },
    icon: {
        padding: '0px'
    },
    zoomSlider: {
        width: "250px",
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(6),
    },
    zoomSliderVer: {
        height: "500px",
        marginLeft: theme.spacing(6),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    zoomSliderHorBox: {
        padding: theme.spacing(2)
    },
    bottomSeparator: {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.palette.action.disabled,
        marginBottom: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    topSeparator: {
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderColor: theme.palette.action.disabled,
        marginTop: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
    },

});

const zoomMarks = [
    { value: 0.01, label: '0.01m' },
    { value: 0.05, label: '' },
    { value: 0.1, label: '' },
    { value: 0.2, label: '0.20m' },
    { value: 0.5, label: '0.50m' },
    { value: 0.75, label: '0.75m' },
    { value: 1, label: '1.00m' },
    { value: 1.5, label: '1.50m' },
    { value: 2, label: '2.00m' },
    { value: 3, label: '3.00m' },
    { value: 4, label: '4.00m' },
    { value: 5, label: '5.00m' },
    { value: 6, label: '6.00m' },
    { value: 7, label: '7.00m' },
    { value: 8, label: '8.00m' }
];

const sampleDistanceMarks = [
    { value: 0.1, label: '0.1' },
    { value: 0.5, label: '0.5' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
]


const StyledToggleButton = withStyles((theme) => ({
    root: {
        padding: '0px',
        border: 'none',
        color: 'rgba(0, 0, 0, 0.54)',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButton);


/**
 * toolbar with all the elements to control settings of the robot/track/lidar/sonar
 */
class RobotToolbar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            resetDlgOpen: false,                                 // opened when asking user for new distance to send to tractor
            maxButtons: null,                                   // when set, we need to display the 'more' button with menu and hide all buttons that cant be made visible
            menuAnchorEl: null,                                 // the anchor for the more menu, when opened
            zoomAnchorEl: null,                                 // when the user wants to zoom, show popover
            displayModeAnchorEl: null,                          // for showing the popup to select the display mode of the sonar/lidar
            sampleDistanceAnchorEl: null,                       // when the user wants to change the sample size on the trackview, show popover
            sonarConfigAnchorEl: null,                          // for configuring the sonar, when available
            lidarRollConfigAnchorEl: null,                      // for configuring the lidar roll, when available
            sourcAnchorEl: null,                                // for selecting the data source of the robot section body
            lidarRollZoomAnchorEl: null,                        // for the zoom settings of the lidarRollout
            showDisformation: false,                            // when true, disformation calculation is performed on the lidar-cross section
            showMeasureDistanceCrossSection: false,             // when true, shows a distance selection/calculation on the lidar cross section
            showMeasureDistanceRollout: false,                  // when true, shows a distance selection/calculation on the lidar rollow
            showMeasureAngleRollout: false,                     // when true, shows an angle selection/calculation on the lidar rollow (draw 2 lines)
        }
        this.nextZoom = null;                                   // so we don't update the zoom value too often. this prevents the ui from freezing.
        this.zoomUpdateDelay = null;                            // timer for updating the zoom on a delay when changing the value too fast
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyboard, true);                          // check for keyboard shortcuts.
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyboard, true);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.allowedHeight !== this.props.allowedHeight) {
            this.handleResize();
        }
    }


    render() {
        const { t } = this.props;
        let dashboardToggle = (this.props.dashboardOpen) ? <ChevronLeft /> : <ChevronRight />;
        let visibleItems = this.renderButtons();
        let invisibleItems = this.renderMenuItems(visibleItems.length);
        return (
            <Box display="flex" flexDirection="column" marginRight="6px">       {/* warning: if you change marginRight,also update it in sonar.handleResize */}
                {visibleItems}
                {(invisibleItems.length > 0) &&
                    <Tooltip title={t("More")}>
                        <div>
                            <IconButton aria-label="more"
                                size="small"
                                onClick={this.handleOpenMenu}
                                className={this.props.classes.icon}
                            >
                                <DotsVertical />
                            </IconButton>
                            <Menu anchorEl={this.state.menuAnchorEl}
                                variant="menu"
                                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                open={Boolean(this.state.menuAnchorEl)}
                                onClose={this.handleCloseMenu}
                            >
                                {invisibleItems}
                            </Menu>
                        </div>
                    </Tooltip>
                }

                <div style={{ flex: 1 }} />
                {(this.props.showDashboardFunctions) &&
                    <Tooltip title={t("Show dashboard")}>
                        <div>
                            <IconButton aria-label="dashboard"
                                size="small"
                                onClick={this.props.onToggleDashboard}
                                className={this.props.classes.icon}
                            >
                                {dashboardToggle}
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                {this.state.resetDlgOpen &&
                    <TractorResetDlg onClose={this.handleSetDlgClose}
                        open={this.state.resetDlgOpen} />
                }
                {(this.props.selectedSource !== 'lidar-rollout') && this.renderZoomPopover()}
                {(this.props.selectedSource === 'inclination') && this.renderSampleDistancePopover()}
                {(this.props.selectedSource === 'sonar-cross-section') && this.renderSonarConfigPopover()}
                {(this.props.selectedSource === 'lidar-rollout') && this.renderLidarRolloutItems()}

                {(this.props.hasSonar || this.props.hasLidar) && this.renderSelectDisplayModePopover()}
            </Box>
        );
    }

    renderLidarRolloutItems() {
        return ([
            this.renderLidarRolloutZoomPopover(),
            this.renderLidarRolloutConfigPopover(),
        ]);
    }

    renderZoomPopover() {
        if (this.props.showTrack) {
            return null;
        }
        return (
            <Popover anchorEl={this.state.zoomAnchorEl}
                open={Boolean(this.state.zoomAnchorEl)}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                onClose={this.handleCloseZoom}
            >
                <div className={this.props.classes.zoomSliderVer}>
                    <Slider
                        orientation="vertical"
                        ValueLabelComponent={ValueLabelComponent}
                        value={this.props.zoom}
                        onChange={this.handleZoomChange}
                        valueLabelDisplay="on"
                        max={8}
                        min={0.01}
                        step={0.01}
                        marks={zoomMarks}
                    />
                </div>
            </Popover>
        );

    }

    renderLidarRolloutZoomPopover() {
        if (this.props.showTrack) {
            return null;
        }
        const { t } = this.props;
        return (
            <Popover anchorEl={this.state.lidarRollZoomAnchorEl}
                open={Boolean(this.state.lidarRollZoomAnchorEl)}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                onClose={this.handleCloseLidarRolloutZoom}
            >
                <div className={this.props.classes.zoomSliderHorBox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.lidarRollSnap}
                                onChange={this.props.onLidarRollSnapChanged}
                                name="snapToHeight"
                                color="primary"
                            />
                        }
                        label={t("Snap to height of the card")}
                    />
                    <div >
                        <Slider
                            orientation="horizontal"
                            valueLabelDisplay="auto"
                            value={this.props.zoom}
                            onChange={this.handleLidarRolloutZoomChanged}
                            max={800}
                            min={1}
                            step={1}
                            valueLabelFormat={(value) => `${value}%`}
                        />
                    </div>
                </div>
            </Popover>
        );

    }

    renderSelectDisplayModePopover() {
        const items = [];
        this.renderSelectDisplayModeItems(items);
        return (
            <Menu anchorEl={this.state.displayModeAnchorEl}
                key="sonarLidarDisplayMenu"
                variant="menu"
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                open={Boolean(this.state.displayModeAnchorEl)}
                onClose={this.handleSelectDisplayMode(null)}
            >
                {items}
            </Menu>
        );
    }

    renderSelectSourcePopover() {
        const items = [];
        this.renderSelectSourceItems(items);
        return (
            <Menu anchorEl={this.state.sourcAnchorEl}
                key="sonarLidarDisplayMenu"
                variant="menu"
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                open={Boolean(this.state.sourcAnchorEl)}
                onClose={this.handleSelectSource(null)}
            >
                {items}
            </Menu>
        );
    }

    renderSelectSourceItems(list) {
        const { t, selectedSource } = this.props;

        let items = [
            ['inclination', <ChartLine />],
        ]
        if (this.props.hasSonar) {
            items = [...items, ['sonar-cross-section', <Bullseye />]];
        }
        if (this.props.hasLidar) {
            items = [...items,
            ['lidar-cross-section', <Flare />],
            ['lidar-rollout', <LidarRollout />],
            ];
        }

        items.forEach((item) => {
            list.push(
                <MenuItem onClick={this.handleSelectSource(item[0])} key={list.length}
                    selected={selectedSource === item[0]}>
                    <ListItemIcon>
                        {item[1]}
                    </ListItemIcon>
                    <ListItemText primary={t(item[0])} />
                </MenuItem>
            );
        })
    }

    renderSelectDisplayModeItems(list) {
        const { t, selectedLayout } = this.props;

        list.push(
            <MenuItem onClick={this.handleSelectDisplayMode('single')} key={list.length}
                selected={selectedLayout === 'single'}>
                <ListItemIcon>
                    <SonarView />
                </ListItemIcon>
                <ListItemText primary={t("Single view")} />
            </MenuItem>
        );
        list.push(
            <MenuItem onClick={this.handleSelectDisplayMode('duoHor')} key={list.length}
                selected={selectedLayout === 'duoHor'}>
                <ListItemIcon>
                    <DuoHorView style={{ transform: "rotate(180deg)" }} />
                </ListItemIcon>
                <ListItemText primary={t("Dual horizontal")} />
            </MenuItem>
        );
        list.push(
            <MenuItem onClick={this.handleSelectDisplayMode('duoVer')} key={list.length}
                selected={selectedLayout === 'duoVer'}>
                <ListItemIcon>
                    <DuoHorView style={{ transform: "rotate(90deg)" }} />
                </ListItemIcon>
                <ListItemText primary={t("Dual vertical")} />
            </MenuItem>
        );
        list.push(
            <MenuItem onClick={this.handleSelectDisplayMode('tripleHor')} key={list.length}
                selected={selectedLayout === 'tripleHor'}>
                <ListItemIcon>
                    <TripleHorView />
                </ListItemIcon>
                <ListItemText primary={t("3 horizontal")} />
            </MenuItem>
        );
        list.push(
            <MenuItem onClick={this.handleSelectDisplayMode('combo')} key={list.length}
                selected={selectedLayout === 'combo'}>
                <ListItemIcon>
                    <TopLargeDuoBottomHorView />
                </ListItemIcon>
                <ListItemText primary={t("3 combined")} />
            </MenuItem>
        );
    }

    renderSampleDistancePopover() {
        return (
            <Popover anchorEl={this.state.sampleDistanceAnchorEl}
                open={Boolean(this.state.sampleDistanceAnchorEl)}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                onClose={this.handleCloseSampleDistance}>
                <Slider
                    className={this.props.classes.zoomSlider}
                    value={this.props.sampleDistance}
                    onChange={this.handleSampleDistanceChange}
                    valueLabelDisplay="on"
                    max={5}
                    min={0.10}
                    step={0.10}
                    marks={sampleDistanceMarks}
                />
            </Popover>
        );
    }

    renderSonarConfigPopover() {
        if (this.state.sonarConfigAnchorEl) {
            return (
                <SonarConfigPopup anchor={this.state.sonarConfigAnchorEl} onClose={this.handleCloseConfigureSonar} playMode={this.props.readOnly || !this.props.liveFeed} />
            );
        }
        else {
            return null;
        }
    }

    renderLidarRolloutConfigPopover() {
        if (this.state.lidarRollConfigAnchorEl) {
            return (
                <LidarRolloutConfigPopup anchor={this.state.lidarRollConfigAnchorEl} onClose={this.handleCloseConfigureLidarRoll}
                    grayMode={this.props.lidarGrayMode}
                    onGrayModeChanged={this.props.onLidarGrayModeChanged}
                    filterRange={this.props.lidarFilterRange}
                    maxFilterRange={this.props.maxLidarFilterRange}
                    onFilterRangeChanged={this.props.onLidarFilterRangeChanged}
                    colors={this.props.lidarColors}
                    onColorsChanged={this.props.onLidarColorsChanged}
                    colorStops={this.props.lidarColorStops}
                    onColorStopsChanged={this.props.onLidarColorStopsChanged}
                />
            );
        }
        else {
            return null;
        }
    }




    /**
     * renders the list of buttons that have to be shown. It only renders those that can be shown
     */
    renderButtons() {
        const { t, classes } = this.props;
        let result = [];
        let max = this.state.maxButtons;
        if (this.props.showMainCrawlerFunctions) {
            max = this.renderTopGenericBtns(result, max);
        }
        max = this.renderSourceSelector(result, max);
        switch (this.props.selectedSource) {
            case 'sonar-cross-section':
                max = this.renderSonarBtns(result, max);
                break;
            case 'lidar-cross-section':
                max = this.renderLidarBtns(result, max);
                break;
            case 'lidar-rollout':
                max = this.renderLidarRollBtns(result, max);
                break;
            case 'inclination':
                max = this.renderInclinationBtns(result, max);
                break;
            default:
                break;
        }
        return result;
    }


    renderTopGenericBtns(result, max) {
        const { t, classes } = this.props;
        if (max > 0) {
            let icon = this.props.showVideoSection ? <ChevronUp /> : <ChevronDown />;
            let txt = this.props.showVideoSection ? t("Hide the video section") : t("Show the video section");
            result.push(
                <div className={classes.bottomSeparator} key={result.length}>
                    <Tooltip title={txt} key={result.length}>
                        <div>
                            <IconButton aria-label="video section visibility"
                                onClick={this.props.onToggleVideoSection}
                                className={this.props.classes.icon}>
                                {icon}
                            </IconButton>
                        </div>
                    </Tooltip>

                </div>
            );
            max--;
        }
        if (!this.props.readOnly && max > 0) {
            result.push(
                <Tooltip title={t("Geef een afstand in en stuur naar tractor")} key={result.length}>
                    <div>
                        <IconButton aria-label="richting"
                            onClick={this.handleSetDistance}
                            disabled={!this.props.liveFeed}
                            className={this.props.classes.icon}>
                            <Textbox />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
            if (max > 0) {
                result.push(
                    <Tooltip title={t("Zet afstandsteller op 0")} key={result.length}>
                        <div>
                            <IconButton aria-label="reset"
                                disabled={!this.props.liveFeed}
                                onClick={this.resetDistance}
                                className={this.props.classes.icon}>
                                <RotateRight />
                            </IconButton>
                        </div>
                    </Tooltip>
                );
                max--;
            }
        }
        if (max > 0) {
            result.push(
                <div className={classes.bottomSeparator} key={result.length}>
                    <Tooltip title={t("Go to position in tube")} >
                        <div>
                            <IconButton aria-label="goto"
                                disabled={this.props.liveFeed}
                                onClick={this.handleSetDistance}
                                className={this.props.classes.icon}>
                                <SwapHor />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            );
            max--;
        }
        if (!this.props.showVideoSection && max > 0 && !configService.isSite) {
            result.push(
                <Tooltip title={t("Show video feed on a new window")} key={result.length}>
                    <div className={classes.topSeparator}>
                        <IconButton aria-label="open new window for video"
                            onClick={this.props.onOpenVideoScreen}
                            className={this.props.classes.icon}>
                            <Monitor />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }
        if (max > 0 && (this.props.hasSonar || this.props.hasLidar)) {
            result.push(
                <Tooltip title={t("Select the layout and nr of boxes")} key={result.length}>
                    <div>
                        <IconButton aria-label="layout"
                            onClick={this.handleShowSelectDisplayMode}
                            className={this.props.classes.icon}>
                            <LayoutMore />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }
        return max;
    }

    renderSourceSelector(result, max) {
        const { t, classes } = this.props;
        if (max > 0 && (this.props.hasLidar || this.props.hasSonar)) {              // if there is only an inclination view, no need to show the menu item
            result.push(
                <div className={classes.bottomSeparator} key={result.length}>
                    <Tooltip title={t("Select the data source")} >
                        <div>
                            <IconButton aria-label="datasource"
                                onClick={this.handleShowSelectSource}
                                className={this.props.classes.icon}>
                                <DatabaseSettings />
                            </IconButton>
                        </div>
                    </Tooltip>
                    {this.renderSelectSourcePopover()}
                </div>
            );
            max--;
        }
        return max;
    }

    renderSonarBtns(result, max) {
        const { t } = this.props;
        if (max > 0) {                                // showing sonar/lidar (current time/live) // last one, here max can be 0
            result.push(
                <Tooltip title={t("Zoom")} key={result.length}>
                    <div>
                        <IconButton aria-label="zoom"
                            onClick={this.handleShowZoom}
                            className={this.props.classes.icon}>
                            <Magnify />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }
        if (max > 0) {
            result.push(
                <Tooltip title={t("Configure sonar")} key={result.length}>
                    <div>
                        <IconButton aria-label="configure sonar"
                            onClick={this.handleShowConfigureSonar}
                            className={this.props.classes.icon}>
                            <TuneVertical />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }
        return max;
    }

    renderLidarBtns(result, max) {
        const { t } = this.props;
        if (max > 0) {                                // showing sonar/lidar (current time/live) // last one, here max can be 0
            result.push(
                <Tooltip title={t("Zoom")} key={result.length}>
                    <div>
                        <IconButton aria-label="zoom"
                            onClick={this.handleShowZoom}
                            className={this.props.classes.icon}>
                            <Magnify />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }
        if (max > 0) {                                                         // last one, here max can be 0
            result.push(
                <Tooltip title={t("show_hide_disformation")} key={result.length}>
                    <div>
                        <StyledToggleButton aria-label="show hide disormation"
                            value="check"
                            selected={this.state.showDisformation}
                            onChange={this.handleToggleDisformation}
                        >
                            <CircleHalfFull/>
                        </StyledToggleButton>
                </div>
                </Tooltip >
            );
            max--;
        }
        if (max > 0) {                                                         // last one, here max can be 0
            result.push(
                <Tooltip title={t("perform_measure")} key={result.length}>
                    <div>
                        <StyledToggleButton aria-label="perform measurment"
                            value="check"
                            selected={this.state.showMeasureDistanceCrossSection}
                            onChange={this.handleToggleMeasureDistanceCrossSection}
                        >
                            <VectorLine />
                        </StyledToggleButton>
                </div>
                </Tooltip >
            );
            max--;
        }
        return max;
    }

    renderLidarRollBtns(result, max) {
        const { t } = this.props;
        if (max > 0) {                                // showing sonar/lidar (current time/live) // last one, here max can be 0
            result.push(
                <Tooltip title={t("Zoom")} key={result.length}>
                    <div>
                        <IconButton aria-label="zoom"
                            onClick={this.handleShowLidarRolloutZoom}
                            className={this.props.classes.icon}>
                            <Magnify />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }
        if (max > 0) {                                                         // last one, here max can be 0
            let selectRevIcon = (this.props.showReverse) ? <ArrowLeftBoldCircleOutline /> : <ArrowRightBoldCircleOutline />;
            result.push(
                <Tooltip title={t("show_regular_or_reverse_inclination")} key={result.length}>
                    <div>
                        <IconButton aria-label="show reverse data"
                            disabled={this.props.liveFeed || !this.props.hasReverse}
                            onClick={this.handleTogglShowReverse}
                            className={this.props.classes.icon}>
                            {selectRevIcon}
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }

        if (max > 0) {
            result.push(
                <Tooltip title={t("Configure lidar rollout")} key={result.length}>
                    <div>
                        <IconButton aria-label="configure lidar"
                            onClick={this.handleShowConfigureLidarRoll}
                            className={this.props.classes.icon}>
                            <TuneVertical />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
        }

        if (max > 0) {                                                         // last one, here max can be 0
            result.push(
                <Tooltip title={t("perform_measure")} key={result.length}>
                    <div>
                        <StyledToggleButton aria-label="perform measurment"
                            value="check"
                            selected={this.state.showMeasureDistanceRollout}
                            onChange={this.handleToggleMeasureDistanceRollout}
                        >
                            <VectorLine />
                        </StyledToggleButton>
                </div>
                </Tooltip >
            );
            max--;
        }

        if (max > 0) {                                                         // last one, here max can be 0
            result.push(
                <Tooltip title={t("measure_angle")} key={result.length}>
                    <div>
                        <StyledToggleButton aria-label="perform angle measurment"
                            value="check"
                            selected={this.state.showMeasureAngleRollout}
                            onChange={this.handleToggleMeasureAngleRollout}
                        >
                            <AngleAcute />
                        </StyledToggleButton>
                </div>
                </Tooltip >
            );
            max--;
        }

        return max;
    }

    renderInclinationBtns(result, max) {
        const { t } = this.props;
        if (max > 0) {
            result.push(
                <Tooltip title={t("Change sample distance")} key={result.length}>
                    <div>
                        <IconButton aria-label="sample distance"
                            disabled={this.props.liveFeed}
                            onClick={this.handleShowSampleDist}
                            className={this.props.classes.icon}>
                            <Ruler />
                        </IconButton>
                    </div>
                </Tooltip>
            );
            max--;
            if (max > 0) {
                const btnStyle = { transform: "scaleY(-1)" };
                let viewToggle = (this.props.showPuddles) ? <FilterHdrIcon style={btnStyle} /> : <FilterHdrOutlinedIcon style={btnStyle} />;
                result.push(
                    <Tooltip title={t("Show/hide puddle formation")} key={result.length}>
                        <div>
                            <IconButton aria-label="puddles"
                                disabled={this.props.liveFeed}
                                onClick={this.handleToggleShowPuddles}
                                className={this.props.classes.icon}>
                                {viewToggle}
                            </IconButton>
                        </div>
                    </Tooltip>
                );
                max--;

                if (max > 0) {                                                         // last one, here max can be 0
                    let heightIcon = this.getAdjustHeightIcon();
                    result.push(
                        <Tooltip title={t("relative_abs_long")} key={result.length}>
                            <div>
                                <IconButton aria-label="crop height"
                                    disabled={this.props.liveFeed}
                                    onClick={this.handleToggleAdjustHeight}
                                    className={this.props.classes.icon}>
                                    {heightIcon}
                                </IconButton>
                            </div>
                        </Tooltip>
                    );
                    max--;
                }


                if (max > 0) {                                                         // last one, here max can be 0
                    let styleIcon = this.getSeriesStyleIcon();
                    result.push(
                        <Tooltip title={t("select_track_series_style")} key={result.length}>
                            <div>
                                <IconButton aria-label="series"
                                    disabled={this.props.liveFeed}
                                    onClick={this.handleToggleSeriesStyle}
                                    className={this.props.classes.icon}>
                                    {styleIcon}
                                </IconButton>
                            </div>
                        </Tooltip>
                    );
                    max--;
                }

                if (max > 0) {                                                         // last one, here max can be 0
                    let viewGrid = (this.props.showGrid) ? <Grid /> : <GridOff />;
                    result.push(
                        <Tooltip title={t("Show/hide the grid lines")} key={result.length}>
                            <div>
                                <IconButton aria-label="show-hide grid"
                                    disabled={this.props.liveFeed}
                                    onClick={this.handleTogglGrid}
                                    className={this.props.classes.icon}>
                                    {viewGrid}
                                </IconButton>
                            </div>
                        </Tooltip>
                    );
                    max--;
                }

                if (max > 0) {                                                         // last one, here max can be 0
                    let xAxisLabels = (this.props.showObsDistances) ? <AlertCircleOutline /> : <Bird />;
                    result.push(
                        <Tooltip title={t("select_label_type_x_axis")} key={result.length}>
                            <div>
                                <IconButton aria-label="x-axis labels"
                                    disabled={this.props.liveFeed}
                                    onClick={this.handleTogglShowObsDistances}
                                    className={this.props.classes.icon}>
                                    {xAxisLabels}
                                </IconButton>
                            </div>
                        </Tooltip>
                    );
                    max--;
                }

                if (max > 0) {                                                         // last one, here max can be 0
                    let viewStartEndLabels = (this.props.showStartEndLabels) ? <StartEndLabel /> : <StartEndLabelOutline />;
                    result.push(
                        <Tooltip title={t("Show/hide the start & end labels")} key={result.length}>
                            <div>
                                <IconButton aria-label="show-hide end and start labels"
                                    disabled={this.props.liveFeed}
                                    onClick={this.handleTogglStartEndLabels}
                                    className={this.props.classes.icon}>
                                    {viewStartEndLabels}
                                </IconButton>
                            </div>
                        </Tooltip>
                    );
                    max--;
                }

                if (max > 0) {                                                         // last one, here max can be 0
                    let selectRevIcon = (this.props.showReverse) ? <ArrowLeftBoldCircleOutline /> : <ArrowRightBoldCircleOutline />;
                    result.push(
                        <Tooltip title={t("show_regular_or_reverse_inclination")} key={result.length}>
                            <div>
                                <IconButton aria-label="show reverse data"
                                    disabled={this.props.liveFeed || !this.props.hasReverse}
                                    onClick={this.handleTogglShowReverse}
                                    className={this.props.classes.icon}>
                                    {selectRevIcon}
                                </IconButton>
                            </div>
                        </Tooltip>
                    );
                    max--;
                }

                if (max > 0) {                                                         // last one, here max can be 0
                    result.push(
                        <Tooltip title={t("Export image")} key={result.length}>
                            <div>
                                <IconButton aria-label="export image"
                                    disabled={this.props.liveFeed}
                                    onClick={this.props.onExportImage}
                                    className={this.props.classes.icon}>
                                    <FileExport />
                                </IconButton>
                            </div>
                        </Tooltip>
                    );
                    max--;
                }
            }
        }
        return max;
    }

    getSeriesStyleIcon() {
        if (this.props.liveFeed) {
            return <LinearScaleIcon />;
        }
        switch (this.props.seriesStyle) {
            case 'error': return <SeriesWide />;
            case 'ideal': return <LinearScaleIcon />;
            default:
                return <SeriesDouble />
        }
    }

    getAdjustHeightIcon() {
        if (this.props.liveFeed || this.props.adjustHeightDif) {
            return <CropFree />;
        }
        return <Crop />
    }

    /**
     * renders all the actions that couldn't be placed as a button.
     * @param count the nr of buttons that were rendered
     */
    renderMenuItems(count) {
        const { t } = this.props;
        let result = [];

        if (this.props.showMainCrawlerFunctions) {
            count = this.renderTopGenericMenus(result, count);
        }
        count = this.renderSourceSelectorMenus(result, count);

        switch (this.props.selectedSource) {
            case 'sonar-cross-section':
                count = this.renderSonarMenus(result, count);
                break;
            case 'lidar-cross-section':
                count = this.renderLidarMenus(result, count);
                break;
            case 'lidar-rollout':
                count = this.renderLidarRollMenus(result, count);
                break;
            case 'inclination':
                count = this.renderInclinationMenus(result, count);
                break;
            default:
                break;
        }
        return result;
    }


    renderTopGenericMenus(result, count) {
        const { t } = this.props;
        if (count <= 0) {
            let icon = this.props.showVideoSection ? <ChevronUp /> : <ChevronDown />;
            let txt = this.props.showVideoSection ? t("Hide the video section") : t("Show the video section");
            result.push(
                <MenuItem onClick={this.props.onToggleVideoSection} key={result.length}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={txt} />
                </MenuItem>
            );
        }
        else {
            count--;
        }
        if (!this.props.readOnly) {
            if (count <= 0) {
                result.push(
                    <MenuItem onClick={this.handleSetDistance} disabled={!this.props.liveFeed} key={result.length}>
                        <ListItemIcon>
                            <Textbox />
                        </ListItemIcon>
                        <ListItemText primary={t("Geef een afstand in en stuur naar tractor")} />
                    </MenuItem>
                );
            }
            else {
                count--;
            }
            if (count <= 0) {
                result.push(
                    <MenuItem onClick={this.resetDistance} disabled={!this.props.liveFeed} key={result.length}>
                        <ListItemIcon>
                            <RotateRight />
                        </ListItemIcon>
                        <ListItemText primary={t("Zet afstandsteller op 0")} />
                    </MenuItem>
                );
            }
            else {
                count--;
            }
        }
        if (count <= 0) {
            result.push(
                <MenuItem onClick={this.handleSetDistance} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        <SwapHor />
                    </ListItemIcon>
                    <ListItemText primary={t("Go to position in tube")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }
        if (!this.props.showVideoSection && !configService.isSite) {
            if (count <= 0) {
                result.push(
                    <MenuItem onClick={this.props.onOpenVideoScreen} key={result.length}>
                        <ListItemIcon>
                            <Monitor />
                        </ListItemIcon>
                        <ListItemText primary={t("Show video feed on a new window")} />
                    </MenuItem>
                );
            }
            else {
                count--;
            }
        }
        if (this.props.hasSonar || this.props.hasLidar) {
            if (count <= 0) {
                this.addMenuSeparator(result);
                this.renderSelectDisplayModeItems(result);
            }
            else {
                count--;
            }
        }

        return count;
    }

    renderSourceSelectorMenus(result, count) {
        const { t } = this.props;
        if (count <= 0) {
            if (this.props.hasLidar || this.props.hasSonar) {                   // if there is onl an inclination view, no need to show the menu item
                this.addMenuSeparator(result);
                this.renderSelectSourceItems(result);
            }
        }
        else {
            count--;
        }
        return count;
    }

    renderLidarRollMenus(result, count) {
        const { t } = this.props;
        let seperated = false;
        if (count <= 0) {
            seperated = this.addMenuSeparator(result);
            result.push(
                <MenuItem onClick={this.handleShowLidarRolloutZoom} key={result.length}>
                    <ListItemIcon>
                        <Magnify />
                    </ListItemIcon>
                    <ListItemText primary={t("Zoom")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }
        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            let selectRevIcon = (this.props.showReverse) ? <ArrowLeftBoldCircleOutline /> : <ArrowRightBoldCircleOutline />;
            result.push(
                <MenuItem onClick={this.handleTogglShowReverse} disabled={this.props.liveFeed || !this.props.hasReverse} key={result.length}>
                    <ListItemIcon>
                        {selectRevIcon}
                    </ListItemIcon>
                    <ListItemText primary={t("show_regular_or_reverse_inclination_short")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }


        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            result.push(
                <MenuItem onClick={this.handleShowConfigureLidarRoll}  key={result.length}>
                    <ListItemIcon>
                    <TuneVertical />
                    </ListItemIcon>
                    <ListItemText primary={t("Configure lidar rollout")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            result.push(
                <MenuItem onClick={this.handleToggleMeasureDistanceRollout} >
                    <ListItemIcon>
                        <VectorLine />
                    </ListItemIcon>
                    <ListItemText primary={t("perform_measure")} />
                    {(this.state.showMeasureDistanceRollout) ? <CheckboxOutline/> : <CheckboxBlankOutline/>}
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            result.push(
                <MenuItem onClick={this.handleToggleMeasureAngleRollout} >
                    <ListItemIcon>
                        <AngleAcute />
                    </ListItemIcon>
                    <ListItemText primary={t("measure_angle")} />
                    {(this.state.showMeasureAngleRollout) ? <CheckboxOutline/> : <CheckboxBlankOutline/>}
                </MenuItem>
            );
        }
        else {
            count--;
        }

        return count;
    }

    renderLidarMenus(result, count) {
        const { t } = this.props;
        if (count <= 0) {
            this.addMenuSeparator(result);
            result.push(
                <MenuItem onClick={this.handleShowZoom} key={result.length}>
                    <ListItemIcon>
                        <Magnify />
                    </ListItemIcon>
                    <ListItemText primary={t("Zoom")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            result.push(
                <MenuItem onClick={this.handleToggleDisformation} key={result.length}>
                    <ListItemIcon>
                        <CircleHalfFull />
                    </ListItemIcon>
                    <ListItemText primary={t("Deformatie")} />
                    {(this.state.showDisformation) ? <CheckboxOutline/> : <CheckboxBlankOutline/>}
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            result.push(
                <MenuItem onClick={this.handleToggleMeasureDistanceCrossSection} key={result.length}>
                    <ListItemIcon>
                        <VectorLine />
                    </ListItemIcon>
                    <ListItemText primary={t("Afstand")} />
                    {(this.state.showMeasureDistanceCrossSection) ? <CheckboxOutline/> : <CheckboxBlankOutline/>}
                </MenuItem>
            );
        }
        else {
            count--;
        }

        return count;
    }

    renderInclinationMenus(result, count) {
        const { t } = this.props;
        let seperated = false;
        if (count <= 0) {
            seperated = this.addMenuSeparator(result);
            result.push(
                <MenuItem onClick={this.handleShowSampleDist} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        <Ruler />
                    </ListItemIcon>
                    <ListItemText primary={t("Change sample distance")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            const btnStyle = { transform: "scaleY(-1)" };
            let viewToggle = (this.props.showPuddles) ? <FilterHdrIcon style={btnStyle} /> : <FilterHdrOutlinedIcon style={btnStyle} />;
            result.push(
                <MenuItem onClick={this.handleToggleShowPuddles} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        {viewToggle}
                    </ListItemIcon>
                    <ListItemText primary={t("Show/hide puddle formation")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            let heightIcon = this.getAdjustHeightIcon();
            result.push(
                <MenuItem onClick={this.handleToggleAdjustHeight} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        {heightIcon}
                    </ListItemIcon>
                    <ListItemText primary={t("Switch between relative and absolute height calculation")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            let styleIcon = this.getSeriesStyleIcon();
            result.push(
                <MenuItem onClick={this.handleToggleSeriesStyle} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        {styleIcon}
                    </ListItemIcon>
                    <ListItemText primary={t("select_track_series_style_short")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            let viewGrid = (this.props.showGrid) ? <Grid /> : <GridOff />;
            result.push(
                <MenuItem onClick={this.handleTogglGrid} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        {viewGrid}
                    </ListItemIcon>
                    <ListItemText primary={t("Show/hide the grid lines")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            let xAxisLabels = (this.props.showObsDistances) ? <AlertCircleOutline /> : <Bird />;
            result.push(
                <MenuItem onClick={this.handleTogglShowObsDistances} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        {xAxisLabels}
                    </ListItemIcon>
                    <ListItemText primary={t("select_label_type_x_axis_short")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            let viewStartEndLabels = (this.props.showStartEndLabels) ? <StartEndLabel /> : <StartEndLabelOutline />;
            result.push(
                <MenuItem onClick={this.handleTogglStartEndLabels} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        {viewStartEndLabels}
                    </ListItemIcon>
                    <ListItemText primary={t("Show/hide the start & end labels")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            let selectRevIcon = (this.props.showReverse) ? <ArrowLeftBoldCircleOutline /> : <ArrowRightBoldCircleOutline />;
            result.push(
                <MenuItem onClick={this.handleTogglShowReverse} disabled={this.props.liveFeed || !this.props.hasReverse} key={result.length}>
                    <ListItemIcon>
                        {selectRevIcon}
                    </ListItemIcon>
                    <ListItemText primary={t("show_regular_or_reverse_inclination_short")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }

        if (count <= 0) {
            seperated = this.addMenuSeparator(result);
            result.push(
                <MenuItem onClick={this.props.onExportImage} disabled={this.props.liveFeed} key={result.length}>
                    <ListItemIcon>
                        <FileExport />
                    </ListItemIcon>
                    <ListItemText primary={t("Export image")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }
        return count;
    }

    renderSonarMenus(result, count) {
        const { t } = this.props;
        let seperated = false;
        if (count <= 0) {
            seperated = this.addMenuSeparator(result);
            result.push(
                <MenuItem onClick={this.handleShowZoom} key={result.length}>
                    <ListItemIcon>
                        <Magnify />
                    </ListItemIcon>
                    <ListItemText primary={t("Zoom")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }
        if (count <= 0) {
            if (!seperated) {
                seperated = this.addMenuSeparator(result);
            }
            result.push(
                <MenuItem onClick={this.handleShowConfigureSonar} key={result.length}>
                    <ListItemIcon>
                        <TuneVertical />
                    </ListItemIcon>
                    <ListItemText primary={t("Configure sonar")} />
                </MenuItem>
            );
        }
        else {
            count--;
        }
        return count;
    }

    addMenuSeparator(list) {
        if (list.length > 0) {
            list.push(
                <Divider key={list.length} />
            );
            return true;
        }
        return false;
    }

    handleZoomChange = (event, value) => {
        if (this.props.onZoomChanged) {
            if (value >= 24 && value <= 26) {                // add a small snap feature           
                value = 25;
            }
            else if (value >= 48 && value <= 52) {
                value = 50;
            }
            else if (value >= 98 && value <= 102) {
                value = 100;
            }
            else if (value >= 148 && value <= 152) {
                value = 150;
            }
            else if (value >= 198 && value <= 202) {
                value = 200;
            }
            else if (value >= 248 && value <= 252) {
                value = 250;
            }
            else if (value >= 298 && value <= 302) {
                value = 300;
            }
            else if (value >= 348 && value <= 352) {
                value = 350;
            }
            else if (value >= 398 && value <= 402) {
                value = 400;
            }
            else if (value >= 448 && value <= 452) {
                value = 450;
            }
            else if (value >= 498 && value <= 502) {
                value = 500;
            }

            else if (value >= 598 && value <= 602) {
                value = 600;
            }
            else if (value >= 698 && value <= 702) {
                value = 700;
            }
            else if (value >= 798 && value <= 802) {
                value = 800;
            }
            else if (value >= 898 && value <= 902) {
                value = 900;
            }
            else if (value >= 998 && value <= 1002) {
                value = 1000;
            }
            if (!this.zoomUpdateDelay) {
                this.props.onZoomChanged(value);
                this.zoomUpdateDelay = setTimeout(() => {
                    this.zoomUpdateDelay = null;
                    if (this.nextZoom !== null) {
                        this.props.onZoomChanged(this.nextZoom);
                        this.nextZoom = null;
                    }
                }, 150);
            }
            else {
                this.nextZoom = value;
            }
        }
    }

    handleSampleDistanceChange = (event, value) => {
        if (this.props.onSampleDistanceChange) {
            this.props.onSampleDistanceChange(value);
        }
    }



    handleToggleTrackSonarView = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleTrackSonarView) {
            this.props.onToggleTrackSonarView();
        }
    }

    handleToggleShowPuddles = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleShowPuddles) {
            this.props.onToggleShowPuddles();
        }
    }

    handleToggleSeriesStyle = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleSeriesStyle) {
            this.props.onToggleSeriesStyle();
        }
    }

    handleToggleAdjustHeight = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleHeightDif) {
            this.props.onToggleHeightDif();
        }
    }

    handleTogglGrid = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleGrid) {
            this.props.onToggleGrid();
        }
    }

    handleTogglStartEndLabels = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleStartEndLabels) {
            this.props.onToggleStartEndLabels();
        }
    }

    handleTogglShowObsDistances = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleShowObsDistances) {
            this.props.onToggleShowObsDistances();
        }
    }

    handleTogglShowReverse = () => {
        this.setState({ resetDlgOpen: false, menuAnchorEl: null });
        if (this.props.onToggleShowReverse) {
            this.props.onToggleShowReverse();
        }
    }

    handleOpenMenu = (event) => {
        this.setState({ menuAnchorEl: event.currentTarget });
    }

    handleCloseMenu = () => {
        this.setState({ menuAnchorEl: null });
    }

    handleCloseDisplayModePopover = () => {
        this.setState({ displayModeAnchorEl: null, menuAnchorEl: null });
    }

    handleShowZoom = (event) => {
        this.setState({ zoomAnchorEl: event.currentTarget });
    }

    handleShowLidarRolloutZoom = (event) => {
        this.setState({ lidarRollZoomAnchorEl: event.currentTarget });
    }

    handleLidarRolloutZoomChanged = (event, value) => {
        if (this.props.onZoomChanged) {
            this.props.onZoomChanged(value);
        }
    }

    handleShowSelectDisplayMode = (event) => {
        this.setState({ displayModeAnchorEl: event.currentTarget });
    }

    handleShowSelectSource = (event) => {
        this.setState({ sourcAnchorEl: event.currentTarget });
    }

    handleSelectSource = (value) => () => {
        this.setState({ sourcAnchorEl: null, menuAnchorEl: null });
        if (value !== null && this.props.onSelectSource) {
            this.props.onSelectSource(value);
        }
    }

    handleShowSampleDist = (event) => {
        this.setState({ sampleDistanceAnchorEl: event.currentTarget });
    }

    handleCloseZoom = () => {
        this.setState({ zoomAnchorEl: null });
    }

    handleCloseLidarRolloutZoom = () => {
        this.setState({ lidarRollZoomAnchorEl: null });
    }

    handleCloseSampleDistance = () => {
        this.setState({ sampleDistanceAnchorEl: null });
    }

    handleShowConfigureSonar = (event) => {
        this.setState({ sonarConfigAnchorEl: event.currentTarget });
    }

    handleCloseConfigureSonar = () => {
        this.setState({ sonarConfigAnchorEl: null });
    }

    handleShowConfigureLidarRoll = (event) => {
        this.setState({ lidarRollConfigAnchorEl: event.currentTarget });
    }

    handleCloseConfigureLidarRoll = () => {
        this.setState({ lidarRollConfigAnchorEl: null });
    }


    /**
     * when not high enough, draw menu
     */
    handleResize = () => {
        let height = this.props.allowedHeight;
        if (height > 200) {
            let nrSeparators = (this.props.showVideoSection || configService.isCloud) ? 2 : 3;
            height -= (this.props.theme.spacing(1) * nrSeparators);
        }
        else if (height > 125) {
            height -= (this.props.theme.spacing(1) * 2);
        }
        else if (height > 75) {
            height -= this.props.theme.spacing(1);
        }
        let toRemove = this.props.showDashboardFunctions ? 2 : 1;   // - 2 for the ... and the showDashboard
        let maxButtons = (height / 25) - toRemove;   // - 2 for the ... and the showDashboard
        let maxButtonsTrunc = Math.trunc(height / 25) - toRemove;
        if (maxButtons - maxButtonsTrunc < 0.01) {      // if the remaining space besomes too small, remove 1 more buttons, otherwise we can't remove the last buttons
            maxButtonsTrunc--;
        }
        this.setState({ maxButtons: maxButtonsTrunc });
    }

    handleSetDistance = () => {
        this.setState({ resetDlgOpen: true, menuAnchorEl: null });
    }

    resetDistance = () => {
        this.setState({ menuAnchorEl: null });                // needs to be reset in case we
        if (!this.props.liveFeed) {
            this.props.onPositionChanged(0);
        }
        else {
            engineService.sendResetDistanceRequest();
        }
    }

    handleSetDlgClose = (value) => {
        this.setState({ resetDlgOpen: false });
        if (value != null) {
            if (!this.props.liveFeed) {
                if (this.props.onFindTimeForDistance) {
                    this.props.onFindTimeForDistance(+value);
                }
            }
            else {
                engineService.sendDistanceSet(+value);
            }
        }
    }

    handleSelectDisplayMode = (value) => () => {
        this.setState({ displayModeAnchorEl: null, menuAnchorEl: null });
        if (value !== null && this.props.onSelectLayout) {
            this.props.onSelectLayout(value);
        }
    }

    handleKeyboard = (ev) => {
        if (this.props.readOnly) {                              // not supported in readonly mode
            return;
        }
        if (ev.code === "F9" && ev.ctrlKey === false) {
            this.handleSetDistance();
        }
    }

    handleToggleDisformation = () => {
        const newValue = !this.state.showDisformation;
        this.setState({ showDisformation: newValue });
        this.handleCloseMenu();
        if (this.props.onShowDeformationChanged) {
            this.props.onShowDeformationChanged(newValue);
        }
    }

    handleToggleMeasureDistanceCrossSection = () => {
        const newValue = !this.state.showMeasureDistanceCrossSection;
        this.setState({ showMeasureDistanceCrossSection: newValue });
        this.handleCloseMenu();
        if (this.props.onShowMeasureDistanceCrossSectionChanged) {
            this.props.onShowMeasureDistanceCrossSectionChanged(newValue);
        }
    }

    handleToggleMeasureDistanceRollout = () => {
        const newValue = !this.state.showMeasureDistanceRollout;
        if (newValue) {
            this.setState({ showMeasureDistanceRollout: newValue, showMeasureAngleRollout: false });
            if (this.props.onShowMeasureAngleRolloutChanged) {
                this.props.onShowMeasureAngleRolloutChanged(false);
            }
        }
        else {
            this.setState({ showMeasureDistanceRollout: newValue });
        }
        this.handleCloseMenu();
        if (this.props.onShowMeasureDistanceRolloutChanged) {
            this.props.onShowMeasureDistanceRolloutChanged(newValue);
        }
    }

    handleToggleMeasureAngleRollout = () => {
        const newValue = !this.state.showMeasureAngleRollout;
        if (newValue) { 
            this.setState({ showMeasureAngleRollout: newValue, showMeasureDistanceRollout: false });                    // only 1 of the 2 can be active at the same time
            if (this.props.onShowMeasureDistanceRolloutChanged) {
                this.props.onShowMeasureDistanceRolloutChanged(false);
            }
        }
        else {
            this.setState({ showMeasureAngleRollout: newValue });
        }
        this.handleCloseMenu();
        if (this.props.onShowMeasureAngleRolloutChanged) {
            this.props.onShowMeasureAngleRolloutChanged(newValue);
        }
    }

}

RobotToolbar.propTypes = {
    readOnly: PropTypes.bool,                           // for viewer
    liveFeed: PropTypes.bool,
    dashboardOpen: PropTypes.bool,                          // when true, the dashboard is open and no button should be shown to open it
    onToggleDashboard: PropTypes.func,                        // called when user wants to show the dashboard
    onFindTimeForDistance: PropTypes.func,                  // called when the user selects a distance which needs to be converted to time
    onToggleTrackSonarView: PropTypes.func,                 // called to switch between different views
    showTrack: PropTypes.bool,                              // used to show the correct icon for the toggle to select between sonar and track view
    hasSonar: PropTypes.bool,                               // when true, shows the toggle button to select between track and sonar. when lidar is also true, more options available
    hasLidar: PropTypes.bool,                               // when true, shows the toggle button to select between track and lidar. when sonar is also true, more options available
    zoom: PropTypes.number,                                 // the current zoom value (for sonar/lidar)
    onZoomChanged: PropTypes.func,                          // called when the zoom value has changed.
    showPuddles: PropTypes.bool,                            // for trackview, current puddle view value
    onToggleShowPuddles: PropTypes.func,                    // for trackview, toggle puddle view value
    seriesStyle: PropTypes.string,                     // for trackview: switch between correct height or height between start and end that is max of view (relative)
    onToggleSeriesStyle: PropTypes.func,                    // for trackview: switch between thick (errors) or thin line
    sampleDistance: PropTypes.number,                       // distance used per sample size on the trackview. allows for for more or less detailed viewed
    onSampleDistanceChange: PropTypes.func,                 // callback when user changed distance value
    allowedHeight: PropTypes.number.isRequired,             // the height that the toolbar can use
    RadarDisplayMode: PropTypes.string,                     // the currently selected display mode for the radar, so we cn show the selction to the user
    onToggleVideoSection: PropTypes.func,                   // called to show/hide the video section
    showVideoSection: PropTypes.bool,                       // when true, the video section is shown, otherwise it is collapsed
    onOpenVideoScreen: PropTypes.func,                      // callback for the button to open new screen for video
    showGrid: PropTypes.bool,                               // when true, the grid & markers are shown
    onToggleGrid: PropTypes.func,                           // callback for showing/hiding the grid
    onToggleStartEndLabels: PropTypes.func,               // callback for showing/hiding the start/end labels
    showStartEndLabels: PropTypes.bool,
    adjustHeightDif: PropTypes.bool,                        // when true, height calcuation will be scaled/cropped so that it fits to the specifications of the streng. this is to adjust for cumulated rounding errors and errors in sensor
    onToggleHeightDif: PropTypes.func,
    onExportImage: PropTypes.func,                          // called to export the svg image
    showReverse: PropTypes.bool,                            // when true, reverse inclindation data is shown
    hasReverse: PropTypes.bool,                            // when true, there is reverse inclination data available to show
    onToggleShowReverse: PropTypes.func,                    // callback to toggle showReverse value
    showObsDistances: PropTypes.bool,                       // when true, observation distances are shown, when false, flattened distance is shown.
    onToggleShowObsDistances: PropTypes.func,               // to change the value showObsDistances
    showMainCrawlerFunctions: PropTypes.bool,               // true for the body part that is at the top, shows the generic toolbar buttons
    onSelectSource: PropTypes.func,                         // called when the user selected a different data source to display
    selectedSource: PropTypes.string,                       // the currently selected source
    onSelectLayout: PropTypes.func,                         // called when the user selected a different data source to display
    selectedLayout: PropTypes.string,                       // currently selected layout
    showDashboardFunctions: PropTypes.bool,                 // when true, the functions for the dashboard on the left are also shown (for the bottom left part)

    lidarRollSnap: PropTypes.bool,
    onLidarRollSnapChanged: PropTypes.func,
    lidarGrayMode: PropTypes.bool,                   // when true, don't use colors
    onLidarGrayModeChanged: PropTypes.func,
    lidarFilterRange: PropTypes.array,               // [low, high] cutoff values
    maxLidarFilterRange: PropTypes.array,               // [low, high] max values for cutoff
    onLidarFilterRangeChanged: PropTypes.func,
    lidarColors: PropTypes.array,                    // list of stops and the color for each stop
    onLidarColorsChanged: PropTypes.func,
    lidarColorStops: PropTypes.array,                // the % positions of each color, for rendering the color range
    onLidarColorStopsChanged: PropTypes.func,
    onShowDeformationChanged: PropTypes.func,
    onShowMeasureDistanceCrossSectionChanged: PropTypes.func,    // called to toggle the distance calculator on the lidar cros-section
    onShowMeasureDistanceRolloutChanged: PropTypes.func,
    onShowMeasureAngleRolloutChanged: PropTypes.func,
};

export default withTheme(withTranslation()(withStyles(styles)(RobotToolbar)));