export function readDegree16BE(buf, start) {
    //return ((data[start] << 8) | data[start+1]) / 32768 * 180;
    return buf.readInt16BE(start) / 32768 * 180;
}

export function readDegree16LE(buf, start) {
    //return ((data[start] << 8) | data[start+1]) / 32768 * 180;
    return buf.readInt16LE(start) / 32768 * 180;
}

export function readTemp16LE(buf, start) {
    return buf.readInt16LE(start) / 100;
}

export function readAcceleration16BE(buf, start) {
    //return ((data[start] << 8) | data[start+1]) / 32768 * 16;
    return buf.readInt16BE(start) / 32768 * 16;
}