/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons

import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { lidarService } from './lidar_service';
import { sonarService } from './sonar_service';
import { strandPropsService } from '../../../services/strand_props_service';
import { drawSonar, drawTicks, drawLidar, buildValueColorConvertor, getIdealScaleSize, drawLidarDistanceTriangle } from './imaging_service';
import { documentControlService } from '../../document_control_service';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeformationCalculator from './deformation_calculator_component';
import LidarDistCrossSectionCalculator from './lidar_dist_calculator.component';

const styles = (theme) => ({
    root: {
        flex: 1, 
        display: "flex",
        overflow: 'hidden',                                                             // important: needed to make certain that the sonar-canvas shrinks when making view smaller 
        borderWidth: "0.1px",
        borderStyle: "solid",
        borderColor: "lightgray",
        width: '1px',                                                                   // small hack for the vertical layout. cause of splitpane we need to set a width value. If we don't, the flex doesn't shrink back properly
    }
});

class SonarView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: documentControlService.robotDs?.isLoading ? true : false,  // when true, loading data from the robot
            angle1: null,                                                     // the 1st angle that the user selected for measuring a distance on the lidar, in state cause this triggers an update in the calculator
            angle2: null,                                                              // the 2nd angle that the user selected for measuring a distance on the lidar, in state cause this triggers an update in the calculator
            tempEndAngle: null,
        }
        this.canvasRef = React.createRef();
        this.rootRef = React.createRef();
        this.colorDict = null;                                                  // ref to the image data of the hidden canvas, for fastest rendering

        this.inGrayMode = sonarService.grayMode;                            // keep local ref, so we can see changes

    }

    componentDidMount() {
        if (this.props.showSonar) {
            sonarService.events.on('onSonarData', this.handleSonarData);
            sonarService.events.on('onSonarConfig', this.handleSonarConfig);
            strandPropsService.events.on('size', this.strandSizeChanged);
        }
        if (this.props.showLidar) {
            lidarService.events.on('onLidarData', this.handleLidarData);
        }
        documentControlService.events.addListener('onRobotDataLoaded', this.handleRobotReady);
        documentControlService.events.addListener('onRobotDataLoading', this.handleRobotLoading);
        this.fillValueColorConvertor();
        this.handleResize();
    }

    componentWillUnmount() {
        documentControlService.events.removeListener('onRobotDataLoaded', this.handleRobotReady);
        documentControlService.events.removeListener('onRobotDataLoading', this.handleRobotLoading);
        if (this.props.showSonar) {
            sonarService.events.removeListener('onSonarData', this.handleSonarData);
            sonarService.events.removeListener('onSonarConfig', this.handleSonarConfig);
            strandPropsService.events.removeListener('size', this.strandSizeChanged);
        }
        if (this.props.showLidar) {
            lidarService.events.removeListener('onLidarData', this.handleLidarData);
        }
    }

    fillValueColorConvertor() {
        this.colorDict = buildValueColorConvertor(this.inGrayMode);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.metersInDiameter !== this.props.metersInDiameter) {
            this.draw();
        }
        if (prevProps.showMeasDistCrossSection !== this.props.showMeasDistCrossSection) {
            this.setState({angle1: null, angle2: null, tempEndAngle: null});
            this.draw();
        }
        if (prevProps.showLidar != this.props.showLidar) {
            if (this.props.showLidar) {
                lidarService.events.on('onLidarData', this.handleLidarData);
            }
            else {
                lidarService.events.removeListener('onLidarData', this.handleLidarData);
            }
        }
        if (prevProps.showSonar != this.props.showSonar) {
            if (this.props.showSonar) {
                sonarService.events.on('onSonarData', this.handleSonarData);
                sonarService.events.on('onSonarConfig', this.handleSonarConfig);
                strandPropsService.events.on('size', this.strandSizeChanged);
            }
            else {
                sonarService.events.removeListener('onSonarData', this.handleSonarData);
                sonarService.events.removeListener('onSonarConfig', this.handleSonarConfig);
                strandPropsService.events.removeListener('size', this.strandSizeChanged);
            }
        }
        if (prevProps.allowedHeight != this.props.allowedHeight) {
            this.handleResize();
        }
        if (prevProps.verSplit != this.props.verSplit || this.props.resizeRequest !== prevProps.resizeRequest) {
            this.handleResize();
        }
        if (prevState.tempEndAngle !== this.state.tempEndAngle || prevState.angle1 !== this.state.angle1 || prevState.angle2 !== this.state.angle2) {
            this.draw();
        }
    }

    render() {
        const { t } = this.props;

        if (this.state.isLoading) {
            return(
                <div className={this.props.classes.root} style={{justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress color="secondary" />
                    <div>{t("Loading")}</div>
                </div>
            );
        }

        let style = null;
        if (this.props.gridArea) {                          // in case of multiple sonars, we lay them out in a grid
            style ={gridArea: this.props.gridArea}
        }
        const calculators = [];
        this.renderDeformationCalculator(calculators);
        this.renderMeasureDistance(calculators);
        return (
            <div className={this.props.classes.root} ref={this.rootRef} style={style}>
                <canvas ref={this.canvasRef} onClick={this.handleLidarClick} onMouseMove={this.handleLidarMouseMove}/>
                {(calculators.length > 0) &&  
                    <div style={{position: 'absolute', top: '16px', right: '16px'}}>
                        {calculators}
                    </div>
                }
            </div>
        );
    }

    renderDeformationCalculator(calculators) {
        const isFreeForm = this.props.measureData?.type === "freeform";
        if (this.props.showDeformationChanged || isFreeForm) {
            calculators.push(<DeformationCalculator key={calculators.length} showAccept={isFreeForm} onAccept={this.props.onMeasured}/>);
        }
    }

    renderMeasureDistance(calculators) {
        const isMeasuring = this.props.measureData?.type === "measure";
        if (this.props.showMeasDistCrossSection || isMeasuring) {
            calculators.push(<LidarDistCrossSectionCalculator key={calculators.length} showAccept={isMeasuring} onAccept={this.props.onMeasured} angle1={this.state.angle1} angle2={this.state.angle2 || this.state.tempEndAngle}/>);
        }
    }

    handleLidarClick = (ev) => {
        const isMeasuring = this.props.measureData?.type === "measure";
        if (this.props.showMeasDistCrossSection || isMeasuring) {
            const pos = this.getRelMousePos(ev);
            let angle = Math.atan2( pos.y, pos.x ) * ( 180 / Math.PI);
            if (angle < 0) {
                angle = 360 + angle;
            }
            if (this.state.angle1 === null && this.state.angle2 === null) {
                this.setState({angle1: angle});                                                 // first time: first angle
            }
            else if (this.state.angle2 === null) {
                this.setState({angle2: angle});                                                 //second time, second angle
            }
            else {
                this.setState({angle1: angle, angle2:  null});                                   // third time, reset and store as first angle
            }
            //this.draw();
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    /**
     * when the first angle has been selected on the lidar, the second angle is shown automatically when
     * moving around, untill the selection is made.
     * @param {object} ev event
     */
    handleLidarMouseMove = (ev) => {
        const isMeasuring = this.props.measureData?.type === "measure";
        if (this.state.angle1 !== null && this.state.angle2 === null && (this.props.showMeasDistCrossSection || isMeasuring)) {
            const pos = this.getRelMousePos(ev);
            let angle = Math.atan2( pos.y, pos.x ) * ( 180 / Math.PI);
            if (angle < 0) {
                angle = 360 + angle;
            }
            this.setState({tempEndAngle: angle});
            //this.draw();
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    /**
     * calculate the mouse position relative to the canvas.
     * @param {object} ev mouse event data
     */
     getRelMousePos(ev) {
        const canvas = this.canvasRef.current;
        if (!canvas) throw new Error("Internal error: no canvas");
        let rect  = this.canvasRef.current.getBoundingClientRect();
        let result = { x: ev.clientX - rect.left, y: ev.clientY - rect.top };
        result.x -= canvas.offsetWidth / 2;
        result.y = (canvas.offsetHeight - result.y) - (canvas.offsetHeight / 2);
        return result;
    }

    handleRobotReady = () => {
        this.setState({isLoading: false});
        this.handleResize();                                                // so we are certain taht the size is als set correctly, if we odn't do this, things can be screwed up a little at first load
    }

    handleRobotLoading = () => {
        this.setState({isLoading: true});
    }

    draw() {
        const canvas = this.canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            const maxSize = canvas.width > canvas.height ? canvas.width : canvas.height;
            const {tick, scale} = getIdealScaleSize(maxSize, this.props.metersInDiameter);                             // nr of meters distance between tick circles
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (this.props.showSonar) {                                                     // draw sonar first, otherwise we can't see the lines and numbers sometimes
                drawSonar(ctx, canvas.offsetWidth, canvas.offsetHeight, scale, tick, this.colorDict.data, sonarService);
            }
            drawTicks(ctx, canvas.offsetWidth, canvas.offsetHeight, scale, tick)
            if (this.props.showLidar) {
                drawLidar(ctx, canvas.offsetWidth, canvas.offsetHeight, scale, tick, lidarService);
            }
            if (this.props.showMeasDistCrossSection && this.state.angle1 !== null) {
                const angles = [this.state.angle1];
                if (this.state.angle2 !== null) {
                    angles.push(this.state.angle2);
                }
                else if (this.state.tempEndAngle) {
                    angles.push(this.state.tempEndAngle);
                }
                drawLidarDistanceTriangle(ctx, canvas.offsetWidth, canvas.offsetHeight, scale, tick, lidarService, angles);
            }
        }
    }

    /**
     * when the size of the strand changes, need to redraw the image. normally don't need an extra event for this for
     * live data, but we do need to trigger an update for data that has been recorded already, which doesn't redraw periodically
     */
    strandSizeChanged = () => {
        this.draw();
    }

    handleSonarData = () => {
        this.draw();
    }

    handleSonarConfig = () => {
        if (this.inGrayMode !== sonarService.grayMode) {
            this.inGrayMode = sonarService.grayMode;
            this.fillValueColorConvertor();                     // could have changed from color to graymode
        }
        this.draw();
    }

    handleLidarData = () => {
        this.draw();
    }

    handleResize() {
        const canvas = this.canvasRef.current;
        const root = this.rootRef.current;
        if (canvas && root) {
            canvas.width = root.clientWidth;
            canvas.height = this.props.allowedHeight - 2;  //-2 for the border
            this.draw();
        }
    }

}


SonarView.defaultProps = {
    metersInDiameter: 1,
    showSonar: true,
    showLidar: false,
}

SonarView.propTypes = {
    metersInDiameter: PropTypes.number,         // nr of meters visible on the screen. this determins the zoom value
    onToggleDashboard: PropTypes.func,          // called when user wants to show the dashboard
    dashboardOpen: PropTypes.bool,              // when true, the dashboard is open and no button should be shown to open it
    showSonar: PropTypes.bool,                  // when true, sonar data is shown
    showLidar: PropTypes.bool,                  // when true, lidar data is shown
    allowedHeight: PropTypes.number,            // for faster rescaling of the canvas. otherwise we need to measure the current size, which gives delays and multiple recalculations
    gridArea: PropTypes.string,                 // optional name of the grid area to place this view in
    verSplit: PropTypes.number,                 // to force a repaint when resized
    resizeRequest: PropTypes.bool,              // to force a repaint when switching between readonly and not. This is actually a trigger for the dashboard to resize, which can cause changes in this size. 
    measureData: PropTypes.any,                 // when lidar is available, we can perform measurements with that sensor, so we need to respond to measument requests
    onMeasured: PropTypes.func,                             // called when measurement is done on the lidar image
    showDeformationChanged: PropTypes.bool,     // when true, freeform deformation value is calculated and shown for the lidar data.
    showMeasDistCrossSection: PropTypes.bool,   // wwhen true, the cross-section lidar will allow/show measuring a distance between 2 points on the wall
};

export default withTranslation()(withStyles(styles)(SonarView));