import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Copyright } from '../start/copyright_component';
import { authService } from '../services/auth_service';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { errExtractor } from '../services/error_extractor';
import Button from '@material-ui/core/Button';
import { dialogService } from '../../services/dialog_service';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


//icons
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { configService } from '../../services/config_service';
import { userService } from '../services/user_service';
import { API_URL, API_SERVICES, GOOGLE_API_KEY, setServerConfig } from '../services/server_config_service';


const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        height: '50px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cred: {
        textAlign: "center",
        fontWeight: 300
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});




class Login extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isLoading: false,
            service: API_URL
        }
    }


    render() {
        const { t } = this.props;
        return (
            <Container maxWidth="xs">
                <div className={this.props.classes.paper}>
                    <img src="https://storage.googleapis.com/cami_exec/name_logo.png"  alt="logo" 
                        className={this.props.classes.avatar}/>
                    <Typography variant="h6">
                        {t('Sign in')}
                    </Typography>
                    <form className={this.props.classes.form} noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("Email address")}
                            name="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={(ev) => this.setState({email: ev.target.value})}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("Password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={this.state.password}
                            onChange={(ev) => this.setState({password: ev.target.value})}
                        />
                        {(configService.isSite === false && configService.isViewer) &&
                            <TextField
                                required={true}
                                select
                                fullWidth
                                id="selectService"
                                label="Service"
                                InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                                value={this.state.service}
                                onChange={this.handleServiceChanged}
                                className={this.props.classes.cmbField}
                                InputProps={{classes:{disabled: this.props.classes.readOnly}}}
                                SelectProps={{
                                    native: true
                                }}
                                margin="dense"
                            >
                                {API_SERVICES.map((val, idx) => {
                                    return(
                                        <option key={idx} value={val.url}>{val.label}</option>
                                    );
                                })}
                            </TextField>
                        }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={this.props.classes.submit}
                        >
                            {t('Sign in')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/reset" 
                                    variant="body2">
                                    {t('Forgot password?')}
                                </Link>
                            </Grid>
                            <Grid item>
                                {/* <Link component={RouterLink} to="/register" variant="body2">
                                    {t("Don't have an account? Sign Up")}
                                </Link> */}
                            </Grid>
                        </Grid>
                    </form>
                </div>
                
                
                <Box mt={8}>
                    {Copyright(t)}
                </Box>
                <Backdrop className={this.props.classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }

    handleSubmit = async (ev) => {
        ev.preventDefault();
        try {
            this.setState({isLoading: true});
            const user = await authService.login(this.state.email, this.state.password);
            await userService.verifyUserInvitation(user);
            let dest = "/home";
            if (this.props.location && this.props.location.state && this.props.location.state.from)  {
                dest = this.props.location.state.from.pathname;
            }
            this.setState({isLoading: false});
            this.props.history.push(dest);
        }
        catch(error) {
            this.setState({isLoading: false});
            const { t } = this.props; 
            dialogService.error(t("Sign in"), t('login_failed_with_error', {error: errExtractor.get(error)}));
        }
    }

    handleServiceChanged = (ev) => {
        try {
            const newValue = ev.target.value;
            const idx = API_SERVICES.findIndex((rec) => rec.url === newValue);
            this.setState({service: newValue});
            setServerConfig(newValue, API_SERVICES[idx].googleKey);
            configService.set("api.url", newValue);
            configService.set("api.google", GOOGLE_API_KEY);
        }
        catch(error) {
            const { t } = this.props;
            dialogService.error(t("Sign in"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

}

export default withTranslation()(withStyles(styles)(Login));