/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { dialogService } from '../../../services/dialog_service';


/**
 * UI element for managing the currently active user: config, logout..
 */
class VideoSectionBase extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    getScreenSize() {
        return {width: window.screen.width, height: window.screen.height};
    }

    /**
     * sets the specified video as the video for the streng.
     * The file is copied to the project locatin with the correct name
     * to be the video for the current streng.
     * @param {string} filename name of the file to import
     */
    async importVideoFile(filename) {
        dialogService.error("Not yet supported");
    }
}

export default VideoSectionBase;