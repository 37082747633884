
/**
 * builds an Image object that draws the svg image
 * @param {string} source source svg
 * @param {func} onLoad called when the image is loaded
 */
 export function createImgObj(width, height, source, onLoad) {
    //let blob = new Blob([source], {type: 'image/svg+xml'});
    //let url = URL.createObjectURL(blob);
    let img = new Image();
    img.width = width;
    img.height = height;
    img.onload = onLoad;
    img.src = `data:image/svg+xml;utf8,${source}`;
    return img;
}