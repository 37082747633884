/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

// icons
import Pencil from 'mdi-material-ui/Pencil';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { SwatchesPicker } from 'react-color';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LidarRangeConfigDlg from './config_lidar_range_dlg';

import i18n from "i18next";
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from "../../../../services/dialog_service";
import { configDefaults } from '../../../services/config_settings';
import { strandPropsService } from '../../../services/strand_props_service';


const styles = (theme) => ({
    root: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        height: '350px',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    lastBox: {
        marginRight: theme.spacing(1),
    },
    value: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    slider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    icon: {
        padding: '0px'
    },
    title: {
        fontWeight: 'bold',
    }
});

function filterToStr(value) {
    if (value !== null && value !== undefined) {
        return Math.round( (value * 1000) - (strandPropsService.maxSize/2));             // subsrtact maxSize/2 to get the min/max deviation from the center
    }
    else {
        return '-';
    }
}


/**
 * UI element for managing the currently active user: config, logout..
 */
class LidarRolloutConfigPopup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            colorPickerAnchor: null,
            editColorIdx: -1,
            showRangeEdit: false,
            rangeEditIdx: -1,
            maxFilterRange: props.maxFilterRange,                               // take a local copy so we can modify it locally
        }
        this.grayModeTxt = i18n.t('Gray mode');
        this.rangeFilterTimer = null;                                           // so we can put some delay on the update
        this.nextRangeFilterValue = null;                                       // the next value to send when the timer is done
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.maxFilterRange !== this.props.maxFilterRange) {
            this.setState({maxFilterRange: this.props.maxFilterRange});
        }
    }

    render() {
        const { classes, t } = this.props;

        let gradients;
        if (this.props.grayMode) {
            gradients = 'linear-gradient(#000000 0%, #ffffff 100%)';
        }
        else {
            gradients = this.props.colors.map((color, idx) => {
                return `${color} ${this.props.colorStops[idx] * 100}%`
            });
            gradients = 'linear-gradient(to top, ' + gradients.join(', ') + ')';
        }
        
        let maxFilter0 = filterToStr(this.state.maxFilterRange[0]);
        let maxFilter1 = filterToStr(this.state.maxFilterRange[1]);
        let maxFilters = [maxFilter0, maxFilter1];
        let filter0 = filterToStr(this.props.filterRange[0]);
        let filter1 = filterToStr(this.props.filterRange[1]);
    
        return (
            <Popover anchorEl={this.props.anchor}
                open={Boolean(this.props.anchor)}
                transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                onClose={this.props.onClose}>
                <div className={classes.root}>
                    <div className={classes.row}>
                        <div className={classes.box}>
                            <div className={classes.title}>{t("Range filter")}</div>
                            <div className={classes.value}>{filter1}mm : {filter0}mm</div>
                            <div className={classes.slider} style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                                    <div style={{marginTop: '-8px', display: "flex", flexDirection: 'row'}}>
                                        <Tooltip title={t("Change the max distance")}>
                                            <div style={{marginRight: '8px'}}>
                                                <IconButton aria-label="more"
                                                    size="small"
                                                    onClick={this.handleEditRange(1)}
                                                    className={this.props.classes.icon}
                                                >
                                                    <Pencil fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        {maxFilter1}mm
                                    </div>
                                    <div>0mm</div>
                                    <div style={{marginBottom: '-8px', display: "flex", flexDirection: 'row'}}>
                                        <Tooltip title={t("Change the min distance")}>
                                            <div  style={{marginRight: '8px'}}>
                                                <IconButton aria-label="more"
                                                    size="small"
                                                    onClick={this.handleEditRange(0)}
                                                    className={this.props.classes.icon}
                                                >
                                                    <Pencil fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        {maxFilter0}mm
                                    </div>
                                </div>
                                <Slider
                                    orientation="vertical"
                                    value={this.props.filterRange}
                                    onChange={this.handleRangeFilterChanged}
                                    max={this.state.maxFilterRange[1]}
                                    min={this.state.maxFilterRange[0]}
                                    step={0.01}
                                />
                            </div>
                        </div>

                        <div className={classes.box}>
                            <div style={{width: '24px', flex: 1, border: '1px solid gray', background: gradients, marginTop: '60px'}}/>
                        </div>

                        <div className={`${classes.lastBox} ${classes.box}`}>
                            <div className={classes.title}>{t("Colors")}</div>
                            <Button color="secondary" onClick={this.handleReset}>Reset</Button>
                            <div className={classes.slider} style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', flex: 1}}>
                                <Slider
                                    orientation="vertical"
                                    track={false}
                                    value={this.props.colorStops}
                                    onChange={this.props.onColorStopsChanged}
                                    max={1.0}
                                    min={0.0}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={(value) => {return(`${(value*100).toFixed(0)}%`)}}
                                    step={0.01}
                                />
                                <div style={{display: 'block', position: 'relative'}}>
                                    {this.props.colors.map((color, idx) => {
                                        return(
                                            <div key={idx} style={{width: '24px', height: '16px', borderRadius: '4px', backgroundColor: color, position: 'absolute', left: '0px', bottom: `calc(${this.props.colorStops[idx]*100}% - 8px)`, border: '1px solid gray'}}
                                                onClick={this.handleStartEditColor(idx)}
                                            />
                                        )
                                    })}    
                                </div>
                            </div>
                            <Popover
                                open={this.state.colorPickerAnchor != null}
                                anchorEl={this.state.colorPickerAnchor}
                                onClose={this.handleCloseColorPicker}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <SwatchesPicker onChange={ this.handlePickColor } color={ this.props.colors[this.state.editColorIdx] }/>
                            </Popover>
                        </div>
                    </div>
                    <FormControlLabel
                        style={{alignSelf: 'flex-start'}}
                        control={
                            <Checkbox
                                checked={this.props.grayMode}
                                onChange={this.props.onGrayModeChanged}
                                name="grayMode"
                                color="primary"
                            />
                        }
                        label={this.grayModeTxt}
                    />

                    
                    {(this.state.showRangeEdit) &&
                        <LidarRangeConfigDlg open={this.state.showRangeEdit} 
                            value={maxFilters[this.state.rangeEditIdx]}
                            onClose={this.closeRangeEdit}
                        />
                    }
                </div>
            </Popover>
        );
    }

    handleRangeFilterChanged = (event, value) => {
        if (!this.rangeFilterTimer) {
            if (this.props.onFilterRangeChanged) {
                this.props.onFilterRangeChanged(value);
            }
            this.rangeFilterTimer = setTimeout(()=> {
                this.rangeFilterTimer = null;
                if (this.nextRangeFilterValue) {
                    if (this.props.onFilterRangeChanged) {
                        this.props.onFilterRangeChanged(this.nextRangeFilterValue);
                    }
                    this.nextRangeFilterValue = null;
                }
            }, 250);
        }
        else {
            this.nextRangeFilterValue = value;
        }
    }

    handleStartEditColor = (colorIdx) => (ev) => {
        this.setState({colorPickerAnchor: ev.currentTarget, editColorIdx: colorIdx});
    }

    handleCloseColorPicker = () => {
        this.setState({colorPickerAnchor: null});
    }

    handlePickColor = (color) => {
        if (this.state.editColorIdx > -1 && this.state.editColorIdx < this.props.colors.length) {
            if (this.props.onColorsChanged) {
                let newColors = [...this.props.colors];
                newColors[this.state.editColorIdx] = color.hex;
                this.props.onColorsChanged(newColors);
            }
        }
        this.setState({colorPickerAnchor: null});
    }

    handleReset = () => {
        if (this.props.onColorsChanged) {
            this.props.onColorsChanged(configDefaults.layout.document.robotSection.parts[1].lidarRoll.colors);
        }

        if (this.props.onColorStopsChanged) {
            this.props.onColorStopsChanged(null, configDefaults.layout.document.robotSection.parts[1].lidarRoll.colorStops);
        }
    }

    handleEditRange = (idx) => () => {
        this.setState({showRangeEdit: true, rangeEditIdx: idx});
    }

    closeRangeEdit = (value) => { 
        if(value != undefined) {
            const newVal = [...this.state.maxFilterRange];
            newVal[this.state.rangeEditIdx] = (+value + (strandPropsService.maxSize/2)) / 1000;         // need to convert back from relative-to-center to absolute distances
            this.setState({maxFilterRange: newVal});

            let newFilterRange = null;
            if (newVal[0] > this.props.filterRange[0]) {
                newFilterRange = [...this.props.filterRange];
                newFilterRange[0] = newVal[0];
            }
            if (newVal[1] < this.props.filterRange[1]) {
                if (!newFilterRange) {
                    newFilterRange = [...this.props.filterRange];
                }
                newFilterRange[1] = newVal[1];
            }
            if (newFilterRange && this.props.onFilterRangeChanged) {
                this.props.onFilterRangeChanged(newFilterRange);
            }
        }
        this.setState({showRangeEdit: false, rangeEditIdx: -1});
    }
}

LidarRolloutConfigPopup.propTypes = {
    anchor: PropTypes.any,                      // locatio for the popup
    onClose: PropTypes.func,

    grayMode: PropTypes.bool,                   // when true, don't use colors
    onGrayModeChanged: PropTypes.func,
    filterRange: PropTypes.array,               // [low, high] cutoff values
    maxFilterRange: PropTypes.array,            // [low, high] max values for cutoff
    onFilterRangeChanged: PropTypes.func,
    colors: PropTypes.array,                    // list of stops and the color for each stop
    onColorsChanged: PropTypes.func,
    colorStops: PropTypes.array,                // the % positions of each color, for rendering the color range
    onColorStopsChanged: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(LidarRolloutConfigPopup));
