import { getSelectedLanguage } from "../../services/translation_service";
import { codeSelectorService } from "./code_selector_service";
import { geoTranslate } from "./geo_service";
import { ERROR_KEYS } from "./project_service";

export class Analyzer {


    /**
     * creates a new list of prj-streng objects, so that they can be sorted based on inspection date.
     * this allows the buildmap routine to correctly merge everything in chronological order.
     * @param {list} data list of project objects
     */
    sortForMap(data) {
        let result = [];
        data.forEach((prj) => {
            prj.strengs.forEach((streng, strengIdx) => {
                result.push({prj, streng, strengIdx});
            });
        });
        result.sort((a, b) => {
            if (!a.streng.inspectionDate || !a.streng.inspectionTime || !b.streng.inspectionDate || !b.streng.inspectionTime) { // missing data, so put at end front of list so that they get processed first and overwritten probably
                return 1;
            }
            const aDateParts = a.streng.inspectionDate.split("-");
            const aTimeParts = a.streng.inspectionTime.split(":");
            const aDate = new Date(aDateParts[0], aDateParts[1] - 1, aDateParts[2], aTimeParts[0], aTimeParts[1], aTimeParts[2]);

            const bDateParts = b.streng.inspectionDate.split("-");
            const bTimeParts = b.streng.inspectionTime.split(":");
            const bDate = new Date(bDateParts[0], bDateParts[1] - 1, bDateParts[2], bTimeParts[0], bTimeParts[1], bTimeParts[2]);

            if (aDate > bDate) return 1;                    // it's newer, so put at end of list, older stuff first
            if (bDate < aDate) return -1;
            if (a.prj.id > b.prj.id) return 1;              // when same timestamp, use project order, 
            if (a.prj.id < b.prj.id) return -1;
            return 0;
        });
        return result;
    }

    /**
     * builds a map based on the project data. This map creates records per street-town combo 
     * and records the latest state
     * @param {list} data list of project objects
     */
    buildMap(data) {
        let result = {};
        const sortedData = this.sortForMap(data);                                                   // sort on inspection date first so that we add things in chronological order
        const locCoordinates = {};                                                                // a map between location names and their coordinates, so we can quickly assign this to the new records
        sortedData.forEach((sortedEl) => {
            const {prj, streng, strengIdx} = sortedEl;
            for (let i=0; i < prj.statistics.locations.length; i++) {
                locCoordinates[prj.statistics.locations[i]] = prj.statistics.coordinates[i]
            }
            let errorCodeIdx = prj.main.purpose == "A"? 0: 1;                                   // if new streng, use idx 0, otherwise error idx 1
            const key = `${prj.main.location}-${streng.location}`;
            let record;
            if (!(key in result)) {
                record = {strengs: {}, location: prj.main.location, route: streng.location, projects: [prj]};
                result[key] = record;
            }
            else {
                record = result[key];
                if (record.projects.indexOf(prj) === -1) {
                    record.projects.push(prj);
                }
            }
            const {strKey, isReverse} = this.buildStrengKey(streng.pointRef1, streng.pointRef2);
            let subRec = {
                history:[], 
                date: streng.inspectionDate, 
                errors: [], 
                inventory: [], 
                isReverse: isReverse, 
                prj: prj, 
                expectedInspectionLength: streng.expectedInspectionLength, 
                actualInspectionLength: streng.actualInspectionLength, 
                type: streng.type,
                sewerUsage: streng.sewerUsage,
                ref: streng.ref,
                id: streng.id,
                pointRef1: streng.pointRef1,
                pointRef2: streng.pointRef2,
                pointRef3: streng.pointRef3,
                location: streng.location,
                purpose: prj.main.purpose,                                              // we store new-construction/old-construction at the level of the streng cause we want to see retests on the same steng
                coordinates: locCoordinates[streng.location],                           // so we can quickly show on maps
            };
            if (strKey in record.strengs) {
                const oldSub = record.strengs[strKey];
                if (oldSub.prj === subRec.prj && ((!isReverse && subRec.isReverse === true) || (isReverse && subRec.isReverse === false)) ) {          // need to merge the data. Important check on === true/false -> when deleted it has been merged
                    subRec.history = oldSub.history;
                    subRec.errors = oldSub.errors;
                    subRec.inventory = oldSub.inventory;
                    delete subRec.isReverse;
                    //possibly have to copy over the date.
                }
                else {
                    subRec.history = [oldSub, ...oldSub.history];   
                    delete oldSub.history;
                }
            }
            record.strengs[strKey] = subRec;
            streng.observations.forEach((obs, obsIdx) => {
                if (obs.values[obs.values.length-1] !== "1") {                          // if the last item in the key is 1, it's a terminator for continuous-observations, so skip that
                    let obsKey = obs.values.join("-");
                    let found = ERROR_KEYS.find((value) => obsKey.startsWith(value));            // find the first item in the list that's the same as the start of the key
                    const def = codeSelectorService.getLeaf(obs.values, streng.type);
                    if (found && def && def.errRating && def.errRating[errorCodeIdx]) {                                // found a problem observation
                        subRec.errors.push({obs: obs, obsIdx: obsIdx, strengIdx: strengIdx});
                    }
                    else {
                        subRec.inventory.push({obs: obs, obsIdx: obsIdx, strengIdx: strengIdx});
                    }
                }
            });
        });
        for (const key in result) {
            const rec = result[key];
            rec['maxErr'] = Math.max(...Object.values(rec.strengs).map(el => el.errors.length));
        }
        return result;
    }


    /**
     * extracts the number out of the strings and builds a key (string) where
     * the string with smallest number is placed first.
     * ex, ref1 = ab12c, ref2 = cd8d, out = cd8d-ab12c
     * 
     * @param {string} ref1 string 1
     * @param {strign} ref2 string 2
     */
    buildStrengKey(ref1, ref2) {
        let nr1;
        let nr2;
        let matches = ref1.match(/(\d+)/);
        if (matches) {
            nr1 = +matches[0];
        }
        matches = ref2.match(/(\d+)/);
        if (matches) {
            nr2 = +matches[0];
        }
        if (nr1 < nr2) {
            return {strKey: `${ref1}-${ref2}`, isReverse: false};
        }
        else {
            return {strKey: `${ref2}-${ref1}`, isReverse: true};
        }
    }

    /**
     * 
     * @param {object} location the original search definition as provided by the user, in his language.
     */
    async _getLocationToSearcFor(location, language=null) {
        let region;
        let route = null;
        let generic = null;
        if (language !== null && language !== getSelectedLanguage()) {
            location = await geoTranslate(location, language);
        }
        if (location) {
            if (location.city) {
                region = location.city;
                if (location.route) {
                    route = location.route;
                }
            }
            else if (location.province) {
                region = location.province;
            }
            else if (location.state) {
                region = location.state;
            }
            else if (location.country) {
                region = location.country;
            }
            else if (location.generic) {
                generic = location.generic;
            }
            else {
                throw new Error ("invalid search criteria");
            }
        }
        else {
            throw new Error ("invalid search criteria");
        }
        return {region, route, generic};
    }   

    /**
     * filters out all the documents that match the criteria and makes certain that 
     * statistics are filled in for all the docs.
     * @param {list} docs all the docs
     * @param {object} location specifies locality or region and possibly route
     */
    async filter(docs, location) {
        let search = {};
        let curLan = getSelectedLanguage();
        search[curLan] = await this._getLocationToSearcFor(location);

        let result = [];

        for(let docIdx=0; docIdx < docs.length; docIdx++) {         // important: use regular loop instead of callback. with callback we block at the await for all items before resolving the first getGeo call, which is bad
            //docs.forEach(async (doc) => {                         -> very very bad, dont do this
            const doc = docs[docIdx];
            let localSearch = search[curLan];
            if (!doc.statistics) {                                  // this shouldn't happen, only for none published docs, taht don't need to show up
                continue;
            }
            if (doc.statistics.language) {
                if (!(doc.statistics.language in search)) {
                    search[doc.statistics.language] = await this._getLocationToSearcFor(location, doc.statistics.language);
                }
                localSearch = search[doc.statistics.language];
            }
            let {region, route, generic} = localSearch;

            if (route) {
                if (doc.statistics.locations) {                        // should be pre-calculated
                    if (doc.main.location.toLowerCase() === region.toLowerCase() && doc.statistics.locations.includes(route)) {
                        result.push(doc);
                    }
                }
            }
            else if (region) {
                if (doc.statistics.regions && doc.statistics.regions.includes(region)) {        // regions is provided by google, region as well, so capitailztaion is the same
                    result.push(doc);
                }
            }
            else if (generic) {
                if ((doc.statistics.regions && doc.statistics.regions.includes(generic)) || doc.statistics.locations.includes(generic)) {
                    result.push(doc);
                }
            }
        }
        return result;
    }
}