import { CHANGE_SHAPE_KEY } from './project_service';

const events = require('events');

/**
 * keeps track of the current properties of the strand such as it's width.
 * Properties of a strand can change during the inspection, for instance the diameter of the strand.
 * Other parts of the system can be dependent of these values, for instance, the sonar needs to know
 * the diameter so that it can adjust it's scanning range.  
 * 
 * currently only for live-data, not for playback of recorded data
 */
 export class StrandPropsService {
    constructor() {
        this._width = 0;
        this._height = 0;
        this._shape = null;
        this.sizeIsLocked = false;                      // when set from running, will no longer trigger events or store value when root props are updated
        this.shapeIsLocked = false;
        this.lastPlayIdx = -1;                           // when playing data, updateFromObsList gets called often, this field keeps track of the last obs that was checked. is reset when the streng is changed or when the new current time is smaller than that of the last played obs
        this.events = new events.EventEmitter();
    }

    /**
     * called when a new/different strand has become active. allows us to init the object properly
     * and allow other to react to any possible changes.    
     * @param {object} streng the strand that has become active
     */
    init(streng) {
        this.sizeIsLocked = false;
        this.shapeIsLocked = false;
        this.lastPlayIdx = -1;
        let changed = +streng.Width !== this._width || +streng.height !== this._height;
        if (changed) {
            this._width = +streng.Width;
            this._height = +streng.height;
            this.events.emit('size');
        }
        this._shape = streng.shape;
    }

    /**
     * called by documentControlService whenever the time has changed during play back or when in pause (but not recording)
     * note: it can be presumed that this function will be called consecutively with the same list for as long as init is not called.
     * so we can optimize and keep track of the last seen observation.
     * Also used for rendering the images in reports
     * @param {array} list list of observations
     * @param {number} time current time
     */
    updateFromObsList(streng, time) {
        if (streng) {                                                       // while resetting everything, this can happen
            const list = streng.observations;
            let newIdx = this.lastPlayIdx + 1;
            let obs = list[this.lastPlayIdx];
            if (obs && obs.time > time) {                                  // we went back, so start from the beginning
                this.init(streng);                                          // need to do a full init to make certain that the values are reset
                newIdx = 0;
            }
            obs = list[newIdx];
            while (newIdx < list.length && obs.time < time) {
                this.updateFromObs(obs);
                this.lastPlayIdx = newIdx;
                obs = list[++newIdx];
            }
        }   
    }

    /**
     * 
     * @param {object} obs the observation that cntains the data for the update
     * @param {bool} fromPlay when true, called because of a play action, when false, called because of an edit
     */ 
    updateFromObs(obs, fromPlay=true) {
        const key = obs.values.join('-');
        if(key.startsWith(CHANGE_SHAPE_KEY)) {
            this._shape = obs.values[obs.values.length-1];
            this.setSizeFromObs(+obs.waarde1, +obs.waarde1, obs, fromPlay);
        }
    }

    get shape() {
        return this._shape;
    }
    set shape(value) {
        if (value === "") value = "A";
        if (value != this._shape && !this.shapeIsLocked) { 
            this._shape = value;
            this.events.emit('size');
        }
        this.events.emit('sizeEdited');
    }

    set width(value) {
        if (value === "") value = 0;
        if (value != this._width && !this.sizeIsLocked) { 
            this._width = +value;
            this.events.emit('size');
        }
        this.events.emit('sizeEdited');
    }
    get width() {
        return this._width;
    }

    set height(value) {
        if (value === "") value = 0;
        if (value != this._height && !this.sizeIsLocked) {
            this._height = +value;
            this.events.emit('size');
        }
        this.events.emit('sizeEdited');
    }
    get height() {
        return this._height;
    }  

    get maxSize() {
        return this._width > this._height ? this._width : this._height;
    }

    /**
     * always stored, when called, locks the value
     */
    setSizeFromObs(width, height, obs, fromPlay=false) {
        this._width = width;
        this._height = height;
        this.sizeIsLocked = true;
        this.events.emit('size', obs);
        if (!fromPlay) {                                    // when not from play, the change is becauseof an edit, so extra event for observation only interested in edits
            this.events.emit('sizeEdited');
        }
    }
}

export const strandPropsService = new StrandPropsService();