/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';



const styles = (theme) => ({
    root: {
    },

});


class ReportDlg extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    
    render() {
        const { t } = this.props;
        let content;
       
        const stopTxt = this.state.isRunning ? t("Annuleren") : t("Sluiten");
        return (
            <Dialog open={this.props.open} 
                onClose={this.handleClose} 
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm">
                <DialogTitle id="form-dialog-title">{t("Rapport")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        "Not supported"
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} 
                        color="primary">
                        {t("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    /**
     * need to handle the 'esc' key. should react the same as cancel button
     */
    handleClose = (event, reason) => {
        this.props.onClose();
    }

}

ReportDlg.propTypes = {
    open: PropTypes.bool,                          // to open/close the dialog
    onClose: PropTypes.func,                       // called when the component requests to be closed (in control of owner)
};

export default withTranslation()(withStyles(styles)(ReportDlg));