/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//img 
import PlaylistStar from 'mdi-material-ui/PlaylistStar';
import Earth from 'mdi-material-ui/Earth';
import CrosshairsGps from 'mdi-material-ui/CrosshairsGps';
import FileDownload from 'mdi-material-ui/FileDownload';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { cloudDocsService } from '../../services/cloud_documents_service';
import { dialogService } from '../../../services/dialog_service';
import { errExtractor } from '../../services/error_extractor';
import Paywall from '../../overlays/paywall_component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { storageService } from '../../../services/storage_service';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { GoogleAutoComplete } from './google_auto_complete';
import { GOOGLE_API_KEY } from '../../services/server_config_service';
import { extractDataFromGoogleGeoLocation, getCurrentGeoLocation } from '../../services/geo_service';
import { Analyzer } from '../../services/analysis';
import StrengsSummary from '../../document/inspection/strengs/strengs_summary_component';
import StrengsSummaryDialog from '../../document/inspection/strengs/strengs_summary_dialog';
import ObservationImageDialog from '../../document/inspection/observation/observation_image_dialog';
import MapDialog from '../../maps/map_dialog';
import { oldBuildReportFotoFilename } from '../../services/filenames_service';
import ARDialog from '../../../components/ar/ar_dialog';
import { configService } from '../../../services/config_service';
import { downloadUrl } from '../../../shared_components/services/download_service';
import { projectsToCsv } from '../../services/convertor_service';

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "row",
        height: '100%'
    },
    list: {
        overflow: 'auto',
        flex: 1,
        minWidth: '300px'
    },
    listHeader: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        color: theme.palette.text.primary,
        height: '65px'                                      // to let the header show at the same height as the 'newProjects' part of the dashboard
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    selectAll: {
        marginRight: theme.spacing(2)
    },
    autoComplete: {
        borderWidth: '0px',
        fontSize: "18px",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: '100%',
        textAlign: 'center'
    }
});


class Search extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            loaded: true,
            selectedIdx: -1,                                            // currently selected project
            selectedProject: null,                                      // the full object of the selected project (not the same as the object found in 'projects')
            innerWidth: window.innerWidth,                              // for responsive behaviour
            searchValue: null,                                          // the current search value
            detailsDlgOpen: false,                                      // show details
            showMap: false,                                             // show the google map
            strengs: [],                                                // the strengs in a list instead of dict for the currently selected project
            strengDetailsTip: null,                                 // when set, a popup should open some details of the obs, like the image
            strengDetailsUrl: null,                                 // when set, show popup with image
            ARData: null,                                               // the data for showing in an AR screen
            ARShowInventory: false,                                     // determins which part of the data-set the ar will show: errors or inventory
        }
        this.data = null;                                               // all the data that the current user can see, so we can switch faster (not optimal, but doable for now when smaller data sets are still happening)
        this.searchAfterDataLoaded = null;                              // if we need to do a search after the data has been loaded, this field contains the parameter for 'searchData
    }

    /**
     * load data here, constructor gets called multiple times, causing data to be loaded multiple times
     */
    async componentDidMount() {
        try {
            window.addEventListener('resize', this.updateDimensions);
            this.data = await cloudDocsService.getDocs("heatmap");
            if (this.searchAfterDataLoaded) {
                this.performSearch(this.searchAfterDataLoaded);
                this.searchAfterDataLoaded = null;
            }
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Search"), t("docs_load_failed", { error: errExtractor.get(error) }));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    /**
     * called when the window is resized. Used to trigger responsive behaviour.
     */
    updateDimensions = () => {
        const width = window.innerWidth;
        this.setState({ innerWidth: width });
    }


    render() {
        const { t, classes } = this.props;
        let selected = this.state.selectedProject;
        let rightSide = null;
        const wideScreen = this.state.innerWidth > this.props.theme.breakpoints.values.sm;
        if (wideScreen) {
            rightSide = this.buildRightSide(selected);
        }
        const leftSide = this.buildleftPart(wideScreen);
        const strengs = this.state.strengs;
        return (
            <Paper className={classes.paper}>
                {leftSide}
                {rightSide}
                {(selected && this.state.detailsDlgOpen) &&
                    <StrengsSummaryDialog open={this.state.detailsDlgOpen}
                        main={selected}
                        strengs={strengs}
                        onClickObs={this.handleShowObsDetails}
                        onShowAR={this.handleShowAR}
                        onClose={this.handleCloseStrengSummary} />
                }
                <ObservationImageDialog open={!!this.state.strengDetailsTip || !!this.state.strengDetailsUrl}
                    image={this.state.strengDetailsUrl}
                    label={this.state.strengDetailsTip}
                    onClose={this.handleCloseObsDetails} />
                <MapDialog open={this.state.showMap}
                    data={this.state.projects}
                    onClose={() => this.setState({ showMap: false })} />
                {(configService.isSite) &&
                    <ARDialog open={!!this.state.ARData}
                        data={this.state.ARData}
                        showInventory={this.state.ARShowInventory}
                        onClose={() => this.setState({ ARData: null })} />
                }
                <Backdrop className={classes.backdrop} open={!this.state.loaded}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Paper>
        );
    }

    /**
     * builds the right view
     * @param {object} selected the selected project   
     */
    buildRightSide(selected) {
        const { t, classes } = this.props;
        const strengs = this.state.strengs;
        if (selected) {
            return (<Box style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
                <StrengsSummary main={selected}
                    strengs={strengs}
                    onShowAR={this.handleShowAR}
                    onClickObs={this.handleShowObsDetails}
                    onClickStreng={this.handleOpenPrj}
                />
            </Box>
            );
        }
        else {
            return (<Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Typography variant="h6">{t("No project selected")}</Typography>
            </Box>
            );
        }
    }

    buildleftPart(wideScreen) {
        const { t, classes } = this.props;
        let paywall = null;
        let listStyle = null;
        //paywall = <Paywall marginTop={80}/>
        //listStyle = {paddingBottom: '0px'};          // when paywall visible, bottom of list should be removed, otherwise there remains some room to clic
        let result =
            <List className={classes.list} style={listStyle}
                subheader={
                    <ListSubheader component="div" className={classes.listHeader} id="nested-list-subheader">

                        {(!configService.isMobile) &&
                            <Tooltip title={t("Download the data in csv format")}>
                                <IconButton
                                    aria-label="new"
                                    disabled={!this.state.projects || this.state.projects.length === 0}
                                    onClick={this.handleDownloadData}>
                                    <FileDownload />
                                </IconButton>
                            </Tooltip>
                        }

                        <Tooltip title={t("Use your current location")}>
                            <IconButton
                                disabled={!navigator.geolocation}
                                aria-label="new"
                                edge={!configService.isMobile ? "start" : ""}
                                onClick={this.handleGetCurrentLocation}>
                                <CrosshairsGps />
                            </IconButton>
                        </Tooltip>

                        <Typography component="h2" variant="h6" style={{ flex: 1 }}>
                            <GoogleAutoComplete apiKey={GOOGLE_API_KEY}
                                onPlaceSelected={this.handlePlaceSelected}
                                onChange={this.handlePlaceValueChanged}
                                value={this.state.searchValue}
                                types={["geocode"]}
                                className={classes.autoComplete} />
                        </Typography>

                        <Tooltip title={t("View on a heatmap")}>
                            <IconButton
                                aria-label="new"
                                onClick={() => this.setState({ showMap: true })}>
                                <Earth />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t("View new projects")}>
                            <IconButton
                                aria-label="new"
                                edge="start"
                                onClick={this.props.onNewProjects}>
                                <PlaylistStar />
                            </IconButton>
                        </Tooltip>
                    </ListSubheader>
                }>
                {this.state.projects.map((prj, idx) => { return this.renderProject(prj, idx) })}
                {paywall}
            </List>
            ;

        if (this.state.projects.length === 0) {
            const rightCss = wideScreen ? '50%' : '0px';                             // when small screen, left side not visible
            result =
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    {result}
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, position: 'absolute', bottom: '0px', top: '0px', left: '0px', right: rightCss, maxWidth: "calc(1280px / 2)", marginLeft: "auto" }}>
                        <Typography variant="h6">{t("Search")}</Typography>
                    </Box>
                    {paywall}
                </div>
        }
        return result;
    }

    handleShowAR = (data, showInventory) => {
        this.setState({ ARData: data, ARShowInventory: showInventory, detailsDlgOpen: false });
    }

    handlePlaceValueChanged = () => {
        if (this.state.searchValue) {                           // when the user enters a new value, need to amke certain to remove the previous 'pre-filled' value from 'current location'
            this.setState({ searchValue: null });
        }
    }

    handleGetCurrentLocation = async () => {
        try {
            let location = await getCurrentGeoLocation();
            this.setState({ searchValue: location.address });
            await this.performSearch(location);
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Search"), t("failed_to_get_pos", { error: errExtractor.get(error) }));
        }
    }

    handleDownloadData = () => {
        const data = projectsToCsv(this.state.projects);
        let blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, 'download.csv');
    }

    handlePlaceSelected = async (place) => {
        const { t } = this.props;
        try {
            if (place instanceof String) {
                throw new Error(t("valid_google_search_result_required"));
            }
            const location = extractDataFromGoogleGeoLocation(place);
            await this.performSearch(location);
            this.setState({ searchValue: location.address });
        }
        catch (error) {
            dialogService.error(t("Search"), t("failed_to_get_pos", { error: errExtractor.get(error) }));
        }
    }

    async performSearch(location) {
        if (!this.data) {                                       // the data is not yet loaded
            this.searchAfterDataLoaded = location;
            this.setState({ loaded: false });
        }
        else {
            this.setState({ loaded: false });
            try {
                let analyser = new Analyzer();
                setTimeout(async () => {                                      // let the ui show the load screen.
                    let docs = await analyser.filter(this.data, location);
                    docs = analyser.buildMap(docs);
                    docs = Object.values(docs);
                    docs.sort((a, b) => {
                        if (a.location < b.location) {
                            return -1;
                        }
                        if (a.location > b.location) {
                            return 1;
                        }
                        if (a.route < b.route) {
                            return -1;
                        }
                        if (a.route > b.route) {
                            return 1;
                        }
                        return 0;
                    });
                    this.setState({ projects: docs });
                }, 0);
            }
            finally {
                this.setState({ loaded: true });
            }
        }
    }


    renderProject(data, idx) {
        const { t, classes } = this.props;
        let dates = [... new Set(Object.values(data.strengs).map((el) => el.date))];
        //for (const prj of data.projects) {
        //    let date = prj.publishedAt ? new Date(prj.publishedAt).toLocaleDateString() : prj.uploadedAt ? new Date(prj.uploadedAt).toLocaleDateString() : "unknown date";
        //    if (dates.indexOf(date) === -1) {               // only add unique dates
        //        dates.push(date);
        //    }
        // }
        let dataColor = null;
        if (data.maxErr > 2) {
            dataColor = { color: 'red' };
        }
        else if (data.maxErr > 0) {
            dataColor = { color: 'orange' };
        }
        return (
            <React.Fragment key={idx}>
                <ListItem alignItems="flex-start"
                    button
                    selected={this.state.selectedIdx === idx}
                    onClick={this.selectProject(data, idx)}
                >
                    <ListItemAvatar>
                        <Radio checked={!data.isRead} style={dataColor} color="default" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div>{data.location}</div>
                                <Typography component="span"
                                    color="textSecondary"
                                    style={{ marginLeft: '4px' }}
                                    variant="body2">{data.route}</Typography>
                            </div>}
                        secondary={
                            <React.Fragment>
                                {dates.join(", ")}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </React.Fragment>
        );
    }

    selectProject = (data, idx) => () => {
        let detailsDlgOpen = this.state.innerWidth <= this.props.theme.breakpoints.values.sm;
        const strengs = data?.strengs ? Object.values(data.strengs) : [];
        this.setState({ selectedIdx: idx, selectedProject: data, detailsDlgOpen: detailsDlgOpen, strengs });
    }

    handleCloseStrengSummary = (goToStreng) => {
        this.setState({ detailsDlgOpen: false });
        this.handleOpenPrj(goToStreng);
    }

    handleOpenPrj = (strengIdx) => {
        if (strengIdx > -1) {
            const streng = this.state.strengs[strengIdx];
            const prj = streng.prj;
            const realIdx = prj.strengs.findIndex((el) => el.id === streng.id);               // we need to find the actual index of the streng in the original project, not in the composed project that is used as data source (can contain multiple projects)
            if (realIdx > -1) {
                this.props.history.push(`/doc/${prj.id}`, { streng: realIdx });
            }
        }
    }

    /**
     * looks up the image for the specified obs index (detail.image) for the specified streng in the selected project
     * and shows the details.
     * @param {number} strengIdx index of the streng to show details of
     * @param {object} detail details to show. contains fields key, tooltip, image. 
     */
    handleShowObsDetails = async (strengIdx, detail) => {
        try {
            if (detail.image.obsIdx > -1) {
                let streng = this.state.strengs[strengIdx];
                const prj = streng.prj;
                let img = null;
                if (prj.files && prj.files.images) {
                    const searchStr = `_${streng.id}_${detail.image.obsIdx}.jpg`
                    img = prj.files.images.find((value) => value.name.endsWith(searchStr));
                    if (!img) {                                                         // the very first images werent stored with _strengId_obsIdx at the end, to find them, we will need to build the name of the image
                        streng = prj.strengs[detail.image.strengIdx];                   // map to the real streng and not the calculated version from before
                        if (streng.observations.length > detail.image.obsIdx) {
                            const obs = streng.observations[detail.image.obsIdx];
                            const oldName = oldBuildReportFotoFilename(prj, streng, obs);
                            img = prj.files.images.find((value) => value.name.endsWith(oldName));
                        }
                    }
                    if (img) {
                        img = await storageService.tryGetUrl(img);
                    }
                }
                this.setState({ strengDetailsTip: detail.tooltip, strengDetailsUrl: img });
            }
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Search"), errExtractor.get(error));
        }
    }

    handleCloseObsDetails = () => {
        this.setState({ strengDetailsTip: null, strengDetailsUrl: null });
    }

}

Search.propTypes = {
    onOpenPdf: PropTypes.func,                          // (url: string) => {} called when the pdf needs to be opened, platform specific, 
    onNewProjects: PropTypes.func,                           // called when the user wants to perform a search, need to switch view
};

export default withTheme(withTranslation()(withStyles(styles)(withRouter(Search))));