/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


/**
 * platform generic base class for robot recorders & players..
 */
export class RobotBase  {
    constructor() {

        this.data = null;
        this.onData = null;                                                                     //callback
    }

    /**
     * when this service starts, load the data or make connection to robot.
     */
    start() {
        throw new Error ("not implemented");
    }
 
    /**after stopping, makes certain all data is cleaned up. */
    stop() {
        this.data = null;
    }

    

    /**
     * returns the data event that was last played.
     * This is used by the ui component to perform a proper init when loaded
     * return null if there isn't any
     */
    getLastEvent() {
        throw new Error ("not implemented");
    }

   

    /**
     * never recording
     */
    isRecording() {
        throw new Error ("not implemented");
    }

    /**
     * checks if the current tractor is able to perform a laser measurement
     * by default not suported
     */
     hasLaserMeasure() {
        return false;
    }

    hasPitch() {
        return false;
    }

    hasYaw() {
        return false;
    }

    hasRoll() {
        return false;
    }

    hasCameraPan() {
        return false;
    }

    hasCameraTilt() {
        return false;
    }

    /**
     * some tractors can send a command to put the camera
     * in the center. This can be used to automatically calibrate the center of a camera
     */
    hasCameraGotoCenter() {
        return false;
    }

    hasDistance() {
        return false;
    }

    hasSonar() {
        return false;
    }

    hasLidar() {
        return false;
    }

    hasElevator() {
        return false;
    }

    hasAccelerometer() {
        return false;
    }

    hasLensPressure() {
        return false;
    }

    hasTractorPressure() {
        return false;
    }

    hasWheelPressure() {
        return false;
    }

    hasBattery() {
        return false;
    }

    hasLifeLeft() {
        return false;
    }

    hasClutch() {
        return false;
    }

    needsClutch() {
        return false;
    }

    /**
     * for the button on the controller dashboard
     */
    canToggleLightDirection() {
        return false;
    }

    /**
     * for the button on the controller dashboard
     */
     canToggleScanDirection() {
        return false;
    }


    /**
     * gets the mode by which speed should be applied to the device.
     * possible values:
     * - single: only 1 speed control available
     * - dual: speed control available for tractor and wheel. can be controlled individually (and thus also combined)
     * - clutchToggled: speed control available for tractor and wheel but value of clutch determins which one is currently possible (clutch in-> tractor, clutch out-> wheel)
     * - none: no speed control allowed
     */
    speedMode() {
        return 'none';
    }

    /**
     * the min/max for all supported sensors
     */
    get sensorLimits() {
        return {};
    }
}