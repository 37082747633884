import { LidarPlayerService } from '../../../../components/document/robot/sonarLidar/lidar_player_service';
import { getMinMaxFor } from '../../../services/lidar_data_service';
import { documentControlService } from '../../document_control_service';

/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2022] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */
const events = require('events');
const ROUND_TO_MM = 50;                         // nr of millimieters to round of to when calculating the new dimensions. this way we can have dimensions of 20x20 or 25x25 intead of 19x19


/**
 * providers a generic interface to connect to for the ui elements. They connect to this service and
 * the service will provide data from the player or recorder, according to the settings. Data is also
 * buffered so that it wont play to fast (cause of too much data too fast or scrolling too fast).
 */
class LidarService {
    constructor() {
        this.data = [];                                             // currenlty acitve line of sonar data
        this.angles = [];
        this.nextData = null;
        this.nextAngles = null;
        this.events = new events.EventEmitter();                    // callback for visualizing the data.
        this.timer = null;
        this.provider = null;                                       // when set, we can record data
        this.onWater = false;                                       // for lidars that are used on a water service (need to do heigh*2)

        this.reverseProvider = null;                                // reference to a lidarPlayer service containing reverse data for the current regular provider. will be cleared when the provider is changed, needs to be set by the client for  
    }

    /**
     * called when the robot recorder is initialized. Allows us to set the data provider.
     * @param {object} provider object that provides the data
     */
    init(provider) {
        if (this.provider !== provider) {                       // when first starting up, this can happen cause init is called when the backend tractor is started and the frontend robotClient is also started
            if (this.provider) {
                this.provider.onData = null;                    // remove any previous callbacks so we don't get duplicate data by accident
            }
            this.provider = provider;
            this.reverseProvider = null;
            this._normalizedData = null;
            if (provider) {
                this.provider.onData = this.handleData;
                if (this.provider.playCurrent) {                                    // the recorder doesn't have a playCurrent
                    this.provider.playCurrent(true);                                // when we init the data from the source, make certain we render the start picture correctly
                }
                this.onWater = this.provider.onWater;
            }
            else {
                this.onWater = false;
            }
        }
    }

    async loadReverseProvider(streng) {
        if(!this.reverseProvider) {
            const newService = new LidarPlayerService();
            await newService.load(streng, true);
            if (this.provider) {
                this.linkTimestamps(this.provider.data, newService.data);
            }
            this.reverseProvider = newService;
        }
        return this.reverseProvider;
    }

    /**
     * adds timestamps to the dest based on source.
     * @param {*} source provides timestamps
     * @param {lidarplayer} dest store timestamps
     */
    linkTimestamps(source, dest) {
        let srcIdx = 0;
        for(const rec of dest) {
            while(srcIdx < source.length && source[srcIdx].distance < rec.distance){
                srcIdx++;
            }
            if (srcIdx < source.length) {
                rec.timestamp = source[srcIdx].timestamp;
            }
        }
    }

    clear() {
        if (this.provider) {
            this.provider.onData = null;
            this.provider = null;
        }
        this.data = null;
        this._normalizedData = null;
    }

    handleData = (values, angles) => {
        if (!this.timer) {                                          // use a timer to make certain we only update the ui in steps, not at every change.
            this.timer = setTimeout(() => {
                this.timer = null;
                if (this.nextData) {                                  // if there are new values to send, do it now
                    this.data = this.nextData;
                    this.angles = this.nextAngles;
                    this.events.emit("onLidarData");
                    this.nextData = null;
                    this.nextAngles = null;
                }
            }, 100);                                                // don't update the ui too often.
            this.data = values;
            this.angles = angles;
            this.events.emit("onLidarData", values, angles);
        }
        else {
            this.nextData = values;
            this.nextAngles = angles;
        }
    }

    /**
     * tries to go to the specified location in the lidar data.
     * The exact lidar band is shown and the corresponding timestamp
     * is sent to the rest of the system.
     * @param {number} distance the distance to go to
     */
    tryGotoPos(distance) {
        this.events.emit("onGoto", distance);                                   // do first so that this is already udpated, if we do after jumptopos, timeupdate might be before the distanceupdate, which is bad
        if (documentControlService.controlState !== "record") {
            if (this.provider.isReversed) throw new Error("not yet supported");
            const data = this.provider.data;
            for(let i=0; i< data.length; i++) {
                const rec = data[i];
                if (rec.distance >= distance) {
                    this.events.emit('jumpToPos', rec.timestamp);
                    //documentControlService.setCurrentTimestamp(rec.timestamp);
                    setTimeout(() => {
                        this.handleData(rec.values, rec.angles);                // go to the exact location, do after setting the current timestamp cause that will also raise the event, but not necesarrily at the exact same location
                    }, 250);
                    break;
                }
            }
        }
    }

    /**
     * get the height and width as reported by the lidar.
     * @returns {object} {width: 0.0, height: 0.0}
     */
     getDimensions() {
        let result = null;
        if (this.data && this.angles) {
            const {minX, maxX, minY, maxY} = getMinMaxFor(this.data, this.angles);
            const realWidth = (Math.abs(minX) + Math.abs(maxX)) * 1000;               // convert from meters to mm
            const realHeight = (Math.abs(minY) + Math.abs(maxY)) * 1000;
            const roundedWidth = Math.round(realWidth / ROUND_TO_MM) * ROUND_TO_MM;
            let roundedHeight = Math.round(realHeight / ROUND_TO_MM) * ROUND_TO_MM;
            if (this.onWater) {
                roundedHeight *= 2;
            }
            result = {width: roundedWidth, height: roundedHeight};
        }
        return result;
    }

}

export const lidarService = new LidarService();