
/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from '../services/server_config_service';
import { getCurrentLocation } from '../services/geo_service';
import { getSelectedLanguage } from '../../services/translation_service';


const styles = (theme) => ({
    root: {

    },


});


/**
 * UI element for displaying google maps.
 * 
 * !!!IMPORTANT!!! 
 * this component should be placed in a parent (div) that defines height (and width)
 */
class GoogleMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            center: null,
            zoom: 11,
            heatmapData: {position: []},                        // data for the google map
            dataPoints: []                                      // data for the components on top of the google map
        }
    }

    async componentDidMount() {
        if (this.props.data) {
            this.calculateData();
        }
        const pos = await getCurrentLocation();
        if (pos) {
            if (pos.lat && pos.lon) {
                this.setState({center: {lat: pos.lat, lng: pos.lon}});
            }
            else if (pos.latitude && pos.longitude) {
                this.setState({center: {lat: pos.latitude, lng: pos.longitude}});
            }
            else {
                this.setState({center: {lat: 50.9941964, lng: 4.6209077}});     // if we dont have a center, the map wont show, so force one if need be
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data != this.props.data) {
            this.calculateData();
        }
    }

    calculateData() {
        const heatmap = [];
        const dataPoints = [];
        for(const prj of this.props.data) {
            for (const key in prj.strengs) {
                const streng = prj.strengs[key];
                const coord = streng.coordinates;
                const weight = streng.errors.length;
                if (coord && weight) {
                    heatmap.push({lat: coord.lat, lng: coord.lng, weight: weight});
                    dataPoints.push({prj, key});
                }
            }
        }
        const options ={   
            radius: 40,   
            //opacity: 0.6,
            //dissipating: false
        }
        this.setState({heatmapData: {positions: heatmap, options}, dataPoints});
    }


    render() {
        const { t } = this.props;
        if (this.state.center) {
            return (
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_API_KEY, libraries:['places', 'visualization', 'geometry'], language: getSelectedLanguage() }}
                    defaultCenter={this.state.center}
                    heatmap={this.state.heatmapData}
                    defaultZoom={this.state.zoom}
                >
                   
                </GoogleMapReact>
            );
        }
        else {
            return null;
        }
    }
}

GoogleMap.propTypes = {
    data: PropTypes.array,                    // data to show
};

export default withTranslation()(withStyles(styles)(GoogleMap));