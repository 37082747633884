/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { codeSelectorService } from '../../../services/code_selector_service';
import Tooltip from '@material-ui/core/Tooltip';
import CodeSelectorTreeSearch from './code_selector_tree_dialog';
import CodeSelectorListPopup from './code_selector_list_popup';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';



const styles = (theme) => ({
    root: {
        height: '100%',
        width: '100%'
    },
    listItemText: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end'
    },
    listItemSecondaryText: {
        marginRight: theme.spacing(1)
    },
    breadcrumbs: {
        color: theme.palette.text.primary
    }
});


// This resolves to nothing and doesn't affect browser history
const dudUrl = 'javascript:;';

class CodeSelector extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            popupOpen: false,
            popupAnchorEl: null,
            currentList: null,                                                  // the selectionNode  that will supply the data for the popup (from the selectionodes list).
            editIdx: -1,                                                        // the index nr of the value currently being edited (if any)
            needsNextValue: true,
            errorIdx: -1,                                                        // if we couldn't find a code in one of the dicts, the location of the first letter with a problem is stored here.
            treeRoot: null,                                                       // contains the root of the tree of selection nodes, so it can be passed on to the sub component when loaded.
            partial: null,                                                      // some values consist out of multiple letters, so during keyboard entry, it is needed to somtimes store values that have not yet been matched with something In the db.
            focusedIdx: -1                                                      // the input element that currently should have focus.  -1 is the questionmark.
        };
        this.QuestionInputRef = null;                                           // ref to the ? object, so we can move focus we needed.
        this.linkRefs = {};                                                     // ref to all the link obects, so we can manage focus as we want.

        this.selectionNodes = [];                                      // stores the objects who's children are currently candidates for the link values. indexes match with values.
        this.lastValueChange = null;                                            // when value is changed, event is raised, prop gets updated, but in this case, the intenral state is still ok, so no need to recalcualte stuff.
        this.callIsCompleteOnTreeClose = false;
        this.valueOnTreeClose = null;
        this.treeOpen = false;                                                  // the tree can deside by itself or we can command it, anyway, we need to know it's state
    }

    componentDidMount() {
        if (codeSelectorService.data == null) {
            codeSelectorService.load();
        }
        this.calculateSelectionNodes();
        this.setState({treeRoot: codeSelectorService.data});
        this.calculateFocusedIdx();
        this.putFocus();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values != this.props.values && this.props.values !== this.lastValueChange) {
            this.calculateSelectionNodes();
            this.setState({editIdx: this.props.values.length, partial: null});                                                                  // when the values have changed, make certain we update the edit pos. Need this for new elements that get inserted into the list, they will get re-used ui elements
            //this.calculateFocusedIdx();
            if (this.props.allowFocus) {
                setImmediate(() => {
                    this.putFocus();
                });
            }
        }
        if (prevProps.focusedValueIdx != this.props.focusedValueIdx || prevProps.allowFocus != this.props.allowFocus) {
            this.calculateFocusedIdx();
        }
        if (this.state.popupOpen === false && (this.props.treeOpen === false || this.props.treeOpen === null)  && this.props.allowFocus) this.putFocus();                        // after an update, there was a rerender, so manage focus
        this.lastValueChange = null;                                                                                // make certain that htis is always reset appropriatly
    }

    /**
     * called after mount or update to check if theh parent requested a specific
     * value to select and if so, if it is within range, otherwise the last one will be selected.
     */
    calculateFocusedIdx() {
        if (this.props.focusedValueIdx !== null) {
            let idx = this.props.focusedValueIdx < this.props.values.length ? this.props.focusedValueIdx : this.props.values.length - 1;
            this.setState({focusedIdx: idx});
            if (this.props.onFocusedInit) this.props.onFocusedInit();
        }
    }

    putFocus(){
        if (this.state.focusedIdx == -1) {
            if (this.QuestionInputRef) this.focusItem(this.QuestionInputRef);
        }
        else {
            let objRef = this.linkRefs[this.state.focusedIdx];
            if (objRef) this.focusItem(objRef);
        }
    }

    focusItem(item) {
        if (item !== null && item !== undefined) {
            ReactDOM.findDOMNode(item).focus();
        }
    }

    calculateSelectionNodes() {
        this.selectionNodes = [codeSelectorService.data];              //first item is always the root 
        let currentLst = codeSelectorService.data.children;
        let idx = 0;
        for (let value of this.props.values) {
            if (value === null) { break; }                              // null can happen if something went wrong with removing codes
            value = value.toLowerCase();                                // make certain we compare apples with apples
            const found = currentLst.find(x => x.value.toLowerCase() == value);
            if (found) {
                this.selectionNodes.push(found);
                currentLst = codeSelectorService.getChildren(found, this.props.strengType);
                if (currentLst == null) { break; }
            }
            else {
                this.setState({errorIdx: idx});
                break;
            }
            idx++;
        }
        let needsNextVal = currentLst != null;
        if (needsNextVal && this.state.partial == null) {                                               // if there is a partial, focus it, otherwise go to new pos
            this.setState({ needsNextValue: needsNextVal, focusedIdx: -1 });
        }
        else {
            this.setState({ needsNextValue: needsNextVal, focusedIdx: this.props.values.length - 1 });  // when all items have been entered, move to end of the list. note: could be problem that it always goes to end of list, in that case, check if there is a change in state for needsNextVAlue
        }
    }

    render() {
        const { t } = this.props;
        let codeSelectorVal = "";
        if (this.state.editIdx >= 0) {
            codeSelectorVal = this.props.values[this.state.editIdx];
        }
        return (
            <React.Fragment>
                <div style={{display:'flex', flexDirection:'row'}} 
                    onKeyDown={this.handleKeyboard}
                >
                    <Breadcrumbs separator="-" maxItems={10} aria-label="breadcrumb" classes={{root: this.props.classes.breadcrumbs}}>
                        {this.props.values.map((value, idx) => {
                            return (
                                <Tooltip title={this.getTooltipFor(idx)} key={idx}>
                                    <div ref={input => this.linkRefs[idx] = input} 
                                        tabIndex="0" 
                                        onFocus={this.handleLinkFocused(idx)}>
                                        <Link color={this.getLinkColor(idx)}
                                            href={dudUrl} 
                                            onClick={this.showSelectionList(idx)}
                                        >
                                            {value}
                                        </Link>
                                    </div>
                                </Tooltip>
                            );
                        })}
                        {(this.state.needsNextValue && this.state.errorIdx == -1) &&
                            <Tooltip title={t("voeg nieuwe code toe via toetsenbord. Click hier of druk op 'enter' voor selectie lijst")}>
                                <div 
                                    ref={input => this.QuestionInputRef = input} 
                                    key={this.props.values.length} 
                                    color="primary" 
                                    tabIndex="0" 
                                    style={{cursor:"pointer"}}
                                    onFocus={this.handleNewFocused}
                                    onClick={this.showSelectionList(this.props.values.length)}
                                >
                                    ?
                                </div>
                            </Tooltip>
                        }
                    </Breadcrumbs>
                    {(!this.props.readOnly) &&  
                        <CodeSelectorTreeSearch values={this.props.values} 
                            tree={this.state.treeRoot}
                            onRemoveItems={this.handleRemoveItemsFromTree}
                            onSetItem={this.handleSetItemFromTree}
                            isOpen={this.props.treeOpen}
                            onClose={this.handleTreeClosed}
                            onTreeOpened={this.handleTreeOpened}
                            strengType={this.props.strengType}
                            showOk={this.props.treeShowsOk}
                        />
                    }
                </div>
                {(!this.props.readOnly) &&  
                    <CodeSelectorListPopup popupOpen={this.state.popupOpen}
                        popupAnchorEl={this.state.popupAnchorEl}
                        currentList={this.state.currentList}
                        selectedValue={codeSelectorVal}
                        onClose={this.handleClosePopup}
                        onValueChanged={this.handleValueChanged}
                        strengType={this.props.strengType}
                    />
                }
            </React.Fragment>
        );
    }

    getLinkColor = (idx) => {
        if (this.state.errorIdx > -1 && idx >= this.state.errorIdx) {
            return 'error';
        }
        return 'inherit';
    }

    getTooltipFor = (idx) => { 
        const { t } = this.props;
        if (this.state.errorIdx > -1 && idx >= this.state.errorIdx) {
            return t("Kan geen text waarde vinden: onbekende waarde.");
        }
        else if (this.selectionNodes.length > idx +1) {                                      // when first loading, the selectionNodes list is not yet completely filled (only after componentDidMount)
            return t(this.selectionNodes[idx + 1].label);
        }
        return "";
    }

    showSelectionList = (idx) => (event) => {
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        if (this.props.onClick) this.props.onClick();                                   // so parent can set focus correctly
        if (this.state.errorIdx == -1 || idx <= this.state.errorIdx) {
            const children = codeSelectorService.getChildren(this.selectionNodes[idx], this.props.strengType);
            this.setState({ popupOpen: true, popupAnchorEl: event.currentTarget, currentList: children, editIdx: idx });
        }
    }

    handleLinkFocused = (idx) => (event) => {
        this.setState({editIdx: idx, partial: this.props.values[idx]});                 // make certain that the correct idx is selected when a link get focus and that it's content is loaded as partial, so that any non-finished partials can still be finished.
    }

    handleNewFocused = (event) => {
        this.setState({editIdx: this.props.values.length, partial: null});
        //this.setState({editIdx: this.props.values.length});
    }

    handleClosePopup = () => {
        this.setState({ popupOpen: false, popupAnchorEl: null });
    }

    handleTreeClosed = () => {
        this.treeOpen = false;
        if (this.callIsCompleteOnTreeClose) {
            this.callIsCompleteOnTreeClose = false;
            if (this.props.onIsCompleteChanged) {
                this.props.onIsCompleteChanged(this.valueOnTreeClose);
            }
            this.valueOnTreeClose = null;
        }
        if (this.props.onTreeClosed) {
            this.props.onTreeClosed();
        }
    }

    handleTreeOpened = () => {
        this.treeOpen = true;
        this.callIsCompleteOnTreeClose = false;
        this.valueOnTreeClose = null;
        if (this.props.onTreeOpened) {
            this.props.onTreeOpened();
        }
    }

    handleValueChanged = (item) => {
        this.updateValues(item.value);
        let currentIdx = this.state.editIdx === -1 ? 0 : this.state.editIdx;                        // handle 'no data yet' case 
        if (this.state.editIdx <= this.state.errorIdx) {                                            // if we just tried to correct an error, reset
            this.setState({errorIdx: -1});
        }
        if (this.selectionNodes.length <= currentIdx + 1){                                          // value might have been added or changed.
            this.selectionNodes.push(item);
        }
        else {
            this.selectionNodes[currentIdx + 1] = item;             
        }
        const needsNextValue = codeSelectorService.hasChildren(item, this.props.strengType);
        if ((this.state.needsNextValue != needsNextValue || !needsNextValue) && this.props.onIsCompleteChanged) {        // need to trigger the event, but only when full input or switched to not-full (not triggered for every not-full event)
            if (this.treeOpen) {
                this.callIsCompleteOnTreeClose = true;
                this.valueOnTreeClose = !needsNextValue;                                            // we keep the last value 
            }
            else {
                this.props.onIsCompleteChanged(!needsNextValue);
            }
        }
        this.setState({ popupOpen: false, 
            popupAnchorEl: null, 
            needsNextValue: needsNextValue, 
            editIdx: currentIdx + 1,
            errorIdx: -1,
            focusedIdx: -1 
        });
        if (needsNextValue && !this.treeOpen) setTimeout(() => this.focusItem(this.QuestionInputRef), 50);
    }

    updateValues(value) {
        let newValues;
        if (this.state.editIdx > 0) {
            newValues = this.props.values.slice(0, this.state.editIdx);
            newValues.push(value);
        }
        else {
            newValues = [value];
        }
        if (this.props.onValuesChanged){
            this.lastValueChange = newValues;
            this.props.onValuesChanged(newValues);
        }
    }

    /**
     * remark: only call when full list of values has been found (can't add any more children)
     */
    handleSetItemFromTree = (value, isLeaf) => {
        if (this.props.onValuesChanged){
            this.props.onValuesChanged(value);
        }
        if (this.state.needsNextValue !== !isLeaf) {
            this.callIsCompleteOnTreeClose = true;
            this.valueOnTreeClose = isLeaf;
        }
        this.setState({partial: null, 
            needsNextValue: !isLeaf, 
            editIdx: value.length,
            errorIdx: -1,
            focusedIdx: -1 
        });
    }

    /**
     * called from tree when using mouse. Level indicate the nr of letters that can remain
     * all after that point must be removed.
     */
    handleRemoveItemsFromTree = (level) => {
        let newValues = this.props.values.slice(0, level);
        if (this.props.onValuesChanged){
            this.props.onValuesChanged(newValues);
        }
        this.setState({
            needsNextValue: true,                                         // we have just removed a value, so it's safe to say that we need a new next value, also put the focus on that
            editIdx: newValues.length,
            errorIdx: -1,
            focusedIdx: -1 
        })
    }

    handleKeyboard = (e) => {
        if (e.keyCode === 13) {
            if (this.props.readOnly) {                                          // not allowed in readonly mode
                return;
            }
            let idx = this.state.editIdx == -1 ? 0: this.state.editIdx;
            let currentList = codeSelectorService.getChildren(this.selectionNodes[idx], this.props.strengType);
            let anchor;
            if (this.QuestionInputRef) {
                anchor = this.QuestionInputRef.current ?? e.currentTarget;
            }
            else {
                anchor = e.currentTarget;
            }
            this.setState({ popupOpen: true, popupAnchorEl: anchor, currentList: currentList, editIdx: idx });
        }
        else if (e.keyCode === 8) {                            // backspace, remove 1 letter from partials or remove value when empty
            if (this.props.readOnly) {                                          // not allowed in readonly mode
                return;
            }
            this.removePartial();
        }
        else if (e.keyCode == 37 || (e.keyCode === 9 && e.ctrlKey === false && e.shiftKey === true)) {          //arrow left || shft-tab
            this.moveLeft();
            e.preventDefault();
            e.stopPropagation();                            // don't let it bubble up, 
        }
        else if(e.keyCode == 39 || (e.keyCode === 9 && e.ctrlKey === false && e.shiftKey === false)) {          // arrow right || tab
            this.moveRight();
            e.preventDefault();
            e.stopPropagation();                            // don't let it bubble up, 
        }
        else if (e.keyCode === 40) {                                                    // arrow down
            this.moveDown();
        }
        else if (e.keyCode === 38) {                                                    // arrow up
            this.moveUp();
        }
        else if (e.keyCode >= 65 && e.keyCode <= 90) {        // letters
            if (this.props.readOnly) {                                          // not allowed in readonly mode
                return;
            }
            this.addPartial(e.key.toUpperCase());             // keydown doesn't store the key capitalize, instead has the shift key value.
        }
        else if ((e.keyCode >= 48 && e.keyCode <= 59) || (e.keyCode >= 96 && e.keyCode <= 105)) {        //nr
            if (this.props.readOnly) {                                          // not allowed in readonly mode
                return;
            }
            this.addPartial(e.key);
        }
        else if (e.keyCode == 32) {                           // this is primarily For the BAL codes where the last char in the code is optional, which is represented by a space.
            if (this.props.readOnly) {                                          // not allowed in readonly mode
                return;
            }
            this.addPartial(" ");
        }
    }

    /**
     * adds the letter to the partial (or creates the partial if not yet existing). Then checks if the current partial
     * can be found in the currentList.
     * @param {string} value the letter to add to the partial
     */
    addPartial(value) {
        if (this.state.partial) {
            value = this.state.partial + value;
        }
        let idx = this.state.editIdx == -1 ? 0: this.state.editIdx;
        const list = this.selectionNodes[idx] ? codeSelectorService.getChildren(this.selectionNodes[idx], this.props.strengType) : null;
        if (list) {
            const found = list.find(x => x.value == value);
            if (found && codeSelectorService.matchesFilter(found, this.props.strengType)) {
                this.setState({partial: null});
                this.handleValueChanged(found);
            }
            else {
                this.updateValues(value);                       // store the partial in file already and show on screen
                this.setState({partial: value, errorIdx: idx});
                if (this.props.treeOpen == null || this.props.treeOpen == false) {
                    setTimeout(() => this.setState({focusedIdx: idx}), 50);// so we can continue to type
                }
            }
        }
    }

    /** removes the last partial
     * if it was already empty, remove the entire value
     */
    removePartial() {
        if (!this.state.partial || this.state.partial.length === 1) {                   // if it's just 1 letter, remove the entire value, don't update the partial
            let newValues = this.props.values.slice(0, this.props.values.length-1);
            if (this.props.onValuesChanged){
                this.props.onValuesChanged(newValues);
            }
            if (this.state.needsNextValue == false && this.props.onIsCompleteChanged) {        // if we were at the end of the code-seq, we went from complete to not-complete, let the parent know this.
                this.props.onIsCompleteChanged(false);
            }
            this.setState({
                needsNextValue: true,                                         // we have just removed a value, so it's safe to say that we need a new next value, also put the focus on that
                editIdx: newValues.length,
                errorIdx: -1,
                focusedIdx: -1,
                partial: null 
            })
            if (this.props.treeOpen == null || this.props.treeOpen == false) {
                setTimeout(() => this.focusItem(this.QuestionInputRef), 50);// so we can continue to type
            }
        }
        else {
            let newPartial = this.state.partial.substr(0, this.state.partial.length - 1);
            this.setState({partial: newPartial, errorIdx: this.state.editIdx});
            this.updateValues(newPartial);

            if (this.props.treeOpen == null || this.props.treeOpen == false) {
                setTimeout(() => this.focusItem(this.linkRefs[this.state.editIdx]), 50);// so we can continue to type
            }
        }
    }

    /**moves the focus to the next letter.
     * if at end of values list, move to next
     * returns true if default event handling should be blocked (default).
     */
    moveRight = () => {
        if (this.state.focusedIdx === -1) {
            if (this.props.onGotoNext) this.props.onGotoNext();
        }
        else if (this.state.focusedIdx < this.props.values.length -1) {
            this.setState({focusedIdx: this.state.focusedIdx+1});
        }
        else if (this.state.needsNextValue) {
            this.setState({focusedIdx: -1});
        }
        else if (this.props.onGotoNext) {
            this.setState({focusedIdx: this.state.focusedIdx+1});           // move focus out of scope so we don't try to focus anything. If we don't do this, can't focus other items in the observation component
            this.props.onGotoNext();
        }
    }

    moveLeft = () => {
        if (this.state.focusedIdx === -1) {
            if (this.props.values.length > 0) {
                this.setState({focusedIdx: this.props.values.length-1});    
            }
            else {
                this.props.onGotoPrev();    
            }
        }
        else if (this.state.focusedIdx > 0) {
            this.setState({focusedIdx: this.state.focusedIdx-1});
        }
        else if (this.props.onGotoPrev) {
            this.props.onGotoPrev();
        }
    }

    moveUp = () => {
        if (this.props.onMoveUp) {
            this.props.onMoveUp(this.state.focusedIdx);
        }
    }

    moveDown = () => {
        if (this.props.onMoveDown) {
            this.props.onMoveDown(this.state.focusedIdx);
        }
    }
}

CodeSelector.defaultProps = {
    treeOpen: false,
    treeShowsOk: true
}

CodeSelector.propTypes = {
    values: PropTypes.array.isRequired,
    onValuesChanged: PropTypes.func.isRequired, 
    onIsCompleteChanged: PropTypes.func.isRequired, 
    onGotoNext: PropTypes.func,                     // called after tab goes out of scope, go to the next observation, start of values list. Should return true if default event handling needs to be blocked
    onGotoPrev: PropTypes.func, 
    treeOpen: PropTypes.bool,                       // when true, the search tree will be opened
    onTreeClosed: PropTypes.func,
    onTreeOpened: PropTypes.func,                   // used to reset that the tree must be opened (after it has been opened, the user must be able to close it again.)
    onMoveDown: PropTypes.func,                     // called after arrow up or down event, focus should move to observation above or below, at the same index as the parameter
    onMoveUp: PropTypes.func,
    focusedValueIdx: PropTypes.number,
    allowFocus: PropTypes.bool,
    onFocusedInit: PropTypes.func,                  // called when a value is focused when loaded or updated in response to a focusedValueIdx 
    onClick: PropTypes.func,                      // so parent can handle that focus was moved to here
    strengType: PropTypes.string,                            // dp = streng, m = put, determins which fields are visible
    readOnly: PropTypes.bool,                           // for viewer
    treeShowsOk: PropTypes.bool,                    // when true, the tree dlg shows 2 buttons instead of 1
};

export default withTranslation()(withStyles(styles)(CodeSelector));