/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DashboardBody from './dashboard/dashboard_body_component';


const styles = (theme) => ({
    root: {
        gridColumnStart: 4,                                         
        gridColumnEnd: 5,
        gridRowStart: 1,
        gridRowEnd: 3,       
        overflow: 'hidden',
        marginTop: '40px',
        position: 'relative',                   // need this? if we remove it, not all items are shown
    },
   

});


/**
 * instrumentation, but represented in a small vertical bar with expandable elements.
 * used for tablet mode. This is an altermative for Inspection
 */
class RobotSectionBar extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <DashboardBody layout={2} btnRadius={25} initLoaded={this.props.initLoaded}/>

            </div>
        );
    }
}

RobotSectionBar.propTypes = {
    liveFeed: PropTypes.bool,
    direction: PropTypes.string,                            // allowed values: 
    onDirectionChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    changePosAllowed: PropTypes.bool,                        // when true, clicking on the track allows changing position. This is not allowed when the application is recording.
    readOnly: PropTypes.bool,                               // for viewer
    initLoaded: PropTypes.bool.isRequired,                  // need to know when initial loading is done, so we can read the engine cofnig correctly
    onOpenVideoScreen: PropTypes.func,                      // callback for the button to open new screen for video
};

export default withStyles(styles)(RobotSectionBar);