export const configDefaults = {
    general: {
        language: null,                             // by default, no language is set, so use the system default
        foldersToMonitor: [],                       // list of folders that should be monitored by the cloud-recorder, for projects that might need uploading
        lastInspector: "",                          // last used insector code, so a default value can be given for new projects
        lastEquipmentSerialNr: "",                  // laset used value, so a default value can be provided for new projects
        lastInspectionPurpose: "A",                 // last selected value for inspection purpose: inspectors usually do the same thing
        homeTab: null,                              // the last tab used on the home page
        activeDashboard: null,                      // the last active view on the dashboard
        showInventory: false,                       // when true, streng summary will show the inventory instead of the errors
        showAR: false,                              // when true, the system will show an augmented reality view of the selected streng instead of going to the streng details
    },
    layout: {                                       // layout config settings
        document: {                                 // document layouts
            presets: [],                            // list of stored layouts
            layoutMode: 'default',                  // supported values: default, tablet
            showVideoSection: true,                 // to hide/show the video on hte main form
            robotSection: {                         // stuff related to the robot section
                HorSplit1: null,                    // init split values, init to null so that calculation is done to get half
                HorSplit2: null,
                VerSplit1: null,
                displayMode: 'single',              // determins which mode is shown: single, duoVer, duoHor, tripleHor, combo
                parts: {                            // config for each part on the robot section. How many parts are visible depends on te displaymode
                    1: {
                        source: 'inclination',      // which type of data is being displayed in this part. possible values: sonar, lidar, inclination
                        inclination: {
                            adjustHeightDif: false,
                            showPuddles: false,                     // in track view: show puddle data or not
                            seriesStyle: 'error+ideal',        // allowed values: error+ideal, error, ideal
                            sampleDistance: 0.10,              // distnace in meters that gets combined to 1 point on the track 
                            showGrid: false,                   // shows horizontal and vertical markers + horizontal grid lines
                            showStartEndLabels: false,         // show actual values for start & end points
                        },
                        sonar: {
                            zoom: 50,                          // zoom value for the sonar/lidar view
                        },
                        lidar: {
                            zoom: 50,                          // zoom value for the sonar/lidar view
                        },
                        lidarRoll: {
                            zoom: 100,                         // zoom value (%)
                            colorStops: [0, 0.25, 0.5, 0.75, 1],    // stop points for rendering the color range mapped to the values
                            colors: [                           // all the initial colors used
                                "#B71C1C",
                                "#FBC02D",
                                "#4CAF50",
                                "#1976D2",
                                "#1A237E"
                            ],
                            filterRange: [0, 255],
                            grayMode: false
                        }
                    },
                    2: {
                        source: "lidar"
                    },
                    3: {
                        source: "sonar"
                    }
                }
            },
            dashboardOpen: true,                    // store state that user selected for showing/hiding the tractor dashboard
        },
        screens: {                                  // dictionary of all possible screens and their last known state
            "1": {fullscreen: true, channelIdx: 0}
        },
        openScreens: []                             // list of dict-keys that determine which screens were last open, so that we can restore them
    },
    vitec: {
        firstRun: true                              // used by the vite card, so it can see if it needs to run a script or not.
    },
    recorder: {
        verticalSplitWidth: 600,
        horizontalSplitWidth: 500,
        forceLiveFeed: false,                       // when true video & tractor input alwasy comes from live data. when false, it only comes from live data if there is no recording yet for the active streng.
    },
    robot: {
        InclinationAsPercentage: true,
        showTractorDataOverVideo: true,
        remote: {
            stopOnSecondBtn: true,                  // when true, the remote-control unit will send a stop message when the same direction button is pressed 2 times, otherwise it will simply resend the same message
        },
    },
    sonar: {                                        // settings for the sonar. depend on the type of sonar, so no default values here, but in the sonar def itself
        highCutoff: 255,
        lowCutoff: 0,
        grayMode: false
    },
    lidar: {
        verifyDimensionsBeforeRecord: true,         // when true, lidar data will be used to verify the dimensions of a streng before recording starts
    },
    observations: {
        errorOnNonFullObservation: true,
        onlyNewObservationDuringRec: true,
        onlyAllowObsOnFulStreng: true,
        fullscreenOnMeasureValue: false,
        distanceTimeEditLinked: true,               // the distance - time editor field of the observation has a 'link' button, who's value is persisted with this.
        showObsDetails: false,                      // hide/show the code-labels as a list for all observations
    },
    strengs: {
        onNewStrengCopyPrevData: true,
        onNewStrengShowGeneral: false,
        onlyAllowStrengsOnFullMain: true
    },
    reports: {
        landscapeWritingMode: "lr",
    },
    video:{
        title: "Your company name",
        selectedBurnDataMethod: "burn",         // stores the last selected value for the report builder dialog on how to add data to video
    },
    engine: {
        activeConfig: "custom 2",                 // the currently selected configuration
    },
    gps: {
        port: '',                                   // empty port -> no gps
        baudRate: 19200,
        dataBits: 8,
        stopBits: 1,
        parity: 'none',
        active: false
    },
    shortcuts: {
        active: false,
    },
    geo: {
        region: null
    },
    api: {
        key: "",
        url: "https://api-dot-camera-inspector.appspot.com",
        google: "AIzaSyDhVr0PFQ5yBzV6yb_GxYZae95aBO55R88"
    },
    at: "nova",                                   // demo timestamp, last run timestamp. 
};