/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { activeProjectService } from '../../../services/active_project_service';
import TwoColLayout from '../../layouts/two_col_layout_component';
import { inspectorsList, inspectionPurposeOptions } from '../../services/constants';
import { isEmpty } from '../../services/project_service';
import { withTranslation } from 'react-i18next';
import { errExtractor } from '../../services/error_extractor';
import { dialogService } from '../../../services/dialog_service';
import { configService } from '../../../services/config_service';
import GpsAdorner from './gps_addorner_component';


const styles = (theme) => ({
    root: {
        height: '100%',
        width: '100%'
    },
    paper: {
        height: '100%'
    },
    textField: {
        width: '100%'
    },
    readOnly: {
        color: "rgba(0, 0, 0, 0.87)"
    }
});

const INPUT_LABEL_PROPS = { shrink: true, style:{width: '135%'} };

class MainInfo extends React.PureComponent {
    constructor(props) {
        super(props);

        const data = props.data;
        let inspectors = inspectorsList();
        if (data) {
            this.state = {
                norm: data.norm,
                codeInsp: data.codeInsp,
                inspectorName: data.inspectorName,                          // this is a translate of codeInsp. for viewers:they might not have the local list of inspectors, but they can still view this value
                purpose: data.purpose,
                opdrCodeOpdrGever: data.opdrCodeOpdrGever,
                opdrCodeBedrijf: data.opdrCodeBedrijf,
                assistant: data.assistant,
                //equipmentSerialNr: data.equipmentSerialNr,
                //vorigCodering: data.vorigCodering,
                location: data.location,
                managingInstance: data.managingInstance,
                inspectors: inspectors
            }
        }
        else {
            this.state = {
                norm: "",
                inspectorName: '',
                codeInsp: '',
                purpose: "",
                opdrCodeOpdrGever: "",
                opdrCodeBedrijf: "",
                //equipmentSerialNr: "",
                assistant: "",
                //vorigCodering: "",
                location: "",
                managingInstance: "",
                inspectors: inspectors
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            const data = this.props.data;
            this.setState({
                norm: data.norm,
                codeInsp: data.codeInsp,
                inspectorName: data.inspectorName,
                purpose: data.purpose,
                opdrCodeOpdrGever: data.opdrCodeOpdrGever,
                opdrCodeBedrijf: data.opdrCodeBedrijf,
                //equipmentSerialNr: data.equipmentSerialNr,
                assistant: data.assistant,
                //vorigCodering: data.vorigCodering,
                location: data.location,
                managingInstance: data.managingInstance
            })
        }
    }

    render() {
        const { t } = this.props;
        let inspector;
        if (this.props.readOnly || !this.state.inspectors) {                    // inspectors might not yet have been loaded.
            inspector = <TextField
                            required
                            id="codeInsp"
                            label={t("Code insp. / bedrijf")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{classes:{disabled: this.props.classes.readOnly}}}
                            disabled={true}
                            defaultValue={`${this.state.codeInsp} (${this.state.inspectorName})`}
                            className={this.props.classes.textField}
                            error={this.props.showMissingErrors && isEmpty(activeProjectService.data.main.codeInsp)}
                            margin="dense"
                        >
                        </TextField>;
        }
        else {
            inspector = <TextField
                            required
                            select
                            id="codeInsp"
                            label={t("Code insp. / bedrijf")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{classes:{disabled: this.props.classes.readOnly}}}
                            disabled={this.props.readOnly}
                            value={this.state.codeInsp}
                            onChange={this.handleInspectorChanged}
                            className={this.props.classes.textField}
                            error={this.props.showMissingErrors && activeProjectService.data && isEmpty(activeProjectService.data.main.codeInsp)}
                            SelectProps={{
                                native: true
                            }}
                            margin="dense"
                        >
                            {this.state.inspectors.map((x, idx) =>{
                                return (<option key={idx} value={x.value}>{x.label}</option>)
                            })}
                        </TextField>
        }
        return (
            <TwoColLayout
                left={
                    <React.Fragment>
                        <TextField
                            required
                            id="norm"
                            label={t("Norm")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{readOnly:this.props.readOnly}}
                            value={this.state.norm}
                            onChange={this.handleTextChanged}
                            className={this.props.classes.textField}
                            margin="dense"
                            error={this.props.showMissingErrors && activeProjectService.data && isEmpty(activeProjectService.data.main.norm)}
                        />
                        <TextField
                            required
                            id="managingInstance"
                            label={t("Beherende instantie")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{readOnly:this.props.readOnly}}
                            value={this.state.managingInstance}
                            onChange={this.handleTextChanged}
                            className={this.props.classes.textField}
                            margin="dense"
                            error={this.props.showMissingErrors && activeProjectService.data && isEmpty(activeProjectService.data.main.managingInstance)}
                        />
                        <GpsAdorner
                            editor={
                                <TextField
                                    required
                                    id="location"
                                    label={t("Stad of dorp")}
                                    InputLabelProps={INPUT_LABEL_PROPS}
                                    InputProps={{readOnly:this.props.readOnly}}
                                    value={this.state.location}
                                    onChange={this.handleTextChanged}
                                    className={this.props.classes.textField}
                                    margin="dense"
                                    error={this.props.showMissingErrors && activeProjectService.data && isEmpty(activeProjectService.data.main.location)}
                                />
                            }
                            readOnly={this.props.readOnly}
                            field="city"
                            onLocation={this.handleLocationChanged}
                        />
                        
                        
                        <TextField
                            required
                            select
                            id="purpose"
                            label={t("Doel inspectie")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{classes:{disabled: this.props.classes.readOnly}}}
                            disabled={this.props.readOnly}
                            value={this.state.purpose}
                            onChange={this.handleTextChanged}
                            className={this.props.classes.textField}
                            error={this.props.showMissingErrors && activeProjectService.data && isEmpty(activeProjectService.data.main.purpose)}
                            SelectProps={{
                                native: true
                            }}
                            margin="dense"
                        >
                            {inspectionPurposeOptions.map((x, idx) =>{
                                return (<option key={idx} value={x.value}>{t(x.label)}</option>)
                            })}
                        </TextField>
                        {(this.state.purpose === "Z") &&
                            <TextField
                                id="purposeZ"
                                required
                                label={t("Opmerking")}
                                InputLabelProps={INPUT_LABEL_PROPS}
                                InputProps={{readOnly:this.props.readOnly}}
                                value={this.state.purposeZ}
                                onChange={this.handleTextChanged}
                                className={this.props.classes.textField}
                                error={this.state.purpose === "Z" && this.props.showMissingErrors && activeProjectService.data && isEmpty(activeProjectService.data.main.purposeZ)}
                                margin="dense"
                            />
                        }
                    </React.Fragment>
                }
                right={
                    <React.Fragment>
                        {inspector}

                        <TextField
                            id="assistant"
                            label={t("Assistent")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{readOnly:this.props.readOnly}}
                            value={this.state.assistant}
                            onChange={this.handleTextChanged}
                            className={this.props.classes.textField}
                            inputProps={{type: "text", maxLength: 200 }}
                            error={false}
                            margin="dense"
                        />

                        <TextField
                            required
                            id="opdrCodeBedrijf"
                            label={t("Opdrachtref. inspecteur")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{readOnly:this.props.readOnly}}
                            value={this.state.opdrCodeBedrijf}
                            onChange={this.handleTextChanged}
                            className={this.props.classes.textField}
                            inputProps={{type: "text", maxLength: 200 }}
                            error={this.props.showMissingErrors && activeProjectService.data && isEmpty(activeProjectService.data.main.opdrCodeBedrijf)}
                            margin="dense"
                        />


                        <TextField
                            id="opdrCodeOpdrGever"
                            label={t("Opdrachtref. opdrachtgever")}
                            InputLabelProps={INPUT_LABEL_PROPS}
                            InputProps={{readOnly:this.props.readOnly}}
                            value={this.state.opdrCodeOpdrGever}
                            onChange={this.handleTextChanged}
                            className={this.props.classes.textField}
                            inputProps={{type: "text", maxLength: 200 }}
                            margin="dense"
                        />
                    </React.Fragment>
                }
            />
        );
    }

    handleTextChanged = (ev) => {
        try {
            activeProjectService.data.main[ev.target.id] = ev.target.value;
            let newState = {};
            newState[ev.target.id] = ev.target.value;
            this.setState(newState);
            activeProjectService.markDirty();
            if (ev.target.id === "purpose")  {
                configService.set("general.lastInspectionPurpose", ev.target.value)
            }
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

    handleLocationChanged = (value) => {
        try {
            activeProjectService.data.main.location = value;
            this.setState({location: value});
            activeProjectService.markDirty();
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }

    /*
     * needs to be handled a little different
     * @param {object} ev params
     */
    handleInspectorChanged = (ev) => {
        try {
            activeProjectService.data.main[ev.target.id] = ev.target.value;
            activeProjectService.data.main["inspectorName"] = this.state.inspectors[ev.target.selectedIndex].label;     // also store the name of the inspector, so others can view the data
            configService.set("general.lastInspector", ev.target.value);                                                // store so we can provide a default value for next project
            let newState = {};
            newState[ev.target.id] = ev.target.value;
            this.setState(newState);
            activeProjectService.markDirty();
        }
        catch (error) {
            const { t } = this.props;
            dialogService.error(t("Document wijzigen"), t("doc_change_error", {error: errExtractor.get(error)}));
        }
    }
}

MainInfo.propTypes = {
    showMissingErrors: PropTypes.bool,
    readOnly: PropTypes.bool,                           // for viewer
    data: PropTypes.object
};

export default withTranslation()(withStyles(styles)(MainInfo));