import i18n from "i18next";

//provides access to the documents api
class DialogService {


    constructor() {
        this.messages = [];
        this.onMessagesChanged = null;                  // callback for popup messages
        this.onAskQuestion = null;                      // callback for blocking question
    }

    error(title, message) {
        console.log(message);
        const value = {severity: 'error', title, message};
        this.messages.push(value);
        if (this.onMessagesChanged) {this.onMessagesChanged()}
    }

    info(title, message) {
        console.log(message);
        const value = {severity: 'info', title, message};
        this.messages.push(value);
        if (this.onMessagesChanged) {this.onMessagesChanged()}
    }

    removeMessage(item) {
        const idx = this.messages.indexOf(item);
        this.messages.splice(idx, 1);
        if (this.onMessagesChanged) {this.onMessagesChanged()}
    }
    
    /**
     * 
     * @param {string} title the title
     * @param {string} message message text
     * @param {string} details more details
     * @param {function} callback (response) => {} 
     */
    askYesNo(title, message, details, callback) {
        if (this.onAskQuestion) {
            const options = {
                ok: i18n.t('Ja'),
                cancel: i18n.t('Nee'),
                action: callback,
                title: title,
                message: message,
                detail: details,
            };
            this.onAskQuestion(options);
        }
    }

    /**
     * 
     * @param {string} title the title
     * @param {string} message message text
     * @param {string} details more details
     * @return  promise(response: int)
     */
    ask(title, message, details, responses, callback) {
        throw new Error("not supported");
    }

    
}

export const dialogService = new DialogService();