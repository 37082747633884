/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
//import { withTranslation } from 'react-i18next';
import RangeProgressBar from '../../../controls/range_progress_bar_component';
import TickedProgressBar from '../../../controls/ticked_progress_bar_component';
import { say } from '../../../services/tts';


const styles = (theme) => ({
    root: {
        position: 'relative',
    },
    content: {
        top: "0px",
        bottom: "0px",
        position: "absolute",
        left: "0",
        right: "0",
        margin: "auto",
        width: "fit-content",
        height: 'fit-content',
    },
    value: {
        fontSize: 'x-small',
        color: theme.palette.text.secondary
    }
});

const MAX_UPDATE_SPEED = 500;                       // nr milliseconds between ui updates

class ImageRotationComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
        if (props.handle) {                         // load at create to get fastest possible results
            props.handle(this);
        }

        this.throttleTimer = null;                  // set a top speed at how fast ui element is updated
        this.nextValue = null;                      // when throttling, need to know if and which value to set next
        this.lastAlert = null;                      // to prevent us from saying the alert message too often
    }

    componentWillUnmount() {
        if (this.props.handle) {
            this.props.handle(null);
        }
    }

    componentDidMount () {
        if (this.props.handle) {                    // can be the it was unmounted and mounted again, so re-register
            this.props.handle(this);
        }
    }

    render() {
        const { classes } = this.props;
        const strokeColor = this.props.showStroke ? "#5d9cec" : "#e6e6e6";
        const rotate = this.props.startLeft ? 180 : -90;
        const imgSize = `${this.props.radius}px`;
        const imgRotate = `rotate(${this.state.value}deg)`;
        const Icon = this.props.image;                  // important Icon must be with capital cause that lets react know it is used as a component name instead of instance
        const absValue = Math.abs(this.state.value);
        const color = this.props.max !== undefined && (absValue >= this.props.max || absValue >= 360-this.props.max) ? 'red' : '#7f7f7f';
        if (this.props.max) {
            return (
                <Tooltip title={this.props.tooltip}>
                    {/* a is needed to show the tooltip */}
                    <div style={{flex: '50%', position:'relative', display: 'flex', justifyContent:'center'}}>
                        <RangeProgressBar progress={this.state.value}
                            radius={this.props.radius}
                            rotate={rotate}
                            steps={360}
                            trackStrokeColor="#e6e6e6"
                            strokeColor={strokeColor}
                            strokeLinecap="round"
                            strokeWidth={2}
                            trackTransition=""
                            trackStrokeWidth={2}
                            pointerStrokeWidth={1}
                            pointerRadius={3}
                            pointerStrokeColor="#5d9cec"
                            pointerFillColor="#5d9cec"
                            limit={this.props.max}
                        >
                            <div className={classes.content}>
                                <div className={classes.value}>
                                    <Icon  fill={color} size={imgSize} transform={imgRotate} />
                                </div>
                            </div>
                        </RangeProgressBar>
                    </div>
                </Tooltip>
    
            );
        }
        else {
            return (
                <Tooltip title={this.props.tooltip}>
                    {/* a is needed to show the tooltip */}
                    <div style={{flex: '50%', position:'relative', display: 'flex', justifyContent:'center'}}>
                        <TickedProgressBar progress={this.state.value}
                            radius={this.props.radius}
                            rotate={rotate}
                            steps={360}
                            trackStrokeColor="#e6e6e6"
                            strokeColor={strokeColor}
                            strokeLinecap="round"
                            strokeWidth={2}
                            trackTransition=""
                            trackStrokeWidth={2}
                            pointerStrokeWidth={1}
                            pointerRadius={3}
                            pointerStrokeColor="#5d9cec"
                            pointerFillColor="#5d9cec"
                        >
                            <div className={classes.content}>
                                <div className={classes.value}>
                                <Icon  fill={color} size={imgSize} transform={imgRotate} />
                                </div>
                            </div>
                        </TickedProgressBar>
                    </div>
                </Tooltip>

            );
        }
    }

    setValue = (value) => {
        if (value > 180) {                              // invert the value when big so that the dial goes down instead of full circle
            value -= 360;
        }
        if (value !== this.state.value) {
            if (!this.throttleTimer) {
                this.internalSetValue(value);
            }
            else {
                this.nextValue = value;
            }
        }
    }

    internalSetValue(value) {
        this.setState({value: value});
        if (!this.props.readOnly && this.props.alertMsg && this.props.max !== undefined) {
            const absValue = Math.abs(value);
            if (absValue >= this.props.max || absValue >= 360-this.props.max) {
                this.SayAlert();
            }
        }
        this.createThrottleTimer();
    }

    SayAlert() {
        let now = new Date();
        if (!this.lastAlert || (now - this.lastAlert) > 1000 * 3 ) {                    // only say it every 4 seconds, otherwise too often
            say(this.props.alertMsg);
            this.lastAlert = now;
        }
    }

    createThrottleTimer() {
        this.throttleTimer = setTimeout(() => {
            if (this.nextValue) {
                this.internalSetValue(this.nextValue);
                this.nextValue = null;
            }
            else {
                this.throttleTimer = null;
            }
        }, MAX_UPDATE_SPEED);
    }

}

ImageRotationComponent.propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.number,
    tooltip: PropTypes.string,
    radius: PropTypes.number,
    image: PropTypes.any,                           // the image that should be depicted. needs to be an asset path
    startLeft: PropTypes.bool,                      // when true, the value will start at the left side (270 deg)
    showStroke: PropTypes.bool,                     // don't show for camera
    handle: PropTypes.func,                             // used to let the parent get a ref to this object
    max: PropTypes.number,                          // max value, outside of this value, the control should show error. This is expressed in degrees. So 40degrees, means 40 up and 40 down
    alertMsg:PropTypes.string,                      // when set, this message will be spoken when the value goes out of bounds (max) to inform user
};

export default withStyles(styles)(ImageRotationComponent);