/* 
 *   
 *   ELASTETIC CONFIDENTIAL
 *   ______________________
 *   
 *   [2019] - [2020] Elastetic GCV
 *   All Rights Reserved.
 *   
 *   NOTICE:  All information contained herein is, and remains
 *   the property of Elastetic GCV and its suppliers,
 *   if any.  The intellectual and technical concepts contained
 *   herein are proprietary to Elastetic GCV
 *   and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *   patents in process, and are protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material
 *   is strictly forbidden unless prior written permission is obtained
 *   from Elastetic GCV.
 */

import React from 'react';
import '../../../App.css';
import IconButton from '@material-ui/core/IconButton';
import { BaseOverlay } from '../../overlays/base_overlay_component';
//import fs from 'fs';

//icons
import CircleHalfFull from 'mdi-material-ui/CircleHalfFull';
import CheckAll from 'mdi-material-ui/CheckAll';
import { createImgObj } from '../../services/draw_service';



class FreeformSurfaceMeasure extends BaseOverlay {
    constructor(props) {
        super(props);

        this.canvasEl = React.createRef();
        Object.assign(this.state, {
            points: [],
            closePath: false,
            inverseSelection: true
        });
    }

    /**
     * builds the image of the background object filled black. Used to calculate the % space used by the drawn area.
     * This represents the full area (100%)
     */
    get CircleImage() {
        let size = this.ImageSize;
        const center = size / 2;
        return (
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${size} ${size}">
                <circle cx="${center}" cy="${center}" r="${center}"  stroke-width="0" fill="black"/>
            </svg>`);
    }

    /**
     * for calculation, builds the svg of the selected image.
     */
    get selectedImage() {
        let size = this.ImageSize;
        let content = this.buildImageContent("0", "black", "1", false);
        
        return (
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${size} ${size}">
            ${content}
        </svg>`);
    }
    
    buildSvgImage() {
        let size = this.ImageSize;
        let content = this.buildImageContent();
        
        return (
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="0 0 ${size} ${size}">
            ${content}
        </svg>`);
    }

    /**
     * builds the inner part of the svg string. This is dynamic so that we can build it for ui and for calculation
     * @param {number/string} fullCircleStroke the stroke thickness of the full circle (white) default 1
     * @param {string} selectedStrokeClr the color of the stroke for the selected part, default red
     * @param {number/string} selectedOpacity the opacity of the selected part, default 0.3
     */
    buildImageContent(fullCircleStroke="1",selectedStrokeClr="red", selectedOpacity="0.3", invertColors=true) {
        const fillColor = invertColors ? "white" : "black";
        let content = null;
        let size = this.ImageSize;
        const center = size / 2;
        const points = this.buildPath();
        let selectedArea = null;
        if (this.state.inverseSelection) {
            if (this.state.points.length > 1) {
                if (invertColors) {                                                                     // when rendering to ui, need to add fill
                    selectedArea = `<path clip-path="url(#clip)" d="${points}" stroke="${selectedStrokeClr}" stroke-width="1" fill-opacity="${selectedOpacity}" fill="${fillColor}"/>`;
                }
                else {
                    selectedArea = `<path clip-path="url(#clip)" d="${points}" stroke="${selectedStrokeClr}" stroke-width="1" fill-opacity="${selectedOpacity}"/>`;
                }
            }
            content = ` <defs>
                            <clipPath id="clip">
                                <circle cx="${center}" cy="${center}" r="${center}"/>
                            </clipPath>
                        </defs>
                        ${selectedArea}
                        <circle cx="${center}" cy="${center}" r="${center}" stroke="white" stroke-width="${fullCircleStroke}" fill="transparent" />`;
        }
        else {
            if (this.state.points.length > 1) {
                selectedArea = `<circle cx="${center}" cy="${center}" r="${center}" fill="${fillColor}" fill-opacity="${selectedOpacity}" mask="url(#selected)" />`;
            }
            content = ` <defs>
                            <clipPath id="clip">
                                <circle cx="${center}" cy="${center}" r="${center}"/>
                            </clipPath>

                            <mask id="selected">
                                <circle cx="${center}" cy="${center}" r="${center}" fill="white"/>
                                <path d="${points}" fill="black"/>
                            </mask>
                        </defs>
                        <path clip-path="url(#clip)" d="${points}" stroke="${selectedStrokeClr}" stroke-width="1" fill="transparent"/>
                        ${selectedArea}
                        <circle cx="${center}" cy="${center}" r="${center}" stroke="white" stroke-width="${fullCircleStroke}" fill="transparent"/>
                        `;
        }
        return content;
    }

    buildPath() {
        let result = this.state.points.map((p) => `${p.x} ${p.y}`).join(" L");
        return `M${result} Z`;
    }

    /**
     * override so we don't show the rotate icon
     */
    buildExtraIcons(checkStyle) {
        return null;
    }

    buildMeasureIcons(checkStyle) {
        let toolIcon;
        if (this.state.inverseSelection === true) {
            toolIcon =  <CircleHalfFull fontSize="inherit"/>;
        }
        else {
            toolIcon =  <CircleHalfFull fontSize="inherit" style={{transform: "scaleX(-1)"}}/>;
        }
        return (
            <React.Fragment>
                <IconButton 
                    onClick={this.toggleSelectedArea} 
                    style={checkStyle}>
                    {toolIcon}
                </IconButton>
                <IconButton 
                    onClick={this.calculateResult} 
                    style={checkStyle}>
                    <CheckAll fontSize="inherit"/>
                </IconButton>
            </React.Fragment>
        );
    }

    buildRenderObjects() {
        return (
            <canvas ref={this.canvasEl} style={{width:this.ImageSize, height:this.ImageSize, visibility: 'hidden'}}/>
        );
    }

    internalHandleMouseDownOnImage(ev) {
        if (this.state.mode === "measure") {
            if (ev.button === 0 && ev.buttons === 1) {
                let pos = this.getRelMousePos(ev);
                pos = this.relativeToCenterImage(pos);

                this.setState({points: [pos], closePath: false});
            }
        }
        else {
            super.internalHandleMouseDownOnImage(ev);
        }
    }

    internalMouseMove(ev) {
        if (this.state.mode === "measure") {
            if (ev.button === 0 && ev.buttons === 1) {
                let pos = this.getRelMousePos(ev);
                pos = this.relativeToCenterImage(pos);

                this.setState({points: [...this.state.points, pos]});
            }
        }
        else {
            super.internalMouseMove(ev);
        }
    }


    toggleSelectedArea = () => {
        this.setState({inverseSelection: !this.state.inverseSelection});
    }

    /**
     * 
     * @param {array} data list of image points
     */
    calculateNrBlack(data) {
        let result = 0;
        for(let x=0; x<data.length; x+=4) {
            if (data[x] === 0 && data[x+1]===0 && data[x+2] === 0) {
                result++;
            }
        }
        return result;
    }

    /*saveImage(canvas, idx) {
        const imgDataURL = canvas.toDataURL("image/jpeg", 1.0);
        const base64Data = imgDataURL.replace(/^data:image\/jpeg;base64,/, "");              // remove Base64 stuff from the Image
        const filePath = `c:\\temp\\img${idx}.jpg`;
        fs.writeFileSync(filePath, base64Data, 'base64');
    }*/
   
    /**
     * calculates the result and returns this.
     */
    calculateResult= () => {
        const size = this.ImageSize;
        this.canvasEl.current.width = size;
        this.canvasEl.current.height = size;
        const context = this.canvasEl.current.getContext('2d');

        let img1 = createImgObj(size, size, this.CircleImage, () => {
            context.fillStyle = "white";                                        // set background
            context.fillRect(0, 0, size, size);
            context.drawImage(img1, 0, 0 , size, size);
            //this.saveImage(this.canvasEl.current, 1);
            const imgData = context.getImageData(0,0, size, size);
            const totalCount = this.calculateNrBlack(imgData.data);
            let img2 = createImgObj(size, size, this.selectedImage, () => {
                context.fillStyle = "white";                                        // set background
                context.fillRect(0, 0, size, size);
                context.drawImage(img2, 0, 0 , size, size);
                //this.saveImage(this.canvasEl.current, 2);
                const imgData = context.getImageData(0,0, size, size);
                const selectedCount = this.calculateNrBlack(imgData.data);
                const result = (100/totalCount) * selectedCount;
                if (this.props.onMeasured) {
                    this.props.onMeasured(result);
                }
            });
        });
    }

}

FreeformSurfaceMeasure.propTypes = {
    
};

export default FreeformSurfaceMeasure;