/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons
import Camera from 'mdi-material-ui/Camera';
import VectorLine from 'mdi-material-ui/VectorLine';
import TransitConnection from 'mdi-material-ui/TransitConnection';
import Delete from 'mdi-material-ui/Delete';
import CircleSlice8 from 'mdi-material-ui/CircleSlice8';
import AngleAcute from 'mdi-material-ui/AngleAcute';
import ArrowExpandDown from 'mdi-material-ui/ArrowExpandDown';
import SelectMultiple from 'mdi-material-ui/SelectMultiple';
import CircleOutline from 'mdi-material-ui/CircleOutline';
import CircleHalfFull from 'mdi-material-ui/CircleHalfFull';
import Radar from 'mdi-material-ui/Radar';
import { Lidar } from '../../../icons';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import DirectionSelector from './direction_selector_component';
import LocationEditor from './location_editor_component';
import CodeSelector from '../code_selector/code_selector_component';
import { activeProjectService } from '../../../../services/active_project_service';
import { codeSelectorService } from '../../../services/code_selector_service';
import { CHANGE_SHAPE_KEY, isEmpty, projectService, TERMINATE_KEY, TERMINATE_KEY2 } from '../../../services/project_service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { descriptiveLocOptions } from '../../../services/constants';
import { Card } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import ContinuousQuestionDialog from './continuous_question_dialog';
import { measurementService } from '../../measurement_service';
import { documentControlService } from '../../document_control_service';
import { withTranslation } from 'react-i18next';
import { errExtractor } from '../../../services/error_extractor';
import { dialogService } from '../../../../services/dialog_service';
import { ObservationFocusService } from './observation_focus_service';
import { strandPropsService } from '../../../services/strand_props_service';
import { ObsTranslations } from './observation_translations';


const styles = (theme) => ({
    root: {
        height: '100%',
        width: '100%',
        background: 'transparent',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    selected: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    textField: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    valuesRow: {
        paddingRight: theme.spacing(1),
    },
    remark: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        flex: 1
    },
    measureBtn: {
        marginLeft: theme.spacing(1),
        marginRight: -theme.spacing(1)/2
    },
    IconUnChecked: {
        color: theme.colors.canSelectIcon,
        marginRight: theme.spacing(0.5),
        '&:hover': {
            color: theme.colors.canSelectIconHover,
        }
    },
    cameraIconChecked: {
        color: theme.colors.checkedCameraIcon,
        marginRight: theme.spacing(0.5)
    },
    joinChecked: {
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(0.5)
    },
    ROCameraIconChecked: {
        color: theme.colors.checkedCameraIcon,
        margin: '3px'
    },
    ROCameraIconUnChecked: {
        color: 'transparent',
        margin: '3px'
    },
    isContinuousIcon: {
        top: '3px',
        position: 'relative',
        color: "darkgreen",
        height: "16px"
    }
    ,
    isContinuousErrIcon: {
        top: '3px',
        position: 'relative',
        color: "darkred",
        height: "16px"
    },
    readOnly: {
        color: "rgba(0, 0, 0, 0.87)"
    },
    valueLabelBreadCrumbs: {                                                    // the breadcrumbs with the labels for each letter in the code, shown when collapsed
        fontStyle: "italic",
        fontSize: "small"
    },
    cardActionsRoot: {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: -theme.spacing(1),
    },
    cardActionItem: {

        margin: theme.spacing(1),
    }
});

class Observation extends React.PureComponent {
    constructor(props) {
        super(props);
        
        //important: set translations first so that we capture translated error messages at create time
        this.translations = new ObsTranslations();                                  // speed improvement: only do translations 1 time, much faster (language can not be changed 'on the fly' in this view)
        this.distanceValueChanged = false;                                      // when the user changes the distance manually, this flag is set so that the distanceChanged event is only called when editor is closed or component unloaded. This way, the sort of the list is only done when editing is stopped, which helps user.
        this.isContinuous = false;                                              // do before checking isContinuous, otherwise we might loose the correct value
        if (props.data) {
            let obsDefPath = codeSelectorService.translatePath(props.data.values);
            let obsDef = codeSelectorService.getLeafFromDictPath(obsDefPath);   // get the definition for the observation, so we can set properties 
            let valueLabels = props.isExpanded ? null : codeSelectorService.getLabelsFromDictPath(obsDefPath);  // we only need to load labels when collapsed. When exanded, labels are handled by the codeSelector
            let valueComplete = obsDef != null;
            let contSettings = this.checkIsContinuous();
            this.state = {
                valueLabels: valueLabels,                                       // labels for the values, when collapsed.
                joint: props.data.joint,
                values: props.data.values,
                location: props.data.longitude,
                waarde1: props.data.waarde1,
                waarde2: props.data.waarde2,
                direction1: props.data.direction1,
                direction2: props.data.direction2,
                remark: props.data.remark,
                includePicture: props.data.includePicture,
                includeSonar: !!props.data.includeSonar,                            // sonar value isn't always present, so take a trueth operator(!!)
                includeLidar: !!props.data.includeLidar,                            // lidar value isn't always present, so take a trueth operator(!!)
                valueComplete: valueComplete,
                focusedValueIdx: props.focusedValueIdx,                             // the idx of the codeselector value that should get focus. we need this so we can tab back from textfield to codeselector
                observationDef: obsDef,                                              // an object that stores for each data field if it is required or not for the current values path
                allowSelectorFocus: true,
                descriptiveLoc: props.data.descriptiveLoc,
                measuring: null,                                                     // when 1 or 2, indicates that are are currently in a measuring request. 1 is for value 1..
                showContinuous: contSettings.showContinuous,
                continuousMsg: contSettings.errorMsg,
                continuousIdx: contSettings.idx,
                continuousComplete: contSettings.complete,
                continuousDlgOpen: false
            }
        }
        else {
            this.state = {
                joint: false,
                location: 0.0,
                values: [],
                waarde1: "",
                waarde2: "",
                direction1: 0,
                direction2: 0,
                remark: "",
                includePicture: false,
                includeSonar: false,
                includeLidar: false,
                valueComplete: false,
                descriptiveLoc: null,
                measuring: null,
                showContinuous: false,
                continuousIdx: null,
                continuousDlgOpen: false
            }
        }
        this.rootRef = React.createRef();                                            // the root div object, for scrolling into view when expanded.
        this.focsMgr = new ObservationFocusService(this);
    }


    componentDidMount() {
        this.hasSonar = documentControlService.robotDs.hasSonar();                  // faster access
        this.hasLidar = documentControlService.robotDs.hasLidar();
        this.focsMgr.buildRefs(this.hasSonar, this.hasLidar);
        if (this.props.isExpanded && this.rootRef.current) {
            this.rootRef.current.scrollIntoView();
        }
    }

    componentWillUnmount() {
        if (this.distanceValueChanged === true && this.props.onDistanceChanged) {
            this.props.onDistanceChanged(this.props.data);
        }
        this.distanceValueChanged = false;
        activeProjectService.events.removeListener("continuousChanged", this.handleContinuousObsChanged);   // normally not registered, but just to make certain, always try.
        if (!documentControlService.documentPos) {                                                          // when set, we are in fullscreen, so don't try to unregister any measurement. It will be handled by the document
            this.stopAllMeasurements();                                                                          // when unloading, make certain that we are no longer measuring, there is no more recipient
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.strengType !== prevProps.strengType) {
            this.focsMgr.buildRefs(this.hasSonar, this.hasLidar);
        }
        let obsDefPath = codeSelectorService.translatePath(this.props.data.values);
        let valueComplete = codeSelectorService.dictPathIsLeaf(obsDefPath);
        if (this.props.data && this.props.data != prevProps.data) {
            const data = this.props.data;
            let obsDef = null;
            let contSettings = {showContinuous: false, errorMsg: ""};
            if (valueComplete) {
                obsDef = codeSelectorService.getLeafFromDictPath(obsDefPath);   // get the definition for the observation, so we can set properties 
                contSettings = this.checkIsContinuous();
            }
            let valueLabels = this.props.isExpanded ? null : codeSelectorService.getLabelsFromDictPath(obsDefPath);  // we only need to load labels when collapsed. When exanded, labels are handled by the codeSelector
            this.setState({
                values: data.values, 
                valueLabels: valueLabels,
                location: data.longitude,
                joint: data.joint,
                waarde1: data.waarde1,
                waarde2: data.waarde2,
                direction1: data.direction1,
                direction2: data.direction2,
                remark: data.remark,
                includePicture: data.includePicture,
                includeSonar: data.includeSonar,                            // sonar value isn't always present, so take a trueth operator(!!)
                includeLidar: data.includeLidar,                            // lidar value isn't always present, so take a trueth operator(!!)
                valueComplete: valueComplete,
                observationDef: obsDef,
                allowSelectorFocus: true,
                descriptiveLoc: data.descriptiveLoc,
                showContinuous: contSettings.showContinuous,
                continuousMsg: contSettings.errorMsg,
                continuousIdx: contSettings.idx,
                continuousComplete: contSettings.complete,
                continuousDlgOpen: false
            });
        }
        if (this.props.isExpanded) {
            if (this.props.focusedValueIdx != prevProps.focusedValueIdx) {
                if (this.props.focusedValueIdx === -2) {
                    const hasMoreThanSelector = this.focsMgr.focusLast();
                    this.setState({focusedValueIdx: -1, allowSelectorFocus: !hasMoreThanSelector});
                }
                else {
                    this.setState({focusedValueIdx: this.props.focusedValueIdx});
                }
            }
            if (this.props.showEmptyError && !prevProps.showEmptyError) {
                this.focsMgr.focusFirstError();
            }
            else if (prevProps.isExpanded === false) {                                      // when just showing the expanded view, put the focus on the right item
                if (valueComplete) {
                    this.focsMgr.moveToFirstAfterCode();
                }
                else {
                    this.setState({focusedValueIdx: -1, allowSelectorFocus: true});
                }
            }
        }
        else if (prevProps.isExpanded != this.props.isExpanded) {
            let valueLabels = codeSelectorService.getLabelsFromDictPath(obsDefPath);
            this.setState({valueLabels});
            this.props.onStopMeasure();                                     // when closing to read-only, make certain that we are no longer measuring, there is no more recipient
            if (this.distanceValueChanged === true && this.props.onDistanceChanged) {       // when closed without clicking on 'ready, still need to call the event.
                this.props.onDistanceChanged(this.props.data);
            }
            this.distanceValueChanged = false;                                              // important: needs to be reset, even if there is no callback assigned
        }
    }

    render() {
        if (this.props.isExpanded) {
            return this.renderExpanded();
        }
        else {
            return this.renderCollapsed();
        }
    }

    

    renderExpanded() {     
        const { t } = this.props;
        return (
            <Card className={`${this.props.classes.root} ${this.props.classes.selected}`} 
                ref={this.rootRef}
                onKeyDown={this.handleKeyboard}>


                <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>   

                    <LocationEditor navDistance={this.focsMgr.locRef} 
                        navTime={this.focsMgr.timeRef}
                        readOnly={this.props.readOnly}
                        onFocus={this.blockSelectorFocus(this.focsMgr.locRefIdx)}
                        data={this.props.data} 
                        onDistanceChanged={this.handleDistanceValueChanged}
                        onTimeChanged={this.handleTimeValueChanged}/>

                    <CodeSelector values={this.state.values} 
                        readOnly={this.props.readOnly}
                        onValuesChanged={this.handleValueChanged}
                        onIsCompleteChanged={this.handleValueComplete}
                        treeOpen={this.props.treeOpen}
                        onTreeOpened={this.props.onTreeOpened}
                        onTreeClosed={this.handleTreeClosed}
                        focusedValueIdx={this.state.focusedValueIdx}
                        allowFocus={this.state.allowSelectorFocus}
                        onFocusedInit={this.props.onFocusedInit}
                        onGotoNext={this.handleCodeSelectorGotoNext}
                        onGotoPrev={this.focsMgr.handleStartOfFocusListReached}
                        onMoveDown={this.props.onMoveDown}
                        onMoveUp={this.props.onMoveUp}
                        onClick={this.handleCodeSelectorClicked}
                        strengType={this.props.strengType}
                    />
                    <div style={{flex: 1}}/>
                    {this.renderContinuous()}
                    {(!this.props.readOnly) && 
                        <Tooltip title={this.translations.removeObs}>
                            <IconButton size='small' 
                                onClick={this.deleteObservation} 
                                style={{padding: '0px'}}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    }
                </div>

                {this.buildLoc()}

                {this.buildContinousTerminationSelector()}

                {(this.state.valueComplete == true && this.needsExtraData()) && 
                    <React.Fragment>
                        {this.renderRow1()}
                        {this.renderRow2()}
                        {this.renderRemark()}
                        {(this.props.showEmptyError === true) &&
                        <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                            <Typography color="error" variant="caption"> {t("Aangeduide waardes ontbreken")} </Typography>
                        </Box>

                        }
                    </React.Fragment>
                }
                {this.renderButtons()}
            </Card>
        );
    }

    /**
     * called when user changed the distance of the tractor.
     * This should trigger a resort by the parent, but not yet cause that
     * would screw up the focus and easy working. It needs to be done
     * when the editor is closed or unloaded
     * @param {number} value new tractor distance
     */
    handleDistanceValueChanged = (value) => {
        this.setState({location: value});
        this.distanceValueChanged = true;
    }

    handleTimeValueChanged = (value) => {
        try {
            if (this.props.onPositionChanged) {
                this.props.onPositionChanged(this.props.data.time);
            }
        }
        catch(error) {
            dialogService.error(this.translations.obs, errExtractor.get(error));
        }
    }

    renderRow1() {
        let obsDef = this.state.observationDef;
        let items = [];
        if (obsDef) {                                                           // when creating a new one, this can be empty
            this.renderRow1Part(items, obsDef, this.state.waarde1, 0, "waarde1", this.focsMgr.tfRef1, this.focsMgr.tfRef1Idx);
            this.renderRow1Part(items, obsDef, this.state.waarde2, 1, "waarde2", this.focsMgr.tfRef2, this.focsMgr.tfRef2Idx);
        }
        return (
            <div style={{flexDirection: 'row', display:'flex', alignItems: 'center', justifyContent:'flex-end'}}
                className={this.props.classes.valuesRow}>
                {items}
            </div>
        );
    }

    /**
     * 
     * @param {list} result contains the result items that were rendered
     * @param {object} obsDef the definition for the observation
     * @param {number} idx 0 or 1, for the index of the value to build an input for.
     * @param {object} ref the input-ref object to assing to the component (so we can get to it from other parts of the code)
     */
    renderRow1Part(result, obsDef, value, idx, inputId, ref, refIdx) {
        if (codeSelectorService.getValueCount(obsDef, this.props.strengType) > idx) {
            let adorner = {end: null, label: this.translations.textValue};                                             // so we can pass in byref
            let validifierData = {};
            let wInputProps = this.buildInputAttribs(obsDef, idx, adorner, validifierData);
            let isRequired = projectService.isObsValueRequired(obsDef, idx, this.props.strengType);
            let inError = false;
            if (this.props.showEmptyError && isRequired) {
                inError = isEmpty(value);
            }
            if (!isEmpty(value)) {
                inError |= this.invalidValue(validifierData, value);
            }
            
            if (!this.props.readOnly) {                                                                 // no editing allowed in readonly mode
                if (obsDef.measure && obsDef.measure instanceof Array && obsDef.measure.length > idx) {                                                          // there is a predefined measure action defined
                    if (obsDef.measure[idx] == "depth") {
                        result.push(<ToggleButton color="secondary" 
                                        key={(idx * 2) }
                                        aria-label="meet" 
                                        selected={this.state.measuring===idx+1}
                                        className={this.props.classes.measureBtn}
                                        value={idx}
                                        size="small" 
                                        onClick={this.handleStartMeasure(idx+1, validifierData, this.props.onStartMeasureDepth)}>
                                        <ArrowExpandDown />
                                    </ToggleButton>);
                    }
                    else if (obsDef.measure[idx] == "deformation" || obsDef.measure[idx] == 'Deformatie') {
                        result.push(<ToggleButton color="secondary" 
                                        key={(idx * 2) }
                                        aria-label="meet" 
                                        selected={this.state.measuring===idx+1}
                                        className={this.props.classes.measureBtn}
                                        value={idx}
                                        size="small" 
                                        onClick={this.handleStartMeasure(idx+1, validifierData, this.props.onStartMeasureAreaSize)}>
                                        <SelectMultiple />
                                    </ToggleButton>);
                    }
                    else if (obsDef.measure[idx] == 'freeform') {
                        result.push(<ToggleButton color="secondary" 
                                        key={(idx * 2) }
                                        aria-label="meet" 
                                        selected={this.state.measuring===idx+1}
                                        className={this.props.classes.measureBtn}
                                        value={idx}
                                        size="small" 
                                        onClick={this.handleStartMeasure(idx+1, validifierData, this.props.onStartMeasureFreeform)}>
                                        <CircleHalfFull/>
                                    </ToggleButton>);
                    }
                }
                else if (obsDef.valueType && obsDef.valueType.length > idx) {               // this needs to be added first
                    if (obsDef.valueType[idx] == "mm" || obsDef.valueType[idx] == "mm_dec") {
                        result.push(<ToggleButton color="secondary" 
                                        key={(idx * 2) }
                                        aria-label="meet" 
                                        selected={this.state.measuring===idx+1}
                                        className={this.props.classes.measureBtn}
                                        value={idx}
                                        size="small" 
                                        onClick={this.handleStartMeasure(idx+1, validifierData, this.props.onStartMeasure)}>
                                        <VectorLine />
                                    </ToggleButton>);
                    }
                    else if (obsDef.valueType[idx] == "degree") {
                        result.push(<ToggleButton color="secondary" 
                                        key={(idx * 2) }
                                        aria-label="meet" 
                                        selected={this.state.measuring===idx+1}
                                        className={this.props.classes.measureBtn}
                                        value={idx}
                                        size="small" 
                                        onClick={this.handleStartMeasure(idx+1, validifierData, this.props.onStartMeasureAngle)}>
                                        <AngleAcute />
                                    </ToggleButton>);
                    }
                }
            }
            result.push(<TextField
                        inputRef={ref}
                        key={(idx * 2) + 1}
                        required={isRequired}
                        id={inputId}
                        label={adorner.label}
                        value={value}
                        onChange={this.handleFieldChanged}
                        className={this.props.classes.textField}
                        inputProps={wInputProps}
                        InputProps={{endAdornment: adorner.end, readOnly:this.props.readOnly}}
                        margin="dense"
                        onFocus={this.blockSelectorFocus(refIdx)}
                        error={inError}
                        InputLabelProps={{
                            shrink: true,
                            style:{ width: '135%'}
                          }}
            />);
        }
    }

    renderRow2() {
        let dir1 = this.buildDirection(0, this.state.direction1, 'direction1', this.focsMgr.dRef1, this.focsMgr.dRef1Idx);
        let dir2 = this.buildDirection(1, this.state.direction2, 'direction2', this.focsMgr.dRef2, this.focsMgr.dRef2Idx);;
        
        return (
            <div style={{flexDirection: 'row', display:'flex', alignItems: 'center', justifyContent:'flex-end'}}
                className={this.props.classes.valuesRow}>
                {dir1}
                {dir2}
            </div>
        );
    }

    buildDirection(idx, value, fieldName, ref, refIdx) {
        let obsDef = this.state.observationDef;
        const directionCount = codeSelectorService.getDirectionCount(obsDef, this.props.strengType);
        if (obsDef && directionCount > idx) {
            let required = projectService.isObsDirectionRequired(obsDef, idx);
            return <DirectionSelector 
                    inner={ref}
                    isExpanded={true} 
                    readOnly={this.props.readOnly}
                    required={required}
                    size={40} 
                    value={value} 
                    onValueChanged={this.handleDirectionChanged(fieldName)}
                    onFocus={this.blockSelectorFocus(refIdx)}
                    error={this.props.showEmptyError && required && isEmpty(value)}
                />
        }
        return null;
    }

    renderButtons() {
        return (
            <CardActions classes={{root: this.props.classes.cardActionsRoot}}
            >
                {this.renderButtonJoint()}
                {this.renderButtonPicture()}
                {this.renderButtonSonar()}
                {this.renderButtonLidar()}
                <div style={{flex: 1, display: "flex", justifyContent: 'flex-end', margin: '0px'}}>
                    <Button ref={this.focsMgr.readyBtnRef}
                        variant="outlined"
                        className={this.props.classes.cardActionItem}
                        onClick={this.handleReadyClicked}
                    >
                        {this.translations.ready}
                    </Button>
                </div>
            
            </CardActions>
        );
    }

    renderButtonJoint() {
        let jointStyle = this.state.joint ? this.props.classes.joinChecked : this.props.classes.IconUnChecked; 
        return (
            <Tooltip title={this.translations.jointTip}>
                <FormControlLabel control={
                    <IconButton size='small' 
                        className={jointStyle}
                        onClick={this.handleCheckJoint}
                        
                    >
                        <TransitConnection fontSize='small'/>
                    </IconButton>
                    }
                    className={this.props.classes.cardActionItem}
                    label={this.state.joint ? this.translations.onJoint : this.translations.onStrand}
                    onFocus={this.blockSelectorFocus(this.focsMgr.jRefIdx)} 
                    ref={this.focsMgr.jRef}
                />
            </Tooltip>
        );
    }

    renderButtonPicture() {
        let includePicStyle = this.state.includePicture ? this.props.classes.cameraIconChecked : this.props.classes.IconUnChecked;
        return(
            <Tooltip title={this.translations.imageTip}>
                <FormControlLabel control={
                    <IconButton size='small' 
                        className={includePicStyle}
                        onClick={this.toggleIncludePic}
                    >
                        <Camera fontSize='small'/>
                    </IconButton>
                    }
                    className={this.props.classes.cardActionItem}
                    label={this.state.includePicture ? this.translations.withPic : this.translations.noPic}
                    onFocus={this.blockSelectorFocus(this.focsMgr.iRefIdx)} 
                    ref={this.focsMgr.iRef} 
                />
            </Tooltip>
        );
    }

    renderButtonSonar() {
        if (this.hasSonar) {
            let includePicStyle = this.state.includeSonar ? this.props.classes.cameraIconChecked : this.props.classes.IconUnChecked;
            return(
                <Tooltip title={this.translations.sonarTip}>
                    <FormControlLabel control={
                        <IconButton size='small' 
                            className={includePicStyle}
                            onClick={this.toggleIncludeSonar}
                        >
                            <Radar fontSize='small'/>
                        </IconButton>
                        }
                        className={this.props.classes.cardActionItem}
                        label={this.state.includeSonar ? this.translations.withSonar : this.translations.noSonar}
                        onFocus={this.blockSelectorFocus(this.focsMgr.sonarRefIdx)} 
                        ref={this.focsMgr.sonarRef} 
                    />
                </Tooltip>
            );
        }
    }

    renderButtonLidar() {
        if(this.hasLidar) {
            let includePicStyle = this.state.includeLidar ? this.props.classes.cameraIconChecked : this.props.classes.IconUnChecked;
            return(
                <Tooltip title={this.translations.sonarTip}>
                    <FormControlLabel control={
                        <IconButton size='small' 
                            className={includePicStyle}
                            onClick={this.toggleIncludeLidar}
                        >
                            <Lidar fontSize='small'/>
                        </IconButton>
                        }
                        className={this.props.classes.cardActionItem}
                        label={this.state.includeLidar ? this.translations.withLidar : this.translations.noLidar}
                        onFocus={this.blockSelectorFocus(this.focsMgr.lidarRefIdx)} 
                        ref={this.focsMgr.lidarRef} 
                    />
                </Tooltip>
            );
        }
    }

    renderRemark() {
        if (this.state.observationDef) {
            return (
                <Box display="flex">
                    <TextField
                        inputRef={this.focsMgr.rRef}
                        required={this.state.observationDef.remark}
                        id="remark"
                        label={this.translations.remark}
                        value={this.state.remark}
                        multiline
                        InputProps={{readOnly:this.props.readOnly}}
                        onChange={this.handleFieldChanged}
                        className={this.props.classes.remark}
                        margin="dense"
                        onFocus={this.blockSelectorFocus(this.focsMgr.rRefIdx)}
                        error={this.props.showEmptyError && this.state.observationDef.remark && isEmpty(this.state.remark)}
                    />
                </Box>
            );
        }
        else {
            return null;
        }
    }

    buildLoc() {
        if (this.props.strengType === "m") {                // it's a pit, so ask the descriptive location.
            const { t } = this.props;
            return(
                <Box display="flex" flexDirection="column">
                    <TextField
                        required={true}
                        select
                        id="descriptiveLoc"
                        inputRef={this.focsMgr.lRef}
                        tabIndex="0"
                        label={this.translations.locLabel}
                        InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                        InputProps={{classes:{disabled: this.props.classes.readOnly}}}
                        disabled={this.props.readOnly}
                        value={this.state.descriptiveLoc}
                        onChange={this.handleDescriptiveLocChanged}
                        className={this.props.classes.cmbField}
                        onFocus={this.blockSelectorFocus(this.focsMgr.lRefIdx)}
                        SelectProps={{
                            native: true
                        }}
                        margin="dense"
                    >
                        {descriptiveLocOptions.map((val, idx) => {
                            return(
                                <option key={idx} value={val.value}>{t(val.label)}</option>
                            );
                        })}
                    </TextField>
                </Box>
            );
        }
    }

    /**
     * 
     * @param {bool} includeClosure when true, the closure for this item is kept in the list.
     */
    getRelatedStartObs(includeClosure) {
        let list = this.props.onGetList();
        const key = this.props.data.values.join("").substr(0, 3);
        let result = list.filter((x) => x !== this.props.data && x.longitude <= this.props.data.longitude && x.values.join("").startsWith(key) && x.values[x.values.length-1] !== "1");

        for (const item of list) {
            if (includeClosure && item === this.props.data) {            // if we need to include the closure for the current data point, then skip this step when the item is the current data point.
                continue;
            }
            if (item.values[item.values.length-1] === "1") {            // it's a terminator, check if it's a terminator for one of our results
                const idx = result.findIndex((x) => +x.continuousIdx === +item.continuousIdx);
                if (idx > -1) {
                    result.splice(idx, 1);
                }
            }
        }
        return result;
    }

    /**
     * looks for any obs in the list (start or end) that has the same continuousIdx
     */
     checkLinkedObs() {
        const list = this.props.onGetList();
        const data = this.props.data; 
        let result = list.find((el) => +el.continuousIdx === +data.continuousIdx && el !== data);
        if (result) {
            const thisKey = data.values.join("").substr(0, 3);
            const otherKey = result.values.join("").substr(0, 3);
            return {result: thisKey === otherKey, related: result};
        }
        return {result: null, related: null};
    }

    /**
     * render the UI element for continuous observation terminators to select the related observation.
     */
    buildContinousTerminationSelector () {
        if (this.props.data.values[this.props.data.values.length-1] == "1") {
            let list = this.getRelatedStartObs(true);
            if (list.length === 1 && !isEmpty(this.props.data.continuousIdx) && list[0].continuousIdx === this.props.data.continuousIdx) {
                return null;
            }
            return(
                <Box display="flex" flexDirection="column">
                    <TextField
                        required={true}
                        select
                        id="continuousIdx"
                        inputRef={this.focsMgr.cRef}
                        tabIndex="0"
                        label={this.translations.relatedStart}
                        InputLabelProps={{ shrink: true, style:{whiteSpace:'nowrap', width: '135%'} }}
                        InputProps={{classes:{disabled: this.props.classes.readOnly}}}
                        disabled={this.props.readOnly}
                        value={this.state.continuousIdx}
                        onChange={this.handleContinuousIdxChanged}
                        className={this.props.classes.cmbField}
                        onFocus={this.blockSelectorFocus(this.focsMgr.cRefIdx)}
                        SelectProps={{
                            native: true
                        }}
                        margin="dense"
                    >
                        <option key={-1} value=""></option>
                        {list.map((val, idx) => {
                            return(
                                <option key={idx} value={val.continuousIdx}>{val.longitude.toFixed(2)} - {val.values.join(" - ")} : {val.continuousIdx}</option>
                            );
                        })}
                    </TextField>
                </Box>
            );
        }
    }

    /**
     * builds the html attributes for input field idx, so that it only allows the proper input.
     * @param {object} obsDef the observation definition
     * @param {number} idx the index nr of the value for which we want to get an adorner
     * @param {object} adorner object with 2 fields: end + label, so we can pass an extra result back to the caller
     * @param {object} validifierData object used to return all the info required to validate the data
     */
    buildInputAttribs(obsDef, idx, adorner, validifierData) {
        let result = {type: 'text'};
        if (obsDef) {
            if (obsDef.valueLength && obsDef.valueLength.length > idx) {
                result['maxLength'] = obsDef.valueLength[idx];
            }
            if (obsDef.valueType && obsDef.valueType.length > idx) {
                let valueType = obsDef.valueType[idx];
                if (valueType) {
                    this.buildValueTypeForInputAttribs(validifierData, valueType, adorner);
                }
            }
            if (obsDef.valueLabel && obsDef.valueLabel.length > idx) {                                         // do after calling buildValueTypeForInputAttribs, so we override it when we have specific label
                adorner.label = this.props.t(obsDef.valueLabel[idx]);
            }
        }
        return result;
    }

    

    /**
     * 
     * @param {object} result will contain the result value
     * @param {string} valueType the type of value to expect
     * @param {object} adorner optional result object that contains the 
     */
    buildValueTypeForInputAttribs(result, valueType, adorner) {
        if (valueType === "ppm") {
            Object.assign(result, {type: 'number', min: 0, max: 1000000, step: 1, precision: 0 });
            adorner.end = <InputAdornment position="end">PPM</InputAdornment>
            adorner.label = this.translations.measure;
        }
        else if(valueType === "%") {
            Object.assign(result, {type: 'number', min: 0, max: 100, step: 1, precision: 0});
            adorner.end = <InputAdornment position="end">{valueType}</InputAdornment>
            adorner.label = this.translations.procent;
        }
        else if(valueType === "mm") {
            Object.assign(result, {type: 'number', min: 0, step: 1 , precision: 0});
            adorner.end = <InputAdornment position="end">{valueType}</InputAdornment>
            adorner.label = this.translations.measure;
        }
        else if (valueType === "degree") {
            Object.assign(result, {type: 'number', min: 0.0, step: 1, max: 360, precision: 1});
            adorner.end = <InputAdornment position="end">°</InputAdornment>
            adorner.label = this.translations.degrees;
        }
        else if(valueType === "mm_dec") {
            Object.assign(result, {type: 'number', min: 0.0, step: 0.1, precision: 1 });
            adorner.end = <InputAdornment position="end">mm</InputAdornment>
            adorner.label = this.translations.measure;
        }
        else if (valueType === "n" || valueType === "number") {
            Object.assign(result, {type: 'number', precision: 0 });
            adorner.label = this.translations.number;
        }
    }

    /**
     * returns true if the current observation definition specifies that extra data is needed
     */
    needsExtraData() {
        return this.state.values[this.state.values.length-1] !== "1";
    }

    /** returns true if the UI should indicate that this is a running continuous observation. */
    checkIsContinuous(newObj=false) {
        let result = {showContinuous: false, idx: null, complete: false, errorMsg: "", clearContinuousIdx: false};
        let prevWasContinuous = this.isContinuous;                // we need to check if we go from not continuous to continuous. If so, we need to register/unregister event listeners.
        this.isContinuous = codeSelectorService.isContinuous(this.props.data.values);
        if (this.isContinuous) {   
            result.showContinuous = true;
            result.idx = this.props.data.continuousIdx;
            if (!prevWasContinuous || newObj) {                                         // newObj is true when an observation.value was changed and has now becme continuous
                activeProjectService.events.addListener("continuousChanged", this.handleContinuousObsChanged);
            }
            const key = this.props.data.values.join("").substr(0, 3);
            const list = this.props.onGetList();
            if (this.props.data.values[this.props.data.values.length-1] === "1") {  // this is a closure, so check if we can find the accomponying start.
                let related = list.filter((x) => x != this.props.data && x.longitude <= this.props.data.longitude && x.values.join("").startsWith(key) && +x.continuousIdx == +this.props.data.continuousIdx);
                if (related && related.length > 1) {
                    related = related.sort((x, y) => y.longitude - x.longitude);        // sort reversed so we have the closest element at pos 0
                    if (related[0].values[related[0].values.length -1] === "1") {       // we have 2 terminators after each other, not good
                        result.showContinuous = true;
                        result.errorMsg = this.translations.multipleEnd;
                    }
                }
                else if (related && related.length == 1) {
                    result.complete = true;
                }
                else {
                    result.showContinuous = true;
                    result.errorMsg = this.translations.noStart;
                    result.clearContinuousIdx = true;                                   // let the caller know that it is best to clear the idx nr on the record cause there is no start anymore
                    result.idx = null;
                }
            }
            else {
                let related = list.filter((x) => x != this.props.data && x.longitude >= this.props.data.longitude && x.values.join("").startsWith(key) && x.continuousIdx == this.props.data.continuousIdx);
                if (related && related.length > 0) {
                    related = related.sort((x, y) => x.longitude - y.longitude);
                    if (related[0].values[related[0].values.length -1] !== "1") {       // we have 2 terminators after each other, not good
                        result.showContinuous = true;
                        result.errorMsg = this.translations.multipleStartMsg;
                    }
                    else {
                        result.complete = true;
                    }
                }
            }
        }
        else if (prevWasContinuous) {
            activeProjectService.events.removeListener("continuousChanged", this.handleContinuousObsChanged);
        }
        return result;
    }

    /**
     * callback that handles cases when other observations have been added/changed/removed that are
     * also continuous. It allows us to recalculate the state of the continuous measurement: closed, open, in error
     */
    handleContinuousObsChanged = (params) => {
        const key = this.props.data.values.join("").substr(0, 3);
        let otherKey = (params.oldKey) ? params.oldKey.values : params.changed.values;
        otherKey = otherKey.join("");
        if (otherKey.startsWith(key)) {                                          // another observation of the same type was added/removed/changed
            const result = this.checkIsContinuous();
            if (result.clearContinuousIdx) {
                delete this.props.data.continuousIdx;
            }
            this.setState({showContinuous: result.showContinuous, continuousMsg: result.errorMsg, continuousComplete: result.complete, continuousIdx: result.idx});
        }
    }

    /**
     * returns true if the value does not meet the requirements.
     * @param {object} validifierData the infor required to validate the value
     * @param {number | string} value the value to check
     */
    invalidValue(validifierData, value) {
        if (validifierData.type && validifierData.type == "number") {
            if (isNaN(value)) return true;
            if (typeof value !== "string" ) value = value.toString();                               // normally, we expect strings, but what if!
            let valueParts = value.split('.');                                                      // we know it's a string cause of the input field (type text)
            value = +value;
            if (validifierData.min !== undefined && value < validifierData.min) return true;
            if (validifierData.max !== undefined && value > validifierData.max) return true;
            let fractionLength = 0;
            if (valueParts.length > 1) {
                fractionLength = valueParts[valueParts.length-1].length;
            }
            if (validifierData.precision !== undefined && fractionLength > validifierData.precision) return true;
        }
        return false;
    }

    renderCollapsed() {
        const { t } = this.props;
        let includePicStyle;
        if (this.state.includePicture) {
            includePicStyle = this.props.classes.ROCameraIconChecked;
        }
        else {
            includePicStyle = this.props.classes.ROCameraIconUnChecked;
        }
        return (
            <div className={this.props.classes.root} 
                onClick={this.expand}>
                <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Camera fontSize='small'
                        className={includePicStyle}
                    />
                    <Box minWidth="45px" textAlign="right" paddingRight="8px">{this.state.location.toFixed(2)}</Box>
                    <Breadcrumbs separator="-" maxItems={16} aria-label="breadcrumb">
                        {this.state.values.map((value, idx) => {
                            let tooltip = this.state.valueLabels ? this.state.valueLabels[idx] : "";
                            if (tooltip) {
                                tooltip = t(tooltip);
                            }
                            return(
                                <Tooltip title={tooltip} key={idx}>
                                    <Link color="inherit" >
                                        {value}
                                    </Link>
                                </Tooltip>
                            );
                        })}
                        {(this.state.waarde1 || this.state.waarde2 || this.state.direction1 || this.state.direction2) &&
                            <div>|</div>
                        }
                        {(this.state.waarde1) && 
                            <Link key={-1}>{this.state.waarde1}</Link>
                        }
                        {(this.state.waarde2) &&
                            <Link key={-2}>{this.state.waarde2}</Link>
                        }
                        {(+this.state.direction1) &&
                            <DirectionSelector key={-3} isExpanded={false} size={22} value={this.state.direction1}  />
                        }
                        {(+this.state.direction2) &&
                            <DirectionSelector key={-4} isExpanded={false} size={22} value={this.state.direction2} />
                        }
                    </Breadcrumbs>
                    <div style={{flex: 1}}/>
                    {this.renderContinuous()}
                </div>
                {(this.props.showObsDetails && this.state.valueLabels) && 
                    <Breadcrumbs separator="/" maxItems={16} aria-label="breadcrumblabels" style={{marginLeft: "70px"}}>
                        {this.state.valueLabels.map((el, idx) => { 
                            return (<div key={idx} color="inherit" className={this.props.classes.valueLabelBreadCrumbs}>{t(el)}</div>);
                        })}
                    </Breadcrumbs>
                }
                <Divider variant="fullWidth" light={true} />
            </div>
        );
    }

    renderContinuous() {
        if (this.state.showContinuous) {
            let msg = "";            
            let className = this.props.classes.isContinuousIcon;
            if (this.state.continuousMsg) {
                msg = this.state.continuousMsg;
                className = this.props.classes.isContinuousErrIcon;
            }
            let image = null;
            if (this.state.continuousComplete) {
                image = <CircleOutline className={className} />;
                // when cont obs is complete, no message needed
                msg = "";
            }
            else {
                image = <CircleSlice8 className={className} onClick={this.showCloseContinuousDlg}/>;
                if (!msg) {
                    msg = this.translations.notClosed;
                }
            }
            
            /* this div is important: otherwise we can't put the image a little bit down */
            return (
                <React.Fragment>
                    <Tooltip title={msg}>
                        <div>{image}</div>      
                    </Tooltip>
                    <Typography style={{minWidth: '16px'}}><sup>{this.state.continuousIdx}</sup> </Typography>
                    <ContinuousQuestionDialog open={this.state.continuousDlgOpen} onClose={this.closeContinuousDlg}/>
                </React.Fragment>
                );
        }
        else if(this.state.continuousIdx != null) {
            return (
                <sup>{this.state.continuousIdx}</sup>
            );
        }
        return null;
    }

    showCloseContinuousDlg = (event) => {
        if (this.props.readOnly) {                                                  // not allowed in readonly mode
            return;
        }
        this.setState({continuousDlgOpen: true});
        event.stopPropagation();                                                    // need to prevent from propogating, otherwise we change the position in the video, which we don't want
        event.preventDefault();
    }

    closeContinuousDlg = (result) => {
        this.setState({continuousDlgOpen: false});
        if (this.props.readOnly) {                                                  // not allowed in readonly mode
            return;
        }
        if (this.props.onContinuousClosed) {
            this.props.onContinuousClosed(this.props.data, result);
        }
    }


   
    expand = (event) => {
        if (!event.isPropagationStopped()) {                                // could be that the user clicked on the continuous-icon to close it in which case it was stopped
            this.props.onExpand(true);
            if (this.props.onPositionChanged) {
                this.props.onPositionChanged(this.props.data.time);
            }
        }
    }

    /*collaps = () => {
        this.props.onExpand(true);
    }
*/
    deleteObservation = () => {
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        this.props.onDelete(this);
    }

    toggleIncludePic = (event) => {
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        this.props.data.includePicture = !this.state.includePicture;
        this.setState({includePicture: !this.state.includePicture});
        activeProjectService.markDirty();
        if (event.screenX === 0 && event.screenY === 0) {                       // its triggered by a keyboard event, so move focus to the next item
            this.focsMgr.moveRight();
        }
    }

    toggleIncludeSonar = (event) => {
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        const newValue = !this.state.includeSonar;
        this.props.data.includeSonar = newValue;
        this.setState({includeSonar: newValue});
        activeProjectService.markDirty();
        if (event.screenX === 0 && event.screenY === 0) {                       // its triggered by a keyboard event, so move focus to the next item
            this.focsMgr.moveRight();
        }
    }

    toggleIncludeLidar = (event) => {
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        const newValue = !this.state.includeLidar;
        this.props.data.includeLidar = newValue;
        this.setState({includeLidar: newValue});
        activeProjectService.markDirty();
        if (event.screenX === 0 && event.screenY === 0) {                       // its triggered by a keyboard event, so move focus to the next item
            this.focsMgr.moveRight();
        }
    }

    handleValueChanged = (newValue) => {
        this.props.data.values = newValue;
        this.setState({values: newValue});
        activeProjectService.markDirty();
    }

    handleFieldChanged = (ev) => {
        this.props.data[ev.target.id] =  ev.target.value;
        let newState = {};
        newState[ev.target.id] =  ev.target.value;
        this.setState(newState);
        activeProjectService.markDirty();
    }

    /**
     * called when the user has changed the value for the direction.
     * withMouse: when true, the user used the mouse, so keep focus on the item, when with keyboard, move to next item 
     */
    handleDirectionChanged = (field) => (value, withMouse) => {
        this.props.data[field] =  value;
        let newState = {};
        newState[field] =  value;
        this.setState(newState);
        activeProjectService.markDirty();
        if (!withMouse) {                                                       // move cursor at next tick, otherwise we move focus before keyboard event is fully processed, which could cause the event to also go to the next html element (the remark input field)
            setImmediate(() => { this.focsMgr.moveRight(); });
        }  
        else {
            this.focsMgr.updateFocus();                                                 // could be that we get here cause of delete pressed. In that case, need to refocus again.
        }            
    }

    handleCheckJoint = (event) => {
        if (this.props.readOnly) {                                          // not allowed in readonly mode
            return;
        }
        this.props.data.joint = !this.props.data.joint;
        this.setState({joint: this.props.data.joint})
        activeProjectService.markDirty();
        if (event.screenX === 0 && event.screenY === 0) {                       // its triggered by a keyboard event, so move focus to the next item
            this.focsMgr.moveRight();
        }
    }

    handleDescriptiveLocChanged = (ev) => {
        this.props.data.descriptiveLoc = ev.target.value;
        this.setState({descriptiveLoc: ev.target.value});
        activeProjectService.markDirty();
    }

    handleContinuousIdxChanged = (ev) => {
        this.props.data.continuousIdx = +ev.target.value;
        this.setState({continuousIdx: +ev.target.value});
        activeProjectService.markDirty();
        activeProjectService.continuousChanged(this.props.data);
    }

    handleValueComplete = (value) => {
        try {
            const prevComplete = this.state.valueComplete;
            const prevContinuous = this.state.showContinuous;
            const data = this.props.data;
            this.setState({valueComplete: value});
            if (value == true) {                                                                            // value is complete
                this.isContinuous = codeSelectorService.isContinuous(data.values);
                if (this.isContinuous && data.continuousIdx == null) {                           // important: keep == null, to also catch 'undefined'
                    if (data.values[data.values.length-1] === "1") {                  // this is a continuous obs that doesn't yet ahve an idx and it's a terminator. So if there is only 1 previous obs related to it, we can pre-fill the idx value.
                        let related = this.getRelatedStartObs(false);
                        if (related && related.length === 1) {
                            data['continuousIdx'] = related[0].continuousIdx;
                            activeProjectService.markDirty();
                        }
                    }
                    activeProjectService.processNewContinuousRecord(data);
                    activeProjectService.findDuplicateToClose(data).then((toClose) => {          // if there is a same obs still open, ask user if it needs to be closed, and do so if needed
                        if (toClose && this.props.onContinuousClosed) {
                            // 'closeDuplicate is just a dummy value, it needs to be not null
                            this.props.onContinuousClosed(toClose, "closeDuplicate", data.time, data.longitude, false);                           
                        }
                    })             
                }
                else if (data.continuousIdx != null) {                                           // the previous iteration of this obs was a continuous one, so there might be a dangling otherside which we need to update (for the ui)
                    const {result, related} = this.checkLinkedObs();
                    if (!result) {
                        activeProjectService.clearContinuousRecord(data, related);         // state.values contains the previous index, this will help searching for any related to the previous continuous key
                    }
                }
                
                let contSettings = this.checkIsContinuous(true);                                            // when checking for continuous obs, we alo register events if they werent registered yet, checked on isContinuous, but this already gets set here, so it can't detect a change. This is why we need to force it when creating a new cont obj
                const obsDef = codeSelectorService.getLeaf(data.values, this.props.strengType)              // get the definition for the observation, so we can set properties 
                if (obsDef) {
                    const valueCount = codeSelectorService.getValueCount(obsDef, this.props.strengType);
                    const directionCount = codeSelectorService.getDirectionCount(obsDef, this.props.strengType);
                    if (valueCount == 1) delete data.waarde2;                                        // when a new observation def leaf is selected, always make certain that the correct amount of values & directions are stored in the data (makes it easier to render/saver)
                    if (!valueCount) this.deleteValues();
                    if (directionCount == 1) delete data.direction2;
                    if (!directionCount) this.deleteDirections();
                    if (!obsDef.remark) delete data.remark;
                    activeProjectService.markDirty();
                }
                if (this.isContinuous) {                                                                    // the value has changed and it is continuous, so make certain everybody is updated
                    activeProjectService.continuousChanged(data);
                }
                this.setState({showContinuous: contSettings.showContinuous, continuousMsg: contSettings.errorMsg, continuousIdx: contSettings.idx, continuousComplete: contSettings.complete, observationDef: obsDef});
                setImmediate(() => {
                    this.focsMgr.moveToFirstAfterCode();                                                   // when value is selected, move to the next input that the user needs to provide
                });
            }
            else {
                if (prevComplete == true && prevContinuous) {                                       // no longer continuous
                    const prevIdx = this.state.continuousIdx;                                       // so we can pass it along as param after removing from state
                    const prevKey = [...this.state.values];                                         // make a copy of the code list, it is about to be changed, but we need to pass it into the continousChanged event
                    this.setState({showContinuous: false, continuousMsg: "", continuousIdx: null});
                    this.isContinuous = false;
                    delete data.continuousIdx;
                    activeProjectService.continuousChanged(data, prevKey, prevIdx);
                }
                this.deleteValues();
                this.deleteDirections();
                delete data.remark;
                this.setState({remark: undefined});
                activeProjectService.markDirty();
            }
        } 
        catch(error) {
            dialogService.error(this.translations.docChanged, this.translations.changeErr(errExtractor.get(error)));
        }
    }

    deleteValues() {
        delete this.props.data.waarde1;  
        delete this.props.data.waarde2;
        this.setState({waarde1: undefined, waarde2: undefined});
    }

    deleteDirections() {
        delete this.props.data.direction1;
        delete this.props.data.direction2;
        this.setState({direction1: undefined, direction2: undefined});
    }

    /**called when the user wants to perform an automatic measurement by clicking on the video 
     * validifierData: so we can figure out how many digits after the comma
     * callback: the event to trigger. each type of measurement has it's own event.
    */
    handleStartMeasure = (valueIdx, validifierData, callback) => () => {
        this.stopAllMeasurements();                                         // if there is a current measurement going on, it needs to be stopped.
        if (this.state.measuring !== valueIdx) {                            // starting a new measure
            const measureData = {measuring: valueIdx, validifierData: validifierData};
            measurementService.measureData = measureData;
            measurementService.obs = this.props.data;
            this.setState(measureData);
            callback(this.handleMeasurementDone);
        }
        else {                                                              // turning an existing off, just set state cause the measurement has already been cnacled.
            this.setState({measuring: 0});
        }
    }


    /**
     * raises the event. Doesn't reset the measurement service, cause that service might need the data after
     * this object is destroyed (measure from fullscreen)
     */
    stopAllMeasurements() {
        if (this.state.measuring) {         
            if (this.props.onStopMeasure) {
                this.props.onStopMeasure();
            }
        }
    }

    /**
     * called when the measurement is done and it wasn't performed from fullscreen.
     * lets the obs update the ui.
     */
    handleMeasurementDone = (value) => {
        if (value == null) {                                                // null indicates canceled operation
            this.setState({measuring: 0});
        }
        else {
            let measuring = this.state.measuring !== null ? this.state.measuring : (measurementService.measureData ? measurementService.measureData.measuring : null);         //when measure starts with fullscreen, but user then goes to normal view, than the obs has been reloaded and state.measuring is gone. But the service should now the info still, so use that
            if (measuring === 1)  {
                this.setState({waarde1: value, measuring: 0});
            }
            else if (measuring === 2) {
                this.setState({waarde2: value, measuring: 0});
            }
            else {
                this.setState({measuring: 0});
                dialogService.error(this.translations.obs, this.translations.obsMeasureErrMsg);
            }
        }
        // don't call onStopMeasure, the measurement was succesful, so the root object will handle this situation. this is because sometimes this object is already destroyed (fullscreen mode)
    }

    handleReadyClicked = () => {
        if (this.distanceValueChanged === true && this.props.onDistanceChanged) {
            this.props.onDistanceChanged(this.props.data);
        }
        this.distanceValueChanged = false;                                              // important: needs to be reset, even if there is no callback assigned
        if (this.props.onExpand) this.props.onExpand(false);
        const key = this.props.data.values.join('-');
        if (key.startsWith(TERMINATE_KEY) || key.startsWith(TERMINATE_KEY2)) {          // the inspection has been terminated
            this.props.onTerminated();
        }
        if (key.startsWith(CHANGE_SHAPE_KEY) && this.props.readOnly === false) {        // the shape is changed and we are in edit mode, on the current obs, so the current time is active, so we need to udpate the strand-props so that all other parts of the system can adjust
            strandPropsService.setSizeFromObs(+this.state.waarde1, +this.state.waarde2, this.props.data);
        }
    }

    /**called by the code selector when a tab action causes it to go out of focus. */
    handleCodeSelectorGotoNext = () => {
        this.setState({allowSelectorFocus: false });
        this.focsMgr.moveRight();
    }

    /**
     * closing of tree needs to async call updateFocus, if we don't the objects to focus to, arent' loaded yet
     */
    handleTreeClosed = () => {
        setImmediate(() => {this.focsMgr.updateFocus()});
    }

    

    blockSelectorFocus =(idx) => () => {    
        this.focsMgr.currentlyFocused = idx;
        this.setState({allowSelectorFocus: false});             // so that we have the correct idx, even on a click
    }

    handleKeyboard = (e) => {
        if ((e.keyCode === 9 && e.ctrlKey === false && e.shiftKey === true) ||                              // shft-tab
            (e.keyCode === 37 && e.ctrlKey === false && e.shiftKey === false && this.focsMgr.notInText(true)) ) {   // arrow key        
            this.focsMgr.moveLeft();
            e.preventDefault();
            e.stopPropagation();
        }
        else if((e.keyCode === 9 && e.ctrlKey === false && e.shiftKey === false) ||                         // tab
        (e.keyCode === 39 && e.ctrlKey === false && e.shiftKey === false && this.focsMgr.notInText(false)) ||       // arrow key
        (e.keyCode === 13 && this.focsMgr.currentlyFocusedIsInput())                                                 // enter key
        ) {                   
            this.focsMgr.moveRight();
            e.preventDefault();
            e.stopPropagation();
        }
    }

    handleCodeSelectorClicked =() => {
        this.focsMgr.currentlyFocused = 0;
        this.setState({allowSelectorFocus: true});
    }

}

Observation.defaultProps = {
    treeOpen: false
}

Observation.propTypes = {
    data: PropTypes.object,
    isExpanded: PropTypes.bool,
    showObsDetails: PropTypes.bool,                         // hide/show code labels in a list.
    onExpand: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onPositionChanged: PropTypes.func,              // when this observation is expanded, let the system go to the video point at which it was recorded.
    treeOpen: PropTypes.bool,                       // when true, the search tree will be opened
    onTreeOpened: PropTypes.func,                   // used to reset that the tree must be opened (after it has been opened, the user must be able to close it again.)
    onMoveDown: PropTypes.func,                     // called after arrow up or down event, focus should move to observation above or below, at the same index as the parameter
    onMoveUp: PropTypes.func,
    onGotoNext: PropTypes.func,                     // called after tab goes out of scope, go to the next observation, start of values list
    onGotoPrev: PropTypes.func,
    focusedValueIdx: PropTypes.number,
    onFocusedInit: PropTypes.func,                  // called when a value is focused when loaded or updated in response to a focusedValueIdx
    showEmptyError: PropTypes.bool,                 // when true, shows an error on inputs that are empty and shoudl be filled
    strengType: PropTypes.string,                   // dp = streng, m = put, s=stationary determins which fields are visible
    onStartMeasure: PropTypes.func,                 // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStopMeasure: PropTypes.func,                  // called when measuring procedure should be stopped
    onStartMeasureAngle: PropTypes.func,            // called when a measuring procedure should be started. Contains a callback that should be called when the operation is done.
    onStartMeasureDepth: PropTypes.func,
    onStartMeasureAreaSize: PropTypes.func,
    onStartMeasureFreeform: PropTypes.func,         // see onStartMeasureAngle/onStartMeasure
    onGetList:PropTypes.func,                       // we use a function to get the list of observations instead of a prop cause the function remains the same, the list object changes often. It would cause too many unrequired ui updates for objects that don't need it.
    onContinuousClosed:PropTypes.func,              // called when the user wants to close a continuous observation or reopen it with new values
    readOnly: PropTypes.bool,                           // for viewer
    onDistanceChanged: PropTypes.func,              // called when the tractor-distance is changed 
    onTerminated: PropTypes.func,                   // called when the B-D-C-?-? code is entered -> inspection was terminated. This should stop video recording
};

export default withTranslation()(withStyles(styles)(Observation));