/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2021] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

import Battery from 'mdi-material-ui/Battery';
import Battery10 from 'mdi-material-ui/Battery10';
import Battery20 from 'mdi-material-ui/Battery20';
import Battery30 from 'mdi-material-ui/Battery30';
import Battery40 from 'mdi-material-ui/Battery40';
import Battery50 from 'mdi-material-ui/Battery50';
import Battery60 from 'mdi-material-ui/Battery60';
import Battery70 from 'mdi-material-ui/Battery70';
import Battery80 from 'mdi-material-ui/Battery80';
import Battery90 from 'mdi-material-ui/Battery90';
import BatteryEmpty from 'mdi-material-ui/BatteryOutline';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { say } from '../../../services/tts';


const styles = (theme) => ({
    root: {
        position: 'relative',
    },
    content: {
        top: "0px",
        bottom: "0px",
        position: "absolute",
        left: "0",
        right: "0",
        margin: "auto",
        width: "fit-content",
        height: 'fit-content',
    },
    value: {
        fontSize: 'x-small',
        color: theme.palette.text.secondary
    }
});


class BatteryIndicator extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            lifeLeft: props.lifeLeft,
        }
        if (props.handle) {                         // load at create to get fastest possible results
            props.handle(this);
        }
        this.lastAlert = null;
    }

    componentWillUnmount() {
        if (this.props.handle) {
            this.props.handle(null);
        }
    }

    componentDidMount () {
        if (this.props.handle) {                    // can be the it was unmounted and mounted again, so re-register
            this.props.handle(this);
        }
    }

    render() {
        const value = this.state.value ?? 0;
        const lifeLeft = this.state.lifeLeft ?? 0;
        const rounded = Math.round(value / 10) * 10;
        const size = (this.props.radius + 2) * 2;

        let fontSize = (this.props.radius/2)-4;
        if (fontSize < 7) {                                         // keep it readable
            fontSize = 7;
        }
        else if (fontSize > 14) {
            fontSize = 14;
        }
        fontSize = `${fontSize}px`
        return (
            <Tooltip title={this.props.tooltip}>
                <div style={{position: 'relative', height: `${size}px`, width: `${size}px`}}>
                    {this.renderIcon(rounded)}
                    <div style={{position: 'absolute', inset: '0px', margin: 'auto', width: 'fit-content', height: 'fit-content', color: '#bfaf61', mixBlendMode: "difference", fontSize: fontSize, fontWeight: 'bolder', textAlign: 'center', lineHeight: 1, paddingTop: "4px"}}>
                        <span >
                            {value}%
                            {(this.props.showLifeLeft) && 
                                <React.Fragment>
                                    <br/>{lifeLeft}<br/>min
                                </React.Fragment>
                            }
                        </span>
                    </div>
                </div>
            </Tooltip>

        );
    }

    setValue = (value) => {
        if (value !== this.state.value) {   
            this.setState({value: value});
            if (this.props.min !== undefined && value <= this.props.min) {
                this.sayAlert(this.props.minTxt);
            }
            else if (this.props.warning !== undefined && value <= this.props.warning) {
                this.sayAlert(this.props.warningTxt);
            }
        }
    }

    setValues = (battery, lifeLeft) => {
        if (battery !== this.state.value || this.state.lifeLeft !== lifeLeft) {   
            this.setState({value: battery, lifeLeft: lifeLeft});
            if (this.props.min !== undefined && battery <= this.props.min) {
                this.sayAlert(this.props.minTxt);
            }
            else if (this.props.warning !== undefined && battery <= this.props.warning) {
                this.sayAlert(this.props.warningTxt);
            }
        }
    }

    sayAlert(msg) {
        if (!this.props.readOnly) {                                                         // no need for this when in read mode
            let now = new Date();
            if (!this.lastAlert || (now - this.lastAlert) > 1000 * 3 ) {                    // only say it every 4 seconds, otherwise too often
                say(msg);
                this.lastAlert = now;
            }
        }
    }

    renderIcon(value) {
        let imgSize;
        const radius = this.props.radius;
        if (radius > 36) {
            imgSize = `${(radius-2)*2}px`;
        }
        else if (radius > 18) {
            imgSize = `${radius*2}px`;
        }
        else {
            imgSize = `${(radius+2)*2}px`;
        }
        let color = this.props.min !== undefined && value <= this.props.min ? 'red' : 'darkgray';
        if (color === 'darkgray') {
            color = this.props.warning !== undefined && value <= this.props.warning ? 'orange' : 'darkgray'; 
        }
        const style = { width: imgSize, height: imgSize, color: color, position: 'absolute', inset: '0px', margin: 'auto' };
        switch (value) {
            case 0:
                return(<BatteryEmpty style={style}/>);
            case 10:
                return(<Battery10 style={style}/>);
            case 20:
                return(<Battery20 style={style}/>);
            case 30:
                return(<Battery30 style={style}/>);
            case 40:
                return(<Battery40 style={style}/>);
            case 50:
                return(<Battery50 style={style}/>);
            case 60:
                return(<Battery60 style={style}/>);
            case 70:
                return(<Battery70 style={style}/>);
            case 80:
                return(<Battery80 style={style}/>);
            case 90:
                return(<Battery90 style={style}/>);
            default:
                return(<Battery style={style}/>);
        }
    }
}

BatteryIndicator.defaultProps = {
    min: 10,
    warning: 20,
    showLifeLeft: false,
}

BatteryIndicator.propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.number,                            // battery
    lifeLeft: PropTypes.number,                           // 
    showLifeLeft: PropTypes.bool,
    tooltip: PropTypes.string,
    radius: PropTypes.number,
    handle: PropTypes.func,                             // used to let the parent get a ref to this object
    min: PropTypes.number,                          // min value, at wich , the control should show error. should be multiple of 10
    warning: PropTypes.number,                          // value, at wich , the control should show warning. should be multiple of 10
    minTxt: PropTypes.string,
    warningTxt: PropTypes.string,
};

export default withStyles(styles)(BatteryIndicator);