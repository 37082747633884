import { storageService } from '../../services/storage_service';
import { downloadResource } from '../../shared_components/services/download_service';

export class AttachmentsBaseService {
    constructor() {

    }


    /**
     * adds the specified file to the current project. This is done copying the file into
     * the documens folders of the project.
     * when already published, the file is automatically uploaded.
     * @param {string} file file name and path
     */
    async add(file) {
       throw new Error("Not supported");
    }

   
    async remove(file) {
        throw new Error("not supported");
    }

    /**
     *  looks up all locally stored attachments and, if allowed, adds them to the list, otherwise only existing recs are allowed
     * to be updated.
     * @param {array} list list of already found items
     * @param {bool} blockAdds when true, no more items can be added, just file references will be completed (so that the list is ok for cloud and local)
     */
    async loadLocal(list, blockAdds) {
        return(list);
    }

    /**
     * show the file or download it
     * @param {object} file always has label and file or bucket 
     */
    async showFile(file) {
        if (file.bucket) {
            let url = await storageService.tryGetUrl(file.bucket);
            downloadResource(url);
        }
        else {
            throw new Error ("no file found");
        }
    }
}