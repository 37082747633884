/**
 * base class for platform independent implementation.
 * Each platform (web, electron,...) has it's own base class that implements platform specific functions.
 * the inheriter, projectService, is in the shared lib.
 */
export class ProjectBaseService {
    /**
     * create a new empty project data structure
     * is called by generic inheriter so, always must be here.
     * should eventually add the userId of current user (who
     * ceated the new project) 
     */
    createNew() {
        return ({});
    }
}