/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */

//icons:
import SwitchScreensIcon from 'mdi-material-ui/ArrowTopLeftBottomRightBold';

import React from 'react';
import '../../../App.css';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import VideoChannelPlayer from './video_channel_player_component';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1),
        width: '100px'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    subStyle: {
        width: "30%",
        bottom: '0px',
        right: '0px',
        position: 'absolute',
        zIndex: 9                                       // needs to be less then that of icon
    },
    subStyleWide: {
        top: '0px',
        bottom: '0px',
        right: '0px',
        position: 'absolute',
    },
    subHiddenStyle: {
        visibility: 'none'
    },
    videoStyleSingle: {
        margin: 'auto',
        top: '0px',
        bottom: '0px',
        left: '0px',
        right: '0px',
        position: 'absolute'
    },
    videoStyleDuo: {
        top: '0px',
        bottom: '0px',
        left: '0px',
        right: '0px',
        position: 'absolute'
    }
});

const textStyle = {
    margin: 'auto',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    position: 'absolute',
    width: 'fit-content',
    height: 'fit-content',
}

class VideoPlayer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.videoRefs = {};

        this.channelsLoading = {};                               // a dict of booleans to indicate if video streams are still loading or not
        this.state = {
            isLoading: false                                    // sum of channelsLoading
        }
    
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.channel2 ) {
            if (prevProps.isFullScreen !== this.props.isFullScreen) {                       // need to render 2 times: 1 position video, 2: calculate pos icon
                this.forceUpdate();
            }
        }
    }

    /**
     * when showing 2 videos, we can put both at the same size if there is room.
     */
    showSameSizedVideos() {
        if (this.props.inView) {
            let width = 0;
            let height = 0;
            for(const key in this.videoRefs) {
                const ref = this.videoRefs[key];
                if (ref && ref.current) {
                    if (ref.current.videoHeight > height) {
                        height = ref.current.videoHeight;
                    }
                    width += ref.current.videoWidth;
                }
            }
            const availableRatio = this.props.width / this.props.height;
            const neededRatio = width / height;
            return neededRatio <= availableRatio;
        }
        return false;
        //
    }

    render() {
        const { t, classes } = this.props;
        let videoStyle = this.props.inView ? classes.videoStyleDuo : classes.videoStyleSingle;   // when 2 elements, put main in top left corner
        
        let subElementRef = null;
        if (this.props.inView) {
            let channel2 = this.props.channelsSwitched ? this.props.channel : this.props.channel2;
            subElementRef = this.videoRefs[channel2];
            if (subElementRef) {
                subElementRef = subElementRef.current;
            }
        }
        let iconStyle;
        if (subElementRef) {
            const videoHeight = subElementRef.offsetHeight;
            const videoWidth = subElementRef.offsetWidth;
            iconStyle = {
                position: "absolute",
                bottom: `${videoHeight-12}px`,                        // -12 to get to the middel of the icon
                right: `${videoWidth-12}px`,
                zIndex: 10,
                color: "white",
                padding: '0px'
            }
        }
        const showSameSizedVideos = this.showSameSizedVideos();
        const channels = this.props.channels ?? [];                                         // for init
        return (
            <React.Fragment>
                {(!this.state.isLoading && !this.state.canPlay) &&
                    <div style={textStyle}>{t("Geen video beschikbaar")}</div>
                }
                {(this.state.isLoading) &&
                    <CircularProgress style={{margin: "auto",position: "absolute",top: 0,bottom: 0,left: 0,right: 0}} />
                }

                {channels.map((channel, idx) => {
                    let isMain;
                    let isVisible;
                    if (this.props.channelsSwitched === true) {
                        isMain = channel === this.props.channel2;
                        isVisible = (isMain || (this.props.inView && channel === this.props.channel));
                    }
                    else {
                        isMain = channel === this.props.channel;
                        isVisible = (isMain || (this.props.inView && channel === this.props.channel2));
                    }
                    isVisible = this.props.visible ? isVisible : false;                     // when video section is collapsed, overwrite visiblet to false
                    const style = isMain ? videoStyle : showSameSizedVideos ? classes.subStyleWide : classes.subStyle;
                    let handleCurrentTimeChanged = null;
                    let handleDurationChanged = null;
                    let handleEnded = null;
                    const handleMeta = (!isMain && isVisible) ? this.handleSubLoaded : null;
                    if (this.props.primaryChannel === channel) {
                        handleCurrentTimeChanged = this.props.onCurrentTimeChanged;
                        handleDurationChanged = this.props.onDurationChanged;
                        handleEnded = this.props.onEnded;
                    }
                    return(
                        <VideoChannelPlayer
                            key={idx}
                            width={this.props.width}
                            height={this.props.height}
                            onSizeSet={this.props.onSizeSet}
                            liveFeed={this.props.liveFeed}
                            controlState={this.props.controlState}
                            currentTime={this.props.currentTime}
                            onCurrentTimeChanged={handleCurrentTimeChanged}
                            onDurationChanged={handleDurationChanged}
                            onEnded={handleEnded}
                            activeStreng={this.props.activeStreng}
                            channel={channel}
                            isMain={isMain}
                            isVisible={isVisible}
                            videoClassName={style}
                            onMetaLoaded={handleMeta}
                            VideoRef={this.storeVideoRef}
                            onSizePosChanged={this.handleSizePosChanged}
                            onIsLoading={this.handleIsCameraLoading}
                            showSameSizedVideos={showSameSizedVideos}
                            canPlay={this.handleVideoCanPlay}
                        />
                    );
                })}

                {(this.props.inView && !showSameSizedVideos) && 
                    <IconButton 
                        onClick={this.handleSwitchCameras} 
                        style={iconStyle}>
                        <SwitchScreensIcon style={{stroke:'black'}}/>
                    </IconButton>
                }
            </React.Fragment>
        );
    }

    handleSwitchCameras = () => {
        this.props.onToggleChannels();
    }

    /**
     * this is needed for to make certain that the sub video element positions itself correctly
     */
    handleSubLoaded = () => {
        this.forceUpdate();
    }

    storeVideoRef = (ref, channel) => {
        this.videoRefs[channel] = ref;
    }

    handleSizePosChanged = () => {
        if (this.props.inView) {
            this.forceUpdate();                             // need to re-adjust the pos of the cursor
        }
    }

    handleIsCameraLoading = (channel, value) => {
        this.channelsLoading[channel] = value;
        let isLoading = false;
        for(const field in this.channelsLoading) {
            if (this.channelsLoading[field]) {
                isLoading = true;
                break;
            }
        }
        this.setState({isLoading: isLoading, canPlay: !isLoading});
    }

    /**
     * when called, at least 1 video is able to play, so hide 'no video' text
     */
    handleVideoCanPlay = () => {        
        this.setState({canPlay: true});
    }
}

VideoPlayer.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    onSizeSet: PropTypes.func,
    liveFeed: PropTypes.bool,
    controlState: PropTypes.string,
    currentTime: PropTypes.number,
    onCurrentTimeChanged: PropTypes.func,
    onDurationChanged: PropTypes.func,
    onEnded: PropTypes.func,
    activeStreng: PropTypes.object,                 // so we can do an update on the data when the strengchange has been processed by the document
    channel: PropTypes.string,                      // the video channel to show
    channel2: PropTypes.string,                     // if a secondary channel in a sub video needs to be shown.
    channels: PropTypes.array,                      // all available channels
    primaryChannel: PropTypes.string,               // the primary channel in the engine. provides time info, needed to register event handlers to the correct element
    onToggleChannels: PropTypes.func,               // called when the channels were switched
    channelsSwitched: PropTypes.bool,               // when true, switch video with sub video in position.
    isFullScreen: PropTypes.bool,                   // need to know this in case when inView is active (2 screens), when going from/to, it needs to rendered 2 times (for video element placement, next for icon)
    inView: PropTypes.bool,                         // when true, both videos are visible, otherwise only the video for channel or channel2 (depending on channelsSwitched)
    visible: PropTypes.bool,                        // when false, the video section is collapsed. only render video when internal camera. dont resize video when not visible, othrwise we loose connection
};

export default withTranslation()(withStyles(styles)(VideoPlayer));