
/* 
 *  ELASTETIC CONFIDENTIAL
 *  ______________________
 *     
 *  [2019] - [2020] Elastetic GCV
 *  All Rights Reserved.
 *     
 *  NOTICE:  All information contained herein is, and remains
 *  the property of Elastetic GCV and its suppliers,
 *  if any.  The intellectual and technical concepts contained
 *  herein are proprietary to Elastetic GCV
 *  and its suppliers and may be covered by Belgian, EU and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from Elastetic GCV.
 */


// icons
import Refresh from 'mdi-material-ui/Refresh';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';



const styles = (theme) => ({
});


/**
 * UI element for ar.
 */
class ARPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            arRefresh: 0,                               // counter used to refresh the iframe. binds to key, which forces a ui update (works better than forcupdate)
        }
        this.iFrame = React.createRef();                // so we can send messages to it
    }


    render() {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <iframe src="./ar/index.html"
                    allowfullscreen="true"
                    ref={this.iFrame}
                    onLoad={this.sendDataToAr}
                    style={{ width: '100%', height: '100%', border: "none",  margin:"0", padding:"0" }} 
                    key={this.state.arRefresh}/>

                <div style={{float: 'left'}}>
                    <IconButton
                        aria-label="reload"
                        style={{ position: 'absolute' }}
                        onClick={this.handleReload}
                        color="default">
                        <Refresh />
                    </IconButton>
                </div>
            </div>
        );
    }


    sendDataToAr = () => {
        const iFrame = this.iFrame.current;
        const data = this.props.location.state.places;
        if (iFrame && data) {
            //iFrame.contentWindow.postMessage({places: this.data}, API_URL);
            iFrame.contentWindow.postMessage({ places: data }, '*');
        }
    }

    handleReload = () => {
        this.setState({arRefresh: this.state.arRefresh + 1});
    }
}

ARPage.propTypes = {
};

export default withTranslation()(withStyles(styles)(ARPage));